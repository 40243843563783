import React from "react";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormNumberError from "../../errors/FormNumberError";
import FormSelectError from "../../errors/FormSelectError";

class ModuleFormError extends React.Component {

    render() {
        let error = this.props.error.split('|');
        if (this.props.error.slice(-6) === "number") {
            return <FormNumberError field_name={this.props.error.slice(0, -7)} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-6) === 'select') {
            return <FormSelectError field_name={error[0]} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

export default ModuleFormError;