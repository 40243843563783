import React from "react";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Website from "../../../models/website/Website";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../../helpers/CountryHelper";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeCatcherItemComponent from "./EyeCatcherItemComponent";
import FormToastComponent from "../../basics/FormToastComponent";
import MediaRepository from "../../../repository/MediaRepository";
import Application from "../../../helpers/Application";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class EyeCatcherOverviewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            website: null, // the website that is selected
            isLoading: true, // check for loading
            language: Application.tableLanguage(), // default language
            show_tool_tip: 1, // the tooltip what is showing,
            isText: this.props.isText,
            // media: this.props.media // array of all the media files
        }
    }

    componentDidMount() {
        // Get the object of the selected website
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }))
    }

    dragItem = (e) => {
        let draggedItem = e.target.closest('.custom-images-table-item');
        if (!draggedItem) return;
        draggedItem.style.cursor = "grabbing";
        this.setState({ draggingElement: draggedItem })
    }

    allowDrop = (e) => e.preventDefault();

    drop = (e) => {
        e.preventDefault();
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let rowToDrop = e.target.closest('.custom-images-table-item');
        if (!rowToDrop) return;

        if (rowToDrop.dataset.key !== this.state.draggingElement.dataset.key) {
            let parent = document.getElementsByClassName('custom-images-table')[this.state.language];
            let children = parent.children;
            let currentOrder = [];
            let currentOrderChildren = {}

            for (let i = 0; i < children.length; i++) {
                currentOrder.push(children[i].dataset.key);
                currentOrderChildren[children[i].dataset.key] = children[i];
            }

            let fromKey = this.state.draggingElement.dataset.key;
            let fromIndex = currentOrder.indexOf(fromKey);

            let toKey = rowToDrop.dataset.key;
            let toIndex = currentOrder.indexOf(toKey);

            let newOrder = currentOrder;
            newOrder.splice(fromIndex, 1); // Remove dragged el
            newOrder.splice(toIndex, 0, fromKey); // Add dragged el on new position

            parent.innerHTML = '';
            for (let i = 0; i < newOrder.length; i++) {
                let newKey = newOrder[i];
                parent.appendChild(currentOrderChildren[newKey]);
            }

            this.setState({ draggingElement: null })

            this.updateOrder();
        }
    }

    updateOrder = () => {
        let parent = document.getElementsByClassName('custom-images-table')[this.state.language];

        if (parent !== undefined) {
            let children = parent.children;
            let newOrder = [];

            for (let i = 0; i < children.length - 1; i++) {
                newOrder.push(children[i].dataset.key);
            }

            MediaRepository.updateOrder(newOrder, "eye_catcher", 'page', this.props.match.params.id !== undefined ? this.props.match.params.id : this.props.id)
                .then(response => {
                    if (response !== undefined && response.data !== undefined && response.data.success) {
                        FormToastComponent.successTrans('Media', 'CMS.Page.eyecatcher.Order.updated.success')
                        this.setState({ reload: true, newOrder: newOrder }, () => {
                            this.setState({ reload: false })
                        })
                    } else {
                        FormToastComponent.errorTrans('Media', 'CMS.Page.eyecatcher.Order.updated.failed')
                    }
                })
                .catch(() => FormToastComponent.errorTrans('Media', 'CMS.Page.eyecatcher.Order.updated.failed'))
        }
    }

    table = (language = null, rows) => {
        if (!this.state.isText && (!rows || rows.length <= 0)) {
            return (
                <div className="custom-data-table">
                    <FormattedMessage id={"CMS.Media.Album." + this.props.type + ".overview.empty"}>
                        {value => <p className="not-found">{value}</p>}
                    </FormattedMessage>
                </div>
            )
        }

        return (
            <div className={"custom-data-table page eye-catchers"}>
                <div className="table-header">
                    <FormattedMessage id={"CMS.Page.eyecatcher.overview.title"}>
                        {(value) => <p>{value}</p>}
                    </FormattedMessage>
                </div>
                <div className="custom-images-table">
                    {rows !== undefined ? rows.map((media_item, index) => {
                        if (media_item === undefined) return null;

                        let text = null;

                        if (language != null) {
                            text = media_item.text.find(i => i.language === language.language);
                            if (text === null && media_item.text[0] !== undefined) {
                                text = media_item.text[0]
                            }
                        }

                        return (
                            <EyeCatcherItemComponent id={this.props.id} key={index} index={index}
                                onDragStart={this.dragItem.bind(this)}
                                onDragOver={this.allowDrop.bind(this)}
                                onDrop={this.drop.bind(this)}
                                isText={this.state.isText}
                                parent={this}
                                image_url={media_item.media !== undefined ? media_item.media.url : media_item.url}
                                text={text} media_item={media_item}
                            />
                        )
                    }) : null}

                    <div className="custom-images-table-item custom-images-table-item-add" onClick={() => {
                        this.props.openModal();
                        this.props.parent.setState({
                            currentModalLanguage: this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language
                        })
                    }}>
                        {this.state.isText && (
                            <div className="custom-images-table-item-inner">
                                <FontAwesomeIcon icon={['far', 'plus-circle']} />
                                <FormattedMessage id={"CMS.Page.eyecatcher.overview.add"}>
                                    {(value) => <p>{value}</p>}
                                </FormattedMessage>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return null;

        let rows = this.props.media;
        let updatedRows = [];

        if (this.state.newOrder !== undefined) {
            this.state.newOrder.forEach(row => updatedRows.push(rows.find(x => x.media !== undefined ? x.media.id === parseInt(row) : x.id === parseInt(row))))
            rows = updatedRows;
        }

        return (
            <>
                {this.state.isText ?
                    <>
                        <div className="tab-tables">
                            <Tab.Container id="list-group-page-eye-catchers"
                                defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                                <ListGroup>
                                    {this.state.website.languages.map((language, index) => {
                                        return (
                                            <ListGroup.Item action href={"#" + language.language} key={index} onClick={() => this.setState({ language: index })}>
                                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                                            </ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                                <Tab.Content>
                                    {this.state.website.languages.map((language, index) => {
                                        return <Tab.Pane eventKey={"#" + language.language} key={index}>
                                            {this.table(language, rows)}
                                        </Tab.Pane>
                                    })}
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </>
                    :
                    <>
                        {this.table(null, rows)}
                    </>
                }
            </>
        );
    }
}

export default EyeCatcherOverviewComponent;