import React from "react";
import WeblinkRepository from "../../repository/WeblinkRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import WeblinkTextFormComponent from "./forms/WeblinkTextFormComponent";
import Module from "../../models/module/Module";
import Weblink from "../../models/weblink/Weblink";
import { ModuleRepository } from "../../repository";
import WeblinkContent from "../../models/weblink/WeblinkContent";
import { LoaderComponent } from "../basics/layout";

class WeblinkFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new WeblinkContent(),
            loading: true,
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let model = this.state.model;
                if (result[1] !== undefined) {
                    model = Weblink.fromJson(result[1]);
                    let content = model.contents.filter(content => content.language === this.props.match.params.language)[0];

                    model.title = content.title;
                    model.url = content.url;
                    model.content = content.content === '[]' ? {} : JSON.parse(content.content);
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    isEdit: model.id
                });
            });
    }

    getBeforeSubmit = (element) => WeblinkTextFormComponent.fields_image_before_submit(element)

    success = () => {
        FormToastComponent.successTrans("Weblink", "Default.saved");
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
    }

    error = (message) => FormHelper.handleSubmitError(this, message);

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className={"weblink"}
                validationSchema={Weblink.schema(this.state.settings)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.state.isEdit) {
                                WeblinkRepository.update({ id: values.id, language: this.props.match.params.language, ...values })
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            } else {
                                WeblinkRepository.create(values)
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={WeblinkTextFormComponent.fields(this.state.settings)}
            />
        );
    }
}

export default withRouter(WeblinkFormComponent);