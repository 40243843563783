import React from "react";
import * as Yup from 'yup';
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { LoaderComponent } from "../basics/layout";
import ProductContent from "../../models/product/ProductContent";
import MediaFileUploadComponent from "../media/image/MediaFileUploadComponent";
import { ModuleRepository, ProductRepository, WebsiteRepository } from "../../repository";
import { FormattedMessage } from "react-intl";
import Website from "../../models/website/Website";
import { ButtonIcon } from "../basics/button";
import ProductHelper from "../../helpers/ProductHelper";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { Col, Modal, Row, Table } from "react-bootstrap";
import FormToastComponent from "../basics/FormToastComponent";
import ReactToPrint from "react-to-print";
import { jsPDF } from "jspdf";
import Product from "../../models/product/Product";

class ProductImportComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: new ProductContent(),
            loading: false,
            website: null,
            settings: {},
            products: [],
            roles: this.props.user.roles,
            modal: false,
            errors: [],

            export: false
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    schema = () => Yup.object({
        file: Yup.mixed().required('CMS.Product.import.file')
    })

    render() {
        if (this.state.loading) return <LoaderComponent />;
        
        return (
            <Row>
                <Col lg={8}>
                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Product.import.info.step_1">
                            {value => <p>{value}</p>}
                        </FormattedMessage>

                        <ButtonIcon variant="outline-primary" size="sm" icon="fa-file-excel" onClick={() => this.export()} disabled={this.state.export}>
                            <FormattedMessageString id="CMS.Product.filter.buttons.export" />
                        </ButtonIcon>
                    </div>

                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Product.import.info.step_2">
                            {value => <p>{value}</p>}
                        </FormattedMessage>
                    </div>

                    <div className="custom-data-table mb-4">
                        <FormattedMessage id="CMS.Product.import.info.step_3">
                            {value => <p>{value}</p>}
                        </FormattedMessage>

                        <StandardFormComponent
                            {...this.customProps}
                            ref={this.table_ref}
                            parent={this}
                            model={this.state.model}
                            validationSchema={this.schema()}
                            className="product"
                            formSubmit={(values, formikActions) => {
                                ProductRepository.import(values.file, Module.get())
                                    .then(response => {
                                        FormToastComponent.successTrans('Products', 'CMS.Product.import.notification.success');
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(errors => this.setState({ modal: true, errors: errors ? errors : [] }, () => {
                                        if (this._mediaFileUploadComponent) {
                                            this._mediaFileUploadComponent.reset();
                                        }
                                    }));
                            }}
                            formErrors={this.state.form_errors}
                            fields={[{
                                type: "row",
                                children: [
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [],
                                        custom: MediaFileUploadComponent,
                                        customProps: { field: 'file', formErrorName: 'file', label: 'CMS.Product.import.file', type: 'import_file', optional: false, showPreview: true, fullWidth: true, onRef: ref => this._mediaFileUploadComponent = ref }
                                    }
                                ]
                            }]}
                        />
                        {this.renderErrorModal()}
                    </div>
                </Col>
            </Row>
        );
    }

    export = () => this.setState({ export: true }, () => {
        let promises = [
            ProductRepository.all(),
            WebsiteRepository.get(Website.get()),
            ModuleRepository.getSettings()
        ];

        Promise.all(promises)
            .then(response => {
                let categories = [];

                let settings = response[2].data?.settings;
                if (settings.use_categories) {
                    settings.references.filter(reference => reference.children.length <= 0).forEach(reference => {
                        categories.push({ id: reference.id, label: reference.depthLabel.join(' > ') });
                    })
                }

                ProductHelper.export(response[0].length ? response[0] : [new Product()], { website: response[1], settings: settings, categories: categories });
                this.setState({ export: false });
            })
    })

    renderErrorModal = () => {
        return <Modal show={this.state.modal} onHide={() => this.setState({ modal: !this.state.modal })} size="lg" animation={false} scrollable className="pages-show-modal" centered>
            <Modal.Header closeButton>
                <FormattedMessage id="CMS.Product.import.error.title">
                    {value => <Modal.Title>{value}</Modal.Title>}
                </FormattedMessage>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="CMS.Product.import.error.text">
                    {value => <p className="text-muted text-center">{value}</p>}
                </FormattedMessage>

                <Table size="sm" striped id="product-import-errors" ref={ref => this._componentRef = ref}>
                    <tr>
                        <th><FormattedMessageString id="CMS.Product.import.overview.row" /></th>
                        <th><FormattedMessageString id="CMS.Product.import.overview.field" /></th>
                        <th><FormattedMessageString id="CMS.Product.import.overview.message" /></th>
                    </tr>
                    {this.state.errors.map(error => (
                        <tr>
                            <td className="text-center">{error.row}</td>
                            <td>{error.field}</td>
                            <td>{error.message}</td>
                        </tr>
                    ))}
                </Table>

                <div className="buttons">
                    <ButtonIcon variant="outline-primary" icon="file-pdf" onClick={() => this.pdf()} className="mr-1">
                        <FormattedMessageString id="CMS.Product.import.buttons.pdf" />
                    </ButtonIcon>

                    <ReactToPrint
                        trigger={() => (
                            <ButtonIcon variant="outline-primary" icon="print" onClick={this.errorspdf}>
                                <FormattedMessageString id="CMS.Product.import.buttons.print" />
                            </ButtonIcon>
                        )}
                        content={() => this._componentRef}
                        pageStyle="
                            @page { size: 80mm 50mm; }
                            @media all {
                                .pagebreak { display: none; }
                            }
                            @media print {
                                th, td { font-size: 1.6em; }
                                .pagebreak { page-break-before: always; }
                            }
                        "
                    />
                </div>
            </Modal.Body>
        </Modal>
    }

    pdf = () => {
        // Default export is a4 paper, portrait, using millimeters for units
        let doc = new jsPDF('landscape', 'pt', 'a4');
        doc.setFontSize(10);

        doc.html(document.getElementById('product-import-errors'), {
            callback: (doc) => {
                doc.save('errors.pdf');
            },
            x: 20,
            y: 20
        });
    }
}

export default withRouter(ProductImportComponent);