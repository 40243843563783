import * as Yup from "yup"
import WeblinkContent from "./WeblinkContent";
import { JsonHelper } from "../../helpers";

export default class Weblink {
    id;
    website;
    category;
    active;
    module_references;
    image;
    contents = [];

    constructor() {
        this.module_references = [];
        this.content = new WeblinkContent();
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.Weblink.Content.Form.title').max(100, 'CMS.Weblink.Content.Form.title|100|max'),
            url: Yup.string().required('CMS.Weblink.Content.Form.url').max(255, 'CMS.Weblink.Content.Form.url|255|max')
                .test("is-url-without-https-http", "CMS.Weblink.Content.Form.url", val => {
                    if (!val) return true;

                    return Yup.object().shape({
                        url: Yup.string().url("CMS.Weblink.Content.Form.url|invalid")
                    }).validate({ url: 'https://' + val.replace('http://', '').replace('https://', '').replace('www.', '') });
                }),
        };

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Weblink.Content.Form.category|select');
        }
        if (settings.use_reference) {
            fields.module_references = Yup.array().required('CMS.Weblink.Content.Form.module_references_empty|select');
        }

        if (settings.show_content) {
            fields.content = Yup.object({ text: Yup.string().required('CMS.Weblink.Content.Form.content') })
        }
        
        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Weblink();

        item.website = json.website;
        item.category = json.category;
        item.id = json.id;
        item.active = json.active;
        item.module_references = json.module_references ? JsonHelper.tryParse(json.module_references) : [];
        item.image = json.image;
        item.contents = json.contents.map(content => WeblinkContent.fromJson(content));

        return item;
    }
}
