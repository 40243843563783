import React from "react";
import {FormattedMessage} from "react-intl";
import FormRequiredError from "../../errors/FormRequiredError";

class WebsiteHostError extends React.Component {
    render() {
        if (this.props.error === "CMS.Website.Form.sftp.host.invalid") {
            return <FormattedMessage id={"CMS.Website.Form.sftp.host"}>
                {
                    (field) =>
                        <FormattedMessage id={"CMS.Form.field.host.invalid"} values={{field: field}}>
                            {
                                (message) => <p>{message}</p>
                            }
                        </FormattedMessage>
                }
            </FormattedMessage>
        } else {
            return <FormRequiredError field_name={"CMS.Website.Form.sftp.host"}/>
        }
    }
}

export default WebsiteHostError;
