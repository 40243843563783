import React from "react";
import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";

class MetaData extends React.Component {
    render() {
        return (
            <Helmet>
                <title>{this.props.intl.formatMessage({ id: 'CMS.Meta.title' })}</title>

                <meta name="author" content={this.props.intl.formatMessage({ id: 'CMS.Meta.title' })} />
                <meta name="keywords" content={this.props.intl.formatMessage({ id: 'CMS.Meta.keywords' })} />
                <meta name="description" content={this.props.intl.formatMessage({ id: 'CMS.Meta.description' })} />
                <meta name="robots" content="index, follow, noarchive" />
                <meta name="googlebot" content="noarchive" />
                <meta name="og:site_name" content={this.props.intl.formatMessage({ id: 'CMS.Meta.author' })} />
                <meta name="og:title" content={this.props.intl.formatMessage({ id: 'CMS.Meta.title' })} />
                <meta name="og:description" content={this.props.intl.formatMessage({ id: 'CMS.Meta.description' })} />
                <meta name="og:locale" content={this.props.intl.formatMessage({ id: 'CMS.Meta.locale' })} />
                <meta name="og:url" content="https://console.seekat.app" />
                <meta name="og:image" content="https://console.seekat.app/images/logo_seekat@2x.png" />

                <script type="application/ld+json">
                    {`{
                            "@context":"https://schema.org/",
                            "@type":"LocalBusiness",
                            "name":"` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.name' }) + `",
                            "description": "` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.description' }) + `"
                            "url":"https://console.seeakt.app",
                            "logo":"https://console.seeakt.app/images/logo_seekat@2x.png",
                            "openingHours":["Mo-Su 08:00-18:00"],
                            "currenciesAccepted":"EUR","paymentAccepted":"Credit Card, Bank Card",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry":"` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.address.country' }) + `",
                                "addressLocality": "` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.address.locality' }) + `",
                                "postalCode":"` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.address.postalCode' }) + `",
                                "streetAddress":"` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.address.streetAddress' }) + `"
                            },
                            "contactPoint":{
                                "@type": "ContactPoint",
                                "telephone":"` + this.props.intl.formatMessage({ id: 'CMS.Schema.LocalBusiness.contactPoint.telephone' }) + `",
                                "contactType":["sales","customer support","technical support","billing support","bill payment","reservations","credit card support"],
                                "availableLanguage":["Dutch","French","English"]
                            }
                        }`}
                </script>
            </Helmet>
        );
    }
}

export default injectIntl(MetaData);