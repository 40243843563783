import React from "react";
import CategoryRepository from "../../repository/CategoryRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import Module from "../../models/module/Module";
import ModuleRepository from "../../repository/ModuleRepository";
import CategoryTextFormComponent from "./forms/CategoryTextFormComponent";
import CategoryContent from "../../models/category/CategoryContent";
import Category from "../../models/category/Category";
import { LoaderComponent } from "../basics/layout";
import { JsonHelper } from "../../helpers";

class CategoryAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let parent = props.match.params.parent ? props.match.params.parent : null;

        let module = Module.get();
        let settings = JsonHelper.tryParse(module?.module.settings);
        if (
            (!parent && !Category.canChange(this.props.user.roles, settings)) ||
            (parent && !Category.canChange(this.props.user.roles, settings, true))
        ) {
            this.props.history.push('/403-forbidden');
        }


        this.state = {
            model: this.props.model !== undefined ? this.props.model : new CategoryContent(),
            loading: true,
            categories: [],
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
            parent: props.match.params.parent ? props.match.params.parent : null
        };

        this.table_ref = React.createRef();
        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise)
        }

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];

                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let model = this.state.model;
                model.color = '#000000';

                this.setState({
                    loading: false,
                    model: model,
                    settings: settings
                });
            });
    }

    async getBeforeSubmit(element) {
        let promises = [];
        if (this.state.settings.show_cover_image) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'image', 'category_image'));
        }

        if (this.state.settings.show_svg) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'svg', 'category_svg'));
        }

        await Promise.all(promises);
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;
        
        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="category"
                validationSchema={Category.validation(this.state.settings, this.state.parent)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {
                        if (this.state.isEdit) {
                            CategoryRepository.updateCategory(values.id, this.props.match.params.language, values.settings, values.name)
                                .then(response => {
                                    FormToastComponent.successTrans("Categories", "Default.saved");
                                    this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                })
                                .catch(error => {
                                    FormHelper.handleSubmitError(this, error)
                                })
                        } else {
                            CategoryRepository.create(values, this.state.parent)
                                .then(response => {
                                    FormToastComponent.successTrans("Categories", "Default.saved");
                                    this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                })
                                .catch(error => {
                                    FormHelper.handleSubmitError(this, error)
                                })
                        }
                    })
                }}
                formErrors={this.state.form_errors}
                fields={CategoryTextFormComponent.fields(this.state.settings, this.state.parent)}
            />
        );
    }
}

export default withRouter(CategoryAddFormComponent);