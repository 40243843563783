import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownButton } from "react-bootstrap";
import User from "../../../models/user/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";

class UserDropDownComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            redirectToLogin: false,
            redirectToProfile: false,
            showDropdown: false
        };

        this.logout = this.logout.bind(this);
    }

    toggleDropdown = () => {
        if (this.state.showDropdown) {
            this.setState({ showDropdown: false });
        } else {
            this.setState({ showDropdown: true });
        }
    }


    logout() {
        User.logout();
        this.setState({
            redirectToLogin: true
        })
    }
    //
    // updateContactInfo(contact) {
    //     let newUser = this.state.user;
    //     newUser.contact = contact.user;
    //     this.setState({
    //         user: newUser
    //     })
    // }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            user: nextProps.user
        };
    }

    /* DEPRECATED --> getDerivedStateFromProps
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            user: nextProps.user
        })
    }*/

    render() {
        if (this.state.redirectToLogin) return <Redirect to="/auth/login" />;
        if (GeneralSettingsHelper.is()) return null;

        return (
            <div className={"user"}>
                <DropdownButton
                    title={
                        <>
                            {this.state.user.contact_info.first_name} {this.state.user.contact_info.last_name}
                            <FontAwesomeIcon icon={['fas', 'angle-down']} />
                        </>
                    }
                    onClick={() => this.toggleDropdown()}
                    onBlur={() => this.toggleDropdown()}
                    id="dropdown-menu-align-right">
                    <Dropdown.Item onClick={() => this.toggleDropdown()} eventkey="1" exact href="/profile" className={"dropdown-item"}><FormattedMessage id="CMS.TopNavBar.menu.user.profile" /></Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={this.logout}><FormattedMessage id="Auth.logout.text" /></Dropdown.Item>
                </DropdownButton>
            </div>
        )
    }
}

UserDropDownComponent.propTypes = {
    user: PropTypes.object.isRequired,
};
export default UserDropDownComponent;