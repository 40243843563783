export default class MediaContentUpload {

    index;
    type;
    title;
    content;
    image;
    image_src;
    image_location;

    // constructor(index, content, image, image_src, image_location, title = null, type = null) {
    constructor(data) {
        this.index = data.index;
        this.type = data.type;
        this.title = data.title;
        this.content = data.content;
        this.image = data.image;
        this.image_src = data.image_src;
        this.image_location = data.image_location;
    }
}
