import OptionContent from "./OptionContent";

export default class Option {
    id;
    order;

    static fromJson(json, language) {
        let option = new Option();

        option.id = json.id;

        let contents = json.contents.filter(content => content.language === language)[0];

        return { ...option, ...OptionContent.fromJson(contents) };
    }
}
