import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import TableEditActionComponent from "../basics/table/TableEditActionComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import CategoryOverviewTreeComponent from "./CategoryOverviewTreeComponent";
import CategoryRepository from "../../repository/CategoryRepository";
import FormToastComponent from "../basics/FormToastComponent";
import ToolTipLink from "../basics/ToolTipLink";
import { FormattedMessage } from "react-intl";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import moment from 'moment';
import { Link } from "react-router-dom";
import Application from "../../helpers/Application";
import FormattedMessageString from "../basics/FormattedMessageString";
import Category from "../../models/category/Category";
import LoaderComponent from "../basics/layout/LoaderComponent";

class CategoryOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            type: this.props.type,
            website: null,
            isLoading: true,
            toggler: [],
            treeData: [],
            language: Application.tableLanguage(),
            canChangeCategory: false,
            canChangeSubcategory: false
        };
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings(),
            WebsiteRepository.get(Website.get())
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let websiteResult = result[1];

                this.setState({
                    isLoading: false,
                    settings: settings,
                    website: websiteResult,
                    canChangeCategory: Category.canChange(this.props.user.roles, settings),
                    canChangeSubcategory: Category.canChange(this.props.user.roles, settings, true)
                }, () => {
                    if (!this.state.canChangeCategory) {
                        this.props.parent.hideAddBtn();
                    }
                });
            });
    }

    columns(index) {
        let { settings } = this.state;
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'placeholder',
                isDummyField: true
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Category.overview.table.name" />,
                sort: false,
                isDummyField: true,
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '25%', paddingLeft: (row.index * 30) + 'px' };
                },
                formatter: (cellContent, row) => {
                    let content = row.category.contents[index];
                    if (content !== undefined)
                        return content.title;
                    else
                        return null;
                }
            },
            {
                dataField: 'subcategories',
                isDummyField: true,
                text: '',
                hidden: !settings.has_subcategories,
                style: (cell, row, rowIndex, colIndex) => {
                    return { width: '15%' };
                },
                formatter: (cellContent, row) => {
                    if (row.index >= Category.maximumNumberOfLevels(settings)) return null;

                    return (
                        <div className={"add"}>
                            <Link to={"/module/categories/" + row.category.module + "/add/" + row.category.id} className={!this.state.canChangeSubcategory ? 'disabled' : ''}>
                                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                                <FormattedMessage id="CMS.Categories.overview.subcategories.add">{(value) => value}</FormattedMessage>
                            </Link>
                        </div>
                    );
                }
            },
            {
                dataField: 'updated',
                text: <FormattedMessageString id="CMS.Category.overview.table.last_updated" />,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let contents = row.category.contents[index];
                    if (contents === undefined) return null;
                    return (
                        <div className="updated_by">
                            {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                            {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                        </div>
                    )
                }
            },
            {
                dataField: 'views',
                text: '',
                sort: false,
                isDummyField: true,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    let contents = row.category.contents[index];
                    let hasContentBlocks = ((settings.content_with_blocks && !row.parentId) || (settings.subcategories_content_with_blocks && row.parentId));
                    let hasContent = ((settings.show_content && !row.parentId) || (settings.subcategories_show_content && row.parentId));

                    return (
                        <div className="actions">
                            {
                                (hasContentBlocks || hasContent) && (
                                    <Link to="#" className="link px-1 link-auto">
                                        <FontAwesomeIcon icon={['fas', 'fa-chart-column']} className="mr-1" />
                                        {contents.views}
                                    </Link>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let userHasRights = row.parentId ? this.state.canChangeSubcategory : this.state.canChangeCategory;
                    let hasContentBlocks = ((settings.content_with_blocks && !row.parentId) || (settings.subcategories_content_with_blocks && row.parentId));

                    return <div className="actions">
                        {hasContentBlocks && (
                            <ToolTipLink className={userHasRights ? "default" : 'default disabled'} to={"content/" + row.id + (row.category.contents[index] !== undefined ? '/' + row.category.contents[index].language : '/')}
                                title={"CMS.Category.overview.tooltip.content_with_blocks"}>
                                <FontAwesomeIcon icon={['far', 'file-plus']} size="lg" />
                            </ToolTipLink>
                        )}
                        {((settings.show_photoalbum && !row.parentId) || (settings.subcategories_show_photoalbum && row.parentId)) && (
                            <ToolTipLink className={userHasRights ? "default" : 'default disabled'} to={"photoalbum/" + row.id}
                                title={"CMS.Category.overview.tooltip.show_photoalbum"}>
                                <FontAwesomeIcon icon={['far', 'images']} size="lg" />
                            </ToolTipLink>
                        )}
                        {((settings.show_videoalbum && !row.parentId) || (settings.subcategories_show_videoalbum && row.parentId)) && (
                            <ToolTipLink className={userHasRights ? "default" : 'default disabled'} to={"videoalbum/" + row.id + (row.category.contents[index] !== undefined ? '/' + row.category.contents[index].language : '/')}
                                title={"CMS.Category.overview.tooltip.show_videoalbum"}>
                                <FontAwesomeIcon icon={['far', 'video-plus']} size="lg" />
                            </ToolTipLink>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() ?
                            row.category.active ?
                                <Link to="#" onClick={(event) => this.toggleActive(event, row)} className={userHasRights ? "active" : 'active disabled'}>
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </Link>
                                :
                                <Link to="#" onClick={(event) => this.toggleActive(event, row)} className={userHasRights ? "inactive" : 'inactive disabled'}>
                                    <FontAwesomeIcon icon={['far', 'times']} />
                                </Link>
                            : null}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && (
                            <TableEditActionComponent id={row.category.id} disabled={!userHasRights}
                                custom={row.category.contents[index] !== undefined ? '/' + row.category.contents[index].language : '/'} />
                        )}
                        {(CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR)) || CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ?
                            (!GeneralSettingsHelper.is() ?
                                <TableDeleteActionComponent disabled={!userHasRights} row={row} parent={this} /> : null)
                            : null}
                    </div>
                }
            }
        ];
    }

    switch = (index) => (
        <div className="toggle-tree" data-toggle="buttons" data-index={index}>
            <button type="button" data-toggle="button" data-view-type={"table"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? null : 'disabled'}>
                <FontAwesomeIcon icon={['far', 'th']} />
            </button>
            <button type="button" data-toggle="button" data-view-type={"tree"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? 'disabled' : ''}>
                <FontAwesomeIcon icon={['far', 'list']} />
            </button>
        </div>
    );

    toggleActive = (event, _row) => CategoryRepository.toggle(_row.category.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.category.id === _row.category.id) {
                    let category = row.category;
                    category.active = response;
                    return {
                        ...row,
                        category: category
                    }
                }
                return row;
            })
            this.setState(curr => ({ ...curr, rows: newRows, reload: false }));
        })
        .catch(error => FormToastComponent.errorTrans('CMS.Categories.overview.toggle.failed'));

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({ reload: true }, () => {
            this.props.parent.setState({ reload: false }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({ toggler: toggler, language: this.state.language })
            })
        })
    }

    updateData = () => CategoryRepository.all().then(response => { this.props.parent.setState({ rows: response }) })

    table = (index) => (
        <div className={"custom-data-table categories"}>
            <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)}
                title={this.props.title_id} type={this.props.type}
                custom={CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ? this.switch(index) : undefined}
                subRows={true} search={true} />
            <DeleteModalComponent parent={this} />
        </div>
    )

    tree = (index) => (
        <div className={'custom-data-table categories'}>
            <div className="table-wrapper">
                <div className="table-header">
                    <FormattedMessage id="CMS.Module.overview.tree.title" values={{ module: this.props.title_id }}>{(value) => <p>{value}</p>}</FormattedMessage>
                    {this.switch(index)}
                </div>
                <CategoryOverviewTreeComponent data={this.state.rows} index={index} parent={this} maxDepth={Category.maximumNumberOfLevels(this.state.settings) + 1} />
            </div>
        </div>
    )

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-categories"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                {this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ?
                                    this.tree(index)
                                    :
                                    this.table(index)
                                }
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}

CategoryOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired
};

export default CategoryOverviewTableComponent;