import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const ButtonStatistic = (props) => (
    <Link to="#" className="link px-1 link-auto">
        <FontAwesomeIcon icon={['fas', props.icon]} className="mr-1" />
        {props.value}
    </Link>
)

ButtonStatistic.defaultProps = {
    icon: 'fa-chart-column',
    value: 0
}
export default ButtonStatistic;
