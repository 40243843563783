import React from "react";
import MediaContentUploadComponent from "../../media/content/MediaContentUploadComponent";
import PageFormError from "../errors/PageFormError";
import MediaHelper from "../../../helpers/MediaHelper";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";

class PageTextFormComponent {
    static fields() {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: "CMS.Page.Content.Form.title",
                                type: "text",
                                name: "value.title",
                                optional: true,
                                customFeedback: PageFormError
                            }
                        ]
                    },
                    {
                        type: "col",
                        xl: 9,
                        fields: [],
                        custom: StandardTextFormEditorComponent
                    }
                ]
            }
        ]
    }

    static fields_image(isEdit = false, model = null, settings = {}) {
        return [
            {
                type: "row",
                children: [
                    (model && model.settings.has_special_blocks) ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: "CMS.Media.Content.block.form.type",
                                type: "select",
                                options: ['text', 'accordion'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Page.Media.Content.block.title.' + item} /> } }),
                                name: "type",
                                optional: false,
                                fake: !isEdit,
                                beforeOnChange: settings.onBlockTypeChange
                            },
                        ]
                    } : {},
                    {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: "CMS.Page.Content.Form.title",
                                type: "text",
                                name: "title",
                                optional: settings.titleOptional,
                                fake: !isEdit
                            },
                        ]
                    },
                    {
                        type: "col",
                        xl: 9,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        fake: !isEdit
                    },
                    {
                        type: "col",
                        xl: 11,
                        fields: [],
                        custom: MediaContentUploadComponent,
                        customProps: { type: 'page_text_image', model: model },
                        fake: isEdit
                    }
                ]
            }
        ]
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let page_content = element.state.model
            let value = page_content.value;
            let value_content = value.content ? value.content : [];
            value_content = await Promise.all(value_content.map(async (item) => {
                if (item.image !== null && item.image !== undefined) {
                    item.image_src = await MediaHelper.uploadImage(item, 'image', 'page_text_image', 'page', element.props.id, null, item.title);
                    item.image = undefined;
                }
                return item;
            }))

            page_content.value.content = value_content;

            element.setState({ model: page_content }, () => resolve(true))
        })
    }
}

export default PageTextFormComponent;
