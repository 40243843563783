import React from "react";
import { withRouter } from "react-router-dom";
import FormToastComponent from "./FormToastComponent";

class PromiseBoundary extends React.Component {

    componentDidMount = () => window.addEventListener('unhandledrejection', this.handleUnhandledRejection);
    componentWillUnmount = () => window.removeEventListener('unhandledrejection', this.handleUnhandledRejection);

    handleUnhandledRejection = (event) => {
        event.preventDefault();

        let reason = event.reason;
        if (typeof reason === 'object' && 'message' in reason) {
            reason = reason.message;
        }
        
        switch (reason) {            
            case 'Request failed with status code 403':
                this.props.history.push('/403-forbidden');
                break;
            case 'Request failed with status code 404':
                this.props.history.push('/404-not-found');
                break;
            default:
                FormToastComponent.errorTrans('Default.error');
                break;
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(PromiseBoundary);