import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import API from "../services/api";

export default class CategoryRepository {

    static all = (all = false) => API.get('/category/all?all=' + (all ? 1 : 0)).then(result => result.data.categories);

    static get = (id) => API.get('/category/details/' + id).then(result => result.data.category);

    static create = (category, parent) => API.post('/category/create', {
        title: category.title,
        parent: parent,
        label: category.label,
        color: category.color,
        email: category.email,
        summary: category.summary,
        highlights: JSON.stringify(category.highlights),
        value: JSON.stringify(category.value),
        image: category.image,
        home_image: category.home_image,
        svg: category.svg,
        settings: { ...category.settings }
    });

    static update = (data) => API.post('/category/content/update', data);

    static delete = (id) => API.delete('/category/delete?id=' + id);

    static toggle = (id) => API.post('/category/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static updateTree = (data) => API.post('/category/update/order', { tree: data });

    static updateCategory = (id, language, settings, title) => API.post('/category/update', {
        id: id,
        language: language,
        settings: { ...settings },
        title: title
    });

    static getAlbum(id, album) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/media/all?type=' + album + '&module=category&moduleId=' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static getBackgroundVideos(id, album) {
        return new Promise((resolve, reject) => {
            CategoryRepository.getAlbum(id, album)
                .then(response => {
                    let rows = [];
                    let data = response['data'];

                    if (data && data.media !== undefined) {
                        data.media.forEach((row) => {
                            rows.push(row)
                        })
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }
}
