import Address from "./Address";
import Contact from "./Contact";
import * as Yup from 'yup';
import FormattedMessageString from "../../components/basics/FormattedMessageString";
import React from "react";
import Website from "./../website/Website";

class UserDetailed {

    email = '';
    address = new Address();
    contact = new Contact();
    profile_picture = null;
    profile_picture_url = '';
    type = '';
    language = '';
    reseller = '';
    websites;

    constructor() {
        this.type = <FormattedMessageString id={"CMS.Editor.overview.single.title"} />;
        this.websites = [Website.get()];
    }

    static schema_fields = {
        email: Yup.string().email('contact.form.email.invalid').required('contact.form.email'),
        profile_picture_url: Yup.string(),
        type: Yup.string().required(),
        language: Yup.string().required(),
        address: Address.schema
    };

    static schema = Yup.object({
        ...UserDetailed.schema_fields,
        contact: Contact.reseller_schema,
    });

    static schema_customer = Yup.object({
        ...UserDetailed.schema_fields,
        contact: Contact.default_schema,
        reseller: Yup.mixed().required()
    });

    static schema_form_standard = Yup.object({
        email: Yup.string().email('contact.form.email.invalid').required('contact.form.email'),
        profile_picture_url: Yup.string(),
        type: Yup.string().required(),
        language: Yup.string().required(),
        websites: Yup.array().required(),
        contact: Contact.schema_form_standard
    })
}

export default UserDetailed;
