import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import ProductFormError from './errors/ProductFormError';
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";
import CustomTextArea from "../basics/CustomTextArea";

class ProductCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_categories: props.values.settings.use_categories,
            use_spotlight: props.values.settings.use_spotlight,
            use_product_tags: props.values.settings.use_product_tags,
            use_variants: props.values.settings.use_variants,
            use_webshop: props.values.settings.use_webshop,

            show_article_number: props.values.settings.show_article_number,
            show_summary: props.values.settings.show_summary,
            show_stock: props.values.settings.show_stock,

            show_additional_field_1: props.values.settings.show_additional_field_1,
            additional_field_1_type: props.values.settings.additional_field_1_type,
            additional_field_1_required: props.values.settings.additional_field_1_required,
            additional_field_1_label: props.values.settings.additional_field_1_label,
            additional_field_1_text: props.values.settings.additional_field_1_text,

            show_additional_field_2: props.values.settings.show_additional_field_2,
            additional_field_2_type: props.values.settings.additional_field_2_type,
            additional_field_2_required: props.values.settings.additional_field_2_required,
            additional_field_2_label: props.values.settings.additional_field_2_label,
            additional_field_2_text: props.values.settings.additional_field_2_text,

            show_additional_field_3: props.values.settings.show_additional_field_3,
            additional_field_3_type: props.values.settings.additional_field_3_type,
            additional_field_3_required: props.values.settings.additional_field_3_required,
            additional_field_3_label: props.values.settings.additional_field_3_label,
            additional_field_3_text: props.values.settings.additional_field_3_text,

            show_cover_image: props.values.settings.show_cover_image,
            cover_image_width: props.values.settings.cover_image_width,
            cover_image_height: props.values.settings.cover_image_height,

            need_home_img: props.values.settings.need_home_img,
            home_img_width: props.values.settings.home_img_width,
            home_img_height: props.values.settings.home_img_height,

            show_pdf: props.values.settings.show_pdf,
            show_photoalbum: props.values.settings.show_photoalbum,
            show_videoalbum: props.values.settings.show_videoalbum,

            categories: [],
            lng: lng,
            additionFieldTypes: [{ key: 'text', label: 'Tekstveld' }, { key: 'url', label: 'URL-veld' }, { key: 'textarea', label: 'Groot tekstveld' }, { key: 'editor', label: 'WYSIWYG editor' }]
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => this.setState({
                categories: result.filter(module => module.module.type === ModuleTypeEnum.CATEGORY),
                use_categories: this.props.values.settings.use_categories
            }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    isInvalid = (setting) => this.props.errors.settings != null && this.props.errors.settings[setting];

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Product.Form.fields"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Show article number */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1"
                                    id="custom-switch-show-article-number" name="settings.show_article_number"
                                    label="show_article_number"
                                    onChange={event => this.onChange(event, () => this.setState({ show_article_number: !this.state.show_article_number }))}
                                    checked={this.state.show_article_number}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_article_number">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show summary */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1"
                                    id="custom-switch-show-summary" name="settings.show_summary"
                                    label="show_summary"
                                    onChange={event => this.onChange(event, () => this.setState({ show_summary: !this.state.show_summary }))}
                                    checked={this.state.show_summary}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_summary">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show stock quantity */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1"
                                    id="custom-switch-show-stock-quantity" name="settings.show_stock"
                                    label="show_stock"
                                    onChange={event => this.onChange(event, () => this.setState({ show_stock: !this.state.show_stock }))}
                                    checked={this.state.show_stock}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_stock">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-cover-image" label="show_cover_image"
                                    name="settings.show_cover_image"
                                    onChange={(event) => {
                                        this.onChange(event, () => this.setState({
                                            show_cover_image: !this.state.show_cover_image,
                                            need_home_img: false
                                        }, () => {
                                            delete this.props.values.settings.cover_image_width;
                                            delete this.props.values.settings.cover_image_height;
                                            this.props.values.settings.need_home_img = false;
                                            delete this.props.values.settings.home_img_width;
                                            delete this.props.values.settings.home_img_height;
                                        }));
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_cover_image ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_cover_image && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={6}>
                                            <InputGroup className={this.isInvalid('cover_image_width') ? 'is-invalid mb-1' : 'mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">cover_image_width</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="cover_image_width"
                                                    name="settings.cover_image_width"
                                                    onChange={this.onChange}
                                                    isInvalid={this.isInvalid('cover_image_width')}
                                                    value={this.props.values.settings.cover_image_width}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('cover_image_width') && (
                                                    <ProductFormError error={this.props.errors.settings.cover_image_width} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col md={6}>
                                            <InputGroup className={this.isInvalid('cover_image_height') ? 'is-invalid mb-1' : 'mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherHeight">cover_image_height</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="cover_image_height"
                                                    name="settings.cover_image_height"
                                                    onChange={this.onChange}
                                                    isInvalid={this.isInvalid('cover_image_height')}
                                                    value={this.props.values.settings.cover_image_height}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('cover_image_height') && (
                                                    <ProductFormError error={this.props.errors.settings.cover_image_height} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col xs={12}>
                                            <Form.Check type="switch" id="custom-switch-needs-home-image" label="need_home_img"
                                                name="settings.need_home_img"
                                                onChange={(event) => {
                                                    this.onChange(event, () => this.setState({ need_home_img: !this.state.need_home_img }, () => {
                                                        delete this.props.values.settings.home_img_width;
                                                        delete this.props.values.settings.home_img_height;
                                                    }));
                                                }}
                                                className="mb-1"
                                                checked={this.state.need_home_img ? "checked" : ""}
                                            />
                                        </Col>

                                        {this.state.need_home_img && (
                                            <Form.Group className="subsettings sub">
                                                <Row>
                                                    <Col md={6}>
                                                        <InputGroup className={this.isInvalid('home_img_width') ? 'is-invalid mb-1' : 'mb-1'}>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="inputGroupPrependEyecatcherWidth">home_img_width</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="text" aria-describedby="home_img_width"
                                                                name="settings.home_img_width"
                                                                onChange={this.onChange}
                                                                isInvalid={this.isInvalid('home_img_width')}
                                                                value={this.props.values.settings.home_img_width}
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.isInvalid('home_img_width') && (
                                                                <ProductFormError error={this.props.errors.settings.home_img_width} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </Col>

                                                    <Col md={6}>
                                                        <InputGroup className={this.isInvalid('home_img_height') ? 'is-invalid mb-1' : 'mb-1'}>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="inputGroupPrependEyecatcherHeight">home_img_height</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="text" aria-describedby="home_img_height"
                                                                name="settings.home_img_height"
                                                                onChange={this.onChange}
                                                                isInvalid={this.isInvalid('home_img_height')}
                                                                value={this.props.values.settings.home_img_height}
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.isInvalid('home_img_height') && (
                                                                <ProductFormError error={this.props.errors.settings.home_img_height} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        )}

                                        <Col>
                                            <FormattedMessage id="CMS.Modules.Form.settings.product.show_cover_image">
                                                {values => <p className="input-info">{values}</p>}
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 1 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_field_1"
                                    id="custom-switch-show-additional-field-1"
                                    name="settings.show_additional_field_1"
                                    onChange={(event) => {
                                        this.onChange(event, () => this.setState({ show_additional_field_1: !this.state.show_additional_field_1 }, () => {
                                            delete this.props.values.settings.additional_field_1_type;
                                            delete this.props.values.settings.additional_field_1_required;
                                            delete this.props.values.settings.additional_field_1_label;
                                        }));
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_1 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_1 && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_1_type') ? 'is-invalid large mb-1' : 'large mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select" aria-describedby="additional_field_1_type"
                                                    onChange={this.onChange}
                                                    name="settings.additional_field_1_type"
                                                    isInvalid={this.isInvalid('additional_field_1_type')}
                                                    value={this.props.values.settings.additional_field_1_type}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.additionFieldTypes.map(type => (
                                                        <FormattedMessage id={type.label}>
                                                            {label => <option value={type.key}>{label}</option>}
                                                        </FormattedMessage>
                                                    ))}
                                                </Form.Control>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_1_type') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_1_type} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_1_label') ? 'is-invalid xxl mb-1' : 'xxl mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="additional_field_1_label"
                                                    type="text"
                                                    name="settings.additional_field_1_label"
                                                    onChange={this.onChange}
                                                    isInvalid={this.isInvalid('additional_field_1_label')}
                                                    value={this.props.values.settings.additional_field_1_label}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_1_label') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_1_label} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <InputGroup className="no-suffix xxl mb-1 ig-textarea">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CustomTextArea aria-describedby="additional_field_1_text"
                                                    name="settings.additional_field_1_text"
                                                    onChange={this.onChange}
                                                >
                                                    {this.props.values.settings.additional_field_1_text}
                                                </CustomTextArea>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.props.errors.settings != null && this.props.errors.settings.additional_field_1_text && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_1_text} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Check type="switch" label="required"
                                                id="custom-switch-show-additional-field-1-required"
                                                name="settings.additional_field_1_required"
                                                onChange={(event) => {
                                                    this.onChange(event, () => this.setState({ additional_field_1_required: !this.state.additional_field_1_required }));
                                                }}
                                                className="mb-1"
                                                checked={this.state.additional_field_1_required ? "checked" : ""}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_additional_field_1">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 2 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-additional-field-2" label="show_additional_field_2"
                                    name="settings.show_additional_field_2"
                                    onChange={(event) => {
                                        this.onChange(event, () => this.setState({ show_additional_field_2: !this.state.show_additional_field_2 }, () => {
                                            delete this.props.values.settings.additional_field_2_type;
                                            delete this.props.values.settings.additional_field_2_required;
                                            delete this.props.values.settings.additional_field_2_label;
                                        }));
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_2 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_2 && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_2_type') ? 'is-invalid large mb-1' : 'large mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select" aria-describedby="additional_field_2_type"
                                                    onChange={this.onChange}
                                                    name="settings.additional_field_2_type"
                                                    isInvalid={this.isInvalid('additional_field_2_type')}
                                                    value={this.props.values.settings.additional_field_2_type}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.additionFieldTypes.map(type => (
                                                        <FormattedMessage id={type.label}>
                                                            {label => <option value={type.key}>{label}</option>}
                                                        </FormattedMessage>
                                                    ))}
                                                </Form.Control>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_2_type') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_2_type} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_2_label') ? 'is-invalid xxl mb-1' : 'xxl mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="additional_field_2_label"
                                                    name="settings.additional_field_2_label"
                                                    onChange={this.onChange}
                                                    isInvalid={this.isInvalid('additional_field_2_label')}
                                                    value={this.props.values.settings.additional_field_2_label}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_2_label') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_2_label} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <InputGroup className="no-suffix xxl mb-1 ig-textarea">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CustomTextArea aria-describedby="additional_field_2_text"
                                                    name="settings.additional_field_2_text"
                                                    onChange={this.onChange}
                                                >
                                                    {this.props.values.settings.additional_field_2_text}
                                                </CustomTextArea>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_2_text') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_2_text} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Check type="switch" id="custom-switch-show-additional-field-2-required"
                                                label="required"
                                                name="settings.additional_field_2_required"
                                                onChange={(event) => {
                                                    this.onChange(event, () => this.setState({ additional_field_2_required: !this.state.additional_field_2_required }));
                                                }}
                                                className="mb-1"
                                                checked={this.state.additional_field_2_required ? "checked" : ""}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_additional_field_2">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show additionalField 3 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-additional-field-3"
                                    label="show_additional_field_3"
                                    name="settings.show_additional_field_3"
                                    onChange={(event) => {
                                        this.onChange(event, () => this.setState({ show_additional_field_3: !this.state.show_additional_field_3 }, () => {
                                            delete this.props.values.settings.additional_field_3_type;
                                            delete this.props.values.settings.additional_field_3_required;
                                            delete this.props.values.settings.additional_field_3_label;
                                        }));
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_additional_field_3 ? "checked" : ""}
                                />
                            </Col>

                            {this.state.show_additional_field_3 && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_3_type') ? 'is-invalid large mb-1' : 'large mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select" aria-describedby="additional_field_3_type"
                                                    onChange={this.onChange}
                                                    name="settings.additional_field_3_type"
                                                    isInvalid={this.isInvalid('additional_field_3_type')}
                                                    value={this.props.values.settings.additional_field_3_type}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.additionFieldTypes.map(type => (
                                                        <FormattedMessage id={type.label}>
                                                            {label => <option value={type.key}>{label}</option>}
                                                        </FormattedMessage>
                                                    ))}
                                                </Form.Control>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_3_type') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_3_type} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('additional_field_3_label') ? 'is-invalid xxl mb-1' : 'xxl mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="additional_field_3_label"
                                                    name="settings.additional_field_3_label"
                                                    onChange={this.onChange}
                                                    isInvalid={this.isInvalid('additional_field_3_label')}
                                                    value={this.props.values.settings.additional_field_3_label}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_3_label') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_3_label} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <InputGroup className="no-suffix xxl mb-1 ig-textarea">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <CustomTextArea aria-describedby="additional_field_3_text"
                                                    name="settings.additional_field_3_text"
                                                    onChange={this.onChange}
                                                >
                                                    {this.props.values.settings.additional_field_3_text}
                                                </CustomTextArea>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('additional_field_3_text') && (
                                                    <ProductFormError error={this.props.errors.settings.additional_field_3_text} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Check type="switch" id="custom-switch-show-additional-field-3-required" className="mb-1"
                                                label="required"
                                                name="settings.additional_field_3_required"
                                                onChange={(event) => this.onChange(event, () => this.setState({ additional_field_3_required: !this.state.additional_field_3_required }))}
                                                checked={this.state.additional_field_3_required ? "checked" : ""}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_additional_field_3">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show pdf */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-pdf" className="mb-1"
                                    label="show_pdf"
                                    name="settings.show_pdf"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_pdf: !this.state.show_pdf }))}
                                    checked={this.state.show_pdf}
                                />
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_pdf">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Col xs={12}>
                        <FormattedMessage id="CMS.Product.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-use-categories" label="use_categories"
                                    name="settings.use_categories"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_categories: !this.state.use_categories }, () => {
                                                delete this.props.values.settings.module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {this.state.use_categories && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <InputGroup className={this.isInvalid('module') ? 'is-invalid large mb-1' : 'large mb-1'}>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select"
                                                    aria-describedby="module"
                                                    onChange={this.onChange}
                                                    name="settings.module"
                                                    isInvalid={this.isInvalid('module')}
                                                    value={this.props.values.settings.module}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.categories.map(module => {
                                                        let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                        return <option value={module.id}>{moduleContent.title}</option>
                                                    })}
                                                </Form.Control>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {this.isInvalid('module') && (
                                                    <ProductFormError error={this.props.errors.settings.module} />
                                                )}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.use_categories">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use spotlight */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-use-spotlight" className="mb-1"
                                    label="use_spotlight" name="settings.use_spotlight"
                                    onChange={event => this.onChange(event, () => this.setState({ use_spotlight: !this.state.use_spotlight }))}
                                    checked={this.state.use_spotlight}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.use_spotlight">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use tags */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1"
                                    id="custom-switch-use-product-tags" name="settings.use_product_tags"
                                    label="use_product_tags"
                                    onChange={event => this.onChange(event, () => this.setState({ use_product_tags: !this.state.use_product_tags }))}
                                    checked={this.state.use_product_tags}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.use_product_tags">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use variants */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1" disabled
                                    id="custom-switch-use-variants" name="settings.use_variants"
                                    label="use_variants"
                                    onChange={event => this.onChange(event, () => this.setState({ use_variants: !this.state.use_variants }))}
                                    checked={this.state.use_variants}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.use_variants">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use webshop */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" className="mb-1"
                                    id="custom-switch-use-webshop" name="settings.use_webshop"
                                    label="use_webshop"
                                    onChange={event => this.onChange(event, () => this.setState({ use_webshop: !this.state.use_webshop }))}
                                    checked={this.state.use_webshop}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.product.use_webshop">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show photo_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-photoalbum" className="mb-1"
                                    label="show_photoalbum"
                                    name="settings.show_photoalbum"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_photoalbum: !this.state.show_photoalbum }))}
                                    checked={this.state.show_photoalbum}
                                />
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_photoalbum">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show video_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-videoalbum" className="mb-1"
                                    label="show_videoalbum"
                                    name="settings.show_videoalbum"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_videoalbum: !this.state.show_videoalbum }))}
                                    checked={this.state.show_videoalbum}
                                />
                                <FormattedMessage id="CMS.Modules.Form.settings.product.show_videoalbum">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this} title="CMS.Product.Form.setting.delete.title" text="CMS.Product.Form.setting.delete.text"
                    buttons={{
                        confirm: {
                            text: 'Default.confirm',
                            icon: 'check'
                        }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group >
        );
    }
}

export default ProductCustomSettingsFormComponent;