import React from "react";
import { Form } from "react-bootstrap";

export default class CustomTextArea extends React.Component {

    componentDidMount() {
        if (this.multilineTextarea) {
            this.multilineTextarea.style.height = 'auto';

            if (this.props.minHeight) {
                this.multilineTextarea.style.height = this.props.minHeight + 'px';
            } else {
                this.multilineTextarea.style.height = this.multilineTextarea.scrollHeight + 'px';
            }
        }
    }

    componentDidUpdate() {
        this.changeTextarea();
    }

    changeTextarea = () => {
        this.multilineTextarea.style.height = 'auto';
        this.multilineTextarea.style.height = (this.multilineTextarea.scrollHeight > 0 ? this.multilineTextarea.scrollHeight : this.props.minHeight) + 'px';
    };

    render() {
        return (
            <Form.Control as="textarea"
                rows={1}
                ref={ref => this.multilineTextarea = ref}
                {...this.props}
                onChange={(value) => {
                    this.props.onChange(value);
                    this.changeTextarea();
                }}
            >
                {this.props.children}
            </Form.Control>
        );
    }
}
