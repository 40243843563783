import React from "react";
import PropTypes from "prop-types";
import TestimonialContent from "../../models/testimonial/TestimonialContent";
import TestimonialRepository from "../../repository/TestimonialRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import TestimonialTextFormComponent from "./forms/TestimonialTextFormComponent";
import Module from "../../models/module/Module";
import ModuleRepository from "../../repository/ModuleRepository";
import { LoaderComponent } from "../basics/layout";
import Testimonial from "../../models/testimonial/Testimonial";

class TestimonialAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new TestimonialContent(),
            loading: true,
            testimonials: [],
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings(),
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                this.setState({
                    loading: false,
                    settings: settings
                });
            })
    }

    getBeforeSubmit = (element) => TestimonialTextFormComponent.fields_image_before_submit(element);


    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="testimonial"
                validationSchema={Testimonial.schema(this.state.settings)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.state.isEdit) {
                                TestimonialRepository.updateTestimonial(values.id, this.props.match.params.language, values.settings, values.name)
                                    .then(response => {
                                        FormToastComponent.successTrans("Testimonials", "Default.saved");
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(error => {
                                        FormHelper.handleSubmitError(this, error)
                                    })
                            } else {
                                TestimonialRepository.create(values)
                                    .then(response => {
                                        FormToastComponent.successTrans("Testimonials", "Default.saved");
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(error => {
                                        FormHelper.handleSubmitError(this, error)
                                    })
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={TestimonialTextFormComponent.fields(this.state.settings, 0)}
            />
        );
    }
}

TestimonialAddFormComponent.propTypes = {
    model: PropTypes.object.isRequired,
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired
};
export default withRouter(TestimonialAddFormComponent);
