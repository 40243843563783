import React from "react";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormNumberError from "../../errors/FormNumberError";

class WebsiteNumberMaxRequiredError extends React.Component{
    render() {
        if (this.props.error === "CMS.Website.Form.sftp.port.max") {
            return <FormMaxError field_name={this.props.error.slice(0, -4)} max={10}/>
        } else if (this.props.error === "CMS.Website.Form.sftp.port.required") {
            return <FormRequiredError field_name={this.props.error.slice(0, -9)} />
        } else {
            return <FormNumberError field_name={"CMS.Website.Form.sftp.port"}/>
        }
    }
}

export default WebsiteNumberMaxRequiredError;
