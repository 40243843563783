import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import TableEditActionComponent from "../basics/table/TableEditActionComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import TestimonialOverviewTreeComponent from "./TestimonialOverviewTreeComponent";
import TestimonialRepository from "../../repository/TestimonialRepository";
import FormToastComponent from "../basics/FormToastComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { FormattedMessage } from "react-intl";
import Application from "../../helpers/Application";
import { Link } from "react-router-dom";
import FormattedMessageString from "../basics/FormattedMessageString";
import moment from 'moment';
import LoaderComponent from "../basics/layout/LoaderComponent";

class TestimonialOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            type: this.props.type,
            website: null,
            isLoading: true,
            toggler: [],
            treeData: [],
            language: Application.tableLanguage()
        };

    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Testimonials.overview.table.name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.testimonial.contents[index];
                    if (content !== undefined) {
                        return content.title;
                    }

                    return null;
                }
            },
            {
                dataField: 'value',
                text: <FormattedMessageString id="CMS.Testimonials.overview.table.description" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.testimonial.contents[index];
                    if (content !== undefined && content.value) {
                        let value = JSON.parse(content.value);
                        return value.text ? value.text.replace(/(<([^>]+)>)/gi, "") : '';
                    }

                    return null;
                }
            },
            {
                dataField: 'updated',
                text: <FormattedMessageString id="CMS.Testimonials.overview.table.last_updated" />,
                isDummyField: true,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.testimonial.contents[index];
                    let contentsB = rowB.testimonial.contents[index];

                    if (order === 'asc') {
                        return Date.parse(contentsA.updated_at) - Date.parse(contentsB.updated_at)
                    } else if (order === 'desc') {
                        return Date.parse(contentsB.updated_at) - Date.parse(contentsA.updated_at)
                    }
                },
                formatter: (cellContent, row) => {
                    let contents = row.testimonial.contents[index];
                    if (contents === undefined) return null;

                    return (
                        <div className="updated_by">
                            {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                            {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    return <div className="actions">
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() ?
                                row.testimonial.active ?
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)} className={"active"}>
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)} className={"inactive"}>
                                        <FontAwesomeIcon icon={['far', 'times']} />
                                    </Link>
                                : null
                        }
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <TableEditActionComponent id={row.testimonial.id}
                                    custom={row.testimonial.contents[index] !== undefined ? '/' + row.testimonial.contents[index].language : '/'} />
                                : null
                        }
                        {
                            (CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR)) || CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ?
                                (!GeneralSettingsHelper.is() ?
                                    <TableDeleteActionComponent row={row} parent={this} /> : null)
                                : null
                        }
                    </div>
                }
            }
        ];
    }

    switch = (index) => {
        return (
            <div className="toggle-tree" data-toggle="buttons" data-index={index}>
                <button type="button" data-toggle="button" data-view-type={"table"} data-index={index}
                    onClick={this.toggle.bind(this)}
                    disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? null : 'disabled'}>
                    <FontAwesomeIcon icon={['far', 'th']} />
                </button>
                <button type="button" data-toggle="button" data-view-type={"tree"} data-index={index}
                    onClick={this.toggle.bind(this)}
                    disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? 'disabled' : ''}>
                    <FontAwesomeIcon icon={['far', 'list']} />
                </button>
            </div>
        );
    }

    toggleActive = (event, _row) => {
        event.preventDefault();

        TestimonialRepository.toggle(_row.testimonial.id)
            .then(response => {
                let newRows = [...this.state.rows];
                newRows = newRows.map((row) => {
                    if (row.testimonial.id === _row.testimonial.id) {
                        let testimonial = row.testimonial;
                        testimonial.active = response;
                        return {
                            ...row,
                            testimonial: testimonial
                        }
                    }
                    return row;
                })
                this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                    this.setState({ reload: false })
                });
            })
            .catch(error => {
                FormToastComponent.errorTrans('CMS.Testimonials.overview.toggle.failed')
            })
    }

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({
            reload: true
        }, () => {
            this.props.parent.setState({
                reload: false
            }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({
                    toggler: toggler,
                    language: this.state.language
                })
            })
        })
    }

    updateData = () => {
        TestimonialRepository.getRows()
            .then(response => {
                this.props.parent.setState({
                    rows: response
                })
            })
    }

    table = (index) => {
        return (
            <div className={"custom-data-table testimonials"}>
                <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={true}
                    title={this.props.title_id} type={this.props.type}
                    custom={CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ? this.switch(index) : undefined}
                    subRows={false}
                    sort={{
                        dataField: 'title',
                        order: 'asc'
                    }} />
                <DeleteModalComponent parent={this} />
            </div>
        )
    }

    tree = (index) => {
        return (
            <div className={'custom-data-table testimonials'}>
                <div className="table-wrapper">
                    <div className="table-header">
                        <FormattedMessage id="CMS.Module.overview.tree.title" values={{ module: this.props.title_id }}>{(value) => <p>{value}</p>}</FormattedMessage>
                        {this.switch(index)}
                    </div>
                    <TestimonialOverviewTreeComponent data={this.state.rows} index={index} parent={this} />
                </div>
            </div>
        )
    }
    
    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-testimonials"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {
                            this.state.website.languages.map((language, index) => {
                                return (
                                    <ListGroup.Item action href={"#" + language.language} key={index}
                                        onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                        {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                                    </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                    <Tab.Content>
                        {
                            this.state.website.languages.map((language, index) => {

                                return <Tab.Pane eventKey={"#" + language.language} key={index}>
                                    {
                                        this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ?
                                            this.tree(index)
                                            :
                                            this.table(index)
                                    }
                                </Tab.Pane>

                            })
                        }
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

}

export default TestimonialOverviewTableComponent;