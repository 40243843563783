import React from "react";
import { Formik } from "formik";
import Media from "../../models/media/Media";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHelper from "../../helpers/FormHelper";
import WebsiteRepository from "../../repository/WebsiteRepository";
import MediaRepository from "../../repository/MediaRepository";
import MediaFormNameError from "./errors/MediaFormNameError";
import FormToastComponent from "../basics/FormToastComponent";
import MediaTableRow from "../../models/media/MediaTableRow";
import MediaAddUploadingComponent from "./MediaAddUploadingComponent";
import Website from "../../models/website/Website";
import MediaHelper from "../../helpers/MediaHelper";
import MediaFormHelper from "./errors/MediaFormHelper";
import LoaderComponent from "../basics/layout/LoaderComponent";

class MediaAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            media: new Media(),
            settings: props.customSettings ? props.customSettings : null,
            loading: true,
            showProgress: false,
            uploadProgress: 0,
            form_errors: []
        };

        this.form = null;
    }

    componentDidMount() {
        if (this.state.settings !== null) {
            this.setState({ loading: false });
        } else {
            MediaRepository.getSpecificSettings("media")
                .then(response => {
                    if (response.data.settings !== undefined) {
                        this.setState({ settings: response.data.settings, loading: false })
                    } else {
                        FormToastComponent.errorTrans('CMS.Media.Album.settings.not.found');
                    }
                })
                .catch(error => console.error(error))
        }
    }

    externalRequirements = () => {
        let external = "";

        let externals = this.props.customSettings !== undefined && this.props.customSettings.external !== undefined ? this.props.customSettings.external : this.state.settings.external;
        externals.forEach((type, index, array) => {
            external += type.charAt(0).toUpperCase() + type.slice(1);
            if (index + 1 !== array.length) {
                external += ", ";
            }
        })
        return external;
    }

    render() {
        if (this.state.loading) return <LoaderComponent />

        if (this.state.showProgress) {
            return (
                <div className={"custom-form media"}>
                    <MediaAddUploadingComponent parent_ref={this} />
                </div>
            )
        }

        return (
            <div className={"custom-form media"}>
                <Formik
                    enableReinitialize
                    validationSchema={Media.schema}
                    initialValues={this.state.media}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, { resetForm }) => {
                        if (this.props.customUpload !== undefined) {
                            this.props.customUpload(values);
                        } else {
                            if (values.media_url !== undefined) {
                                WebsiteRepository.uploadExternalMedia(values.name, values.media_url)
                                    .then(response => {
                                        if (this.props.table_ref && response.data.success) {
                                            let rows = this.props.table_ref.state ? this.props.table_ref.state.rows : [];
                                            let media = response.data.external_media;
                                            let id = rows.length > 0 ? rows[rows.length - 1].id + 1 : 1;
                                            rows.push(new MediaTableRow(id, media.id, media.name, media.url, null, media.path, media.url, media.type, media.updated_at, true));

                                            this.props.table_ref.setState({ rows: rows }, () => {
                                                this.props.parent_ref.setState({ showFormModal: false }, () => {
                                                    this.props.table_ref.reload();
                                                })
                                            });
                                            FormToastComponent.successTrans('Media', 'Default.saved');
                                        } else {
                                            let rows = [];
                                            let media = response.data.external_media;
                                            rows.push(new MediaTableRow(1, media.id, media.name, media.url, null, media.path, media.url, media.type, media.updated_at, true));
                                            this.props.parent_ref.setState({
                                                rows: rows
                                            }, () => {
                                                this.props.parent_ref.setState({
                                                    showFormModal: false
                                                }, () => {
                                                    this.props.parent_ref.hardReload();
                                                })
                                            });
                                        }
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error))
                            } else {
                                const options = {
                                    onUploadProgress: (progressEvent) => {
                                        const { loaded, total } = progressEvent;
                                        let percent = Math.floor((loaded * 100) / total)
                                        this.setState({
                                            uploadProgress: percent
                                        })
                                    }
                                }
                                this.setState({ showProgress: true }, () =>
                                    WebsiteRepository.uploadMedia(values.name, values.media, options)
                                        .then(response => {
                                            if (this.props.table_ref && response.data.success) {
                                                let rows = this.props.table_ref.state ? this.props.table_ref.state.rows : [];
                                                let media = response.data.media;
                                                let id = rows.length > 0 ? rows[rows.length - 1].id + 1 : 1;
                                                rows.push(new MediaTableRow(id, media.id, media.name, media.file_name, media.size, media.path, media.url, media.mime_type, media.updated_at));

                                                this.props.table_ref.setState({ rows: rows }, () => {
                                                    this.props.parent_ref.setState({ showFormModal: false }, () =>
                                                        this.setState({ showProgress: false, uploadProgress: 0 }, () =>
                                                            this.props.table_ref.reload()));
                                                });
                                                FormToastComponent.successTrans('Media', 'Default.saved');
                                            } else {
                                                if (response.data.success) {
                                                    let rows = [];
                                                    let media = response.data.media;
                                                    rows.push(new MediaTableRow(1, media.id, media.name, media.file_name, media.size, media.path, media.url, media.mime_type, media.updated_at));
                                                    this.props.parent_ref.setState({
                                                        rows: rows
                                                    }, () => {
                                                        this.props.parent_ref.setState({
                                                            showFormModal: false
                                                        }, () => {
                                                            this.props.parent_ref.hardReload();
                                                        })
                                                    });
                                                    FormToastComponent.successTrans('Media', 'Default.saved');
                                                } else {
                                                    setTimeout(() => {
                                                        FormToastComponent.errorTrans("CMS.Form.ref.not.found");
                                                        this.setState({ showProgress: false, uploadProgress: 0 })
                                                    }, 500);
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            if (error.message != null && error.message === "Network Error") {
                                                FormToastComponent.errorTrans("CMS.Media.Form.upload.size.error");
                                            } else {
                                                FormHelper.handleSubmitError(this, error);
                                            }

                                            setTimeout(() => this.setState({ showProgress: false, uploadProgress: 0, media: values }, () => this.props.parent_ref.setState({ customHeaderText: null })), 500);
                                        })
                                );
                            }
                        }
                    }}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <FormattedMessage id={"CMS.Media.Form.name"}>
                                        {
                                            (value) => <Form.Label>{value}</Form.Label>
                                        }
                                    </FormattedMessage>
                                    <FormattedMessage id={"CMS.Media.Form.name.placeholder"}>
                                        {
                                            (value) => <Form.Control type="text" name="name"
                                                value={values.name || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                                onBlur={handleBlur}
                                                placeholder={value} />
                                        }
                                    </FormattedMessage>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && touched.name && (
                                            <MediaFormNameError error={errors.name} />
                                        )}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Dropzone
                                        multiple={false}
                                        accept={this.props.customAcceptedMimeTypes !== undefined ? this.props.customAcceptedMimeTypes() : MediaHelper.acceptedMimeTypes(this.state.settings)}
                                        noKeyboard
                                        maxSize={this.state.settings.max_size}
                                        onDrop={(acceptedFiles, rejectedFiles) => {
                                            if (acceptedFiles.length > 0) {
                                                let media = this.state.media;
                                                media.media = acceptedFiles[0];
                                                this.setState({ media: media });

                                                MediaHelper.handleDropAccepted(media.media, { minWidth: this.state.settings.min_width, minHeight: this.state.settings.min_height }, (result) => values.media = acceptedFiles[0]);
                                            }

                                            MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);

                                            if (acceptedFiles.length <= 0 && rejectedFiles.length <= 0) {
                                                FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid");
                                            }
                                        }}>
                                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                                            return (
                                                <>
                                                    <section
                                                        className={(errors.media && touched.media) || this.state.form_errors.file ? "media-drag-drop invalid" : "media-drag-drop"} {...getRootProps()}>

                                                        <div className={"media-drag-drop-inner"}>

                                                            <div className={"media-drag-drop-inner-row"}>
                                                                <div className={"media-drag-drop-icon"}>
                                                                    <div className={"icon-wrapper"}>
                                                                        <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                                                    </div>
                                                                </div>
                                                                <div className={"media-drag-drop-text"}>

                                                                    {
                                                                        this.state.media.media !== undefined ?
                                                                            <h2>{this.state.media.media.name}</h2>
                                                                            :
                                                                            <>
                                                                                <FormattedMessage id={"CMS.Media.Form.media_dragdrop.title"}>
                                                                                    {(value) => <h1>{value}</h1>}
                                                                                </FormattedMessage>
                                                                                <FormattedMessage id={"CMS.Media.Form.video_dragdrop.title.sub"}>
                                                                                    {(value) => <h2>{value}</h2>}
                                                                                </FormattedMessage>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={"media-drag-drop-requirements"}>
                                                                <p className="mt-1">{MediaHelper.assembleRequirements(this.state.settings, null, true)}</p>
                                                            </div>
                                                            <Form.Control {...getInputProps()} />
                                                        </div>
                                                    </section>
                                                </>
                                            );
                                        }}
                                    </Dropzone>
                                    {this.state.form_errors.file && (
                                        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                            <p>{this.state.form_errors.file.join(', ')}</p>
                                        </Form.Control.Feedback>
                                    )}
                                    {/*errors.media && touched.media && (
                                    <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                        <FormRequiredError field_name={errors.media} />
                                    </Form.Control.Feedback>
                                )*/}

                                </Form.Group>
                                <div className={"media-or"}>
                                    <FormattedMessage id={"CMS.Media.Form.or"}>
                                        {
                                            (value) => <p>{value}</p>
                                        }
                                    </FormattedMessage>
                                </div>
                                <Form.Group>
                                    <div className={"media-url-wrapper"}>
                                        <FormattedMessage id={"CMS.Media.Form.url"}>
                                            {
                                                (value) => <h1>{value}</h1>
                                            }
                                        </FormattedMessage>
                                        {/*<FormattedMessage id={"CMS.Media.Form.url.placeholder"}>*/}
                                        {/*    {*/}
                                        {/*        (value) => <Form.Control*/}
                                        {/*            name={"media_url"}*/}
                                        {/*            value={values.media_url || ''}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            isInvalid={!!errors.media_url || !!this.state.form_errors.type}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            placeholder={value}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*</FormattedMessage>*/}
                                        <FormattedMessage id={"CMS.Media.Form.url.placeholder"}>
                                            {
                                                (value) =>

                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="url-addon">
                                                                https://
                                                            </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control aria-describedby="url-addon"
                                                            type="text" name="media_url"
                                                            value={this.state.media.media_url || ''}
                                                            onChange={(event) => {
                                                                values.media_url = Website.removeHttpOrHttpsInUrl(event.target.value);
                                                                let media = this.state.media;
                                                                media.media_url = values.media_url;
                                                                this.setState({
                                                                    media: media
                                                                });
                                                                handleChange(event);
                                                            }}
                                                            isInvalid={!!errors.media_url || !!this.state.form_errors.type}
                                                            onBlur={handleBlur}
                                                            placeholder={value} />
                                                        {/*<Form.Control.Feedback type="invalid">
                                                        {errors.url && touched.url && (
                                                            <WebsiteFormWebsiteError error={errors.url} />)}
                                                        {this.state.form_errors !== [] && this.state.form_errors.domain && (
                                                            <p>{this.state.form_errors.domain}</p>
                                                        )}
                                                        </Form.Control.Feedback>*/}
                                                    </InputGroup>

                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={"CMS.Media.Form.url.placeholder.subtitle"} values={{ external: this.externalRequirements() }}>
                                            {(value) => <p>{value}</p>}
                                        </FormattedMessage>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <div className="actions">
                                        <FormattedMessage id={"CMS.Media.Form.add"}>
                                            {
                                                (value) => <Button type={"submit"}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        this.form.handleSubmit();
                                                        MediaFormHelper.submitFormErrorToast(this)
                                                    }}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={"Default.cancel"}>
                                            {
                                                (value) => <Button variant={"secondary"}
                                                    onClick={() => this.props.parent_ref.setState({
                                                        showFormModal: false,
                                                        showEditModal: false
                                                    })}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                    </div>
                                </Form.Group>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        );
    }
}

export default MediaAddFormComponent;