import React from 'react';
import './styles/App.scss';
import './Responsive.css';
import AuthPage from "./pages/AuthPage";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import CMSPage from "./pages/CMSPage";
import User from "./models/user/User";
import LoadingScreenComponent from "./components/basics/LoadingScreenComponent";
import { ToastContainer } from "react-toastify";
import OneTimePassword2faFragment from "./fragments/authentication/2fa/OneTimePassword2faFragment";
import 'react-sortable-tree/style.css';
import PromiseBoundary from './components/basics/PromiseBoundary';
import WebsiteRepository from './repository/WebsiteRepository';
import Application from './helpers/Application';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import MetaData from './fragments/layout/MetaData';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            user: User.get(),
            authError: null,
            loginRedirectMessage: null,
            oneTimePasswordRedirect: false
        }
    }

    componentDidMount() {
        this.loadAxiosInterceptors();
        this.loadFontAwesome();
        this.loadNewRelic();
        window.addEventListener('storage', this.handleStorageChange);

        let promises = [];
        if (this.state.user.email != null && this.state.user.token != null) {
            promises.push(WebsiteRepository.getSettings(0));
            //promises.push(this.state.user.getDetails());
        }

        Promise.all(promises)
            .then(response => {
                let websiteSettingResponse = response[0];
                if (websiteSettingResponse) {
                    Application.setSettings(websiteSettingResponse.default);
                }

                /*let userDetailResponse = response[1];
                if (userDetailResponse['use_one_time_password']) {
                    this.setState({
                        oneTimePasswordRedirect: true,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        user: userDetailResponse,
                        isLoading: false
                    })
                }*/

                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                if (error === undefined) {
                    error = { data: "Database error" }
                } else if (error.data && error.data['use_one_time_password']) {
                    this.setState({ oneTimePasswordRedirect: true, isLoading: false })
                }

                this.setState({ authError: error }, () =>
                    this.setState({ loginRedirectMessage: this.state.authError.data }, () =>
                        this.setState({ isLoading: false })
                    )
                );
            });
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    render() {
        library.add(fab);
        library.add(fas);
        library.add(far);
        library.add(fal);

        if (this.state.isLoading) return <LoadingScreenComponent />;

        if (this.state.oneTimePasswordRedirect) return <OneTimePassword2faFragment />;

        return (
            <div className="App">
                <MetaData />
                <Router>
                    <PromiseBoundary>
                        <Switch>
                            <Route path="/auth" component={AuthPage} />
                            <Route path="/" render={({ match }) => (
                                this.state.user.email != null && this.state.user.token != null && this.state.authError == null ?
                                    <CMSPage user={this.state.user} /> :
                                    <Redirect to={{ pathname: '/auth/login', state: { error: this.state.loginRedirectMessage } }} />
                            )} />
                        </Switch>
                        <ToastContainer />
                    </PromiseBoundary>
                </Router>
            </div>
        );
    }

    loadFontAwesome = () => {
        let link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "/assets/css/fontawesome.6.1.2.all.css"; //"https://use.fontawesome.com/releases/v6.1.2/css/all.css";
        document.head.appendChild(link);
    }

    loadNewRelic = () => {
        if (['demo', 'live'].indexOf(process.env.REACT_APP_ENV.trim()) < 0) return;

        let script = document.createElement("script");
        script.src = "/assets/js/newrelic-" + process.env.REACT_APP_ENV.trim() + ".js";
        document.head.appendChild(script);
    }

    loadAxiosInterceptors = () => axios.interceptors.response.use(response => {
        return response;
    }, (error) => {
        if (401 === error?.response?.status) {
            window.location.href = '/auth/login';
        } else {
            return Promise.reject(error);
        }
    });

    handleStorageChange = e => {
        if (e.storageArea !== localStorage) return;

        if (e.key === 'website.id') {
            window.location.href = '/';
        }
    }
}

export default injectIntl(App);