import * as Yup from "yup"
import "yup-phone-lite";
import MapContent from "./MapContent";

export default class Map {
    id;
    website;
    active;
    latitude;
    longitude;
    contents = [];

    static schema_add = Yup.object().shape({
        title: Yup.string().max(255, 'CMS.Map.Content.Form.title|255|max').nullable(),
        // street: Yup.string().required('CMS.Map.Content.Form.street').matches(/^[a-zA-Z0-9'-.säáàÄÁÀëéèËÉÈüúùÜÚÙïíìÏÍÌöóòÖÓÒ ]*$/, 'CMS.Map.Content.Form.street|match').max(100, 'CMS.Map.Content.Form.street|100|max'),
        street: Yup.string().required('CMS.Map.Content.Form.street').matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Map.Content.Form.street|match').max(100, 'CMS.Map.Content.Form.street|100|max'),
        number: Yup.string().required('CMS.Map.Content.Form.number').matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'CMS.Map.Content.Form.number|match', { excludeEmptyString: true }).max(100, 'CMS.Map.Content.Form.number|50|max'),
        box: Yup.string().max(50, 'CMS.Map.Content.Form.box|50|max').matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'CMS.Map.Content.Form.box|match', { excludeEmptyString: true }).nullable(),
        postal: Yup.string().required('CMS.Map.Content.Form.postal').max(50, 'CMS.Map.Content.Form.postal|50|max').matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'CMS.Map.Content.Form.postal|match', { excludeEmptyString: true }),
        city: Yup.string().required('CMS.Map.Content.Form.city').max(100, 'CMS.Map.Content.Form.city|100|max').matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'CMS.Map.Content.Form.city|match', { excludeEmptyString: true }),
        //city: Yup.string().required('CMS.Map.Content.Form.city').max(100, 'CMS.Map.Content.Form.city|100|max').matches(/^[A-z0-9\u00C0-\u00ff' -]+$/, 'CMS.Map.Content.Form.city|match', { excludeEmptyString: true }),
        country: Yup.string().required('CMS.Map.Content.Form.country'),

        remark: Yup.string().max(255, 'CMS.Map.Content.Form.remark|255|max').nullable(),

        phone: Yup.string().when('phone', {
            is: (value) => value?.length > 0,
            then: Yup.string().phone('BE', 'CMS.Map.Content.Form.phone|phone'),
            otherwise: Yup.string()
        }).nullable(),
        //phone: Yup.string().nullable().phone('BE', 'CMS.Map.Content.Form.phone|phone'),

        fax: Yup.string().when('fax', {
            is: (value) => value?.length > 0,
            then: Yup.string().phone('BE', 'CMS.Map.Content.Form.fax|phone'),
            otherwise: Yup.string()
        }).nullable(),
        //fax: Yup.string().phone('BE', 'CMS.Map.Content.Form.fax|phone').nullable(),

        mobile: Yup.string().when('mobile', {
            is: (value) => value?.length > 0,
            then: Yup.string().phone('BE', 'CMS.Map.Content.Form.mobile|phone'),
            otherwise: Yup.string()
        }).nullable(),
        // mobile: Yup.string().phone('BE', 'CMS.Map.Content.Form.mobile|phone').nullable(),

        email: Yup.string().email('CMS.Map.Content.Form.email').nullable(),
    }, [['phone', 'phone'], ['fax', 'fax'], ['mobile', 'mobile']]);

    static fromJson = (json) => {
        let map = new Map();

        map.id = json.id;
        map.website = json.website;
        map.active = json.active;

        map.latitude = json.latitude;
        map.longitude = json.longitude;

        map.contents = json.contents.map(content => MapContent.fromJson(content));

        return map;
    }
}
