import React from "react";
import { injectIntl } from 'react-intl';
import StandardTableComponent from "../../basics/table/StandardTableComponent";
import DeleteModalComponent from "../../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../../basics/table/TableDeleteActionComponent";
import CheckPermissions from "../../../helpers/CheckPermissions";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";
import FormEntryData from "./FormEntryData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import InputHelper from "../../../helpers/InputHelper";
import { Button } from "react-bootstrap";
import FormRepository from "../../../repository/FormRepository";
import EmptyTablePlaceholderComponent from "../../basics/EmptyTablePlaceholderComponent";
import moment from "moment";

class FormEntryOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            fields: this.fieldsSorted(),
            data: this.props.rows,
            checked: []
        };
    }

    componentDidMount = () => {
        this.renderExport();
    }

    columns = () => {
        return [
            {
                dataField: 'checkbox',
                text: <input type="checkbox" className="form-control form-control-xs" checked={this.state.data.entries.length > 0 && this.state.data.entries.length === this.state.checked.length} onChange={this.toggleAll} />,
                headerStyle: (colum, colIndex) => {
                    return { width: '75px', textAlign: 'center' };
                },
                formatter: (cellContent, row) => {
                    return <input type="checkbox" className="form-control form-control-xs" checked={this.state.checked.indexOf(parseInt(row.id)) > -1} data-id={row.id} onChange={this.toggle} />
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => { /** Needed for checkbox toggle */ }
                }
            },
            {
                dataField: 'data',
                text: <FormattedMessageString id="CMS.Form.Entry.overview.table.data" />,
                isDummyField: true,
                formatter: (cellContent, row) => <FormEntryData entry={row} fields={this.state.fields} />
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    return <div className="actions">
                        {(CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR)) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                    </div>
                }
            }
        ];
    }

    render() {
        if (this.state.data.entries.length <= 0) return <EmptyTablePlaceholderComponent typeId={this.props.title_id} />

        return (
            <div className="tab-tables">
                <div className="custom-data-table forms">
                    <StandardTableComponent keyField="id" data={this.state.data.entries} columns={this.columns()} search={false}
                        title={this.props.title_id} type={this.props.type} subRows={false}
                        sort={{ dataField: 'title', order: 'asc' }}
                    />
                </div>

                {this.renderBatchDeleteSection()}

                <DeleteModalComponent ref={r => this._deleteRef = r} parent={this} customDeleteRowFunction={this.deleteEntries} />
            </div>
        );
    }

    renderBatchDeleteSection = () => (
        <div className="custom-data-table forms text-center mt-4 float-right w-25 p-3">
            <FormattedMessage key="number" id="CMS.Form.Entry.overview.checked" values={{ number: this.state.checked.length }}>
                {value => <strong className="mr-2">{value}</strong>}
            </FormattedMessage>

            <FormattedMessage id="Default.delete">
                {value => (
                    <Button type="button" variant="outline-danger" className="ml-2" disabled={this.state.checked.length <= 0}
                        onClick={(event) => DeleteModalComponent.openDeleteModal(event, {}, this)}
                    >
                        <FontAwesomeIcon className="mr-2" icon={['fas', 'trash']} />{value}
                    </Button>
                )}
            </FormattedMessage>
        </div>
    );

    deleteEntries = (event, _row) => {
        event.preventDefault();

        let ids = _row.id ? [_row.id] : this.state.checked;

        FormRepository.deleteEntries(encodeURIComponent(JSON.stringify(ids)))
            .then(() => {
                let form = this.state.data;
                form.entries = form.entries.filter(row => ids.indexOf(row.id) < 0);

                this.setState({ form: form, checked: [] }, () => this._deleteRef ? this._deleteRef.closeModal() : null);
            });
    }

    toggleAll = (e) => {
        if (e.target.checked) {
            this.setState({ checked: this.state.data.entries.map(entry => entry.id) });
        } else {
            this.setState({ checked: [] });
        }
    }

    toggle = (e) => {
        let id = parseInt(e.target.dataset.id);
        let checked = this.state.checked;

        if (checked.indexOf(id) > -1) {
            checked.splice(checked.indexOf(id), 1);
        } else {
            checked.push(id);
        }
        this.setState({ checked: checked });
    }

    renderExport = () => {
        if (this.state.data.entries.length <= 0) return;

        let exportData = this.exportData();

        this.props.parent.setState({
            actionsEnd: [
                ...this.props.parent.state.actionsEnd,
                ...[
                    <FormattedMessage key="1" id="CMS.Form.Entry.overview.export">
                        {value =>
                            <CSVLink className="btn btn-primary" data={exportData.data} headers={exportData.headers} filename={InputHelper.transformToLowerCaseAndUnderscore(this.state.data.title) + '_export.csv'} separator=";">
                                <FontAwesomeIcon icon={['fa', 'download']} className="mr-1" />
                                <span>{value}</span>
                            </CSVLink>
                        }
                    </FormattedMessage>
                ]
            ]
        });
    }

    exportData = () => {
        let headers = this.state.fields;
        headers.unshift({ key: '0', label: this.props.intl.formatMessage({ id: 'CMS.Form.Entry.export.created_at' }) }); // IMPORTANT: key needs to be string

        let fields = [];
        this.state.data.form.groups.forEach((group, i) => {
            group.fields.forEach((field, j) => {
                let key = i + '_' + j + '_' + field.id;
                fields[field.id] = key;
            });
        });
        fields.consent = 'consent';

        let records = [];
        this.state.data.entries.forEach(entry => {
            let record = {};

            record['0'] = moment(entry.createdAt).format('DD/MM/YYYY HH:mm:ss');

            entry.data.forEach(item => {
                let key = fields[item.field];
                if (key) record[key] = this.sanitize(item.value);
            });
            records.push(record);
        });

        return { headers: Object.values(headers), data: records };
    }

    fieldsSorted = () => {
        let language = localStorage.getItem('language');

        let fields = [];
        this.props.rows.form.groups.forEach((group, i) => {
            group.fields.forEach((field, j) => {
                let content = field.contents.filter(content => content.language === language)[0];
                if (!content) content = field.contents[0];
                fields.push({ key: i + '_' + j + '_' + field.id, id: field.id, label: this.sanitize(content.label) });
            });
        });

        if (this.props.rows.entries?.length) {
            let consent = this.props.rows.entries[0].data.filter(record => record.type === 'consent')[0];
            fields.push({ key: 'consent', id: 'consent', label: consent.label });
        }

        return fields;
    }

    sanitize = (value) => {
        if (!value) return value;

        value = '' + value;
        value = value.replace(/"/g, '\'');
        value = value.replace(/(?:\r\n|\r|\n)/g, ' ');

        return value;
    }
}

export default injectIntl(FormEntryOverviewTableComponent);