import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormArrayMaxError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {
                    (field) =>
                        <FormattedMessage id={"CMS.Form.array.max"}
                            values={{ field: field, max: this.props.max }}>
                            {
                                (message) => <p>{message}</p>
                            }
                        </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

FormArrayMaxError.propTypes = {
    field_name: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired
};
export default FormArrayMaxError