import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import WebsiteTableRow from "../models/website/WebsiteTableRow";
import UserTableRow from "../models/user/UserTableRow";
import GeneralSettingsHelper from "../helpers/GeneralSettingsHelper";

class CustomerRepository {

    static storeCustomer(values, id = null) {
        let data = {
            'email': values.email,
            'reseller': values.reseller,
            'customer_id': id,
            'language': values.language,
            'contact_firstname': values.contact.first_name,
            'contact_lastname': values.contact.last_name,
            'contact_company_name': values.contact.company_name,
            'contact_tax': values.contact.vat_number,
            'contact_phone': values.contact.phone,
            'contact_mobile': values.contact.mobile,
            'contact_alternative_email': values.contact.alternative_email,
            'address_street': values.address.street,
            'address_housenumber': values.address.number,
            'address_postal': values.address.zip,
            'address_city': values.address.city,
            'address_country': values.address.country,
        };
        if (id == null) {
            delete data.customer_id;
        }

        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/customer/store',
                data,
                {
                    headers: CustomHeaders.get()
                }
            ).then(response => {
                    if (response.data.success !== null) {
                        resolve(true);
                    }
                }
            ).catch(error => reject(error.response));
        })
    }

    static deleteCustomer(user) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/customer/trash?customer=' + user,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static getCustomerRows() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/customer/all',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    let data = response['data'];
                    let rows = [];
                    data.forEach((customer, key) => {
                        let websites = [];
                        if (customer.websites !== null) {
                            customer.websites.forEach((website, index, arr) => {
                                if (website !== null && website['languages'] !== null && website.id !== GeneralSettingsHelper.is()) {
                                    let languages = website['languages'].map(language => language.language);
                                    websites.push(new WebsiteTableRow(website.id, website.name, website.domain, languages, website.customer, website.active, website.api_key));
                                }
                            })
                        }
                        rows.push(new UserTableRow(
                            key,
                            customer.id,
                            customer.contact_info !== null ? customer.contact_info.firstname + " " + customer.contact_info.lastname : '',
                            customer.language,
                            customer.contact_info !== null ? customer.contact_info.company_name : '',
                            customer.email,
                            customer.last_login,
                            'customer',
                            websites
                        ));
                    });
                    resolve(rows)
                })
                .catch(error => {
                    reject(error.response)
                })
        });
    }

    static getCustomers() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/customer/all',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

    static getCustomer(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/customer/details',
                {
                    'customer': id
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    }
}

export default CustomerRepository;
