import React from "react";
import { Link, withRouter } from "react-router-dom";
import { DeleteModalComponent, StandardTableComponent, TableDeleteActionComponent } from "../basics/table";
import { CheckPermissions, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { EventRegistrationRepository, EventRepository } from "../../repository";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import LoaderComponent from "../basics/layout/LoaderComponent";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import EventRegistrationEditFormComponent from "./EventRegistrationEditFormComponent";
import { Button } from "react-bootstrap";
import FormToastComponent from "../basics/FormToastComponent";

class EventRegistrationOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            website: null,
            isLoading: false,
            showEditModal: false,
            editRegistration: null
        };
    }

    componentDidMount() {
        if (!this.state.settings.use_attendees) {
            this.props.history.push('/module/events/' + Module.get().id + '/overview')
        }
    }

    columns = () => {
        let columns = [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'created_at',
                text: <FormattedMessageString id="CMS.Event.registrations.overview.table.created_at" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let dateA = rowA.created_at;
                    let dateB = rowB.created_at;

                    return order === 'desc' ? Date.parse(dateB) - Date.parse(dateA) : Date.parse(dateA) - Date.parse(dateB)
                },
                isDummyField: true,
                formatter: (cellContent, row) => row.created_at ? row.created_at.format('DD/MM/YYYY HH:mm:ss') : null
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Event.registrations.overview.table.event" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.event.contents[0]?.title
            },
            {
                dataField: 'nr_days',
                text: <FormattedMessageString id="CMS.Event.registrations.edit.form.nr_days" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.nr_days
            }];

        if (this.state.settings.use_attendees) {
            columns.push(
                {
                    dataField: 'attendee',
                    text: <FormattedMessageString id="CMS.Event.registrations.overview.table.attendee" />,
                    sort: true,
                    isDummyField: true,
                    formatter: (cellContent, row) => row.attendee ? row.attendee.first_name + ' ' + row.attendee.last_name : '-'
                },
                {
                    dataField: 'status',
                    text: <FormattedMessageString id="CMS.Event.registrations.overview.table.status" />,
                    sort: true,
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        if (!row) return null;

                        switch (row.status) {
                            case 'paid':
                                return (
                                    <>
                                        <FormattedMessage id={'CMS.Event.registrations.overview.table.status.' + row.status}>
                                            {value => <div className="badge badge-success">
                                                <FontAwesomeIcon icon={['fa', 'fa-check']} />
                                                {value}
                                            </div>}
                                        </FormattedMessage>

                                        <FormattedMessage id="CMS.Event.registrations.overview.table.status.paid_at" values={{ date: row.paid_at.format('DD/MM/YYYY HH:mm:ss') }}>
                                            {value => <p>{value}</p>}
                                        </FormattedMessage>
                                    </>
                                );
                            case 'awaiting-payment':
                            default:
                                return (
                                    <>
                                        <FormattedMessage id={'CMS.Event.registrations.overview.table.status.' + row.status}>
                                            {value => <div className="badge badge-warning">
                                                <FontAwesomeIcon icon={['fa', 'fa-stopwatch']} />
                                                {value}
                                            </div>}
                                        </FormattedMessage>
                                        <br />
                                        <FormattedMessage id="CMS.Event.registrations.overview.table.status.set_paid">
                                            {value =>
                                                <Button variant="link" type="button" size="sm" onClick={() => this.setPaid(row)}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                    </>
                                )
                        }
                    }
                }
            );
        }

        columns.push({
            dataField: 'actions',
            isDummyField: true,
            text: <FormattedMessage id="CMS.Table.actions" />,
            hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
            sort: false,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
            formatter: (cellContent, row) => {
                return <div className="actions">
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                        <Link className="edit" to="#" onClick={() => this.setState({ showEditModal: true, editRegistration: row })}>
                            <FontAwesomeIcon icon={['fas', 'edit']} />
                        </Link>
                    )}
                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                        <TableDeleteActionComponent row={row} parent={this} />
                    )}
                </div>
            }
        })

        return columns;
    }

    updateData = () => EventRepository.all().then(response => this.props.parent.setState({ rows: response }));

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="custom-data-table event">
                <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns()} search={true}
                    title={this.props.title_id} type={this.props.type}
                    subRows={false}
                    sort={{ dataField: 'date_start', order: 'desc' }}
                />
                <DeleteModalComponent parent={this} />

                <ModalFormFragment title_id="CMS.Event.registrations.edit.title" show={this.state.showEditModal}
                    onHide={() => this.setState({ showEditModal: false, editRegistration: null })}
                >
                    <EventRegistrationEditFormComponent parent_ref={this} table_ref={this} registration={this.state.editRegistration} />
                </ModalFormFragment>
            </div>
        );
    }

    setPaid = (_row) => EventRegistrationRepository.quickUpdate({ id: _row.id, action: 'status', value: 'paid' })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => {
            this.setState({ reload: false })
        }))
        .catch(() => FormToastComponent.errorTrans('Default.error'));
}

export default withRouter(EventRegistrationOverviewTableComponent);