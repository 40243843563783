import React from "react";
import Contact from "../../models/user/Contact";
import Address from "../../models/user/Address";
import UserRepository from "../../repository/UserRepository";
import { Formik } from "formik";
import * as Yup from 'yup';
import { FormattedMessage } from "react-intl";
import { Button, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CountryHelper from "../../helpers/CountryHelper";
import User from "../../models/user/User";
import FormToastComponent from "../basics/FormToastComponent";
import UserRolesEnum from "../../enums/UserRolesEnum";
import Select from "react-select";
import FormHelper from "../../helpers/FormHelper";
import FormFirstnameError from "./errors/FormFirstnameError";
import FormLastnameError from "./errors/FormLastnameError";
import FormStreetError from "./errors/FormStreetError";
import FormStreetNumberError from "./errors/FormStreetNumberError";
import FormZipError from "./errors/FormZipError";
import FormRequiredError from "../errors/FormRequiredError";
import FormContactPhoneError from "./errors/FormContactPhoneError";
import FormEmailError from "../errors/FormEmailError";
import FormContactCompanyNameError from "./errors/FormContactCompanyNameError";
import FormCityError from "./errors/FormCityError";
import { FormContactEmailError } from "./errors";
import { FormWebsiteError } from "../errors";
import UserFormError from "./errors/UserFormError";

class ProfileInfoEditFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: new User(),
            contact: new Contact(),
            address: new Address('', '', '', '', 'BE'),
            role: this.props.role === "Reseller" ? UserRolesEnum.RESELLER : UserRolesEnum.WIZARD,
            form_errors: []
        };

        this._countries = CountryHelper.getCountries();
    }

    componentDidMount() {
        this.getUserInfo()
            .then(response => {
                let address = new Address('', '', '', '', 'BE');
                if (response[1] !== null && response[1]['status'] === 200) {
                    let data = response[1]['data']['address'];
                    if (data !== undefined && data !== null) {
                        address = new Address(data.street, data.housenumber, data.postal, data.city, data.country)
                        /*this.setState({
                            address: new Address(data.street, data.housenumber, data.postal, data.city, data.country)
                        })*/
                    }
                }

                let contact = new Contact();
                if (response[2] !== null && response[2]['status'] === 200) {
                    let data = response[2]['data']['contact_info'];
                    if (data !== undefined && data !== null) {
                        contact = new Contact(data.firstname, data.lastname, data.phone, data.mobile, data.alternative_email, data.url ? data.url : '', data.company_name, data.tax)
                        /*this.setState({
                            contact: new Contact(data.firstname, data.lastname, data.phone, data.mobile, data.alternative_email, data.company_name, data.tax)
                        })*/
                    }
                }

                contact.email = response[0].email

                this.setState({ user: response[0], address: address, contact: contact });
            })
            .catch(error => console.error(error))
    }

    getUserInfo = () => new Promise((resolve, reject) => Promise.all([
        UserRepository.get(),
        UserRepository.getAddress(),
        UserRepository.getContact()
    ])
        .then(response => resolve(response))
        .catch(error => reject(error)));

    storeUserInfoSubmit = (values, actions) => this.storeUserInfo(values, actions)
        .then(response => {
            if (response) {
                FormToastComponent.successTrans("Info", "Default.saved");
                this.props.handleParentUpdate(values.contact);
            }
        })
        .catch(error => FormHelper.handleSubmitError(this, error));

    storeUserInfo = (values, actions) => new Promise((resolve, reject) => {
        values.type = this.props.role;
        values.email = values.contact.email;
        values.language = values.user.language;

        UserRepository.storeUser(values, values.user.id)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });

    render() {
        return (
            <Formik
                enableReinitialize
                validationSchema={
                    this.state.role === UserRolesEnum.RESELLER ?
                        Yup.object({
                            address: Address.schema,
                            contact: Contact.reseller_schema
                        }) :
                        Yup.object({
                            address: null,
                            contact: Contact.user_schema
                        })
                }
                initialValues={{
                    user: this.state.user,
                    address: this.state.address,
                    contact: this.state.contact
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.storeUserInfoSubmit}
                ref={ref => this.form = ref}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            {this.state.role === UserRolesEnum.RESELLER && (
                                <Card className={"green cms"}>
                                    <Card.Header>
                                        <FormattedMessage id={"CMS.Profile.address.form"}>
                                            {(value) => <h1>{value}</h1>}
                                        </FormattedMessage>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group as={Row} controlId="streetNameId">
                                            <FormattedMessage id="CMS.Profile.address.form.street">
                                                {value => <Form.Label column sm="5">{value}</Form.Label>}
                                            </FormattedMessage>
                                            <Col sm="7">
                                                <Form.Control type="text" name="address.street"
                                                    value={values.address.street || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address && !!errors.address.street}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address && touched.address && (<FormStreetError error={errors.address.street} />)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="numberId">
                                            <FormattedMessage id="CMS.Profile.address.form.number">
                                                {value => <Form.Label column sm="5">{value}</Form.Label>}
                                            </FormattedMessage>
                                            <Col sm="7">
                                                <Form.Control type="text" name="address.number"
                                                    value={values.address.number || null}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address && !!errors.address.number}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address && touched.address && (<FormStreetNumberError error={errors.address.number} />)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="zipId">
                                            <FormattedMessage id="CMS.Profile.address.form.zip">
                                                {value => <Form.Label column sm="5">{value}</Form.Label>}
                                            </FormattedMessage>
                                            <Col sm="7">
                                                <Form.Control type="text" name="address.zip"
                                                    value={values.address.zip || ''}
                                                    onChange={handleChange}
                                                    isInvalid={(!!errors.address && !!errors.address.zip) || this.state.form_errors.postal}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address && touched.address && (<FormZipError error={errors.address.zip} />)}
                                                    {this.state.form_errors.postal && (<p>{this.state.form_errors.postal}</p>)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="townshipId">
                                            <FormattedMessage id="CMS.Profile.address.form.city">
                                                {value => <Form.Label column sm="5"> {value} </Form.Label>}
                                            </FormattedMessage>
                                            <Col sm="7">
                                                <Form.Control type="text" name="address.city"
                                                    value={values.address.city || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address && !!errors.address.city}
                                                    onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address && touched.address && (<FormCityError error={errors.address.city} />)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="countryId">
                                            <FormattedMessage id="CMS.Profile.address.form.country">
                                                {value => <Form.Label column sm="5"> {value}</Form.Label>}
                                            </FormattedMessage>
                                            <Col sm="7">
                                                <Select
                                                    value={this._countries.find(({ value }) => value + "" === values.address.country)}
                                                    name="address.country"
                                                    onChange={event => {
                                                        handleChange(event);
                                                        let address = this.state.address;
                                                        address.country = event.value;
                                                        this.setState({
                                                            address: address
                                                        })
                                                    }}
                                                    isInvalid={!!errors.address && !!errors.address.country}
                                                    onBlur={handleBlur}
                                                    options={this._countries}
                                                    className={this.state.countryInputFocused === true ? 'react-select-container react-select-container-fix' : 'react-select-container'}
                                                    classNamePrefix="react-select"
                                                    onFocus={() => this.setState({ countryInputFocused: true })}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address && touched.address && (<FormRequiredError field_name={"CMS.Profile." + errors.address.country} />)}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            )}

                            <Card className={"green cms"}>
                                <Card.Header>
                                    <FormattedMessage id={"CMS.Profile.contact.form"}>
                                        {(value) => <h1>{value}</h1>}
                                    </FormattedMessage>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group as={Row} controlId="firstNameId">
                                        <FormattedMessage id="CMS.User.Form.firstname">
                                            {value => <Form.Label column sm="5">{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Col sm="7">
                                            <Form.Control type="text" name="contact.first_name"
                                                value={values.contact.first_name || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors.contact && !!errors.contact.first_name}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact && touched.contact && (<FormFirstnameError error={errors.contact.first_name} />)}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="lastNameId">
                                        <FormattedMessage id="CMS.User.Form.lastname">
                                            {value => <Form.Label column sm="5">{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Col sm="7">
                                            <Form.Control type="text" name="contact.last_name"
                                                value={values.contact.last_name || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors.contact && !!errors.contact.last_name}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact && touched.contact && (<FormLastnameError error={errors.contact.last_name} />)}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="phoneId">
                                        <FormattedMessage id="CMS.Profile.contact.form.phone">
                                            {value => <Form.Label column sm="5">{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Col sm="7">
                                            <FormattedMessage id={"CMS.Profile.contact.form.phone.placeholder"}>
                                                {placeholder => (
                                                    <Form.Control type="text" name="contact.phone"
                                                        value={values.contact.phone || ''}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                        isInvalid={!!errors.contact && !!errors.contact.phone}
                                                        placeholder={placeholder}
                                                    />
                                                )}
                                            </FormattedMessage>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact && touched.contact && (<FormContactPhoneError error={errors.contact.phone} />)}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="gsmId">
                                        <FormattedMessage id="CMS.Profile.contact.form.mobile">
                                            {value => <Form.Label column sm="5">{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Col sm="7">
                                            <FormattedMessage id={"CMS.Profile.contact.form.phone.placeholder"}>
                                                {placeholder => (
                                                    <Form.Control type="text" name="contact.mobile"
                                                        value={values.contact.mobile || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.contact && !!errors.contact.mobile}
                                                        placeholder={placeholder}
                                                    />
                                                )}
                                            </FormattedMessage>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact && touched.contact && (<FormContactPhoneError error={errors.contact.mobile} />)}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="emailWorkId">
                                        <FormattedMessage id="CMS.Profile.contact.form.email">
                                            {value => <Form.Label column sm="5">{value}</Form.Label>}
                                        </FormattedMessage>
                                        <Col sm="7">
                                            <Form.Control type="text" name="contact.email"
                                                value={values.contact.email}
                                                onChange={event => {
                                                    handleChange(event);
                                                    this.setState({ contact: { ...this.state.contact, ...{ email: event.target.value } } })
                                                }}
                                                onBlur={handleBlur}
                                                isInvalid={(!!errors.contact && !!errors.contact.email) || this.state.form_errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact && touched.contact && (<FormContactEmailError error={errors.contact.email} />)}
                                                {this.state.form_errors.email && <p>{this.state.form_errors.email.join(', ')}</p>}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    {this.state.role === UserRolesEnum.RESELLER && (
                                        <>
                                            <Form.Group as={Row} controlId="emailPrivateId">
                                                <FormattedMessage id="CMS.Profile.contact.form.email2">
                                                    {value => <Form.Label column sm="5">{value}</Form.Label>}
                                                </FormattedMessage>
                                                <Col sm="7">
                                                    <Form.Control type="text" name="contact.alternative_email"
                                                        value={values.contact.alternative_email || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.contact && !!errors.contact.alternative_email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (<FormEmailError field_name={"CMS.Profile." + errors.contact.alternative_email} />)}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="url">
                                                <FormattedMessage id="CMS.Profile.contact.form.url">
                                                    {value => <Form.Label column sm="5">{value}</Form.Label>}
                                                </FormattedMessage>
                                                <Col sm="7">
                                                    <Form.Control type="text" name="contact.url"
                                                        value={values.contact.url || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={(!!errors.contact && !!errors.contact.url) || !!errors.url}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (<UserFormError error={errors.contact.url} field_name={"CMS.Profile." + errors.contact.url} />)}
                                                        {errors.url && touched.contact.url && (<FormWebsiteError field_name={"CMS.Profile." + errors.url.slice(0, -8)} />)}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <FormattedMessage id={"CMS.User.Form.company"}>
                                                    {value => <Form.Label column sm="5">{value}</Form.Label>}
                                                </FormattedMessage>
                                                <Col sm="7">
                                                    <Form.Control type="text" name="contact.company_name"
                                                        value={values.contact.company_name || ''}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.contact && !!errors.contact.company_name}
                                                        onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && errors.contact.company_name && (<FormContactCompanyNameError error={errors.contact.company_name} />)}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <FormattedMessage id={"CMS.User.Form.vat_number"}>
                                                    {value => <Form.Label column sm="5">{value}</Form.Label>}
                                                </FormattedMessage>
                                                <Col sm="7">
                                                    <Form.Control type="text" name="contact.vat_number"
                                                        value={values.contact.vat_number || ''}
                                                        onChange={handleChange}
                                                        isInvalid={(!!errors.contact && !!errors.contact.vat_number) || this.state.form_errors.tax}
                                                        onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (<FormRequiredError field_name={"CMS.User.Form.vat_number"} />)}
                                                        {this.state.form_errors.tax && (<p>{this.state.form_errors.tax}</p>)}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                            <Card className={"green cms card submit"}>
                                <Card.Body>
                                    <Button type="submit" onClick={() => FormHelper.submitFormErrorToast(this)}>
                                        <FormattedMessage id="CMS.User.Form.save" />
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
        );
    }
}

export default ProfileInfoEditFormComponent;