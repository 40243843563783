import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "../repository/CustomHeaders";

export default class API {
    static get = (endpoint) => new Promise((resolve, reject) =>
        axios
            .get(this.getUrl(endpoint), this.getConfig())
            .then(response => resolve(response))
            .catch(error => reject(error.message))
    );

    static post = (endpoint, data = {}) => new Promise((resolve, reject) =>
        axios
            .post(this.getUrl(endpoint), data, this.getConfig())
            .then(response => resolve(response))
            .catch(error => reject(error))
    );

    static delete = (endpoint) => new Promise((resolve, reject) =>
        axios
            .delete(this.getUrl(endpoint), this.getConfig())
            .then(response => resolve(response))
            .catch(error => reject(error))
    );

    static getUrl = (endpoint) => config.get('api_url') + endpoint;
    static getConfig = () => { return { headers: CustomHeaders.get() } }

    static isSuccess = (response) => response.data?.success === true;
}