import React from 'react';
import LoginFragment from "../fragments/authentication/LoginFragment";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ForgotPasswordFragment from "../fragments/authentication/ForgotPasswordFragment";
import ResetPasswordFormFragment from "../fragments/authentication/ResetPasswordFormFragment";
import Activate2faFragment from "../fragments/authentication/2fa/Activate2faFragment";

class AuthPage extends React.Component {
    render() {
        return (
            <Router basename="/auth" exact>
                <div className="authentication">
                    <Route path="/login" exact component={LoginFragment} />
                    <Route path="/forgot-password" component={ForgotPasswordFragment} />
                    <Route path="/reset-password/:token" component={(props) => <ResetPasswordFormFragment {...props} policy={true} />} />
                    <Route path={"/2fa/activate"} component={(props) => <Activate2faFragment {...props} />} />
                </div>

            </Router>
        )
    }
}

export default AuthPage;