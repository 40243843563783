export default class WeblinkContent {

    static fromJson = (json) => {
        let content = new WeblinkContent();

        content.id = json.id;
        content.language = json.language;
        content.title = json.title;
        content.url = json.url;
        content.content = json.content != null ? json.content : '{}';

        return content;
    }
}