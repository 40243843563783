export default class DynamicFieldValueContent {
    language;
    value;

    static fromJson = (json) => {
        let item = new DynamicFieldValueContent();

        item.language = json.language;
        item.value = json.value;

        return item;
    }
}
