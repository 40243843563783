import React from "react";
import FormPhoneError from "../../errors/FormPhoneError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormRequiredConditionalError from "../../errors/FormRequiredConditionalError";

class FormContactPhoneError extends React.Component {
    render() {
        if (this.props.error === "contact.form.phone" || this.props.error === "contact.form.mobile") {
            return <FormPhoneError field_name={"CMS.Profile." + this.props.error} countryCodeRequired />;
        } else if (this.props.error?.indexOf('required.conditional') > -1) {
            return <FormRequiredConditionalError field1={"CMS.Profile.contact.form.phone"} field2={"CMS.Profile.contact.form.mobile"} />;
        } else {
            return <FormRequiredError field_name={"CMS.Profile." + this.props.error?.replace('.required', '')} />;
        }
    }
}

export default FormContactPhoneError;