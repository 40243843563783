import React from "react";
import TopNavMenuComponent from "../../components/basics/navigation/TopNavMenuComponent";
import PropTypes from "prop-types";
import LangSwitchComponent from "../../components/basics/LangSwitchComponent";
import UserDropDownComponent from "../../components/basics/navigation/UserDropDownComponent";
import TopNavMenuMobileComponent from "../../components/basics/navigation/TopNavMenuMobileComponent";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";

/**
 *
 */
class TopNavFragment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            showMobile: false
        }

    }

    componentDidMount() {
        this.checkShowMobile();
        window.addEventListener("resize", this.checkShowMobile.bind(this));
    }

    checkShowMobile() {
        if (
            (window.innerWidth < 1541 && CheckPermissions.role(this.state.user.roles, UserRolesEnum.WIZARD)) ||
            (window.innerWidth < 1200 && CheckPermissions.role(this.state.user.roles, UserRolesEnum.RESELLER))
        ) {
            this.setState({
                showMobile: true
            })
        } else {
            this.setState({
                showMobile: window.innerWidth < 961
            })
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            user: nextProps.user
        };
    }

    /* DEPRECATED --> getDerivedStateFromProps
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            user: nextProps.user,
        })
    }*/

    render() {
        return (
            <>
                {
                    this.state.showMobile ?
                        <TopNavMenuMobileComponent user={this.state.user} parent={this.props.parent} />
                        :
                        <TopNavMenuComponent user={this.state.user}/>

                }
                <div className="actions">
                    <LangSwitchComponent/>
                    <UserDropDownComponent user={this.state.user}/>
                </div>
            </>
        )
    }
}

TopNavFragment.propTypes = {
    user: PropTypes.object.isRequired,
};
export default TopNavFragment;