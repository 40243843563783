import React from 'react';
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AuthenticationRepository, WebsiteRepository } from "../../repository";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import User from "../../models/user/User";
import FormToastComponent from "../basics/FormToastComponent";
import Website from "../../models/website/Website";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Application from '../../helpers/Application';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

/**
 * In this component is the login form created. Also it will handle the login form requests.
 */
class LoginFormComponent extends React.Component {

    constructor(props) {
        super(props);

        this.props.actions.userSet(null);

        this.state = {
            email: '',
            password: '',
            showPasswordPlain: false,
            failed: false,
            error: this.props.error,
            message: this.props.message
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.error) {
            FormToastComponent.error(this.state.error);
            Website.set(null);
        }

        if (this.state.message) {
            FormToastComponent.success(this.state.message);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        AuthenticationRepository.login(this.state.email, this.state.password)
            .then(response => {
                if (response.data.success != null) {
                    this.handleLoginSuccess(response.data.success);
                }
            })
            .catch(reason => {
                FormToastComponent.errorTrans("Auth.login.form.failed");
                this.setState({ password: '' })
            });
    }

    handleLoginSuccess = (data) => {
        User.transform(data).set();

        WebsiteRepository.getSettings(0)
            .then(result => {
                if (result.website) {
                    Application.setSettings(result.default);
                }

                window.location.href = '/';
            });
    }

    render() {
        return (
            <div className="custom-form">
                <Form onSubmit={this.handleSubmit} id="login-form">
                    <Form.Group controlId="formEmail">
                        <FormattedMessage id="Auth.form.username.placeholder">
                            {
                                placeholder => <Form.Control type="mail" placeholder={placeholder} name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} />
                            }
                        </FormattedMessage>
                    </Form.Group>
                    <Form.Group controlId="formUsername" style={{ display: 'none' }}>
                        <Form.Control type="text" name="username" />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <FormattedMessage id="Auth.form.password.placeholder">
                            {
                                placeholder =>
                                    <>
                                        <div className={"password-toggle-wrap"}>
                                        <Form.Control type={this.state.showPasswordPlain ? 'text' : 'password'} placeholder={placeholder} name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                            autoComplete="current-password"
                                        />
                                        <div className={"password-toggle"} onClick={() => this.setState({ showPasswordPlain: !this.state.showPasswordPlain })}>
                                        <FontAwesomeIcon  icon={['far', this.state.showPasswordPlain ? 'eye-slash' : 'eye']} />
                                        </div>
                                        </div>
                                    </>
                            }
                        </FormattedMessage>
                    </Form.Group>
                    <Form.Row>
                        <Col>
                            <Link to="/forgot-password"><FormattedMessage
                                id="Auth.form.password.forgotten" /></Link><br></br>
                        </Col>
                        <Form.Group as={Col} controlId="formSubmit">
                            <Button type="submit" className="login-submit"><FormattedMessage id="Auth.login.form.submit" /></Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
export default connect(null, mapDispatchToProps)(LoginFormComponent);