import React from "react";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import FormToastComponent from "../../basics/FormToastComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import MediaRepository from "../../../repository/MediaRepository";
import Module from "../../../models/module/Module";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Website from "../../../models/website/Website";
import MediaHelper from "../../../helpers/MediaHelper";
import LoaderComponent from "../../basics/layout/LoaderComponent";
import StandardFormErrorMessageComponent from "../../basics/form/StandardFormErrorMessageComponent";
import FsLightbox from "fslightbox-react";

class MediaFileUploadComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings ? props.settings : {},
            optional: props.optional === undefined || !!props.optional,
            isLoading: true,
            file: null,
            file_src: props.values[props.field] ? props.values[props.field] : null,
            showEdit: false,
            lightboxToggler: false,
            lightboxKey: Math.random(),
        }
    }

    deleteRef = null;

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        let moduleType = null;
        let module = null;
        if (this.props.module === 'website') {
            moduleType = 'website';
        } else {
            module = Module.get();
            moduleType = module ? module.module.type : null;
        }

        let promises = [
            MediaRepository.getSpecificSettings(this.props.type, module ? module.id : this.props.parent.props.id, moduleType),
            WebsiteRepository.get(Website.get())
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResponse = result[0];
                let websiteResponse = result[1];

                if (settingsResponse !== undefined && settingsResponse.data !== undefined && settingsResponse.data.settings !== undefined) {
                    let settings = settingsResponse.data.settings;
                    this.setState({
                        settings: { ...settings, ...this.state.settings },
                        website: websiteResponse,
                        isLoading: false,
                    }, () => {
                        if (this.props.fake) {
                            this.setState({ file_src: this.props.values.file_src })
                        }
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(error => {
                console.error(error)
                FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
            });
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    editParentModel = (key, value) => {
        let model = this.props.parent.state.model;
        model[key] = value;
        this.props.parent.setState({ model: model })
    }

    handleFileRemove = (e) => {
        if (e) e.preventDefault();

        if (this.props.fake) {
            this.editParentModel(this.props.field ? this.props.field : 'file', null);
            this.editParentModel("file_src", null);
        } else {
            this.setState({ file: null, file_src: null });
            this.editParentModel(this.props.field ? this.props.field : 'file', null);
            this.editParentModel("file_src", null);
        }
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        let hasError = this.props.errors[this.props.formErrorName] !== undefined;

        return (
            <Form.Group className="media-content-upload-wrapper">
                <Row>
                    <Col xs={12}>
                        <div className="media-content-upload">
                            <Row>
                                <Col xs={12}>
                                    {this.state.optional ?
                                        <FormattedMessage id="CMS.Form.optional">
                                            {optional_text =>
                                                <FormattedMessage id={this.props.label}>
                                                    {(value) => <Form.Label>{value}<span>*{optional_text}</span></Form.Label>}
                                                </FormattedMessage>
                                            }
                                        </FormattedMessage> :
                                        <FormattedMessage id={this.props.label}>
                                            {(value) => <Form.Label>{value}</Form.Label>}
                                        </FormattedMessage>
                                    }
                                </Col>
                                <Col xl={this.props.fullWidth ? 12 : 10} xs={12}>
                                    <div className={this.props.showPreview ? 'upload-wrap' : ''}>
                                        {this.props.showPreview && (
                                            <div className="upload-left-wrap">
                                                {(this.state.file_src != null) || (this.props.fake && this.state.file_src != null) ?
                                                    <div className={"uploaded-image " + (hasError ? 'is-invalid' : '')}>
                                                        <Link className="delete icon" to="#" onClick={this.handleFileRemove}>
                                                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                                        </Link>

                                                        <Link to="#" onClick={() => this.setState({ lightboxToggler: !this.state.lightboxToggler })} className="text-center">
                                                            <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                                                            <p>{this.state.file?.path ? this.state.file.path : this.state.file_src.split('/').slice(-1)}</p>
                                                        </Link>

                                                        <FsLightbox key={this.state.lightboxKey} toggler={this.state.lightboxToggler} disableLocalStorage={true}
                                                            sources={[<iframe title="media-pdf" src={this.state.file ? this.state.file_src : MediaHelper.buildImageSrc(this.state.file_src, this.state.website)} width="1920px" height="1080px" allowFullScreen="allowfullscreen" allow="fullscreen" frameborder="0" />]}
                                                        />
                                                    </div>
                                                    :
                                                    <div className={"uploaded-image " + (hasError ? 'is-invalid' : '')}>
                                                        <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                                                        <FormattedMessage id="CMS.Page.Media.Content.upload.no-file">
                                                            {(value) => <p>{value}</p>}
                                                        </FormattedMessage>
                                                    </div>
                                                }

                                                {hasError && <StandardFormErrorMessageComponent error={this.props.label} />}
                                            </div>
                                        )}
                                        <Dropzone multiple={false} noKeyboard
                                            accept={MediaHelper.acceptedMimeTypes(this.state.settings)}
                                            onDrop={(acceptedFiles, rejectedFiles) => {
                                                if (acceptedFiles.length > 0) {
                                                    let media = acceptedFiles[0];

                                                    if (this.props.fake) {
                                                        this.editParentModel(this.props.field ? this.props.field : 'file', media);
                                                    } else {
                                                        this.setState({ file: media });
                                                        this.editParentModel(this.props.field ? this.props.field : 'file', media);
                                                    }

                                                    let dimensionPrefix = this.props.dimensionPrefix;
                                                    let minWidthField = (dimensionPrefix ? dimensionPrefix : 'min') + '_width';
                                                    let minHeightField = (dimensionPrefix ? dimensionPrefix : 'min') + '_height';

                                                    MediaHelper.handleDropAccepted(media, { minWidth: this.state.settings[minWidthField], minHeight: this.state.settings[minHeightField] }, (result) => {
                                                        this.setState({ file_src: result });

                                                        if (this.props.fake) {
                                                            this.editParentModel("file_src", result);
                                                        }
                                                    });
                                                }

                                                MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                <section className={'media-drag-drop ' + (this.props.fullWidth ? 'p-0' : '') + (hasError ? 'invalid ' : '')} {...getRootProps()}>
                                                    <div className="media-drag-drop-inner p-3">
                                                        <div className="media-drag-drop-inner-row">
                                                            <div className="media-drag-drop-icon">
                                                                <div className="icon-wrapper">
                                                                    <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                                                </div>
                                                            </div>
                                                            <div className="media-drag-drop-text">
                                                                <FormattedMessage id="CMS.Media.Form.file_dragdrop.title">
                                                                    {(value) => <h1>{value}</h1>}
                                                                </FormattedMessage>
                                                                <FormattedMessage id="CMS.Media.Form.file_dragdrop.title.sub">
                                                                    {(value) => <h2>{value}</h2>}
                                                                </FormattedMessage>
                                                            </div>
                                                        </div>
                                                        <div className="media-drag-drop-requirements">
                                                            <p className="mt-3">{MediaHelper.assembleRequirements(this.state.settings, this.props.dimensionPrefix)}</p>
                                                        </div>
                                                        <Form.Control{...getInputProps()} />
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form.Group >
        );
    }

    reset = () => this.setState({ file: null, file_src: null }, () => this.handleFileRemove());
}

MediaFileUploadComponent.defaultProps = {
    label: 'CMS.Page.Media.Content.upload.title',
    showPreview: true
};

export default MediaFileUploadComponent;