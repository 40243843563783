import React from 'react';
import User from '../../models/user/User';

class PermissionChecker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: User.get()
        }
    }

    render() {
        this.guardPermission();

        return this.props.children ? this.props.children : null;
    }

    guardPermission = () => {
        try {
            // Determine current page
            let currentLocation = this.props.location;

            let route = currentLocation.pathname.split('/').filter(x => x);
            if (route.length <= 0) return;

            this.state.user.guardModuleAccess(route[0], route[2], route[1]);
        } catch (e) {
            this.props.history.push('/403-forbidden');
        }
    }
}

export default PermissionChecker;