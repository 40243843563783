import moment from "moment";
import * as Yup from "yup"
import BlogContent from "./BlogContent";

export default class Blog {
    id;
    website;
    category;
    active;
    published_at;
    image;
    contents = [];

    constructor() {
        this.published_at = moment()
        this.content = new BlogContent();
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.Blog.Content.Form.title').max(255, 'CMS.Blog.Content.Form.title|255|max'),
            published_at: Yup.string().required('CMS.Blog.Content.Form.published_at'),
            seo_title: Yup.string().nullable().max(65, 'CMS.Blog.Content.Form.seo_title|65|max'),
            seo_tags: Yup.string().nullable().max(500, 'CMS.Blog.Content.Form.seo_tags|500|max'),
            seo_description: Yup.string().nullable().max(160, 'CMS.Blog.Content.Form.seo_description|160|max'),
        };

        if (settings.show_summary) {
            fields.summary = Yup.string().required('CMS.Blog.Content.Form.summary').max(255, 'CMS.Blog.Content.Form.summary|255|max');
        }

        if (!settings.content_with_blocks) {
            fields.value = Yup.object({ text: Yup.string().required('CMS.Blog.Content.Form.content') });
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Blog();

        item.website = json.website;

        item.id = json.id;
        item.active = json.active;
        item.published_at = json.published_at;
        item.image = json.image;
        item.contents = json.contents.map(content => BlogContent.fromJson(content));

        return item;
    }
}
