import API from "../services/api";

export default class WeblinkRepository {
    static all = (all) => API.get('/weblink/all?all=' + (all ? 1 : 0)).then(response => response.data.weblinks);
    static get = (id) => API.get('/weblink/details/' + id).then(respone => respone.data.weblink);

    static create = (data) => API.post('/weblink/create', data);
    static update = (data) => API.post('/weblink/update', data).then(response => response.data);
    static updateTree = (data) => API.post('/weblink/update/order', { tree: data });
    static toggle = (id) => API.post('/weblink/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static delete = (id) => API.delete('/weblink/delete?id=' + id);
}