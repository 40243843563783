import React from "react";
import CategoryFormError from "../errors/CategoryFormError";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import MediaContentUploadComponent from "../../media/content/MediaContentUploadComponent";
import MediaHelper from "../../../helpers/MediaHelper";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";

class CategoryTextFormComponent {
    static fields(settings, parent) {
        let settingPrefix = parent ? 'subcategories_' : '';

        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? "CMS.Subcategory.Content.Form.title" : "CMS.Category.Content.Form.title",
                                type: "text",
                                name: "title",
                                customFeedback: CategoryFormError
                            }
                        ]
                    },
                    settings[settingPrefix + 'show_label'] ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? "CMS.Subcategory.Content.Form.label" : "CMS.Category.Content.Form.label",
                                type: "text",
                                name: "label",
                                customFeedback: CategoryFormError,
                                formErrorName: "label"
                            }
                        ]
                    }
                        : {},
                    settings[settingPrefix + 'show_colors'] ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? "CMS.Subcategory.Content.Form.color" : "CMS.Category.Content.Form.color",
                                type: "color",
                                name: "color",
                                customFeedback: CategoryFormError,
                                formErrorName: "color"
                            }
                        ]
                    }
                        : {},
                    settings[settingPrefix + 'show_email'] ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? "CMS.Subcategory.Content.Form.email" : "CMS.Category.Content.Form.email",
                                type: "text",
                                name: "email",
                                customFeedback: CategoryFormError,
                                formErrorName: "email"
                            }
                        ]
                    }
                        : {},
                    settings[settingPrefix + 'show_summary'] ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? 'CMS.Subcategory.Content.Form.summary' : 'CMS.Category.Content.Form.summary',
                                type: "textarea",
                                name: "summary",
                                //  message: 'CMS.Modules.Form.description.message',
                                customFeedback: CategoryFormError,
                                // formErrorName: "summary",
                            }
                        ]
                    }
                        : {},
                    settings[settingPrefix + 'show_highlights'] ? {
                        type: "col",
                        xl: 9,
                        fields: [
                            {
                                title: parent ? 'CMS.Subcategory.Content.Form.highlights' : 'CMS.Category.Content.Form.highlights',
                                type: "tags",
                                name: "highlights",
                                customFeedback: CategoryFormError,
                            }
                        ]
                    }
                        : {},
                    settings[settingPrefix + 'show_content'] ? {
                        type: "col",
                        xl: 9,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        customProps: {
                            label: parent ? 'CMS.Subcategory.Content.Form.content' : 'CMS.Category.Content.Form.content',
                            placeholder: parent ? 'CMS.Subcategory.Content.Form.content.placeholder' : 'CMS.Category.Content.Form.content.placeholder'
                        }
                    }
                        : {},
                    settings[settingPrefix + 'show_cover_image'] ? {
                        type: "col",
                        xl: 11,
                        fields: [],
                        name: 'cover_image',
                        custom: MediaImageUploadComponent,
                        // customProps: { type: 'category_image', dimensionPrefix: settingPrefix + 'need_home_img' ? 'home_img' : 'cover_image', settings: settings, field: 'image', formErrorName: 'image', label: 'CMS.Category.Content.Form.image' }
                        customProps: { type: parent ? 'subcategory_image' : 'category_image', settings: settings, field: 'image', formErrorName: 'image', label: 'CMS.Category.Content.Form.image' }
                    }
                        : {},
                    settings[settingPrefix + 'show_svg'] ? {
                        type: "col",
                        xl: 11,
                        fields: [],
                        custom: MediaImageUploadComponent,
                        customProps: { type: 'category_svg', field: 'svg', settings: settings, formErrorName: 'svg', label: 'CMS.Category.Content.Form.svg' }
                    }
                        : {},
                ]
            }
        ]
    }

    static fields_block(settings, parent = null, isEdit = false) {
        let settingPrefix = parent ? 'subcategories_' : '';

        return [{
            type: "row",
            children: [
                settings[settingPrefix + 'has_special_blocks'] ? {
                    type: "col",
                    xl: 9,
                    fields: [{
                        title: "CMS.Media.Content.block.form.type",
                        type: "select",
                        default: 'text',
                        options: ['text', 'accordion'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Page.Media.Content.block.title.' + item} /> } }),
                        name: "type",
                        optional: false,
                        fake: !isEdit,
                        beforeOnChange: settings.onBlockTypeChange
                    }]
                } : {},
                {
                    type: "col",
                    xl: 9,
                    fields: [{
                        title: "CMS.Media.Content.block.form.title",
                        type: "text",
                        name: "title",
                        optional: settings.titleOptional,
                        fake: !isEdit
                    }]
                },
                {
                    type: "col",
                    xl: 9,
                    fields: [],
                    custom: StandardTextFormEditorComponent,
                    fake: !isEdit
                },
                {
                    type: "col",
                    xl: 11,
                    fields: [],
                    custom: MediaContentUploadComponent,
                    customProps: { type: parent ? 'subcategory_block_image' : 'category_block_image', title: "CMS.Category.Media.Content.title", dimensionPrefix: parent ? 'subcategories_image' : 'content_with_blocks_image', settings: settings },
                    fake: isEdit
                }
            ]
        }]
    }

    static async fields_image_before_submit(element, field = 'image', type, settings = {}) {
        return new Promise(async (resolve, reject) => {
            let item = element.state.model;
            let id = element.props.id

            if (item[field] !== null && item[field] !== undefined && (typeof item[field] !== 'string')) {
                item[field] = await MediaHelper.uploadImage(item, field, type, 'category', id, null, item.title, settings);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static async fields_block_before_submit(element, type) {
        return new Promise(async (resolve, reject) => {
            let category_content = element.state.model
            let value = category_content.value;
            let value_content = value.content ? value.content : [];
            value_content = await Promise.all(value_content.map(async (item) => {
                if (item.image !== null && item.image !== undefined) {
                    item.image_src = await MediaHelper.uploadImage(item, 'image', type, 'category', element.props.id, null, item.title);
                    item.image = undefined;
                }
                return item;
            }))

            category_content.value.content = value_content;

            element.setState({ model: category_content }, () => resolve(true))
        })
    }
}

export default CategoryTextFormComponent;