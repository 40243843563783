import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import logoLight from '../../images/logos/logo-light-big.png';
import FormattedMessageString from "../basics/FormattedMessageString";

class TwofaBackupCodesPDF extends React.Component {

    styles = {
        page: { backgroundColor: '#fff' },
        header: { backgroundColor: '#0d1a21', padding: '40px 0' },
        headerImage: { width: '80px', margin: 'auto' },
        textContent: { padding: '60px 0 40px', margin: '0 60px', textAlign: 'center' },
        textContentTitle: { fontWeight: 'bold', marginBottom: '30px' },
        textContentText: { fontSize: '14' },
        codesContent: {},
        codesContentInner: { width: '50%', margin: 'auto', backgroundColor: '#f3f1ed', padding: '40px 50px' },
        codesContentInnerTitle: { color: '#394f38', fontSize: '14', textAlign: 'center', marginBottom: '30px' },
        codesContentInnerWrapper: { margin: '0 10px', backgroundColor: '#f3f1ed' },
        codesContentInnerText: {
            color: '#394f38',
            fontSize: '14',
            padding: '10px 0',
            textAlign: 'center',
            backgroundColor: '#fff'
        },
        codesContentInnerTextDivider: { backgroundColor: '#f1f3f2', padding: '5px' }
    };

    codes() {
        if (this.props.codes.length > 0) {
            return this.props.codes.map((backupCode, index) => (
                <>
                    <Text style={this.styles.codesContentInnerText}>{backupCode}</Text>
                    <Text style={this.styles.codesContentInnerTextDivider} />
                </>
            ));
        } else {
            return Array.apply(null, { length: 8 }).map(Number.call, Number).map((number, index) => (
                <>
                    <Text style={this.styles.codesContentInnerText}>000000</Text>
                    <Text style={this.styles.codesContentInnerTextDivider} />
                </>
            ));
        }
    }

    render() {
        return (
            <Document>
                <Page size="A4" style={this.styles.page}>
                    <View style={this.styles.header}>
                        <Image src={logoLight} style={this.styles.headerImage} />
                    </View>
                    <View style={this.styles.textContent}>
                        <Text style={this.styles.textContentTitle}>
                            <FormattedMessageString id="Auth.2fa.step3.title2" />
                        </Text>
                        <Text style={this.styles.textContentText}>
                            <FormattedMessageString id="Auth.2fa.step3.text1" />
                        </Text>
                    </View>
                    <View style={this.styles.codesContent}>
                        <View style={this.styles.codesContentInner}>
                            <Text style={this.styles.codesContentInnerTitle}>
                                <FormattedMessageString id="Auth.2fa.step3.subtitle" />
                            </Text>
                            <View style={this.styles.codesContentInnerWrapper}>
                                {this.codes()}
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}

TwofaBackupCodesPDF.propTypes = {
    codes: PropTypes.array.isRequired
};

export default TwofaBackupCodesPDF;