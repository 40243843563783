import React from "react";
import MediaHelper from "../../../helpers/MediaHelper";
import TeamFormError from "../errors/TeamFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";

class TeamTextFormComponent {
    static fields(settings) {
        let fields = [
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.first_name",
                        type: "text",
                        name: 'first_name',
                        customFeedback: TeamFormError,
                        formErrorName: "first_name"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.last_name",
                        type: "text",
                        name: 'last_name',
                        customFeedback: TeamFormError,
                        formErrorName: "last_name"
                    }
                ]
            }
        ];

        if (settings.use_categories) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: 'CMS.Team.Content.Form.category',
                        type: "select",
                        name: "category",
                        options: settings.references.map(item => {
                            if (item.children.length) {
                                return false;
                            }

                            let label = item.depthLabel.join(' > ');

                            return { value: item.id, label: label };
                        }).filter(x => x !== false),
                        customFeedback: TeamFormError,
                        formErrorName: "category"
                    }
                ]
            });
        }

        if (settings.use_reference) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: <FormattedMessageString id="CMS.Team.Content.Form.module_references" values={{ reference: settings.reference.title }} />,
                        type: "select",
                        name: "module_references",
                        multi: true,
                        options: this.renderReferenceOptions(settings.reference, 0),
                        customFeedback: TeamFormError,
                        formErrorName: "module_references"
                    }
                ]
            });
        }

        if (settings.show_function) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.function",
                        type: "text",
                        name: 'function',
                        customFeedback: TeamFormError,
                        formErrorName: "function"
                    }
                ]
            });
        }

        if (settings.show_phone) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.phone",
                        type: "text",
                        name: 'phone',
                        customFeedback: TeamFormError,
                        formErrorName: "phone"
                    }
                ]
            });
        }

        if (settings.show_mobile) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.mobile",
                        type: "text",
                        name: 'mobile',
                        customFeedback: TeamFormError,
                        formErrorName: "mobile"
                    }
                ]
            });
        }

        if (settings.show_email) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Team.Content.Form.email",
                        type: "text",
                        name: 'email',
                        customFeedback: TeamFormError,
                        formErrorName: "email"
                    }
                ]
            });
        }

        for (let i = 1; i <= 3; i++) {
            if (!settings['show_additional_field_' + i]) continue;
            
            if (settings['additional_field_' + i + '_type'] === 'editor') {
                fields.push({
                    type: "col",
                    md: 8,
                    fields: [],
                    custom: StandardTextFormEditorComponent,
                    customProps: {
                        valueField: 'additional_field_' + i + '_value',
                        placeholder: 'CMS.Team.Content.Form.additional_field_value.placeholder',
                        label: settings['additional_field_' + i + '_label']
                    }
                });
            } else {
                fields.push({
                    type: "col",
                    md: 8,
                    fields: [
                        {
                            type: settings['additional_field_' + i + '_type'],
                            title: settings['additional_field_' + i + '_label'],
                            placeholder: 'CMS.Team.Content.Form.additional_field_value.placeholder',
                            name: 'additional_field_' + i + '_value',
                            customFeedback: TeamFormError,
                            formErrorName: 'additional_field_' + i + '_value'
                        }
                    ]
                });
            }
        }

        if (settings.show_content) {
            fields.push({
                type: "col",
                md: 12,
                fields: [],
                custom: StandardTextFormEditorComponent,
                customProps: { valueField: 'content', label: 'CMS.Team.Content.Form.content', placeholder: 'CMS.Team.Content.Form.content.placeholder' }
            });
        }

        if (settings.show_image) {
            fields.push({
                type: "col",
                md: 12,
                fields: [],
                custom: MediaImageUploadComponent,
                customProps: { label: 'CMS.Team.Content.Form.image', type: 'team_image', settings: settings, field: 'image', formErrorName: 'image' }
            });
        }

        return [{
            type: "row",
            children: fields
        }];
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            let type = module ? module.module.type : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'team_image', type, element.props.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static renderReferenceOptions = (reference, language) => {
        let options = [];
        reference.items.forEach(item => options.push({ value: item.id, label: this.renderOptionLabel(reference, item, language) }))
        return options;
    }

    static renderOptionLabel = (reference, item, language) => {
        switch (reference.type) {
            case 'category':
                return item.depthLabel.join(' > ');
            default:
                let content = item[reference.type].contents.filter(content => content.language === language)[0];
                if (!content) content = item[reference.type].contents[0];
                return content.title ? content.title : (content.name ? content.name : '');
        }
    }
}

export default TeamTextFormComponent;