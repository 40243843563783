import React from "react";
import PropTypes from "prop-types";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { FormattedMessage } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import MediaOverviewTableCustomToggleComponent from "./MediaOverviewTableCustomToggleComponent";
import { Image } from "react-bootstrap";
import FormattedMessageString from "../basics/FormattedMessageString";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import FormToastComponent from "../basics/FormToastComponent";
import FsLightbox from 'fslightbox-react';
import prettyBytes from 'pretty-bytes';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import Application from "../../helpers/Application";
import moment from 'moment';

class MediaOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            type: this.props.type,
            showDeleteModal: false,
            toggle: false,
            lightboxToggler: false,
            deleteRow: null,
            lightboxKey: Math.random()
        };

        this.columns = [
            {
                dataField: 'id',
                text: 'Id',
                sort: true,
                hidden: true
            },
            {
                dataField: 'media_url',
                text: "Media",
                sort: false,
                hidden: true,
                formatter: (cellContent, row) => {
                    if (row.external && row.embed != null) {
                        let icon = "";
                        let classname = "icon icon-over";

                        switch (row.type) {
                            case 'youtube':
                                icon = ['fab', "youtube"];
                                break;
                            case 'vimeo':
                                icon = ['fab', "vimeo"];
                                break;
                            case 'soundcloud':
                                icon = ['fab', "soundcloud"];
                                break;
                            case 'slideshare':
                                icon = ['fab', "slideshare"];
                                classname = classname + " icon-noimage";
                                break;
                            default:
                                icon = '';
                                break;
                        }

                        return (
                            <div className={"image"} onClick={() => this.setState({ lightboxToggler: !this.state.lightboxToggler, slide: row.id + 1 })}>
                                <Image src={row.embed['thumbnail_url']} fluid={true} />
                                <div className={classname}>
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                            </div>
                        )
                    } else {
                        let type = row.type;
                        let icon = "";

                        if (type.substring(0, 5) === "image") {
                            icon = ['far', "file-image"];
                        } else if (type.substring(0, 5) === "video") {
                            icon = ['far', "file-video"];
                        } else if (type.substring(0, 5) === "audio") {
                            icon = ['far', "file-audio"];
                        } else {
                            let extension = row.file_name.split('.').pop();
                            if (extension === "xlsx") {
                                icon = ['far', "file-excel"];
                            } else if (extension === "docx") {
                                icon = ['far', "file-word"];
                            } else if (extension === "pptx") {
                                icon = ['far', "file-powerpoint"];
                            } else if (extension === "pdf") {
                                icon = ['far', "file-pdf"];
                            } else {
                                icon = ['far', "file"];
                            }
                        }

                        return (
                            <div className={"icon"} onClick={() => this.setState({ lightboxToggler: !this.state.lightboxToggler, slide: row.id + 1 })}>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                        )
                    }
                }
            },
            {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.Media.overview.table.name" />,
                sort: true,
                formatter: (cellContent, row) => {
                    if (cellContent.length > 49) {
                        return cellContent.substring(0, 50) + '...'
                    } else
                        return cellContent;
                }
            },
            {
                dataField: 'file_name',
                text: <FormattedMessageString id="CMS.Media.overview.table.filename" />,
                sort: true,
                formatter: (cellContent, row) => {
                    if (row.external) {
                        if (cellContent.length > 49) {
                            return cellContent.substring(0, 50) + '...'
                        }
                    } else {
                        let name = row.file_name.split('.');
                        if (name[0].length > 46) {
                            return name[0].substring(0, 47) + '....' + name[1];
                        }
                    }
                    return cellContent;
                }
            },
            {
                dataField: 'type',
                text: <FormattedMessageString id="CMS.Media.overview.table.type" />,
                sort: true,
                formatter: (cellContent, row) => {
                    let type = row.external ? row.type : row.file_name.split('.').pop();
                    return <FormattedMessage id={"CMS.Media.overview.file.name." + type}>
                        {(value) => value}
                    </FormattedMessage>;
                }
            },
            {
                dataField: 'size',
                text: <FormattedMessageString id="CMS.Media.overview.table.size" />,
                sort: true,
                formatter: (cellContent, row) => {
                    return cellContent != null && Number.isInteger(cellContent) ? prettyBytes(cellContent) : 'N.V.T.'
                }
            },
            {
                dataField: 'upload_date',
                text: <FormattedMessageString id="CMS.Media.overview.table.uploaddate" />,
                sort: true,
                formatter: (cellContent, row) => {
                    return moment(cellContent).format('DD/MM/YYYY HH:mm:ss');
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                formatter: (cellContent, row) => {
                    return (
                        <div className="actions">
                            <Link to="#" onClick={(event) => {
                                this.openDeleteModal(event, row)
                            }} className={"delete"}><FontAwesomeIcon icon={['far', 'trash-alt']} /></Link>
                        </div>
                    )
                }
            }
        ];

        this.closeDeleteModal = this.closeDeleteModal.bind(this);
    }

    openDeleteModal = (event, row) => {
        event.preventDefault();
        this.setState({
            showDeleteModal: true,
            deleteRow: row
        });
    };

    closeDeleteModal = () => this.setState({ showDeleteModal: false, deleteRow: null }, () => this.reload());

    deleteRow = (event, _row) => {
        event.preventDefault();
        this.props.delete_func(_row)
            .then(response => {
                this.setState({
                    rows: this.state.rows.filter((row) => {
                        return _row.id !== row.id;
                    })
                }, () => {
                    this.closeDeleteModal()
                });
            })
            .catch(error => {
                if (error.data != null && error.data.message != null) {
                    FormToastComponent.errorTrans(error.data.message);
                } else {
                    FormToastComponent.errorTrans('CMS.Table.delete.error');
                }
                this.closeDeleteModal();
            })
    }

    openLightBoxOnSlide = (number) => this.setState({ lightboxToggler: !this.state.lightboxToggler, slide: number })

    reload = () => this.setState({ reload: true }, () => this.setState({ reload: false }))

    render() {
        if (this.state.reload) return 'reloading...';

        let toggles = this.state.toggles;
        const images = this.state.rows.map(function (row) {
            if (row.external && row.embed != null && row.embed.error == null) {
                let parseHtml = parse(row.embed.html);
                if (Array.isArray(parseHtml)) {
                    if (row.type === "youtube") {
                        parseHtml = parseHtml[1];
                    } else if (row.type === "slideshare") {
                        parseHtml = parseHtml[0];
                    }
                }
                return parseHtml;
            } else {
                if (row.embed != null && row.embed.error != null) {
                    return <div className="lightbox-download-wrapper">
                        <div className="lightbox-download lightbox-error">
                            <h2><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} /></h2>
                            <h1>{row.embed.error}</h1>
                        </div>
                    </div>;
                } else if (row.media_url == null) {
                    return <div className="lightbox-download-wrapper">
                        <div className="lightbox-download">
                            <h2><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />{row.file_name}</h2>
                            <h1><FormattedMessage id={"CMS.Media.overview.file.404"} /></h1>
                        </div>
                    </div>;
                } else if (row.type.substring(0, 5) === "image") {
                    return <Image src={row.media_url} width="100%" />
                } else if (row.type.substring(0, 5) === "video") {
                    return <iframe title="media-video" src={row.media_url + "?autoplay=false"} width="1920px" height="1080px" frameBorder="0" allow="fullscreen" allowFullScreen />
                } else if (row.type.substring(0, 5) === "audio") {
                    return <audio controls src={row.media_url}>
                        Your browser does not support the <code>audio</code> element.
                    </audio>
                } else if (row.type === "application/pdf") {
                    return <iframe title="media-pdf" src={row.media_url} width="1920px" height="1080px" allowFullScreen="allowfullscreen" scrolling="no" allow="fullscreen" frameborder="0" />
                } else {
                    let extension = row.file_name.split('.').pop();
                    let icon = [];
                    if (extension === "xlsx") {
                        icon = ['far', "file-excel"];
                    } else if (extension === "docx") {
                        icon = ['far', "file-word"];
                    } else if (extension === "pptx") {
                        icon = ['far', "file-powerpoint"];
                    } else if (extension === "pdf") {
                        icon = ['far', "file-pdf"];
                    } else {
                        icon = ['far', "file"];
                    }
                    return <div className="lightbox-download-wrapper">
                        <div className="lightbox-download">
                            <h1>{row.name}</h1>
                            <h2><FontAwesomeIcon icon={icon} />{row.file_name}</h2>
                            <a href={row.media_url} className="btn btn-primary">
                                <FormattedMessageString id="CMS.Media.button.download" />
                            </a>
                        </div>
                    </div>
                }
            }
        });
        
        return (
            <div className={this.state.toggle ? 'custom-data-table media show-media' : 'custom-data-table media'}>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.rows}
                    columns={this.columns.map(function (item, index) {
                        if (toggles !== undefined) {
                            item.hidden = !toggles[item.dataField]
                        }
                        return item;
                    })}
                    bootstrap4
                    columnToggle
                >
                    {
                        props => (
                            <PaginationProvider
                                pagination={paginationFactory({
                                    sizePerPage: Application.setting('table_number_rows', 100),
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true,
                                    custom: true,
                                    totalSize: this.state.rows.length,
                                    prePageText: <FormattedMessageString id={"CMS.Table.Pagination.prev"} />,
                                    nextPageText: <FormattedMessageString id={"CMS.Table.Pagination.next"} />,
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div className="table-wrapper">
                                            <div className={"table-header"}>
                                                <FormattedMessage id={this.props.title_id}>
                                                    {
                                                        (title) =>
                                                            <FormattedMessage id={"CMS.Table.overview"}>
                                                                {
                                                                    (title2) => <p>{title + " " + title2}</p>
                                                                }
                                                            </FormattedMessage>
                                                    }
                                                </FormattedMessage>
                                                <MediaOverviewTableCustomToggleComponent {...props.columnToggleProps}
                                                    parent={this} />
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                {...paginationTableProps}
                                                wrapperClasses="table-responsive"
                                                sort={{
                                                    dataField: 'upload_date',
                                                    order: 'desc'
                                                }}
                                            />
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    )
                                }
                            </PaginationProvider>

                        )
                    }
                </ToolkitProvider>

                <ModalFormFragment title_id={this.props.delete_title_id} show={this.state.showDeleteModal}
                    onHide={this.closeDeleteModal} is_delete={true}>
                    <DeleteConfirmComponent onHide={this.closeDeleteModal} delete_text_id={this.props.delete_text_id}
                        delete_func={(event) => {
                            this.deleteRow(event, this.state.deleteRow)
                        }} />
                </ModalFormFragment>
                {
                    <FsLightbox
                        toggler={this.state.lightboxToggler}
                        sources={images}
                        slide={this.state.slide}
                        disableLocalStorage={true}
                        key={this.state.lightboxKey}
                    />
                }
            </div>
        )
    }

}

MediaOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    delete_title_id: PropTypes.string.isRequired,
    delete_text_id: PropTypes.string.isRequired,
    delete_func: PropTypes.func.isRequired,
};

export default MediaOverviewTableComponent;