import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";

class PageFormSeoError extends React.Component {

    render() {
        let field = this.props.error.split(',');
        return <FormMaxError field_name={field[0]} max={field[1]} />
    }
}

PageFormSeoError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default PageFormSeoError;
