import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import CheckPermissions from "../../helpers/CheckPermissions";
import ProfileInfoEditFormComponent from "../../components/users/ProfileInfoEditFormComponent";
import Select from "react-select";
import CountryHelper from "../../helpers/CountryHelper";
import UserRepository from "../../repository/UserRepository";
import FormToastComponent from "../../components/basics/FormToastComponent";
import LoaderComponent from "../../components/basics/layout/LoaderComponent";


class ProfileEditFormFragment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            role: null,
            loading: true
        };

        this.languages = CountryHelper.getLanguagesShort(['nl', 'fr', 'en']);
    }

    componentDidMount() {
        let role = CheckPermissions.get(this.state.user.roles, true);
        if (role != null) {
            role = role.charAt(0).toUpperCase() + role.slice(1);
            this.setState({
                role: role != null ? role : 'wizard',
                loading: false
            })
        }
    }

    handleUpdate(contact) {
        let newUser = this.state.user;
        newUser.contact_info.first_name = contact.first_name;
        newUser.contact_info.last_name = contact.last_name;
        this.setState({ user: newUser });

        this.props.handleUserUpdate(newUser);
    }

    language() {
        return <Select
            options={this.languages}
            value={this.languages.find(({ value }) => value + "" === this.state.user.language)}
            onChange={(event) => UserRepository.updateLanguage(event.value)
                .then(() => {
                    let user = this.state.user;
                    user.language = event.value;
                    this.setState({
                        user: user,
                        editLanguage: false
                    });
                    FormToastComponent.successTrans("Taal", "Default.saved");

                })
                .catch(error => console.error(error))
            }
            className={'react-select-container'}
            classNamePrefix="react-select"
            onMenuClose={() => this.setState({ editLanguage: false })}
        />;
    }

    render() {
        if (this.state.loading) return <LoaderComponent />

        return (
            <>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={8} md={6} xl={8} className="name">
                                <h1>{this.state.user.contact_info.first_name} {this.state.user.contact_info.last_name}</h1>
                                <h2>{this.state.role}</h2>
                            </Col>
                            <Col sm={4} md={6} xl={4} className="name">
                                {this.language()}
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>
                <ProfileInfoEditFormComponent handleParentUpdate={this.handleUpdate.bind(this)} role={this.state.role} />
            </>
        )
    }
}

ProfileEditFormFragment.propTypes = {
    user: PropTypes.object.isRequired,
    handleUserUpdate: PropTypes.func.isRequired
};
export default ProfileEditFormFragment;