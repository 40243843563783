import React from "react";
import { Formik } from "formik";
import { Button, Col, Form, InputGroup, ListGroup, Tab } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import * as Yup from 'yup';
import FormToastComponent from "../basics/FormToastComponent";
import Row from "react-bootstrap/Row";
import FormHelper from "../../helpers/FormHelper";
import PropTypes from "prop-types";
import NavigationFormError from "./errors/NavigationFormError";
import PageRepository from '../../repository/PageRepository';
import LoaderComponent from "../basics/layout/LoaderComponent";

class NavigationEditFormComponent extends React.Component {

    constructor(props) {
        super(props);

        let pageNavigation = props.page.page ? JSON.parse(props.page.page.navigation) : {};

        let navigation = {};
        navigation['page-' + props.page.id] = pageNavigation;

        let modules = [];
        let moduleTypes = {};

        let pageModules = JSON.parse(props.page.page.modules);
        if (pageModules) {
            pageModules.forEach(id => {
                let m = props.modules.find(module => module.id === parseInt(id));
                modules.push(m);

                if (['category'].indexOf(m.module.type) < 0) return;

                let t = props.moduleTypes.find(type => type.title === m.module.type);
                moduleTypes[t.title] = t;

                navigation['module-' + m.id] = {};
                if (m.module && ('module-' + m.module.id) in pageNavigation) {
                    navigation['module-' + m.id] = pageNavigation['module-' + m.module.id];
                }
            });
        }

        this.state = {
            isLoading: false,
            page: props.page,
            modules: modules,
            moduleTypes: moduleTypes,
            language: props.language,
            navigation: navigation,
            form_errors: []
        };

        this.form = null;
    }

    onBooleanChange = (module, setting) => {
        let nav = this.state.navigation[module];
        nav[setting] = !nav[setting];

        // PAGE specific
        if (setting === 'as_primary_navigation' && nav[setting]) {
            nav.as_secondary_navigation = false;
            nav.as_footer_navigation = false;
        }
        if (setting === 'as_secondary_navigation' && nav[setting]) {
            nav.as_primary_navigation = false;
            nav.as_footer_navigation = false;
        }
        if (setting === 'as_footer_navigation') {
            nav.as_primary_navigation = !nav[setting];
            nav.as_secondary_navigation = false;
        }

        // CATEGORY specific
        if (setting === 'categories_as_menu' && nav[setting]) {
            nav['categories_as_dropdown'] = !nav['categories_as_menu']
        }
        if (setting === 'categories_as_dropdown' && nav[setting]) {
            nav['categories_as_menu'] = !nav['categories_as_dropdown']
        }

        if (!nav.categories_as_dropdown && !nav.categories_as_menu) {
            nav.subcategories_as_dropdown = false;
        }

        if (!nav.categories_as_dropdown || !nav.subcategories_as_dropdown) {
            nav.num_rows = null;
        }

        this.setState({
            ...this.state.navigation,
            [module]: nav
        });
    }

    onChange = (module, name, value) => {
        let nav = this.state.navigation[module];
        nav[name] = value;

        this.setState({
            ...this.state.navigation,
            [module]: nav
        });
    }

    validation = () => {
        let fields = {};

        if (this.state.navigation['page-' + this.state.page.id].as_button_navigation) {
            fields['page-' + this.state.page.id] = Yup.object({ button_classes: Yup.string().required('CMS.Navigation.Edit.button_classes').max(30, 'CMS.Navigation.Edit.button_classes|30|max') })
        }

        return Yup.object(fields);
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className={"custom-form website-translation"}>
                <Formik
                    enableReinitialize
                    validationSchema={this.validation}
                    initialValues={this.state.navigation}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, formikActions) => {
                        PageRepository.updateNavigation({ navigation: values, id: this.state.page.id })
                            .then(response => {
                                FormToastComponent.successTrans('Navigatie', 'Default.saved')
                                this.props.parent_ref.updateNavigation(response, this.state.page.id);
                                this.props.parent_ref.loadData();
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => {
                        let pageKey = 'page-' + this.state.page.id;

                        return (
                            <>
                                <div className="tab-tables">
                                    <Tab.Container id={"list-group-language-pages"} defaultActiveKey="#page">
                                        <ListGroup>
                                            <ListGroup.Item action href="#page" onClick={() => this.setState({ activeTab: 'page' })}>
                                                <FormattedMessage id="CMS.LeftNavBar.menu.pages">{(value) => value}</FormattedMessage>
                                            </ListGroup.Item>

                                            {this.state.modules.map(module => {
                                                if (!this.state.moduleTypes[module.module.type]) return null;
                                                return (
                                                    <ListGroup.Item action href={'#module-' + module.id} onClick={() => this.setState({ activeTab: 'module-' + module.id })} key={module.id}>
                                                        {module.module.contents[this.state.language].title}
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Tab.Content className="custom-form">
                                                <Tab.Pane eventKey="#page">
                                                    {['show_in_navigation', 'as_primary_navigation', 'as_secondary_navigation', 'as_footer_navigation', 'as_button_navigation'].map(setting => {
                                                        return (
                                                            <Form.Group className="navigation-edit custom-settings">
                                                                <Form.Group>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={'custom-switch-' + setting}
                                                                        label={setting}
                                                                        name={"settings." + setting}
                                                                        onChange={(event) => {
                                                                            this.onBooleanChange(pageKey, setting);
                                                                        }}
                                                                        className={"mb-1 custom-switch"}
                                                                        checked={values[pageKey][setting] ? "checked" : ""}
                                                                    />
                                                                    <FormattedMessage id={"CMS.Navigation.Edit." + setting + ".info"}>
                                                                        {
                                                                            (value) => <p className="input-info">{value}</p>
                                                                        }
                                                                    </FormattedMessage>
                                                                </Form.Group>
                                                            </Form.Group>
                                                        )
                                                    })}

                                                    {values[pageKey].as_button_navigation && (
                                                        <Form.Group className="navigation-edit custom-settings">
                                                            <Form.Group>
                                                                <InputGroup className="xl">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text id="inputGroupPrependButtonClasses">
                                                                            button_classes
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="page.button_classes"
                                                                        value={values[pageKey].button_classes}
                                                                        onChange={(event) => {
                                                                            this.onChange(pageKey, 'button_classes', event.target.value);
                                                                        }}
                                                                        isInvalid={!!(errors[pageKey] && errors[pageKey].button_classes) || !!this.state.form_errors.button_classes}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors[pageKey] && errors[pageKey].button_classes && (
                                                                            <NavigationFormError error={errors[pageKey].button_classes} />
                                                                        )}
                                                                        {this.state.form_errors.button_classes && (
                                                                            <p>{this.state.form_errors.button_classes}</p>
                                                                        )}
                                                                    </Form.Control.Feedback>
                                                                    <FormattedMessage id={"CMS.Navigation.Edit.button_classes.info"}>
                                                                        {
                                                                            (value) => <p className="input-info">{value}</p>
                                                                        }
                                                                    </FormattedMessage>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Form.Group>
                                                    )}
                                                </Tab.Pane>

                                                {/** MODULES */}
                                                {this.state.modules.map(module => {
                                                    let type = this.state.moduleTypes[module.module.type];
                                                    if (!type) return null;

                                                    let moduleKey = 'module-' + module.id;
                                                    let settings = JSON.parse(type.settings)['navigation'];

                                                    return (
                                                        <Tab.Pane eventKey={'#module-' + module.id} key={module.id}>
                                                            {settings.map(setting => {
                                                                if (module.module.type === 'category') {
                                                                    if (setting.key === 'num_rows' && (!values[moduleKey].categories_as_dropdown || !values[moduleKey].subcategories_as_dropdown)) {
                                                                        return null;
                                                                    }
                                                                }

                                                                if (setting.key === 'subcategories_as_dropdown') {
                                                                    let moduleSettings = JSON.parse(module.module.settings);
                                                                    if (!moduleSettings.has_subcategories) return null;

                                                                    let nav = this.state.navigation[moduleKey];
                                                                    if (!nav.categories_as_menu && !nav.categories_as_dropdown) return null;
                                                                }

                                                                switch (setting.type) {
                                                                    case 'boolean':
                                                                        return (
                                                                            <Form.Group className="navigation-edit custom-settings">
                                                                                <Form.Group>
                                                                                    <Form.Check
                                                                                        type="switch"
                                                                                        id={'custom-switch-' + moduleKey + '-' + setting.key}
                                                                                        label={setting.key}
                                                                                        name={'settings.' + moduleKey + '.' + setting.key}
                                                                                        onChange={(event) => {
                                                                                            this.onBooleanChange(moduleKey, setting.key);
                                                                                        }}
                                                                                        className={"mb-1 custom-switch"}
                                                                                        checked={values[moduleKey][setting.key] ? "checked" : (setting.default ? 'checked' : '')}
                                                                                    />
                                                                                    <FormattedMessage id={"CMS.Navigation.Edit." + setting.key + ".info"}>
                                                                                        {
                                                                                            (value) => <p className="input-info">{value}</p>
                                                                                        }
                                                                                    </FormattedMessage>
                                                                                </Form.Group>
                                                                            </Form.Group>
                                                                        );
                                                                    case 'text':
                                                                        return (
                                                                            <Form.Group className="navigation-edit custom-settings">
                                                                                <Form.Group>
                                                                                    <InputGroup className="no-suffix">
                                                                                        <InputGroup.Prepend>
                                                                                            <InputGroup.Text
                                                                                                id="inputGroupPrependEyecatcherWidth">{setting.key}
                                                                                            </InputGroup.Text>
                                                                                        </InputGroup.Prepend>
                                                                                        <Form.Control
                                                                                            aria-describedby={setting.key}
                                                                                            type={"text"}
                                                                                            name={'settings.' + moduleKey + '.' + setting.key}
                                                                                            //onChange={this.onChange}
                                                                                            onChange={(event) => {
                                                                                                this.onChange(moduleKey, 'num_rows', event.target.value);
                                                                                            }}
                                                                                            isInvalid={!!(errors[moduleKey] && errors[moduleKey].num_rows) || !!this.state.form_errors.num_rows}
                                                                                            value={values[moduleKey].num_rows}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            {errors[moduleKey] && errors[moduleKey].num_rows && (
                                                                                                <NavigationFormError error={errors[moduleKey].num_rows} />
                                                                                            )}
                                                                                            {this.state.form_errors.num_rows && (
                                                                                                <p>{this.state.form_errors.num_rows}</p>
                                                                                            )}
                                                                                        </Form.Control.Feedback>
                                                                                        <FormattedMessage id={"CMS.Navigation.Edit." + setting.key + ".info"}>
                                                                                            {
                                                                                                (value) => <p className="input-info">{value}</p>
                                                                                            }
                                                                                        </FormattedMessage>
                                                                                    </InputGroup>
                                                                                </Form.Group>
                                                                            </Form.Group>
                                                                        );
                                                                    default:
                                                                        return null;
                                                                }
                                                            })}
                                                        </Tab.Pane>
                                                    )
                                                })}

                                                <Row className={"buttons"}>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <FormattedMessage id={"CMS.Form.save"}>
                                                                {
                                                                    value => <Button type={"submit"} onClick={() => FormHelper.submitFormErrorToast(this)}>{value}</Button>
                                                                }
                                                            </FormattedMessage>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <FormattedMessage id={"CMS.Websites.Translation.Form.cancel"}>
                                                                {
                                                                    value => <Button variant={"secondary"}
                                                                        onClick={() => this.props.parent_ref.setState({
                                                                            showEditModal: false
                                                                        })}>{value}</Button>
                                                                }
                                                            </FormattedMessage>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab.Content>
                                        </Form>
                                    </Tab.Container>
                                </div>
                            </>
                        )
                    }}
                </Formik>
            </div >
        );
    }
}

NavigationEditFormComponent.propTypes = {
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired,
    page: PropTypes
}

export default withRouter(NavigationEditFormComponent);