import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ToolTipInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_tool_tip: false
        }
    }

    toggleToolTip = () => this.setState({ show_tool_tip: !this.state.show_tool_tip })
    closeToolTip = () => this.setState({ show_tool_tip: false })

    render() {
        return (
            <div className="icon-tooltip">
                <Link to="#" onClick={this.toggleToolTip} className="info-icon ml-1">
                    <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </Link>
                <div className={this.state.show_tool_tip === true ? "info-tooltip show" : "info-tooltip"}>
                    <p className="title">Beschikbare tags</p>
                    <div className="text">
                        <table>
                            <tbody>
                                <tr>
                                    <td>%strong%</td>
                                    <td><strong>vet</strong></td>
                                    <td>%end_strong%</td>
                                </tr>
                                <tr>
                                    <td>%em%</td>
                                    <td><em>italic</em></td>
                                    <td>%end_em%</td>
                                </tr>
                                <tr>
                                    <td>%small%</td>
                                    <td><small>kleiner</small></td>
                                    <td>%end_small%</td>
                                </tr>
                                <tr>
                                    <td>%u%</td>
                                    <td><u>onderlijnd</u></td>
                                    <td>%end_u%</td>
                                </tr>
                                <tr>
                                    <td>%br%</td>
                                    <td>Line break</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Link to="#" onClick={this.closeToolTip} className="close">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </Link>
                </div>
            </div>
        )
    }
}

export default ToolTipInfo;