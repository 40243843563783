import FormToastComponent from "../components/basics/FormToastComponent";

class FormHelper {

    /**
     * Show error when the form in invalid
     *
     * @param component
     */
    static submitFormErrorToast(component) {
        component.setState({
            form_errors: []
        });
        if (component.props.parent !== undefined) {
            component.props.parent.setState({
                form_errors: []
            })
        }

        setTimeout(() => {
            if (component.form !== null && component.form !== undefined) {
                let context = component.form.getFormikContext();
                setTimeout(() => {
                    if (Object.entries(context.errors).length !== 0) {
                        FormToastComponent.errorTrans('CMS.Form.invalid')
                    }
                }, 100)
            }
        })
    }

    /**
     * When an api call has an error then it needs to be stored in a different state.
     *
     * @param form
     * @param error
     */
    static handleSubmitError(form, error) {
        let messageShown = false;
        let data = error.response !== undefined ? error.response.data : error.data;
        if (data != null) {
            if (data.message !== undefined && !this.isJSON(data.message)) {
                FormToastComponent.error(data.message);
                messageShown = true;
            } else {
                let error = data.error != null ? data.error : (data.message != null ? JSON.parse(data.message) : null)
                if (error != null) {
                    form.setState({
                        form_errors: error
                    });
                    /*Object.keys(error).forEach((value, index) => {
                        let errors = error[value];
                        errors.forEach(error => {
                            FormToastComponent.error(error);
                            messageShown = true
                        })
                    })*/
                }
            }
        }

        if (!messageShown) {
            console.error(error);
            FormToastComponent.errorTrans('CMS.Form.invalid')
        }
    }

    static isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    static fakeDelete() {
        return new Promise(resolve => resolve(true));
    }
}

export default FormHelper;
