import React from "react";
import { Link } from "react-router-dom";
import { LoaderComponent } from "./../../basics/layout";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModuleRepository } from "../../../repository";
import Select from "react-select";
import FormattedMessageString from "../../basics/FormattedMessageString";
import { ButtonIcon } from "../../basics/button";

class ProductFilterWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            expanded: false,
            categories: [],
            filters: {},
            selectedFilters: this.initFilters()
        };
    }

    initFilters = () => {
        return {
            category: null,
            image: null,
            stock: null,
            spotlight: null,
            status: null,
        };
    }

    componentDidMount() {
        let promises = [];

        if (this.props.parent.state.settings.use_categories) {
            promises.push(ModuleRepository.getSettings());
        }

        Promise.all(promises)
            .then(result => {
                let categories = [];

                if (this.props.parent.state.settings.use_categories) {
                    categories = result[0].data.settings.references.map(item => {
                        if (item.children.length) return false;
                        return { value: item.id, label: item.depthLabel.join(' > ') };
                    }).filter(x => x !== false);

                    categories = [...[{ value: null, label: <FormattedMessageString id="CMS.Product.filter.categories.placeholder" /> }], ...categories];
                }

                this.setState({ categories: categories }, () => this.assembleFiltersData());
            });
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <Card className={'custom-data-table mb-2 ' + (!this.state.expanded ? 'pb-0' : '')}>
                <div className="table-header" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                    <Link color="primary" className="default" to="#" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                        <FontAwesomeIcon icon={['fa', (this.state.expanded ? 'fa-chevron-up' : 'fa-chevron-down')]} />
                    </Link>
                    <FormattedMessage id="CMS.Product.filter.title">
                        {value =>
                            <p>
                                <FontAwesomeIcon icon={['fa', 'fa-filter']} />
                                <span className="ml-1">{value}</span>
                            </p>
                        }
                    </FormattedMessage>
                </div>

                {this.state.expanded && (
                    <>
                        <div className="card-body border-top">
                            <Row>
                                {Object.keys(this.state.filters).map(filter => (
                                    <Col lg={3} key={filter}>
                                        <Row>
                                            <Col xs={12}>
                                                <FormattedMessage id={this.state.filters[filter].label}>
                                                    {(value) => <Form.Label>{value}</Form.Label>}
                                                </FormattedMessage>
                                            </Col>

                                            <Form.Group as={Col} xs={12}>
                                                <Select classNamePrefix="react-select" className="react-select-container"
                                                    options={this.state.filters[filter].options}
                                                    onChange={event => this.filter(filter, event.value)}
                                                    value={this.state.filters[filter].options.filter(option => this.state.selectedFilters[filter] === option.value)}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <div className="table-footer border-top pt-3">
                            <ButtonIcon variant="outline-primary" size="sm" icon="fa-times" className="mr-1" onClick={() => this.resetFilters()}>
                                <FormattedMessageString id="CMS.Product.filter.buttons.reset" />
                            </ButtonIcon>
                            <ButtonIcon variant="outline-primary" size="sm" icon="fa-file-excel" onClick={() => this.props.parent.export()}>
                                <FormattedMessageString id="CMS.Product.filter.buttons.export" />
                            </ButtonIcon>
                        </div>
                    </>
                )}
            </Card>
        );
    }

    filter = (field, value) => this.setState({ selectedFilters: { ...this.state.selectedFilters, [field]: value } }, () => this.props.parent.filter(this.state.selectedFilters));
    resetFilters = () => this.setState({ selectedFilters: this.initFilters() }, () => this.props.parent.filter(this.state.selectedFilters));

    assembleFiltersData = () => {
        let data = {};

        if (this.props.parent.state.settings.use_categories) {
            data.category = {
                label: 'CMS.Product.filter.categories.label',
                options: this.state.categories
            };
        }

        if (this.props.parent.state.settings.show_cover_image) {
            data.image = {
                label: 'CMS.Product.filter.image.label',
                options: [
                    { value: null, label: <FormattedMessageString id="CMS.Product.filter.image.placeholder" /> },
                    { value: true, label: <FormattedMessageString id="CMS.Product.filter.image.option.has_image" /> },
                    { value: false, label: <FormattedMessageString id="CMS.Product.filter.image.option.has_no_image" /> }
                ]
            };
        }

        if (this.props.parent.state.settings.use_spotlight) {
            data.spotlight = {
                label: 'CMS.Product.filter.spotlight.label',
                options: [
                    { value: null, label: <FormattedMessageString id="CMS.Product.filter.spotlight.placeholder" /> },
                    { value: true, label: <FormattedMessageString id="CMS.Product.filter.spotlight.option.in_spotlight" /> },
                    { value: false, label: <FormattedMessageString id="CMS.Product.filter.spotlight.option.out_of_spotlight" /> }
                ]
            };
        }

        if (this.props.parent.state.settings.show_stock) {
            data.stock = {
                label: 'CMS.Product.filter.stock.label',
                options: [
                    { value: null, label: <FormattedMessageString id="CMS.Product.filter.stock.placeholder" /> },
                    { value: true, label: <FormattedMessageString id="CMS.Product.filter.stock.option.in_stock" /> },
                    { value: false, label: <FormattedMessageString id="CMS.Product.filter.stock.option.out_of_stock" /> }
                ]
            };
        }

        data.status = {
            label: 'CMS.Product.filter.status.label',
            options: [
                { value: null, label: <FormattedMessageString id="CMS.Product.filter.status.placeholder" /> },
                { value: true, label: <FormattedMessageString id="CMS.Product.filter.status.option.active" /> },
                { value: false, label: <FormattedMessageString id="CMS.Product.filter.status.option.inactive" /> }
            ]
        };

        this.setState({ filters: data, loading: false });
    }
}

export default ProductFilterWidget;