import React from "react";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent } from "../basics/table";
import Website from "../../models/website/Website";
import { ListGroup, Tab } from "react-bootstrap";
import { Application, CountryHelper, CheckPermissions, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { TeamRepository, WebsiteRepository } from "../../repository";
import FormToastComponent from "../basics/FormToastComponent";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ToolTipLink from "../basics/ToolTipLink";
import LoaderComponent from "../basics/layout/LoaderComponent";
import TeamOverviewTreeComponent from "./TeamOverviewTreeComponent";

class TeamOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            website: null,
            isLoading: true,
            toggler: [],
            treeData: [],
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));
    }

    columns(index) {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'first_name',
                text: <FormattedMessageString id="CMS.Team.overview.table.first_name" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.team.contents[index];
                    let contentsB = rowB.team.contents[index];

                    if (order === 'asc')
                        return contentsA.first_name.toLowerCase() < contentsB.first_name.toLowerCase() ? -1 : 1;
                    else if (order === 'desc')
                        return contentsB.first_name.toLowerCase() > contentsA.first_name.toLowerCase() ? 1 : -1;
                },
                isDummyField: true,
                formatter: (cellContent, row) => row.team.contents[index].first_name
            },
            {
                dataField: 'last_name',
                text: <FormattedMessageString id="CMS.Team.overview.table.last_name" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.team.contents[index];
                    let contentsB = rowB.team.contents[index];

                    if (order === 'asc')
                        return contentsA.last_name.toLowerCase() < contentsB.last_name.toLowerCase() ? -1 : 1;
                    else if (order === 'desc')
                        return contentsB.last_name.toLowerCase() > contentsA.last_name.toLowerCase() ? 1 : -1;
                },
                isDummyField: true,
                formatter: (cellContent, row) => row.team.contents[index].last_name
            },
            this.state.settings.use_categories ?
                {
                    dataField: 'category',
                    text: <FormattedMessageString id="CMS.Team.overview.table.category" />,
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                        let contentsA = rowA.team.contents[index];
                        let contentsB = rowB.team.contents[index];

                        if (order === 'asc')
                            return contentsA.category.title.toLowerCase() < contentsB.category.title.toLowerCase() ? -1 : 1;
                        else if (order === 'desc')
                            return contentsB.category.title.toLowerCase() > contentsA.category.title.toLowerCase() ? 1 : -1;
                    },
                    formatter: (cellContent, row) => row.team.contents[index].category.title
                } :
                {},
            this.state.settings.use_reference ?
                {
                    dataField: 'references',
                    text: <FormattedMessageString id="CMS.Team.overview.table.references" />,
                    sort: false,
                    formatter: (cellContent, row) => row.team.contents[index].references.map(r => r.title).join(', ')
                } :
                {},
            {
                dataField: 'updated',
                text: <FormattedMessageString id="CMS.Team.overview.table.last_updated" />,
                isDummyField: true,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.team.contents[index];
                    let contentsB = rowB.team.contents[index];

                    if (order === 'asc') {
                        return Date.parse(contentsA.updated_at) - Date.parse(contentsB.updated_at)
                    }
                    else if (order === 'desc') {
                        return Date.parse(contentsB.updated_at) - Date.parse(contentsA.updated_at)
                    }
                },
                formatter: (cellContent, row) => {
                    let contents = row.team.contents[index];
                    if (contents === undefined) return null;
                    return (
                        <div className="updated_by">
                            {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                            {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.team.contents[index];

                    return <div className="actions">
                        {this.state.settings.show_photoalbum && (
                            <ToolTipLink className="default" to={"photoalbum/" + row.id}
                                title={"CMS.Team.overview.tooltip.show_photoalbum"}>
                                <FontAwesomeIcon icon={['far', 'images']} size="lg" />
                            </ToolTipLink>
                        )}
                        {this.state.settings.show_videoalbum && (
                            <ToolTipLink className="default" to={"videoalbum/" + row.id + (content !== undefined ? '/' + content.language : '/')}
                                title={"CMS.Team.overview.tooltip.show_videoalbum"}>
                                <FontAwesomeIcon icon={['far', 'video-plus']} size="lg" />
                            </ToolTipLink>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            row.team.active ?
                                <Link onClick={() => this.toggleActive(row)} className="active" to="#">
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </Link>
                                :
                                <Link onClick={() => this.toggleActive(row)} className="inactive" to="#">
                                    <FontAwesomeIcon icon={['fas', 'times']} />
                                </Link>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableEditActionComponent id={row.team.id} custom={content !== undefined ? '/' + content.language : '/'} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                    </div >
                }
            }
        ];
    }

    updateData = () => TeamRepository.all().then(response => this.props.parent.setState({ rows: response }));

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({
            reload: true
        }, () => {
            this.props.parent.setState({
                reload: false
            }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({ toggler: toggler, language: this.state.language })
            })
        })
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-teams"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) =>
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) =>
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                {
                                    this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ?
                                        this.tree(index)
                                        :
                                        this.table(index)
                                }
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

    table = (index) => (
        <div className={"custom-data-table team"}>
            <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={false}
                title={this.props.title_id} type={this.props.type}
                custom={CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ? this.switch(index) : undefined}
                subRows={false}
                sort={{
                    dataField: 'published_at',
                    order: 'desc'
                }} />
            <DeleteModalComponent parent={this} />
        </div>
    )

    tree = (index) => (
        <div className={'custom-data-table teams'}>
            <div className="table-wrapper">
                <div className="table-header">
                    <FormattedMessage id="CMS.Module.overview.tree.title" values={{ module: this.props.title_id }}>{(value) => <p>{value}</p>}</FormattedMessage>
                    {this.switch(index)}
                </div>
                <TeamOverviewTreeComponent data={this.state.rows} index={index} parent={this} />
            </div>
        </div>
    )

    switch = (index) => (
        <div className="toggle-tree" data-toggle="buttons" data-index={index}>
            <button type="button" data-toggle="button" data-view-type={"table"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? null : 'disabled'}>
                <FontAwesomeIcon icon={['far', 'th']} />
            </button>
            <button type="button" data-toggle="button" data-view-type={"tree"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? 'disabled' : ''}>
                <FontAwesomeIcon icon={['far', 'list']} />
            </button>
        </div>
    );

    toggleActive = (_row) => TeamRepository.toggle(_row.team.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.team.id === _row.team.id) {
                    let team = row.team;
                    team.active = response;
                    return {
                        ...row,
                        team: team
                    }
                }
                return row;
            });

            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                this.setState({ reload: false })
            });
        })
        .catch(error => FormToastComponent.errorTrans('CMS.Team.overview.toggle.failed'));
}

export default TeamOverviewTableComponent;