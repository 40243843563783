import ArrayHelper from "../../helpers/ArrayHelper";
import FieldContent from "./FieldContent";
import Option from "./Option";

export default class Field {
    id;
    type;
    order;
    required;
    css_class;
    options;
    label;

    constructor() {
        this.required = true;
        this.options = [];
    }

    static fromJson(json, language) {
        let field = new Field();

        field.id = json.id;
        field.type = json.type;
        field.order = json.order;
        field.required = json.required;
        field.css_class = json.css_class;

        let contents = json.contents.filter(content => content.language === language)[0];

        field.options = [];
        json.options.forEach(option => {
            field.options.push(Option.fromJson(option, language));
        });
        ArrayHelper.sortByValue(field.options, 'order')

        return { ...field, ...FieldContent.fromJson(contents) };
    }
}
