import React from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import EyeCatcherUploadComponent from "./EyeCatcherUploadComponent";
import EyeCatcherOverviewComponent from "./EyeCatcherOverviewComponent";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import FormToastComponent from "../../basics/FormToastComponent";
import MediaAlbumUploadComponent from "../../media/album/MediaAlbumUploadComponent";
import PageRepository from "../../../repository/PageRepository";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class EyeCatcherComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            reloadOverview: false,
            media: null,
            showModal: false,
            currentModalLanguage: null
        }
    }

    openModal = () => this.setState({ showModal: true })
    closeModal = (func = null) => this.setState({ showModal: false, currentModalLanguage: null }, func)

    componentDidMount() {
        this.props.get_promise // call the promise for getting the media
            .then(response => {
                if (response !== undefined && response.media) {
                    this.setState({
                        media: response.media, // set the given media to the state
                        isText: response.is_text !== undefined ? response.is_text : false,
                        isLoading: false // stop the loading
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.media.not.found")
                }
            })
            .catch(() => FormToastComponent.errorTrans("CMS.Media.Album.media.not.found"))
    }

    reload = () => this.setState({ reloadOverview: true }, () =>
        PageRepository.getEyeCatchers(this.props.id)
            .then(response => {
                if (response !== undefined && response.media) {
                    this.setState({
                        media: response.media,
                        isText: response.is_text !== undefined ? response.is_text : false,
                        reloadOverview: false
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.media.not.found")
                }
            })
            .catch(() => FormToastComponent.errorTrans("CMS.Media.Album.media.not.found"))
    )

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <>
                <FormattedMessage id="CMS.Page.eyecatcher.text">
                    {(value) => <p className="overview-text">{value}</p>}
                </FormattedMessage>
                <div className="wrapper">
                    {this.state.isText ?
                        <ModalFormFragment title_id="CMS.Page.eyecatcher.modal.title" size="lg"
                            show={this.state.showModal} onHide={this.closeModal}
                        >
                            <EyeCatcherUploadComponent {...this.props} parent={this} />
                        </ModalFormFragment>
                        :
                        <MediaAlbumUploadComponent type={this.props.type} id={this.props.id} parent={this} />
                    }
                    {this.state.reloadOverview ? null :
                        <EyeCatcherOverviewComponent {...this.props} media={this.state.media} parent={this}
                            openModal={this.openModal} ref={ref => this.overview = ref}
                            isText={this.state.isText} />
                    }
                </div>
            </>
        );
    }
}

export default withRouter(EyeCatcherComponent);