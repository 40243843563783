import * as Yup from "yup"

export default class ContentBlockContent {
    id;
    language;
    summary;
    title;
    content;
    image;

    static schema_add = Yup.object({
        summary: Yup.string().required('CMS.ContentBlock.Form.summary').max(255, 'CMS.ContentBlock.Form.summary|255|max'),
        title: Yup.string().required('CMS.ContentBlock.Form.title').max(255, 'CMS.ContentBlock.Form.title|255|max'),
        content: Yup.object({ text: Yup.string().required('CMS.ContentBlock.Form.content') }),
        url: Yup.string().required('CMS.ContentBlock.Form.url').max(255, 'CMS.ContentBlock.Form.url|255|max')
    });

    static fromJson(json) {
        let contentBlockContent = new ContentBlockContent();
        contentBlockContent.id = json['id'];
        contentBlockContent.language = json['language'];
        contentBlockContent.title = json['title'];
        contentBlockContent.summary = json['summary'];
        contentBlockContent.content = json['content'] != null ? json['content'] : '{}';
        contentBlockContent.image = json['image'];
        contentBlockContent.url = json['url'];
        return contentBlockContent;
    }
}
