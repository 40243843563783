import * as React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import Website from "../../../models/website/Website";

class StandardTableFormInputComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value
        }
    }

    getValue() {
        return this.state.value;
    }

    componentDidMount() {
        if (this.props.didMount) {
            this.props.didMount();
        }
    }

    render() {
        const { value, onUpdate, ...rest } = this.props;

        return (
            <Form.Control
                {...rest}
                type="text"
                onChange={(event) => this.setState({ value: Website.removeHttpOrHttpsInUrl(event.target.value) })}
                value={this.state.value}
            >
            </Form.Control>
        );
    }
}

StandardTableFormInputComponent.propTypes = {
    value: PropTypes.string,
    onUpdate: PropTypes.func.isRequired
};
export default StandardTableFormInputComponent;
