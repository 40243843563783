import * as Yup from 'yup';
import FaqContent from "./FaqContent";

export default class Faq {
    id;
    website;
    active;
    category;
    content = [];

    static schema_add = Yup.object({
        question: Yup.string().required('CMS.Faq.Form.name').max(255, 'CMS.Faq.Form.name.255.max'),
        answer: Yup.string().required
    });

    static fromJson(json)
    {
        let faq = new Faq();
        faq.id = json['id'];
        faq.active = json['active'];
        faq.category = json['category'];
        faq.website = json['website'];
        faq.settings = json['settings'] === "[]" ? "{}" : json['settings']
        faq.content = json['contents'].map(function (content) {
            return FaqContent.fromJson(content);
        });
        return faq;
    }

}
