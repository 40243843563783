import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { PageHelper } from "../../helpers";

class UrlInternalFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            loading: true,
            language: props.language ? props.language : 'nl',
            links: []
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.selectUrl = this.selectUrl.bind(this);
    }

    componentDidMount() {
        PageHelper.generateInternalLinkList(this.state).then(list => this.setState({ links: list, loading: false }));
    }

    toggleModal = () => this.setState({ show: !this.state.show })

    selectUrl = (event) => {
        this.props.handleUrlSelect(event);
        this.toggleModal();
    }

    render() {
        return (
            <>
                <FormattedMessage id="CMS.Page.Content.Form.internal.url.link">
                    {(value) => (
                        <Link to="#" style={{ color: "#95A58B", fontSize: "13px", textDecoration: "underline" }}
                            onClick={this.toggleModal}>
                            {value}
                        </Link>
                    )}
                </FormattedMessage>

                <Modal show={this.state.show} onHide={this.toggleModal} animation={false}
                    className="internal-pages-select-modal" centered>
                    <Modal.Header closeButton>
                        <FormattedMessage id="CMS.Page.Content.Form.internal.url.title">
                            {(value) => <Modal.Title>{value}</Modal.Title>}
                        </FormattedMessage>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="internal-pages-select">
                            {this.state.loading && 'loading...'}
                            {this.renderLinks()}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    renderLinks = () => {
        let links = [];

        this.state.links.forEach(link => this.renderLink(link, links));

        return links
    }

    renderLink = (link, links, depth = 0) => {
        let classes = "internal-pages-select-item sub-";
        if (link.disabled) classes += " disabled";

        links.push(
            <div onClick={!link.disabled ? this.selectUrl : null} className={classes} data-url={link.url} key={link.url} >
                <p>{('-'.repeat(depth) + ' ' + link.title).trim()}</p>
                <FontAwesomeIcon icon={['fal', 'arrow-right']} className="arrow" />
            </div>
        );

        if (link.children.length > 0) {
            link.children.forEach(child => this.renderLink(child, links, depth + 1))
        }

        return links;
    }
}

UrlInternalFormComponent.propTypes = {
    values: PropTypes.array.isRequired,
    parent: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired
};

export default UrlInternalFormComponent;