import React from 'react';
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";

class FormContactCompanyNameError extends React.Component {
    render() {
        if (this.props.error === "company.max") {
            return <FormMaxError
                field_name={"CMS.User.Form." + this.props.error.slice(0, -4)}
                max={100}/>
        } else {
            return <FormRequiredError field_name={"CMS.User.Form." + this.props.error} />
        }
    }
}


FormContactCompanyNameError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default FormContactCompanyNameError;