import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import FormattedMessageString from "../FormattedMessageString";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';

class TableDropdownActionComponent extends React.Component {

    componentDidMount() {
        this.reset();
        document.addEventListener('click', this.handleClickListener);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickListener);
    }

    render() {
        if (this.props.items.length <= 0) return null;

        return (
            <div className="table-dropdown">
                <Button variant="link" id={'dropdown-' + this.props.id} onClick={() => this.props.actions.applicationUpdate('table.dropdown', this.props.id)}>
                    <FontAwesomeIcon icon={['far', this.props.icon]} />
                </Button>

                <ul target={'dropdown-' + this.props.id} className={'table-dropdown-items ' + (this.props.application['table.dropdown'] !== this.props.id ? 'visually-hidden' : '')}>
                    {this.props.items.map(action => (
                        <li key={action.key} className="table-dropdown-item">
                            <Link to="#" className="table-dropdown-link btn btn-link" onClick={() => this.handleClick(action)}>
                                <FontAwesomeIcon className="table-dropdown-icon" icon={['far', action.icon]} />
                                <FormattedMessageString id={action.label} />
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    reset = () => this.props.actions.applicationUpdate('table.dropdown', null);

    handleClick = (action) => {
        this.reset();
        action.callback();
    }

    setDropdownPosition = () => {
        let id = 'dropdown-' + this.props.id;

        let tooltip = document.getElementById(id);
        let dropdown = tooltip.parentElement.querySelector('ul[target="' + id + '"]');
        if (!tooltip || !dropdown) return;

        dropdown.style.top = (tooltip.getBoundingClientRect().top + 35) + 'px';
        dropdown.style.left = (tooltip.getBoundingClientRect().left) + 'px';
    }

    handleClickListener = (e) => {
        if (e.target.closest('button[id^="dropdown-"]')) return;
        this.reset();
    }
}

TableDropdownActionComponent.propTypes = {
    id: PropTypes.any.isRequired,
    icon: PropTypes.string,
    items: PropTypes.array
};

const mapStateToProps = state => ({ application: state.application });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
export default connect(mapStateToProps, mapDispatchToProps)(TableDropdownActionComponent);