import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckActive, CheckPermissions, GeneralSettingsHelper } from "../../../helpers";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import Module from "../../../models/module/Module";
import ModuleContent from "../../../models/module/ModuleContent";
import User from "../../../models/user/User";
import ModuleTypeEnum from "../../../enums/ModuleTypeEnum";
import AttendeeMenuComponent from "../../attendee/AttendeeMenuComponent";
import EventMenuComponent from "../../event/EventMenuComponent";

class LeftNavMenuComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roles: this.props.user.roles,
            language: localStorage.getItem('language'),
            fullAccess: CheckPermissions.atLeast(this.props.user.roles, UserRolesEnum.MANAGER),
            activeModules: []
        }
    }

    componentDidMount() {
        let user = User.get();

        if (user && user.permissions.length > 0) {
            let activeModules = [];

            user.permissions.forEach(permission => {
                if (permission.model_string_type === 'page') activeModules.push('pages');
                if (permission.model_string_type === 'form') activeModules.push('forms');
                if (permission.model_string_type === 'content_block') activeModules.push('content_blocks');
                if (permission.model_string_type === 'translation') activeModules.push('translations');
                if (permission.model_string_type === 'module') activeModules.push(permission.model_id)
            });

            this.setState({ activeModules: activeModules });
        }
    }

    render() {
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && GeneralSettingsHelper.is()) {
            return (
                <div className="left-navigation-wrapper">
                    <Nav className="left-navigation justify-content-center" activeKey="/home">
                        <Nav.Item>
                            <NavLink exact to="/website/settings" className="nav-link" activeClassName="active" isActive={CheckActive.check('settings')}>
                                <FontAwesomeIcon icon={['fal', 'sliders']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.settings" /></span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to={"/pages/overview"} className="nav-link">
                                <FontAwesomeIcon icon={['far', 'book-open']} />
                                <span><FormattedMessage id={"CMS.LeftNavBar.menu.pages"} /></span>
                            </NavLink>
                        </Nav.Item>
                    </Nav>
                </div >
            );
        }

        return (
            <div className="left-navigation-wrapper">
                <Nav className="left-navigation justify-content-center" activeKey="/home">
                    {this.userHasAccess('pages') && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/pages/overview" className="nav-link">
                                <FontAwesomeIcon icon={['far', 'book-open']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.pages" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}
                    {CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/navigation/overview" className="nav-link">
                                <FontAwesomeIcon icon={['far', 'map-signs']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.navigation" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}
                    {this.userHasAccess('content_blocks') && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/content-blocks/overview" className="nav-link">
                                <FontAwesomeIcon icon={['far', 'shapes']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.content_blocks" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}
                    {this.userHasAccess('translations') && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/website/translations" className="nav-link"
                                isActive={CheckActive.check('website/translations')}
                            >
                                <FontAwesomeIcon icon={['far', 'comment-alt']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.translations" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}
                    {this.userHasAccess('forms') && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/forms/overview" className="nav-link"
                                isActive={CheckActive.check('forms/overview')}>
                                <FontAwesomeIcon icon={['far', 'clipboard-list']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.forms" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}

                    {CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER) && (
                        <Nav.Item>
                            <NavLink onClick={() => Module.change(null)} to="/website/settings" className="nav-link"
                                isActive={CheckActive.check('/website/settings')}>
                                <FontAwesomeIcon icon={['far', 'sliders-h']} />
                                <span><FormattedMessage id="CMS.LeftNavBar.menu.settings" /></span>
                            </NavLink>
                        </Nav.Item>
                    )}
                    {this.props.modules.map(module => {
                        if (!this.userHasAccess(module.id) || !module.module.active) return null;
                        if (module.module.type === ModuleTypeEnum.WEBSHOP) return null;

                        let moduleContent = ModuleContent.getByLanguage(module.module.contents, this.state.language);

                        return (
                            <Nav.Item key={module.id} className={this.state["showSubMenu" + module.id] ? 'open' : 'closed'}>
                                <NavLink onClick={() => Module.change(module)} to={"/module/" + Module.getRoute(module.module) + "/" + module.id + "/overview"} className="nav-link">
                                    <FontAwesomeIcon icon={['far', Module.getIcon(module.module)]} />
                                    <span>{moduleContent.title}</span>
                                </NavLink>

                                {Module.renderSubMenu(module.module) && (
                                    <>
                                        <div className="subnav-toggle" onClick={() => { Module.change(null); this.setState({ ["showSubMenu" + module.id]: !this.state["showSubMenu" + module.id] }) }}>
                                            <FontAwesomeIcon icon={['far', this.state["showSubMenu" + module.id] ? 'fa-chevron-up' : 'fa-chevron-down']} />
                                        </div>

                                        {this.renderSubMenu(module)}
                                    </>
                                )}
                            </Nav.Item>
                        )
                    })}
                </Nav>
            </div>
        );
    }

    renderSubMenu = (module) => {
        switch (module.module.type) {
            case ModuleTypeEnum.ATTENDEE:
                return (
                    <div className="subnav">
                        <AttendeeMenuComponent module={module} />
                    </div>
                )
            case ModuleTypeEnum.EVENT:
                return (
                    <div className="subnav">
                        <EventMenuComponent module={module} />
                    </div>
                )
            default:
                return null;
        }
    }

    userHasAccess = (module) => this.state.fullAccess ? true : this.state.activeModules.indexOf(module) > -1;
}

export default LeftNavMenuComponent;