import React from "react";
import MediaHelper from "../../../helpers/MediaHelper";
import BlogFormError from "../errors/BlogFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import moment from "moment";
import MediaContentUploadComponent from "../../media/content/MediaContentUploadComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";

class BlogTextFormComponent extends React.Component {
    static fields(settings) {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.title",
                                type: "text",
                                name: 'title',
                                customFeedback: BlogFormError,
                                formErrorName: "title"
                            }
                        ]
                    },
                    {
                        type: "col",
                        md: 8,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.published_at",
                                type: "datetime",
                                name: 'published_at',
                                customProps: { min: moment().format('YYYY-MM-DD') },
                                customFeedback: BlogFormError,
                                formErrorName: "published_at"
                            }
                        ]
                    },
                    settings.show_summary ? {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.summary",
                                type: "textarea",
                                name: 'summary',
                                customFeedback: BlogFormError,
                                formErrorName: "summary"
                            }
                        ]
                    } : {},
                    !settings.content_with_blocks ? {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        customProps: { label: 'CMS.Blog.Content.Form.content', placeholder: 'CMS.Blog.Content.Form.content.placeholder' }
                    } : {},
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.seo_title",
                                type: "text",
                                name: 'seo_title',
                                optional: true,
                                customFeedback: BlogFormError,
                                formErrorName: "seo_title"
                            }
                        ]
                    },
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.seo_tags",
                                type: "tags",
                                name: 'seo_tags',
                                optional: true,
                                customFeedback: BlogFormError,
                                formErrorName: "seo_tags"
                            }
                        ]
                    },
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Blog.Content.Form.seo_description",
                                type: "textarea",
                                name: 'seo_description',
                                optional: true,
                                customFeedback: BlogFormError,
                                formErrorName: "seo_description"
                            }
                        ]
                    },
                    settings.show_cover_image ? {
                        type: "col",
                        md: 12,
                        fields: [],
                        optional: true,
                        custom: MediaImageUploadComponent,
                        customProps: { label: 'CMS.Blog.Content.Form.image', type: 'blog_image', settings: settings, field: 'image', formErrorName: 'image' }
                    } : {}
                ]
            }
        ]
    }

    static fields_block(settings, isEdit = false) {
        return [{
            type: "row",
            children: [
                settings.has_special_blocks ? {
                    type: "col",
                    xl: 9,
                    fields: [
                        {
                            title: "CMS.Media.Content.block.form.type",
                            type: "select",
                            options: ['text', 'accordion'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Page.Media.Content.block.title.' + item} /> } }),
                            name: "type",
                            optional: false,
                            fake: !isEdit,
                            beforeOnChange: settings.onBlockTypeChange
                        },
                    ]
                } : {},
                {
                    type: "col",
                    xl: 9,
                    fields: [
                        {
                            title: "CMS.Media.Content.block.form.title",
                            type: "text",
                            name: "title",
                            optional: settings.titleOptional, // !settings.has_special_blocks,
                            fake: !isEdit
                        },
                    ]
                },
                {
                    type: "col",
                    xl: 9,
                    fields: [],
                    custom: StandardTextFormEditorComponent,
                    fake: !isEdit
                },
                {
                    type: "col",
                    xl: 11,
                    fields: [],
                    custom: MediaContentUploadComponent,
                    customProps: { type: 'blog_block_image', title: "CMS.Blog.Media.Content.title", dimensionPrefix: 'content_with_blocks_image', settings: settings },
                    fake: isEdit
                }
            ]
        }]
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            let type = module ? module.module.type : null;
            // let id = module ? module.id : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'blog_image', type, element.props.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static async fields_block_before_submit(element, type) {
        return new Promise(async (resolve, reject) => {
            let blog_content = element.state.model
            let value = blog_content.value;
            let value_content = value.content ? value.content : [];
            value_content = await Promise.all(value_content.map(async (item) => {
                if (item.image !== null && item.image !== undefined) {
                    item.image_src = await MediaHelper.uploadImage(item, 'image', type, 'blog', element.props.id, null, item.title);
                    item.image = undefined;
                }
                return item;
            }))

            blog_content.value.content = value_content;

            element.setState({ model: blog_content }, () => resolve(true))
        })
    }
}

export default BlogTextFormComponent;