import React from "react";
import { Col, Form, Image as BsImage, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import MediaRepository from "../../../repository/MediaRepository";
import FormToastComponent from "../../basics/FormToastComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import MediaContentUpload from "../../../models/media/MediaContentUpload";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import StandardFormComponent from "../../basics/form/StandardFormComponent";
import PageTextFormComponent from "../../pages/forms/PageTextFormComponent";
import DeleteModalComponent from "../../basics/table/DeleteModalComponent";
import DragDrop from "../../../helpers/DragDrop";
import Module from "../../../models/module/Module";
import { Link } from "react-router-dom";
import Website from "../../../models/website/Website";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import MediaHelper from "../../../helpers/MediaHelper";
import FormattedMessageString from "../../basics/FormattedMessageString";
import LoaderComponent from "../../basics/layout/LoaderComponent";
import { injectIntl } from "react-intl";
import CategoryTextFormComponent from "../../categories/forms/CategoryTextFormComponent";
import BlogTextFormComponent from "../../blog/forms/BlogTextFormComponent";
import EventTextFormComponent from "../../event/forms/EventTextFormComponent";

class MediaContentUploadComponent extends React.Component {

    static field_name = 'content';

    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings ? props.settings : {},
            model: props.model,
            website: null,
            isLoading: true,
            image: null,
            image_src: null,
            image_location: 0,
            value_objects: this.props.values.value !== undefined && this.props.values.value.content !== undefined ? this.props.values.value.content.map((value, index) => {
                // return new MediaContentUpload(index, value.content, null, value.image_src, value.image_location, value.title, value.type);
                return new MediaContentUpload({ ...{ index: index, image: null }, ...value });
            }) : [],
            showEdit: false,
            blockDragged: null,
            blockDraggedTarget: null
        }

        this.handleBlockLocationDrop = this.handleBlockLocationDrop.bind(this);
    }

    _deleteRef = null;

    componentDidMount() {
        let module = Module.get();

        let modelId = null;
        if (module) {
            modelId = module.id;
        } else if (this.props.parent.props.id) {
            modelId = this.props.parent.props.id;
        } else if (this.state.model) {
            modelId = this.state.model.id;
        }

        let promises = [
            MediaRepository.getSpecificSettings(this.props.type, modelId, module ? module.module.type : ''),
            WebsiteRepository.get(Website.get())
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResponse = result[0];
                let websiteResponse = result[1];

                if (settingsResponse !== undefined && settingsResponse.data !== undefined && settingsResponse.data.settings !== undefined) {
                    let settings = settingsResponse.data.settings;
                    this.setState({
                        settings: { ...settings, ...this.state.settings },
                        website: websiteResponse,
                        isLoading: false,
                    }, () => {
                        if (this.props.fake) {
                            this.setState({
                                image_location: this.props.values.image_location === "left" ? 0 : 1,
                                image_src: this.props.values.image_src
                            })
                        }
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(() => FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found"));
    }

    resetErrors = () => {
        document.querySelectorAll('.is-invalid').forEach(el => el.classList.remove('is-invalid'));
        document.querySelectorAll('.invalid-feedback').forEach(el => el.style.display = 'none');
    }

    add = () => {
        this.resetErrors();

        let errorFields = [];

        let type = null;
        let title = this.props.parent.fields[0] !== null ? this.props.parent.fields[0].state.value : null
        let content = this.props.parent.fields[1] !== null ? this.props.parent.fields[1].state.value : null

        if (this.props.parent.fields.length === 4) {
            type = this.props.parent.fields[0] !== null ? this.props.parent.fields[0].state.value : null;
            if (!type) errorFields.push('type');

            title = this.props.parent.fields[1] !== null ? this.props.parent.fields[1].state.value : null;
            if (type !== 'text' && !title) errorFields.push('title');

            content = this.props.parent.fields[2] !== null ? this.props.parent.fields[2].state.value : null;
        }
        if (!content) errorFields.push('content');

        if (errorFields.length > 0) {
            this.showError(false, errorFields);
            return;
        }

        let image = this.state.image;
        let image_src = this.state.image_src;
        let image_location = this.state.image_location === 0 ? 'left' : 'right';
        // let media_content_upload = new MediaContentUpload(this.state.value_objects.length, content, image, image_src, image_location, title, type);
        let media_content_upload = new MediaContentUpload({
            index: this.state.value_objects.length,
            content: content,
            image: image,
            image_src: image_src,
            image_location: image_location,
            title: title,
            type: type
        });

        let list = this.state.value_objects;
        list.push(media_content_upload);
        this.setState({
            value_objects: list,
            image: null,
            image_src: null,
            image_location: 0
        }, () => {
            let model = this.props.parent.state.model;

            model.value[MediaContentUploadComponent.field_name] = list.map((item, index) => {
                if (item.image == null) {
                    return {
                        index: index,
                        type: item.type,
                        title: item.title,
                        content: item.content,
                        image_src: item.image_src,
                        image_location: item.image_location,
                    }
                } else {
                    return {
                        index: index,
                        type: item.type,
                        title: item.title,
                        content: item.content,
                        image: item.image,
                        image_location: item.image_location,
                    };
                }
            })
            this.props.parent.setState({ model: model }, () => this.submit())

            this.props.parent.fields.forEach(field => field.setState({
                value: null,
                reload: true
            }, () => field.setState({ reload: false })))
        })
    }

    update = (model) => this.setState({ editPageContent: model });

    close = () => {
        this.resetErrors();

        let model = this.props.parent.state.model;

        model.value.content = model.value.content.map(content => {
            if (content.index === this.state.editPageContent.index) {
                return this.state.editPageContent;
            }

            return content;
        });

        if (this.state.editPageContent?.index in model.value.content) {
            let mediaContent = model.value.content[this.state.editPageContent.index];

            let errorFields = [];
            if (this.props.parent.fields.length === 4) {
                if (!mediaContent.type) errorFields.push('type');
                if (mediaContent.type !== 'text' && !mediaContent.title) errorFields.push('title');
            }

            if (mediaContent.value?.text) {
                mediaContent.content = mediaContent.value.text;
                model.value.content[this.state.editPageContent.index] = mediaContent;
            }

            if (!mediaContent.content) errorFields.push('content');

            if (errorFields.length > 0) {
                this.showError(true, errorFields);
                return;
            }
        }

        this.setState({ showEdit: false }, () =>
            this.props.parent.setState({ model: model }, () => {
                let value_objects = this.state.value_objects;
                value_objects = value_objects.map(_item => {
                    if (_item.index === this.state.editPageContent.index) {
                        return this.state.editPageContent;
                    }
                    return _item;
                });

                this.setState({ value_objects: value_objects, editPageContent: null }, () => this.submit());
            })
        );
    }

    showError = (modal = false, errorFields) => {
        if (errorFields.indexOf('type') > -1) {
            let typeEl = null;
            if (modal) {
                typeEl = document.getElementsByClassName('modal-body')[0].querySelector('[name=type]');
            } else {
                typeEl = document.querySelector('[name=type]');
            }

            if (typeEl) {
                typeEl.closest('.react-select-container').classList.add('is-invalid')
                let typeErrorEl = typeEl.parentElement.parentElement.querySelector('.invalid-feedback');
                typeErrorEl.style.display = 'block';
                typeErrorEl.innerText = this.props.intl.formatMessage({ id: 'CMS.Form.field.required' }, { field: this.props.intl.formatMessage({ id: 'CMS.Media.Content.block.form.type' }) });
            }
        }

        if (errorFields.indexOf('title') > -1) {
            let titleEl = null;

            if (modal) {
                titleEl = document.getElementsByClassName('modal-body')[0].querySelector('[name=title]');
            } else {
                titleEl = document.querySelector('[name=title]');
            }

            if (titleEl) {
                titleEl.classList.add('is-invalid');
                titleEl.parentElement.classList.add('is-invalid');
                let titleErrorEl = titleEl.parentElement.parentElement.querySelector('.invalid-feedback');
                titleErrorEl.style.display = 'block';
                titleErrorEl.innerText = this.props.intl.formatMessage({ id: 'CMS.Form.field.required' }, { field: this.props.intl.formatMessage({ id: 'CMS.Media.Content.block.form.title' }) });
            }
        }
        if (errorFields.indexOf('content') > -1) {
            let editorWrapper = null;
            if (modal) {
                editorWrapper = document.getElementsByClassName('modal-body')[0].querySelector('[id^=page-edit-upload-editor]');
            } else {
                editorWrapper = document.querySelector('[id^=page-edit-upload-editor]');
            }

            if (editorWrapper) {
                editorWrapper.querySelector('.editor-wrapper').classList.add('is-invalid');
                let errorEl = editorWrapper.querySelector('.editor-error-wrapper').querySelector('.invalid-feedback');
                errorEl.style.display = 'block';
            }
        }

        FormToastComponent.errorTrans("CMS.Form.invalid", { field: <FormattedMessageString id='CMS.Page.Content.Form.content' /> });
    }

    submit = () => {
        if (this.props.parent.submit) {
            this.props.parent.submit();
        }
    }

    editParentModel = (key, value) => {
        let model = this.props.parent.state.model;
        model[key] = value;
        this.props.parent.setState({
            model: model
        })
    }

    changeLocation = (location) => {
        if (this.props.fake) {
            this.editParentModel("image_location", location === 0 ? "left" : "right")
        }

        this.setState({ image_location: location })
    }

    handleImageRemove = (item) => {
        item.image = null;
        item.image_src = null;

        let model = this.props.parent.state.model;

        let indexToUpdate = null;
        model.value.content.forEach(((block, index) => {
            if (block.index === item.index) indexToUpdate = index;
        }));

        if (indexToUpdate !== null) {
            model.value.content[indexToUpdate] = item;
            this.props.parent.setState({ model: model }, () => this.submit());
        }
    }

    handleBlockLocationDragStart = (event, item) => {
        let draggableEl = event.target.closest('.inner-content');
        if (!draggableEl) return;

        this.setState({
            blockDragged: {
                source: draggableEl.classList.contains('text') ? 'text' : 'image',
                index: item.index
            }
        });
    }

    handleBlockLocationDrop = (event, item) => {
        if (!this.state.blockDragged) return;

        event.preventDefault();
        event.stopPropagation();

        let dropEl = event.target.closest('.inner-content');
        if (!dropEl) return;

        let droppedOn = dropEl.classList.contains('text') ? 'text' : 'image';

        if (
            this.state.blockDragged.source === droppedOn ||
            this.state.blockDragged.index !== item.index
        ) {
            return;
        }

        if (item.image_location === 'left') {
            item.image_location = 'right';
        } else {
            item.image_location = 'left';
        }

        let model = this.props.parent.state.model;

        let indexToUpdate = null;
        model.value.content.forEach(((block, index) => {
            if (block.index === item.index) indexToUpdate = index;
        }));

        if (indexToUpdate !== null) {
            model.value.content[indexToUpdate] = item;
            this.props.parent.setState({ model: model }, () => this.submit());
        }
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        let settings = this.props.settings;
        if (this.state.editPageContent) {
            settings = { ...settings, ...{ titleOptional: this.state.editPageContent.type === 'text' } };
        }

        return (
            <Form.Group className="media-content-upload-wrapper">
                <Row>
                    <Col xs={12}>
                        <div className="media-content-upload">
                            <Row>
                                <Col xs={12}>
                                    <FormattedMessage id="CMS.Form.optional">
                                        {(optional_text) =>
                                            <FormattedMessage id="CMS.Page.Media.Content.upload.title">
                                                {(value) => <Form.Label>{value}<span>*{optional_text}</span></Form.Label>}
                                            </FormattedMessage>
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col xs={12} xl={10}>
                                    <div className="upload-wrap">
                                        <div className="upload-left-wrap">
                                            {(this.state.image != null && this.state.image_src != null) || (this.props.fake && this.state.image_src != null) ?
                                                <div className="uploaded-image">
                                                    <BsImage src={MediaHelper.buildImageSrc(this.state.image_src, this.state.website)} fluid={true} />
                                                </div>
                                                :
                                                <div className="uploaded-image">
                                                    <FontAwesomeIcon icon={['fas', 'image']} />
                                                    <FormattedMessage id="CMS.Page.Media.Content.upload.no-image">
                                                        {(value) => <p>{value}</p>}
                                                    </FormattedMessage>
                                                </div>
                                            }
                                            <div className="uploaded-image-location">
                                                <FormattedMessage id="CMS.Page.Media.Content.upload.image.location">
                                                    {(value) => <p>{value}</p>}
                                                </FormattedMessage>
                                                <div className="uploaded-image-location-wrapper">
                                                    <FormattedMessage id="Left">
                                                        {(value) =>
                                                            <Link to="#" className={this.state.image_location === 0 ? "left active" : "left"}
                                                                onClick={() => this.changeLocation(0)}
                                                            >
                                                                <FontAwesomeIcon icon={['fas', 'align-left']} /> {value}
                                                            </Link>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id="Right">
                                                        {(value) =>
                                                            <Link to="#" className={this.state.image_location === 1 ? "right active" : "right"}
                                                                onClick={() => this.changeLocation(1)}>
                                                                <FontAwesomeIcon icon={['fas', 'align-right']} /> {value}
                                                            </Link>
                                                        }
                                                    </FormattedMessage>

                                                </div>
                                            </div>
                                        </div>
                                        <Dropzone
                                            multiple={false}
                                            noKeyboard
                                            maxSize={this.state.settings.max_size}
                                            accept={MediaHelper.acceptedMimeTypes(this.state.settings)}
                                            onDrop={(acceptedFiles, rejectedFiles) => {
                                                if (acceptedFiles.length > 0) {
                                                    let media = acceptedFiles[0];

                                                    if (this.props.fake) {
                                                        this.editParentModel("image", media);
                                                    } else {
                                                        this.setState({ image: media })
                                                    }

                                                    let dimensionPrefix = this.props.dimensionPrefix;
                                                    let minWidthField = (dimensionPrefix ? dimensionPrefix : 'min') + '_width';
                                                    let minHeightField = (dimensionPrefix ? dimensionPrefix : 'min') + '_height';

                                                    MediaHelper.handleDropAccepted(media, { minWidth: this.state.settings[minWidthField], minHeight: this.state.settings[minHeightField] }, (result) => {
                                                        this.setState({ image_src: result });

                                                        if (this.props.fake) {
                                                            this.editParentModel("image_src", result);
                                                        }
                                                    });
                                                }

                                                MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps, acceptedFiles }) => {
                                                return (
                                                    <section className="media-drag-drop" {...getRootProps()}>
                                                        <div className="media-drag-drop-inner">

                                                            <div className="media-drag-drop-inner-row">
                                                                <div className="media-drag-drop-icon">
                                                                    <div className="icon-wrapper">
                                                                        <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                                                    </div>
                                                                </div>
                                                                <div className="media-drag-drop-text">
                                                                    <FormattedMessage id="CMS.Media.Form.image_dragdrop.title">
                                                                        {(value) => <h1>{value}</h1>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="CMS.Media.Form.image_dragdrop.title.sub">
                                                                        {(value) => <h2>{value}</h2>}
                                                                    </FormattedMessage>
                                                                </div>
                                                            </div>
                                                            <div className="media-drag-drop-requirements">
                                                                <p className="mt-3">{MediaHelper.assembleRequirements(this.state.settings, this.props.dimensionPrefix)}</p>
                                                            </div>
                                                            <Form.Control {...getInputProps()} />
                                                        </div>
                                                    </section>
                                                )
                                            }}
                                        </Dropzone>
                                    </div>
                                </Col>
                                <Col xl={12}>
                                    {!this.props.fake ?
                                        <FormattedMessage id="CMS.Page.Media.Content.upload.add">
                                            {(value) => (
                                                <Link to="#" className="btn btn-primary" onClick={event => this.add(event)}>{value}</Link>
                                            )}
                                        </FormattedMessage> : null
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12}>
                        {!this.props.fake ?
                            <div className="media-content">
                                <FormattedMessage id={this.props.title}>
                                    {(value) => <Form.Label>{value}</Form.Label>}
                                </FormattedMessage>
                                {this.state.value_objects.map((value, index) => {
                                    return (
                                        <div className="media-content-block" key={index}
                                            draggable
                                            onDragStart={e => DragDrop.startDrag(e, index, this)}
                                            onDragOver={DragDrop.dragOver}
                                            onDrop={e => {
                                                DragDrop.drop(e, index, this, this.state.value_objects, "value_objects")
                                                DragDrop.drop(e, index, this, this.props.parent.state.model.value.content, "model.value.content", this.props.parent)
                                            }}
                                        >
                                            <div className="left">
                                                <div className="drag">
                                                    <FontAwesomeIcon icon={['fas', 'arrows-alt']} />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="head">
                                                    <FormattedMessage id={'CMS.Page.Media.Content.block.title.' + (value.type ? value.type : 'text')}>
                                                        {(value) => <p className="title">{value} {index + 1}</p>}
                                                    </FormattedMessage>
                                                    <div className="actions">
                                                        <Link to="#" className="edit" onClick={() => this.setState({ showEdit: true, editPageContent: value })}>
                                                            <FontAwesomeIcon icon={['fas', 'edit']} />
                                                        </Link>
                                                        <Link to="#" className="delete" onClick={event => DeleteModalComponent.openDeleteModal(event, value, this)}>
                                                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    {(value.image != null || value.image_src) && value.image_location === 'left' ?
                                                        <div className="inner-content image"
                                                            draggable
                                                            onDragStart={e => this.handleBlockLocationDragStart(e, value)}
                                                            onDragOver={DragDrop.dragOver}
                                                            onDrop={e => this.handleBlockLocationDrop(e, value)}
                                                        >
                                                            <Link className="delete icon" to="#" onClick={() => this.handleImageRemove(value)}>
                                                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                                            </Link>
                                                            <BsImage src={value.image_src != null ? MediaHelper.buildImageSrc(value.image_src, this.state.website) : "http://via.placeholder.com/260x180"} fluid={true} />
                                                        </div>
                                                        : null
                                                    }
                                                    <div className="inner-content text" draggable onDragStart={e => this.handleBlockLocationDragStart(e, value)} onDragOver={DragDrop.dragOver} onDrop={e => this.handleBlockLocationDrop(e, value)}>
                                                        {value.title != null && <p className="title">{value.title}</p>}
                                                        <div dangerouslySetInnerHTML={{ __html: value.content }} />
                                                    </div>
                                                    {(value.image != null || value.image_src) && value.image_location === 'right' ?
                                                        <div className="inner-content image" draggable onDragStart={e => this.handleBlockLocationDragStart(e, value)} onDragOver={DragDrop.dragOver} onDrop={e => this.handleBlockLocationDrop(e, value)}>
                                                            <Link className="delete icon" to="#" onClick={() => this.handleImageRemove(value)}>
                                                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                                            </Link>
                                                            <BsImage src={value.image_src != null ? MediaHelper.buildImageSrc(value.image_src, this.state.website) : "http://via.placeholder.com/260x180"} fluid={true} />
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                    </Col>
                </Row>

                <ModalFormFragment
                    title_id={<FormattedMessageString id={'CMS.Media.Form.modal.edit.title.' + (this.state.editPageContent?.type ? this.state.editPageContent.type : 'text')} values={{ blockNr: this.state.editPageContent ? (this.state.editPageContent.index + 1) : 1 }} />}
                    show={this.state.showEdit}
                    size="xl"
                    onHide={() => this.setState({ showEdit: false, editPageContent: null })}
                >
                    <StandardFormComponent
                        key="media-content-edit-modal-form"
                        parent={this}
                        model={this.state.editPageContent}
                        validationSchema={null}
                        formSubmit={null}
                        fields={this.getFields(settings)}
                        formErrors={this.state.formErrors}
                        btnCloseText="CMS.Media.Form.modal.edit.close"
                        onClose={this.close}
                    />
                </ModalFormFragment>

                <DeleteModalComponent
                    ref={r => this._deleteRef = r}
                    parent={this}
                    title="CMS.Page.Media.Content.delete.title"
                    text="CMS.Page.Media.Content.delete.text"
                    customDeleteRowFunction={(event) => {
                        event.preventDefault();
                        let item = this.state.deleteRow;
                        let model = this.props.parent.state.model;
                        model.value.content = model.value.content.filter(function (_item) {
                            return _item.index !== item.index;
                        });
                        this.props.parent.setState({ model: model }, () => {
                            let value_objects = this.state.value_objects;
                            value_objects = value_objects.filter(function (_item) {
                                return _item.index !== item.index;
                            });
                            this.setState({ value_objects: value_objects }, this._deleteRef.closeModal)

                            this.submit();
                        })
                    }}
                />
            </Form.Group >
        );
    }

    getFields = (settings) => {
        if (this.props.type.indexOf('category_block_image') > -1) {
            return CategoryTextFormComponent.fields_block(settings, this.props.parent.props?.parent?.state?.parent, true);
        } else if (this.props.type.indexOf('blog_block_image') > -1) {
            return BlogTextFormComponent.fields_block(settings, true);
        } else if (this.props.type.indexOf('event_block_image') > -1) {
            return EventTextFormComponent.fields_block(settings, true);
        }
        return PageTextFormComponent.fields_image(true, this.state.model);
    }
}

MediaContentUploadComponent.defaultProps = {
    title: 'CMS.Page.Media.Content.title'
}

export default injectIntl(MediaContentUploadComponent);