import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DeleteConfirmComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buttons: {
                ...{
                    confirm: { text: 'Default.delete', icon: 'trash-alt' },
                    cancel: { text: 'Default.cancel' }
                },
                ...props.buttons
            },
        }
    }

    render() {
        return (
            <div className={"delete"}>
                <FormattedMessage id={"Default.delete.warning"}>
                    {
                        (value) => <FormattedMessage id={this.props.delete_text_id}>
                            {
                                (value2) => <p><strong>{value}!</strong> {value2}</p>
                            }
                        </FormattedMessage>
                    }
                </FormattedMessage>
                <div className="actions">
                    <FormattedMessage id={this.state.buttons.confirm.text}>
                        {
                            (value) => <Button variant="danger" onClick={this.props.delete_func}>
                                <FontAwesomeIcon icon={['far', this.state.buttons.confirm.icon]} />
                                {value}
                            </Button>
                        }
                    </FormattedMessage>
                    <FormattedMessage id={this.state.buttons.cancel.text}>
                        {
                            (value) => <Button onClick={this.props.onHide} variant="light">
                                {this.state.buttons.cancel.icon && <FontAwesomeIcon icon={['far', this.state.buttons.cancel.icon]} />}
                                {value}
                            </Button>
                        }
                    </FormattedMessage>
                </div>
            </div>
        );
    }
}

export default DeleteConfirmComponent;