import MediaHelper from "../../../helpers/MediaHelper";
import ProjectFormError from "../errors/ProjectFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";

class ProjectTextFormComponent {
    static fields(settings) {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Project.Content.Form.title",
                                type: "text",
                                name: 'title',
                                customFeedback: ProjectFormError,
                                formErrorName: "title"
                            }
                        ]
                    },
                    settings.use_categories ?
                        {
                            type: "col",
                            md: 6,
                            fields: [
                                {
                                    title: 'CMS.Project.Content.Form.category',
                                    type: "select",
                                    name: "category",
                                    options: settings.references.map(item => {
                                        if (item.children.length) {
                                            return false;
                                        }

                                        let label = item.depthLabel.join(' > ');

                                        return { value: item.id, label: label };
                                    }).filter(x => x !== false),
                                    customFeedback: ProjectFormError,
                                    formErrorName: "category"
                                }
                            ]
                        }
                        : {},
                    settings.show_client ? {
                        type: "col",
                        md: 8,
                        fields: [
                            {
                                title: "CMS.Project.Content.Form.client",
                                type: "text",
                                name: 'client',
                                customFeedback: ProjectFormError,
                                formErrorName: "client"
                            }
                        ]
                    } : {},
                    settings.show_url ? {
                        type: "col",
                        md: 8,
                        fields: [
                            {
                                title: "CMS.Project.Content.Form.url",
                                type: "url",
                                name: 'url',
                                customFeedback: ProjectFormError,
                                formErrorName: "url"
                            }
                        ]
                    } : {},
                    settings.show_summary ? {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Project.Content.Form.summary",
                                type: "textarea",
                                name: 'summary',
                                customFeedback: ProjectFormError,
                                formErrorName: "summary"
                            }
                        ]
                    } : {},
                    settings.show_content ? {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        customProps: { valueField: 'content', label: 'CMS.Project.Content.Form.content', placeholder: 'CMS.Project.Content.Form.content.placeholder' }
                    } : {},            
                    settings.show_cover_image ? {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: MediaImageUploadComponent,
                        customProps: { label: 'CMS.Project.Content.Form.image', type: 'project_image', settings: settings, field: 'image', formErrorName: 'image' }
                    } : {}
                ]
            }
        ]
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            let type = module ? module.module.type : null;
            // let id = module ? module.id : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'project_image', type, element.props.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static renderOptionLabel = (reference, item, language) => {
        switch (reference.type) {
            case 'category':
                return item.depthLabel.join(' > ');
            default:
                let content = item[reference.type].contents.filter(content => content.language === language)[0];
                if (!content) content = item[reference.type].contents[0];
                return content.title ? content.title : (content.name ? content.name : '');
        }
    }
}

export default ProjectTextFormComponent;