import React from "react";
import LogoComponent from "../../components/basics/LogoComponent";
import SettingsComponent from "../../components/basics/SettingsComponent";
import WebsitesDropDownComponent from "../../components/basics/navigation/WebsitesDropDownComponent";
import LeftNavMenuComponent from "../../components/basics/navigation/LeftNavMenuComponent";
import LeftNavButtonComponent from "../../components/basics/navigation/LeftNavButtonComponent";
import { ModuleRepository } from "../../repository";
import WebsitesLanguageDropDownComponent from "../../components/basics/navigation/WebsitesLanguageDropDownComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";

class LeftNavFragment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMobile: false,
            modules: []
        }
    }

    componentDidMount() {
        this.checkShowMobile();
        window.addEventListener("resize", this.checkShowMobile.bind(this));

        ModuleRepository.all().then(result => this.setState({ modules: result }));
    }

    checkShowMobile = () => this.setState({ showMobile: window.innerWidth < 992 });

    render() {
        return (
            <>
                <div className="left-nav-scroll">
                    <SettingsComponent user={this.props.user} />
                    <LogoComponent color={"light"} />

                    <WebsitesDropDownComponent user={this.props.user} />
                    {!GeneralSettingsHelper.is() && <WebsitesLanguageDropDownComponent user={this.props.user} />}
                    {this.state.showMobile && <LeftNavButtonComponent modules={this.state.modules} parent={this.props.parent} />}
                    <LeftNavMenuComponent modules={this.state.modules} user={this.props.user} />
                </div>
            </>
        )
    }
}

export default LeftNavFragment;