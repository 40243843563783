import * as Yup from 'yup';
import ipv4 from "../../yup/ipv4";
import generateApiKey from 'generate-api-key';

class Website {
    id;
    user;
    name;
    url;
    alternative_url;
    default_language;
    customer;
    active;
    sftp_host;
    sftp_port;
    sftp_user;
    sftp_password;
    sftp_docroot_path;
    sftp_media_path;
    api_key;
    logo_svg;
    logo;
    main_color = '#000000';
    secondary_color = '#000000';
    tertiary_color = '#000000';


    constructor(id, user, name, url, alternative_url, default_language, sftp_host, sftp_port, sftp_user, sftp_password, sftp_docroot_path, sftp_media_path, api_key, logo_svg, logo, main_color, secondary_color, tertiary_color, active = true) {
        this.id = id;
        this.user = user;
        this.name = name;
        this.url = Website.removeHttpOrHttpsInUrl(url);
        this.alternative_url = alternative_url;
        this.default_language = default_language;
        this.sftp_host = sftp_host;
        this.sftp_port = sftp_port;
        this.sftp_user = sftp_user;
        this.sftp_password = sftp_password;
        this.sftp_docroot_path = sftp_docroot_path;
        this.sftp_media_path = sftp_media_path;
        this.api_key = api_key ? api_key : generateApiKey({ method: 'bytes', length: 64 });
        this.logo_svg = logo_svg;
        this.logo = logo;
        this.main_color = main_color ? main_color : '#000000';
        this.secondary_color = secondary_color ? secondary_color : '#000000';
        this.tertiary_color = tertiary_color ? tertiary_color : '#000000';
        this.active = active;
    }

    static schema = (isEdit = false) => {
        Yup.addMethod(Yup.string, 'ipv4', ipv4.ipv4);

        let fields = {
            user: Yup.mixed().required("CMS.Website.Form.user"),
            name: Yup.string().required("CMS.Website.Form.name").max(100, "CMS.Website.Form.name.max"),
            url: Yup.string().test("is-url-without-https-http", "CMS.Website.Form.url.invalid", val => {
                if (val !== undefined) {
                    let schemaUrl = Yup.object().shape({
                        url: Yup.string().url("CMS.Website.Form.url.invalid")
                    });
                    return schemaUrl.validate({ url: 'https://' + val.replace('http://', '').replace('https://', '').replace('www.', '') })
                } else {
                    return true;
                }
            }).required("CMS.Website.Form.url"),
            //alternative_url: Yup.string(),
            default_language: Yup.string().required("CMS.Website.Form.default_language"),
            sftp_host: Yup.string().required("CMS.Website.Form.sftp.host").ipv4("CMS.Website.Form.sftp.host.invalid"),
            sftp_port: Yup.number().required("CMS.Website.Form.sftp.port.required").max(9999999999, "CMS.Website.Form.sftp.port.max"),
            sftp_user: Yup.string().required("CMS.Website.Form.sftp.user").max(100, "CMS.Website.Form.sftp.user.max"),
            sftp_password: Yup.string().max(100, "CMS.Website.Form.sftp.password.max").when("id", (id) => {
                if (!isEdit)
                    return Yup.string().required("CMS.Website.Form.sftp.password");
            }),
            sftp_docroot_path: Yup.string().max(100, "CMS.Website.Form.sftp.docroot.path.max"),
            sftp_media_path: Yup.string().max(100, "CMS.Website.Form.sftp.media.path.max"),
            api_key: Yup.string().max(100, "CMS.Website.Form.api_key.path.max"),
            // logo_svg: Yup.string().required("CMS.Website.Form.logo_svg.required"),
            main_color: Yup.string().required('CMS.Website.Form.main_color'),
            secondary_color: Yup.string().required('CMS.Website.Form.secondary_color'),
            tertiary_color: Yup.string().required('CMS.Website.Form.tertiary_color')
        }

        if (isEdit) {
            fields.logo_svg = Yup.string().required("CMS.Website.Form.logo_svg.required");
        }

        return Yup.object(fields);
    };

    static get() {
        let websiteId = localStorage.getItem('website.id');
        if (websiteId && !isNaN(websiteId)) {
            return parseInt(websiteId);
        }
        return null;
    }

    static set(id) {
        localStorage.setItem('website.id', id)
    }

    static remove() {
        localStorage.removeItem('website.id');
    }

    static removeHttpOrHttpsInUrl(url) {
        if (url !== undefined) {
            if (url.length > 7) {
                if (url.substr(0, 8) === 'https://') {
                    return url.replace('https://', '');
                }
            }

            if (url.length > 6) {
                if (url.substr(0, 7) === 'http://') {
                    return url.replace('http://', '');
                }
            }
        }

        return url;
    }

    static isNotNull() {
        return Website.get() !== undefined && Website.get() !== null && Website.get() !== 0;
    }

    toArray = (id = null) => {
        return {
            id: id,
            customer: this.user,
            name: this.name,
            domain: "https://" + this.url,
            default_language: this.default_language,
            alternative_homepage: this.alternative_url,
            sftp_host: this.sftp_host,
            sftp_port: this.sftp_port,
            sftp_user: this.sftp_user,
            sftp_password: this.sftp_password,
            sftp_docroot_path: this.sftp_docroot_path,
            sftp_media_path: this.sftp_media_path,
            api_key: this.api_key,
            logo_svg: this.logo_svg,
            logo: this.logo,
            main_color: this.main_color,
            secondary_color: this.secondary_color,
            tertiary_color: this.tertiary_color
        }
    }
}

export default Website;
