import moment from "moment";

export default class EventContent {
    id;
    title;
    language;
    summary;
    content;
    pdf_document;

    views;
    category;

    updated_at;
    updated_by;

    static fromJson = (json) => {
        let content = new EventContent();

        content.id = json.id;
        content.language = json.language;
        content.category = json.category;
        content.title = json.title;
        content.summary = json.summary;
        content.value = json.value != null ? json.value : '{}';
        content.views = json.views;
        content.pdf_document = json.pdf_document;

        content.updated_at = json.updated_at ? moment(json.updated_at) : null;
        content.updated_by = json.updated_by;

        return content;
    }

    calculateNrDays = () => {
        if (!this.date_start && !this.date_end) return 0;

        let start = moment(this.date_start).set({ hour: 0, minute: 0, second: 0 });
        let end = moment(this.date_end).set({ hour: 0, minute: 0, second: 0 });

        return end.diff(start, 'days') + 1;
    }
}