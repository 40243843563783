class ContentHelper {
    static replaceTags = (text) => {
        if (!text) return text;

        ['strong', 'small', 'u', 'em', 'br'].forEach(tag => {
            text = text.replaceAll('%' + tag + '%', '<' + tag + '>');
            text = text.replaceAll('%end_' + tag + '%', '</' + tag + '>');
        });

        return text;
    }

    static stripTags = (text) => {
        if (!text) return text;

        ['strong', 'small', 'u', 'em', 'br'].forEach(tag => {
            text = text.replaceAll('%' + tag + '%', '');
            text = text.replaceAll('%end_' + tag + '%', '');
        });

        return text;
    }

    static formatUrl = (url) => {
        if (!url) return url;
        if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) return url;

        return 'https://' + url;
    }
}

export default ContentHelper;