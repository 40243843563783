import React from "react";
import FormattedMessageString from "../../basics/FormattedMessageString";
import TwoFARepository from "../../../repository/TwoFARepository";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormToastComponent from "../../basics/FormToastComponent";

class Activate2faStep1Component extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            image: '',
            secret: '',
            show_secret: ''
        };

        TwoFARepository.register()
            .then(response => {
                this.setState({
                    image: response.data.qr_image,
                    secret: response.data.secret
                })
            }
            )
            .catch(reason => {
                this.props.history.goBack();
            }
            );
    }

    secret() {
        let show_secret_array = [];
        let show_secret_array_item_temp = '';
        this.state.secret.split('').forEach((character, key) => {
            show_secret_array_item_temp += character;
            if ((key + 1) % 4 === 0) {
                show_secret_array.push(show_secret_array_item_temp);
                show_secret_array_item_temp = '';
            }
        });

        return show_secret_array.map((character, index) => (
            <span key={index}>{character}</span>
        ));
    };

    copy = (event) => {
        event.preventDefault();
        let textField = document.createElement('textarea');
        textField.innerText = this.state.secret;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        FormToastComponent.successTrans('2FA', 'Auth.2fa.secret.copy.success')
    };

    render() {

        return (
            <>
                <p className={"step-title"}><FormattedMessageString id={"Auth.2fa.step1.title"} /></p>
                <p><FormattedMessageString id={"Auth.2fa.step1.text1"} /></p>
                <p><FormattedMessageString id={"Auth.2fa.step1.text2"} /></p>
                <Image className={"qr-code"} src={this.state.image} />
                <div className={"secret"}>
                    <p>
                        {this.secret()}
                        <Link onClick={this.copy} to="#"><FontAwesomeIcon icon={['far', 'key']} /></Link>
                    </p>
                </div>
                <div className={"buttons"}>
                    <Button onClick={this.props.next_step_action}><FormattedMessageString
                        id={"Auth.2fa.step.next"} /></Button>
                    <Button variant="secondary" onClick={this.props.cancel_action}><FormattedMessageString id={"Auth.2fa.step.cancel"} /></Button>
                </div>
            </>
        )
    }

}

Activate2faStep1Component.propTypes = {
    next_step_action: PropTypes.func.isRequired,
    cancel_action: PropTypes.func.isRequired
};
export default withRouter(Activate2faStep1Component);