import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormDateMinError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {field =>
                    <FormattedMessage id="CMS.Form.Date.min" values={{ field: field, min: this.props.min }}>
                        {message => <p>{message}</p>}
                    </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

FormDateMinError.propTypes = {
    field_name: PropTypes.string.isRequired
};
export default FormDateMinError