import JsonHelper from "../../helpers/JsonHelper";

export default class FormContent {
    id;
    language;
    title;
    button_text;
    success_message;
    mail_admin_subject;
    mail_admin_content;
    mail_visitor_subject;
    mail_visitor_content;

    static fromJson(json) {
        let formContent = new FormContent();
        
        formContent.title = json.title;
        formContent.button_text = json.button_text;
        formContent.success_message = JsonHelper.tryParse(json.success_message);
        
        formContent.mail_admin_subject = json.mail_admin_subject;
        formContent.mail_admin_content = json.mail_admin_content ? JsonHelper.tryParse(json.mail_admin_content) : {};

        formContent.mail_visitor_subject = json.mail_visitor_subject;
        formContent.mail_visitor_content = json.mail_visitor_content ? JsonHelper.tryParse(json.mail_visitor_content) : {};

        return formContent;
    }
}
