import React from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import TwofaOneTimePasswordFormComponent from "../../../components/authentication/2fa/TwofaOneTimePasswordFormComponent";
import TwoFARepository from "../../../repository/TwoFARepository";
import User from "../../../models/user/User";
import TwoFaBackupCodesFormComponent from "../../../components/authentication/2fa/TwoFaBackupCodesFormComponent";
import FormToastComponent from "../../../components/basics/FormToastComponent";
import { ToastContainer } from "react-toastify";

class OneTimePassword2faFragment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            backupCodes: false
        }
    }


    submit = (values, actions) => {
        TwoFARepository.verify(values.code, values.trust)
            .then(response => {
                if (response.data['success']) {
                    User.addTwoFactor(response.data['auth_passed'], response.data['auth_time']);
                    window.location.reload();
                } else {
                    FormToastComponent.errorTrans('Auth.2fa.activate.failed');
                }
            })
            .catch(reason => {
                FormToastComponent.errorTrans('Auth.2fa.activate.failed');
            });
    };

    submitBackupCodes = (values, actions) => {
        TwoFARepository.backupCodeCheck(values.code)
            .then(response => {
                if (response.data['success']) {
                    User.addTwoFactor(response.data['auth_passed'], response.data['auth_time']);
                    window.location.reload();
                } else {
                    FormToastComponent.errorTrans('Auth.2fa.backupcodes.activate.failed');
                }
            })
            .catch(error => {
                console.error(error.response);
                FormToastComponent.errorTrans('Auth.2fa.backupcodes.activate.failed');
            })
    };

    backupCodes = () => {
        this.setState({
            backupCodes: true
        })
    };

    oneTimePassword = () => {
        this.setState({
            backupCodes: false
        })
    };


    render() {
        return (
            <>
                <ToastContainer />
                <div className="authentication">
                    <Card className={"activate-2fa green passcode"}>
                        <Card.Header>
                            <h1><FormattedMessage
                                id={this.state.backupCodes ? 'Auth.2fa.backupcodes.title' : 'Auth.2fa.login.title'} />
                            </h1>
                        </Card.Header>
                        <Card.Body>
                            {
                                this.state.backupCodes ?
                                    <TwoFaBackupCodesFormComponent submit_action={this.submitBackupCodes}
                                        cancel_action={this.oneTimePassword} />
                                    :
                                    <TwofaOneTimePasswordFormComponent submit_action={this.submit}
                                        back_action={this.backupCodes} login={true} />
                            }
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}

export default OneTimePassword2faFragment;
