import React from "react";
import { Col, Form, Image as BsImage, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import FormToastComponent from "../../basics/FormToastComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import MediaRepository from "../../../repository/MediaRepository";
import StandardFormErrorMessageComponent from "../../basics/form/StandardFormErrorMessageComponent";
import Module from "../../../models/module/Module";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Website from "../../../models/website/Website";
import MediaHelper from "../../../helpers/MediaHelper";
import { Link } from "react-router-dom";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class MediaImageUploadComponent extends React.Component {

    static field_name = 'image';

    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings ? props.settings : {},
            optional: props.optional === undefined || !!props.optional,
            isLoading: false,
            image: null,
            image_src: props.values[props.field] ? props.values[props.field] : null,
            showEdit: false
        }
    }

    deleteRef = null;

    componentDidMount() {
        let moduleType = null;
        let module = null;
        if (this.props.module === 'website') {
            moduleType = 'website';
        } else {
            module = Module.get();
            moduleType = module ? module.module.type : null;
        }

        let promises = [
            MediaRepository.getSpecificSettings(this.props.type, module ? module.id : this.props.parent.props.id, moduleType),
            WebsiteRepository.get(Website.get())
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResponse = result[0];
                let websiteResponse = result[1];

                if (settingsResponse !== undefined && settingsResponse.data !== undefined && settingsResponse.data.settings !== undefined) {
                    let settings = settingsResponse.data.settings;
                    this.setState({
                        settings: { ...settings, ...this.state.settings },
                        website: websiteResponse,
                        isLoading: false,
                    }, () => {
                        if (this.props.fake) {
                            this.setState({
                                image_location: this.props.values.image_location === "left" ? 0 : 1,
                                image_src: this.props.values.image_src
                            })
                        }
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(error => {
                console.error(error)
                FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
            });
    }

    editParentModel(key, value) {
        let model = this.props.parent.state.model;
        model[key] = value;
        this.props.parent.setState({
            model: model
        })
    }

    handleImageRemove = (e) => {
        e.preventDefault();

        if (this.props.fake) {
            this.editParentModel(this.props.field ? this.props.field : 'image', null);
            this.editParentModel("image_src", null);
        } else {
            this.setState({
                image: null,
                image_src: null,
            });
            this.editParentModel(this.props.field ? this.props.field : 'image', null);
            this.editParentModel("image_src", null);
        }
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        let hasError = this.props.errors[this.props.formErrorName] !== undefined;

        return (
            <Form.Group className={"media-content-upload-wrapper"}>
                <Row>
                    <Col xs={12}>
                        <div className={"media-content-upload"}>
                            <Row>
                                <Col xs={12}>
                                    {this.state.optional ?
                                        <FormattedMessage id={"CMS.Form.optional"}>
                                            {
                                                (optional_text) =>
                                                    <FormattedMessage id={this.props.label ? this.props.label : "CMS.Page.Media.Content.upload.title"}>
                                                        {(value) => <Form.Label>{value}<span>*{optional_text}</span></Form.Label>}
                                                    </FormattedMessage>
                                            }
                                        </FormattedMessage> :
                                        <FormattedMessage id={this.props.label ? this.props.label : "CMS.Page.Media.Content.upload.title"}>
                                            {(value) => <Form.Label>{value}</Form.Label>}
                                        </FormattedMessage>
                                    }
                                </Col>
                                <Col xs={12} xl={10}>
                                    <div className="upload-wrap">
                                        <div className="upload-left-wrap">
                                            {
                                                (this.state.image_src != null) || (this.props.fake && this.state.image_src != null) ?
                                                    <div className={"uploaded-image " + (hasError ? 'is-invalid' : '')}>
                                                        <Link className="delete icon" to="#" onClick={this.handleImageRemove}>
                                                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                                        </Link>
                                                        <BsImage src={MediaHelper.buildImageSrc(this.state.image_src, this.state.website)} fluid={true} />
                                                    </div>
                                                    :
                                                    <div className={"uploaded-image " + (hasError ? 'is-invalid' : '')}>
                                                        <FontAwesomeIcon icon={['fas', 'image']} />
                                                        <FormattedMessage id={"CMS.Page.Media.Content.upload.no-image"}>
                                                            {(value) => <p>{value}</p>}
                                                        </FormattedMessage>
                                                    </div>
                                            }

                                            {hasError && <StandardFormErrorMessageComponent error={this.props.label ? this.props.label : "CMS.Page.Content.Form.image"} />}
                                        </div>
                                        <Dropzone
                                            multiple={false}
                                            noKeyboard
                                            accept={MediaHelper.acceptedMimeTypes(this.state.settings)}
                                            onDrop={(acceptedFiles, rejectedFiles) => {
                                                if (acceptedFiles.length > 0) {
                                                    let media = acceptedFiles[0];

                                                    if (this.props.fake) {
                                                        this.editParentModel(this.props.field ? this.props.field : 'image', media);
                                                    } else {
                                                        this.setState({
                                                            image: media
                                                        });
                                                        this.editParentModel(this.props.field ? this.props.field : 'image', media);
                                                    }

                                                    let dimensionPrefix = this.props.dimensionPrefix;
                                                    let minWidthField = (dimensionPrefix ? dimensionPrefix : 'min') + '_width';
                                                    let minHeightField = (dimensionPrefix ? dimensionPrefix : 'min') + '_height';

                                                    MediaHelper.handleDropAccepted(media, { minWidth: this.state.settings[minWidthField], minHeight: this.state.settings[minHeightField] }, (result) => {
                                                        this.setState({ image_src: result });

                                                        if (this.props.fake) {
                                                            this.editParentModel("image_src", result);
                                                        }
                                                    });
                                                }

                                                MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);
                                            }}
                                        >
                                            {({ getRootProps, getInputProps, acceptedFiles }) => {
                                                return (
                                                    <>
                                                        <section className={"media-drag-drop"} {...getRootProps()}>
                                                            <div className={"media-drag-drop-inner"}>

                                                                <div className={"media-drag-drop-inner-row"}>
                                                                    <div className={"media-drag-drop-icon"}>
                                                                        <div className={"icon-wrapper"}>
                                                                            <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={"media-drag-drop-text"}>
                                                                        <FormattedMessage
                                                                            id={"CMS.Media.Form.image_dragdrop.title"}>
                                                                            {
                                                                                (value) => <h1>{value}</h1>
                                                                            }
                                                                        </FormattedMessage>
                                                                        <FormattedMessage
                                                                            id={"CMS.Media.Form.image_dragdrop.title.sub"}>
                                                                            {
                                                                                (value) => <h2>{value}</h2>
                                                                            }
                                                                        </FormattedMessage>
                                                                    </div>
                                                                </div>
                                                                <div className={"media-drag-drop-requirements"}>
                                                                    <p className="mt-3">{MediaHelper.assembleRequirements(this.state.settings, this.props.dimensionPrefix)}</p>
                                                                </div>
                                                                <Form.Control
                                                                    {...getInputProps()}
                                                                />
                                                            </div>
                                                        </section>
                                                    </>
                                                )
                                            }}
                                        </Dropzone>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Form.Group >
        );
    }
}

export default MediaImageUploadComponent;
