import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Module from "../../models/module/Module";
import FormattedMessageString from "../basics/FormattedMessageString";

class EventMenuComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            module: props.module,
            settings: Module.getSettings(props.module.module),
            language: localStorage.getItem('language')
        }
    }

    render() {
        let navItems = [];

        if (this.state.settings.use_attendees) {
            navItems.push(
                <Nav.Item key={this.state.module.id} className="subnav-item">
                    <NavLink onClick={() => Module.change(this.state.module)} className="nav-link subnav-link" to={"/module/events/" + this.state.module.id + "/registrations/0/overview"}>
                        <FormattedMessageString id="CMS.Event.overview.link.registrations" />
                    </NavLink>
                </Nav.Item>
            )
        }

        return navItems;
    }
}

export default EventMenuComponent;