import API from "../services/api";

export default class MapRepository {

    static getAll = () => API.get('/map/all').then(response => response.data.maps);

    static get = (id) => API.get('/map/details/' + id).then(response => response.data.map);

    static create = (data) => API.post('/map/create', data);

    static update = (id, language, data) => API.post('/map/update', {
        ...data,
        id: id,
        language: language
    })
        .then(response => response.data);

    static delete = (id) => API.delete('/map/delete?id=' + id);

    static toggle = (id, language) => API.post('/map/toggle', { id: id, language: language })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.isFavorite;
            }
        });
}
