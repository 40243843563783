import * as Yup from 'yup';
import '../../helpers/phone-validation';

class Contact {

    first_name = '';
    last_name = '';
    phone = '';
    mobile = '';
    email = '';
    alternative_email = '';
    url = '';
    company_name = '';
    vat_number = '';

    constructor(first_name, last_name, phone, mobile, alternative_email, url, company_name, vat_number) {
        this.first_name = first_name;
        this.last_name = last_name;
        this.phone = phone;
        this.mobile = mobile;
        this.alternative_email = alternative_email;
        this.url = url || '';
        this.company_name = company_name;
        this.vat_number = vat_number;
    }

    static schema_fields = {
        first_name: Yup.string().required('firstname').max(100, 'firstname.max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'firstname.invalid', { excludeEmptyString: true }),
        last_name: Yup.string().required('lastname').max(100, 'lastname.max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'lastname.invalid', { excludeEmptyString: true }),
        phone: Yup.string().phone('contact.form.phone').when('mobile', (mobile, passSchema) => mobile == null || mobile === '' ?
            passSchema.required('contact.form.phone.required.conditional')
            : passSchema
        ).nullable(),
        //mobile: Yup.string().phone('contact.form.mobile').nullable(),
        mobile: Yup.string().phone('contact.form.mobile').when('phone', (phone, passSchema) => phone == null || phone === '' ?
            passSchema.required('contact.form.mobile.required.conditional')
            : passSchema
        ).nullable(),
        alternative_email: Yup.string().email('contact.form.email2').nullable(),
    };

    static default_schema = Yup.object().shape({
        ...this.schema_fields
    }, [['phone', 'mobile'], ['phone', 'mobile']]);

    static schema = Yup.object().shape({
        ...this.schema_fields,
        company_name: Yup.string().required('company').max(100, 'company.max'),
        vat_number: Yup.string().nullable(),
    }, [['phone', 'mobile'], ['phone', 'mobile']]);

    static user_schema = Yup.object().shape({
        ...this.schema_fields,
        email: Yup.string().email('contact.form.email.invalid').required('contact.form.email'),
    }, [['phone', 'mobile'], ['mobile', 'mobile']]);

    static reseller_schema = Yup.object().shape({
        ...this.schema_fields,
        // email: Yup.string().email('contact.form.email.invalid').required('contact.form.email'),
        company_name: Yup.string().required('company').max(100, 'company.max'),
        vat_number: Yup.string().required(),
        url: Yup.string().required('contact.form.url')
            .max(255, 'contact.form.url|255|max')
            .test("is-url-without-https-http", "contact.form.url.invalid", val => {
                if (!val) return true;

                return Yup.object().shape({
                    url: Yup.string().url("contact.form.url.invalid")
                }).validate({ url: 'https://' + val.replace('http://', '').replace('https://', '').replace('www.', '') });
            })
    }, [['phone', 'mobile'], ['mobile', 'mobile']]);

    static schema_form_standard = Yup.object({
        first_name: Yup.string().required('firstname').max(100, 'firstname.max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'firstname.invalid', { excludeEmptyString: true }),
        last_name: Yup.string().required('lastname').max(100, 'lastname.max').matches(/^[A-z\u00C0-\u00ff' -]+$/, 'lastname.invalid', { excludeEmptyString: true }),
    })

    fullName = (inversed = false) => {
        if (inversed) {
            return this.last_name + ' ' + this.first_name;
        }

        return this.first_name + ' ' + this.last_name;
    }
}

export default Contact;
