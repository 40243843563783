import React from "react";
import EventRegistrationRepository from "../../repository/EventRegistrationRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import EventRegistrationTextFormComponent from "./forms/EventRegistrationTextFormComponent";
import Module from "../../models/module/Module";
import ModuleRepository from "../../repository/ModuleRepository";
import EventRegistration from "../../models/event/EventRegistration";
import { LoaderComponent } from "../basics/layout";
import { AttendeeRepository, ClientRepository, EventRepository } from "../../repository";
import FormattedMessageString from "../basics/FormattedMessageString";

class EventRegistrationFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new EventRegistration(),
            loading: true,
            settings: {},
            events: [],
            periods: [],
            clients: [],
            allAttendees: [],
            attendees: [],
            isEdit: false,
            roles: this.props.user.roles,
            btnSubmitDisabled: false
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings(),
            EventRepository.all(),
            ClientRepository.all(),
            AttendeeRepository.all(true)
        ];

        Promise.all(promises)
            .then(result => {
                let events = result[1];

                let eventId = parseInt(this.props.match.params.event)
                if (eventId) events = events.filter(event => event.id === eventId);

                this.setState({
                    settings: result[0]?.data?.settings,
                    events: events,
                    clients: result[2].filter(c => c.active),
                    allAttendees: result[3],
                    loading: false
                });
            });
    }

    handleEventChange = (_, value) => {
        let event = this.state.events.filter(event => event.id === value)[0];
        this.setState({ periods: event ? event.periods : [] });
    }

    handleClientChange = (_, value) => this.setState({ attendees: [] }, () => this.setState({ attendees: this.state.allAttendees.filter(a => a.active && a.client.id === value) }, () => {
        let form_errors = {};
        let btnSubmitDisabled = false
        if (!this.state.attendees.length) {
            form_errors = { client: <FormattedMessageString id="CMS.Event.registration.Content.Form.client.no_attendees" /> };
            btnSubmitDisabled = true;
        }
        this.setState({ form_errors: form_errors, btnSubmitDisabled: btnSubmitDisabled });
    }));

    handleSaveSuccess = () => {
        FormToastComponent.successTrans("Event", "Default.saved");
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/registrations/' + this.props.match.params.event + '/overview');
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="event-registration"
                validationSchema={EventRegistration.schema(this.state.settings)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (this.state.isEdit) {
                        EventRegistrationRepository.update({ id: values.id, language: this.props.match.params.language, ...values })
                            .then(() => this.handleSaveSuccess())
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    } else {
                        EventRegistrationRepository.create(values)
                            .then(() => this.handleSaveSuccess())
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }
                }}
                formErrors={this.state.form_errors}
                fields={EventRegistrationTextFormComponent.fields(this.state, this)}
                btnSubmitDisabled={this.state.btnSubmitDisabled}
            />
        );
    }
}

export default withRouter(EventRegistrationFormComponent);