import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import API from "../services/api";

export default class FormRepository {
    static getTypes() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/form/field/types',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.message));
        });
    }

    static getMailSettings(id, language) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/form/mail/settings/' + id + '?language=' + language,
                { headers: CustomHeaders.get() }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error.message));
        });
    }

    static create(values) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/form/create',
                values,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }

    static delete(form) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/form/delete?id=' + form,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }

    static getRows() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/form/all',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data.forms))
                .catch(error => reject(error.message));
        });
    }

    static get(id, language) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/form/details/' + id + '?lng=' + language,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error.message))
        })
    }

    static update(formId, language, value) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/form/content/update',
                {
                    id: formId,
                    language: language,
                    value: value
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }

    static updateForm(values, language) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/form/update',
                {
                    ...{ language: language },
                    ...values
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/form/toggle',
                { id: id },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(response.data.active);
                    }
                })
                .catch(error => reject(error))
        })
    }

    static deleteEntries(entries) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/form/entry/delete?ids=' + entries,
                { headers: CustomHeaders.get() }
            )
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }
}
