import React from 'react';
import LoginFormComponent from "../../components/authentication/LoginFormComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PolicyComponent from "../../components/basics/PolicyComponent";
import LogoComponent from "../../components/basics/LogoComponent";
import LangSwitchComponent from "../../components/basics/LangSwitchComponent";
import User from '../../models/user/User';
import LoaderComponent from '../../components/basics/layout/LoaderComponent';

class LoginFragment extends React.Component {

    constructor(props) {
        super(props);

        User.logout();

        this.state = {
            error: null,
            loading: true
        };
    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.error != null && this.props.history.action === "REPLACE") {
            this.setState({ error: this.props.location.state.error, loading: false })
        } else {
            this.setState({ loading: false })
        }

        if (this.props.location.state !== undefined && this.props.location.state.message != null && this.props.history.action === "REPLACE") {
            this.setState({ message: this.props.location.state.message, loading: false })
        } else {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;
        
        return (
            <>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>
                                <LogoComponent />
                            </Col>
                            <Col>
                                <LangSwitchComponent />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <LoginFormComponent error={this.state.error} message={this.state.message} />
                    </Card.Body>
                </Card>
                <PolicyComponent />
            </>
        )
    }
}

export default LoginFragment;