import React from "react";
import MutateModalComponent from "../../basics/table/MutateModalComponent";
import TwoFARepository from "../../../repository/TwoFARepository";
import FormToastComponent from "../../basics/FormToastComponent";
import User from "../../../models/user/User";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";


class DeactivateModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deactivate2fa: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    deactivate(code) {
        TwoFARepository.deactivate(code)
            .then(response => {

                if (response.data.success) {
                    this.props.parent.setState({
                        twoFaActivated: false,
                    });
                    User.removeTwoFactor();

                    FormToastComponent.successTrans('TwoFactor', 'Auth.2fa.deactivate.success');
                    MutateModalComponent.closeModal(this)
                } else {
                    console.error(response.data);
                    FormToastComponent.errorTrans('Auth.2fa.activate.failed')
                }
            })
            .catch(error => {
                FormToastComponent.errorTrans('Auth.2fa.deactivate.error')
            })
    };

    handleSubmit(values) {
        this.deactivate(values.code)
    }


    render() {
        return (
            <MutateModalComponent title={"Auth.2fa.title.deactivate"} parent={this} customIdentifier={"deactivate2fa"}>
                <Formik
                    enableReinitialize
                    validationSchema={Yup.object({
                        code: Yup.number().required()
                    })}
                    initialValues={
                        {
                            code: null
                        }
                    }
                    onSubmit={this.handleSubmit}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                {
                                    this.props.login ?
                                        <FormattedMessage id={"Auth.2fa.login.passcode"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        :
                                        <FormattedMessage id={"Auth.2fa.step2.passcode"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                }
                                <FormattedMessage id={"Auth.2fa.step2.passcode.placeholder"}>
                                    {
                                        (value) => <Form.Control type="text" name="code"
                                                                 value={values.code || ''}
                                                                 onChange={handleChange}
                                                                 isInvalid={!!errors.code}
                                                                 placeholder={value}/>
                                    }
                                </FormattedMessage>
                            </Form.Group>
                            <Row className={"buttons"}>

                                <Col xs={6}>
                                    <Form.Group>
                                        <Button type="submit" className={"w-100"}><FormattedMessageString
                                            id={"Auth.2fa.deactivate"}/></Button>
                                    </Form.Group>
                                </Col>

                                <Col xs={6}>
                                    <Form.Group>
                                        <Button variant="secondary"
                                                onClick={event => MutateModalComponent.closeModal(this)}
                                                className={"w-100"}><FormattedMessageString
                                            id={"Default.close"}/></Button>
                                    </Form.Group>
                                </Col>


                            </Row>
                        </Form>
                    )}

                </Formik>
            </MutateModalComponent>
        );
    }
}


export default DeactivateModalComponent;
