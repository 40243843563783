import React from "react";
import PropTypes from "prop-types";

class DashedProgressBarComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1
        }

    }

    changeStep = (step) => {
        this.setState({
            step: step
        })
    };

    dash(count) {
        return (
            <div className={"dash"} data-count={count} key={"dash-" + count} data-active={count <= this.state.step ? 'true': 'false'}>
                step {count}
            </div>
        )
    }

    render() {

        let dashes = [];

        for (let i = 1; i <= this.props.total_steps; i++) dashes.push(this.dash(i));

        return (
            <div className={"dashed-progressbar"}>
                {dashes}
            </div>
        );
    }
}
DashedProgressBarComponent.propTypes = {
    total_steps: PropTypes.number.isRequired,
};
export default DashedProgressBarComponent;
