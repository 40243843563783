import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormInvalidError from "../../errors/FormInvalidError";
import FormRequiredError from "../../errors/FormRequiredError";
import { FormWebsiteError } from "../../errors";

class MediaFormNameError extends React.Component {
    render() {
        switch (this.props.error) {
            case "CMS.Media.Form.name.max":
            case "CMS.Page.eyecatcher.upload.url_text.max":
                return <FormMaxError field_name={this.props.error.slice(0, -4)} max={255} />;
            case "CMS.Media.Form.name.invalid":
                return <FormInvalidError field_name={this.props.error.slice(0, -8)} />;
            case 'CMS.Website.Form.url':
                return <FormWebsiteError field_name={this.props.error} />;
            default:
                return <FormRequiredError field_name={this.props.error} />;
        }
    }
}

MediaFormNameError.propTypes = {
    error: PropTypes.string.isRequired
};
export default MediaFormNameError;
