import React from "react";
import PropTypes from "prop-types";
import FormRequiredError from "../../errors/FormRequiredError";
import FormWebsiteError from "../../errors/FormWebsiteError";

class WebsiteFormWebsiteError extends React.Component{
    render() {
        if (this.props.error.slice(this.props.error.length - 7, this.props.error.length) === "invalid") {
            return <FormWebsiteError field_name={this.props.error.slice(0, -8)}/>;
        } else {
            return <FormRequiredError field_name={this.props.error}/>;
        }
    }
}

WebsiteFormWebsiteError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default WebsiteFormWebsiteError;
