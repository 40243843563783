import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebsiteSubTableUserOverviewComponent from "../websites/WebsiteSubTableUserOverviewComponent";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import FormToastComponent from "../basics/FormToastComponent";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import Application from "../../helpers/Application";
import moment from "moment";

const { SearchBar } = Search;

class OverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                subRows: 2,
                rows: this.props.rows
            },
            tableLoading: true,
            type: this.props.type,
            showDeleteModal: false,
            deleteRow: null
        };

        this.closeDeleteModal = this.closeDeleteModal.bind(this);

        this.columns = [
            {
                dataField: 'id',
                text: 'Id',
                sort: true,
                hidden: true
            },
            {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.User.overview.table.name" />,
                sort: true,
                classes: 'name first',
                headerClasses: 'first',
                style: {
                    width: this.props.type === "resellers" ? '17.9%' : this.props.type === "managers" ? '29.95%' : this.props.type === "customers" ? '' : ''
                }
            },
            {
                dataField: 'language',
                text: <FormattedMessageString id="CMS.User.overview.table.language" />,
                sort: true,
                formatter: ((cellContent, row) => {
                    return (
                        <div className={"language-single"}>
                            <span>{row.language}</span>
                        </div>
                    )
                }),
                style: {
                    width: this.props.type === "customers" ? '' : '9.8%',
                    minWidth: '110px'
                }
            },
            {
                dataField: 'company',
                text: <FormattedMessageString id="CMS.User.overview.table.company" />,
                sort: true,
                style: {
                    width: this.props.type === "customers" ? '' : '16.4%'
                }
            },
            {
                dataField: 'email',
                text: <FormattedMessageString id="CMS.User.overview.table.email" />,
                sort: true,
                style: {
                    width: this.props.type === "resellers" ? '22.1%' : '29.95%'
                }
            },
            {
                dataField: 'last_login',
                text: <FormattedMessageString id="CMS.User.overview.table.last_login" />,
                sort: true,
                formatter: (cellContent, row) => (
                    <div className="updated_by">
                        {row.lastLogin ? moment(row.lastLogin).format('DD/MM/YYYY HH:mm:ss') : ''}
                    </div>
                ),
                style: { width: '22.1%' }
            },
            {
                dataField: 'add_action',
                isDummyField: true,
                text: '',
                formatter: (cellContent, row) => {
                    return (
                        <div className={"add"}>
                            <Link to={"/customers/add/" + row.key} >
                                <FontAwesomeIcon icon={['fas', 'user-plus']} />
                                <FormattedMessageString id="CMS.User.overview.customer.add" />
                            </Link>
                        </div>
                    );
                },
                style: {
                    width: '17.9%'
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                formatter: (cellContent, row) => {
                    return (
                        <div className={"actions text-right"}>
                            {
                                row.role === "EDITOR" ?
                                    <Link className={"default"} to={"/managers/permissions/" + row.key}>
                                        <FontAwesomeIcon icon={['far', 'key']} />
                                    </Link>
                                    :
                                    null
                            }
                            <Link className={"edit"} to={"edit/" + row.key}>
                                <FontAwesomeIcon icon={['fas', 'edit']} />
                            </Link>
                            {
                                CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ?
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        this.openDeleteModal(event, row)
                                    }}
                                        className={this.props.user.email !== row.email && row.deletable ? "delete" : "delete disabled"}
                                    >
                                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                    </Link>
                                    : null
                            }
                        </div>
                    );
                },
                style: {
                    width: this.props.type === "customers" ? '' : '10.3%'
                }
            }
        ];

        this.customer_columns = [...this.columns];

        this.customer_columns.splice(1, 1, {
            dataField: 'name',
            text: 'Name',
            sort: true,
            classes: 'name first',
            headerClasses: 'first',
            formatter: ((cellContent, row) => {
                return (
                    <>
                        {/*
                        <div className={"info before-action"}>
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <strong>API</strong> {row.api_key}
                                    </Tooltip>
                                }
                            >
                                <Link to="#"><FontAwesomeIcon icon={['fal', 'info-circle']} /></Link>
                            </OverlayTrigger>
                        </div> */}
                        {
                            this.state.type !== "customers" ?
                                <div className={"user before-action"}>
                                    <Link to="#"><FontAwesomeIcon icon={['far', 'user']} /></Link>
                                </div>
                                : null
                        }
                        {row.name}
                    </>
                )
            }),
            style: {
                width: '17.9%'
            }
        });

        this.customer_columns.splice(5, 1);
        this.customer_columns.splice(5, 1, {
            dataField: 'add_action',
            isDummyField: true,
            text: '',
            formatter: (cellContent, row) => {
                return (
                    <div className={"add"}>
                        <Link to={"/websites/add/" + row.key}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            <FormattedMessageString id="CMS.User.overview.website.add" />
                        </Link>

                    </div>
                );
            },
            style: {
                width: '17.9%'
            }
        });

        this.customer_columns.splice(6, 1, {
            dataField: 'actions',
            isDummyField: true,
            text: 'Acties',
            formatter: (cellContent, row) => {
                return (
                    <div className="actions">
                        <Link className={"edit"} to={"/customers/edit/" + row.key}><FontAwesomeIcon
                            icon={['fas', 'edit']} /></Link>
                        <Link to="#" onClick={(event) => {
                            event.preventDefault();
                            this.openDeleteModal(event, row, true)
                        }}
                            className={"delete"}><FontAwesomeIcon icon={['far', 'trash-alt']} /></Link>
                    </div>
                );
            },
            style: {
                width: this.props.type === "customers" ? '' : '10.3%'
            }
        });

        if (this.state.type === 'resellers') {
            this.columns.splice(5, 1);
        }

        if (this.state.type === "managers") {
            this.columns.splice(3, 1);
            this.columns.splice(5, 1);

            this.columns.splice(1, 1, {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.User.overview.table.name" />,
                sort: true,
                classes: 'name first',
                headerClasses: 'first',
                formatter: ((cellContent, row) => {
                    return row.name;
                }),
                style: {
                    width: '29.95%'
                }
            })
        }

        this.expandWebsiteRow = {
            renderer: row => (
                <WebsiteSubTableUserOverviewComponent rows={row.rows} />
            ),
            showExpandColumn: true,
            onlyOneExpanding: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <FontAwesomeIcon icon={['fas', 'chevron-up']} />
                    );
                }
                return (
                    <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                );
            },
            className: 'custom-sub-data-table-wrapper'
        };

        this.expandRow = {
            renderer: row => (
                <ToolkitProvider
                    keyField="id"
                    data={row.rows}
                    columns={this.customer_columns}
                    bootstrap4
                >
                    {
                        props => (
                            <BootstrapTable
                                {...props.baseProps}
                                expandRow={this.state.type === "resellers" ? this.expandWebsiteRow : <></>}
                                defaultSorted={[{
                                    dataField: 'name',
                                    order: 'asc'
                                }]}
                            />
                        )
                    }
                </ToolkitProvider>
            ),
            showExpandColumn: true,
            onlyOneExpanding: true,
            expandColumnPosition: 'right',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return null;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <FontAwesomeIcon icon={['fas', 'chevron-up']} />
                    );
                }
                return (
                    <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                );
            },
            onExpand: (row, isExpand, rowIndex, e) => { },
            parentClassName: 'expanded'
        };
    }

    openDeleteModal = (event, row, customerDelete = false) => {
        event.preventDefault();
        this.setState({
            showDeleteModal: true,
            deleteRow: row,
            customerDelete: customerDelete
        });
    };

    closeDeleteModal = () => this.setState({
        showDeleteModal: false,
        deleteRow: null,
        customerDelete: false
    });

    deleteRow = (event, _row) => {
        event.preventDefault();
        if (_row.role === "customer" && this.props.delete_customer_func != null) {
            this.props.delete_customer_func(_row.key)
                .then(() => {
                    let data = this.state.data;
                    data.rows[_row.parentIndex].rows = this.state.data.rows[_row.parentIndex].rows.filter((row) => {
                        return _row.key !== row.key;
                    });
                    this.setState({
                        data: data
                    }, () => {
                        FormToastComponent.successTrans('User', 'CMS.Table.delete.success');
                        this.closeDeleteModal()
                    });
                })
                .catch(() => FormToastComponent.errorTrans('CMS.Table.delete.error'));
        } else {
            this.props.delete_func(_row.key, _row.role, _row.websiteId)
                .then(() => {
                    let data = this.state.data;
                    data.rows = this.state.data.rows.filter((row) => {
                        return _row.key !== row.key;
                    });
                    FormToastComponent.successTrans('User', 'CMS.Table.delete.success');
                    this.closeDeleteModal();
                })
                .catch(() => FormToastComponent.errorTrans('CMS.Table.delete.error'));
        }
    }

    render() {
        return (
            <div className={"custom-data-table user"}>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.data.rows}
                    columns={this.state.type === "customers" ? this.customer_columns : this.columns}
                    search
                    bootstrap4
                >
                    {
                        props => (
                            <PaginationProvider
                                pagination={paginationFactory(
                                    {
                                        sizePerPage: Application.setting('table_number_rows', 100),
                                        hideSizePerPage: true,
                                        hidePageListOnlyOnePage: true,
                                        custom: true,
                                        totalSize: this.state.data.rows.length,
                                        prePageText: <FormattedMessageString id={"CMS.Table.Pagination.prev"} />,
                                        nextPageText: <FormattedMessageString id={"CMS.Table.Pagination.next"} />,
                                    }
                                )}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div>
                                            <div className={"table-header"}>
                                                <FormattedMessage id={this.props.title_id}>
                                                    {
                                                        (title) =>
                                                            <FormattedMessage id={"CMS.Table.overview"}>
                                                                {
                                                                    (title2) => <p>{title + " " + title2}</p>
                                                                }
                                                            </FormattedMessage>
                                                    }
                                                </FormattedMessage>
                                                <div className={"custom-search-bar"}>
                                                    <FontAwesomeIcon icon={['fas', 'search']} />
                                                    <FormattedMessage id={"CMS.Table.search.placeholder"}
                                                        values={{ "type": this.props.type }}>
                                                        {
                                                            (value) => <SearchBar {...props.searchProps}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                expandRow={this.state.type === "resellers" ? this.expandRow : this.state.type === "customers" ? this.expandWebsiteRow : <></>}
                                                wrapperClasses="table-responsive"
                                                {...paginationTableProps}
                                                defaultSorted={[{
                                                    dataField: 'name',
                                                    order: 'asc'
                                                }]}
                                            />
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    )
                                }
                            </PaginationProvider>
                        )
                    }
                </ToolkitProvider>

                <ModalFormFragment title_id={this.state.customerDelete ? "CMS.Customer.overview.delete.title" : this.props.delete_title_id} show={this.state.showDeleteModal}
                    onHide={this.closeDeleteModal} is_delete={true}>
                    <DeleteConfirmComponent onHide={this.closeDeleteModal} delete_text_id={this.props.delete_text_id}
                        delete_func={(event) => {
                            this.deleteRow(event, this.state.deleteRow)
                        }} />
                </ModalFormFragment>

            </div>
        )
    }
}

OverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    delete_title_id: PropTypes.string.isRequired,
    delete_text_id: PropTypes.string.isRequired,
    delete_func: PropTypes.func.isRequired,
    delete_customer_func: PropTypes.func
};
export default OverviewTableComponent;

/*
Reseller table:
1109,22

name:
218 - 20 = 198
17,9

Lang:
109
9,8

bedrijf:
182
16,4

email:
245
22,1

Add
199
17,9

Actions
114
10,3

Open
43 + 20 = 63
5,7


 */
