import React from "react";
import { withRouter } from 'react-router-dom';
import StandardFormComponent from "../basics/form/StandardFormComponent";
import PageRepository from "../../repository/PageRepository";
import FormToastComponent from "../basics/FormToastComponent";
import PageContent from "../../models/page/PageContent";
import PageFormSeoError from "./errors/PageFormSeoError";
import FormHelper from "../../helpers/FormHelper";
import LoaderComponent from "../basics/layout/LoaderComponent";
import { JsonHelper } from "../../helpers";

class PageSeoComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            seo: null,
            isLoading: true,
            form_errors: []
        }
    }

    componentDidMount() {
        this.props.get_promise
            .then(seo => {
                if (seo !== undefined) {
                    if (seo.tags == null) {
                        seo.tags = []
                    } else {
                        seo.tags = JsonHelper.tryParse(seo.tags, [], []);
                    }

                    this.setState({ seo: seo, isLoading: false })
                } else {
                    FormToastComponent.errorTrans("Default.error")
                }
            })
            .catch(error => FormToastComponent.error(error))
    }

    goBackAfterCreate = () => this.props.history.push("/" + this.props.slug);

    getFields() {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        xl: 8,
                        fields: [
                            {
                                title: "CMS.Page.Content.Seo.field.title",
                                type: "text",
                                name: "title",
                                customFeedback: PageFormSeoError
                            },
                            {
                                title: "CMS.Page.Content.Seo.field.tags",
                                type: "tags",
                                name: "tags",
                                message: "CMS.Page.Content.Seo.field.tags.message",
                                customFeedback: PageFormSeoError
                            },
                            {
                                title: "CMS.Page.Content.Seo.field.description",
                                type: "textarea",
                                name: "description",
                                customFeedback: PageFormSeoError
                            }
                        ]
                    }
                ]
            }
        ]
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent
                {...this.props}
                parent={this}
                model={this.state.seo}
                validationSchema={PageContent.seo_schema}
                formSubmit={(values, formikActions) => {
                    PageRepository.storeSeo({ id: values.page_content, title: values.title, tags: JSON.stringify(values.tags), description: values.description })
                        .then(() => {
                            FormToastComponent.successTrans("SEO", "Default.saved");
                            this.goBackAfterCreate();
                        })
                        .catch(error => FormHelper.handleSubmitError(this, error))
                }}
                fields={this.getFields()}
                formErrors={this.state.form_errors}
            />
        );
    }
}

export default withRouter(PageSeoComponent);