import axios from "axios";
import config from 'react-global-configuration';
import User from "../models/user/User";
import CustomHeaders from "./CustomHeaders";

class AuthenticationRepository {
    static login(email, password) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/login', {
                    'email': email,
                    'password': password
                },
                {
                    headers: CustomHeaders.get()
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static logout = () => {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/logout',
                {
                    headers: CustomHeaders.get()
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static resetPasswordRequest(email) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/password/create', {
                    'email': email
                }, {
                    headers: {
                        'X-localization': localStorage.getItem('language')
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response));
        })
    }

    static resetPassword(password, c_password) {
        let user = User.get();
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/password/reset', {
                    'email': user.email,
                    'password': password,
                    'password_confirmation': c_password,
                    'token': user.token
                },
                {
                    headers: {
                        'X-localization': localStorage.getItem('language')
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response));
        })
    }

    static resetPasswordFind(token) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/password/find/' + token,
                {
                    headers: {
                        'X-localization': localStorage.getItem('language')
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response));
        });
    }

    static resetPasswordByPassword(current_password, new_password, new_c_password) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/reset/password',
                {
                    'current_password': current_password,
                    'new_password': new_password,
                    'new_c_password': new_c_password
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }
}

export default AuthenticationRepository;