import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Faq from "../../models/faq/Faq";
import FaqTextFormComponent from "./forms/FaqTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import FaqRepository from "../../repository/FaqRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import * as Yup from "yup"
import Module from "../../models/module/Module";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";

class FaqEditFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            faq: null,
            faqContent: null,
            isLoading: true
        };

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];
        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                // Faq
                let faq = Faq.fromJson(result[1]);
                let faqContent = faq.content.filter(content => content.language === this.props.match.params.language)[0];
                faqContent.answer = JSON.parse(faqContent.answer);
                faqContent.category = faq.category;

                this.setState({ faq: faq, settings: settings, faqContent: faqContent, isLoading: false }, () => {
                    let moduleContent = Module.getContent(localStorage.getItem('language'));
                    this.props.parent.updateTitle(<FormattedMessageString id="CMS.Module.edit.title" values={{ module: moduleContent.title, title: faqContent.question }} />);
                });
            })
            .catch(error => {
                FormToastComponent.error(error);
                this.goBackAfterCreate();
            });
    }

    goBackAfterCreate = () => this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');

    getBeforeSubmit(element) {
        return true
        //return FaqTextFormComponent.fields_image_before_submit(element);
    }

    validation() {
        let fields = {
            question: Yup.string().required('CMS.Faq.Content.Form.question').max(255, 'CMS.Faq.Form.name|255|max'),
            answer: Yup.object({ text: Yup.string().required('CMS.Faq.Content.Form.answer') })
        };

        if (this.state.settings.use_categories) {
            fields['category'] = Yup.mixed().required('CMS.Faq.Form.category|select');
        }

        return Yup.object(fields);
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent
                {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.faqContent}
                validationSchema={this.validation()}
                formSubmit={(values, formikActions) => {
                    FaqRepository.update(this.state.faq.id, values)
                        .then(response => {
                            if (response.success) {
                                FormToastComponent.successTrans(values.question, "Default.saved")
                                this.goBackAfterCreate();
                            } else {
                                FormHelper.handleSubmitError(this, null)
                            }
                        })
                        .catch(error => {
                            FormHelper.handleSubmitError(this, error)
                        })
                }}
                formErrors={this.state.form_errors}
                fields={FaqTextFormComponent.fields(this.state.settings, this.props.match.params.language)}
                rawValidationSchema={this.state.validation}
            />
        )
    }
}

export default withRouter(FaqEditFormComponent);