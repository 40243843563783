import MediaHelper from "../../../helpers/MediaHelper";
import ProductFormError from "../errors/ProductFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import MediaFileUploadComponent from "../../media/image/MediaFileUploadComponent";

class ProductTextFormComponent {
    static fields(settings) {
        let fields = [
            {
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.title",
                        type: "text",
                        name: 'title',
                        customFeedback: ProductFormError,
                        formErrorName: "title"
                    }
                ]
            }
        ];

        if (settings.use_spotlight) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.spotlight",
                        type: "switch",
                        name: 'spotlight',
                        customFeedback: ProductFormError,
                        formErrorName: "spotlight"
                    }
                ]
            });
        }

        if (settings.use_categories) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: 'CMS.Product.Content.Form.category',
                        type: "select",
                        name: "category",
                        options: settings.references.map(item => {
                            if (item.children.length) return false;
                            let label = item.depthLabel.join(' > ');
                            return { value: item.id, label: label };
                        }).filter(x => x !== false),
                        customFeedback: ProductFormError,
                        formErrorName: "category"
                    }
                ]
            });
        }

        if (settings.show_article_number) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.article_number",
                        type: "text",
                        name: 'article_number',
                        customFeedback: ProductFormError,
                        formErrorName: "article_number"
                    }
                ]
            });
        }

        fields.push({
            type: "col",
            md: 8,
            fields: [
                {
                    title: "CMS.Product.Content.Form.unit_price",
                    type: "text",
                    name: 'unit_price',
                    customFeedback: ProductFormError,
                    formErrorName: "unit_price",
                    customProps: { addOn: { name: 'currency' } }
                }
            ]
        })

        if (settings.show_stock) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.stock",
                        type: "number",
                        name: 'stock',
                        customFeedback: ProductFormError,
                        customProps: { min: 0 },
                        formErrorName: "stock"
                    }
                ]
            });
        }

        for (let i = 1; i <= 3; i++) {
            if (!settings['show_additional_field_' + i]) continue;

            if (settings['additional_field_' + i + '_type'] === 'editor') {
                fields.push({
                    type: "col",
                    md: 8,
                    fields: [],
                    custom: StandardTextFormEditorComponent,
                    customProps: {
                        valueField: 'additional_field_' + i + '_value',
                        placeholder: 'CMS.Product.Content.Form.additional_field_value.placeholder',
                        label: settings['additional_field_' + i + '_label']
                    }
                });
            } else {
                fields.push({
                    type: "col",
                    md: 8,
                    fields: [
                        {
                            type: settings['additional_field_' + i + '_type'],
                            title: settings['additional_field_' + i + '_label'],
                            placeholder: 'CMS.Product.Content.Form.additional_field_value.placeholder',
                            message: settings['additional_field_' + i + '_text'],
                            name: 'additional_field_' + i + '_value',
                            customFeedback: ProductFormError,
                            formErrorName: 'additional_field_' + i + '_value'
                        }
                    ]
                });
            }
        }

        if (settings.use_product_tags) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.tags",
                        type: 'select-creatable', //"tags",
                        name: 'tags',
                        options: settings.tags ? settings.tags.map(tag => {
                            return { value: tag, label: tag };
                        }) : [],
                        multi: true,
                        clearable: true,
                        optional: true,
                        customFeedback: ProductFormError,
                        formErrorName: "tags"
                    }
                ]
            });
        }

        if (settings.show_summary) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Product.Content.Form.summary",
                        type: "textarea",
                        name: 'summary',
                        customFeedback: ProductFormError,
                        formErrorName: "summary"
                    }
                ]
            });
        }

        fields.push({
            type: "col",
            md: 8,
            fields: [],
            custom: StandardTextFormEditorComponent,
            customProps: { valueField: 'content', label: 'CMS.Product.Content.Form.content', placeholder: 'CMS.Product.Content.Form.content.placeholder' }
        });

        if (settings.show_cover_image) {
            fields.push({
                type: "col",
                md: 8,
                fields: [],
                custom: MediaImageUploadComponent,
                customProps: { label: 'CMS.Product.Content.Form.image', type: 'product_image', settings: settings, field: 'image', formErrorName: 'image' }
            });
        }

        if (settings.show_pdf) {
            fields.push({
                type: "col",
                md: 8,
                fields: [],
                optional: true,
                custom: MediaFileUploadComponent,
                customProps: { label: 'CMS.Product.Content.Form.pdf_document', type: 'product_file', settings: settings, field: 'pdf_document', formErrorName: 'pdf_document' }
            });
        }

        return [{
            type: "row",
            children: fields
        }];
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            let type = module ? module.module.type : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'product_image', type, element.props.id, null, item.title);
            }

            if (item.pdf_document !== null && item.pdf_document !== undefined && (typeof item.pdf_document !== 'string')) {
                item.pdf_document = await MediaHelper.uploadImage(item, 'pdf_document', 'product_file', type, element.props.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }
}

export default ProductTextFormComponent;