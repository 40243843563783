import React from "react";
import Page from "../../models/page/Page";
import PageRepository from "../../repository/PageRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import PageCustomSettingsFormComponent from "./PageCustomSettingsFormComponent";
import PageFormError from "./errors/PageFormError";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import ModuleRepository from "../../repository/ModuleRepository";
import { LoaderComponent } from "../basics/layout";
import Module from "../../models/module/Module";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";

class PageAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new Page(),
            loading: true,
            pages: [],
            types: [],
            selectedType: null,
            modules: [],
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            PageRepository.getTypes(),
            PageRepository.all(),
            ModuleRepository.all()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise)
        }

        Promise.all(promises)
            .then(result => {
                let types = result[0];
                let pages = result[1];
                let modules = result[2];

                if (types !== undefined && types.data !== undefined) {
                    types = types.data.map(type => ({ value: type, label: type }));

                    if (this.isManager) {
                        types = types.filter(type => type.value === 'url' || type.value === 'text')
                    }

                    types = types.filter(type => type.value !== 'sitemap');
                }
                
                let pagesList = [];
                if (pages !== undefined) {
                    pagesList = pages.map(page => {
                        return {
                            value: page.page.id,
                            label: '-'.repeat(page.index) + " " + page.title,
                            isDisabled: page.index >= 2
                        }
                    });
                }

                let modulesList = [];
                let modulesLinked = [];
                if (modules !== undefined) {
                    // Ignore module referenced to other module
                    modulesLinked = modules.map(module => parseInt(Module.getSetting(module.module, 'module'))).filter(x => x);
                    modules = modules.filter(module => modulesLinked.indexOf(module.id) < 0);

                    // Ignore Client and attendee modules
                    modules = modules.filter(module => [ModuleTypeEnum.ATTENDEE, ModuleTypeEnum.CLIENT, ModuleTypeEnum.MAP].indexOf(module.module.type) < 0).filter(x => x);

                    modules.forEach(module => modulesList.push({ value: module.id, label: module.title }));
                }

                let model = this.state.model;
                let selectedType = this.state.selectedType;

                if (result[3] !== undefined) {
                    let page = Page.fromJson(result[3]);
                    let pageContent = page.content.filter(content => content.language === this.props.match.params.language)[0];
                    model = {
                        id: page.id,
                        name: pageContent.title,
                        type: page.type,
                        modules: page.modules,
                        parent: page.parent,
                        settings: page.settings,
                        _page: page
                    }

                    selectedType = page.type;
                }

                this.setState({
                    types: types,
                    selectedType: selectedType,
                    pages: pagesList,
                    modules: modulesList,
                    model: model,
                    loading: false,
                    isEdit: model.id !== undefined
                })
            })
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                parent={this}
                className="page"
                validationSchema={Page.schema_add}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (this.state.isEdit) {
                        PageRepository.update({ id: values.id, language: this.props.match.params.language, settings: { ...values.settings }, title: values.name, modules: values.modules })
                            .then(() => {
                                FormToastComponent.successTrans("Pages", "Default.saved");
                                this.props.history.push("/" + this.props.slug);
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    } else {
                        let data = {
                            title: values.name,
                            type: values.type,
                            modules: values.modules,
                            parent: values.parent,
                            settings: { ...values.settings }
                        };
                        PageRepository.create(data)
                            .then(() => {
                                FormToastComponent.successTrans("Pages", "Default.saved");
                                this.props.history.push("/" + this.props.slug);
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }

                }}
                formErrors={this.state.form_errors}
                fields={[
                    {
                        type: "row",
                        children: [
                            {
                                type: "col",
                                xl: 8,
                                fields: [
                                    {
                                        title: "CMS.Pages.Form.name",
                                        type: "text",
                                        name: "name",
                                        customFeedback: PageFormError,
                                        formErrorName: "title"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 6,
                                fields: [
                                    {
                                        title: "CMS.Pages.Form.type",
                                        type: "select",
                                        name: "type",
                                        options: this.state.types,
                                        disabled: this.state.isEdit,
                                        onChange: this.handleTypeChange
                                    }
                                ]
                            },
                            {
                                type: "col",
                                xl: 8,
                                fields: [
                                    {
                                        title: "CMS.Pages.Form.modules",
                                        type: "select",
                                        name: this.state.selectedType && this.state.selectedType.indexOf('module') > -1 ? 'modules' : null,
                                        options: this.state.modules,
                                        message: "CMS.Pages.Form.modules.message",
                                        multi: true,
                                        sortable: true,
                                        beforeOnChange: this.formatModulesValue,
                                        customFeedback: PageFormError,
                                        formErrorName: "modules"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 6,
                                fields: [
                                    {
                                        title: "CMS.Pages.Form.parent",
                                        type: "select",
                                        name: "parent",
                                        options: this.state.pages,
                                        optional: true,
                                        disabled: this.state.isEdit
                                    }
                                ]
                            },
                            !this.isManager ? {
                                type: "col",
                                xl: 8,
                                fields: [],
                                custom: PageCustomSettingsFormComponent
                            } : {}
                        ]
                    }
                ]}
            />
        );
    }

    handleTypeChange = (event, value) => this.setState({ selectedType: value });

    formatModulesValue = (event) => event.map(module => module.value);
}

export default withRouter(PageAddFormComponent);