import React from "react";
import SortableTree from "react-sortable-tree";
import ProjectRepository from "../../repository/ProjectRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class ProjectOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            isLoading: true,
            index: this.props.index
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false, treeData: this.format(this.props.data) })
    }

    update = (data) =>
        ProjectRepository.updateTree(this.state.treeData)
            .then(response => {
                FormToastComponent.successTrans("Projects", "CMS.Projects.overview.tree.updated")
                this.props.parent.updateData()
            })
            .catch(error => {
                console.error(error.message);
                FormToastComponent.successTrans("Projects", "CMS.Projects.overview.tree.failed")
            });

    format = (array) => {
        let formattedArray = [];

        array.forEach(project => {
            let content = project.project.contents[this.state.index];
            formattedArray.push({
                title: content.title + '    ( id {' + project.id + '})',
                id: project.id,
                expanded: true
            })
        })

        return formattedArray;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div style={{ height: 500 }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    onMoveNode={(data) => this.update(data)}
                    maxDepth={0}
                />
            </div>
        );
    }

}

export default ProjectOverviewTreeComponent;
