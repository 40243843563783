import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TableEditActionComponent extends React.Component {
    render() {
        return (
            <Link className={this.props.disabled ? "edit disabled" : "edit"} to={"edit/" + this.props.id + this.props.custom}>
                <FontAwesomeIcon icon={['fas', 'edit']} />
            </Link>
        );
    }
}

TableEditActionComponent.propTypes = {
    id: PropTypes.any.isRequired,
    custom: PropTypes.string
};
export default TableEditActionComponent;
