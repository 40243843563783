import React from "react";
import SpinnerComponent from "./SpinnerComponent";

class LoadingScreenComponent extends React.Component {

    render() {
        return (
            <div className={'loading'}>
                <SpinnerComponent />
            </div>
        )
    }
}

export default LoadingScreenComponent;
