import React from "react";
import PropTypes from "prop-types";
import FormRequiredError from "../../errors/FormRequiredError";
import FormEmailError from "../../errors/FormEmailError";

class FormContactEmailError extends React.Component {
    render() {
        if (this.props.error === "contact.form.email.invalid") {
            return <FormEmailError field_name={"CMS.Profile." + this.props.error.slice(0, -8)} />;
        } else {
            return <FormRequiredError field_name={"CMS.Profile." + this.props.error} />;
        }
    }
}

FormContactEmailError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default FormContactEmailError;