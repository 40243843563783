class WebsiteSettingRow {

    id;
    key;
    name;
    value;
    isDefault;

    constructor(id, key, name, value, isDefault = false) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.value = value;
        this.isDefault = isDefault;
    }


}

export default WebsiteSettingRow;