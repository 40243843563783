import { JsonHelper } from "../../helpers";

export default class CategoryContent {
    id;
    title;
    language;
    slug;
    email;
    label;
    summary;
    highlights = [];
    value;
    image;
    home_image;
    svg;
    views;

    static fromJson(json) {
        let categoryContent = new CategoryContent();
        categoryContent.id = json['id'];
        categoryContent.title = json['title'];
        categoryContent.language = json['language'];
        categoryContent.slug = json['slug'];
        categoryContent.label = json.label ? json.label : '';
        categoryContent.email = json['email'];
        categoryContent.summary = json.summary ? json.summary : '';
        categoryContent.highlights = JsonHelper.tryParse(json['highlights'], []);
        categoryContent.value = json['value'] != null ? json['value'] : '{}';
        categoryContent.image = json['image'];
        categoryContent.home_image = json['home_image'];
        categoryContent.svg = json['svg'];
        categoryContent.views = json['views'];
        return categoryContent;
    }
}
