import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModalComponent from "./DeleteModalComponent";
import { Link } from "react-router-dom";

class TableDeleteActionComponent extends React.Component {
    render() {
        return (
            <Link className={this.props.disabled ? "delete disabled" : "delete"} to="#"
                onClick={(event) => DeleteModalComponent.openDeleteModal(event, this.props.row, this.props.parent)}
            >
                <FontAwesomeIcon icon={['far', 'trash-alt']} />
            </Link>
        );
    }
}

export default TableDeleteActionComponent;