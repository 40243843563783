import React from 'react';
import FormattedMessageString from "../../basics/FormattedMessageString";
import AttendeeFormError from "../errors/AttendeeFormError";

class AttendeeTextFormComponent {
    static fields(settings, parent) {
        let fields = [
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: "CMS.Attendee.Content.Form.client",
                        type: "select",
                        options: settings.clients,
                        name: 'client',
                        customFeedback: AttendeeFormError,
                        formErrorName: "client"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: []
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.first_name',
                        type: "text",
                        name: "first_name",
                        customFeedback: AttendeeFormError,
                        formErrorName: "first_name"
                    }
                ]
            },
            {
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.last_name',
                        type: "text",
                        name: "last_name",
                        customFeedback: AttendeeFormError,
                        formErrorName: "last_name"
                    }
                ]
            }
        ];

        if (settings.fields?.email) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.email',
                        type: "email",
                        name: "email",
                        customFeedback: AttendeeFormError,
                        formErrorName: "email"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] })
        }

        if (settings.fields?.phone) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.phone',
                        type: "text",
                        name: "phone",
                        optional: true,
                        customFeedback: AttendeeFormError,
                        formErrorName: "phone"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields?.birth_date) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.birth_date',
                        type: "date",
                        name: "birth_date",
                        optional: true,
                        customFeedback: AttendeeFormError,
                        formErrorName: "birth_date"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields?.gender) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.gender',
                        type: "select",
                        name: "gender",
                        optional: true,
                        options: ['m', 'f', 'x'].map(item => { return { value: item, label: <FormattedMessageString id={'CMS.Attendee.Content.Form.gender.' + item} /> } }),
                        customFeedback: AttendeeFormError,
                        formErrorName: "gender"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields?.national_insurance_number) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.national_insurance_number',
                        type: "text",
                        name: "national_insurance_number",
                        optional: true,
                        customFeedback: AttendeeFormError,
                        formErrorName: "national_insurance_number"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.fields?.medical_info) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.medical_info',
                        type: "textarea",
                        name: "medical_info",
                        optional: true,
                        customFeedback: AttendeeFormError,
                        formErrorName: "medical_info"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        if (settings.membership_webshop_discount_code) {
            fields.push({
                type: "col",
                md: 6,
                fields: [
                    {
                        title: 'CMS.Attendee.Content.Form.webshop_discount_code',
                        type: "text",
                        optional: true,
                        name: "webshop_discount_code",
                        customFeedback: AttendeeFormError,
                        formErrorName: "webshop_discount_code"
                    }
                ]
            });
            fields.push({ type: "col", md: 6, fields: [] });
        }

        [1, 2, 3].forEach(i => {
            if (settings['show_additional_dynamic_field_' + i]) {
                fields.push({
                    type: "col",
                    md: 6,
                    fields: [{
                        title: parent.props.intl.formatMessage({ id: "CMS.Attendee.Content.Form.addional_field" }, { title: settings['additional_dynamic_field_' + i + '_title'] }),
                        type: settings['additional_dynamic_field_' + i + '_type'] === 'dropdown' ? 'select' : "text",
                        optional: true,
                        name: 'additional_dynamic_field_' + i,
                        customFeedback: AttendeeFormError,
                        options: this.renderDynamicFieldOptions(settings['additional_dynamic_field_' + i + '_options']),
                        customProps: { placeholder: parent.props.intl.formatMessage({ id: "CMS.Attendee.Content.Form.addional_field.placeholder" }, { title: settings['additional_dynamic_field_' + i + '_title'] }) },
                        formErrorName: 'additional_dynamic_field_' + i
                    }]
                });
                fields.push({ type: "col", md: 6, fields: [] })
            }
        });

        return [{ type: "row", children: fields }];
    }

    static renderDynamicFieldOptions(values) {
        if (!values) return [];

        let language = localStorage.getItem('language');
        let options = [];
        values.forEach(value => {
            let content = value.contents.filter(content => content.language === language)[0];
            if (!content) content = value.contents[0];

            options.push({ value: value.id, label: content.value });
        });
        return options;
    }
}

export default AttendeeTextFormComponent;