import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Category from "../../models/category/Category";
import CategoryTextFormComponent from "./forms/CategoryTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import CategoryRepository from "../../repository/CategoryRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";
import API from "../../services/api";

class CategoryContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parent: props.match.params.parent ? props.match.params.parent : null,
            category: null,
            categoryContent: null,
            isLoading: true,
            settings: {},
            validation: {}
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }
                settings.titleOptional = true;

                let categoryResponse = result[1];
                let category = Category.fromJson(categoryResponse);
                let categoryContent = category.content.filter(content => content.language === this.props.match.params.language)[0];
                categoryContent.value = JSON.parse(categoryContent.value);

                if (categoryContent.value.content) {
                    categoryContent.value.content.forEach((_, i) => {
                        categoryContent.value.content[i].index = i;
                    })
                }

                this.setState({
                    settings: settings,
                    category: category,
                    parent: category.parent,
                    categoryContent: categoryContent
                }, () => this.setState({ isLoading: false }, () => this.props.parent.updateTitle(<FormattedMessageString id="CMS.Media.Content.block.add" />)))
            })
            .catch(error => FormToastComponent.error(error));
    }

    async getBeforeSubmit(element) {
        let promises = [];
        if (this.state.settings.show_cover_image) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'image', 'category_image'));
        }
        if (this.state.settings.show_svg) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'svg', 'category_svg'));
        }
        if (this.state.settings.content_with_blocks || this.state.settings.subcategories_content_with_blocks) {
            promises.push(CategoryTextFormComponent.fields_block_before_submit(element, 'category_block_image'));
        }

        await Promise.all(promises)
            .then(result => {
                return true;
            });
    }

    handleBlockTypeChange = (e) => {
        this.setState({
            settings: {
                ...this.state.settings,
                titleOptional: e.value === 'text'
            }
        });

        return e.value;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.categoryContent}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {
                        let data = {
                            id: this.state.category.id,
                            language: values.language,
                            type: values.type,
                            title: values.title,
                            //parent: values.parent ? values.parent : null,
                            //email: values.email,
                            //label: values.label,
                            summary: values.summary,
                            //highlights: JSON.stringify(values.highlights),
                            value: JSON.stringify(values.value),
                            image: values.image,
                            //home_image: values.home_image,
                            //svg: values.svg
                        };

                        CategoryRepository.update(data)
                            .then(response => {
                                if (API.isSuccess(response)) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    })
                }}
                formErrors={this.state.form_errors}
                fields={CategoryTextFormComponent.fields_block({ ...this.state.settings, ...{ onBlockTypeChange: this.handleBlockTypeChange } }, this.state.parent)}
                btnSubmitClass="visually-hidden"
            />
        )
    }
}

export default withRouter(CategoryContentComponent);