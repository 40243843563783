import React from "react";
import MediaHelper from "../../../helpers/MediaHelper";
import WeblinkFormError from "../errors/WeblinkFormError";
import Module from "../../../models/module/Module";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";

class WeblinkTextFormComponent {
    static fields(settings) {
        let fields = [
            {
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Weblink.Content.Form.title",
                        type: "text",
                        name: 'title',
                        customFeedback: WeblinkFormError,
                        formErrorName: "title"
                    }
                ]
            },
            {
                type: "col",
                md: 8,
                fields: [
                    {
                        title: "CMS.Weblink.Content.Form.url",
                        type: "url",
                        name: 'url',
                        customFeedback: WeblinkFormError,
                        formErrorName: "url"
                    }
                ]
            }
        ];

        if (settings.use_categories) {
            fields.push({
                type: "col",
                md: 8,
                fields: [
                    {
                        title: 'CMS.Weblink.Content.Form.category',
                        type: "select",
                        name: "category",
                        options: settings.references.map(item => {
                            if (item.children.length) {
                                return false;
                            }

                            let label = item.depthLabel.join(' > ');

                            return { value: item.id, label: label };
                        }).filter(x => x !== false),
                        customFeedback: WeblinkFormError,
                        formErrorName: "category"
                    }
                ]
            });
        }

        if (settings.use_reference) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: <FormattedMessageString id="CMS.Weblink.Content.Form.module_references" values={{ reference: settings.reference.title }} />,
                        type: "select",
                        name: "module_references",
                        multi: true,
                        options: this.renderReferenceOptions(settings.reference, 0),
                        customFeedback: WeblinkFormError,
                        formErrorName: "module_references"
                    }
                ]
            });
        }

        if (settings.show_content) {
            fields.push({
                type: "col",
                md: 12,
                fields: [],
                custom: StandardTextFormEditorComponent,
                customProps: { valueField: 'content', label: 'CMS.Weblink.Content.Form.content', placeholder: 'CMS.Weblink.Content.Form.content.placeholder' }
            });
        }

        if (settings.show_image) {
            fields.push({
                type: "col",
                md: 12,
                fields: [],
                custom: MediaImageUploadComponent,
                customProps: { label: 'CMS.Weblink.Content.Form.image', type: 'weblink_image', settings: settings, field: 'image', formErrorName: 'image' }
            });
        }

        return [{
            type: "row",
            children: fields
        }];
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;

            let type = module ? module.module.type : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'weblink_image', type, element.props.id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static renderReferenceOptions = (reference, language) => {
        let options = [];
        reference.items.forEach(item => options.push({ value: item.id, label: this.renderOptionLabel(reference, item, language) }))
        return options;
    }

    static renderOptionLabel = (reference, item, language) => {
        switch (reference.type) {
            case 'category':
                return item.depthLabel.join(' > ');
            default:
                let content = item[reference.type].contents.filter(content => content.language === language)[0];
                if (!content) content = item[reference.type].contents[0];
                return content.title ? content.title : (content.name ? content.name : '');
        }
    }
}

export default WeblinkTextFormComponent;