import React from "react";
import FaqFormError from "../errors/FaqFormError";
import FormattedMessageString from "../../basics/FormattedMessageString";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";

class FaqTextFormComponent {
    static fields(settings, language) {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Faq.Content.Form.question",
                                type: "text",
                                name: 'question',
                                customFeedback: FaqFormError,
                                formErrorName: "question"
                            }
                        ]
                    },
                    settings.use_categories ?
                        {
                            type: "col",
                            md: 12,
                            fields: [
                                {
                                    title: <FormattedMessageString id="CMS.Faq.Content.Form.category" />,
                                    type: "select",
                                    name: "category",
                                    options: settings.references.map(item => {
                                        if (item.children.length) {
                                            return false;
                                        }

                                        let label = item.depthLabel.join(' > ');

                                        return { value: item.id, label: label/*content.title*/ };
                                    }).filter(x => x !== false),
                                    customFeedback: FaqFormError,
                                    formErrorName: "category"
                                }
                            ]
                        }
                        : {},
                    {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        customProps: { valueField: 'answer', label: 'CMS.Faq.Content.Form.answer', placeholder: 'CMS.Faq.Content.Form.answer.placeholder' }
                    }
                ]
            }
        ]
    }

}

export default FaqTextFormComponent;