import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import StandardTableFormInputComponent from "../basics/table/StandardTableFormInputComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import ModuleRepository from "../../repository/ModuleRepository";
import FormToastComponent from "../basics/FormToastComponent";
import UserToItemPermissionsComponent from "../permissions/UserToItemPermissionsComponent";
import ToolTipLink from "../basics/ToolTipLink";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import cellEditFactory from "react-bootstrap-table2-editor";
import FormattedMessageString from "../basics/FormattedMessageString";
import TableHelper from "../../helpers/TableHelper";
import LoaderComponent from "../basics/layout/LoaderComponent";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";

class ModuleOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this._userPermissions = React.createRef();

        this.state = {
            isLoading: true,
            rows: this.props.rows,
            type: this.props.type,
            website: null,
            languages: ['nl', 'fr', 'en'],
            language: this.moduleLanguage()
        };

    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }))
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'placeholder',
                isDummyField: true,
                hidden: true
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Modules.overview.table.name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.module.contents[index];
                    if (content !== undefined)
                        return content.title;
                    else
                        return null;
                },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    let content = row.module.contents[index];
                    if (content !== undefined)
                        value = content.title;

                    return <StandardTableFormInputComponent {...editorProps} value={value} />
                },
                validator: (newValue, row, column) => {
                    if (!newValue) {
                        return { valid: false, message: <FormattedMessageString id="CMS.Table.validation.required" /> };
                    }
                }
            },
            {
                dataField: 'module.type',
                text: <FormattedMessageString id="CMS.Modules.overview.table.type" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return row.module.type;
                },
                editable: false
            },
            {
                dataField: 'module-actions',
                isDummyField: false,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                editable: false,
                formatter: (cellContent, row) => {
                    let contents = row.module.contents[index];
                    return (
                        <div className={"actions text-right"}>
                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                <ToolTipLink className={"default"} to="#"
                                    title="CMS.Modules.overview.tooltip.permissions"
                                    onClick={() => this._userPermissions.openModal("module_permission", row.id)}
                                >
                                    <FontAwesomeIcon icon={['far', 'key']} />
                                </ToolTipLink>
                                : null
                            }
                            {(
                                CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ||
                                (CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && row.module.type === ModuleTypeEnum.BLOG)
                            ) ?
                                <ToolTipLink className={"default"}
                                    to={"config/" + row.id + '/' + (contents !== undefined ? contents.language : null)}
                                    title={"CMS.Modules.overview.tooltip.config"}>
                                    <FontAwesomeIcon icon={['far', 'tools']} />
                                </ToolTipLink>
                                : null
                            }
                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                row.module.active ?
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)}
                                        className={"active"}><FontAwesomeIcon icon={['fas', 'check']} /></Link>
                                    :
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)}
                                        className={"inactive"}><FontAwesomeIcon icon={['far', 'times']} /></Link>

                                : null
                            }
                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ?
                                (!GeneralSettingsHelper.is() ?
                                    <TableDeleteActionComponent row={row} parent={this} disabled={[ModuleTypeEnum.ATTENDEE, ModuleTypeEnum.WEBSHOP].indexOf(row.module.type) > -1} /> : null)
                                : null
                            }
                        </div >
                    )
                }
            }
        ];
    }

    toggleActive = (event, _row) => {
        event.preventDefault();

        ModuleRepository.toggle(_row.module.id)
            .then(response => {
                let newRows = [...this.state.rows];
                newRows = newRows.map((row) => {
                    if (row.module.id === _row.module.id) {
                        let module = row.module;
                        module.active = response;
                        return {
                            ...row,
                            module: module
                        }
                    }
                    return row;
                })
                this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                    this.setState({ reload: false })
                });

                if (this.props.parent.props.parent) {
                    this.props.parent.props.parent.reload();
                }
            })
            .catch(error => FormToastComponent.errorTrans('CMS.Modules.overview.toggle.failed'))
    }

    table = (index) => (
        <div className={"custom-data-table modules"}>
            <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)}
                title={this.props.title_id} type={this.props.type}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: this.handleInlineStartEdit.bind(this),
                    afterSaveCell: this.handleInlineEdit.bind(this)
                })}
                defaultSorted={[{
                    dataField: 'title',
                    order: 'asc'
                }]}
            />
            <DeleteModalComponent parent={this} />
        </div>
    )

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-pages"}
                    defaultActiveKey={"#" + (this.state.languages[this.state.language] !== undefined ? this.state.languages[this.state.language] : this.state.languages[0])}>
                    <ListGroup>
                        {
                            this.state.languages.map((language, index) => {
                                return (
                                    <ListGroup.Item action href={"#" + language} key={index}
                                        onClick={() => this.setState({ language: index }, () => this.setModuleLanguage(index))}
                                    >
                                        {CountryHelper.getLanguages().find(({ value }) => value + "" === language + "").label}
                                    </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                    <Tab.Content>
                        {this.state.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language} key={index}>
                                <div className={"custom-data-table modules"}>
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)}
                                        title={this.props.title_id} type={this.props.type}
                                        cellEdit={cellEditFactory({
                                            mode: 'click',
                                            blurToSave: true,
                                            onStartEdit: this.handleInlineStartEdit.bind(this),
                                            afterSaveCell: this.handleInlineEdit.bind(this)
                                        })}
                                        defaultSorted={[{
                                            dataField: 'title',
                                            order: 'asc'
                                        }]}
                                    />
                                    <DeleteModalComponent parent={this} />
                                </div>
                            </Tab.Pane>

                        ))}
                    </Tab.Content>
                </Tab.Container>

                <UserToItemPermissionsComponent name="module_permission"
                    parent={this} title="CMS.module.permissions.title" type="module"
                    onRef={ref => this._userPermissions = ref}
                />
            </div>
        );
    }

    handleInlineStartEdit = () => TableHelper.focusEditCell();

    handleInlineEdit = (oldValue, newValue, row, column) => {
        let content = row.module.contents[this.state.language];
        if (content) oldValue = content.title;

        if (oldValue === newValue) return;

        ModuleRepository.update(row.id, this.state.languages[this.state.language], JSON.parse(row.module.settings), { name: newValue, desctiption: content.description })
            .then(() => {
                FormToastComponent.successTrans("Modules", "Default.saved");
                this.props.parent.props.history.push('/modules/overview');

                if (this.props.parent.props.parent) {
                    this.props.parent.props.parent.reload();
                }
            })
            .catch(() => FormToastComponent.errorTrans("Default.error"))
    }


    setModuleLanguage = (language) => localStorage.setItem('application.module_language', language);
    moduleLanguage = () => {
        let language = localStorage.getItem('application.module_language');
        if (!language) language = 0;
        return language;
    }
}

export default ModuleOverviewTableComponent;