import * as Yup from "yup"

export default class FaqContent {
    id;
    question;
    language;
    answer;

    static schema_add = Yup.object({
        question: Yup.string().required('CMS.Faq.Form.name').max(255, 'CMS.Faq.Form.name|255|max'),
        category: Yup.mixed().required('CMS.Faq.Form.module|select'),
        answer: Yup.object({ text: Yup.string().required('CMS.Faq.Form.content') })
    });

    static fromJson(json) {
        let faqContent = new FaqContent();
        faqContent.id = json['id'];
        faqContent.question = json['question'];
        faqContent.language = json['language'];
        faqContent.answer = json['answer'] != null ? json['answer'] : '{}';
        return faqContent;
    }
}
