import React from "react";
import MediaHelper from "../../../helpers/MediaHelper";
import MediaImageUploadComponent from "../../media/image/MediaImageUploadComponent";
import TestimonialFormError from "../errors/TestimonialFormError";
import FormattedMessageString from "../../basics/FormattedMessageString";
import StandardTextFormEditorComponent from "../../basics/form/StandardTextFormEditorComponent";
import Module from "../../../models/module/Module";

class TestimonialTextFormComponent {
    static fields(settings, language) {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Testimonial.Content.Form.title",
                                type: "text",
                                name: 'title',
                                customFeedback: TestimonialFormError,
                                formErrorName: "title"
                            }
                        ]
                    },
                    settings.use_reference ?
                        {
                            type: "col",
                            md: 12,
                            fields: [
                                {
                                    title: <FormattedMessageString id="CMS.Testimonial.Content.Form.module" values={{ reference: settings.reference.title }} />,
                                    type: "select",
                                    name: "module_references",
                                    multi: true,
                                    options: this.renderReferenceOptions(settings.reference, language),
                                    customFeedback: TestimonialFormError,
                                    formErrorName: "module_references"
                                }
                            ]
                        }
                        : {},
                    {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: StandardTextFormEditorComponent,
                        customProps: { placeholder: 'CMS.Testimonials.Form.content.placeholder' }
                    },
                    settings.show_image ? {
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: MediaImageUploadComponent,
                        customProps: { type: 'testimonial_image', settings: settings, field: 'image' }
                    } : {}
                ]
            }
        ]
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;
            let type = module ? module.module.type : null;
            let id = module ? module.id : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'testimonial_image', type, id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static renderReferenceOptions = (reference, language) => {
        let options = [];
        reference.items.forEach(item => options.push({ value: item.id, label: this.renderOptionLabel(reference, item, language) }))
        return options;
    }

    static renderOptionLabel = (reference, item, language) => {
        switch (reference.type) {
            case 'category':
                return item.depthLabel.join(' > ');
            default:
                let content = item[reference.type].contents.filter(content => content.language === language)[0];
                if (!content) content = item[reference.type].contents[0];
                return content.title ? content.title : (content.name ? content.name : '');
        }
    }
}


export default TestimonialTextFormComponent;
