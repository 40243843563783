export default class GroupContent {
    id;
    language;
    title;

    static fromJson(json) {
        let content = new GroupContent();
        content.language = json['language'];
        content.title = json['title'];

        return content;
    }
}
