import React from "react";
import { FormRepository } from "../../repository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import Form from "../../models/form/Form";
import FormFormError from "./errors/FormFormError";
import StandardTextFormEditorComponent from "../basics/form/StandardTextFormEditorComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class FormMailFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: props.model !== undefined ? props.model : new Form(),
            settings: [],
            loading: true
        };

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            FormRepository.getMailSettings(this.props.id, this.props.match.params.language)
        ];

        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settings = result[0].settings;
                let form = result[1];

                if (!form) {
                    FormToastComponent.errorTrans("CMS.Form.not.found");
                    this.props.history.push('/forms/overview');
                }

                let model = new Form();
                if (form !== undefined && form) {
                    model = Form.fromJson(form.form, this.props.match.params.language);
                }

                let emailFields = [];
                if (settings) {
                    if (settings.emailFields && settings.emailFields.length > 0) {
                        settings.emailFields.forEach(field => emailFields.push({ value: field.id, label: field.label }))
                    }
                }

                this.setState({ model: model, loading: false, settings: { emailFields: emailFields } })
            });
    }

    render() {
        if (this.state.loading) return <LoaderComponent />
        
        return (
            <StandardFormComponent
                {...this.props}
                ref={this.table_ref}
                parent={this}
                className={"Form"}
                validationSchema={Form.schema_mail_settings(this.state.model)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    values.action = 'mail-settings';
                    FormRepository.updateForm(values, this.props.match.params.language)
                        .then(() => {
                            FormToastComponent.successTrans("Forms", "Default.saved");
                            this.props.history.push('/forms/overview');
                        })
                        .catch(error => FormHelper.handleSubmitError(this, error))
                }}
                formErrors={this.state.form_errors}
                layout="tab"
                activeTab="mail-admin"
                fields={[
                    {
                        type: "tab",
                        name: "mail-admin",
                        label: "CMS.Form.Form.Tab.admin",
                        children: [
                            {
                                type: "row",
                                children: [
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.send",
                                                type: "switch",
                                                name: 'mail_admin_send',
                                                onChange: (event, value) => {
                                                    let model = this.state.model;
                                                    model[event.target.name] = value;

                                                    this.setState({ model: model });
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.sender-name",
                                                type: "text",
                                                name: 'mail_admin_sender_name',
                                                disabled: !this.state.model.mail_admin_send,
                                                customFeedback: FormFormError,
                                                formErrorName: "mail_admin_sender_name"
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.sender-email",
                                                type: "email",
                                                name: 'mail_admin_sender_email',
                                                disabled: !this.state.model.mail_admin_send,
                                                customFeedback: FormFormError,
                                                formErrorName: "mail_admin_sender_email"
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.recipient-name",
                                                type: "text",
                                                name: 'mail_admin_recipient_name',
                                                disabled: !this.state.model.mail_admin_send,
                                                customFeedback: FormFormError,
                                                formErrorName: "mail_admin_recipient_name"
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.recipient-email",
                                                type: "tags",
                                                name: 'mail_admin_recipient_email',
                                                disabled: !this.state.model.mail_admin_send,
                                                customFeedback: FormFormError,
                                                formErrorName: "mail_admin_recipient_email"
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [
                                            {
                                                title: "CMS.Form.Form.Email.subject",
                                                type: "text",
                                                name: 'mail_admin_subject',
                                                disabled: !this.state.model.mail_admin_send,
                                                customFeedback: FormFormError,
                                                formErrorName: "mail_admin_subject"
                                            }
                                        ]
                                    },
                                    {
                                        type: "col",
                                        md: 12,
                                        fields: [],
                                        custom: StandardTextFormEditorComponent,
                                        customProps: {
                                            label: 'CMS.Form.Form.Email.content',
                                            disabled: !this.state.model.mail_admin_send,
                                            placeholder: 'CMS.Form.Form.Email.content.placeholder',
                                            valueField: 'mail_admin_content'
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: "tab",
                        name: "mail-visitor",
                        label: "CMS.Form.Form.Tab.visitor",
                        children: [{
                            type: 'row',
                            children: [
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Form.Form.Email.send",
                                            type: "switch",
                                            name: 'mail_visitor_send',
                                            onChange: (event, value) => {
                                                let model = this.state.model;
                                                model[event.target.name] = value;

                                                this.setState({ model: model });
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Form.Form.Email.sender-name",
                                            type: "text",
                                            name: 'mail_visitor_sender_name',
                                            disabled: !this.state.model.mail_visitor_send,
                                            customFeedback: FormFormError,
                                            formErrorName: "mail_visitor_sender_name"
                                        }
                                    ]
                                },

                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Form.Form.Email.sender-email",
                                            type: "email",
                                            name: 'mail_visitor_sender_email',
                                            disabled: !this.state.model.mail_visitor_send,
                                            customFeedback: FormFormError,
                                            formErrorName: "mail_visitor_sender_email"
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Form.Form.Email.recipient-email",
                                            type: "select",
                                            name: "mail_visitor_recipient_field",
                                            options: this.state.settings.emailFields ? this.state.settings.emailFields : [],
                                            disabled: !this.state.model.mail_visitor_send,
                                            customFeedback: FormFormError,
                                            formErrorName: "mail_visitor_recipient_field"
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Form.Form.Email.subject",
                                            type: "text",
                                            name: 'mail_visitor_subject',
                                            disabled: !this.state.model.mail_visitor_send,
                                            customFeedback: FormFormError,
                                            formErrorName: "mail_visitor_subject"
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [],
                                    custom: StandardTextFormEditorComponent,
                                    customProps: {
                                        label: 'CMS.Form.Form.Email.content',
                                        placeholder: 'CMS.Form.Form.Email.content.placeholder',
                                        disabled: !this.state.model.mail_visitor_send,
                                        valueField: 'mail_visitor_content'
                                    }
                                }
                            ]
                        }]
                    }
                ]
                }
            />
        );
    }
}

export default withRouter(FormMailFormComponent);