import React from "react";
import PropTypes from "prop-types";
import FormRequiredError from "../../errors/FormRequiredError";
import { FormMaxError, FormWebsiteError } from "../../errors";

class UserFormError extends React.Component {

    render() {
        if (!this.props.error) return null;

        if (this.props.error.indexOf('invalid') > -1) {
            return <FormWebsiteError field_name={"CMS.Profile." + this.props.error.slice(0, -8)} />
        } else if (this.props.error.indexOf('max') > -1) {
            let error = this.props.error.split('|');
            return <FormMaxError field_name={"CMS.Profile." + error[0]} max={error[1]} />
        } else {
            return <FormRequiredError field_name={"CMS.Profile." + this.props.error} />
        }
    }

}

UserFormError.propTypes = {
    error: PropTypes.string.isRequired,
};

export default UserFormError;
