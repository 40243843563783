import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";
import PropTypes from "prop-types";

class TwofaOneTimePasswordFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trust: false
        }
    }


    render() {
        return (
            <>
                <Formik
                    enableReinitialize
                    validationSchema={Yup.object({
                        code: Yup.number().required(),
                        trust: Yup.bool
                    })}
                    initialValues={
                        {
                            code: null,
                            trust: false
                        }
                    }
                    onSubmit={this.props.submit_action}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                {
                                    this.props.login ?
                                        <FormattedMessage id={"Auth.2fa.login.passcode"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        :
                                        <FormattedMessage id={"Auth.2fa.step2.passcode"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                }
                                <FormattedMessage id={"Auth.2fa.step2.passcode.placeholder"}>
                                    {
                                        (value) => <Form.Control type="text" name="code"
                                            value={values.code || ''}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            onBlur={handleBlur}
                                            placeholder={value} />
                                    }
                                </FormattedMessage>
                            </Form.Group>
                            <Form.Group className={"buttons"}>
                                <Button type="submit"><FormattedMessageString id={"Auth.2fa.activate"} /></Button>
                                {
                                    this.props.login ?
                                        <Button variant="secondary"
                                            onClick={this.props.back_action}><FormattedMessageString
                                                id={"Auth.2fa.onetimepassword.use.backupcodes"} /></Button>
                                        :
                                        <Button variant="secondary"
                                            onClick={this.props.back_action}><FormattedMessageString
                                                id={"Auth.2fa.step.back"} /></Button>
                                }

                            </Form.Group>
                        </Form>
                    )}

                </Formik>
            </>
        );
    }
}

TwofaOneTimePasswordFormComponent.propTypes = {
    submit_action: PropTypes.func.isRequired,
    back_action: PropTypes.func.isRequired,
    login: PropTypes.bool.isRequired
};
export default TwofaOneTimePasswordFormComponent;