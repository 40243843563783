import React from "react";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserPermissionRepository, UserRepository } from "../../repository";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import FormattedMessageString from "../basics/FormattedMessageString";

class UserToItemPermissionsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            users: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.updateUser();
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    updateUser = () => UserRepository.getUsersByRole('EDITOR')
        .then(response => this.setState({
            users: response.data.map(editor => {
                return {
                    key: editor.id,
                    name: editor.user.contact_info.firstname + " " + editor.user.contact_info.lastname,
                    permissions: editor.user.modelPermissions
                };
            })
        }));

    openModal = (name, id) => {
        this.props.parent.setState({
            [name]: true,
            [name + "_selected"]: id
        })
    }

    closeModal = () => this.props.parent.setState({ [this.props.name]: false }, () => this.setState({ selected: null }));
    handleChange = (user) => this.setState({ selected: user != null ? user : null })

    add = () => {
        if (this.state.selected != null) {
            this.change(this.state.selected.value, true);
        }
    }

    delete = (user) => this.change(user.key, false);

    change = (key, value) => UserPermissionRepository.change(this.props.type, this.props.parent.state[this.props.name + "_selected"], value, this.props.name, key)
        .then(response => {
            this.updateUser();
            this.setState({ selected: null })
        });

    checkSelected = (user) => user.permissions.find(permission => {
        return this.props.type === permission.model_string_type && this.props.name === permission.type && this.props.parent.state[this.props.name + "_selected"] === permission.model_id;
    });

    render() {
        return (
            <ModalFormFragment title_id={this.props.title} show={this.props.parent.state[this.props.name]}
                onHide={() => this.closeModal()}
            >
                <Form.Group className={"user-to-permissions-select"}>
                    <FormattedMessage id={"CMS.page.permissions.select.placeholder"}>
                        {
                            (value) => <Select
                                value={this.state.selected}
                                onChange={(user) => this.handleChange(user)}
                                options={this.state.users.filter(user => !this.checkSelected(user)).map(user => {
                                    return { value: user.key, label: user.name }
                                })}
                                noOptionsMessage={() => <FormattedMessageString id="CMS.module.permissions.no_result" />}
                                placeholder={value}
                                isClearable={true}
                                className="user-select"
                                classNamePrefix="user-select"
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                        }
                    </FormattedMessage>
                    <Link to="#"
                        onClick={this.state.selected != null ? event => this.add() : () => false}
                        className={this.state.selected != null ? "add" : "add disabled"}>
                        <FontAwesomeIcon icon={['far', 'user-plus']} />
                    </Link>
                </Form.Group>

                <div className={"user-to-permissions-selected"}>
                    {
                        this.state.users.filter(user => this.checkSelected(user)).map(user => (
                            <div className={"user-to-permissions-selected-item"}>
                                <div class={"user-to-permissions-selected-item-icon"}><FontAwesomeIcon icon={['far', 'user']} /></div>
                                <p>{user.name}</p>
                                <Link to="#" onClick={event => this.delete(user)}>
                                    <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </ModalFormFragment>
        );
    }
}

export default UserToItemPermissionsComponent;