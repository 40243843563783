import MediaHelper from "../../helpers/MediaHelper";

class WebsiteTextFormComponent {

    static async fields_image_before_submit(element, id = null) {
        return new Promise(async (resolve, reject) => {
            let item = element.state.model;

            id = id ? id : element.props.id
            if (item.logo !== null && item.logo !== undefined && (typeof item.logo !== 'string')) {
                item.logo = await MediaHelper.uploadImage(item, 'logo', 'website_logo', 'website', id);
            }

            if (item.logo_svg !== null && item.logo_svg !== undefined && (typeof item.logo_svg !== 'string')) {
                let media = await MediaHelper.uploadImage(item, 'logo_svg', 'website_logo_svg', 'website', id);
                item.logo_svg = media.path;
                item.logo = media.formatted_images?.png;
            }

            element.setState({
                model: item
            }, () => {
                resolve(true);
            })
        })
    }
}

export default WebsiteTextFormComponent;