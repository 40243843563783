import React from "react";
import PropTypes from "prop-types";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";

class MutateModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.closeModal = this.props.customCloseModalFunction !== undefined ? this.props.customCloseModalFunction :
            this.closeModal.bind(this);

        this.parent = this.props.parent;
        this.showModalIdentifier = MutateModalComponent.getShowModalIdentifier(this.parent.props.component_name);

    }

    static getShowModalIdentifier(name) {
        return "show" + name.toUpperCase() + "Modal";
    }

    static openModal(event, parent) {
        event.preventDefault();
        MutateModalComponent.toggleModal(parent, true)
    }

    static closeModal(parent) {
       MutateModalComponent.toggleModal(parent, false)
    }

    static toggleModal(parent, bool) {
        parent.setState({
            [MutateModalComponent.getShowModalIdentifier(parent.props.component_name)]: bool
        })
    }

    closeModal() {
        this.parent.setState({
            [this.showModalIdentifier]: false
        });
    }

    render() {
        return (
            <ModalFormFragment title_id={this.props.title} show={this.parent.state[this.showModalIdentifier]}
                               onHide={this.closeModal}
            >
                {this.props.children}
            </ModalFormFragment>
        );
    }
}

MutateModalComponent.propTypes = {
    title: PropTypes.string.isRequired,
    parent: PropTypes.object.isRequired,

    customCloseModalFunction: PropTypes.func,
};
export default MutateModalComponent;
