import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class FormattedMessageInfo extends React.Component {

    render() {
        return (
            <FormattedMessage id={this.props.id}>
                {(value) => <span>{this.props.message} - {value}</span>}
            </FormattedMessage>
        )
    }
}

FormattedMessageInfo.propTypes = {
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};
export default FormattedMessageInfo;