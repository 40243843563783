import * as Yup from 'yup';
import ModuleContent from './ModuleContent';
import { Application, JsonHelper } from '../../helpers';
import ModuleTypeEnum from '../../enums/ModuleTypeEnum';

export default class Module {
    id;
    type;
    settings = {};
    website;

    static schema_add = (model) => {
        let settings = {
            module: Yup.string().when("use_categories", (show, schema) => {
                return show ? schema.required('module') : schema;
            }),

            reference_module: Yup.string().when("use_reference", (show, schema) => {
                return show ? schema.required('reference_module') : schema;
            }),

            reference_modules: Yup.string().when("use_references", (show, schema) => {
                return show ? schema.required('modules') : schema;
            }),

            image_width: Yup.string().when("show_image", (show, schema) => {
                return show ? schema.required('image_width').matches(/^[0-9]+$/, "image_width.number") : schema;
            }),
            image_height: Yup.string().when("show_image", (show, schema) => {
                return show ? schema.required('image_height').matches(/^[0-9]+$/, "image_height.number") : schema;
            }),

            content_with_blocks_image_width: Yup.string().when("content_with_blocks", (show, schema) => {
                return show ? schema.required('content_with_blocks_image_width').matches(/^[0-9]+$/, "content_with_blocks_image_width.number") : schema;
            }),
            content_with_blocks_image_height: Yup.string().when("content_with_blocks", (show, schema) => {
                return show ? schema.required('content_with_blocks_image_height').matches(/^[0-9]+$/, "content_with_blocks_image_height.number") : schema;
            }),
            cover_image_width: Yup.string().when("show_cover_image", (show, schema) => {
                return show ? schema.required('cover_image_width').matches(/^[0-9]+$/, "cover_image_width.number") : schema;
            }),
            cover_image_height: Yup.string().when("show_cover_image", (show, schema) => {
                return show ? schema.required('cover_image_height').matches(/^[0-9]+$/, "cover_image_height.number") : schema;
            }),
            home_img_width: Yup.string().when("need_home_img", (show, schema) => {
                return show ? schema.required('home_img_width').matches(/^[0-9]+$/, "home_img_width.number") : schema;
            }),
            home_img_height: Yup.string().when("need_home_img", (show, schema) => {
                return show ? schema.required('home_img_height').matches(/^[0-9]+$/, "home_img_height.number") : schema;
            }),
            subcategories_image_width: Yup.string().when("subcategories_content_with_blocks", (show, schema) => {
                return show ? schema.required('subcategories_image_width').matches(/^[0-9]+$/, "subcategories_image_width.number") : schema;
            }),
            subcategories_image_height: Yup.string().when("subcategories_content_with_blocks", (show, schema) => {
                return show ? schema.required('subcategories_image_height').matches(/^[0-9]+$/, "subcategories_image_height.number") : schema;
            }),

            subcategories_cover_image_width: Yup.string().when("subcategories_show_cover_image", (show, schema) => {
                return show ? schema.required('subcategories_cover_image_width').matches(/^[0-9]+$/, { message: "subcategories_cover_image_width.number", excludeEmptyString: true }) : schema;
            }),
            subcategories_cover_image_height: Yup.string().when("subcategories_show_cover_image", (show, schema) => {
                return show ? schema.required('subcategories_cover_image_height').matches(/^[0-9]+$/, { message: "subcategories_cover_image_height.number", excludeEmptyString: true }) : schema;
            }),
            subcategories_home_img_width: Yup.string().when("subcategories_need_home_img", (show, schema) => {
                return show ? schema.required('subcategories_home_img_width').matches(/^[0-9]+$/, { message: "subcategories_home_img_width.number", excludeEmptyString: true }) : schema;
            }),
            subcategories_home_img_height: Yup.string().when("subcategories_need_home_img", (show, schema) => {
                return show ? schema.required('subcategories_home_img_height').matches(/^[0-9]+$/, { message: "subcategories_home_img_height.number", excludeEmptyString: true }) : schema;
            }),

            media_password: Yup.string().when("hide_media", (hide, schema) => {
                return hide ? schema.required('media_password').min(12, 'media_password|12|min') : schema;
            }),

            use_membership_duration: Yup.bool().when(['use_membership', 'use_membership_start_date'], {
                is: (use_membership, use_membership_start_date) => use_membership && !use_membership_start_date,
                then: Yup.bool().oneOf([true], 'use_membership_duration|use_membership_start_date|required.conditional')
            }),
            use_membership_start_date: Yup.bool().when(['use_membership', 'use_membership_duration'], {
                is: (use_membership, use_membership_duration) => use_membership && !use_membership_duration,
                then: Yup.bool().oneOf([true], 'use_membership_duration|use_membership_start_date|required.conditional')
            }),

            membership_duration: Yup.string().when("use_membership_duration", (hide, schema) => {
                return hide ? schema.required('membership_duration') : schema;
            }),
            membership_start_date: Yup.string().when("use_membership_start_date", (hide, schema) => {
                return hide ? schema.required('membership_start_date') : schema;
            }),
            membership_price: Yup.string().when("use_membership", (hide, schema) => {
                return hide ? schema.required('membership_price').matches(/^\d*[.,,]{0,1}\d*$/, 'membership_price|number') : schema;
            }),

            additional_gdpr_consent_1_text: Yup.string().when("show_additional_gdpr_consent_1", (show, schema) => {
                return show ? schema.required('additional_gdpr_consent_1_text') : schema;
            }),
            additional_gdpr_consent_2_text: Yup.string().when("show_additional_gdpr_consent_2", (show, schema) => {
                return show ? schema.required('additional_gdpr_consent_2_text') : schema;
            }),

            additional_dynamic_field_1_type: Yup.string().when("show_additional_dynamic_field_1", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_dynamic_field_1_title: Yup.string().when("show_additional_dynamic_field_1", (show, schema) => {
                return show ? schema.required('title') : schema;
            }),
            /*additional_dynamic_field_1_text: Yup.string().when("show_additional_dynamic_field_1", (show, schema) => {
                return show ? schema.required('text') : schema;
            }),*/

            additional_dynamic_field_2_type: Yup.string().when("show_additional_dynamic_field_2", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_dynamic_field_2_title: Yup.string().when("show_additional_dynamic_field_2", (show, schema) => {
                return show ? schema.required('title') : schema;
            }),
            /*additional_dynamic_field_2_text: Yup.string().when("show_additional_dynamic_field_2", (show, schema) => {
                return show ? schema.required('text') : schema;
            }),*/

            additional_dynamic_field_3_type: Yup.string().when("show_additional_dynamic_field_3", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_dynamic_field_3_title: Yup.string().when("show_additional_dynamic_field_3", (show, schema) => {
                return show ? schema.required('title') : schema;
            }),
            /*additional_dynamic_field_3_text: Yup.string().when("show_additional_dynamic_field_3", (show, schema) => {
                return show ? schema.required('text') : schema;
            }),*/

            additional_field_1_type: Yup.string().when("show_additional_field_1", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_field_1_label: Yup.string().when("show_additional_field_1", (show, schema) => {
                return show ? schema.required('label') : schema;
            }),

            additional_field_2_type: Yup.string().when("show_additional_field_2", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_field_2_label: Yup.string().when("show_additional_field_2", (show, schema) => {
                return show ? schema.required('label') : schema;
            }),

            additional_field_3_type: Yup.string().when("show_additional_field_3", (show, schema) => {
                return show ? schema.required('type') : schema;
            }),
            additional_field_3_label: Yup.string().when("show_additional_field_3", (show, schema) => {
                return show ? schema.required('label') : schema;
            }),

            show_minimum_age: Yup.bool().when(['use_age_requirements', 'show_maximum_age'], {
                is: (use_age_requirements, show_maximum_age) => use_age_requirements && !show_maximum_age,
                then: Yup.bool().oneOf([true], 'show_minimum_age|show_maximum_age|required.conditional')
            }),
            show_maximum_age: Yup.bool().when(['use_age_requirements', 'show_minimum_age'], {
                is: (use_age_requirements, show_minimum_age) => use_age_requirements && !show_minimum_age,
                then: Yup.bool().oneOf([true], 'show_minimum_age|show_maximum_age|required.conditional')
            }),
            switch_date: Yup.string().when("use_age_requirements", (hide, schema) => {
                return hide ? schema.required('switch_date') : schema;
            })
            
            /*switch_date: Yup.string().test("is-day-month", "switch_date", val => {
                if (!val) return true;

                let schemaUrl = Yup.object().shape({
                    switch_date: Yup.string().matches(/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[1,2])/, 'switch_date|invalid')
                });

                return schemaUrl.validate({ switch_date: val });
            }).nullable()*/
        };

        if (model.type === ModuleTypeEnum.MAP) {
            settings.pages = Yup.array().required('pages|select');
        }

        return Yup.object({
            name: Yup.string().required('CMS.Modules.Form.name').max(30, 'CMS.Modules.Form.name|30|max'),
            description: Yup.string().max(500, 'CMS.Modules.Form.description|500|max').nullable(),
            type: Yup.mixed().required('CMS.Modules.Form.type|select'),
            settings: Yup.object().shape(settings, [['use_membership_duration', 'use_membership_start_date'], ['use_membership_duration', 'use_membership_start_date'], ['show_minimum_age', 'show_maximum_age'], ['show_minimum_age', 'show_maximum_age']])
        });
    }

    static fromJson = (json) => {
        let module = new Module();
        module.id = json.id;
        module.type = json.type;
        module.website = json.website;
        module.settings = json.settings === "[]" ? "{}" : json.settings;
        module.content = json.contents.map(content => ModuleContent.fromJson(content));
        return module;
    }

    static getSettings(module = null) {
        if (!module) module = this.get().module;
        if (!module) return [];

        if (module.settings === "[]") return [];
        return JsonHelper.tryParse(module.settings);
    }

    static getSetting(module, setting) {
        let settings = this.getSettings(module);

        if (!settings[setting]) return null;
        return settings[setting];
    }

    static getContent(language, module = null) {
        if (!module) {
            module = this.get();
        }

        if (!module) return {};

        return module.module.contents.filter(content => content.language === language)[0];
    }

    static get = () => {
        let module = localStorage.getItem('module');
        if (!module) return null;

        return JSON.parse(module);
    }

    static getId = () => {
        let module = this.get();
        if (!module) return null;

        return module.id;
    }

    static set = (module) => localStorage.setItem('module', JSON.stringify(module));

    static remove = () => localStorage.removeItem('module');

    static getIcon = (module) => {
        let settings = JSON.parse(module.settings);
        return settings.icon ? settings.icon : 'cubes';
    }

    static getRoute = (module) => {
        let settings = JSON.parse(module.settings);
        return settings.route ? settings.route : '';
    }

    static change = (newModule) => {
        this.remove();

        Application.resetTablePageNumber();

        if (!newModule) return;

        this.set(newModule);

        let e = null;
        if (typeof window.CustomEvent === 'function') {
            e = new CustomEvent('moduleChanged');
        } else {
            e = document.createEvent('CustomEvent');
            e.initCustomEvent('moduleChanged', true, false);
        }

        window.dispatchEvent(e);
    }

    static filterReferences = (modules) => modules.filter(module => [ModuleTypeEnum.CATEGORY, ModuleTypeEnum.PROJECT, ModuleTypeEnum.WEBLINK, ModuleTypeEnum.TEAM].indexOf(module.module.type) > -1)
        .filter(module => {
            if (module.module.type !== ModuleTypeEnum.CATEGORY) return module;
            let settings = JsonHelper.tryParse(module.module.settings);

            let hasContent = settings.show_content || settings.content_with_blocks;
            if (settings.has_subcategories) {
                hasContent = hasContent || settings.subcategories_show_content || settings.subcategories_content_with_blocks
            }
            return hasContent ? module : null;
        });

    static renderSubMenu = (module) => {
        if ([ModuleTypeEnum.ATTENDEE, ModuleTypeEnum.EVENT].indexOf(module.type) < 0) return false;

        let settings = JsonHelper.tryParse(module.settings);

        switch (module.type) {
            case ModuleTypeEnum.EVENT:
                return settings?.use_attendees;
            case ModuleTypeEnum.ATTENDEE:
                let show = false;
                [1, 2, 3].forEach(i => {
                    if (settings['show_additional_dynamic_field_' + i] && settings['additional_dynamic_field_' + i + '_type'] === 'dropdown') {
                        show = true;
                    }
                });

                return show;
            default:
                return true;
        }
    };
}
