import * as React from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/plugins/fullscreen.min.js"
import "froala-editor/js/plugins/code_view.min.js"
import "froala-editor/js/plugins/code_beautifier.min.js"
import "froala-editor/js/plugins/link.min.js"
import "froala-editor/js/plugins/char_counter.min.js"
import "froala-editor/js/third_party/font_awesome.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/css/plugins/fullscreen.min.css";
import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/css/plugins/char_counter.min.css";
import "froala-editor/css/third_party/font_awesome.min.css"

// Import languages
import 'froala-editor/js/languages/en_gb';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/nl';

import Froala from "react-froala-wysiwyg";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import StandardFormErrorMessageComponent from "./StandardFormErrorMessageComponent";
import MediaHelper from "../../../helpers/MediaHelper";
import MediaRepository from "../../../repository/MediaRepository";
import Application from "../../../helpers/Application";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Website from "../../../models/website/Website";
import JsonHelper from "../../../helpers/JsonHelper";
import { LoaderComponent } from "../layout";
import MediaContentUpload from "../../../models/media/MediaContentUpload";
import { PageHelper } from "../../../helpers";

class FroalaEditorComponent extends React.Component {

    constructor(props) {
        super(props);

        this._editor = React.createRef();

        this.state = {
            loading: true,
            model: '',
            valueField: props.parent.props.valueField ? props.parent.props.valueField : 'value',
            language: localStorage.getItem('application.table_language'),
            editor: {
                attribution: false,
                pluginsEnabled: [
                    "paragraphFormat",
                    "paragraphStyle",
                    "align",
                    "lists",
                    "table",
                    "emoticons",
                    "specialCharacters",
                    "fontAwesome",
                    'codeView',
                    'fullscreen',
                    "inlineClass",
                    "inlineStyle",
                    "link",
                    "charCounter"
                ],
                toolbarButtons: {
                    moreText: {
                        buttons: [
                            "bold",
                            "italic",
                            "underline",
                            "paragraphFormat",
                            "strikeThrough",
                            "subscript",
                            "superscript",
                            "clearFormatting"
                        ],
                        align: 'left',
                        buttonsVisible: 4
                    },
                    moreParagraph: {
                        buttons: [
                            "alignLeft",
                            "alignCenter",
                            "alignRight",
                            "alignJustify",
                            "formatOL",
                            "formatUL"
                        ],
                        align: 'left',
                        buttonsVisible: 6
                    },
                    moreRich: {
                        buttons: [
                            "insertLink",
                            "insertTable"
                        ],
                        align: 'left',
                        buttonsVisible: 2
                    },
                    moreMisc: {
                        buttons: [
                            "undo",
                            "redo",
                            "insertHR",
                            "emoticons",
                            "specialCharacters",
                            "fontAwesome",
                            "fullscreen",
                            "selectAll",
                            "html"
                        ],
                        align: "right",
                        buttonsVisible: 2
                    }
                },
                paragraphFormat: {
                    N: 'Normal',
                    H2: 'Heading 2',
                    H3: 'Heading 3',
                    H4: 'Heading 4',
                    PRE: 'Code'
                },
                linkEditButtons: ['linkEdit', 'linkRemove'],
                tableEditButtons: ['tableHeader', 'tableRemove', 'tableRows', 'tableColumns', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
                inlineMode: false,
                linkList: [],
                charCounterCount: true,
                paragraphStyles: {},
                inlineClasses: {},
                inlineStyles: {},
                linkStyles: {},
                tableStyles: {},
                imagePaste: false,
                pasteAllowedStyleProps: ['font-size', 'font-weight', 'text-align', 'text-decoration'],
                pasteAllowLocalImages: false,
                pasteDeniedAttrs: ['class', 'id', 'style', 'width', 'height', 'border', 'padding', 'margin', 'cellspacing', 'cellpadding'],
                pasteDeniedTags: ['button', 'colgroup', 'col'],
                tableResizerOffset: 10,
                tableResizingLimit: 50
            }
        }

        this.editor_id = "page-edit-upload-editor-" + Application.generateUniqueId();
        this.handleModelChange = this.handleModelChange.bind(this);
    }

    componentDidMount() {
        Promise.all([
            WebsiteRepository.getSettings(Website.get()),
            PageHelper.generateInternalLinkList({ language: Application.language() })
        ])
            .then(result => {
                let editor = this.state.editor;

                let websiteResult = result[0];

                if (websiteResult.website) {
                    let websiteSettings = {};
                    websiteResult.website.forEach(setting => {
                        if (setting.name.indexOf('wysiwyg_') < 0) return;
                        websiteSettings[setting.name] = setting;
                    });

                    Object.keys(websiteSettings).forEach(key => {
                        let setting = websiteSettings[key];
                        if (!setting) return;

                        let value = JsonHelper.tryParse(setting.value, null, null);
                        if (!value) return;

                        switch (key) {
                            case 'wysiwyg_paragraph_styles':
                                editor.toolbarButtons.moreText.buttons.push('paragraphStyle');
                                editor['paragraphStyles'] = value;
                                break;
                            case 'wysiwyg_inline_classes':
                                editor.toolbarButtons.moreParagraph.buttons.push('inlineClass');
                                editor['inlineClasses'] = value;
                                break;
                            case 'wysiwyg_inline_styles':
                                editor.toolbarButtons.moreParagraph.buttons.push('inlineStyle');
                                editor['inlineStyles'] = value;
                                break;
                            case 'wysiwyg_link_styles':
                                editor.linkEditButtons.push('linkStyle');
                                editor['linkStyles'] = value;
                                break;
                            case 'wysiwyg_table_styles':
                                editor.tableEditButtons.push('tableStyle');
                                editor['tableStyles'] = value;
                                break;
                            default:
                                break;
                        }
                    });
                }

                let { valueField, model } = this.state;
                if (this.props.parent.props.values !== undefined && this.props.parent.props.values !== null) {
                    if (this.props.parent.props.values[valueField] !== undefined) {
                        model = this.props.parent.props.values[valueField] ? this.props.parent.props.values[valueField].text : null;
                    } else if (this.props.parent.props.values.content !== undefined) {
                        model = this.props.parent.props.values.content
                        valueField = 'content';
                    }
                }

                // Link list
                if (result[1]) {
                    result[1].forEach(link => this.assembleLink(editor, link));
                }

                this.setState({ loading: false, model: model, valueField: valueField, editor: editor });
            });
    }

    assembleLink = (editor, link, depth = 0) => {
        if (!editor.linkList) editor.linkList = [];

        editor.linkList.push({
            displayText: (('-'.repeat(depth)) + ' ' + link.title).trim(),
            href: link.url,
        });

        if (link.children.length > 0) {
            link.children.forEach(child => this.assembleLink(editor, child, depth + 1));
        }

        return editor;
    }

    componentDidUpdate() {
        if (!this._editor.editor) return;

        setTimeout(() => {
            if (!this._editor || !this._editor.editor.edit) return;
            if (this.props.parent.props.disabled) {
                this._editor.editor.edit.off();
            } else {
                this._editor.editor.edit.on();
            }
        }, 100);
    }

    assembleLinkList = (list, page, categories, depth = 1) => {
        Object.keys(categories).forEach(id => {
            let category = categories[id];
            let content = category.category.contents[this.state.language];

            list.push({
                displayText: content ? ('-'.repeat(depth) + ' ' + content.title) : null,
                href: '/' + (content ? content.language : 'nl') + '/permalink/category/' + category.category.id + '/' + page.id
            });

            if (Object.keys(category.children).length > 0) {
                this.assembleLinkList(list, page, category.children, depth + 1);
            }
        });
    }

    assembleModuleLinks = (list, page, data) => {
        let type = data.module.module.type;
        data.items.forEach(item => {
            let content = item[type].contents[this.state.language];

            list.push({
                displayText: content ? ('-'.repeat(1) + ' ' + content.title) : null,
                href: '/' + (content ? content.language : 'nl') + '/permalink/' + type + '/' + item.id + '/' + page.id + '/' + module.id
            });
        })
    }

    handleModelChange = (value) => {
        if (this.props.parent.props.fake) {
            this.props.parent.setState({ value: value })
        } else {
            let valueField = this.state.valueField;
            let model = this.props.parent.props.parent.state.model;
            if (this.props.parent.props.parent.state.model instanceof MediaContentUpload) {
                model = new MediaContentUpload(model);
            }

            if (model !== undefined) {
                if (valueField !== 'content') {
                    if (model[valueField] === undefined || model[valueField] === null) {
                        model[valueField] = {};
                    }
                    model[valueField].text = value;
                } else if ('content' in model) {
                    if ((typeof model.content === 'object') && 'text' in model.content) {
                        model.content.text = value;
                    } else {
                        model.content = value;
                    }
                } else {
                    if (model[valueField] === undefined || model[valueField] === null) {
                        model[valueField] = {};
                    }
                    model[valueField].text = value;
                }
            } else {
                console.error('Cannot find model data')
            }

            this.props.parent.props.parent.setState({ model: model }, () => {
                this.props.parent.props.parent.form.setState({ values: model }, () => {
                    if (this.props.parent.props.parent.props.parent.update) {
                        this.props.parent.props.parent.props.parent.update(model)
                    }
                })
            });
        }
    };

    render() {
        if (this.state.loading) return <LoaderComponent />;

        let parent = this;
        let errors = this.props.parent.props.errors;
        let _preventFocus = !!this.state.model;

        return (
            <div id={this.editor_id} className={"custom-text-editor"}>
                <FormattedMessage id={this.props.parent.props.label ? this.props.parent.props.label : "CMS.Page.Content.Form.content"}>
                    {(value) => <Form.Label>{value}</Form.Label>}
                </FormattedMessage>

                <div className={'editor-wrapper ' + (errors[this.state.valueField] !== undefined && errors[this.state.valueField].text !== undefined ? "is-invalid" : null)}>
                    <FormattedMessage id={this.props.parent.props.placeholder ? this.props.parent.props.placeholder : "CMS.Page.Content.Form.content.placeholder"}>
                        {
                            (value) =>
                                <Froala
                                    ref={ref => this._editor = ref}
                                    model={this.state.model}
                                    // onModelChange={this.handleModelChange}
                                    tag="textarea"
                                    config={{
                                        language: localStorage.getItem('language'),
                                        key: process.env.REACT_APP_FROALA_EDITOR_API_KEY.trim(),
                                        placeholderText: value,
                                        height: Application.setting('wysiwyg_editor_height', 150),
                                        events: {
                                            init: function () {
                                                let editor = document.getElementById(parent.editor_id);
                                                let textarea = editor.getElementsByClassName('fr-element')
                                                if (textarea.length > 0) {
                                                    textarea = textarea[0];
                                                    textarea.addEventListener('drop', function (event) {
                                                        let row_id = parseInt(parent.props.parent.state.media_drag);
                                                        let row = parent.props.parent.state.media.find(media => media.id === row_id);
                                                        if (!row) return;

                                                        if (row.external) {
                                                            MediaRepository.getEmbed(row.url)
                                                                .then(response => {
                                                                    if (response.status === 200) {
                                                                        if (response.data.html) {
                                                                            textarea.innerHTML += '<div class="responsive-video">' + response.data.html + '</div>';
                                                                        } else if (response.data.url) {
                                                                            textarea.innerHTML += MediaHelper.renderLink(row);
                                                                        }

                                                                        parent.handleModelChange(textarea.innerHTML);
                                                                    } else {
                                                                        textarea.innerHTML += MediaHelper.renderLink(row);
                                                                    }
                                                                }).catch(() => textarea.innerHTML += MediaHelper.renderLink(row));
                                                        } else if (row.type === 'video/mp4') {
                                                            textarea.innerHTML += MediaHelper.renderInlineVideo(row);
                                                        } else if (row.type === 'audio/mpeg') {
                                                            textarea.innerHTML += MediaHelper.renderInlineAudio(row);
                                                        } else {
                                                            textarea.innerHTML += MediaHelper.renderLink(row);
                                                        }

                                                        parent.handleModelChange(textarea.innerHTML);
                                                    })
                                                    textarea.addEventListener('dragover', (event) => event.preventDefault());
                                                }
                                            },
                                            keyup: function () {
                                                _preventFocus = false;
                                            },
                                            contentChanged: function () {
                                                if (_preventFocus) {
                                                    _preventFocus = false;
                                                    return;
                                                }

                                                // Prevent cursor from jumping back to begin
                                                let snapshot = this.snapshot.get();
                                                parent.handleModelChange(this.html.get());
                                                this.snapshot.restore(snapshot);
                                            },
                                            'html.get': function (html) {
                                                return html.replace(/id="isPasted"/g, '');
                                            }
                                        },
                                        ...this.state.editor
                                    }}
                                />
                        }
                    </FormattedMessage>
                </div>

                {this.props.parent.props.errors !== undefined && this.props.parent.props.errors[this.state.valueField] !== undefined && this.props.parent.props.errors[this.state.valueField].text ?
                    <StandardFormErrorMessageComponent error={this.props.parent.props.errors[this.state.valueField].text} />
                    : null}

                <div className="editor-error-wrapper">
                    <StandardFormErrorMessageComponent error="CMS.Page.Content.Form.text" />
                </div>
            </div>
        );
    }
}

export default FroalaEditorComponent;