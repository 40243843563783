import API from "../services/api";

export default class ContentBlockRepository {
    static create = (data) => API.post('/content-block/create', data);
    static update = (data) => API.post('/content-block/content/update', data).then(response => response.data);
    static delete = (id) => API.delete('/content-block/delete?id=' + id);

    static all = () => API.get('/content-block/all').then(response => response.data.contentBlocks);
    static get = (id) => API.get('/content-block/details/' + id).then(respone => respone.data);    

    static toggle = (id) => API.post('/content-block/toggle', { id: id })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.active;
        }
    });
}
