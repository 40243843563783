import React from "react";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";

class MediaAddUploadingComponent extends React.Component {
    constructor(props) {
        super(props);

        this.props.parent_ref.props.parent_ref.setState({
            customHeaderText: "CMS.Media.Form.uploading.title"
        })
    }

    render() {
        return (
            <div className={"progress-wrapper"}>
                <p>{this.props.parent_ref.state.uploadProgress} %</p>
                <ProgressBar now={this.props.parent_ref.state.uploadProgress} />
            </div>
        );
    }
}

MediaAddUploadingComponent.propTypes = {
    parent_ref: PropTypes.object.isRequired
};
export default MediaAddUploadingComponent;