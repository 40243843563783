import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Testimonial from "../../models/testimonial/Testimonial";
import TestimonialTextFormComponent from "./forms/TestimonialTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import TestimonialRepository from "../../repository/TestimonialRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import Module from "../../models/module/Module";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";

class TestimonialEditFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testimonial: null,
            testimonialContent: null,
            isLoading: true,
            validation: { title: 'required', text: 'required' }
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];
        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                // Testimonial
                let testimonialResponse = result[1].testimonial;
                let testimonial = Testimonial.fromJson(testimonialResponse);
                let testimonialContent = testimonial.content.filter(content => content.language === this.props.match.params.language)[0];
                testimonialContent.value = JSON.parse(testimonialContent.value);
                testimonialContent.module_references = testimonial.module_references;

                this.setState({
                    testimonial: testimonial,
                }, () => {
                    this.setState({
                        settings: settings,
                        testimonialContent: testimonialContent,
                    }, () => {
                        this.setState({ isLoading: false }, () => {
                            let moduleContent = Module.getContent(localStorage.getItem('language'));
                            this.props.parent.updateTitle(<FormattedMessageString id="CMS.Module.edit.title" values={{ module: moduleContent.title, title: testimonialContent.title }} />);
                        })
                    })
                })
            })
            .catch(error => {
                FormToastComponent.error(error);
                this.goBackAfterCreate();
            });
    }

    goBackAfterCreate = () => this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
    getBeforeSubmit = (element) => TestimonialTextFormComponent.fields_image_before_submit(element);

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent
                {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.testimonialContent}
                validationSchema={Testimonial.schema(this.state.settings)}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current).then(result => {
                        TestimonialRepository.update(this.state.testimonial.id, values)
                            .then(response => {
                                if (response.success) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                    this.goBackAfterCreate();
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    })
                }}
                formErrors={this.state.form_errors}
                fields={TestimonialTextFormComponent.fields(this.state.settings, this.props.match.params.language)}
            />
        )
    }
}

TestimonialEditFormComponent.propTypes = {
    get_promise: PropTypes.any
}
export default withRouter(TestimonialEditFormComponent);
