import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormInvalidError from "../../errors/FormInvalidError";

class FormStreetError extends React.Component {
    render() {
        if (this.props.error === "address.form.street.max") {
            return <FormMaxError field_name={"CMS.Profile." + this.props.error.slice(0, -4)} max={100}/>;
        } else if (this.props.error === "address.form.street.invalid") {
            return <FormInvalidError field_name={"CMS.Profile." + this.props.error.slice(0, -8)}/>;
        } else {
            return <FormRequiredError field_name={"CMS.Profile." + this.props.error}/>;
        }
    }
}

FormStreetError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default FormStreetError;
