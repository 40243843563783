import React from "react";
import FormattedMessageString from "../../basics/FormattedMessageString";
import PropTypes from "prop-types";
import TwoFARepository from "../../../repository/TwoFARepository";
import TwofaOneTimePasswordFormComponent from "./TwofaOneTimePasswordFormComponent";
import FormToastComponent from "../../basics/FormToastComponent";
import User from "../../../models/user/User";
import API from "../../../services/api";

class Activate2faStep2Component extends React.Component {

    submit = (values, actions) => {
        TwoFARepository.complete(values.code)
            .then(response => {
                if (API.isSuccess(response)) {
                    User.addTwoFactor(response.data['auth_passed'], response.data['auth_time']);
                    this.props.next_step_action();
                } else if (response.data[0] !== null){
                    FormToastComponent.error(response.data[1]);
                }
            })
            .catch(reason => {
                FormToastComponent.errorTrans('Auth.2fa.activation.code.invalid')
            })
    };

    render() {
        return (
            <>
                <p className={"step-title"}><FormattedMessageString id={"Auth.2fa.step2.title"}/></p>
                <p><FormattedMessageString id={"Auth.2fa.step2.text1"}/></p>

                <TwofaOneTimePasswordFormComponent submit_action={this.submit} back_action={this.props.back_action} login={false}/>
            </>
        )
    }

}

Activate2faStep2Component.propTypes = {
    next_step_action: PropTypes.func.isRequired,
    back_action: PropTypes.func.isRequired
};
export default Activate2faStep2Component;