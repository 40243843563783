import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import WeblinkFormError from './errors/WeblinkFormError';
import Module from "../../models/module/Module";

class WeblinkCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_categories: props.values.settings.use_categories,
            use_reference: props.values.settings.use_reference,
            show_content: props.values.settings.show_content,
            show_image: props.values.settings.show_image,
            image_width: props.values.settings.image_width,
            image_height: props.values.settings.image_height,
            show_on_all_pages: props.values.settings.show_on_all_pages,
            weblink_random: props.values.settings.weblink_random,
            categories: [],
            modules: [],
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => this.setState({
                categories: result.filter(module => module.module.type === 'category'),
                modules: Module.filterReferences(result),
                use_categories: this.props.values.settings.use_categories
            }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Weblink.Form.settings"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-categories"
                                    label="use_categories"
                                    name={"settings.use_categories"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_categories: !this.state.use_categories }, () => {
                                                delete this.props.values.settings.module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {
                                this.state.use_categories ?
                                    <>
                                     <Form.Group className="subsettings">
                                     <Row>
                                        <Col md={12}>
                                            <InputGroup className="large mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select"
                                                    aria-describedby="module"
                                                    onChange={this.onChange}
                                                    name={"settings.module"}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.module}
                                                    value={this.props.values.settings.module}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.categories.map(module => {
                                                        let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                        return <option key={module.id} value={module.id}>{moduleContent.title}</option>
                                                    })}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.module && (
                                                        <WeblinkFormError error={this.props.errors.settings.module} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                        </Row>
                                        </Form.Group>
                                    </>
                                    : null
                            }

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.weblink.use_categories"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use reference */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-reference"
                                    label="use_reference"
                                    name={"settings.use_reference"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_reference: !this.state.use_reference }, () => {
                                                delete this.props.values.settings.reference_module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_reference}
                                />
                            </Col>

                            {
                                this.state.use_reference ?
                                    <>
                                     <Form.Group className="subsettings">
                                     <Row>
                                        <Col md={12}>
                                            <InputGroup className="large mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select"
                                                    aria-describedby="reference_module"
                                                    onChange={this.onChange}
                                                    name={"settings.reference_module"}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.reference_module}
                                                    value={this.props.values.settings.reference_module}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.modules.map(module => {
                                                        let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                        return <option key={module.id} value={module.id}>{moduleContent.title}</option>
                                                    })}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.reference_module && (
                                                        <WeblinkFormError error={this.props.errors.settings.reference_module} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                        </Row>
                                        </Form.Group>
                                    </>
                                    : null
                            }

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.weblink.use_reference"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show content */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-content"
                                    label="show_content"
                                    name={"settings.show_content"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_content: !this.state.show_content }))}
                                    className="mb-1"
                                    checked={this.state.show_content}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.weblink.show_content"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-image"
                                    label="show_image"
                                    name={"settings.show_image"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({
                                                show_image: !this.state.show_image
                                            }, () => {
                                                delete this.props.values.settings.image_width;
                                                delete this.props.values.settings.image_height;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_image ? "checked" : ""}
                                />
                            </Col>

                            {
                                this.state.show_image ?
                                    <>
                                     <Form.Group className="subsettings">
                                     <Row>
                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherWidth">image_width</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="image_width"
                                                    type={"text"}
                                                    name={"settings.image_width"}
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.image_width}
                                                    value={this.props.values.settings.image_width}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.image_width && (
                                                        <WeblinkFormError error={this.props.errors.settings.image_width} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherHeight">image_height</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="image_height"
                                                    type={"text"}
                                                    name={"settings.image_height"}
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.image_height}
                                                    value={this.props.values.settings.image_height}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.image_height && (
                                                        <WeblinkFormError error={this.props.errors.settings.image_height} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                        </Row>
                                        </Form.Group>
                                    </>
                                    : null
                            }


                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.weblink.show_image"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show all pages */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-on-all-pages"
                                    label="show_on_all_pages"
                                    name="settings.show_on_all_pages"
                                    onChange={event => this.onChange(event, () => this.setState({ show_on_all_pages: !this.state.show_on_all_pages }))}
                                    className="mb-1"
                                    checked={this.state.show_on_all_pages}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.weblink.show_on_all_pages">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                    
                    {/* Weblink random */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-weblink-random"
                                    label="weblink_random"
                                    name="settings.weblink_random"
                                    onChange={event => this.onChange(event, () => this.setState({ weblink_random: !this.state.weblink_random }))}
                                    className="mb-1"
                                    checked={this.state.weblink_random}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.weblink.weblink_random">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title={"CMS.Weblink.Form.setting.delete.title"} text={"CMS.Weblink.Form.setting.delete.text"}
                    buttons={{
                        confirm: {
                            text: 'Default.confirm',
                            icon: 'check'
                        }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default WeblinkCustomSettingsFormComponent;