import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";

class WebshopCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            use_guest_checkout: props.values.settings.use_guest_checkout,
            show_company_vat_fields: props.values.settings.show_company_vat_fields,
            show_in_store_pickup: props.values.settings.show_in_store_pickup,
            send_internal_confirmation_mail: props.values.settings.send_internal_confirmation_mail
        }

        this.originalValues = this.props.values;
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Webshop.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Show use guest checkout */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-use-guest-checkout" className="mb-1"
                                    label="use_guest_checkout" name="settings.use_guest_checkout"
                                    onChange={event => this.onChange(event, () => this.setState({ use_guest_checkout: !this.state.use_guest_checkout, show_company_vat_fields: false }, () => {
                                        this.props.values.settings.show_company_vat_fields = false;
                                    }))}
                                    checked={this.state.use_guest_checkout}
                                />
                            </Col>

                            {this.state.use_guest_checkout && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <Form.Check type="switch" id="custom-switch-show-company-vat-fields" className="mb-1"
                                                label="show_company_vat_fields" name="settings.show_company_vat_fields"
                                                onChange={event => this.onChange(event, () => this.setState({ use_guest_checkout: !this.state.show_company_vat_fields }))}
                                                checked={this.state.show_company_vat_fields}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <FormattedMessage id="CMS.Modules.Form.settings.webshop.show_company_vat_fields">
                                                {(values) => <p className="input-info">{values}</p>}
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.webshop.use_guest_checkout">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_in_store_pickup */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-in-store-pickup" className="mb-1"
                                    label="show_in_store_pickup" name="settings.show_in_store_pickup"
                                    onChange={event => this.onChange(event, () => this.setState({ show_in_store_pickup: !this.state.show_in_store_pickup }))}
                                    checked={this.state.show_in_store_pickup}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.webshop.show_in_store_pickup">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show send_internal_confirmation_mail */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-send-internal-confirmation-mail" className="mb-1"
                                    label="send_internal_confirmation_mail" name="settings.send_internal_confirmation_mail"
                                    onChange={event => this.onChange(event, () => this.setState({ send_internal_confirmation_mail: !this.state.send_internal_confirmation_mail }))}
                                    checked={this.state.send_internal_confirmation_mail}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.webshop.send_internal_confirmation_mail">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title="CMS.Webshop.Form.setting.delete.title" text="CMS.Webshop.Form.setting.delete.text"
                    buttons={{
                        confirm: { text: 'Default.confirm', icon: 'check' }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default WebshopCustomSettingsFormComponent;