import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";
import PropTypes from "prop-types";

class TwoFaBackupCodesFormComponent extends React.Component {

    render() {
        return (
            <>
                <Formik
                    enableReinitialize
                    validationSchema={Yup.object({
                        code: Yup.number().required(),
                    })}
                    initialValues={
                        {
                            code: null
                        }
                    }
                    onSubmit={this.props.submit_action}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                <FormattedMessage id={"Auth.2fa.backupcodes.text"}>
                                    {
                                        (value) => <Form.Label>{value}</Form.Label>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={"Auth.2fa.backupcodes.code.placeholder"}>
                                    {
                                        (value) => <Form.Control type={"text"} name={"code"}
                                            value={values.code || ''}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            onBlur={handleBlur}
                                            placeholder={value} />
                                    }
                                </FormattedMessage>
                            </Form.Group>
                            <Form.Group className={"buttons"}>
                                <Button type="submit"><FormattedMessageString id={"Auth.2fa.activate"} /></Button>
                                {
                                    <Button variant="secondary" onClick={this.props.cancel_action}><FormattedMessageString
                                        id={"Auth.2fa.backupcodes.use.2fa"} /></Button>
                                }

                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

TwoFaBackupCodesFormComponent.propTypes = {
    submit_action: PropTypes.func.isRequired,
    cancel_action: PropTypes.func.isRequired
};
export default TwoFaBackupCodesFormComponent;