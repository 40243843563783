import React from "react";
import SortableTree from "react-sortable-tree";
import WeblinkRepository from "../../repository/WeblinkRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class WeblinkOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: this.format(props.data),
            isLoading: false,
            index: this.props.index
        }
    }

    update = () => WeblinkRepository.updateTree(this.state.treeData)
        .then(response => {
            FormToastComponent.successTrans("Weblinks", "CMS.Weblinks.overview.tree.updated")
            this.props.parent.updateData()
        })
        .catch(error => {
            console.error(error.message);
            FormToastComponent.successTrans("Weblinks", "CMS.Weblinks.overview.tree.failed")
        });

    format = (array) => array.map(weblink => {
        let content = weblink.weblink.contents[this.props.index];
        return {
            title: content.title + '    ( id {' + weblink.id + '})',
            id: weblink.id,
            expanded: true
        }
    });

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div style={{ height: 500 }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    onMoveNode={(data) => this.update(data)}
                    maxDepth={0}
                />
            </div>
        );
    }

}

export default WeblinkOverviewTreeComponent;
