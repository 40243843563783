import * as Yup from "yup"
import TestimonialContent from "./TestimonialContent";
import { JsonHelper } from "../../helpers";

export default class Testimonial {
    id;
    website;
    active;
    module_references;
    image;
    content = [];

    constructor() {
        this.module_references = [];
        this.content = new TestimonialContent();
    }

    static fromJson(json) {
        let testimonial = new Testimonial();
        testimonial.id = json['id'];
        testimonial.active = json['active'];
        testimonial.image = json['image'];
        testimonial.module_references = json.module_references ? JsonHelper.tryParse(json.module_references) : [];
        testimonial.website = json['website'];
        testimonial.settings = json['settings'] === "[]" ? "{}" : json['settings']
        testimonial.content = json['contents'].map(function (content) {
            return TestimonialContent.fromJson(content);
        });
        return testimonial;
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.Testimonials.Form.name').max(255, 'CMS.Testimonials.Form.name|255|max'),
            value: Yup.object({ text: Yup.string().required('CMS.Testimonials.Form.content') })
        };

        if (settings.use_reference) {
            fields.module_references = Yup.array().required('CMS.Testimonials.Form.module|select');
        }

        return Yup.object(fields);
    }
}
