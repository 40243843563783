import * as Yup from 'yup';
import DynamicFieldValueContent from "./DynamicFieldValueContent";

export default class DynamicFieldValue {
    id;
    website;
    module;

    field_number;
    order;
    deletable;

    contents = []

    static fromJson = (json) => {
        let item = new DynamicFieldValue();

        item.website = json.website;
        item.module = json.module;

        item.id = json.id;
        item.field_number = json.field_number;
        item.order = json.order;
        item.deletable = json.deletable;

        item.contents = json.contents.map(content => DynamicFieldValueContent.fromJson(content));

        return item;
    }

    static schema = Yup.object({
        value: Yup.string().required("CMS.Attendee.DynamicField.Forme.value").max(100, "CMS.Attendee.DynamicField.Forme.value.max"),
    })
}