import * as Yup from 'yup';

class Address {

    street = '';
    number = '';
    zip = '';
    city = '';
    country = '';

    constructor(street, number, zip, city, country) {
        this.street = street;
        this.number = number;
        this.zip = zip; // max 25, geen vreemde tekens
        this.city = city; // max 50
        this.country = country;
    }

    static schema = Yup.object({
        street: Yup.string().required('address.form.street').matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'address.form.street.invalid', { excludeEmptyString: true }).max(100, 'address.form.street.max'),
        number: Yup.string().required('address.form.number').matches(/^[a-zA-Z0-9'-.säÄëËüÜïÏöÖ ]*$/, 'address.form.number.invalid', { excludeEmptyString: true }).max(50, 'address.form.number.max'),
        zip: Yup.string().required('address.form.zip').max(50, 'address.form.zip.max').matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, 'address.form.zip.invalid', { excludeEmptyString: true }),
        city: Yup.string().required('address.form.city').max(100, 'address.form.city.max').matches(/^[a-zA-Z-.s\u00C0-\u024F\u1E00-\u1EFF ]*$/, 'address.form.city.invalid', { excludeEmptyString: true }),
        country: Yup.string().required('address.form.country')
    })
}

export default Address
