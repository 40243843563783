import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import PageRepository from "../../repository/PageRepository";
import FormToastComponent from "../basics/FormToastComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageOverviewTreeComponent from "../pages/PageOverviewTreeComponent";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import FormattedMessageString from "../basics/FormattedMessageString";
import NavigationEditFormComponent from "./NavigationEditFormComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import Application from "../../helpers/Application";
import LoaderComponent from "../basics/layout/LoaderComponent";

class NavigationOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            website: null,
            modules: [],
            moduleTypes: [],
            isLoading: true,
            toggler: [],
            treeData: [],
            language: Application.tableLanguage(),
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        let promises = [
            WebsiteRepository.get(Website.get()),
            ModuleRepository.all(),
            ModuleRepository.getTypes()
        ];

        Promise.all(promises)
            .then(result => this.setState({
                website: result[0],
                modules: result[1],
                moduleTypes: result[2] ? result[2].data : [],
                isLoading: false,
                showEditModal: false
            }));
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true,
                text: ''
            },
            {
                dataField: 'placeholder',
                isDummyField: true,
                text: ''
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Navigation.overview.table.name" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.page.contents[index];
                    if (content !== undefined)
                        return content.title;
                    else
                        return null;
                }
            },
            {
                dataField: 'show',
                text: <FormattedMessageString id="CMS.Navigation.overview.table.show" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let navigation = JSON.parse(row.page.navigation);
                    return <div className="actions">
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                navigation.show_in_navigation ?
                                    <Link onClick={() => this.toggleNavigation('show_in_navigation', row)} className={"active"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link onClick={() => this.toggleNavigation('show_in_navigation', row)} className={"inactive"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </Link>

                                : null
                        }

                    </div>
                }
            },
            {
                dataField: 'primary',
                text: <FormattedMessageString id="CMS.Navigation.overview.table.primary" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let navigation = JSON.parse(row.page.navigation);
                    return <div className="actions">
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                navigation.as_primary_navigation ?
                                    <Link onClick={() => this.toggleNavigation('as_primary_navigation', row)} className={"active"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link onClick={() => this.toggleNavigation('as_primary_navigation', row)} className={"inactive"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </Link>

                                : null
                        }
                    </div>
                }
            },
            {
                dataField: 'secondary',
                text: <FormattedMessageString id="CMS.Navigation.overview.table.secondary" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let navigation = JSON.parse(row.page.navigation);
                    return <div className="actions">
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                navigation.as_secondary_navigation ?
                                    <Link onClick={() => this.toggleNavigation('as_secondary_navigation', row)} className={"active"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link onClick={() => this.toggleNavigation('as_secondary_navigation', row)} className={"inactive"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </Link>

                                : null
                        }
                    </div>
                }
            },
            {
                dataField: 'footer',
                text: <FormattedMessageString id="CMS.Navigation.overview.table.footer" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let navigation = JSON.parse(row.page.navigation);
                    return <div className="actions">
                        {
                            CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                navigation.as_footer_navigation ?
                                    <Link onClick={() => this.toggleNavigation('as_footer_navigation', row)} className={"active"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link onClick={() => this.toggleNavigation('as_footer_navigation', row)} className={"inactive"} to="#">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </Link>
                                : null
                        }
                    </div>
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.WIZARD),
                formatter: (cellContent, row) => {
                    return <div className="actions">
                        <Link className={"edit"} to="#" onClick={() => this.setState({ showEditModal: true, page: row })}>
                            <FontAwesomeIcon icon={['fas', 'edit']} />
                        </Link>
                    </div>
                }
            }
        ];
    }

    toggleNavigation = (field, _row) => PageRepository.toggleNavigation({ field: field, id: _row.page.id })
        .then(response => this.updateNavigation(response, _row.page.id))
        .catch(() => FormToastComponent.errorTrans('CMS.Navigations.overview.toggle.failed'));

    updateNavigation = (navigation, id) => {
        let newRows = [...this.state.rows];
        newRows = newRows.map((row) => {
            if (row.page.id === id) {
                let page = row.page;
                page.navigation = navigation;

                return {
                    ...row,
                    page: page
                }
            }
            return row;
        })
        this.setState(curr => ({ ...curr, rows: newRows, reload: true, showEditModal: false }), () => {
            this.setState({ reload: false })
        });
    }

    switch = (index) => (
        <div className="toggle-tree" data-toggle="buttons" data-index={index}>
            <button type="button" data-toggle="button" data-view-type={"table"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? null : 'disabled'}
            >
                <FontAwesomeIcon icon={['far', 'th']} />
            </button>
            <button type="button" data-toggle="button" data-view-type={"tree"} data-index={index}
                onClick={this.toggle.bind(this)}
                disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? 'disabled' : ''}
            >
                <FontAwesomeIcon icon={['far', 'list']} />
            </button>
        </div>
    );

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({ reload: true }, () => {
            this.props.parent.setState({ reload: false }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({
                    toggler: toggler,
                    language: this.state.language
                })
            })
        })
    }

    updateData = () => PageRepository.all().then(response => this.props.parent.setState({ rows: response }))

    table = (index) => (
        <div className={"custom-data-table pages"}>
            <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)}
                title={this.props.title_id} type={this.props.type}
                custom={CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ? this.switch(index) : undefined}
                subRows={true} />
            <DeleteModalComponent parent={this} />
        </div>
    )

    tree = (index) => (
        <div className={'custom-data-table pages'}>
            <div className="table-wrapper">
                <div className="table-header">
                    <FormattedMessage id="CMS.Pages.overview.tree.title">{(value) => <p>{value}</p>}</FormattedMessage>
                    {this.switch(index)}
                </div>
                <PageOverviewTreeComponent data={this.state.rows} index={index} parent={this} />
            </div>
        </div>
    )

    render() {
        if (this.state.isLoading || this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-pages"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}
                            >
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                {this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ?
                                    this.tree(index)
                                    :
                                    this.table(index)
                                }
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>

                {this.state.page && (
                    <ModalFormFragment size="lg" title_id={<FormattedMessageString id={'CMS.Navigation.Edit.modal.title'} values={{ page: this.state.page.page.contents[this.state.language].title }} />}
                        show={this.state.showEditModal}
                        onHide={() => this.setState({ showEditModal: false, page: null })}
                    >
                        <NavigationEditFormComponent parent_ref={this} table_ref={this} page={this.state.page} modules={this.state.modules} moduleTypes={this.state.moduleTypes} language={this.state.language} />
                    </ModalFormFragment>
                )}
            </div>
        );
    }
}

NavigationOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired
}

export default NavigationOverviewTableComponent;