import React from 'react';
import { FormattedMessage } from 'react-intl';

class ErrorForbidden extends React.Component {

    render() {
        return (
            <FormattedMessage id={'CMS.Error.forbidden.title'}>
                {
                    (value) => <h1>{value}</h1>
                }
            </FormattedMessage>
        )
    }
}

export default ErrorForbidden;