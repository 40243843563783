import React from "react";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import { Col, Form, Row } from "react-bootstrap";
import FroalaEditorComponent from "../../basics/form/FroalaEditorComponent";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import MediaAddFormComponent from "../../media/MediaAddFormComponent";
import MutateModalComponent from "../../basics/table/MutateModalComponent";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import StandardFormEditorUploadComponent from "./StandardFormEditorUploadComponent";
import LoaderComponent from "../layout/LoaderComponent";

class StandardTextFormEditorComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            html: false,
            media_drag: null,
            showFormModal: false,
            search: ''
        }

        this._ref = React.createRef();
    }

    componentDidMount() {
        this.initImages().then(() => this.setState({ isLoading: false }));
    }

    initImages = () => {
        return new Promise(resolve => {
            WebsiteRepository.getMediaRows()
                .then(response => {
                    this.setState({
                        media: response.map((item) => {
                            return {
                                id: item.id,
                                title: item.name,
                                name: item.file_name,
                                type: item.type,
                                path: item.path,
                                url: item.media_url,
                                external: item.external
                            }
                        })
                    }, () => {
                        resolve(true);
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }

    // myUndo = () => this._ref.getEditor().history.undo();
    // myRedo = () => this._ref.getEditor().history.redo();
    // toggleHtml = () => this.setState({ html: !this.state.html });

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <Form.Group>
                <Row>
                    <Col xs={12}>
                        <FroalaEditorComponent parent={this} />
                        {!GeneralSettingsHelper.is() && <StandardFormEditorUploadComponent parent={this} ref={this._ref} rows={this.state.media} />}
                    </Col>
                </Row>
                {!GeneralSettingsHelper.is() && (
                    <ModalFormFragment
                        title_id="CMS.Media.add.title"
                        show={this.state.showFormModal}
                        onHide={() => this.setState({ showFormModal: false })}
                    >
                        <MediaAddFormComponent table_ref={this._ref.current} parent_ref={this} onClose={() => MutateModalComponent.closeModal(this)} />
                    </ModalFormFragment>
                )}

            </Form.Group>
        );
    }
}

export default StandardTextFormEditorComponent;