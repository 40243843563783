import Module from "../models/module/Module";
import Website from "../models/website/Website";

class CustomHeaders {

    static get = (custom_token = null, custom_website_id = null, custom_module_id = null) => {
        let headers = {};

        let user_token = custom_token == null ? localStorage.getItem('user.token') : custom_token;
        headers['Authorization'] = user_token != null ? 'Bearer ' + user_token : ''

        let language = localStorage.getItem('language');
        headers['X-localization'] = language != null ? language : 'nl';

        let auth_passed = sessionStorage.getItem('auth_passed');
        headers['Auth-Passed'] = auth_passed != null ? auth_passed : '';

        let auth_time = sessionStorage.getItem('auth_time');
        headers['Auth-Time'] = auth_time != null ? auth_time : '';

        let website_id = custom_website_id == null ? Website.get() : custom_website_id;
        if (website_id) {
            headers['Website-Id'] = website_id;
        }

        let module_id = custom_module_id == null ? Module.getId() : custom_module_id;
        if (module_id) {
            headers['Module-Id'] = module_id;
        }

        return headers;
    }
}

export default CustomHeaders;