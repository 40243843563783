import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import { PageRepository } from "../../repository";
import MapFormError from './errors/MapFormError';
import Select from "react-select";

class MapCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_favorite: props.values.settings.use_favorite,
            show_additional_information: props.values.settings.show_additional_information,
            pages: props.values.settings.pages ? props.values.settings.pages : [],
            pagesList: [],
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        PageRepository.all().then(result => this.setState({
            pagesList: result.map(page => {
                return {
                    value: page.page.id,
                    label: '-'.repeat(page.index) + " " + page.title
                }
            })
        }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })

        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Maps.Form.pages">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    <Form.Group as={Col} xs={9}>
                        <Select classNamePrefix="react-select" isClearable isMulti
                            className={this.props.errors.settings != null && this.props.errors.settings.pages ? 'react-select-container is-invalid' : 'react-select-container'}
                            placeholder={<FormattedMessage id="CMS.Form.select.placeholder" />}
                            options={this.state.pagesList}
                            onChange={(event) => {
                                let e = { target: { type: 'select', name: 'settings.pages', value: event ? event.map(e => e.value) : [] } }
                                this.onChange(e, () => this.setState({ pages: e.target.value }));
                            }}
                            value={this.state.pagesList.filter(page => this.state.pages.indexOf(page.value) > -1)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.props.errors.settings != null && this.props.errors.settings.pages && (
                                <MapFormError error={this.props.errors.settings.pages} />
                            )}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Maps.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use favorite */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="use_favorite" className="mb-1"
                                    id="custom-switch-use-favorite" name="settings.use_favorite"
                                    onChange={(event) => this.onChange(event, () => this.setState({ use_favorite: !this.state.use_favorite }))}
                                    checked={this.state.use_favorite}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.map.use_favorite">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use show_additional_information */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_information" className="mb-1"
                                    id="custom-switch-show-additional-information" name="settings.show_additional_information"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_additional_information: !this.state.show_additional_information }))}
                                    checked={this.state.show_additional_information}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.map.show_additional_information">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this} title="CMS.Maps.Form.setting.delete.title" text="CMS.Maps.Form.setting.delete.text"
                    buttons={{
                        confirm: { text: 'Default.confirm', icon: 'check' }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default MapCustomSettingsFormComponent;