import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormRequiredError from "../../errors/FormRequiredError";
import { FormEmailError, FormMaxError, FormNumberError } from "../../errors";

class StandardFormErrorMessageComponent extends React.Component {
    render() {
        return (
            <Form.Control.Feedback type="invalid">
                {
                    this.props.error !== undefined ?
                        this.props.customFeedback !== undefined ?
                            this.custom() :
                            this.default()
                        : null
                }
                {
                    this.props.formErrors !== undefined && this.props.formErrors[this.props.formErrorsName] && (
                        <p>{this.props.formErrors[this.props.formErrorsName]}</p>
                    )
                }
            </Form.Control.Feedback>
        );
    }

    default = () => {
        let error = this.props.error.split('|');

        if (this.props.error.slice(-6) === "number") {
            return <FormNumberError field_name={this.props.error.slice(0, -7)} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error === 'CMS.Category.Form.email') {
            return <FormEmailError field_name={this.props.error} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }

    custom = () => {
        const Feedback = this.props.customFeedback;
        return <Feedback error={this.props.error} />
    }
}

StandardFormErrorMessageComponent.propTypes = {
    error: PropTypes.any.isRequired,
    formErrors: PropTypes.array,
    formErrorsName: PropTypes.string,
    customFeedback: PropTypes.func,
}
export default StandardFormErrorMessageComponent;
