import React from "react";
import PropTypes from "prop-types";
import FormRequiredError from "../../errors/FormRequiredError";
import FormMaxError from "../../errors/FormMaxError";
class WebsiteMaxRequiredError extends React.Component{
    render() {
        if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={this.props.error.slice(0, -4)} max={100} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

WebsiteMaxRequiredError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default WebsiteMaxRequiredError;