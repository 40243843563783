import React from "react";
import SortableTree from "react-sortable-tree";
import FaqRepository from "../../repository/FaqRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class FaqOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: this.format(this.props.data),
            isLoading: false,
            index: this.props.index
        };
    }

    componentDidMount() {
        setTimeout(() => this.handleSortableTreeHeight(), 250);
    }

    update = (index) => FaqRepository.updateTree(this.state.treeData[index])
        .then(() => {
            FormToastComponent.successTrans("Faqs", "CMS.Faq.overview.tree.updated");
            this.props.parent.updateData();
        })
        .catch(error => {
            FormToastComponent.errorTrans("Faqs", "CMS.Faq.overview.tree.failed");
        });

    format = (array) => {
        if (!array.length) return [];

        if (!array[0].category) {
            return [array.map(faq => {
                return {
                    title: (faq.question + ' ( id ' + faq.id + ')').trim(),
                    id: faq.id,
                    expanded: true
                }
            })];
        }

        let items = {};
        array.forEach(faq => {
            if (!(faq.category in items)) {
                items[faq.category] = [];
            }

            items[faq.category].push({
                title: ((faq.category ? faq.category + ' | ' : '') + faq.question + ' ( id ' + faq.id + ')').trim(),
                id: faq.id,
                expanded: true
            });
        });
        return Object.values(items);
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            this.state.treeData.map((data, i) => (
                <div key={i} style={{ height: 500 }} className="sortable-wrapper">
                    <SortableTree
                        treeData={data}
                        onChange={treeData => {
                            let stateTree = this.state.treeData;
                            stateTree[i] = treeData;
                            this.setState({ stateTree })
                        }}
                        onMoveNode={() => this.update(i)}
                        maxDepth={0}
                    />
                </div>
            ))
        );
    }

    handleSortableTreeHeight = () => {
        [...document.querySelectorAll('.rst__tree')].forEach(el => {
            let height = el.querySelector('div[role="rowgroup"]').offsetHeight;
            el.closest('.sortable-wrapper').style.height = (height + 25) + 'px';
        });
    }
}

export default FaqOverviewTreeComponent