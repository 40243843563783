import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Category from "../../models/category/Category";
import CategoryTextFormComponent from "./forms/CategoryTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import CategoryRepository from "../../repository/CategoryRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import Module from "../../models/module/Module";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";
import API from "../../services/api";

class CategoryEditComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: null,
            categoryContent: null,
            isLoading: true,
            settings: {},
            validation: {}
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];
        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let categoryResponse = result[1];
                let category = Category.fromJson(categoryResponse);
                let categoryContent = category.content.filter(content => content.language === this.props.match.params.language)[0];
                categoryContent.value = JSON.parse(categoryContent.value);

                if (
                    (!category.parent && !Category.canChange(this.props.user.roles, settings)) ||
                    (category.parent && !Category.canChange(this.props.user.roles, settings, true))
                ) {
                    this.props.history.push('/403-forbidden');
                }

                this.setState({
                    settings: settings,
                    category: category,
                    categoryContent: categoryContent
                }, () => {
                    this.setState({ isLoading: false }, () => {
                        let moduleContent = Module.getContent(localStorage.getItem('language'));
                        this.props.parent.updateTitle(<FormattedMessageString id="CMS.Module.edit.title" values={{ module: moduleContent.title, title: categoryContent.title }} />);
                    })
                })
            });
    }

    async getBeforeSubmit(element) {
        let promises = [];

        let prefix = this.state.category.parent ? 'subcategories_' : '';

        if (this.state.settings[prefix + 'show_cover_image']) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'image', 'category_image'));
        }

        if (this.state.settings.show_svg) {
            promises.push(CategoryTextFormComponent.fields_image_before_submit(element, 'svg', 'category_svg'));
        }

        await Promise.all(promises);
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.categoryContent}
                validationSchema={Category.validation(this.state.settings, this.state.category.parent)}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {
                        let data = {
                            id: this.state.category.id,
                            language: values.language,
                            title: values.title,
                            parent: values.parent ? values.parent : null,
                            email: values.email,
                            label: values.label,
                            color: values.color,
                            summary: values.summary,
                            highlights: JSON.stringify(values.highlights),
                            value: JSON.stringify(values.value),
                            image: values.image,
                            home_image: values.home_image,
                            svg: values.svg
                        };

                        CategoryRepository.update(data)
                            .then(response => {
                                if (API.isSuccess(response)) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                    this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    })

                }}
                formErrors={this.state.form_errors}
                fields={CategoryTextFormComponent.fields(this.state.settings, this.state.category.parent)}
            />
        )
    }
}

export default withRouter(CategoryEditComponent);