import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from 'react-global-configuration';
import configuration from './config';
import { IntlProvider } from "react-intl";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-quill/dist/quill.snow.css';

import configureStore from './store/store';

import { addLocaleData } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_nl from 'react-intl/locale-data/nl';
import locale_fr from 'react-intl/locale-data/fr';

import messages_en from "./translations/en.json";
import messages_nl from "./translations/nl.json";
import messages_fr from "./translations/fr.json";
import Application from './helpers/Application';

addLocaleData([...locale_en, ...locale_nl, ...locale_fr]);

const messages = {
    'en': messages_en,
    'nl': messages_nl,
    'fr': messages_fr
};

const store = configureStore();

let language = 'en';

let url = new URL(window.location.href);
let givenLang = url.searchParams.get('lang');
let defaultLang = Application.setting('default_language');

if (givenLang !== null) {
    language = givenLang;
    localStorage.setItem('language', givenLang);
} else if (localStorage.getItem('language') != null) {
    language = localStorage.getItem('language')
} else if (defaultLang !== null) {
    language = defaultLang;
    localStorage.setItem('language', language);
} else {
    language = navigator.language.split(/[-_]/)[0];
    localStorage.setItem('language', language);
}
config.set(configuration);

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={language} messages={messages[language]} onError={() => { }}>
            <App />
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
