import libphonenumber from 'google-libphonenumber';
import * as yup from "yup";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

yup.addMethod(yup.string, 'phone', function (text) {
    return this.test({
        name: 'phone',
        exclusive: true,
        message: text,
        test: (value) => {
            if (!!value) {
                if (value.charAt(0) === '0' && value.charAt(1) === '0') {
                    value = value.replace('00', '+');
                }
                if (!/[a-z]/gi.test(value)) {
                    try {
                        const phone = phoneUtil.parse(value, null);
                        return phoneUtil.isValidNumber(phone);
                    } catch (e) {
                        return false;
                    }
                } else {
                    return false
                }
            }
            return true;
        }
    })
});