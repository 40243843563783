import React from "react";
import TestimonialCustomSettingsFormComponent from '../testimonials/TestimonialCustomSettingsFormComponent';
import FaqCustomSettingsFormComponent from '../faqs/FaqCustomSettingsFormComponent';
import CategoryCustomSettingsFormComponent from '../categories/CategoryCustomSettingsFormComponent';
import MapCustomSettingsFormComponent from "../maps/MapCustomSettingsFormComponent";
import NewsCustomSettingsFormComponent from "../news/NewsCustomSettingsFormComponent";
import ProjectCustomSettingsFormComponent from "../project/ProjectCustomSettingsFormComponent";
import TeamCustomSettingsFormComponent from "../team/TeamCustomSettingsFormComponent";
import BlogCustomSettingsFormComponent from "../blog/BlogCustomSettingsFormComponent";
import ClientCustomSettingsFormComponent from "../client/ClientCustomSettingsFormComponent";
import WeblinkCustomSettingsFormComponent from "../weblink/WeblinkCustomSettingsFormComponent";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";
import EventCustomSettingsFormComponent from "../event/EventCustomSettingsFormComponent";
import AttendeeCustomSettingsFormComponent from "../attendee/AttendeeCustomSettingsFormComponent";
import ProductCustomSettingsFormComponent from "../product/ProductCustomSettingsFormComponent";
import WebshopCustomSettingsFormComponent from "../webshop/WebshopCustomSettingsFormComponent";

class ModuleCustomSettingsFormComponent extends React.Component {
    render() {
        switch (this.props.values.type) {
            case ModuleTypeEnum.ATTENDEE:
                return <AttendeeCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.BLOG:
                return <BlogCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.CATEGORY:
                return <CategoryCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.CLIENT:
                return <ClientCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.EVENT:
                return <EventCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.FAQ:
                return <FaqCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.MAP:
                return <MapCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.NEWS:
                return <NewsCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.PRODUCT:
                return <ProductCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.PROJECT:
                return <ProjectCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.TEAM:
                return <TeamCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.TESTIMONIAL:
                return <TestimonialCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.WEBLINK:
                return <WeblinkCustomSettingsFormComponent {...this.props} />
            case ModuleTypeEnum.WEBSHOP:
                return <WebshopCustomSettingsFormComponent {...this.props} />
            default:
                return null;
        }
    }
}

export default ModuleCustomSettingsFormComponent;