export default class FieldContent {
    id;
    language;
    label;
    placeholder;
      
    static fromJson(json, language) {
        let content = new FieldContent();

        content.language = language;
        content.label = json.label;
        content.placeholder = json.placeholder;

        return content;
    }
}