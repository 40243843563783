import Website from "../models/website/Website";

export default class GeneralSettingsHelper {

    static filter(website) {
        return website.value !== 1;
    }

    static is() {
        let websiteId = Website.get();
        return websiteId === 1 || websiteId === null || websiteId === undefined;
    }
}
