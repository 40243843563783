import React from "react";
import { FormRepository } from "../../repository";
import { withRouter } from 'react-router-dom';
import { Alert, Col, Row } from "react-bootstrap";
import FieldTypeTable from "./widgets/FieldTypeTable";
import { FormattedMessage, injectIntl } from "react-intl";
import DragDrop from "../../helpers/DragDrop";
import { Group, Field, Option } from "../../models/form";
import GroupComponent from "./widgets/GroupComponent";
import { ReactSortable } from 'react-sortablejs'
import LoaderComponent from "../basics/layout/LoaderComponent";
import { FieldArray } from "formik";
import FormFormError from "./errors/FormFormError";

class FormFieldFormComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            types: [],
            draggedType: null,
            selectedGroup: 1, //group.order property
            groups: []
        };
    }

    componentDidMount() {
        let promises = [
            FormRepository.getTypes(),
        ];

        Promise.all(promises)
            .then(result => {
                let types = result[0];

                if (types !== undefined && types.data !== undefined && types.data.types !== undefined) {
                    types = types.data.types;
                }

                this.setState({
                    types: types,
                    loading: false
                })
            });
    }

    render() {
        if (this.state.loading) return <LoaderComponent />

        return <Row>
            <Col lg={9}>
                {this.props.model.groups.length <= 0 && (
                    <div className="custom-data-table mb-2">
                        <FormattedMessage id="CMS.Form.Form.fields.empty.message">
                            {value => <p className="not-found">{value}</p>}
                        </FormattedMessage>
                    </div>
                )}
                {this.renderGroups()}
            </Col>
            <Col lg={3}>
                <FieldTypeTable types={this.state.types} onDragStart={this.handleDragStart} onClick={this.handleClick} />
            </Col>
        </Row>
    }

    renderDropZone = () => {
        return (
            <section className="media-drag-drop">
                <div className="media-drag-drop-inner"
                    onDragOver={DragDrop.dragOver}
                    onDrop={this.handleDropAccepted}
                >
                    <div className="media-drag-drop-inner-row">
                        <div className="media-drag-drop-text">
                            <FormattedMessage id="CMS.Form.Form.fields.drag_drop.message">
                                {(value) => <p>{value}</p>}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderGroups = () => {
        let { errors, model } = this.props
        let { groups } = model;

        let groupsRender = [];
        groups.forEach((group, index) => {
            let groupErrors = {};
            if (errors?.groups && typeof errors.groups === 'object' && index in errors.groups) {
                groupErrors = errors.groups[index];
            }
            groupsRender.push(<GroupComponent selectGroup={this.handleSelectGroup} handleSortFields={this.handleSortFields} handleEndSortFields={this.handleEndSortFields} key={group.order} selected={group.order === this.state.selectedGroup} index={index} errors={groupErrors} group={group} updateGroup={this.updateGroup} removeGroup={this.removeGroup} types={this.state.types} />);
        });

        let groupsError = '';

        if (errors?.groups && typeof errors.groups === 'string') {
            groupsError = (
                <Alert variant="danger" size="sm">
                    <FormFormError error={errors.groups} />
                </Alert>
            );
        }

        return <React.Fragment>
            {groupsError}
            <FieldArray name="groups" render={arrayHelpers => (
                <ReactSortable group="GroupComponents" handle=".fbgroup-handle" list={groups} setList={this.handleSortGroups} onEnd={this.handleSortGroupsEnd}>
                    {groupsRender}
                </ReactSortable>
            )} />
        </React.Fragment>;
    }

    handleEndSortFields = (index) => {
        let { errors } = this.props;

        if (errors?.groups && index in errors.groups) {
            errors.groups.splice(index, 1);
        }

        this.props.parent.setState({
            model: this.props.model
        });
    }

    handleSortFields = (group, fields) => {
        group.fields = fields;
    }

    handleSortGroups = (groups) => {
        let { model, errors } = this.props;

        groups.forEach((group, index) => {
            group.order = index + 1;
        });

        errors.groups = {};

        model.groups = groups;
        //state update pas uitvoeren bij sort end
    }

    handleSortGroupsEnd = (event) => {
        return this.setState(
            { selectedGroup: event.newIndex + 1 },
            this.props.parent.setState({
                model: this.props.model
            })
        );
    }

    handleSelectGroup = (event, group) => {
        return this.setState({ selectedGroup: group.order });
    }

    handleDragStart = (type) => {
        return this.setState({ draggedType: type });
    };

    handleDropAccepted = () => {
        let { draggedType } = this.state;
        if (!draggedType) return;

        this.handleNew(draggedType);
    }

    handleClick = (type) => {
        if (!type) return;

        this.handleNew(type);
    }

    handleNew = (type) => {
        if (!type) return;
        let { selectedGroup } = this.state;
        let { model } = this.props;

        if (type === 'group' || model.groups.length === 0) {
            model = this.addGroup(model);
            selectedGroup = model.groups[model.groups.length - 1].order;
        }

        if (type !== 'group') {
            model = this.addField(model, type);
        }

        this.setState(
            { draggedType: null, selectedGroup: selectedGroup },
            this.props.parent.setState({
                model: model
            })
        );
    }

    addGroup = (model) => {
        let currentGroupLength = model.groups.length;

        let fg = new Group();
        fg.order = currentGroupLength + 1;
        fg.title = ''; // this.props.intl.formatMessage({ id: 'Group {number}', defaultMessage: 'Groep {number}' }, { 'number': (currentGroupLength + 1) });

        model.groups.push(fg);
        return model;
    }

    updateGroup = (group) => {
        let { model } = this.props;

        model.groups[model.groups.indexOf(group)] = group;
        this.props.parent.setState({ model: model });
    }

    removeGroup = (index) => {
        let { model, errors } = this.props;

        model.groups.splice(index, 1);
        errors.groups = {};

        this.props.parent.setState({ model: model, errors: errors });
    }

    addField = (model, type) => {
        let { selectedGroup } = this.state;

        let group = model.groups.find((group) => group.order === selectedGroup);

        let f = new Field();
        f.type = type;
        f.order = group.fields.length + 1;
        f.label = ''; // this.props.intl.formatMessage({ id: 'Field {number}', defaultMessage: 'Veld {number}' }, { 'number': f.order });
        f.placeholder = '';
        f.css_class = '';

        if (f.type.indexOf('html_') > -1) {
            f.required = false;
        }

        if (this.state.types[type]?.hasOptions) {
            for (let i = 1; i <= 2; i++) {
                let o = new Option();
                o.order = i;
                f.options.push(o);
            }
        }

        group.fields.push(f);
        model.groups[model.groups.indexOf(group)] = group;
        return model;
    }
}

export default withRouter(injectIntl(FormFieldFormComponent));