import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormNumberError from "../../errors/FormNumberError";
import FormEmailError from "../../errors/FormEmailError";

class CategoryFormError extends React.Component {

    render() {
        let error = this.props.error.split('|');

        if (this.props.error.slice(-6) === "number") {
            return <FormNumberError field_name={this.props.error.slice(0, -7)} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error === 'CMS.Category.Content.Form.email.invalid') {
            return <FormEmailError field_name={this.props.error.replace('.invalid', '')} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

CategoryFormError.propTypes = {
    error: PropTypes.string.isRequired
};
export default CategoryFormError;
