import React from "react";
import FormattedMessageString from "../../basics/FormattedMessageString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";

class FieldTypeTable extends React.Component {
    render() {
        return <div className="fbsidebar">
            <div className="fbsidebar-header">
                <FormattedMessage id="CMS.Form.Form.fields.title" />
            </div>

            <div className="fbsidebar-group">
                <div className="fbsidebar-groupheader">
                    <FormattedMessageString id="CMS.Form.Form.fields.groups" />
                </div>

                <div className="fbsidebar-list">
                    <div className="fbsidebar-item">
                        <div>
                            <Button size="sm" variant="primary" className="iconbtn" onClick={() => this.props.onClick('group')}>
                                <FontAwesomeIcon icon={['fas', 'fa-plus']} />
                            </Button>
                        </div>
                        <div>
                            <FormattedMessageString id="CMS.Form.Form.fields.group" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="fbsidebar-group">
                <div className="fbsidebar-groupheader">
                    <FormattedMessageString id="CMS.Form.Form.fields.fields" />
                </div>

                <div className="fbsidebar-list">
                    {Object.keys(this.props.types).map((type, index) => (
                        <div key={index} className="fbsidebar-item">
                            <div>
                                <Button size="sm" variant="primary" className="iconbtn" onClick={() => this.props.onClick(type)}>
                                    <FontAwesomeIcon icon={['fas', 'fa-plus']} />
                                </Button>
                            </div>
                            <div>
                                <FormattedMessageString id={'CMS.Form.Form.fields.type.' + type} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="fbsidebar-group">
                <div className="fbsidebar-groupheader">
                    <FormattedMessageString id="CMS.Form.Form.fields.html" />
                </div>

                <div className="fbsidebar-list">
                    {['html_linebreak', 'html_paragraph'].map(type => (
                        <div className="fbsidebar-item">
                            <div>
                                <Button size="sm" variant="primary" className="iconbtn" onClick={() => this.props.onClick(type)}>
                                    <FontAwesomeIcon icon={['fas', 'fa-plus']} />
                                </Button>
                            </div>
                            <div>
                                <FormattedMessageString id={'CMS.Form.Form.fields.type.' + type} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    }
}

export default FieldTypeTable;