class InputHelper {

    static transformToLowerCaseAndUnderscore(value) {
        let splittedValues = value.split(' ');
        
        for (let i = 1; i < splittedValues.length; i++) {
            if (splittedValues[i - 1][splittedValues[i - 1].length - 1] === "_") {
                splittedValues.splice(i, 1);
            }
        }
        value = splittedValues.join('_');
        value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        value = value.replace(/[^a-zA-Z0-9_]/g, "");
        value = value.toLowerCase();
        return value;
    }

    static copyToClipboard = (text) => {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            // console.log('Copy to clipboard was successful!');
        }, function (err) {
            console.error('Could not copy to clipboard: ', err);
        });
    }

    static fallbackCopyTextToClipboard = (text) => {
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Could not copy to clipboard: ', err);
        }

        document.body.removeChild(textArea);
    }

}

export default InputHelper;
