import React from "react";
import Module from "../../models/module/Module";
import ModuleRepository from "../../repository/ModuleRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import ModuleCustomSettingsFormComponent from "./ModuleCustomSettingsFormComponent";
import ModuleFormError from "./errors/ModuleFormError";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import { LoaderComponent } from "../basics/layout";
import { CheckPermissions } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";

class ModuleFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new Module(),
            loading: true,
            modules: [],
            types: [],
            isEdit: !!props.match.params.id,
            roles: this.props.user.roles,
        };

        this.customProps = { ...this.props };
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getTypes(this.state.isEdit)
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise)
        }

        Promise.all(promises)
            .then(result => {
                let types = result[0];
                if (types !== undefined && types.data !== undefined) {
                    types = types.data.map(type => (
                        {
                            value: type.title,
                            label: type.title.charAt(0).toUpperCase() + type.title.slice(1)
                        }
                    ));
                }

                let model = this.state.model;
                if (result[1] !== undefined) {
                    let module = Module.fromJson(result[1].module);
                    let moduleContent = module.content.filter(content => content.language === this.props.match.params.language)[0];
                    model = {
                        id: module.id,
                        name: moduleContent.title,
                        description: moduleContent.description,
                        type: module.type,
                        parent: module.parent,
                        settings: JSON.parse(module.settings),
                        _module: module
                    }
                }

                this.setState({
                    types: types,
                    modules: [],
                    model: model,
                    loading: false,
                    isEdit: model.id !== undefined
                })
            })
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                parent={this}
                className="module"
                validationSchema={Module.schema_add(this.state.model)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (values.settings.use_categories && values.settings.module === undefined) {
                        values.settings.use_categories = false;
                    }

                    if (this.state.isEdit) {
                        ModuleRepository.update(values.id, this.props.match.params.language, values.settings, values)
                            .then(() => {
                                FormToastComponent.successTrans("Modules", "Default.saved");
                                this.props.history.push("/" + this.props.slug);

                                if (this.props.parent.props.parent) {
                                    this.props.parent.props.parent.reload();
                                }
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    } else {
                        ModuleRepository.create(values)
                            .then(() => {
                                FormToastComponent.successTrans("Modules", "Default.saved");
                                this.props.history.push("/" + this.props.slug);

                                if (this.props.parent.props.parent) {
                                    this.props.parent.props.parent.reload();
                                }
                            })
                            .catch(error => FormHelper.handleSubmitError(this, error))
                    }

                }}
                formErrors={this.state.form_errors}
                fields={[
                    {
                        type: "row",
                        children: [
                            {
                                type: "col",
                                xl: 8,
                                fields: [
                                    {
                                        title: "CMS.Modules.Form.name",
                                        type: "text",
                                        name: "name",
                                        disabled: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER),
                                        message: 'CMS.Modules.Form.name.message',
                                        customFeedback: ModuleFormError,
                                        formErrorName: "title"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                xl: 8,
                                fields: [
                                    {
                                        title: "CMS.Modules.Form.description",
                                        type: "textarea",
                                        name: "description",
                                        disabled: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER),
                                        message: 'CMS.Modules.Form.description.message',
                                        customFeedback: ModuleFormError,
                                        formErrorName: "description",
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 6,
                                fields: [
                                    {
                                        title: "CMS.Modules.Form.type",
                                        type: "select",
                                        name: "type",
                                        options: this.state.types,
                                        disabled: this.state.isEdit,
                                        customFeedback: ModuleFormError,
                                        formErrorName: 'type'
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 8,
                                fields: []
                            },
                            {
                                type: "col",
                                xl: 8,
                                fields: [],
                                custom: ModuleCustomSettingsFormComponent
                            }
                        ]
                    }
                ]}
            />
        );
    }
}

export default withRouter(ModuleFormComponent);