
export default class ModuleContent {
    id;
    title;
    description;
    language;
    slug;

    static fromJson(json) {
        let content = new ModuleContent();
        content.id = json['id'];
        content.title = json['title'];
        content.description = json['description'];
        content.language = json['language'];
        content.slug = json['slug'];
        return content;
    }

    static getByLanguage(contents, language) {
        return contents.filter(content => content.language === language)[0];
    }
}
