class JsonHelper {

    static tryParse = (json, returnNullOnError = false, returnNullOnEmpty = false) => {
        try {
            var o = JSON.parse(json);

            if (o && typeof o === "object") {
                if (false !== returnNullOnEmpty && Object.values(o).length <= 0) {
                    return returnNullOnEmpty;
                }

                return o;
            }
        } catch (e) {
            if (false !== returnNullOnError) {
                return returnNullOnError;
            }
        }

        return json;
    }

    static stringify = (object) => {
        try {
            return JSON.stringify(object);
        } catch (e) {
            return null;
        }
    }
}

export default JsonHelper;