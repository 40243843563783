import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormattedMessageString extends React.Component {

    render() {
        return (
            <FormattedMessage id={this.props.id} {...this.props}>
                {(value) => <>{value}</>}
            </FormattedMessage>
        );
    }

}

FormattedMessageString.propTypes = {
    id: PropTypes.string.isRequired,
};
export default FormattedMessageString
