import API from "../services/api";

export default class FaqRepository {

    static all = () => API.get('/faq/all').then(result => result.data.faqs);
    static get = (id) => API.get('/faq/details/' + id).then(result => result.data.faq);

    static create = (data) => API.post('/faq/create', {
        question: data.question,
        answer: JSON.stringify(data.answer),
        category: data.category
    });

    static update = (id, data) => API.post('/faq/content/update', {
        id: id,
        question: data.question,
        language: data.language,
        answer: JSON.stringify(data.answer),
        category: data.category
    })
        .then(response => response.data);

    static updateTree = (data) => API.post('/faq/update/order', { tree: data });

    static delete = (id) => API.delete('/faq/delete?id=' + id);

    static toggle = (id) => API.post('/faq/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });
}
