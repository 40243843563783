import UserRolesEnum from "../enums/UserRolesEnum";
import Website from "../models/website/Website";
import UserRole from "../models/user/UserRole";

class CheckPermissions {

    static role(roles, required_role) {
        let accept = false;

        let website_id = Website.get();
        if (website_id === null) return false;

        roles.forEach(function (role) {
            if (website_id === role.website_id || role.website_id === null) {
                let role_name = role.name;
                let role_number = UserRolesEnum[role_name];

                if (required_role >= role_number) accept = true;
            }
        });

        return accept;
    }

    static isWizard(roles) {
        let result = false;

        roles.forEach(function (role) {
            let role_name = role.name;
            let role_number = UserRolesEnum[role_name];
            if (role.website_id === null && UserRolesEnum.WIZARD === role_number)
                result = true;
        });

        return result;
    }

    static atLeast = (roles, requiredRole) => {
        let result = false;

        roles.forEach(role => {
            let number = UserRolesEnum[role.name];

            if (requiredRole >= number) {
                result = true;
            }
        });

        return result;
    }

    static is(roles, required_role) {
        let result = false;

        roles.forEach(function (role) {
            let role_name = role.name;
            let role_number = UserRolesEnum[role_name];
            if (role.website_id === Website.get() && required_role === role_number)
                result = true;
        });

        return result;
    }

    static get(roles, string = false) {
        let website_id = Website.get();

        let fixed_roles = [];

        let _role = null;

        roles.forEach(function (role) {
            if (role instanceof UserRole) {
                fixed_roles.push(role);
            } else {
                let user_role = new UserRole();
                if (role.pivot != null) {
                    user_role.name = role.pivot.role;
                    user_role.website_id = role.pivot.website;
                    fixed_roles.push(user_role);
                }
            }
        });

        fixed_roles.forEach(function (role) {
            if ("" + role.website_id === "" + website_id && role.name !== "CUSTOMER") {
                _role = string ? role.name.toLowerCase() : UserRolesEnum[role.name];
            } else if (((website_id === "0" || website_id === null) || (role.website_id === "0" || role.website_id == null)) && _role == null) {
                _role = string ? role.name.toLowerCase() : UserRolesEnum[role.name];
            }
        });


        return _role;
    }
}


export default CheckPermissions;