import moment from "moment";
import * as Yup from "yup"
import ProjectContent from "./ProjectContent";

export default class Project {
    id;
    website;
    category;
    active;
    image;
    contents = [];

    constructor() {
        this.published_at = moment()
        this.content = new ProjectContent();
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.Project.Content.Form.title').max(100, 'CMS.Project.Content.Form.title|100|max')
        };

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Project.Content.Form.category|select');
        }
        if (settings.show_client) {
            fields.client = Yup.string().required('CMS.Project.Content.Form.client').max(255, 'CMS.Project.Content.Form.client|255|max');
        }
        if (settings.show_url) {
            fields.url = Yup.string().required("CMS.Project.Content.Form.url")
                .test("is-url-without-https-http", "CMS.Project.Content.Form.url|invalid", val => {
                    if (val !== undefined) {
                        let schemaUrl = Yup.object().shape({
                            url: Yup.string().url("CMS.Project.Content.Form.url|invalid")
                        });
                        return schemaUrl.validate({ url: 'https://' + val.replace('http://', '').replace('https://', '').replace('www.', '') })
                    } else {
                        return true;
                    }
                })
                .max(255, 'CMS.Project.Content.Form.url|255|max');
        }
        if (settings.show_summary) {
            fields.summary = Yup.string().required('CMS.Project.Content.Form.summary').max(255, 'CMS.Project.Content.Form.summary|255|max');
        }
        if (settings.show_content) {
            fields.content = Yup.object({ text: Yup.string().required('CMS.Project.Content.Form.content') })
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Project();

        item.website = json.website;
        item.category = json.category;
        item.id = json.id;
        item.active = json.active;
        item.image = json.image;
        item.contents = json.contents.map(content => ProjectContent.fromJson(content));

        return item;
    }
}
