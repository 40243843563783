import React from "react";
import { FormRepository, PageRepository } from "../../repository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import Form from "../../models/form/Form";
import FormFormError from "./errors/FormFormError";
import StandardTextFormEditorComponent from "../basics/form/StandardTextFormEditorComponent";
import FormFieldFormComponent from "./FormFieldFormComponent";
import FormToastComponent from "../basics/FormToastComponent";
import { FormHelper } from "../../helpers";
import FormattedMessageString from "../basics/FormattedMessageString";

class FormFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: props.model !== undefined ? props.model : new Form(),
            loading: true,
            isEdit: false,
            pages: [],
        };

        this.table_ref = React.createRef();
    }


    componentDidMount() {
        let promises = [
            PageRepository.all()
        ];

        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let pages = result[0];
                let form = result[1];

                let pagesList = [];
                if (pages !== undefined) {
                    pagesList = pages.map(page => {
                        return {
                            value: page.page.id,
                            label: '-'.repeat(page.index) + " " + page.title
                        }
                    });
                }

                let model = new Form();
                if (form !== undefined && form) {
                    model = Form.fromJson(form.form, this.props.match.params.language);
                }

                this.setState({
                    model: model,
                    pages: pagesList,
                    loading: false,
                    isEdit: model.id ? true : false
                })
            });
    }

    render() {
        if (this.state.loading) return <p>loading...</p>;

        return (
            <StandardFormComponent
                {...this.props}
                ref={this.table_ref}
                parent={this}
                className={"Form"}
                validationSchema={Form.schema_add()}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    if (this.state.isEdit) {
                        values.action = 'form';
                        FormRepository.updateForm(values, this.props.match.params.language)
                            .then(() => {
                                FormToastComponent.successTrans("Forms", "Default.saved");
                                this.props.history.push('/forms/overview');
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    } else {
                        FormRepository.create(values)
                            .then(() => {
                                FormToastComponent.successTrans("Forms", "Default.saved");
                                this.props.history.push('/forms/overview');
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    }
                }}
                formErrors={this.state.form_errors}
                fields={[
                    {
                        type: "row",
                        children: [
                            {
                                type: "col",
                                md: 8,
                                fields: [
                                    {
                                        title: "CMS.Form.Form.title",
                                        type: "text",
                                        name: 'title',
                                        customFeedback: FormFormError,
                                        formErrorName: "title"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 8,
                                fields: [
                                    {
                                        title: "CMS.Form.Form.pages",
                                        placeholder: <FormattedMessageString id="CMS.Form.Form.pages.placeholder" />,
                                        type: "select",
                                        multi: true,
                                        name: "pages",
                                        options: this.state.pages ? this.state.pages : [],
                                        customFeedback: FormFormError,
                                        formErrorName: "pages"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 8,
                                fields: [
                                    {
                                        title: "CMS.Form.Form.button_text",
                                        type: "text",
                                        name: 'button_text',
                                        customFeedback: FormFormError,
                                        formErrorName: "button_text"
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 8,
                                fields: [],
                                custom: StandardTextFormEditorComponent,
                                customProps: { label: 'CMS.Form.Form.success_message', placeholder: 'CMS.Form.Form.success_message.placeholder', valueField: 'success_message' }
                            },
                            {
                                type: "col",
                                md: 12,
                                fields: [],
                                custom: FormFieldFormComponent,
                                customProps: { model: this.state.model, parent: this }
                            },
                        ]
                    }
                ]}
            />
        );
    }
}

export default withRouter(FormFormComponent);