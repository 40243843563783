class WebsiteTableRow {
    id;
    name;
    url;
    languages = [];
    active;
    api_key;
    customer;

    constructor(id, name, url, languages, customer, active = true, api_key = null) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.languages = languages;
        this.customer = customer;
        this.active = active;
        this.api_key = api_key;
    }
}

export default WebsiteTableRow;