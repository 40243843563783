import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AuthenticationRepository from "../../repository/AuthenticationRepository";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import FormEmailError from "../errors/FormEmailError";
import FormRequiredError from "../errors/FormRequiredError";

class ForgotPasswordFormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { email: '', redirectToLogin: false, error: false };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: false });
        if (this.validateEmail(this.state.email)) {
            AuthenticationRepository.resetPasswordRequest(this.state.email)
                .then(response => {
                    if (response.data != null) {
                        localStorage.setItem('user.email', this.state.email);
                        this.redirect(response.data['message']);
                    }
                })
                .catch(error => {
                    this.redirect(error.data['message']);
                })
        } else {
            this.setState({ error: true });
        }

    }

    redirect(message) {
        this.setState({
            redirectToLogin: true,
            redirectToLoginMessage: message
        })
    }

    validateEmail(email) {
        return /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(email) !== false;
    }

    render() {
        if (this.state.redirectToLogin) return <Redirect to={{
            pathname: '/login',
            state: { message: this.state.redirectToLoginMessage }
        }} />;

        return (
            <div className={'custom-form'}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formEmail">
                        <Form.Label><FormattedMessage id="Auth.form.username.placeholder" /></Form.Label>
                        <Form.Control type="text" name="email" value={this.state.email}
                            onChange={this.handleInputChange} isInvalid={!!this.state.error} />
                        <Form.Control.Feedback type="invalid">
                            {

                            }
                            {this.state.error && (
                                this.state.email === '' ?
                                    <FormRequiredError field_name={"Auth.form.username.placeholder"} />
                                    :
                                    <FormEmailError field_name={"Auth.form.username.placeholder"} />
                            )}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formSubmit">
                        <Button type="submit"><FormattedMessage id="Auth.forgot.password.form.submit" /></Button>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}

export default ForgotPasswordFormComponent;
