import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormInvalidError from "../../errors/FormInvalidError";
import FormRequiredError from "../../errors/FormRequiredError";

class FormLastnameError extends React.Component{
    render() {
        switch (this.props.error) {
            case "lastname.max" :
                return <FormMaxError field_name={"CMS.User.Form." + this.props.error.slice(0, -4)} max={100} />;
            case "lastname.invalid":
                return <FormInvalidError field_name={"CMS.User.Form." + this.props.error.slice(0, -8)}/>;
            default:
                return <FormRequiredError field_name={"CMS.User.Form." + this.props.error}/>;
        }
    }
}

FormLastnameError.propTypes = {
    error: PropTypes.string.isRequired,
};
export default FormLastnameError;