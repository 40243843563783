import React from "react";
import { injectIntl } from "react-intl";
import Application from './../../../helpers/Application';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import MapRepository from "../../../repository/MapRepository";
import FormToastComponent from "../../basics/FormToastComponent";

class MapLeafletMap extends React.Component {

    constructor(props) {
        super(props);

        this._map = null;

        this.state = {
            zoom: parseInt(Application.setting('maps_default_zoom')),
            markers: [],
        }
    }

    componentDidMount() {
        this.setState({ ...this.state, ...this.assembleCoordinates(this.props) });
    }

    componentDidUpdate() {
        this.renderMap();
    }

    render() {
        return (
            <div className="custom-data-table maps mt-4">
                <div id={'lmap-' + this.props.language}></div>
            </div>
        );
    }

    assembleCoordinates = ({ locations }) => {
        let activeLanguage = Application.tableLanguage();

        let newState = {
            markers: []
        };

        if (locations.length > 0) {
            let newCenter = null;

            locations.forEach(location => {
                let { map } = location;
                if (!map.latitude && !map.longitude) return;

                let content = map.contents[activeLanguage];
                let position = [map.latitude, map.longitude]

                if (newCenter === null) {
                    newCenter = position
                } else if (content.is_favorite) {
                    newCenter = position;
                }

                newState.markers.push({
                    position: position,
                    ...location.map,
                    ...content
                });
            });

            newState.center = newCenter;
        }

        return newState;
    }

    renderMap = () => {
        if (!this.state.markers.length) return;

        if (!this._map) {
            this._map = L.map('lmap-' + this.props.language).setView(this.state.center, this.state.zoom);
        }

        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this._map);

        if (this.state.markers.length > 1) {
            let bounds = new L.LatLngBounds(this.state.markers.map(marker => marker.position));
            this._map.fitBounds(bounds.pad(0.1));
        }

        let m = null;
        this.state.markers.forEach(marker => {
            m = L.marker(marker.position, {
                draggable: true,
                icon: L.icon({ iconUrl: icon, shadowUrl: iconShadow, iconSize: [23, 31], iconAnchor: [11, 31], popupAnchor: [0, -29] })
            })
                .addTo(this._map)
                .bindPopup(this.renderPopupContent(marker))
                .on('dragend', (event) => {
                    let position = event.target._latlng;

                    marker.latitude = position.lat;
                    marker.longitude = position.lng;

                    let content = marker.contents[this.props.language];
                    MapRepository.update(marker.map, content.language, marker).then(() => {
                        FormToastComponent.successTrans("Maps", "Default.saved")
                        this.props.onMapUpdate();
                    });
                });

            if (marker.is_favorite) {
                m.openPopup();
            }
        });
    }

    renderPopupContent = (marker) => {
        let content = marker.contents[this.props.language];

        let html = '' +
            (content.title ? '<strong>' + content.title + '</strong><br /><br />' : '') +
            '<span><em class="fa fa-map-marker-alt"></em> ' + content.street + ' ' + content.number + '  ' + (content.box ? content.box : '') + '</span><br />' +
            '<span>' + content.postal + ' ' + content.city + '</span><br />' +
            (this.props.settings.show_additional_information && content.remark ? '<i class="text-italic">' + content.remark + '</i><br /><br />' : '<br />') +
            '<a href="http://maps.google.com/maps?q=' + content.address + '&ll=' + marker.position.join(',') + '&z=17" target="_blank" rel="noopener noreferrer">' +
            '<span><em class="fa fa-route"></em> ' + (this.props.intl.messages['CMS.Maps.popup.route_link'] ? this.props.intl.messages['CMS.Maps.popup.route_link'] : 'Plan route') +
            '</a><br /><br />';

        if (this.props.settings.show_additional_information) {
            html += '' +
                (content.phone ? '<span class="fa fa-phone fa-sm"></span> <a href="tel:' + content.phone + '">' + content.phone + '</a><br />' : '') +
                (content.fax ? '<span><em class="fa fa-fax fa-sm"></em> <a href="tel:' + content.fax + '">' + content.fax + '</a></span><br />' : '') +
                (content.mobile ? '<span><em class="fa fa-mobile fa-sm"></em> <a href="tel:' + content.mobile + '">' + content.mobile + '</a></span><br />' : '') +
                (content.email ? '<span><em class="fa fa-at fa-sm"></em> <a href="mailto:' + content.email + '">' + content.email + '</a></span><br />' : '');
        }

        return html;
    }
}

export default injectIntl(MapLeafletMap);