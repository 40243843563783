const CURRENCY_LIST = {
    'EUR': {
        sign: '&#x20AC;'
    },
    'GBP': {
        sign: '&#xa3;'
    },
    'USD': {
        sign: '&#x24;'
    }
}

class PriceHelper {
    static getCurrencyList = () => {
        return CURRENCY_LIST;
    }

    static getCurrencySign = (currency) => {
        return CURRENCY_LIST[currency].sign;
    }

    static price = (value, precision = 2, decimalPoint = ',') => {
        return Number(value).toFixed(precision).replace(/\./g, decimalPoint);
    }
}

export default PriceHelper;