import React from "react";
import PropTypes from "prop-types";
import WebsiteSetting from "../../models/website/WebsiteSetting";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FormRequiredError from "../errors/FormRequiredError";
import FormHelper from "../../helpers/FormHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { withRouter } from "react-router-dom";
import FormToastComponent from "../basics/FormToastComponent";
import WebsiteSettingRow from "../../models/website/WebsiteSettingRow";
import CustomTextArea from "../basics/CustomTextArea";
import InputHelper from "../../helpers/InputHelper";
import WebsiteMaxRequiredError from "./errors/WebsiteMaxRequiredError";
import LoaderComponent from "../basics/layout/LoaderComponent";

class WebsiteSettingFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            setting: this.props.setting == null ? new WebsiteSettingRow() : this.props.setting,
            isLoading: true,
            website_id: this.props.match.params.id,
            form_errors: []
        };
        this.form = null;
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        })
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="custom-form website-setting">
                <Formik
                    enableReinitialize
                    validationSchema={WebsiteSetting.schema}
                    initialValues={this.state.setting}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, formikActions) => {
                        WebsiteRepository.storeSettings(this.state.website_id, values.name, values.value, this.state.setting != null && this.state.setting.isDefault ? 'default' : 'website', this.state.setting.id)
                            .then(response => {
                                if (response.status === 200) {
                                    let table_ref = this.props.table_ref;

                                    let rows = table_ref.state.rows;

                                    if (this.state.setting.name == null) {
                                        let website_rows = table_ref.state.rows.website;
                                        website_rows.push(new WebsiteSettingRow(response.data.setting.id, null, values.name, values.value));
                                        rows.website = website_rows;
                                    } else if (this.state.setting.isDefault) {
                                        let default_rows = table_ref.state.rows.default;
                                        rows.default = default_rows.map(function (row) {
                                            if (row.name === values.name) {
                                                row.value = values.value;
                                            }
                                            return row;
                                        })
                                    } else {
                                        let website_rows = table_ref.state.rows.website;
                                        rows.website = website_rows.map(function (row) {
                                            if (row.name === values.name) {
                                                row.value = values.value;
                                            }
                                            return row;
                                        })
                                    }

                                    table_ref.setState({ isTableReloading: true }, () =>
                                        table_ref.setState({ rows: rows, isTableReloading: false }, () =>
                                            this.props.parent_ref.setState({
                                                showFormModal: false,
                                                showEditModal: false,
                                                editSetting: null
                                            })
                                        ));

                                    FormToastComponent.successTrans('Settings', 'Default.saved');
                                } else {
                                    console.error('error');
                                    FormToastComponent.error(response.status);
                                }
                            })
                            .catch(reason => FormHelper.handleSubmitError(this, reason.response))
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                <FormattedMessage id={"CMS.Websites.Settings.Form.name"}>
                                    {
                                        (value) => <Form.Label>{value}</Form.Label>
                                    }
                                </FormattedMessage>
                                <Form.Control type="text" name="name"
                                    value={values.name || ''}
                                    onChange={(event) => {
                                        event.target.value = InputHelper.transformToLowerCaseAndUnderscore(event.target.value);
                                        handleChange(event);
                                    }}
                                    isInvalid={!!errors.name || !!this.state.form_errors.name}
                                    onBlur={handleBlur}
                                    disabled={this.state.setting.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name && touched.name && (
                                        <WebsiteMaxRequiredError error={errors.name} />
                                    )}
                                    {this.state.form_errors.name && (
                                        <p>{this.state.form_errors.name}</p>
                                    )}
                                </Form.Control.Feedback>
                                <FormattedMessage id={"CMS.Websites.Settings.overview.title.website.info." + (this.state.setting.id ? 'edit' : 'add')}>
                                    {
                                        (value) => <p className={"input-info mb-2"}>{value}</p>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={"CMS.Websites.Settings.overview.title.website.info.a"}>
                                    {
                                        (value) => <p className="input-info"><strong>{value}</strong></p>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={"CMS.Websites.Settings.overview.title.website.info.b"}>
                                    {
                                        (value) => <p className="input-info">{value}</p>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id={"CMS.Websites.Settings.overview.title.website.info.c"}>
                                    {
                                        (value) => <p className="input-info">{value}</p>
                                    }
                                </FormattedMessage>
                            </Form.Group>
                            <Form.Group>
                                <FormattedMessage id={"CMS.Websites.Settings.Form.value"}>{
                                    (value) => <Form.Label>{value}</Form.Label>
                                }
                                </FormattedMessage>
                                <CustomTextArea
                                    name="value"
                                    onChange={handleChange}
                                    isInvalid={!!errors.value}
                                    onBlur={handleBlur}>
                                    {values.value}
                                </CustomTextArea>
                                <Form.Control.Feedback type="invalid">
                                    {errors.value && touched.value && (
                                        <FormRequiredError field_name={"CMS.Websites.Settings.Form.value"} />
                                    )}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row className={"buttons"}>
                                <Col xs={6}>
                                    <Form.Group>
                                        <FormattedMessage
                                            id={this.state.setting.name ? "CMS.Websites.Settings.Form.save" : "CMS.Websites.Settings.Form.submit"}>
                                            {
                                                value => <Button type={"submit"}
                                                    onClick={() => {
                                                        FormHelper.submitFormErrorToast(this);
                                                        this.setState({
                                                            form_errors: []
                                                        })
                                                    }}>
                                                    {
                                                        this.state.setting.name ?
                                                            null
                                                            :
                                                            <FontAwesomeIcon icon={["far", "plus"]} />
                                                    }
                                                    {value}</Button>
                                            }
                                        </FormattedMessage>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <FormattedMessage id={"CMS.Websites.Translation.Form.cancel"}>
                                            {
                                                value => <Button variant={"secondary"}
                                                    onClick={() => this.props.parent_ref.setState({
                                                        showFormModal: false,
                                                        showEditModal: false,
                                                        editTranslation: null
                                                    })}>{value}</Button>
                                            }
                                        </FormattedMessage>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}

                </Formik>
            </div >
        );
    }

}

WebsiteSettingFormComponent.propTypes = {
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired,
    setting: PropTypes
};
export default withRouter(WebsiteSettingFormComponent);
