import MediaHelper from "../../../helpers/MediaHelper";

class ContentBlockTextFormComponent {
    static async fields_image_before_submit(element, id = null) {
        return new Promise(async (resolve, reject) => {
            let item = element.state.model;

            id = id ? id : element.props.id
            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'content_block_image', 'page', id, null, item.title, JSON.stringify({ min_width: item.image_width, min_height: item.image_height }));
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }
}

export default ContentBlockTextFormComponent;
