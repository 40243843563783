import Product from "../models/product/Product";
import API from "../services/api";
import MediaRepository from "./MediaRepository";

export default class ProductRepository {
    static all = (all) => API.get('/product/all?all=' + (all ? 1 : 0)).then(response => response.data.products.map(product => Product.fromJson(product.product)));
    static get = (id) => API.get('/product/details/' + id).then(respone => respone.data.product);

    static create = (data) => API.post('/product/create', data);
    static update = (data) => API.post('/product/update', data).then(response => response.data);
    static toggle = (id, field) => API.post('/product/toggle', { id: id, field: field })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static import = (file, module) => {
        const data = new FormData();
        data.append('file', file);
        if (module) data.append('module_id', module?.id);

        return new Promise((resolve, reject) => {
            API.post('/product/import', data).then(response => resolve(response)).catch(response => reject(response.response.data.errors))
        });
    };

    static delete = (id) => API.delete('/product/delete?id=' + id);

    static getBackgroundVideos = (id, album) => {
        return new Promise((resolve, reject) => {
            MediaRepository.getAlbum(id, album, 'product')
                .then(response => {
                    let rows = [];

                    if (response.data?.media !== undefined) {
                        response.data.media.forEach(row => rows.push(row))
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }
}