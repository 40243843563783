import FormToastComponent from "../../basics/FormToastComponent";

class MediaFormHelper {

    /**
     * Show error when the form in invalid
     * 
     * @param component
     */
    static submitFormErrorToast(component) {
        component.setState({
            form_errors: []
        });
        if (component.props.parent !== undefined) {
            component.props.parent.setState({
                form_errors: []
            })
        }

        setTimeout(() => {
            if (component.form !== null && component.form !== undefined) {
                let context = component.form.getFormikContext();

                setTimeout(() => {
                    let { errors } = context;

                    if (errors.name) {
                        FormToastComponent.errorTrans('CMS.Form.invalid')
                    }

                    if (errors.media && errors.media_url) {
                        FormToastComponent.errorTrans('CMS.Media.Form.file.error')
                    }
                }, 100)
            }
        })
    }
}

export default MediaFormHelper;
