import React from 'react';
import { FormattedMessage } from 'react-intl';

class ErrorNotFound extends React.Component {

    render() {
        return (
                <FormattedMessage id={'CMS.Error.not_found.title'}>
                    {
                        (value) => <h1>{value}</h1>
                    }
                </FormattedMessage>
        )
    }
}



export default ErrorNotFound;