import React from "react";
import OverviewTableComponent from "../../components/users/OverviewTableComponent";
import { Button, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import MediaOverviewTableComponent from "../../components/media/MediaOverviewTableComponent";
import ModalFormFragment from "./ModalFormFragment";
import MediaAddFormComponent from "../../components/media/MediaAddFormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebsiteOverviewTableComponent from "../../components/websites/WebsiteOverviewTableComponent";
import WebsiteTranslationOverviewTableComponent from "../../components/websites/WebsiteTranslationOverviewTableComponent";
import WebsiteTranslationFormComponent from "../../components/websites/WebsiteTranslationFormComponent";
import EmptyTablePlaceholderComponent from "../../components/basics/EmptyTablePlaceholderComponent";
import PageOverviewTableComponent from "../../components/pages/PageOverviewTableComponent";
import ContentBlockOverviewTableComponent from "../../components/content-blocks/ContentBlockOverviewTableComponent";
import ModuleOverviewTableComponent from "../../components/modules/ModuleOverviewTableComponent";
import TestimonialOverviewTableComponent from "../../components/testimonials/TestimonialOverviewTableComponent";
import EventOverviewTableComponent from "../../components/event/EventOverviewTableComponent";
import FaqOverviewTableComponent from "../../components/faqs/FaqOverviewTableComponent";
import MapOverviewTableComponent from "../../components/maps/MapOverviewTableComponent";
import NewsOverviewTableComponent from "../../components/news/NewsOverviewTableComponent";
import AttendeeOverviewTableComponent from "../../components/attendee/AttendeeOverviewTableComponent";
import BlogOverviewTableComponent from "../../components/blog/BlogOverviewTableComponent";
import CategoryOverviewTableComponent from "../../components/categories/CategoryOverviewTableComponent";
import WebsiteSettingsOverviewTableComponent from "../../components/websites/WebsiteSettingsOverviewTableComponent";
import WebsiteSettingFormComponent from "../../components/websites/WebsiteSettingFormComponent";
import MutateModalComponent from "../../components/basics/table/MutateModalComponent";
import Website from "../../models/website/Website";
import UserRolesEnum from "../../enums/UserRolesEnum";
import CheckPermissions from "../../helpers/CheckPermissions";
import MediaBackgroundVideosAddFormComponent from "../../components/media/MediaBackgroundVideosAddFormComponent";
import MediaAlbumOverviewComponent from "../../components/media/album/MediaAlbumOverviewComponent";
import FormOverviewTableComponent from "../../components/forms/FormOverviewTableComponent";
import UserPermissionsComponent from "../../components/permissions/UserPermissionsComponent";
import Module from "../../models/module/Module";
import NavigationOverviewTableComponent from "../../components/navigation/NavigationOverviewTableComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import Category from "../../models/category/Category";
import { LoaderComponent } from "../../components/basics/layout";
import ProjectOverviewTableComponent from "../../components/project/ProjectOverviewTableComponent";
import ProductOverviewTableComponent from "../../components/product/ProductOverviewTableComponent";
import TeamOverviewTableComponent from "../../components/team/TeamOverviewTableComponent";
import WeblinkOverviewTableComponent from "../../components/weblink/WeblinkOverviewTableComponent";
import FormEntryOverviewTableComponent from "../../components/forms/entries/FormEntryOverviewTableComponent";
import BlogSubscriptionOverviewTableComponent from "../../components/blog/subscription/BlogSubscriptionOverviewTableComponent";
import ClientOverviewTableComponent from "../../components/client/ClientOverviewTableComponent";
import EventRegistrationOverviewTableComponent from "../../components/event/EventRegistrationOverviewTableComponent";
import DynamicFieldValueOverviewTableComponent from "../../components/attendee/dynamicField/DynamicFieldValueOverviewTableComponent";
import DynamicFieldValueFormComponent from "../../components/attendee/dynamicField/DynamicFieldValueFormComponent";
import PeriodOverviewTableComponent from "../../components/event/period/PeriodOverviewTableComponent";

class OverviewFragment extends React.Component {

    constructor(props) {
        super(props);

        this.available_tables = {
            "media": MediaOverviewTableComponent,
            "media-background-videos": MediaAlbumOverviewComponent,
            "modules": ModuleOverviewTableComponent,

            "pages": PageOverviewTableComponent,
            "content-blocks": ContentBlockOverviewTableComponent,

            "navigation": NavigationOverviewTableComponent,

            "forms": FormOverviewTableComponent,
            "form-entries": FormEntryOverviewTableComponent,

            "user": OverviewTableComponent,
            "user_permissions": UserPermissionsComponent,
            "website-settings": WebsiteSettingsOverviewTableComponent,
            "website-translations": WebsiteTranslationOverviewTableComponent,

            "websites": WebsiteOverviewTableComponent,

            "attendees": AttendeeOverviewTableComponent,
            "attendee-dynamic-fields": DynamicFieldValueOverviewTableComponent,
            "blog": BlogOverviewTableComponent,
            "blog-subscription": BlogSubscriptionOverviewTableComponent,
            "categories": CategoryOverviewTableComponent,
            "clients": ClientOverviewTableComponent,
            "events": EventOverviewTableComponent,
            'event-registrations': EventRegistrationOverviewTableComponent,
            'event-periods': PeriodOverviewTableComponent,
            "faqs": FaqOverviewTableComponent,
            "maps": MapOverviewTableComponent,
            "news": NewsOverviewTableComponent,
            "projects": ProjectOverviewTableComponent,
            products: ProductOverviewTableComponent,
            "teams": TeamOverviewTableComponent,
            "testimonials": TestimonialOverviewTableComponent,
            "weblinks": WeblinkOverviewTableComponent
        };

        this.available_modal_forms = {
            "media": MediaAddFormComponent,
            "media-background-videos": MediaBackgroundVideosAddFormComponent,
            "website-translations": WebsiteTranslationFormComponent,
            "website-settings": WebsiteSettingFormComponent,
            "attendee-dynamic-fields": DynamicFieldValueFormComponent
        };

        this.available_mutate_modal_forms = {};

        this.roles = {
            'website-translations': {
                add: UserRolesEnum.RESELLER,
                delete: UserRolesEnum.RESELLER
            },
            pages: {
                add: UserRolesEnum.MANAGER,
                'navigation': UserRolesEnum.MANAGER
            },
            navigation: {
                pages: UserRolesEnum.MANAGER
            },
            'content-blocks': {
                add: UserRolesEnum.RESELLER,
                edit: UserRolesEnum.EDITOR,
                delete: UserRolesEnum.RESELLER
            },
            modules: {
                add: UserRolesEnum.RESELLER,
                delete: UserRolesEnum.RESELLER
            },
            testimonials: {
                add: UserRolesEnum.EDITOR,
                edit: UserRolesEnum.EDITOR,
                delete: UserRolesEnum.EDITOR
            },
            faqs: {
                add: UserRolesEnum.EDITOR,
                edit: UserRolesEnum.EDITOR,
                delete: UserRolesEnum.EDITOR
            },
            categories: {
                add: UserRolesEnum.EDITOR,
                edit: UserRolesEnum.EDITOR,
                delete: UserRolesEnum.EDITOR
            },
            forms: {
                add: UserRolesEnum.RESELLER,
                edit: UserRolesEnum.RESELLER,
                delete: UserRolesEnum.RESELLER
            },
            events: {
                add: UserRolesEnum.EDITOR,
                edit: UserRolesEnum.EDITOR,
                delete: UserRolesEnum.EDITOR
            }
        }

        this.table_ref = null; // React.createRef();

        this.state = {
            rows: null,
            loading: true,
            edit: false,
            showFormModal: false,
            isModal: false,
            customHeaderText: null,
            userCanAdd: !GeneralSettingsHelper.is() && this.checkRole('add'),
            extraButtons: props.extraButtons,
            actionsEnd: props.actionsEnd ? props.actionsEnd : []
        };
    }

    hideAddBtn = () => this.setState({ userCanAdd: false });

    componentDidMount() {
        let promises = [
            this.props.promise
        ];

        if (this.props.settingCheck) {
            promises.push(ModuleRepository.getSettings());
        }

        Promise.all(promises)
            .then(response => {
                let rows = response[0];
                let settings = response[1];
                if (settings !== undefined && settings.data !== undefined) {
                    settings = settings.data.settings;
                }

                let showAddButton = this.state.userCanAdd;
                if (this.props.settingCheck && this.props.settingCheck.indexOf('manager_can_change') > -1) {
                    showAddButton = Category.canChange(this.props.user.roles, settings);
                }

                this.setState({
                    rows: rows,
                    loading: false,
                    userCanAdd: showAddButton
                })
            })
            /*.catch(error => console.error(error))*/;
    }

    checkRole = (type) => !GeneralSettingsHelper.is() && ((this.roles[this.props.component_name] === undefined || this.roles[this.props.component_name][type] === undefined) || CheckPermissions.role(this.props.user.roles, this.roles[this.props.component_name][type]));

    render() {
        if (this.state.loading || this.state.reload) return <LoaderComponent />;

        return (
            <>
                <Col md={12}>
                    <div className={"title overview-title"}>
                        <FormattedMessage id={this.props.title_id}>
                            {(value) => <h1>{value}</h1>}
                        </FormattedMessage>
                        {this.state.userCanAdd && (this.props.add === undefined || this.props.add !== false) ?
                            <FormattedMessage id={this.props.single_title_id}>
                                {(value) =>
                                    <FormattedMessage id="CMS.User.overview.add.btn" values={{ text: value }}>
                                        {(value) => {
                                            if (this.available_modal_forms[this.props.component_name] !== undefined) {
                                                return (
                                                    <Button onClick={() => this.setState({ showFormModal: true })}>
                                                        <FontAwesomeIcon className={"mr-2"} icon={['fas', 'plus']} />
                                                        <span>{value}</span>
                                                    </Button>
                                                )
                                            } else if (this.available_mutate_modal_forms[this.props.component_name] !== undefined) {
                                                return (
                                                    <Button onClick={(e) => MutateModalComponent.openModal(e, this)}>
                                                        <FontAwesomeIcon className={"mr-2"} icon={['fas', 'plus']} />
                                                        <span>{value}</span>
                                                    </Button>
                                                )
                                            } else {
                                                return (
                                                    <Link to={this.props.add_link ? this.props.add_link : 'add'}>
                                                        <Button>
                                                            <FontAwesomeIcon className="mr-2" icon={['fas', 'plus']} />
                                                            <span>{value}</span>
                                                        </Button>
                                                    </Link>
                                                )
                                            }
                                        }}
                                    </FormattedMessage>
                                }
                            </FormattedMessage> : null}
                        {this.state.extraButtons.map(btn => {
                            if (!this.checkRole(btn.role)) return null;

                            if (btn.path) {
                                return <FormattedMessage id={btn.text} key={btn.text}>
                                    {value => <Link className="btn btn-link" to={btn.path}>{value}</Link>}
                                </FormattedMessage>
                            } else {
                                return <FormattedMessage id={btn.text} key={btn.text}>
                                    {value => <Button {...btn}>{value}</Button>}
                                </FormattedMessage>
                            }
                        })}
                        {this.props.goBack ?
                            <FormattedMessage id={"CMS.User.overview.back"}>
                                {value =>
                                    <Link to="#" className={"go-back"} onClick={() => this.props.history.push(this.props.goBack)}>
                                        <FontAwesomeIcon icon={['fas', 'chevron-left']} />{value}
                                    </Link>
                                }
                            </FormattedMessage>
                            : null}

                        {this.state.actionsEnd.length > 0 && (
                            <div className="ml-3">
                                {this.state.actionsEnd.map(btn => btn)}
                            </div>
                        )}
                    </div>

                    {this.props.text_id && (
                        <FormattedMessage id={this.props.text_id}>
                            {value => <p className="overview-text">{value}</p>}
                        </FormattedMessage>
                    )}
                </Col >
                {this.content()}
            </>
        )
    }

    content = () => (
        <>
            <Col md={12}>
                {this.state.rows.length === 0 && <EmptyTablePlaceholderComponent typeId={this.props.title_id} />}

                <div className={this.state.rows.length === 0 ? 'visually-hidden' : ''}>
                    {this.table()}
                </div>
            </Col>
            {this.formModal()}
        </>
    )

    formModal = () => {
        const MutateForm = this.available_mutate_modal_forms[this.props.component_name];
        if (MutateForm !== undefined) {
            return <MutateModalComponent title={this.props.modal_title + ".add"} parent={this}>
                {this.form()}
            </MutateModalComponent>
        } else {
            let Form = this.available_modal_forms[this.props.component_name];
            if (Form) {
                return (
                    <ModalFormFragment
                        title_id={this.state.customHeaderText != null ? this.state.customHeaderText : this.props.modal_title}
                        show={this.state.showFormModal}
                        onHide={() => this.setState({ showFormModal: false })}
                    >
                        {this.form()}
                    </ModalFormFragment>
                )
            }

            return null;
        }
    }

    hardReload = () => {
        if (this.props.parent !== undefined) {
            this.props.parent.setState({ reload: true, module: Module.get() }, () => this.props.parent.setState({ reload: false }))
        }
    }

    table = () => {
        const Table = this.available_tables[this.props.component_name];
        return <Table title_id={this.props.title_id} rows={this.state.rows}
            type={this.props.slug}
            overview_title={this.props.overview_title}
            delete_title_id={this.props.delete_title_id} delete_text_id={this.props.delete_text_id}
            delete_func={this.props.delete_promise}
            delete_customer_func={this.props.delete_customer_promise}
            ref={ref => this.table_ref = ref}
            id={Website.get() !== undefined ? Website.get() : null}
            parent={this}
            user={this.props.user}
        />
    }

    form = () => {
        let Form = this.available_modal_forms[this.props.component_name];
        if (Form === undefined) {
            Form = this.available_mutate_modal_forms[this.props.component_name];
        }

        if (Form === undefined) return null;

        return <Form table_ref={this.table_ref} parent_ref={this} onClose={() => MutateModalComponent.closeModal(this)} model={this.props.model} />
    }
}

OverviewFragment.defaultProps = {
    extraButtons: []
}

export default withRouter(OverviewFragment);