import React from "react";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import Website from "../../models/website/Website";
import { FormRepository, WebsiteRepository } from "../../repository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import FormToastComponent from "../basics/FormToastComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { FormattedMessage } from "react-intl";
import Application from "../../helpers/Application";
import FormattedMessageString from "../basics/FormattedMessageString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableEditActionComponent } from "../basics/table";
import ToolTipLink from "../basics/ToolTipLink";
import moment from "moment";
import { LoaderComponent } from "../basics/layout";
import TableToggleActiveActionComponent from "../basics/table/TableToggleActiveActionComponent";
import { JsonHelper } from "../../helpers";

class FormOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            website: null,
            isLoading: true,
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }))
    }

    columns = (index) => [
        {
            dataField: 'id',
            hidden: true
        },
        {
            dataField: 'title',
            text: <FormattedMessageString id="CMS.Form.overview.table.name" />,
            sort: true,
            isDummyField: true,
            formatter: (cellContent, row) => row.form.contents[index] ? row.form.contents[index].title : ''
        },
        {
            dataField: 'pages',
            text: <FormattedMessageString id="CMS.Form.overview.table.pages" />,
            isDummyField: true,
            formatter: (cellContent, row) => row.pages?.join(', ')
        },
        {
            dataField: 'recipients',
            text: <FormattedMessageString id="CMS.Form.overview.table.recipients" />,
            isDummyField: true,
            formatter: (cellContent, row) => row.form.mail_admin_recipient_email ? JsonHelper.tryParse(row.form.mail_admin_recipient_email).join(', ') : '-'
        },
        {
            dataField: 'last_entry',
            text: <FormattedMessageString id="CMS.Form.overview.table.last_entry" />,
            isDummyField: true,
            formatter: (cellContent, row) => {
                return row.entries_data[row.form.contents[index]?.language]?.last_entry ? moment(row.entries_data[row.form.contents[index].language].last_entry).format('DD/MM/YYYY HH:mm:ss') : '-'
            }
        },
        {
            dataField: 'views',
            text: '',
            sort: false,
            isDummyField: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '1%' };
            },
            formatter: (cellContent, row) => {
                let contents = row.form.contents[index];
                return (
                    <div className="actions">
                        {
                            contents && !GeneralSettingsHelper.is() ?
                                <ToolTipLink title="CMS.Form.overview.tooltip.entries" className={!CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ? 'disabled link' : 'link'}
                                    to={'/forms/entries/' + row.id + '/' + (row.form.contents[index] !== undefined ? row.form.contents[index].language : '')}
                                >
                                    <FontAwesomeIcon icon={['fas', 'fa-chart-column']} className="mr-1" />
                                    {row.form.contents[index] !== undefined ? row.entries_data[row.form.contents[index].language].count : 0}
                                </ToolTipLink>
                                : null
                        }
                    </div>
                )
            }
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: <FormattedMessage id="CMS.Table.actions" />,
            hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
            sort: false,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
            formatter: (cellContent, row) => (
                <div className="actions">
                    <ToolTipLink title="CMS.Form.overview.tooltip.mail-settings"
                        className={!CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ? 'disabled' : null}
                        to={'/forms/mail/' + row.id + '/' + (row.form.contents[index] !== undefined ? row.form.contents[index].language : '')}
                    >
                        <FontAwesomeIcon className={row.form.mail_admin_send || row.form.mail_visitor_send ? 'succcess' : 'danger'} icon={['far', 'fa-envelopes-bulk']} />
                    </ToolTipLink>

                    <TableToggleActiveActionComponent active={row.form.active} onClick={() => this.toggleActive(row)} disabled={!(CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is())} />
                    <TableEditActionComponent id={row.form.id} custom={row.form.contents[index] !== undefined ? '/' + row.form.contents[index].language : '/'} disabled={!(CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is())} />
                    <TableDeleteActionComponent row={row} parent={this} disabled={!((CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER)) && !GeneralSettingsHelper.is())} />
                </div>
            )
        }
    ];

    toggleActive = (_row) => FormRepository.toggle(_row.form.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.form.id === _row.form.id) {
                    let form = row.form;
                    form.active = response;
                    return {
                        ...row,
                        form: form
                    }
                }
                return row;
            });

            this.setState(curr => ({ ...curr, rows: newRows, isLoading: true }), () => {
                this.setState({ isLoading: false })
            });
        })
        .catch(() => FormToastComponent.errorTrans('CMS.Form.overview.toggle.failed'))

    updateData = () => FormRepository.getRows().then(response => this.props.parent.setState({ rows: response }))

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-Forms"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                <div className={"custom-data-table Forms"}>
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={false}
                                        title={this.props.title_id} type={this.props.type}
                                        subRows={false}
                                        sort={{ dataField: 'title', order: 'asc' }} />
                                    <DeleteModalComponent parent={this} />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }
}

export default FormOverviewTableComponent;