import * as Yup from 'yup';
import PageContent from "./PageContent";
import { JsonHelper } from '../../helpers';

export default class Page {
    id;
    type;
    modules;
    parent;
    settings = {};
    website;
    content = [];

    static schema_add = Yup.object({
        name: Yup.string().required('CMS.Pages.Form.name').max(50, 'CMS.Pages.Form.name|50|max'),
        type: Yup.mixed().required('CMS.Pages.Form.type'),
        modules: Yup.mixed().when('type', {
            is: (type) => type && type.indexOf('module') > -1,
            then: Yup.mixed().required('CMS.Pages.Form.modules|select')
        }),
        settings: Yup.object({
            "eyecatcher_width": Yup.string().when("show_eyecatchers", (show, schema) => {
                return show ? schema.required('eyecatcher_width').matches(/^[0-9]+$/, "eyecatcher_width.number") : schema;
            }),
            "eyecatcher_height": Yup.string().when("show_eyecatchers", (show, schema) => {
                return show ? schema.required('eyecatcher_height').matches(/^[0-9]+$/, "eyecatcher_height.number") : schema;
            })
        }).when("type", {
            is: (type) => type === "text/image" || type === "text/image/module",
            then: Yup.object().shape({
                image_width: Yup.string().required('image_width').matches(/^[0-9]+$/, "image_width.number"),
                image_height: Yup.string().required('image_height').matches(/^[0-9]+$/, "image_height.number")
            })
        })
    });

    static navigation_edit = (navigation) => {
        let fields = {};

        if (navigation.page.as_button_navigation) {
            fields['page'] = Yup.object({ button_classes: Yup.string().required('CMS.Navigation.Edit.button_classes').max(30, 'CMS.Navigation.Edit.button_classes|30|max') })
        }

        return Yup.object(fields);
    }

    static fromJson(json) {
        let page = new Page();
        page.id = json.id;
        page.type = json.type;
        page.modules = json.modules;
        if (page.modules) {
            page.modules = JsonHelper.tryParse(page.modules);
        }

        page.parent = json.parent;
        page.website = json.website;

        json.settings = json.settings === "[]" ? "{}" : json.settings
        page.settings = JsonHelper.tryParse(json.settings);
        page.content = json.contents ? json.contents.map(function (content) {
            return PageContent.fromJson(content);
        }) : [];
        page.navigation = json.navigation === "[]" ? "{}" : json.navigation;
        return page;
    }

}
