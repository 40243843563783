import API from "../services/api";
import MediaRepository from "./MediaRepository";

export default class ProjectRepository {
    static create = (data) => API.post('/project/create', data);

    static update = (data) => API.post('/project/update', data).then(response => response.data);

    static updateTree = (data) => API.post('/project/update/order', { tree: data });

    static delete = (id) => API.delete('/project/delete?id=' + id);

    static all = (all) => API.get('/project/all?all=' + (all ? 1 : 0)).then(response => response.data.projects);

    static get = (id) => API.get('/project/details/' + id).then(response => response.data);

    static toggle = (id) => API.post('/project/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static getBackgroundVideos = (id, album) => {
        return new Promise((resolve, reject) => {
            MediaRepository.getAlbum(id, album, 'project')
                .then(response => {
                    let rows = [];
                    let data = response['data'];

                    if (data && data.media !== undefined) {
                        data.media.forEach((row) => {
                            rows.push(row)
                        })
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }
}