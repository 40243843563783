import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class MediaOverviewTableCustomToggleComponent extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.props.parent.setState({
            toggle: !this.props.parent.state.toggle
        }, () => {
            this.props.onColumnToggle("media_url");
            this.props.onColumnToggle("file_name");
            this.props.onColumnToggle("type");
            this.props.onColumnToggle("size");
            this.props.onColumnToggle("upload_date");


            this.props.parent.setState({
                toggles: this.props.toggles
            }, () => {
                this.props.parent.setState({
                    lightboxKey: Math.random()
                })
            })
        })
    }

    render() {
        return (
            <div className={'toggle-show-image'} data-toggle={"buttons"}>
                <button
                    type={"button"}
                    key={"media_url_list"}
                    disabled={!this.props.toggles["media_url"]}
                    data-toggle={"button"}
                    onClick={this.toggle}
                >
                    <FontAwesomeIcon icon={['fas', 'list']}/>
                </button>
                <button
                    type={"button"}
                    key={"media_url_th"}
                    disabled={this.props.toggles["media_url"]}
                    data-toggle={"button"}
                    onClick={this.toggle}
                >
                    <FontAwesomeIcon icon={['fas', 'th']}/>
                </button>
            </div>
        );
    }
}

MediaOverviewTableCustomToggleComponent.propTypes = {
    parent: PropTypes.object.isRequired
};
export default MediaOverviewTableCustomToggleComponent;