import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import WebsiteRepository from "../../repository/WebsiteRepository";
import FormToastComponent from "../basics/FormToastComponent";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import Select from "react-select";
import CountryHelper from "../../helpers/CountryHelper";
import Website from "../../models/website/Website";
import ReactTooltip from "react-tooltip";

class WebsiteSubTableUserOverviewComponent extends React.Component {
    constructor(props) {
        super(props);

        let rows = [];
        props.rows.forEach(row => {
            if (row.id !== 1) {
                rows.push(row);
            }
        })

        this.state = {
            rows: rows,
            clickedEditLanguageCell: null
        };

        this.languages = CountryHelper.getLanguages();

        this.columns = [
            {
                dataField: 'id',
                text: 'Id',
                hidden: true
            },
            {
                dataField: 'name',
                text: "Name",
                sort: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className={"website-link"}>
                            <a href={row.url} target="_blank" rel="noopener noreferrer">
                                <div className={"arrow"}>
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                </div>
                                <div className={"text"}>
                                    {row.name}
                                </div>
                            </a>
                        </div>
                    )
                },
                style: {
                    width: '12.4%'
                }
            },
            {
                dataField: 'url',
                text: 'Url',
                style: {
                    width: '48.8%'
                },
                formatter: (cellContent, row) => {
                    if (!row) return;

                    return (
                        <div className={"website-url"}>
                            {row.api_key && (
                                <>
                                    <FontAwesomeIcon icon={['far', 'info-circle']} data-tip data-for={'website-url-' + row.id} className="mr-1" />
                                    <ReactTooltip id={'website-url-' + row.id} type={"success"} effect={"solid"} className={"custom-tooltip"}>
                                        <strong>API</strong> {row.api_key}
                                    </ReactTooltip>
                                </>
                            )}
                            <span> {row.url}</span>
                        </div>
                    );
                }
            },
            {
                dataField: 'languages',
                text: 'Languages',
                formatter: (cellContent, row) => {
                    if (row.editLanguageCell) {
                        return <Select
                            options={this.languages.filter(language => !row.languages.includes(language.value))}
                            onChange={(event) => {
                                WebsiteRepository.addLanguage(event.value, row.id)
                                    .then(response => {
                                        this.addLanguage(event.value, row);
                                    })
                                    .catch(error => console.error(error));
                            }}
                            className={'react-select-container'}
                            classNamePrefix="react-select"
                            onMenuClose={(event) => {
                                this.toggleLanguage(false, row);
                            }}
                        />
                    } else {
                        return this.languageCell(row);
                    }
                },
                style: {
                    width: '20.1%'
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: "Acties",
                formatter: (cellContent, row) => {
                    return (
                        <div className="actions">
                            <Link className={"download disabled"} to={"/"}>
                                <FontAwesomeIcon icon={['far', 'download']} />
                            </Link>
                            {
                                row.active ?
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)}
                                        className={"active"}><FontAwesomeIcon icon={['fas', 'check']} /></Link>
                                    :
                                    <Link to="#" onClick={(event) => this.toggleActive(event, row)}
                                        className={"inactive"}><FontAwesomeIcon icon={['far', 'times']} /></Link>

                            }
                            <Link className={"edit"} to={"/websites/edit/" + row.id}>
                                <FontAwesomeIcon icon={['fas', 'edit']} />
                            </Link>
                            <Link to="#"
                                className={"delete"} onClick={(event) => {
                                    this.openDeleteModal(event, row)
                                }}><FontAwesomeIcon icon={['far', 'trash-alt']} /></Link>
                        </div>
                    )
                },
                style: {
                    width: "18.7%"
                }
            }
        ];


        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.closeDeleteLanguageModal = this.closeDeleteLanguageModal.bind(this);
    }

    openDeleteModal(event, row) {
        event.preventDefault();
        this.setState({
            showDeleteModal: true,
            deleteRow: row
        });
    };

    closeDeleteModal(event) {
        this.setState({
            showDeleteModal: false,
            deleteRow: null
        })
    }

    deleteRow(event, _row) {
        event.preventDefault();
        WebsiteRepository.delete(_row.id)
            .then(response => {
                this.setState({
                    rows: this.state.rows.filter((row) => {
                        return _row.id !== row.id;
                    })
                }, () => {
                    this.closeDeleteModal();
                    if (_row.id === Website.get()) {
                        Website.remove();
                        window.location.reload();
                    }
                })
            })
            .catch(error => {
                FormToastComponent.errorTrans('CMS.Table.delete.error');
            });
    }

    toggleActive(event, _row) {
        WebsiteRepository.toggle(_row.id)
            .then(response => {
                let newRows = [...this.state.rows];
                newRows = newRows.map((row) => {
                    if (row.id === _row.id) {
                        return {
                            ...row,
                            active: response
                        };
                    }
                    return row;
                });
                this.setState(curr => ({ ...curr, rows: newRows }));
            })
            .catch(error => {
                FormToastComponent.errorTrans('CMS.Websites.overview.toggle.failed')
            })
    }


    languageCell(row) {
        let counter = 0;
        return (
            <div className={"languages"}>
                {
                    row.languages.map(language => {
                        counter++;
                        return (
                            <div className={"language"}>
                                <p>
                                    {language}
                                    {
                                        counter !== 1 ? <Link to="#"
                                            onClick={event => this.openDeleteLanguageModal(event, row, language)}>x</Link> :
                                            null
                                    }
                                </p>
                            </div>
                        )
                    })
                }
                <div className={"language-add"}>
                    <p>
                        <Link to="#" onClick={event => this.toggleLanguage(true, row)}>+</Link>
                    </p>
                </div>
            </div>
        )
    }

    addLanguage(value, row) {
        let newRows = [...this.state.rows];
        newRows = newRows.map((d) => {
            if (d.id === row.id) {
                return {
                    ...d,
                    languages: [...row.languages, value],
                    editLanguageCell: false
                };
            }
            return d;
        });
        this.setState(curr => ({ ...curr, rows: newRows }));
    }

    removeLanguage(event, row, language) {
        WebsiteRepository.deleteLanguage(row.id, language)
            .then(response => {
                let newRows = [...this.state.rows];
                let newLanguages = [...row.languages];
                newRows = newRows.map((d) => {
                    if (d.id === row.id) {
                        return {
                            ...d,
                            languages: newLanguages.filter(e => e !== language),
                        };
                    }
                    return d;
                });
                this.setState(curr => ({ ...curr, rows: newRows }), () => {
                    this.closeDeleteLanguageModal();
                });
            })
            .catch(reason => {
                if (reason['data']['error']) {
                    FormToastComponent.error(reason['data']['error']);
                } else {
                    FormToastComponent.error(reason);

                }
            })
    }

    openDeleteLanguageModal(event, row, language) {
        event.preventDefault();
        this.setState({
            clickedDeleteLanguage: [
                row,
                language
            ]
        })
    }

    closeDeleteLanguageModal() {
        this.setState({
            clickedDeleteLanguage: null
        })
    }

    toggleLanguage(bool, row) {
        let newRows = [...this.state.rows];
        newRows = newRows.map((d) => {
            if (d.id === row.id) {
                return {
                    ...d,
                    editLanguageCell: bool
                };
            }
            return d;
        });
        this.setState(curr => ({ ...curr, rows: newRows }));
    }

    render() {
        return (
            <div className={"custom-sub-data-table website"}>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.rows !== undefined ? this.state.rows : []}
                    columns={this.columns}
                    bootstrap4
                >
                    {
                        props => (
                            <BootstrapTable
                                {...props.baseProps}
                            />
                        )
                    }
                </ToolkitProvider>

                <ModalFormFragment title_id={"CMS.Websites.overview.delete.title"} show={this.state.showDeleteModal}
                    onHide={this.closeDeleteModal} is_delete={true}>
                    <DeleteConfirmComponent onHide={this.closeDeleteModal}
                        delete_text_id={"CMS.Websites.overview.delete.text"}
                        delete_func={(event) => {
                            this.deleteRow(event, this.state.deleteRow)
                        }} />
                </ModalFormFragment>

                <ModalFormFragment title_id={"CMS.Websites.overview.delete.language.title"} show={this.state.clickedDeleteLanguage != null}
                    onHide={this.closeDeleteLanguageModal} is_delete={true}>
                    <DeleteConfirmComponent delete_text_id={"CMS.Websites.overview.delete.language"} onHide={this.closeDeleteLanguageModal} delete_func={(event) => {
                        this.removeLanguage(event, this.state.clickedDeleteLanguage[0], this.state.clickedDeleteLanguage[1])
                    }} />
                </ModalFormFragment>

            </div>
        )
    }
}

WebsiteSubTableUserOverviewComponent.propTypes = {
    rows: PropTypes.array.isRequired,
};
export default WebsiteSubTableUserOverviewComponent;

/*
988,22
89

name:
143 - 20 = 123
12,4

url:
482
48,8

language:
199
20.1

actions:
18,7
 */
