export default class NewsContent {
    id;
    title;
    language;
    summary;
    content;
    seo_title;
    seo_tags = [];
    seo_description;

    static fromJson = (json) => {
        let content = new NewsContent();

        content.id = json.id;
        content.language = json.language;
        content.title = json.title;
        content.summary = json.summary;
        content.content = json.content != null ? json.content : '{}';

        content.seo_title = json.seo_title;
        content.seo_tags = json.seo_tags;
        content.seo_description = json.seo_description;

        return content;
    }
}