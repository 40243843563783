import MediaRepository from "./MediaRepository";
import API from "../services/api";

export default class BlogRepository {
    static all = (all) => API.get('/blog/all?all=' + (all ? 1 : 0)).then(response => response.data.blog);
    static get = (id) => API.get('/blog/details/' + id).then(respone => respone.data.blog);

    static create = (data) => API.post('/blog/create', data);
    static update = (data) => API.post('/blog/update', data).then(response => response.data);
    static updateContent = (data) => API.post('/blog/content/update', data);
    static delete = (id) => API.delete('/blog/delete?id=' + id);
    static toggle = (id) => API.post('/blog/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static getBackgroundVideos = (id, album) => {
        return new Promise((resolve, reject) => {
            MediaRepository.getAlbum(id, album, 'blog')
                .then(response => {
                    let rows = [];

                    if (response.data?.media !== undefined) {
                        response.data.media.forEach(row => rows.push(row))
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }

    static subscriptions = () => API.get('/blog/subscription/all').then(response => response.data.subscriptions);
    static deleteSubscription = (id) => API.delete('/blog/subscription/delete?id=' + id);
}
