import React from 'react';
import Card from "react-bootstrap/Card";
import PolicyComponent from "../../components/basics/PolicyComponent";
import ResetPasswordFormComponent from "../../components/authentication/ResetPasswordFormComponent";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import User from "../../models/user/User";

class ResetPasswordFormFragment extends React.Component {
    constructor(props) {
        super(props);
        if (props.email != null) {
            this.params = props;
        } else {
            const { match: { params } } = this.props;
            this.params = params;
        }
    }

    componentDidMount() {
        if (this.props.existing_password === undefined) new User({ email: localStorage.getItem('user.email'), token: this.params.token }).set();
    }


    render() {
        return (
            <>
                <Card className="forgot-password green">
                    <Card.Header>
                        <h1><FormattedMessage id="Auth.reset.password.form.title" /></h1>
                    </Card.Header>
                    <Card.Body>
                        <ResetPasswordFormComponent logged_in={this.props.email != null} existing_password={this.props.existing_password} {...this.params} />
                    </Card.Body>
                </Card>
                {this.props.policy ? <PolicyComponent /> : ''}
            </>
        )
    }
}

ResetPasswordFormFragment.propTypes = {
    policy: PropTypes.bool.isRequired,
    existing_password: PropTypes.bool
};
export default ResetPasswordFormFragment;