import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import ProjectFormError from './errors/ProjectFormError';
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";

class ProjectCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_categories: props.values.settings.use_categories,
            show_client: props.values.settings.show_client,
            show_url: props.values.settings.show_url,
            show_summary: props.values.settings.show_summary,
            show_content: props.values.settings.show_content,
            show_cover_image: props.values.settings.show_cover_image,
            cover_image_width: props.values.settings.cover_image_width,
            cover_image_height: props.values.settings.cover_image_height,
            need_home_img: props.values.settings.need_home_img,
            home_img_width: props.values.settings.home_img_width,
            home_img_height: props.values.settings.home_img_height,
            show_photoalbum: props.values.settings.show_photoalbum,
            show_videoalbum: props.values.settings.show_videoalbum,
            modules: [],
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => this.setState({
                modules: result.filter(module => module.module.type === ModuleTypeEnum.CATEGORY),
                use_categories: this.props.values.settings.use_categories
            }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })

        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Project.Form.settings"}>
                            {
                                (value) => <Form.Label>{value}</Form.Label>
                            }
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-categories"
                                    label="use_categories"
                                    name={"settings.use_categories"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_categories: !this.state.use_categories }, () => {
                                                delete this.props.values.settings.module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {
                                this.state.use_categories ?
                                    <>
                                     <Form.Group className="subsettings">
                                     <Row>
                                        <Col md={12}>
                                            <InputGroup className="large mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">
                                                        module
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select"
                                                    aria-describedby="module"
                                                    onChange={this.onChange}
                                                    name="settings.module"
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.module}
                                                    value={this.props.values.settings.module}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.modules.map(module => {
                                                        let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                        return <option value={module.id}>{moduleContent.title}</option>
                                                    })}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.module && (
                                                        <ProjectFormError error={this.props.errors.settings.module} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                        </Row>
                                        </Form.Group>
                                    </>
                                    : null
                            }

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.use_categories"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show client */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-client"
                                    label="show_client"
                                    name={"settings.show_client"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_client: !this.state.show_client }))}
                                    className="mb-1"
                                    checked={this.state.show_client}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_client"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show url */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-url"
                                    label="show_url"
                                    name={"settings.show_url"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_url: !this.state.show_url }))}
                                    className="mb-1"
                                    checked={this.state.show_url}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_url"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show summary */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-summary"
                                    label="show_summary"
                                    name={"settings.show_summary"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({
                                                show_summary: !this.state.show_summary
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_summary}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_summary"}>
                                    {
                                        (values) => <p className="input-info">{values}</p>
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show content */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-content"
                                    label="show_content"
                                    name={"settings.show_content"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_content: !this.state.show_content }))}
                                    className="mb-1"
                                    checked={this.state.show_content}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_content"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-cover-image"
                                    label="show_cover_image"
                                    name={"settings.show_cover_image"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({
                                                show_cover_image: !this.state.show_cover_image,
                                                need_home_img: false
                                            }, () => {
                                                delete this.props.values.settings.cover_image_width;
                                                delete this.props.values.settings.cover_image_height;
                                                this.props.values.settings.need_home_img = false;
                                                delete this.props.values.settings.home_img_width;
                                                delete this.props.values.settings.home_img_height;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_cover_image ? "checked" : ""}
                                />
                            </Col>

                            {
                                this.state.show_cover_image ?
                                    <>
                                     <Form.Group className="subsettings">
                                     <Row>
                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherWidth">cover_image_width</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="cover_image_width"
                                                    type={"text"}
                                                    name={"settings.cover_image_width"}
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_width}
                                                    value={this.props.values.settings.cover_image_width}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.cover_image_width && (
                                                        <ProjectFormError error={this.props.errors.settings.cover_image_width} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        id="inputGroupPrependEyecatcherHeight">cover_image_height</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="cover_image_height"
                                                    type={"text"}
                                                    name={"settings.cover_image_height"}
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_height}
                                                    value={this.props.values.settings.cover_image_height}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.cover_image_height && (
                                                        <ProjectFormError error={this.props.errors.settings.cover_image_height} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                       
                                                <Col xs={12}>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch-needs-home-image"
                                                        label="need_home_img"
                                                        name={"settings.need_home_img"}
                                                        onChange={(event) => {
                                                            this.onChange(event, () => {
                                                                this.setState({
                                                                    need_home_img: !this.state.need_home_img
                                                                }, () => {
                                                                    delete this.props.values.settings.home_img_width;
                                                                    delete this.props.values.settings.home_img_height;
                                                                })
                                                            });
                                                        }}
                                                        className="mb-1"
                                                        checked={this.state.need_home_img ? "checked" : ""}
                                                    />
                                                    </Col>

                                                    {this.state.need_home_img && (
                                                             <Form.Group className="subsettings sub">
                                                        <Row>
                                                            <Col md={6}>
                                                                <InputGroup className="mb-1">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text
                                                                            id="inputGroupPrependEyecatcherWidth">home_img_width</InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        aria-describedby="home_img_width"
                                                                        type={"text"}
                                                                        name={"settings.home_img_width"}
                                                                        onChange={this.onChange}
                                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_width}
                                                                        value={this.props.values.settings.home_img_width}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {this.props.errors.settings != null && this.props.errors.settings.home_img_width && (
                                                                            <ProjectFormError error={this.props.errors.settings.home_img_width} />
                                                                        )}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>

                                                            <Col md={6}>
                                                                <InputGroup className="mb-1">
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text
                                                                            id="inputGroupPrependEyecatcherHeight">home_img_height</InputGroup.Text>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control
                                                                        aria-describedby="home_img_height"
                                                                        type={"text"}
                                                                        name={"settings.home_img_height"}
                                                                        onChange={this.onChange}
                                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_height}
                                                                        value={this.props.values.settings.home_img_height}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {this.props.errors.settings != null && this.props.errors.settings.home_img_height && (
                                                                            <ProjectFormError error={this.props.errors.settings.home_img_height} />
                                                                        )}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        </Form.Group>
                                                    )}
                                                </Row>
                                                </Form.Group>
                                           
                                       
                                    </>
                                    : null
                            }

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_cover_image"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show photo_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-photoalbum"
                                    label="show_photoalbum"
                                    name={"settings.show_photoalbum"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ show_photoalbum: !this.state.show_photoalbum })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.show_photoalbum}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_photoalbum"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show video_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-show-videoalbum"
                                    label="show_videoalbum"
                                    name={"settings.show_videoalbum"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_videoalbum: !this.state.show_videoalbum }))}
                                    className="mb-1"
                                    checked={this.state.show_videoalbum}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.project.show_videoalbum"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title={"CMS.Project.Form.setting.delete.title"} text={"CMS.Project.Form.setting.delete.text"}
                    buttons={{
                        confirm: { text: 'Default.confirm', icon: 'check' }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default ProjectCustomSettingsFormComponent;