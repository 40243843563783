let UserTypeEnum = {
    WIZARD: {
        key: "wizard",
        value_id: "CMS.Wizard.overview.single.title"
    },
    RESELLER: {
        key: 'reseller',
        value_id: "CMS.Reseller.overview.single.title"
    },
    MANAGER: {
        key: 'manager',
        value_id: "CMS.Manager.overview.single.title"
    },
    EDITOR: {
        key: 'editor',
        value_id: "CMS.Editor.overview.single.title"
    }
};

export default UserTypeEnum;