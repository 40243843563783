import { Application, CheckPermissions } from "../../helpers";
import { AuthenticationRepository, UserRepository } from "../../repository";
import Module from "../module/Module";
import Contact from "./Contact";
import UserRole from "./UserRole";

import configureStore from '../../store/store';
import UserRolesEnum from "../../enums/UserRolesEnum";
const store = configureStore();

class User {
    address;
    contact_info;
    email;
    language;
    permissions = [];
    roles = [];
    token;

    constructor(data = {}) {
        this.email = data.email;
        this.language = data.language ? data.language : 'nl';
        this.token = data.token;
    }

    static transform = (data) => {
        let item = new User();

        if (!data) return item;

        item.address = data.address;

        // item.contact_info = data.contact_info;
        item.contact_info = new Contact();
        if (data.contact_info != null) {
            let firstname = data.contact_info['firstname'] !== undefined ? data.contact_info['firstname'] : data.contact_info['first_name'];
            let lastname = data.contact_info['lastname'] !== undefined ? data.contact_info['lastname'] : data.contact_info['last_name'];
            item.contact_info = new Contact(firstname, lastname, data.contact_info['phone'], data.contact_info['mobile'], data.contact_info['alternative_email'], data.contact_info['company_name'], data.contact_info['tax']);
        }

        item.email = data.email;
        item.language = data.language;
        item.permissions = data.permissions;
        // item.roles = data.roles;

        data.roles.forEach(role => {
            let user_role = new UserRole();

            if (role.pivot) {
                user_role.name = role.pivot.role;
                user_role.website_id = role.pivot.website;
            } else {
                user_role.name = role.name;
                user_role.website_id = role.website_id;
            }

            item.roles.push(user_role)
        });

        item.token = data.token;

        return item;
    }

    set() {
        User.logout();

        localStorage.setItem('user.email', this.email);
        localStorage.setItem('user.token', this.token);

        if (this.language) {
            localStorage.setItem('language', this.language);
        }

        store.dispatch({ type: 'USER_SET', payload: this });
    }

    getDetails() {
        return new Promise((resolve, reject) => {
            UserRepository.getUser()
                .then(response => {
                    let user = response.data.user;

                    if (user != null) {
                        this.contact_info = user['user']['contact_info'] != null ? new Contact(user['user']['contact_info']['firstname'], user['user']['contact_info']['lastname'], user['user']['contact_info']['phone'], user['user']['contact_info']['mobile'], user['user']['contact_info']['alternative_email'], user['user']['contact_info']['company_name'], user['user']['contact_info']['tax']) : new Contact();
                        this.created_at = user['created_at'];
                        this.updated_at = user['updated_at'];
                        this.language = user['language'];

                        let context = this;
                        user.roles.forEach(function (role) {
                            let user_role = new UserRole();
                            user_role.name = role.pivot.role;
                            user_role.website_id = role.pivot.website;
                            context.roles.push(user_role)
                        });

                        resolve(this)
                    } else if (response.data['use_one_time_password']) {
                        resolve(response.data);
                    }
                })
                .catch(reason => reject(reason));
        });

    }

    static get = () => User.transform(store.getState().user);

    static logout() {
        AuthenticationRepository.logout();

        store.dispatch({ type: 'USER_SET', payload: null });

        localStorage.removeItem('user.email');
        localStorage.removeItem('user.token');
        localStorage.removeItem('website.id');
        localStorage.removeItem('language');
        localStorage.removeItem('customer.id');

        Module.remove();

        this.removeTwoFactor();

        Application.clear();
    }

    static addTwoFactor(auth_passed, auth_time) {
        sessionStorage.setItem('auth_passed', auth_passed);
        sessionStorage.setItem('auth_time', auth_time);
    }

    static removeTwoFactor() {
        sessionStorage.removeItem('auth_passed');
        sessionStorage.removeItem('auth_time');
    }

    static redirectToLogin() {
        User.logout();
    }

    static setCustomer = (id) => localStorage.setItem('customer.id', id);
    static getCustomer = () => localStorage.getItem('customer.id');

    guardModuleAccess = (module, moduleId, action) => {
        if (CheckPermissions.role(this.roles, UserRolesEnum.MANAGER)) return;

        ['module', 'page', 'form', 'website', 'manager', 'customer'].forEach(type => {
            if (module.indexOf(type) <= -1) return;

            let callback = (permission) => permission.type === type + '_permission';
            if (type === 'website' && action === 'translations') {
                callback = (permission) => permission.type = 'module_permission' && permission.model_string_type === 'translation';
            } else if (moduleId) {
                callback = (permission) => permission.type === type + '_permission' && parseInt(moduleId) === parseInt(permission.model_id);
            }

            if (this.permissions.filter(callback).length > 0) {
                return;
            }

            throw new Error('forbidden');
        });
    }
}

export default User;