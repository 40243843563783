import React from "react";
import PropTypes from "prop-types";
import { NavItem } from "react-bootstrap";
import Select, { components } from 'react-select';
import FormattedMessageString from "../../basics/FormattedMessageString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter,NavLink } from "react-router-dom";
import CheckPermissions from "../../../helpers/CheckPermissions";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import LogoComponent from "../../basics/LogoComponent";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import LoaderComponent from "../layout/LoaderComponent";

import { FormattedMessage } from "react-intl";
import Website from "../../../models/website/Website";

class TopNavMenuMobileComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roles: this.props.user.roles,
            options: [],
            isLoading: true,
            selected: null,
            showLogo: false
        };
    }

    componentDidMount() {
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is())
            this.addNavItem('/modules/overview', "CMS.TopNavBar.menu.modules", 'modules', ['fal', 'th']);
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is())
            this.addNavItem('/managers/overview', "CMS.TopNavBar.menu.users", 'users', ['fal', 'users']);
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.EDITOR) && (localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && !GeneralSettingsHelper.is())
            this.addNavItem('/media', "CMS.TopNavBar.menu.media", 'media', ['fal', 'image']);
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER) && !GeneralSettingsHelper.is())
            this.addNavItem('/customers/overview', "CMS.TopNavBar.menu.customers", 'customers', ['fal', 'users-crown']);
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && !GeneralSettingsHelper.is())
            this.addNavItem('/managers/resellers/overview', "CMS.TopNavBar.menu.resellers", 'resellers', ['fal', 'users-crown']);
        if (CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && !GeneralSettingsHelper.is())
            this.addNavItem('/websites/overview', "CMS.TopNavBar.menu.websites", 'websites', ['fal', 'globe']);
        this.setState({
            isLoading: false
        });

        this.checkShowLogo();
        window.addEventListener("resize", this.checkShowLogo.bind(this));
    }

    checkShowLogo() {
        this.setState({
            showLogo: window.innerWidth < 992
        });
    }

    addNavItem(value, label, active, icon) {
        let options = this.state.options;
        options.push({
            value: value,
            label: label,
            active: active,
            icon: icon
        });
        this.setState({
            options: options
        })
    }

    checkSelected() {
        let selected = null;

        this.state.options.forEach((option) => {
            if (window.location.pathname.includes(option.active)) {
                selected = option;
            }
        });

        return selected;
    }

    render() {
        if (this.state.isLoading)
            return <LoaderComponent />
        const CustomOption = ({ children, ...props }) => {
            return (
                    <components.Option {...props}>
                        <FontAwesomeIcon icon={props.data.icon} />
                        <FormattedMessageString id={children}>
                            {
                                (value) => value
                            }
                        </FormattedMessageString>
                    </components.Option>
                    );
        };

        const SingleValue = ({ children, ...props }) => (
                    <components.SingleValue {...props}>
                        <FontAwesomeIcon icon={props.data.icon} />
                        <FormattedMessageString id={children}>
                            {
                                (value) => value
                            }
                        </FormattedMessageString>
                    </components.SingleValue>
                    );


        return (
                <div className={this.state.showLogo ? "dropdown-navbar with-logo" : "dropdown-navbar"}>
                    {
                        this.state.showLogo ? <LogoComponent /> : null
                    } 
                    
                    {CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && GeneralSettingsHelper.is() ?
                    <>
                     <NavItem className="mobile-leave-settings">
                                    <NavLink onClick={() => {
                                        Website.set(null);
                                        setTimeout(() => window.location.reload(), 100)
                                        this.handleNavLinkClick()
                                    }} exact to="/" className="nav-link"
                                        activeClassName="active" isActive={false}>
                                        <FontAwesomeIcon icon={['fal', 'sign-out']} />
                                        <FormattedMessage id="CMS.TopNavBar.menu.settings.general.exit" />
                                    </NavLink>
                                </NavItem>
                                </>
                                
                                : null
                                }
                                
                    {
                        this.state.options.length ?
                                    <Select components={{Option: CustomOption, SingleValue}}
                                            options={this.state.options}
                                            className={'react-select-container topnav-module-select'}
                                            classNamePrefix="react-select"
                                            onChange={event => {
                                                        this.props.history.push(event.value);
                                                    }}
                                            value={this.checkSelected()}
                                            />
                                        : null

                    }
                </div>
                );
    }
}

TopNavMenuMobileComponent.propTypes = {
    user: PropTypes.object.isRequired,
};
export default withRouter(TopNavMenuMobileComponent);
