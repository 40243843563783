export default class MapContent {
    title;
    language;
    street;
    number;
    box;
    postal;
    city;
    country;
    remark;
    phone;
    fax;
    mobile;
    email;
    isFavorite;

    static fromJson(json) {
        let mapContent = new MapContent();

        mapContent.language = json.language;

        mapContent.title = json.title;
        mapContent.street = json.street;
        mapContent.number = json.number;
        mapContent.box = json.box;
        mapContent.postal = json.postal;
        mapContent.city = json.city;
        mapContent.country = json.country;

        mapContent.remark = json.remark;

        mapContent.phone = json.phone;
        mapContent.fax = json.fax;
        mapContent.mobile = json.mobile;
        mapContent.email = json.email;

        mapContent.isFavorite = json.is_favorite;

        return mapContent;
    }
}
