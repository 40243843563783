import Client from "../models/client/Client";
import API from "../services/api";

export default class ClientRepository {
    static create = (data) => API.post('/client/create', data);
    static update = (data) => API.post('/client/update', data).then(response => response.data);
    static quickUpdate = (data) => API.post('/client/quick-update', data).then(response => Client.fromJson(response.data.client));
    static delete = (id) => API.delete('/client/delete?id=' + id);

    static all = (all) => API.get('/client/all?all=' + (all ? 1 : 0)).then(response => response.data.clients.map(client => Client.fromJson(client.client)));
    static get = (id) => API.get('/client/details/' + id).then(response => response.data);

    static notify = (data) => API.post('/client/notify', data).then(response => response.data);

    static toggle = (id) => API.post('/client/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static import = (file, module) => {
        const data = new FormData();
        data.append('file', file);
        if (module) data.append('module_id', module?.id);
        
        return new Promise((resolve, reject) => {
            API.post('/client/import', data).then(response => resolve(response)).catch(response => reject(response.response.data.errors))
        });
    };
}