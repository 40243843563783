import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import moment from "moment";

class FormEntryData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    render() {
        let i = 1;
        return (
            <div>
                <FormattedMessage id="CMS.Form.Entry.export.created_at">
                    {value => <><strong>{value}</strong>: <span>{this.props.entry.createdAt ? moment(this.props.entry.createdAt).format('DD/MM/YYYY HH:mm:ss') : '-'}</span><br /></>}
                </FormattedMessage>

                {this.props.fields.map(field => {
                    if (!field.id || (!this.state.expanded && i >= 3)) return null;
                    i++;

                    let record = this.props.entry.data.filter(entry => entry.field === field.id)[0];

                    return (
                        <React.Fragment key={i}>
                            <strong>{field.id === 'consent' && record.label ? record.label : field.label}</strong>: <span dangerouslySetInnerHTML={{ __html: record ? record.value : '-' }}></span><br />
                        </React.Fragment>
                    )
                })}

                {this.renderExpandBtn()}
            </div>
        );
    }

    renderExpandBtn = () => {
        if (this.props.entry.data.length <= 3) return null;

        return (
            <Button variant="link" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                <FormattedMessage id={this.state.expanded ? 'CMS.Form.Entry.overview.table.collpase' : 'CMS.Form.Entry.overview.table.expand'}>
                    {value => <span className="mr-1">{value}</span>}
                </FormattedMessage>
                <FontAwesomeIcon icon={['fa', (this.state.expanded ? 'chevron-up' : 'chevron-down')]} size="sm" />
            </Button>
        );
    }
}

export default FormEntryData;