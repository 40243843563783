import React from "react";
import { FormattedMessage } from "react-intl";
import prettyBytes from 'pretty-bytes';
import MediaRepository from "../repository/MediaRepository";
import FormToastComponent from "../components/basics/FormToastComponent";
import JsonHelper from "./JsonHelper";
import Application from "./Application";

class MediaHelper {
    static renderInlineVideo = (row) => '<video width="320" height="240" controls><source src="' + row.path + '" type="video/mp4" />Your browser does not support the video tag.</video>';
    static renderInlineAudio = (row)  => '<audio controls><source src="' + row.path + '" type="video/mpeg" />Your browser does not support the audio tag.</video>';
    static renderLink = (row) => '<a href="' + row.path + '" target="_blank">' + row.name + '</a>';
    static buildImageSrc(src, website) {
        if (!src) return null;
        if (src.indexOf('data:') > -1 || src.indexOf('https://') > -1 || src.indexOf('http://') > -1) {
            return src;
        }
        if (!website) return src;

        return website.domain + '/' + website.sftp_media_path + '/' + src;
    }

    static assembleRequirements = (settings, dimensionPrefix = null, showDimensionsOnMime = false) => {
        if (settings === undefined) return '';

        let requirements = [];

        requirements.push(this.assembleRequirementsMimeTypes(settings, showDimensionsOnMime));

        let minWidthField = (dimensionPrefix ? dimensionPrefix : 'min') + '_width';
        let minHeightField = (dimensionPrefix ? dimensionPrefix : 'min') + '_height';

        if (!showDimensionsOnMime && settings[minWidthField] && settings[minHeightField]) {
            requirements.push(<FormattedMessage key="min_dimension" id={"CMS.Media.Form.media_dragdrop.text.min_size"} values={{ minWidth: settings[minWidthField], minHeight: settings[minHeightField] }} />);
        }
        if (settings.max_size !== undefined) {
            requirements.push(<FormattedMessage key="max_size" id={"CMS.Media.Form.media_dragdrop.text.max"} values={{ size: prettyBytes(settings.max_size).toLocaleLowerCase() }} />);
        }
        if (settings.max_upload !== undefined) {
            requirements.push(<FormattedMessage key="max_upload" id={"CMS.Media.Form.media_dragdrop.text.max_upload"} values={{ maxUpload: settings.max_upload }} />);
        }

        return requirements;
    }

    static assembleRequirementsMimeTypes = (settings, showDimensions = false) => {
        if (settings === undefined || !settings.extensions) return null;

        let allowedTypes = {
            images: [],
            files: [],
            video: [],
            audio: [],
            others: []
        };

        Object.keys(settings.extensions).forEach(key => {
            settings.extensions[key].forEach(type => {
                if (type.slice(0, 5) === "image") {
                    if (allowedTypes.images.indexOf(key) < 0)
                        allowedTypes.images.push(key);
                } else if (type.slice(0, 11) === "application") {
                    if (allowedTypes.files.indexOf(key) < 0)
                        allowedTypes.files.push(key);
                } else if (type.slice(0, 5) === "video") {
                    if (allowedTypes.video.indexOf(key) < 0)
                        allowedTypes.video.push(key);
                } else if (type.slice(0, 5) === "audio") {
                    if (allowedTypes.audio.indexOf(key) < 0)
                        allowedTypes.audio.push(key);
                }
            });
        });

        return (
            Object.keys(allowedTypes).map(key => {
                let innerArray = allowedTypes[key];

                if (innerArray.length > 0) {
                    return <FormattedMessage key={'mime_types_' + key} id={"CMS.Media.Form.media_dragdrop.text" + (showDimensions ? '.dimensions' : '') + "." + key} values={{ files: innerArray.join(', '), minWidth: settings.min_width, minHeight: settings.min_height }} />
                }

                return '';
            })
        )
    }

    static acceptedMimeTypes = (settings) => {
        let acceptedMimeTypes = {};
        if (settings !== undefined && settings.extensions) {
            Object.keys(settings.extensions).forEach(ext => {
                settings.extensions[ext].forEach(type => {
                    if (acceptedMimeTypes[type] === undefined) {
                        acceptedMimeTypes[type] = [];
                    }
                    acceptedMimeTypes[type].push('.' + ext);
                });
            });
        }

        return acceptedMimeTypes;
    }

    static uploadImage = (item, imageField, collection, module, moduleId, options = null, name = null, settings = null) => {
        return new Promise((resolve, reject) => {
            MediaRepository.upload(item[imageField], collection, module, moduleId, options, name, settings)
                .then(result => {
                    if (result.data && result.data.media !== undefined) {
                        if (collection === 'website_logo_svg') {
                            resolve(result.data.media);
                        } else {
                            resolve(result.data.media.path);
                        }
                    } else {
                        throw result;
                    }
                })
                .catch(error => {
                    if (!error.response) {
                        reject(error.message);
                    } else {
                        let { message, errors } = error.response.data;

                        if (message) {
                            message = JsonHelper.tryParse(message);
                            if (message.file) {
                                message.file.forEach(m => FormToastComponent.errorTrans('CMS.Media.Form.image.error', {
                                    message: m
                                }));
                            } else {
                                reject(message);
                            }
                        } else if (errors && errors.file) {
                            errors.file.forEach(message => FormToastComponent.errorTrans('CMS.Media.Form.image_dragdrop.error', {
                                blockNr: item.index + 1,
                                message: message
                            }));
                        } else {
                            reject(error.message ? error.message : null);
                        }
                    }
                })
        })
    }

    static isFileSizeAllowed = (file) => {
        let maxUploadSize = Application.setting('upload_max_filesize');
        if (file && file.size / 1000000 > maxUploadSize) {
            return false;
        }
        return true;
    }

    static handleDropAccepted = (media, params, callback) => {
        let reader = new FileReader();
        reader.readAsDataURL(media);

        let isVideo = media.type.indexOf('video/') > -1;

        reader.onloadend = () => {
            if (isVideo) {
                let v = document.createElement('video');
                v.src = reader.result;
                v.addEventListener('loadedmetadata', (data) => {
                    if (data.target.videoWidth >= params.minWidth && data.target.videoHeight >= params.minHeight) {
                        callback(reader.result);
                    } else {
                        FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid_dimensions", { min_width: params.minWidth, min_height: params.minHeight });
                    }
                });
            } else if (media.type.indexOf('image/') > -1 && (params.minWidth && params.minHeight)) {
                let i = new Image();
                i.src = reader.result;
                i.onload = (data) => {
                    if (data.target.width >= params.minWidth && data.target.height >= params.minHeight) {
                        callback(reader.result);
                    } else {
                        FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid_dimensions", { min_width: params.minWidth, min_height: params.minHeight });
                    }
                };
            } else {
                callback(reader.result);
            }
        }
    }

    static handleDropRejected = (files, settings) => {
        if (files.length <= 0) return;

        files.forEach(data => {
            switch (data.errors[0]?.code) {
                case 'file-invalid-type':
                    FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid_type", { types: Object.keys(settings.extensions).join(', ') });
                    break;
                case 'file-too-large':
                    FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid_size", { size: prettyBytes(settings.max_size).toLocaleLowerCase() });
                    break;
                default:
                    FormToastComponent.errorTrans("CMS.Media.Form.media_dragdrop.invalid");
                    break;
            }
        });
    }
}

export default MediaHelper;