import React from "react";
import { FormattedMessage } from "react-intl";

class FormError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {(message) => <p>{message}</p>}
            </FormattedMessage>
        );
    }
}

export default FormError;