import WebsiteFormWebsiteError from "../../websites/errors/WebsiteFormWebsiteError";

class PageUrlFormComponent {
    static fields() {
        return [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Page.Content.Form.url",
                                type: "url",
                                name: "value.url",
                                formErrorName: "url",
                                customFeedback: WebsiteFormWebsiteError
                            }
                        ]
                    },
                    /*{
                        type: "col",
                        md: 12,
                        fields: [],
                        custom: PageUrlInternalFormComponent
                    }*/
                ]
            }
        ]
    }

    static fields_before_submit(element) {
        return new Promise((resolve, reject) => {
            let oldModel = JSON.parse(element.state.oldModel);
            let model = element.state.model;
            if (model.value !== undefined &&
                oldModel.value !== undefined &&
                model.value.url !== oldModel.value.url &&
                model.value.page_content_id === oldModel.value.page_content_id) {
                delete model.value.page_content_id;
            }

            element.setState({ model: model }, () => resolve(true))
        });
    }
}

export default PageUrlFormComponent;
