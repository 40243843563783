import React from "react";
import { FormRepository, ModuleRepository, PageRepository, UserPermissionRepository, UserRepository } from "../../repository";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Application, CheckPermissions } from "../../helpers";
import FormToastComponent from "../basics/FormToastComponent";
import FormattedMessageString from "../basics/FormattedMessageString";
import { LoaderComponent } from "../basics/layout";
import UserRolesEnum from "../../enums/UserRolesEnum";

class UserPermissionsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            language: Application.tableLanguage(),
            permissions: [],
            user: null,
            pages: [],
            forms: [],
            modules: [],
            show: {
                pages: false,
                forms: false,
                modules: false
            },
            selected: {
                page_permission: [],
                form_permission: [],
                module_permission: []
            }
        }
    }

    componentDidMount() {
        let promises = [
            UserPermissionRepository.getPermissions(this.props.match.params.id),
            UserRepository.get(this.props.match.params.id),
            PageRepository.all(),
            FormRepository.getRows(),
            ModuleRepository.all()
        ];

        Promise.all(promises)
            .then(result => {
                if (!CheckPermissions.is(result[1].roles, UserRolesEnum.EDITOR)) {
                    FormToastComponent.errorTrans('CMS.module.permissions.user_error');
                    this.goBack();
                }

                let selected = this.state.selected;
                if (result[0]) {
                    result[0].forEach(permission => selected[permission.type].push('' + (permission.model_id > 0 ? permission.model_id : permission.model_string_type)))
                }

                let modules = [
                    { id: 'content_block', label: <FormattedMessageString id="CMS.LeftNavBar.menu.content_blocks" /> },
                    { id: 'translation', label: <FormattedMessageString id="CMS.LeftNavBar.menu.translations" /> },
                ];

                // Modules
                if (result[4]) {
                    result[4].forEach(module => {
                        let content = module.module.contents[this.state.language];
                        modules.push({ id: module.id, label: content ? content.title : module.id });
                    })
                }

                this.setState({
                    loading: false,
                    selected: selected,
                    user: result[1],
                    pages: result[2] ? result[2] : [],
                    forms: result[3] ? result[3] : [],
                    modules: modules
                })
            })
            .catch(() => {
                FormToastComponent.errorTrans('Default.error');
                this.goBack();
            });
    }

    handleChange = (e) => {
        let name = e.target.name.split('.');
        let type = name[0];
        let id = '' + name[1];

        let selected = this.state.selected[type];

        if (e.target.checked) {
            selected.push(id);
        } else if (selected.indexOf(id) > -1) {
            selected.splice(selected.indexOf(id), 1);
        }

        this.setState({
            selected: {
                ...this.state.selected,
                [type]: selected
            }
        });
    }

    goBack = () => this.props.history.push('/managers/overview');

    render() {
        if (this.state.loading) return <LoaderComponent />

        return (
            <Col md={8}>
                <div className={"title overview-title"}>
                    <FormattedMessage id="CMS.User.Permissions.title">
                        {value => <>
                            <h1>
                                {value}<br />
                                {this.state.user && <span className="subtitle">{this.state.user.contact_info.fullName()}</span>}
                            </h1>
                        </>}
                    </FormattedMessage>

                    <FormattedMessage id={"CMS.User.overview.back"}>
                        {value =>
                            <Link to="#" className={"go-back"} onClick={() => this.goBack()}>
                                <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                                {value}
                            </Link>
                        }
                    </FormattedMessage>
                </div>

                <div className="custom-form">
                    <form>
                        {this.state.pages.length > 0 && (
                            <Row>
                                <Col xs={12} lg={8}>
                                    <div className="custom-settings form-group custom-toggle-wrap">
                                        <h3 className={"custom-toggle" + (this.state.show.pages ? " active" : "")} onClick={() => this.setState({ show: { ...this.state.show, pages: !this.state.show.pages } })}>
                                            <FormattedMessage id="CMS.LeftNav.page.title">
                                                {value => value}
                                            </FormattedMessage>
                                        </h3>

                                        {this.state.show.pages && this.renderPages()}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {this.state.forms.length > 0 && (
                            <Row>
                                <Col xs={12} lg={8}>
                                    <div className="custom-settings form-group custom-toggle-wrap">
                                        <h3 className={"custom-toggle" + (this.state.show.forms ? " active" : "")} onClick={() => this.setState({ show: { ...this.state.show, forms: !this.state.show.forms } })}>
                                            <FormattedMessage id="CMS.LeftNavBar.menu.forms">
                                                {value => value}
                                            </FormattedMessage>
                                        </h3>

                                        {this.state.show.forms && this.renderForms()}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {this.state.modules.length > 0 && (
                            <Row>
                                <Col xs={12} lg={8}>
                                    <div className="custom-settings form-group custom-toggle-wrap">
                                        <h3 className={"custom-toggle" + (this.state.show.modules ? " active" : "")} onClick={() => this.setState({ show: { ...this.state.show, modules: !this.state.show.modules } })}>
                                            <FormattedMessage id="CMS.LeftNav.module.title">
                                                {value => value}
                                            </FormattedMessage>
                                        </h3>
                                        {this.state.show.modules && this.renderModules()}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </form>
                </div>

                <div className="buttons buttons-wrap">

                    <FormattedMessage id="CMS.Form.edit">
                        {value => (
                            <Button type="submit" id="form-submit"
                                onClick={() => UserPermissionRepository.update(this.props.match.params.id, this.state.selected)
                                    .then(() => {
                                        FormToastComponent.successTrans(<FormattedMessageString id="CMS.User.Permissions.title" />, 'Default.saved');
                                        this.goBack();
                                    })
                                    .catch(error => FormToastComponent.error(error))}
                            >
                                {value}
                            </Button>
                        )}
                    </FormattedMessage>
                </div >
            </Col >
        );
    }

    renderPages = () => this.state.pages.map(page => {
        if (page.page.type === 'sitemap') return null;

        let content = page.page.contents[this.state.language] ? page.page.contents[this.state.language] : null;

        return (
            <Form.Group as={Col} xs={12} className="px-0" key={page.id}>
                <Form.Check
                    type="switch"
                    id={'custom-switch-pages-' + page.id}
                    label={content ? content.title : page.id}
                    name={'page_permission.' + page.id}
                    onChange={this.handleChange}
                    checked={this.state.selected.page_permission.indexOf('' + page.id) > -1}
                />
            </Form.Group>
        );
    })

    renderForms = () => this.state.forms.map(form => {
        let content = form.form.contents[this.state.language] ? form.form.contents[this.state.language] : null;

        return (
            <Form.Group as={Col} xs={12} className="px-0" key={form.id}>
                <Form.Check
                    type="switch"
                    id={'custom-switch-forms-' + form.id}
                    label={content ? content.title : form.id}
                    name={'form_permission.' + form.id}
                    onChange={this.handleChange}
                    checked={this.state.selected.form_permission.indexOf('' + form.id) > -1}
                />
            </Form.Group>
        );
    })


    renderModules = () => this.state.modules.map(module => (
        <Form.Group as={Col} xs={12} className="px-0" key={module.id}>
            <Form.Check
                type="switch"
                id={'custom-switch-modules-' + module.id}
                label={module.label}
                name={'module_permission.' + module.id}
                onChange={this.handleChange}
                checked={this.state.selected.module_permission.indexOf('' + module.id) > -1}
            />
        </Form.Group>
    ))
}

export default UserPermissionsComponent;