import MediaHelper from "../../../helpers/MediaHelper";
import MapFormError from "../errors/MapFormError";
import Module from "../../../models/module/Module";
import CountryHelper from "../../../helpers/CountryHelper";

class MapTextFormComponent {
    static fields(settings) {
        let fields = [
            {
                type: "row",
                children: [
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Map.Content.Form.title",
                                type: "text",
                                name: 'title',
                                optional: true,
                                customFeedback: MapFormError,
                                formErrorName: "title"
                            }
                        ]
                    },
                    {
                        type: "col",
                        md: 12,
                        fields: [
                            {
                                title: "CMS.Map.Content.Form.country",
                                type: "select",
                                name: "country",
                                options: CountryHelper.getCountries(),
                                customFeedback: MapFormError,
                                formErrorName: "country"
                            }
                        ]
                    },
                    {
                        type: "col",
                        md: 8,
                        fields: [{
                            title: "CMS.Map.Content.Form.street",
                            type: "text",
                            name: 'street',
                            customFeedback: MapFormError,
                            formErrorName: "street"
                        }]
                    },
                    {
                        type: "col",
                        md: 2,
                        fields: [{
                            title: "CMS.Map.Content.Form.number",
                            type: "text",
                            name: 'number',
                            customFeedback: MapFormError,
                            formErrorName: "number"
                        }]
                    },
                    {
                        type: "col",
                        md: 2,
                        fields: [{
                            title: "CMS.Map.Content.Form.box",
                            type: "text",
                            name: 'box',
                            optional: true,
                            customFeedback: MapFormError,
                            formErrorName: "box"
                        }]
                    },
                    {
                        type: "col",
                        md: 3,
                        fields: [{
                            title: "CMS.Map.Content.Form.postal",
                            type: "text",
                            name: 'postal',
                            customFeedback: MapFormError,
                            formErrorName: "postal"
                        }]
                    },
                    {
                        type: "col",
                        md: 9,
                        fields: [{
                            title: "CMS.Map.Content.Form.city",
                            type: "text",
                            name: 'city',
                            customFeedback: MapFormError,
                            formErrorName: "city"
                        }]
                    }
                ]
            }
        ];

        if (settings.show_additional_information) {
            fields.push(
                {
                    type: 'row',
                    children: [{
                        type: "col",
                        md: 12,
                        fields: [{
                            title: "CMS.Map.Content.Form.remark",
                            type: "text",
                            name: 'remark',
                            optional: true,
                            customFeedback: MapFormError,
                            formErrorName: "remark"
                        }]
                    }]
                },
                {
                    type: 'row',
                    children: [
                        {
                            type: "col",
                            md: 4,
                            fields: [{
                                title: "CMS.Map.Content.Form.phone",
                                type: "text",
                                name: 'phone',
                                optional: true,
                                customFeedback: MapFormError,
                                formErrorName: "phone"
                            }]
                        },
                        {
                            type: "col",
                            md: 4,
                            fields: [{
                                title: "CMS.Map.Content.Form.fax",
                                type: "text",
                                name: 'fax',
                                optional: true,
                                customFeedback: MapFormError,
                                formErrorName: "fax"
                            }]
                        },
                        {
                            type: "col",
                            md: 4,
                            fields: [{
                                title: "CMS.Map.Content.Form.mobile",
                                type: "text",
                                name: 'mobile',
                                optional: true,
                                customFeedback: MapFormError,
                                formErrorName: "mobile"
                            }]
                        },
                        {
                            type: "col",
                            md: 12,
                            fields: [{
                                title: "CMS.Map.Content.Form.email",
                                type: "email",
                                name: 'email',
                                optional: true,
                                customFeedback: MapFormError,
                                formErrorName: "email"
                            }]
                        }
                    ]
                }
            );
        }

        return fields;
    }

    static async fields_image_before_submit(element) {
        return new Promise(async (resolve, reject) => {
            let module = Module.get();
            let item = element.state.model;
            let type = module ? module.module.type : null;
            let id = module ? module.id : null;

            if (item.image !== null && item.image !== undefined && (typeof item.image !== 'string')) {
                item.image = await MediaHelper.uploadImage(item, 'image', 'map_image', type, id, null, item.title);
            }

            element.setState({ model: item }, () => resolve(true))
        })
    }

    static renderOptionLabel = (reference, item, language) => {
        switch (reference.type) {
            case 'category':
                return item.depthLabel.join(' > ');
            default:
                let content = item[reference.type].contents.filter(content => content.language === language)[0];
                if (!content) content = item[reference.type].contents[0];
                return content.title ? content.title : (content.name ? content.name : '');
        }
    }
}


export default MapTextFormComponent;
