import React from "react";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Form from "react-bootstrap/Form";
import FormToastComponent from "../../basics/FormToastComponent";
import Website from "../../../models/website/Website";
import CheckPermissions from "../../../helpers/CheckPermissions";
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import Application from "../../../helpers/Application";
import CountryHelper from "../../../helpers/CountryHelper";

class WebsitesLanguageDropDownComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            languages: [],
            language: Application.tableLanguage(),
            isWizard: CheckPermissions.isWizard(this.props.user.roles),
            isLoading: true
        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.updateWebsites();
    }

    updateWebsites() {
        WebsiteRepository.get(Website.get())
            .then(response => {
                let languages = [];
                response.languages.forEach((language, index) => {
                    languages.push({ value: index, label: CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label })
                })

                let currentLanguage = parseInt(this.state.language);
                if (!currentLanguage || languages[currentLanguage] === undefined) {
                    currentLanguage = 0;
                    Application.setTableLanguage(currentLanguage);
                }

                this.setState({
                    languages: languages,
                    language: currentLanguage,
                    isLoading: false
                });
            })
            .catch(reason => {
                FormToastComponent.errorTrans('CMS.website.language.dropdown.error');
            })
    }

    handleChange(language) {
        if (language == null) {
            language = { value: 0 };
        }

        this.setState({ language: language.value }, () => {
            Application.setTableLanguage(language.value);
            window.location.reload();
        })
    }


    render() {
        if (this.state.languages.length <= 1) return null;

        return (
            <div className="language-select">
                <Form.Group>
                    <Select
                        value={this.state.languages.find(({ value }) => value === this.state.language)}
                        onChange={this.handleChange}
                        options={this.state.languages}
                        isLoading={this.state.isLoading}
                        onFocus={() => this.updateWebsites()}
                    />
                </Form.Group>
            </div>
        );
    }
}

export default withRouter(WebsitesLanguageDropDownComponent);