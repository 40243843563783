import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Event from "../../models/event/Event";
import EventTextFormComponent from "./forms/EventTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import EventRepository from "../../repository/EventRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";
import API from "../../services/api";
import { JsonHelper } from "../../helpers";

class EventContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            eventContent: null,
            isLoading: true,
            settings: {},
            validation: {}
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];
        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }
                settings.titleOptional = true;

                let eventResponse = result[1];
                let event = Event.fromJson(eventResponse);
                let eventContent = event.contents.filter(content => content.language === this.props.match.params.language)[0];
                eventContent.value = JsonHelper.tryParse(eventContent.value);

                if (eventContent.value && eventContent.value.content) {
                    eventContent.value.content.forEach((_, i) => eventContent.value.content[i].index = i)
                }

                this.setState({
                    settings: settings,
                    event: event,
                    eventContent: eventContent
                }, () => this.setState({ isLoading: false }, () => {
                    this.props.parent.updateTitle(<FormattedMessageString id="CMS.Media.Content.block.add" />);
                }))
            })
            .catch(error => FormToastComponent.error(error));
    }

    async getBeforeSubmit(element) {
        let promises = [];
        promises.push(EventTextFormComponent.fields_block_before_submit(element, 'event_block_image'));

        await Promise.all(promises).then(result => true);
    }

    handleBlockTypeChange = (e) => {
        this.setState({
            settings: {
                ...this.state.settings,
                titleOptional: e.value === 'text'
            }
        });

        return e.value;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.eventContent}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {

                        let data = {
                            id: this.state.event.id,
                            language: values.language,
                            type: values.type,
                            title: values.title,
                            summary: values.summary,
                            value: JSON.stringify(values.value)
                        };

                        EventRepository.updateContent(data)
                            .then(response => {
                                if (API.isSuccess(response)) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    })
                }
                }
                formErrors={this.state.form_errors}
                fields={EventTextFormComponent.fields_block({ ...this.state.settings, ...{ onBlockTypeChange: this.handleBlockTypeChange } })}
                btnSubmitClass="visually-hidden"
            />
        )
    }
}

export default withRouter(EventContentComponent);