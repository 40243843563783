import React from "react";
import PropTypes from "prop-types";
import SortableTree from "react-sortable-tree";
import PageRepository from "../../repository/PageRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class PageOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            index: this.props.index
        }
    }

    componentDidMount() {
        this.setState({ treeData: this.format(this.props.data) })
    }

    update = (data) => PageRepository.updateTree(this.state.treeData)
        .then(() => {
            FormToastComponent.successTrans("Pages", "CMS.Pages.overview.tree.updated")
            this.props.parent.updateData()
        })
        .catch(() => FormToastComponent.successTrans("Pages", "CMS.Pages.overview.tree.failed"))

    format(array, parent = null) {
        let formattedArray = [];
        let parentComponent = this;

        array.forEach(function (page) {
            if (page['page']['parent'] === parent) {
                let childArray = parentComponent.format(array, page['id']);
                page = {
                    title: page['title'] /* + '    ( id {' + page['id'] + '})' */,
                    id: page['id'],
                    children: childArray,
                    expanded: true
                }

                formattedArray.push(page)
            }
        })

        return formattedArray;
    }

    render() {
        if (this.state.treeData.length === 0) return <LoaderComponent />

        return (
            <div style={{ height: 500 }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    onMoveNode={(data) => this.update(data)}
                    maxDepth={3}
                />
            </div>
        );
    }
}

PageOverviewTreeComponent.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    parent: PropTypes.object.isRequired
};
export default PageOverviewTreeComponent