import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import PageFormError from "./errors/PageFormError";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";

class PageCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
            show_eyecatcher: false,
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        if (this.props.values.settings.show_eyecatchers) {
            this.setState({ show_eyecatcher: true })
        }
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({ currentEvent: { ...event } })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();
        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    isInvalid = (name) => this.props.errors.settings != null && this.props.errors.settings[name];

    render() {
        if (this.props.values.type === "dropdown" || this.props.values.type === "url" || this.props.values.type === "sitemap") return null;

        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Pages.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Image sizes */}
                    {this.props.values.type !== undefined && this.props.values.type.substring(0, 10) === "text/image" ?
                        <>
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col md={6}>
                                        <InputGroup className={this.isInvalid('image_width') ? 'is-invalid' : ''}>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text
                                                    id="inputGroupPrependImageWidth">image_width</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" name="settings.image_width" aria-describedby="image_width"
                                                onChange={this.onChange}
                                                isInvalid={this.isInvalid('image_width')}
                                                value={this.props.values.settings.image_width}
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            {this.isInvalid('image_width') && <PageFormError error={this.props.errors.settings.image_width} />}
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col md={6}>
                                        <InputGroup className={this.isInvalid('image_height') ? 'is-invalid' : ''}>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrependImageHeight">image_height</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" name="settings.image_height" aria-describedby="image_height"
                                                onChange={this.onChange}
                                                isInvalid={this.isInvalid('image_height')}
                                                value={this.props.values.settings.image_height}
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            {this.isInvalid('image_height') && <PageFormError error={this.props.errors.settings.image_height} />}
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col xs={12}>
                                        <FormattedMessage id="CMS.Pages.Form.setting.image.text">
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check type="switch" id="custom-switch-has-special-blocks" name="settings.has_special_blocks"
                                            label="has_special_blocks" checked={this.props.values.settings.has_special_blocks ? "checked" : ""}
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <FormattedMessage id="CMS.Pages.Form.setting.has_special_blocks.text">
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </>
                        : null
                    }

                    {/* Eyecatcher */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-eyecatcher" name="settings.show_eyecatchers"
                                    label="show_eyecatchers" className="mb-1" checked={this.state.show_eyecatcher ? "checked" : ""}
                                    onChange={(event) => {
                                        this.onChange(event, () => this.setState({ show_eyecatcher: !this.state.show_eyecatcher }, () => {
                                            delete this.props.values.settings.eyecatcher_width;
                                            delete this.props.values.settings.eyecatcher_height;
                                        }));
                                    }}
                                />
                            </Col>

                            {this.state.show_eyecatcher ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <InputGroup className={this.isInvalid('eyecatcher_width') ? 'mb-1 is-invalid' : 'mb-1'}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">eyecatcher_width</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        aria-describedby="eyecatcher_width"
                                                        type="text"
                                                        name="settings.eyecatcher_width"
                                                        onChange={this.onChange}
                                                        isInvalid={this.isInvalid('eyecatcher_width')}
                                                        value={this.props.values.settings.eyecatcher_width}
                                                    />
                                                </InputGroup>
                                                <Form.Control.Feedback type="invalid">
                                                    {this.isInvalid('eyecatcher_width') && <PageFormError error={this.props.errors.settings.eyecatcher_width} />}
                                                </Form.Control.Feedback>
                                            </Col>

                                            <Col xs={12} xl={6}>
                                                <InputGroup className={this.isInvalid('eyecatcher_height') ? 'mb-1 is-invalid' : 'mb-1'}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherHeight">eyecatcher_height</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control type="text" name="settings.eyecatcher_height" aria-describedby="eyecatcher_height"
                                                        onChange={this.onChange}
                                                        isInvalid={this.isInvalid('eyecatcher_height')}
                                                        value={this.props.values.settings.eyecatcher_height}
                                                    />
                                                </InputGroup>
                                                <Form.Control.Feedback type="invalid">
                                                    {this.isInvalid('eyecatcher_height') && <PageFormError error={this.props.errors.settings.eyecatcher_height} />}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null
                            }

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Pages.Form.setting.eyecatcher.text">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {this.state.show_eyecatcher ?
                        <Form.Group as={Col} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-eyecatcher-text" name="settings.eyecatchers_text"
                                        label="eyecatchers_text" checked={this.props.values.settings.eyecatchers_text ? "checked" : ""}
                                        onChange={this.onChange}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormattedMessage id="CMS.Pages.Form.setting.eyecatcher.text.text">
                                        {(values) => <p className="input-info">{values}</p>}
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </Form.Group>
                        : null
                    }

                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-background-images" name="settings.background_images"
                                    label="background_images" checked={this.props.values.settings.background_images ? "checked" : ""}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Pages.Form.setting.background.images.text">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-background-videos" name="settings.background_videos"
                                    label="background_videos" checked={this.props.values.settings.background_videos ? "checked" : ""}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Pages.Form.setting.background.videos.text">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-photoalbum-text" name="settings.show_photoalbum"
                                    label="show_photoalbum" checked={this.props.values.settings.show_photoalbum ? "checked" : ""}
                                    onChange={this.onChange}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Pages.Form.setting.photoalbum.text">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-videoalbum-text" label="show_videoalbum" name="settings.show_videoalbum"
                                    onChange={this.onChange}
                                    checked={this.props.values.settings.show_videoalbum ? "checked" : ""}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Pages.Form.setting.videoalbum.text">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this} customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={(event) => this.setState({
                        showDeleteModal: false,
                        deleteRow: null
                    })}
                    title="CMS.Pages.Form.setting.delete.title" text="CMS.Pages.Form.setting.delete.text"
                />
            </Form.Group>
        );
    }
}

PageCustomSettingsFormComponent.propTypes = {
    values: PropTypes.array.isRequired,
    parent: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired
};
export default PageCustomSettingsFormComponent;
