import axios from "axios";
import config from 'react-global-configuration';
import GeneralSettingsHelper from "../helpers/GeneralSettingsHelper";
import Contact from "../models/user/Contact";
import User from "../models/user/User";
import UserRole from "../models/user/UserRole";
import UserTableRow from "../models/user/UserTableRow";
import WebsiteTableRow from "../models/website/WebsiteTableRow";
import CustomHeaders from "./CustomHeaders";

class UserRepository {

    static postAddress(address, token = null, id = 0) {
        return new Promise((resolve, reject) => {
            let data = {};

            if (address.street !== null)
                data['street'] = address.street;
            if (address.number !== null)
                data['housenumber'] = address.number;
            if (address.zip !== null)
                data['postal'] = address.zip;
            if (address.city !== null)
                data['city'] = address.city;
            if (address.country !== null)
                data['country'] = address.country;
            if (id !== 0)
                data['user_id'] = id;

            axios.post(
                config.get('api_url') + '/user/address/store', data,
                {
                    headers: CustomHeaders.get(id === 0 ? token : null)
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static getAddress() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/user/address',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static postContact(contact, token = null, id = 0) {
        return new Promise((resolve, reject) => {
            let data = {};

            if (contact.first_name)
                data['firstname'] = contact.first_name;
            if (contact.last_name)
                data['lastname'] = contact.last_name;
            if (contact.phone)
                data['phone'] = contact.phone;
            if (contact.mobile)
                data['mobile'] = contact.mobile;
            if (contact.alternative_email)
                data['alternative_email'] = contact.alternative_email;
            if (contact.company_name)
                data['company_name'] = contact.company_name;
            if (contact.vat_number)
                data['tax'] = contact.vat_number;
            if (id !== 0)
                data['user_id'] = id;

            axios.post(
                config.get('api_url') + '/user/contact/store', data,
                {
                    headers: CustomHeaders.get(id === 0 ? token : null)
                })
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static getContact() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/user/contact',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static getResellersRows() {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/resellers',
                {},
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (response.status === 200 && response.data !== null) {
                        let data = response.data;
                        let rows = [];

                        Object.keys(data).forEach((key, index, arr) => {
                            let user = data[key];
                            let userIndex = index;
                            let customerRows = [];


                            if (user.customers !== null) {
                                user.customers.forEach((customer, index, arr) => {
                                    if (customer !== null) {
                                        let websites = [];

                                        if (customer.websites !== null) {
                                            customer.websites.forEach((website, index, arr) => {
                                                if (website !== null && website['languages'] !== null && website.id !== GeneralSettingsHelper.is()) {
                                                    let languages = website['languages'].map(language => language.language);
                                                    websites.push(new WebsiteTableRow(website.id, website.name, website.domain, languages, website.customer, website.active, website.api_key));
                                                }
                                            })
                                        }
                                        let customerRow = new UserTableRow(index, customer.user.id, customer.contact_info.firstname + " " + customer.contact_info.lastname, customer.user.language, customer.contact_info.company_name, customer.user.email, customer.user.last_login, 'customer', websites);
                                        customerRow.parentIndex = userIndex;
                                        customerRows.push(customerRow);
                                    }
                                });
                            }

                            let userRow = new UserTableRow(index, user.user.id, user.contact_info ? user.contact_info.firstname + " " + user.contact_info.lastname : null, user.user.language, user.contact_info ? user.contact_info.company_name : null, user.user.email, user.user.last_login, "RESELLER", customerRows, null, user.deletable);
                            // userRow.deletable = user.deletable;
                            rows.push(userRow);
                        });


                        resolve(rows);
                    } else {
                        reject('no data found')
                    }
                })
                .catch(error => {
                    reject(error.response);
                })
        })
    }

    static getManagerRows() {
        return new Promise(((resolve, reject) => {
            UserRepository.getUsersByRoles(["MANAGER", "EDITOR"])
                .then(response => {
                    let rows = [];
                    let index = 1;
                    response.forEach((single_response) => {
                        let data = single_response['data'];
                        let _role = JSON.parse(single_response['config']['data']).role;
                        Object.keys(data).forEach((key) => {
                            let item = data[key];
                            let websiteId = null;
                            let roleCounter = 0;
                            item.roles.forEach((role) => {
                                if (role.id === _role && roleCounter === 0) {
                                    websiteId = role.pivot.website;
                                    roleCounter++;
                                }
                            });
                            rows.push(new UserTableRow(index, item.user.id, item.contact_info.firstname + " " + item.contact_info.lastname, item.language, item.contact_info.company_name, item.user.email, item.user.last_login, _role, null, websiteId, item.deletable));
                            index++;
                        });

                    });
                    resolve(rows);

                })
                .catch(error => reject(error.response));
        }))
    }

    static get(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/details',
                { user: id },
                { headers: CustomHeaders.get() }
            )
                .then(response => {
                    let user = response.data.user;

                    let u = new User({ email: user.email });

                    if (user != null) {
                        u.id = user.id;
                        u.contact_info = user['user']['contact_info'] != null ? new Contact(user['user']['contact_info']['firstname'], user['user']['contact_info']['lastname'], user['user']['contact_info']['phone'], user['user']['contact_info']['mobile'], user['user']['contact_info']['alternative_email'], user['user']['contact_info']['company_name'], user['user']['contact_info']['tax']) : new Contact();
                        u.created_at = user['created_at'];
                        u.updated_at = user['updated_at'];
                        u.language = user['language'];

                        let context = u;
                        user['roles'].forEach(function (role) {
                            let user_role = new UserRole();
                            user_role.name = role.pivot.role;
                            user_role.website_id = role.pivot.website;
                            context.roles.push(user_role)
                        });

                        u.websites = [];
                        user.websites.forEach(website => u.websites.push(website.id));

                        resolve(u)
                    } else if (response.data['use_one_time_password']) {
                        resolve(response.data);
                    }
                })
                .catch(error => reject(error.response))
        })
    }

    static getUser(id = null) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/details',
                { user: id },
                { headers: CustomHeaders.get() }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static getUsersByRole(role) {
        return new Promise(((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/type',
                {
                    'role': role
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error.response);
                })
        }))
    }

    static getUsersByRoles(roles) {
        return new Promise((resolve, reject) => {
            let promises = [];

            roles.forEach((role) => {
                promises.push(UserRepository.getUsersByRole(role));
            });

            Promise.all(promises)
                .then((response => {
                    resolve(response);
                }))
                .catch((error => {
                    reject(error);
                }));
        })
    }

    static getUsersByWebsites(websites) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/reseller/users',
                {
                    'websites': websites
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error.response);
                })
        });
    }

    static update

    static storeUser(values, id = null) {
        let data = {
            id: id,
            email: values.email,
            role: values.type.toUpperCase(),
            language: values.language,
            contact_firstname: values.contact.first_name,
            contact_lastname: values.contact.last_name,
            contact_company_name: values.contact.company_name,
            contact_tax: values.contact.vat_number,
            contact_phone: values.contact.phone,
            contact_mobile: values.contact.mobile,
            contact_alternative_email: values.contact.alternative_email,
            contact_url: values.contact.url,
            address_street: values.address.street,
            address_housenumber: values.address.number,
            address_postal: values.address.zip,
            address_city: values.address.city,
            address_country: values.address.country,
            websites: values.user && values.user.websites ? values.user.websites : (values.websites ? values.websites : [])
        };

        if (id == null) {
            delete data.id;
        }

        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/store',
                data,
                { headers: CustomHeaders.get() }
            )
                .then(response => response.data.success ? resolve(response) : reject(response))
                .catch(error => reject(error.response));
        })
    }

    static createUser(user) {
        user.password = 'Test1q2w3e!';
        user.c_password = 'Test1q2w3e!';
        return new Promise(((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/register', {
                'email': user.email,
                'password': user.password,
                'c_password': user.c_password,
                'role': user.type,
                'language': user.language
            },
                {
                    headers: CustomHeaders.get()
                }
            ).then(
                response => {
                    let user_response = [];
                    if (response.data.success !== null) {
                        user_response.push(response.data);
                        let token = response.data.success.token;
                        UserRepository.postContact(user.contact, token).then(
                            response => {
                                user_response.push(response.data);
                                if (user.type === "reseller") {
                                    UserRepository.postAddress(user.address, token)
                                        .then(response => {
                                            user_response.push(response.data);
                                        }
                                        ).catch(error => reject(error.response))
                                }
                                resolve(user_response);
                            }
                        ).catch(error => reject(error.response))
                    } else {
                        reject(response);
                    }
                }
            ).catch(error => reject(error.response))
        }))
    }

    static editUser(id, values) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/update', {
                'email': values.email,
                'role': values.type,
                'user_id': id,
                'language': values.language
            },
                {
                    headers: CustomHeaders.get()
                }
            ).then(response => {
                if (response.data.success !== null) {
                    UserRepository.postContact(values.contact, null, id)
                        .then(response => {
                            if (values.type === "reseller") {
                                UserRepository.postAddress(values.address, null, id)
                                    .then(response => {
                                        resolve(true);
                                    })
                                    .catch(error => reject(error))
                            }
                        })
                        .catch(error => reject(error.response))
                }
            }
            ).catch(error => reject(error.response));

            UserRepository.postContact(values.contact, null, id)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error.response);
                })
        })
    }

    static deleteUser(user, role, website) {
        let url = config.get('api_url') + '/user/unlink?user=' + user + '&role=' + role + (website !== null ? '&website=' + website : '');
        return new Promise((resolve, reject) => {
            axios.delete(
                url,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }

    static updateLanguage(language) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/language',
                {
                    language: language
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }
}

export default UserRepository;
