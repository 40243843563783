import EventFormError from "../errors/EventFormError";
import moment from "moment";

class PeriodTextFormComponent {
    static fields(settings, form) {
        let fields = [
            {
                type: "col",
                md: 8,
                fields: [{
                    title: "CMS.Event.period.Content.Form.title",
                    type: "text",
                    name: 'title',
                    customFeedback: EventFormError,
                    formErrorName: "title"
                }]
            },
            { type: "col", md: 12, fields: [] }
        ];

        if (settings.show_start_date) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.period.Content.Form.date_start",
                    type: settings.show_hours ? 'datetime' : 'date',
                    name: 'date_start',
                    customProps: !form.state.model.id ? { min: moment().format('YYYY-MM-DD') } : {},
                    customFeedback: EventFormError,
                    formErrorName: "date_start",
                    onChange: (e, value) => {
                        let model = form.state.model;
                        model.nr_days = model.calculateNrDays();
                        form.setState({ model: model });
                    }
                }]
            });
        }

        if (settings.show_end_date) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.period.Content.Form.date_end",
                    type: settings.show_hours ? 'datetime' : 'date',
                    name: 'date_end',
                    customProps: !form.state.model.id ? { min: moment().format('YYYY-MM-DD') } : {},
                    customFeedback: EventFormError,
                    formErrorName: "date_end",
                    onChange: (e, value) => {
                        let model = form.state.model;

                        model.nr_days = model.calculateNrDays();
                        form.setState({ model: model });
                    }
                }]
            }, {
                type: "col",
                md: 8,
                fields: [{
                    title: "CMS.Event.period.Content.Form.nr_days",
                    type: "number",
                    name: 'nr_days',
                    customProps: { min: 1 },
                    customFeedback: EventFormError,
                    formErrorName: "nr_days"
                }]
            },
                { type: "col", md: 12, fields: [] });
        }
        if (settings.use_attendees) {
            fields.push({
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.period.Content.Form.bookable_from",
                    type: "datetime",
                    name: 'bookable_from',
                    optional: true,
                    customFeedback: EventFormError,
                    formErrorName: "bookable_from"
                }]
            }, {
                type: "col",
                md: 4,
                fields: [{
                    title: "CMS.Event.period.Content.Form.bookable_till",
                    type: "datetime",
                    name: 'bookable_till',
                    optional: true,
                    customFeedback: EventFormError,
                    formErrorName: "bookable_till"
                }]
            });
        }
        if (settings.show_rate) {
            fields.push({
                type: "col",
                md: 6,
                fields: [{
                    title: "CMS.Event.period.Content.Form.price",
                    type: "text",
                    name: 'price',
                    customFeedback: EventFormError,
                    formErrorName: "price",
                    customProps: { addOn: { name: 'currency' } }
                }]
            },
                { type: "col", md: 12, fields: [] });
        }

        return [{
            type: "row",
            children: fields
        }];
    }
}

export default PeriodTextFormComponent;