import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FaqFormError from "./errors/FaqFormError";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";

class FaqCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            modules: [],
            use_categories: false,
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {

        ModuleRepository.all()
            .then(result => {
                this.setState({
                    modules: result.filter(module => module.module.type === 'category'),
                    use_categories: this.props.values.settings.use_categories
                })
            });
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })

        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Faq.Form.settings"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-categories"
                                    label="use_categories"
                                    name={"settings.use_categories"}
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({
                                                use_categories: !this.state.use_categories
                                            }, () => {
                                                delete this.props.values.settings.module;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {this.state.use_categories ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text
                                                            id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select"
                                                        aria-describedby="module"
                                                        onChange={this.onChange}
                                                        name={"settings.module"}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.module}
                                                        value={this.props.values.settings.module}
                                                    >
                                                        <option value="">-</option>
                                                        {this.state.modules.map(module => {
                                                            let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                            return <option value={module.id}>{moduleContent.title}</option>
                                                        })}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.module && (
                                                            <FaqFormError
                                                                error={this.props.errors.settings.module} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.faq.use_categories"}>
                                    {
                                        (values) => <p className="input-info">{values}</p>
                                    }
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent
                    parent={this}
                    buttons={{
                        confirm: { icon: 'check' }
                    }}
                    title={"CMS.Faq.Form.setting.delete.title"} text={"CMS.Faq.Form.setting.delete.text"}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={(event) => this.setState({
                        showDeleteModal: false,
                        deleteRow: null
                    })}
                />
            </Form.Group>
        );
    }
}

export default FaqCustomSettingsFormComponent;