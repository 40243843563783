import React from "react";
import FormMaxError from "../../errors/FormMaxError";
import FormInvalidError from "../../errors/FormInvalidError";
import FormRequiredError from "../../errors/FormRequiredError";

class FormFirstnameError extends React.Component {
    render() {
        switch (this.props.error) {
            case "firstname.max":
                return <FormMaxError field_name={"CMS.User.Form." + this.props.error.slice(0, -4)} max={100} />;
            case "firstname.invalid":
                return <FormInvalidError field_name={"CMS.User.Form." + this.props.error.slice(0, -8)} />;
            default:
                return <FormRequiredError field_name={"CMS.User.Form." + this.props.error} />;
        }
    }
}

export default FormFirstnameError;