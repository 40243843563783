import React from "react";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Form from "react-bootstrap/Form";
import FormToastComponent from "../../basics/FormToastComponent";
import Website from "../../../models/website/Website";
import CheckPermissions from "../../../helpers/CheckPermissions";
import Select from 'react-select';
import { withRouter } from "react-router-dom";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import Application from "../../../helpers/Application";
import User from "../../../models/user/User";
import Module from "../../../models/module/Module";

class WebsitesDropDownComponent extends React.Component {

    constructor(props) {
        super(props);

        let website = Website.get();

        this.state = {
            websites: [],
            website: website !== null ? website : null,
            isWizard: CheckPermissions.isWizard(this.props.user.roles),
            isLoading: true
        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.updateWebsites();
    }

    updateWebsites() {
        if (this.state.isWizard) {
            WebsiteRepository.getAll()
                .then(response => {
                    let websites = response.map(website => {
                        return { value: website.id, label: website.name, data: { customer: website.customer } };
                    }).sort((a, b) => a.label.localeCompare(b.label)).filter((website) => GeneralSettingsHelper.filter(website));

                    let currentWebsite = Website.get();
                    if (this.state.website === null && websites.length > 0) {
                        currentWebsite = websites[0].value;
                        Website.set(currentWebsite);
                        User.setCustomer(websites[0].data.customer);
                        window.location.reload();
                    }

                    this.setState({ websites: websites, website: currentWebsite, isLoading: false });
                })
                .catch(reason => {
                    FormToastComponent.errorTrans('CMS.website.dropdown.error');
                    Website.remove();
                    window.location.reload();
                })
        } else {
            WebsiteRepository.getUserWebsiteRows()
                .then(response => {
                    let websites = response.map((website) => {
                        return { value: website.id, label: website.name, data: { customer: website.customer } };
                    }).sort((a, b) => a.label.localeCompare(b.label)).filter((website) => GeneralSettingsHelper.filter(website));

                    if ((GeneralSettingsHelper.is() || this.state.website === null) && response.length > 0) {
                        Website.set(websites[0].value);
                        User.setCustomer(websites[0].data.customer);
                        window.location.reload();
                    }

                    this.setState({ websites: websites, isLoading: false });
                })
                .catch(() => {
                    FormToastComponent.errorTrans('CMS.website.dropdown.error')
                    Website.remove();
                    window.location.reload();
                })
        }
    }

    handleChange(website) {
        if (website == null) {
            website = { value: 0 };
        }

        Application.setTableLanguage(0);
        Application.resetTablePageNumber();

        this.setState({ website: website.value }, () => {
            Website.set(website.value);
            Module.remove();
            User.setCustomer(website.data.customer);
            this.props.history.push('/pages/overview')
            window.location.reload();
        })
    }


    render() {
        if (this.state.websites.length <= 1) return null;
        if (GeneralSettingsHelper.is()) return null;

        return (
            <div className="website-select">
                <Form.Group>
                    <Select
                        value={this.state.websites.find(({ value }) => value === this.state.website)}
                        onChange={this.handleChange}
                        options={this.state.websites}
                        //isClearable={this.state.isWizard}
                        isLoading={this.state.isLoading}
                        onFocus={() => this.updateWebsites()}
                    />
                </Form.Group>
            </div>
        );
    }
}

export default withRouter(WebsitesDropDownComponent);