import React from 'react';
import { Link } from 'react-router-dom';

/**
 * In this component is the lang switch created. Also it will handle the lang changes requests.
 */
class LangSwitchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: localStorage.getItem('language')
        }
    }

    handleClick(event) {
        event.preventDefault();
        this.setState({
            active: event.target.innerHTML
        });
        localStorage.setItem('language', event.target.innerHTML);
        window.location.reload();

    }

    render() {
        return (
            <div className="lang-switch">
                <ul>
                    <li><Link to="#" className={this.state.active === "nl" ? "active" : ''} onClick={this.handleClick.bind(this)}>nl</Link></li>
                    <li><Link to="#" className={this.state.active === "fr" ? "active" : ''} onClick={this.handleClick.bind(this)}>fr</Link></li>
                    <li><Link to="#" className={this.state.active === "en" ? "active" : ''} onClick={this.handleClick.bind(this)}>en</Link></li>
                </ul>
            </div>
        )
    }
}

export default LangSwitchComponent;