import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import PageRepository from "./PageRepository";
import WebsiteRepository from "./WebsiteRepository";

export default class UserPermissionRepository {
    static change = (model, id, value, name, user_id) => {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/permissions/change',
                {
                    'model_type': model,
                    'model_id': id,
                    'value': value,
                    'type': name,
                    'user_id': user_id
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static update(user_id, permissions) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/permissions/update',
                { user_id: user_id, permissions: permissions },
                { headers: CustomHeaders.get() }
            )
                .then(response => response.data.success ? resolve(response.data.success) : reject(response.data.success))
                .catch(error => reject(error))
        })
    }

    static getPermissions(id = null) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/permissions/all' + (id ? '?id=' + id : ''),
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(async response => {
                    if (response.data != null && response.data.permissions != null) {
                        let pages = await PageRepository.all();
                        let websites = await WebsiteRepository.getAll();

                        let permissions = response.data.permissions.map(async (permission) => {
                            let table_name = "not found";
                            let model = {};
                            if (permission.model_string_type === "page") {
                                let page = pages.find(page => page.id === permission.model_id);
                                let website = websites.find(website => website.id === page.page.website);

                                let page_content = page.page.contents.find(content => content.language === website.default_language);
                                table_name = page_content.title;
                                model = {
                                    page: page,
                                    website: website,
                                    page_content: page_content
                                }
                            }

                            return {
                                ...permission,
                                table_name: table_name,
                                model: model
                            }
                        });

                        Promise.all(permissions).then(values => resolve(values));
                    } else {
                        resolve([]);
                    }

                })
                .catch(error => reject(error))
        })
    }

    static delete(id) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/permissions/delete?id=' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.response))
        })
    }
}