import React from "react";
import Map from "../../models/map/Map";
import MapRepository from "../../repository/MapRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import MapTextFormComponent from "./forms/MapTextFormComponent";
import Module from "../../models/module/Module";
import { LoaderComponent } from "../basics/layout";
import MapContent from "../../models/map/MapContent";

class MapFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let module = Module.get();

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new MapContent(),
            settings: {
                show_additional_information: module ? Module.getSetting(module.module, 'show_additional_information') : false,
            },
            loading: true,
            isEdit: false,
            roles: this.props.user.roles,
        };

        this._tableRef = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let model = this.state.model;
                if (result[0] !== undefined) {
                    model = Map.fromJson(result[0]);

                    let content = model.contents.filter(content => content.language === this.props.match.params.language)[0];
                    model = { ...model, ...content };
                }

                this.setState({ model: model, loading: false, isEdit: !!model.id });
            });
    }

    getBeforeSubmit = (element) => MapTextFormComponent.fields_image_before_submit(element);

    success = () => {
        FormToastComponent.successTrans("Maps", "Default.saved");
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
    }
    error = (error) => FormHelper.handleSubmitError(this, error);

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.props}
                ref={this._tableRef}
                parent={this}
                className="map"
                validationSchema={Map.schema_add}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this._tableRef.current)
                        .then(() => {
                            if (this.state.isEdit) {
                                MapRepository.update(values.id, this.props.match.params.language, values)
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            } else {
                                MapRepository.create(values)
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={MapTextFormComponent.fields(this.state.settings)}
            />
        );
    }
}

export default withRouter(MapFormComponent);