import React from "react";
import SortableTree from "react-sortable-tree";
import TeamRepository from "../../repository/TeamRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class TeamOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: this.format(props.data),
            isLoading: false,
            index: this.props.index
        }
    }

    update = () => TeamRepository.updateTree(this.state.treeData)
        .then(response => {
            FormToastComponent.successTrans("Teams", "CMS.Teams.overview.tree.updated")
            this.props.parent.updateData()
        })
        .catch(error => {
            console.error(error.message);
            FormToastComponent.successTrans("Teams", "CMS.Teams.overview.tree.failed")
        });

    format = (array) => {
        let formattedArray = [];

        array.forEach(team => {
            let content = team.team.contents[this.props.index];
            formattedArray.push({
                title: content.first_name + ' ' + content.last_name + '    ( id {' + team.id + '})',
                id: team.id,
                expanded: true
            })
        })

        return formattedArray;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div style={{ height: 500 }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    onMoveNode={(data) => this.update(data)}
                    maxDepth={0}
                />
            </div>
        );
    }

}

export default TeamOverviewTreeComponent;
