import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

class ClientHelper {
    static export = (records, params) => {
        let workbook = {
            Sheets: {
                'clients': XLSX.utils.json_to_sheet(records.map(record => this.convertToExportRecord(record, params)))
            },
            SheetNames: ['clients']
        };

        let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, 'clients.xlsx');
    }

    static convertToExportRecord = (item, params) => {
        // Set general data
        let record = {
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            language: item.language,
            membership_enabled: item.membership.enabled
        };

        if (!params.settings.fields || Object.keys(params.settings.fields).length <= 0) return record;

        if (params.settings.fields.phone) record.phone = item.phone;
        if (params.settings.fields.gender) record.gender = item.gender;
        if (params.settings.fields.national_insurance_number) record.national_insurance_number = item.national_insurance_number;
        if (params.settings.fields.birth_date) record.birth_date = item.birth_date;

        if (params.settings.fields.billing_address) {
            record.billing_street = item.billing_street;
            record.billing_number = item.billing_number;
            record.billing_box = item.billing_box;
            record.billing_postal = item.billing_postal;
            record.billing_city = item.billing_city;
            record.billing_country = item.billing_country;
        }
        if (params.settings.fields.delivery_address) {
            record.delivery_equal = item.delivery_equal;
            record.delivery_street = item.delivery_street;
            record.delivery_number = item.delivery_number;
            record.delivery_box = item.delivery_box;
            record.delivery_postal = item.delivery_postal;
            record.delivery_city = item.delivery_city;
            record.delivery_country = item.delivery_country;
        }

        if (params.settings.fields.bank_details) {
            record.iban = item.iban;
            record.bic = item.bic;
        }

        return record;
    }
}

export default ClientHelper;