import MediaRepository from "./MediaRepository";
import API from "../services/api";

export default class NewsRepository {
    static all = (all) => API.get('/news/all?all=' + (all ? 1 : 0)).then(response => response.data.news);
    static get = (id) => API.get('/news/details/' + id).then(respone => respone.data.news);

    static create = (data) => API.post('/news/create', data);
    static update = (data) => API.post('/news/update', data).then(response => response.data);
    static delete = (id) => API.delete('/news/delete?id=' + id);
    static toggle = (id) => API.post('/news/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static getBackgroundVideos = (id, album) => {
        return new Promise((resolve, reject) => {
            MediaRepository.getAlbum(id, album, 'news')
                .then(response => {
                    let rows = [];

                    if (response.data?.media !== undefined) {
                        response.data.media.forEach(row => rows.push(row))
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }
}
