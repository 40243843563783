import * as Yup from "yup"
import EventContent from "./EventContent";
import moment from "moment";
import { JsonHelper, PriceHelper } from "../../helpers";
import EventPeriod from "./EventPeriod";

export default class Event {
    id;
    website;
    category;
    module_references = [];
    active;
    /*date_start;
    date_end;
    bookable_from;
    bookable_till;
    nr_days;*/
    location;
    //price;
    minimum_age;
    maximum_age;
    //maximum_participants;
    //waiting_list;
    is_g_event;
    image;
    nr_registrations = 0;
    nr_periods = 0;
    contents = [];
    periods = [];


    constructor() {
        this.content = new EventContent();
    }

    static schema = (settings, model = null) => {
        let fields = {
            title: Yup.string().required('CMS.Event.Content.Form.title').max(100, 'CMS.Event.Content.Form.title|100|max'),
        }

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Event.Content.Form.category|select');
        }

        /*if (settings.show_start_date) {
            if (!model.date_start) {
                fields.date_start = Yup.mixed().required('CMS.Event.Content.Form.date_start');
            } else if (!model || !model.id) {
                fields.date_start = Yup.date().nullable().min(new Date((new Date()).setHours(0, 0, 0, 0)), 'CMS.Event.Content.Form.date_start|min_date');
            }
        }
        if (settings.show_end_date) {
            if (!model.date_end) {
                fields.date_end = Yup.mixed().required('CMS.Event.Content.Form.date_end')
            } else {
                fields.date_end = Yup.date().required('CMS.Event.Content.Form.date_end')
                    .when('date_start', (date_start, schema) => date_start ? schema.min(date_start, 'CMS.Event.Content.Form.date_end|CMS.Event.Content.Form.date_start|min_conditional') : schema);
            }

            let maxDays = model.calculateNrDays();
            fields.nr_days = Yup.number().required('CMS.Event.Content.Form.nr_days').typeError('CMS.Event.Content.Form.nr_days|number').min(1, 'CMS.Event.Content.Form.nr_days|1|number_min').max(maxDays, 'CMS.Event.Content.Form.nr_days|' + maxDays + '|number_max');
        }

        if (model.date_start) {
            fields.bookable_from = Yup.date()
                .when('date_start', (date_start, schema) => date_start ? schema.max(date_start, 'CMS.Event.Content.Form.bookable_from|CMS.Event.Content.Form.date_start|max_conditional') : schema)
                .nullable();

            fields.bookable_till = Yup.date()
                .when('date_start', (date_start, schema) => date_start ? schema.max(date_start, 'CMS.Event.Content.Form.bookable_till|CMS.Event.Content.Form.date_start|max_conditional') : schema)
                .when('bookable_from', (bookable_from, schema) => bookable_from ? schema.min(bookable_from, 'CMS.Event.Content.Form.bookable_till|CMS.Event.Content.Form.bookable_from|min_conditional') : schema)
                .nullable();
        }*/

        if (settings.show_location) {
            fields.location = Yup.string().required('CMS.Event.Content.Form.location');
        }
        /* if (settings.show_rate) {
          fields.price = Yup.number().transform((_, value) => parseFloat(value.replace(/,/g, ''))).typeError('CMS.Event.Content.Form.price|number').required('CMS.Event.Content.Form.price');
        }*/
        if (settings.show_minimum_age) {
            fields.minimum_age = Yup.number()
                .typeError('CMS.Event.Content.Form.minimum_age|number')
                .required('CMS.Event.Content.Form.minimum_age')
                .min(0, 'CMS.Event.Content.Form.minimum_age|0|number_min')
                .max(100, 'CMS.Event.Content.Form.minimum_age|100|number_max')
                .when('maximum_age', (maximum_age, schema) => maximum_age ? schema.max(maximum_age, 'CMS.Event.Content.Form.minimum_age|CMS.Event.Content.Form.maximum_age|max_conditional') : schema);
        }
        if (settings.show_maximum_age) {
            fields.maximum_age = Yup.number()
                .typeError('CMS.Event.Content.Form.maximum_age|number')
                .required('CMS.Event.Content.Form.maximum_age')
                .min(0, 'CMS.Event.Content.Form.maximum_age|0|number_min')
                .max(100, 'CMS.Event.Content.Form.maximum_age|100|number_max');
        }

        if (settings.show_summary) {
            fields.summary = Yup.string().required('CMS.Event.Content.Form.summary').max(255, 'CMS.Event.Content.Form.summary|255|max');
        }

        if (!settings.content_with_blocks) {
            fields.value = Yup.object({ text: Yup.string().required('CMS.Event.Content.Form.content') });
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Event();

        item.website = json.website;
        item.category = json.category;
        item.module_references = json.module_references ? JsonHelper.tryParse(json.module_references) : {};

        if (Object.keys(item.module_references).length > 0) {
            Object.keys(item.module_references).forEach(moduleId => {
                item['module_references_' + moduleId] = item.module_references[moduleId];
            });
        }

        item.id = json.id;
        item.active = json.active;
        item.date_start = json.date_start ? moment(json.date_start) : null;
        item.date_end = json.date_end ? moment(json.date_end) : null;
        item.bookable_from = json.bookable_from ? moment(json.bookable_from) : null;
        item.bookable_till = json.bookable_till ? moment(json.bookable_till) : null;
        item.nr_days = json.nr_days;
        item.location = json.location;
        item.price = PriceHelper.price(json.price);
        item.minimum_age = json.minimum_age;
        item.maximum_age = json.maximum_age;
        item.maximum_participants = json.maximum_participants;
        item.waiting_list = json.waiting_list;
        item.is_g_event = json.is_g_event
        item.image = json.image;
        item.nr_registrations = json.nr_registrations;
        item.nr_periods = json.nr_periods;
        item.contents = json.contents.map(content => EventContent.fromJson(content));

        if (json.periods && json.periods.length) {
            item.periods = json.periods.map(period => EventPeriod.fromJson(period));
        }

        return item;
    }

    calculateNrDays = () => {
        if (!this.date_start && !this.date_end) return 0;

        let start = moment(this.date_start).set({ hour: 0, minute: 0, second: 0 });
        let end = moment(this.date_end).set({ hour: 0, minute: 0, second: 0 });

        return end.diff(start, 'days') + 1;
    }

    clone = () => {
        this.id = null;
        this.date_start = null;
        this.date_end = null;
        this.nr_days = null;
        this.bookable_from = null;
        this.bookable_till = null;
        this.image = null;
    }

    registrationOpen = () => {
        let now = moment();
        if (this.bookable_from && now < this.bookable_from) {
            return false;
        }

        if (this.bookable_till && now >= this.bookable_till) {
            return false;
        }

        return true;
    }

    hasPeriods = () => this.periods.length > 0;
    firstPeriod = () => this.periods[0];
}
