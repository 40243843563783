import { JsonHelper } from "../../helpers";

export default class ProductContent {

    tags = [];
    category = null;
    existing_tags = [];

    static fromJson = (json) => {
        let content = new ProductContent();

        content.id = json.id;
        content.language = json.language;
        content.title = json.title;
        content.summary = json.summary;
        content.pdf_document = json.pdf_document;
        content.additional_field_1_value = json.additional_field_1_value;
        content.additional_field_2_value = json.additional_field_2_value;
        content.additional_field_3_value = json.additional_field_3_value;
        content.content = json.content != null ? json.content : '{}';
        content.tags = json.tags != null ? JsonHelper.tryParse(json.tags) : [];
        content.pdf_document = json.pdf_document;
        content.views = json.views;

        content.existing_tags = json.existing_tags;

        if (json.category) {
            content.category = json.category;
        }

        return content;
    }
}