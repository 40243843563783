import React from "react";
import { Button } from "react-bootstrap";
import FormattedMessageString from "../../basics/FormattedMessageString";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TwoFARepository from "../../../repository/TwoFARepository";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TwofaBackupCodesPDF from "../../pdfs/TwofaBackupCodesPDF";
import FormToastComponent from "../../basics/FormToastComponent";

class TwofaBackupCodesComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            backupCodes: []
        };


        TwoFARepository.getBackupCodes()
            .then(response => {
                if (response.data != null) {
                    this.setState({
                        backupCodes: response.data
                    })
                } else {
                    FormToastComponent.errorTrans('Auth.2fa.backupcodes.error')
                }
            })
            .catch(reason => {
                FormToastComponent.errorTrans('Auth.2fa.backupcodes.error')
            })

    }


    codes() {
        if (this.state.backupCodes.length > 0) {
            return this.state.backupCodes.map((backupCode, index) => (
                <p className={"backup-code"} key={index}>{backupCode}</p>
            ));
        } else {
            return Array.apply(null, { length: 8 }).map(Number.call, Number).map((number, index) => (
                <p className={"backup-code"} key={index}>00000000</p>
            ));
        }
    }


    copy = () => {
        let textField = document.createElement('textarea');
        let text = '';
        if (this.state.backupCodes.length > 0) {
            this.state.backupCodes.forEach((item, key) => {
                text += item;
                text += this.state.backupCodes.length - 1 !== key ? ' ' : '';
            })
        } else {
            Array.apply(null, { length: 8 }).map(Number.call, Number).forEach((item, key) => {
                text += "00000000";
                text += key !== 7 ? ' ' : '';
            })
        }
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        FormToastComponent.successTrans('Backupcodes', 'Auth.2fa.backupcodes.copy.success')
    };

    mail = () => {
        TwoFARepository.mailBackupCodes()
            .then(response => {
                FormToastComponent.successTrans('Backupcodes', 'Auth.2fa.backupcodes.mail.success')
            })
            .catch(reason => {
                FormToastComponent.errorTrans('Auth.2fa.backupcodes.mail.error')
            })
    };

    render() {
        return (
            <div className={"backup-codes"}>

                <p><FormattedMessageString id={"Auth.2fa.step3.subtitle"} /></p>
                <div className={"backup-codes-wrapper"}>
                    {this.codes()}
                </div>
                <div className={"buttons"}>
                    <Button onClick={this.props.next_step_action}><FormattedMessageString
                        id={"Auth.2fa.step.done"} /></Button>
                    <PDFDownloadLink
                        document={<TwofaBackupCodesPDF codes={this.state.backupCodes} />}
                        fileName="backupcodes.pdf"
                        className={"btn btn-outline-primary"}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : <><FontAwesomeIcon
                                icon={['fas', 'download']} /><FormattedMessageString id={"Auth.2fa.step3.download"} /></>
                        }
                    </PDFDownloadLink>
                    <Button variant={"outline-primary"} onClick={this.copy}><FontAwesomeIcon icon={['far', 'copy']} /><FormattedMessageString
                        id={"Auth.2fa.step3.copy"} /></Button>
                    <Button variant={"outline-primary"} onClick={this.mail}><FontAwesomeIcon icon={['far', 'envelope']} /><FormattedMessageString
                        id={"Auth.2fa.step3.mail"} /></Button>
                </div>

            </div>
        );
    }
}

TwofaBackupCodesComponent.propTypes = {
    next_step_action: PropTypes.func.isRequired
};
export default TwofaBackupCodesComponent;