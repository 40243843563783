import moment from "moment";
import * as Yup from "yup"
import NewsContent from "./NewsContent";

export default class News {
    id;
    website;
    category;
    active;
    published_at;
    expired_at;
    image;
    contents = [];

    constructor() {
        this.published_at = moment()
        this.content = new NewsContent();
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.News.Content.Form.title').max(255, 'CMS.News.Content.Form.title|255|max'),
            content: Yup.object({ text: Yup.string().required('CMS.News.Content.Form.content') }),
            seo_title: Yup.string().nullable().max(65, 'CMS.News.Content.Form.seo_title|65|max'),
            seo_tags: Yup.string().nullable().max(500, 'CMS.News.Content.Form.seo_tags|500|max'),
            seo_description: Yup.string().nullable().max(160, 'CMS.News.Content.Form.seo_description|160|max'),
        };

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.News.Content.Form.category|select');
        }

        /*if (settings.show_date) {
            fields.published_at = Yup.string().required('CMS.News.Content.Form.published_at');
        }*/

        if (settings.show_summary) {
            fields.summary = Yup.string().required('CMS.News.Content.Form.summary').max(255, 'CMS.News.Content.Form.summary|255|max');
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new News();

        item.website = json.website;
        item.category = json.category;
        item.id = json.id;
        item.active = json.active;
        item.published_at = json.published_at;
        item.expired_at = json.expired_at;
        item.image = json.image;
        item.contents = json.contents.map(content => NewsContent.fromJson(content));

        return item;
    }
}
