import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";

class TwoFARepository {

    static register() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/user/2fa/register', {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(reason => {
                    reject(reason);
                })
        });
    }

    static complete(code) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/register-complete', {
                    'verify_code': code
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }

    static check() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/user/2fa/check', {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }


    static verify(code, trust = false) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/verify', {
                    'one_time_password': code,
                    'trust': trust
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }


    static getBackupCodes() {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/backup', {
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }

    static backupCodeCheck(code) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/verify', {
                    backup_code: code
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }

    static deactivate(code){
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/deactivate',
                {
                    'one_time_password': code
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason.response)
                })
        })
    }

    static mailBackupCodes() {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/user/2fa/backup/mail', {},
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => {
                    reject(reason.response)
                })
        })
    }
}

export default TwoFARepository;
