import * as Yup from 'yup';


class WebsiteSetting {

    name;
    value;
    isDefault;

    constructor(name, value, isDefault = false) {
        this.name = name;
        this.value = value;
        this.isDefault = isDefault;
    }

    static schema = Yup.object({
        name: Yup.string().required("CMS.Websites.Settings.Form.name").max(100,"CMS.Websites.Settings.Form.name.max"),
        value: Yup.string().required(),
    })

}

export default WebsiteSetting