import React from "react";
import { Card } from "react-bootstrap";
import PolicyComponent from "../../../components/basics/PolicyComponent";
import DashedProgressBarComponent from "../../../components/basics/DashedProgressBarComponent";
import Activate2faStep1Component from "../../../components/authentication/2fa/Activate2faStep1Component";
import Activate2faStep2Component from "../../../components/authentication/2fa/Activate2faStep2Component";
import Activate2faStep3Component from "../../../components/authentication/2fa/Activate2faStep3Component";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class Activate2faFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            redirectToProfile: false
        };

        this.dashedProgressBarElement = React.createRef();
    }


    nextStep = () => {
        let new_step = this.state.step + 1;
        this.setState({
            step: new_step
        }, () => {
            this.dashedProgressBarElement.current.changeStep(new_step);
        });
    };

    finish = () => {
        this.setState({
            redirectToProfile: true
        })
    };

    cancel = () => {
        this.props.history.goBack();
    };

    back = () => {
        let new_step = this.state.step - 1;
        this.setState({
            step: new_step
        }, () => {
            this.dashedProgressBarElement.current.changeStep(new_step);
        });
    };

    content = () => {
        switch (this.state.step) {
            case 1:
                return <Activate2faStep1Component next_step_action={this.nextStep} cancel_action={this.cancel} />;
            case 2:
                return <Activate2faStep2Component next_step_action={this.nextStep} back_action={this.back} />;
            case 3:
                return <Activate2faStep3Component next_step_action={this.nextStep} />;
            default:
                return <Activate2faStep1Component next_step_action={this.nextStep} cancel_action={this.cancel} />;
        }
    };


    render() {

        if (this.state.redirectToProfile) return <Redirect to={"../profile"} />;

        return (
            <>
                <Card className={"activate-2fa green"}>
                    <Card.Header>
                        <h1><FormattedMessage id={"Auth.2fa.title"} /></h1>
                    </Card.Header>
                    <Card.Body>
                        <DashedProgressBarComponent total_steps={3} ref={this.dashedProgressBarElement} />
                        {this.content()}
                    </Card.Body>
                </Card>
                <PolicyComponent />
            </>
        )
    }

}


export default Activate2faFragment;