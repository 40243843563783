import React from "react";
import PropTypes from "prop-types";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormattedMessageString from "../basics/FormattedMessageString";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { FormattedMessage } from "react-intl";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, withRouter } from "react-router-dom";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import FormToastComponent from "../basics/FormToastComponent";
import WebsiteRepository from "../../repository/WebsiteRepository";
import Select from "react-select";
import CountryHelper from "../../helpers/CountryHelper";
import Website from "../../models/website/Website";
import ReactTooltip from "react-tooltip";
import Application from "../../helpers/Application";

const { SearchBar } = Search;

class WebsiteOverviewTableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            type: this.props.type,
            clickedEditLanguageCell: null,
            clickedDeleteLanguage: null,
            showDeleteModal: false
        };

        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.closeDeleteLanguageModal = this.closeDeleteLanguageModal.bind(this);


        this.languages = CountryHelper.getLanguages();
    }

    toggleActive(event, _row) {
        event.preventDefault();

        WebsiteRepository.toggle(_row.id)
            .then(response => {
                let newRows = [...this.state.rows];
                newRows = newRows.map((row) => {
                    if (row.id === _row.id) {
                        return {
                            ...row,
                            active: response
                        };
                    }
                    return row;
                });
                this.setState(curr => ({ ...curr, rows: newRows }));
            })
            .catch(error => {
                FormToastComponent.errorTrans('CMS.Websites.overview.toggle.failed')
            })

    }

    openDeleteModal = (event, row) => {
        event.preventDefault();
        this.setState({ showDeleteModal: true, deleteRow: row });
    };

    closeDeleteModal = (event) => this.setState({ showDeleteModal: false, deleteRow: null });

    openDeleteLanguageModal(event, row, language) {
        event.preventDefault();
        this.setState({
            clickedDeleteLanguage: [
                row,
                language
            ]
        })
    }

    closeDeleteLanguageModal = () => this.setState({ clickedDeleteLanguage: null })

    deleteRow(event, _row) {
        event.preventDefault();
        this.props.delete_func(_row.id)
            .then(response => {
                this.setState({
                    rows: this.state.rows.filter((row) => {
                        return _row.id !== row.id;
                    })
                }, () => {
                    this.closeDeleteModal();
                    if (_row.id === Website.get()) {
                        Website.remove();
                        window.location.reload();
                    }
                })
            })
            .catch(error => {
                FormToastComponent.errorTrans('CMS.Table.delete.error');
            });
    }

    addLanguage(event, row) {
        event.preventDefault();
        this.toggleLanguage(true, row);
    }

    toggleLanguage(bool, row) {
        let newRows = [...this.state.rows];
        newRows = newRows.map((d) => {
            if (d.id === row.id) {
                return {
                    ...d,
                    editLanguageCell: bool
                };
            }
            return d;
        });
        this.setState(curr => ({ ...curr, rows: newRows }));
    }

    addLanguageValue(value, row) {
        let newRows = [...this.state.rows];
        newRows = newRows.map((d) => {
            if (d.id === row.id) {
                return {
                    ...d,
                    languages: [...row.languages, value],
                    editLanguageCell: false
                };
            }
            return d;
        });
        this.setState(curr => ({ ...curr, rows: newRows }));
    }

    removeLanguageValue(value, row) {
        let newRows = [...this.state.rows];
        let newLanguages = [...row.languages];

        newRows = newRows.map((d) => {
            if (d.id === row.id) {
                return {
                    ...d,
                    languages: newLanguages.filter(e => e !== value),
                };
            }
            return d;
        });
        this.setState(curr => ({ ...curr, rows: newRows }), () => {
            this.closeDeleteLanguageModal();
        });
    }

    deleteLanguage(event, row, language) {
        WebsiteRepository.deleteLanguage(row.id, language)
            .then(response => {
                this.removeLanguageValue(language, row);
            })
            .catch(reason => {
                if (reason['data']['error']) {
                    FormToastComponent.error(reason['data']['error']);
                } else {
                    FormToastComponent.error(reason);

                }
            })
    }

    languageCell(row) {
        let counter = 0;
        return (
            <div className={"languages"}>
                {
                    row.languages.map(language => {
                        counter++;
                        return (
                            <div key={counter} className={"language"}>
                                <p>
                                    {language}
                                    {
                                        counter !== 1 ?
                                            <Link to="#" onClick={event => this.openDeleteLanguageModal(event, row, language)}><FontAwesomeIcon icon={['fas', 'xmark']} /></Link>
                                            : null
                                    }
                                </p>
                            </div>
                        );

                    })
                }
                <div className={"language-add"}>
                    <p>
                        <Link to="#" onClick={event => this.addLanguage(event, row)}><FontAwesomeIcon icon={['fas', 'plus']} /></Link>
                    </p>
                </div>
            </div>
        )
    }

    render() {
        const columns = [
            {
                dataField: 'id',
                text: 'Id',
                hidden: true
            },
            {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.Websites.overview.table.name" />,
                sort: true,
                formatter: (cellContent, row) => {
                    if (!row) return;

                    return (
                        <div className={"website-link"}>
                            <a href={row.url} target="_blank" rel="noopener noreferrer">
                                <div className={"arrow"}>
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                </div>
                                <div className={"text"}>
                                    {row.name}
                                </div>
                            </a>
                        </div>
                    )
                }
            },
            {
                dataField: 'url',
                text: <FormattedMessageString id="CMS.Websites.overview.table.url" />,
                sort: true,
                formatter: (cellContent, row) => {
                    if (!row) return;

                    return (
                        <div className={"website-url"}>
                            {row.api_key && (
                                <>
                                    <FontAwesomeIcon icon={['far', 'info-circle']} data-tip data-for={'website-url-' + row.id} className="mr-1" />
                                    <ReactTooltip id={'website-url-' + row.id} type={"success"} effect={"solid"} className={"custom-tooltip"}>
                                        <strong>API</strong> {row.api_key}
                                    </ReactTooltip>
                                </>
                            )}
                            <span>{row.url}</span>
                        </div>
                    );
                }
            },
            {
                dataField: 'languages',
                text: <FormattedMessageString id="CMS.Websites.overview.table.languages" />,
                formatter: (cellContent, row) => {
                    if (!row) return;

                    if (row.editLanguageCell) {
                        return <Select
                            options={this.languages.filter(language => !row.languages.includes(language.value))}
                            onChange={(event) => {
                                WebsiteRepository.addLanguage(event.value, row.id)
                                    .then(response => {
                                        this.addLanguageValue(event.value, row);
                                    })
                                    .catch(error => console.error(error));
                            }}
                            className={'react-select-container'}
                            classNamePrefix="react-select"
                            onMenuClose={(event) => {
                                this.toggleLanguage(false, row);
                            }}

                        />;
                    } else {
                        return this.languageCell(row);
                    }
                },
                classes: 'languages-wrapper'
            },
            {
                dataField: 'settings',
                isDummyField: true,
                text: '',
                formatter: (cellContent, row) => {
                    if (!row) return;

                    return (
                        <div className={"settings"}>
                            <Link to="#" className={"setting"} onClick={event => {
                                event.preventDefault();
                                Website.set(row.id);
                                this.props.history.push('/website/settings');
                                window.location.reload();
                            }}>
                                <FontAwesomeIcon icon={['fal', 'tools']} />
                            </Link>
                            <Link className={"setting disabled"} to={"download/" + row.id}>
                                <FontAwesomeIcon icon={['fal', 'download']} />
                            </Link>
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                formatter: (cellContent, row) => {
                    if (!row) return;

                    return (
                        <div className="actions">
                            {row.active ?
                                <Link to="#" onClick={event => this.toggleActive(event, row)} className={"active"}><FontAwesomeIcon icon={['fas', 'check']} /></Link>
                                :
                                <Link to="#" onClick={event => this.toggleActive(event, row)} className={"inactive"}><FontAwesomeIcon icon={['far', 'times']} /></Link>
                            }
                            <Link className={"edit"} to={"edit/" + row.id}>
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Link>
                            <Link to="#" className={"delete"} onClick={event => this.openDeleteModal(event, row)}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                            </Link>
                        </div>
                    )
                },
                style: {
                    width: '154px'
                }
            }
        ];

        return (
            <div className={"custom-data-table website"}>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.rows}
                    columns={columns}
                    bootstrap4
                    columnToggle
                    search
                >
                    {
                        props => (
                            <PaginationProvider
                                pagination={paginationFactory({
                                    sizePerPage: Application.setting('table_number_rows', 100),
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true,
                                    custom: true,
                                    totalSize: this.state.rows.length,
                                    prePageText: <FormattedMessageString id={"CMS.Table.Pagination.prev"} />,
                                    nextPageText: <FormattedMessageString id={"CMS.Table.Pagination.next"} />,
                                })}
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div className="table-wrapper">
                                            <div className={"table-header"}>
                                                <FormattedMessage id={this.props.title_id}>
                                                    {
                                                        (title) =>
                                                            <FormattedMessage id={"CMS.Table.overview"}>
                                                                {
                                                                    (title2) => <p>{title + " " + title2}</p>
                                                                }
                                                            </FormattedMessage>
                                                    }
                                                </FormattedMessage>
                                                <div className={"custom-search-bar"}>
                                                    <FontAwesomeIcon icon={['fas', 'search']} />
                                                    <FormattedMessage id={"CMS.Table.search.placeholder"}
                                                        values={{ "type": this.props.type }}>
                                                        {
                                                            (value) => <SearchBar {...props.searchProps}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                {...paginationTableProps}
                                                wrapperClasses="table-responsive"
                                                defaultSorted={[{
                                                    dataField: 'name',
                                                    order: 'asc'
                                                }]}
                                            />
                                            <PaginationListStandalone {...paginationProps} />
                                        </div>
                                    )
                                }

                            </PaginationProvider>
                        )
                    }
                </ToolkitProvider>

                <ModalFormFragment title_id={this.props.delete_title_id} show={this.state.showDeleteModal}
                    onHide={this.closeDeleteModal} is_delete={true}>
                    <DeleteConfirmComponent onHide={this.closeDeleteModal} delete_text_id={this.props.delete_text_id}
                        delete_func={(event) => {
                            this.deleteRow(event, this.state.deleteRow)
                        }} />
                </ModalFormFragment>

                <ModalFormFragment title_id={"CMS.Websites.overview.delete.language.title"} show={this.state.clickedDeleteLanguage != null}
                    onHide={this.closeDeleteLanguageModal} is_delete={true}>
                    <DeleteConfirmComponent delete_text_id={"CMS.Websites.overview.delete.language"} onHide={this.closeDeleteLanguageModal} delete_func={(event) => {
                        this.deleteLanguage(event, this.state.clickedDeleteLanguage[0], this.state.clickedDeleteLanguage[1])
                    }} />
                </ModalFormFragment>
            </div>
        );
    }
}

WebsiteOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    delete_title_id: PropTypes.string.isRequired,
    delete_text_id: PropTypes.string.isRequired,
    delete_func: PropTypes.func.isRequired,
};
export default withRouter(WebsiteOverviewTableComponent);