import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../../basics/FormToastComponent";
import MediaAlbumOverviewComponent from "./MediaAlbumOverviewComponent";
import MediaAlbumUploadComponent from "./MediaAlbumUploadComponent";
import WebsiteRepository from "../../../repository/WebsiteRepository";
import Website from "../../../models/website/Website";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class MediaAlbumComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            media: null,
            isLoading: true,
            website: null
        }
    }

    componentDidMount() {
        if (this.props.parent.props.type === "background_images") {
            this.props.parent.updateTitle("CMS.page.background_images.title");
        } else if (this.props.parent.props.type === "photo_album") {
            this.props.parent.updateTitle("CMS.Pages.photoalbum.title")
        }

        let promises = [
            this.props.get_promise,
            WebsiteRepository.get(Website.get())

        ]

        Promise.all(promises)
            .then(result => {
                let mediaResponse = result[0];
                let websiteResponse = result[1];

                if (mediaResponse !== undefined && mediaResponse.data !== undefined && mediaResponse.data.media !== undefined) {
                    this.setState({
                        media: mediaResponse.data.media,
                        isLoading: false,
                        website: websiteResponse
                    })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.media.not.found")
                }
            });
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />
        return (
            <div className="wrapper">
                <MediaAlbumUploadComponent {...this.props} parent={this} />
                {this.state.reloadOverview ?
                    null :
                    <MediaAlbumOverviewComponent {...this.props} media={this.state.media} parent={this} />
                }
            </div>
        )
    }
}

MediaAlbumComponent.propTypes = {
    get_promise: PropTypes.any
}
export default withRouter(MediaAlbumComponent);
