import PropTypes from "prop-types";
import React from "react";
import { FormEmailError } from "../../errors";
import FormAlphaNumericError from "../../errors/FormAlphaNumericError";
import FormArrayMaxError from "../../errors/FormArrayMaxError";
import FormArrayMinError from "../../errors/FormArrayMinError";
import FormMaxError from "../../errors/FormMaxError";
import FormNumberError from "../../errors/FormNumberError";
import FormRequiredError from "../../errors/FormRequiredError";
import FormSelectError from "../../errors/FormSelectError";

class FormFormError extends React.Component {

    render() {
        let error = this.props.error.split('|');
        if (this.props.error.slice(-6) === "number") {
            return <FormNumberError field_name={error[0]} />
        } else if (this.props.error.slice(-9) === "min.array") {
            return <FormArrayMinError field_name={error[0]} min={error[1]} />
        } else if (this.props.error.slice(-9) === "max.array") {
            return <FormArrayMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-3) === "min") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-5) === "match") {
            return <FormAlphaNumericError field_name={error[0]} />
        } else if (this.props.error.slice(-6) === 'select') {
            return <FormSelectError field_name={error[0]} />
        } else if (error[1] && error[1] === 'email') {
            return <FormEmailError field_name={error[0]} />            
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

FormFormError.propTypes = {
    error: PropTypes.string.isRequired
};
export default FormFormError;
