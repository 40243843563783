import React from 'react';
import Card from "react-bootstrap/Card";
import PolicyComponent from "../../components/basics/PolicyComponent";
import ForgotPasswordFormComponent from "../../components/authentication/ForgotPasswordFormComponent";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ForgotPasswordFragment extends React.Component {

    render() {
        return (
            <>
                <Link to="/login" className="go-back">
                    <FontAwesomeIcon  icon={['far', 'angle-left']}/> <FormattedMessage  id="Auth.forgot.password.back" />
                </Link>
                <Card className="forgot-password green">
                    <Card.Header >
                        <h1><FormattedMessage id="Auth.form.password.forgotten" /></h1>
                    </Card.Header>
                    <Card.Body>
                        <ForgotPasswordFormComponent />
                    </Card.Body>
                </Card>
                <PolicyComponent />
            </>
        )
    }
}

export default ForgotPasswordFragment;