import React from "react";
import ProductRepository from "../../repository/ProductRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import ProductTextFormComponent from "./forms/ProductTextFormComponent";
import Module from "../../models/module/Module";
import Product from "../../models/product/Product";
import { ModuleRepository } from "../../repository";
import ProductContent from "../../models/product/ProductContent";
import { LoaderComponent } from "../basics/layout";
import { JsonHelper } from "../../helpers";

class ProductFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new ProductContent(),
            loading: true,
            settings: {},
            isEdit: false,
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                let model = this.state.model;
                if (result[1] !== undefined) {
                    model = Product.fromJson(result[1]);
                    let content = model.contents.filter(content => content.language === this.props.match.params.language)[0];

                    model.title = content.title;
                    model.summary = content.summary;
                    model.content = content.content === '[]' ? {} : JSON.parse(content.content);
                    model.pdf_document = content.pdf_document;
                    model.tags = content.tags;

                    model.additional_field_1_value = content.additional_field_1_value;
                    if (settings.additional_field_1_type === 'editor') {
                        model.additional_field_1_value = model.additional_field_1_value === '[]' ? {} : JSON.parse(model.additional_field_1_value);
                    }

                    model.additional_field_2_value = content.additional_field_2_value;
                    if (settings.additional_field_2_type === 'editor') {
                        model.additional_field_2_value = model.additional_field_2_value === '[]' ? {} : JsonHelper.tryParse(model.additional_field_2_value);
                    }

                    model.additional_field_3_value = content.additional_field_3_value;
                    if (settings.additional_field_3_type === 'editor') {
                        model.additional_field_3_value = model.additional_field_3_value === '[]' ? {} : JSON.parse(model.additional_field_3_value);
                    }

                    settings.tags = content.existing_tags;
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    isEdit: model.id
                });
            });
    }

    getBeforeSubmit = (element) => ProductTextFormComponent.fields_image_before_submit(element)

    success = () => {
        FormToastComponent.successTrans("Product", "Default.saved");
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
    }

    error = (message) => FormHelper.handleSubmitError(this, message);

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="product"
                validationSchema={Product.schema(this.state.settings)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.state.isEdit) {
                                ProductRepository.update({ id: values.id, language: this.props.match.params.language, ...values })
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            } else {
                                ProductRepository.create(values)
                                    .then(() => this.success())
                                    .catch(error => this.error(error))
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={ProductTextFormComponent.fields(this.state.settings)}
            />
        );
    }
}

export default withRouter(ProductFormComponent);