import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Module from "../../models/module/Module";

class AttendeeMenuComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            module: props.module,
            settings: Module.getSettings(props.module.module),
            language: localStorage.getItem('language')
        }
    }

    render() {
        let navItems = [];

        [1, 2, 3].forEach(i => {
            if (
                !this.state.settings['show_additional_dynamic_field_' + i] ||
                this.state.settings['additional_dynamic_field_' + i + '_type'] !== 'dropdown'
            ) {
                return;
            }

            navItems.push(
                <Nav.Item key={this.state.module.id + '_' + i} className="subnav-item">
                    <NavLink onClick={() => Module.change(this.state.module)} className="nav-link subnav-link"
                        to={"/module/" + Module.getRoute(this.state.module.module) + "/" + this.state.module.id + "/dynamic-field/" + i} >
                        {this.state.settings['additional_dynamic_field_' + i + '_title']}
                    </NavLink>
                </Nav.Item>
            )
        });

        return navItems;
    }
}

export default AttendeeMenuComponent;