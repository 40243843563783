import GroupContent from "./GroupContent";
import Field from "./Field";

export default class FormGroup {
    active;
    order;
    fields;

    constructor() {
        this.active = true;
        this.fields = [];
    }

    static fromJson(json, language) {
        let group = new FormGroup();
        
        group.id = json.id;
        group.active = json.active;
        group.order = json.order;

        let contents = json.contents.filter(content => content.language === language)[0];

        group.fields = [];
        json.fields.forEach(field => {
            group.fields.push(Field.fromJson(field, language));
        });

        return { ...group, ...GroupContent.fromJson(contents) };
    }
}
