import React from "react";
import { Col } from "react-bootstrap";
import ResetPasswordFormFragment from "../authentication/ResetPasswordFormFragment";
import ProfileEditFormFragment from "./ProfileEditFormFragment";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class ProfileEditFragment extends React.Component {
    render() {
        return (
            <>
                <Col md={12}>
                    <FormattedMessage id="CMS.Profile">
                        {(value) => <h1>{value}</h1>}
                    </FormattedMessage>
                </Col>
                <Col xl={5} md={6}>
                    <div className="edit-profile">
                        <ProfileEditFormFragment user={this.props.user} handleUserUpdate={this.props.handleUserUpdate} />
                    </div>
                </Col>
                <Col xl={5} md={6}>
                    <ResetPasswordFormFragment email={this.props.user.email} policy={false} existing_password={true} />
                </Col>
            </>
        )
    }
}

ProfileEditFragment.propTypes = {
    user: PropTypes.object.isRequired,
    handleUserUpdate: PropTypes.func.isRequired
};
export default ProfileEditFragment;