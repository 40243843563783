import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const TableToggleActiveAction = (props) => (
    <Link onClick={() => props.toggle(props.item, 'active')} className={props.item.active ? 'active' : 'inactive'} to="#">
        <FontAwesomeIcon icon={['fas', props.item.active ? 'check' : 'times']} />
    </Link>
)

export default TableToggleActiveAction;
