import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

class EventHelper {
    static export = (event, registrations, params) => {

        let content = event.contents.filter(content => content.language === params.language)[0];
        if (!content) content = event.contents[0];

        let workbook = {
            Sheets: {
                'registrations': XLSX.utils.json_to_sheet(registrations.map(registration => this.convertToExportRecord(registration, params)))
            },
            SheetNames: ['registrations']
        };

        let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, params.intl.formatMessage({ id: 'CMS.Event.registrations.export.file_name' }, { date: event.date_start.format('DD-MM-YYYY'), event: content.title.toLowerCase().replace(' ', '-') }));
    }

    static convertToExportRecord = (registration, params) => {
        // Set general data
        let record = {
            [params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.id' })]: registration.id,
            [params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.created' })]: registration.created_at.format('DD/MM/YYYY HH:mm:ss'),
            [params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.payment_status' })]: registration.status ? params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.payment_status.' + registration.status }) : null,
            [params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.last_name' })]: registration.attendee?.last_name,
            [params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.first_name' })]: registration.attendee?.first_name
        };

        if (params.settings.attendee.fields.email) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.email' })] = registration.attendee?.email;
        }
        if (params.settings.attendee.fields.phone) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.phone' })] = registration.attendee?.phone;
        }
        if (params.settings.attendee.fields.birth_date) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.birth_date' })] = registration.attendee?.birth_date;
        }
        if (params.settings.attendee.fields.gender) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.gender' })] = registration.attendee?.gender ? params.intl.formatMessage({ id: 'CMS.Attendee.Content.Form.gender.' + registration.attendee?.gender }) : null;
        }
        if (params.settings.attendee.fields.national_insurance_number) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.national_number' })] = registration.attendee?.national_insurance_number;
        }
        if (params.settings.attendee.fields.medical_info) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.attendee.medical_info' })] = registration.attendee?.medical_info;
        }

        for (let i = 1; i <= 3; i++) {
            if (params.settings.attendee['show_additional_dynamic_field_' + i]) {
                if (params.settings.attendee['additional_dynamic_field_' + i + '_type'] === 'dropdown') {
                    let value = registration.attendee ? params.settings.dynamicFields[i]?.filter(field => field.id === parseInt(registration.attendee['additional_dynamic_field_' + i]))[0] : null;
                    if (value) {
                        let content = value.contents.filter(content => content.language === params.language)[0];
                        if (!content) content = value.contents[0];
                        record[params.settings.attendee['attendee_additional_dynamic_field_' + i + '_title']] = content.value
                    }
                } else {
                    record[params.settings.attendee['attendee_additional_dynamic_field_' + i + '_title']] = registration.attendee ? registration.attendee['additional_dynamic_field_' + i] : null;
                }
            }
        }

        // Client
        record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.client.last_name' })] = registration.client.last_name;
        record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.client.first_name' })] = registration.client.first_name;
        record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.client.email' })] = registration.client.email;

        if (params.settings.client.fields.phone) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.client.phone' })] = registration.client.phone;
        }
        if (params.settings.client.fields.gender) {
            record[params.intl.formatMessage({ id: 'CMS.Event.registrations.export.header.client.gender' })] = registration.client.gender ? params.intl.formatMessage({ id: 'CMS.Attendee.Content.Form.gender.' + registration.client.gender }) : null;
        }

        return record;
    }

    static data = (params) => {
        let data = [];

        if (params.settings.use_categories) {
            data = params.categories;
            data.push({ id: null, label: null });
        }

        for (let i = 1; i <= 3; i++) {
            if (!params.settings['show_additional_field_' + i]) continue;
            data.push({ id: 'aditional_field_' + i, label: params.settings['additional_field_' + i + '_label'] });
        }

        return data;
    }
}

export default EventHelper;