import EventFormError from "../errors/EventFormError";

class EventRegistrationTextFormComponent {
    static fields(state, form) {
        let fields = [{
            type: "col",
            md: 12,
            fields: [
                {
                    title: "CMS.Event.registration.Content.Form.event",
                    type: "select",
                    name: 'event',
                    options: state.events.map(item => {
                        return { value: item.id, label: item.contents[0].title };
                    }),
                    onChange: (form.handleEventChange),
                    customFeedback: EventFormError,
                    formErrorName: "event"
                }
            ]
        }];

        if (state.periods.length > 0 && state.model.event) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: "CMS.Event.registration.Content.Form.period",
                        type: "select",
                        name: 'period',
                        options: state.periods.map(item => {
                            return { value: item.id, label: item.contents[0].title };
                        }),
                        customFeedback: EventFormError,
                        formErrorName: 'period'
                    }
                ]
            });
        }


        fields.push({
            type: "col",
            md: 12,
            fields: [
                {
                    title: "CMS.Event.registration.Content.Form.client",
                    type: "select",
                    name: 'client',
                    options: state.clients.map(item => {
                        return { value: item.id, label: item.fullname() };
                    }),
                    onChange: (form.handleClientChange),
                    customFeedback: EventFormError,
                    formErrorName: 'client'
                }
            ]
        });

        if (state.settings.use_attendees && state.attendees.length > 0 && state.model.client) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: "CMS.Event.registration.Content.Form.attendee",
                        type: "select",
                        name: 'attendee',
                        options: state.attendees.map(item => {
                            return { value: item.hash, label: item.fullname() };
                        }),
                        customFeedback: EventFormError,
                        formErrorName: "attendee"
                    }
                ]
            }, {
                type: "col",
                md: 12,
                fields: [
                    {
                        title: "CMS.Event.registration.Content.Form.free",
                        type: "switch",
                        name: 'free',
                        customFeedback: EventFormError,
                        formErrorName: "free"
                    }
                ]
            });
        }

        return [{ type: "row", children: fields }];
    }

    static fields_edit(state, form) {
        let fields = [{
            type: "col",
            md: 12,
            fields: [
                {
                    title: "CMS.Event.registration.Content.Form.event",
                    type: "select",
                    name: 'event',
                    options: state.events.map(item => {
                        return { value: item.id, label: item.title };
                    }),
                    customFeedback: EventFormError,
                    formErrorName: "event"
                }
            ]
        }];

        /*if (state.settings.use_attendees && state.attendees.length > 0) {
            fields.push({
                type: "col",
                md: 12,
                fields: [
                    {
                        title: "CMS.Event.registration.Content.Form.attendee",
                        type: "select",
                        name: 'attendee',
                        options: state.attendees.map(item => {
                            return { value: item.id, label: item.fullname() };
                        }),
                        customFeedback: EventFormError,
                        formErrorName: "attendee"
                    }
                ]
            });
        }*/

        return [{ type: "row", children: fields }];
    }
}

export default EventRegistrationTextFormComponent;