import React from "react";
import DeleteConfirmComponent from "../DeleteConfirmComponent";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import FormToastComponent from "../FormToastComponent";

class DeleteModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title !== undefined ? this.props.title : this.props.parent.props.delete_title_id,
            text: this.props.text !== undefined ? this.props.text : this.props.parent.props.delete_text_id
        };

        this.closeModal = this.props.customCloseModalFunction !== undefined ?
            this.props.customCloseModalFunction :
            this.closeModal.bind(this);
    }

    static openDeleteModal(event, row, parent) {
        event.preventDefault();
        parent.setState({ showDeleteModal: true, deleteRow: row })
    }

    closeModal = () => this.props.parent.setState({ showDeleteModal: false, deleteRow: null })

    deleteRow = (event, _row) => {
        event.preventDefault();

        this.props.parent.props.delete_func(_row.id)
            .then(() => {
                if (this.props.parent.props.parent.props.parent) {
                    this.props.parent.props.parent.props.parent.reload();
                }

                if (this.props.parent.postDeleteRowFunction) {
                    this.props.parent.postDeleteRowFunction(event, _row);
                    this.closeModal();
                } else {
                    this.props.parent.setState({
                        rows: this.props.parent.state.rows.filter((row) => {
                            return _row.id !== row.id;
                        })
                    }, this.closeModal)
                }
            })
            .catch(() => FormToastComponent.errorTrans('CMS.Table.delete.error'));
    }

    render() {
        return (
            <ModalFormFragment title_id={this.state.title} show={this.props.parent.state.showDeleteModal}
                onHide={this.closeModal} is_delete={true}
            >
                <DeleteConfirmComponent
                    onHide={this.closeModal}
                    buttons={this.props.buttons}
                    delete_text_id={this.state.text}
                    delete_func={(event) => {
                        this.props.customDeleteRowFunction !== undefined ?
                            this.props.customDeleteRowFunction(event, this.props.parent.state.deleteRow)
                            :
                            this.deleteRow(event, this.props.parent.state.deleteRow)
                    }} />
            </ModalFormFragment>
        );
    }
}

export default DeleteModalComponent;