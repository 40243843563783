import moment from "moment";
import { PriceHelper } from "../../helpers";

export default class Attendee {
    id;
    website;
    client = [];
    active;
    status;

    hash;
    first_name;
    last_name;
    email;
    birth_date;
    gender;
    phone;
    national_insurance_number;
    medical_info;

    webshop_discount_code;

    membership = {
        enabled: false,
        price: 0,
        paid: false,
        expires: null
    };

    additional_dynamic_field_1;
    additional_dynamic_field_2;
    additional_dynamic_field_3;

    static fromJson = (json) => {
        let item = new Attendee();

        item.website = json.website;
        item.module = json.module;
        item.client = json.client;

        item.id = json.id;
        item.active = json.active;
        item.status = json.status;

        item.hash = json.hash;
        item.first_name = json.first_name;
        item.last_name = json.last_name;
        item.email = json.email || '';

        item.gender = json.gender || '';
        item.birth_date = json.birth_date || '';
        item.national_insurance_number = json.national_insurance_number || '';
        item.phone = json.phone || '';
        item.medical_info = json.medical_info || '';

        item.webshop_discount_code = json.webshop_discount_code || '';

        item.additional_dynamic_field_1 = json.additional_dynamic_field_1;
        item.additional_dynamic_field_2 = json.additional_dynamic_field_2;
        item.additional_dynamic_field_3 = json.additional_dynamic_field_3;

        item.membership = {
            enabled: json.membership_enabled,
            price: PriceHelper.price(json.membership_price ? json.membership_price : 0),
            paid: json.membership_paid ? moment(json.membership_paid) : null,
            expires: json.membership_expires ? moment(json.membership_expires) : null
        };

        return item;
    }

    fullname = () => this.first_name + ' ' + this.last_name;
    statusLabel = () => {
        if (this.status.indexOf('membership-') < 0) {
            if (this.status === 'active') {
                return { color: 'success', label: 'CMS.Client.overview.table.status.active', icon: 'fa-check', extra: [] };
            }

            return { color: 'danger', label: 'CMS.Client.overview.table.status.inactive', icon: 'fa-times', extra: this.membership.expires && this.membership.expires < moment() ? ['expired'] : [] };
        }

        if (this.status === 'membership-paid') {
            return { color: 'success', label: 'CMS.Client.overview.table.status.membership_active', icon: 'fa-check', extra: ['expires'] };
        }

        if (!this.membership.expires) {
            return { color: 'warning', label: 'CMS.Client.overview.table.status.membership-awaiting-payment', icon: 'fa-stopwatch', extra: ['pay-manual'] };
        }

        if (this.membership.expires > moment()) {
            return { color: 'warning', label: 'CMS.Client.overview.table.status.membership-awaiting-payment-renewal', icon: 'fa-stopwatch', extra: ['expires', 'pay-manual'] };
        }

        return { color: 'danger', label: 'CMS.Client.overview.table.status.membership_inactive', icon: 'fa-times', extra: ['expired'] };
    }
}
