import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CheckPermissions from "../../../helpers/CheckPermissions";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import CheckActive from "../../../helpers/CheckActive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import Website from "../../../models/website/Website";
import { Application } from "../../../helpers";

class TopNavMenuComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            roles: this.props.user.roles
        };
    }

    render() {
        return (
                
            <Navbar expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                            <NavItem>
                                <NavLink exact to="/modules/overview" className="nav-link"
                                    activeClassName="active" isActive={CheckActive.check('modules')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'th']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.modules" />
                                </NavLink>
                            </NavItem>
                            : null}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() && Website.get() ?
                            <NavItem>
                                <NavLink exact to="/managers/overview" className="nav-link"
                                    activeClassName="active"
                                    isActive={CheckActive.check('users')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'users']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.users" />
                                </NavLink>
                            </NavItem>
                            : null}
                        {/*{CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) ?*/}
                        {/*    <NavItem>*/}
                        {/*        <NavLink exact to="/google_analytics" className="nav-link"*/}
                        {/*                 activeClassName="active"><FontAwesomeIcon icon={['fal', 'image']}/><FormattedMessage*/}
                        {/*            id="CMS.TopNavBar.menu.google.analytics"/></NavLink>*/}
                        {/*    </NavItem>*/}
                        {/*    : null}*/}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.EDITOR) && Website.get() !== null && !GeneralSettingsHelper.is() ?
                            <NavItem>
                                <NavLink exact to="/media" className="nav-link" activeClassName="active"
                                    isActive={CheckActive.check('media')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'image']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.media" />
                                </NavLink>
                            </NavItem>
                            : null}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER) && !GeneralSettingsHelper.is() ?
                            <NavItem>
                                <NavLink exact to="/customers/overview" className="nav-link"
                                    activeClassName="active" isActive={CheckActive.check('customers')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'users-crown']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.customers" />
                                </NavLink>
                            </NavItem>
                            : null}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && !GeneralSettingsHelper.is() ?
                            <NavItem>
                                <NavLink exact to="/managers/resellers/overview" className="nav-link"
                                    activeClassName="active" isActive={CheckActive.check('resellers')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'users-cog']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.resellers" />
                                </NavLink>
                            </NavItem>
                            : null}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER) && !GeneralSettingsHelper.is() ?
                            <NavItem>
                                <NavLink exact to="/websites/overview" className="nav-link"
                                    activeClassName="active" isActive={CheckActive.check('websites')}
                                    onClick={this.handleNavLinkClick}
                                >
                                    <FontAwesomeIcon icon={['fal', 'globe']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.websites" />
                                </NavLink>
                            </NavItem>
                            : null}
                        { /*CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) ?
                            <NavItem>
                                <NavLink onClick={event => {
                                    Website.set(0);
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 100)
                                }} exact to="/websites/settings" className="nav-link"
                                    activeClassName="active" isActive={CheckActive.check('settings')}>
                                    <FontAwesomeIcon icon={['fal', 'cog']} />
                                    <FormattedMessage id="CMS.TopNavBar.menu.settings.general" />
                                </NavLink>
                            </NavItem>
                            : null */}
                        {CheckPermissions.role(this.state.roles, UserRolesEnum.WIZARD) && GeneralSettingsHelper.is() ?
                            <>
                                { /*   <Nav.Item>
                                    <NavLink to={"/pages/overview"} className={"nav-link"}>
                                        <FontAwesomeIcon icon={['far', 'newspaper']} />
                                        <FormattedMessage id={"CMS.LeftNavBar.menu.pages"} />
                                    </NavLink>
                                </Nav.Item>
                                <NavItem>
                                    <NavLink exact to="/website/settings" className="nav-link"
                                        activeClassName="active" isActive={CheckActive.check('settings')}>
                                        <FontAwesomeIcon icon={['fal', 'cog']} />
                                        <FormattedMessage id="CMS.TopNavBar.menu.settings.general" />
                                    </NavLink>
                                </NavItem> */ }
                                <NavItem>
                                    <NavLink onClick={() => {
                                        Website.set(null);
                                        setTimeout(() => window.location.reload(), 100)
                                        this.handleNavLinkClick()
                                    }} exact to="/" className="nav-link"
                                        activeClassName="active" isActive={false}>
                                        <FontAwesomeIcon icon={['fal', 'sign-out']} />
                                        <FormattedMessage id="CMS.TopNavBar.menu.settings.general.exit" />
                                    </NavLink>
                                </NavItem>
                            </>
                            : null}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }

    handleNavLinkClick = () => Application.resetTablePageNumber();
}

TopNavMenuComponent.propTypes = {
    user: PropTypes.object.isRequired,
};
export default TopNavMenuComponent;
