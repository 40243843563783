import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import Website from '../../models/website/Website';

class SettingsComponent extends React.Component {
    render() {
        if (CheckPermissions.role(this.props.user.roles, UserRolesEnum.WIZARD)) {
            return <Link exact="true" to="/website/settings" onClick={this.onClick} className="btn-settings">
                <FontAwesomeIcon icon={['fal', 'gear']} />
            </Link>
        }

        return null;
    }

    onClick = () => {
        Website.set(1);
        setTimeout(() => window.location.reload(), 100)
    }
}

export default SettingsComponent;