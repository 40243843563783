export default class OptionContent {
    id;
    label;
    value;

    static fromJson(json, language) {
        let option = new OptionContent();

        option.language = language;
        option.label = json.label;
        option.value = json.value;
        option.order = json.order;

        return option;
    }
}
