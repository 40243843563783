import React from "react";
import WebsiteTranslation from "../../models/website/WebsiteTranslation";
import { Formik } from "formik";
import { Button, Col, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import CountryHelper from "../../helpers/CountryHelper";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHelper from "../../helpers/FormHelper";
import PropTypes from "prop-types";
import FormRequiredError from "../errors/FormRequiredError";
import FormToastComponent from "../basics/FormToastComponent";
import WebsiteTranslationTableRow from "../../models/website/WebsiteTranslationTableRow";
import CustomTextArea from "../basics/CustomTextArea";
import InputHelper from "../../helpers/InputHelper";
import WebsiteMaxRequiredInvalidError from "./errors/WebsiteMaxRequiredInvalidError";
import FormattedMessageString from "../basics/FormattedMessageString";
import ToolTipInfo from "../basics/ToolTipInfo";
import LoaderComponent from "../basics/layout/LoaderComponent";

class WebsiteTranslationFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            translation: this.props.translation == null ? new WebsiteTranslation() : new WebsiteTranslation(this.props.translation.id, this.props.translation.name, this.props.translation.translation, this.props.translation.language),
            website_id: Website.get(),
            website: null,
            translation_id: null,
            isLoading: true,
            add_schema: WebsiteTranslation.add_schema,
            edit_schema: WebsiteTranslation.edit_schema,
            clickedEditLanguageCell: null,
            form_errors: [],
            show_tool_tip: false
        };

        this.languages = CountryHelper.getLanguages();
        this.form = null;
    }

    componentDidMount() {
        if (this.state.website_id !== undefined) {
            WebsiteRepository.get(this.state.website_id)
                .then(response => {
                    this.setState({
                        website: response,
                        add_schema: WebsiteTranslation.add_schema(response.languages),
                        isLoading: false
                    })
                })
                .catch(error => console.error(error));
        }
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <div className="custom-form website-translation">
                <Formik
                    enableReinitialize
                    validationSchema={this.state.translation.id ? this.state.edit_schema : this.state.add_schema}
                    initialValues={this.state.translation}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, formikActions) => {
                        if (this.state.translation.id == null) {
                            this.state.website.languages.forEach(language => {
                                values.translations[language.language] = values['translations_' + language.language];
                                delete values['translations_' + language.language];
                            });
                        }

                        for (let key in values.translations) {
                            if (values.translations[key] === "") {
                                delete values.translations[key];
                            }
                        }

                        if (this.state.translation.id == null) {
                            WebsiteRepository.addTranslations(values)
                                .then(response => {
                                    if (this.props.table_ref != null && response.status === 200) {
                                        let table_ref = this.props.table_ref;
                                        table_ref.setState({ isTableReloading: true }, () =>
                                            table_ref.setState({
                                                rowsArray: table_ref.state.rowsArray.map(function (rowArray) {
                                                    let row = response.data.translations[rowArray.language_code];
                                                    let rows = rowArray.rows;
                                                    rows.website.push(new WebsiteTranslationTableRow(row.id, row.type, row.name, row.translation, rowArray.language_code));
                                                    return rowArray;
                                                }),
                                                isTableReloading: false
                                            }, () => this.props.parent_ref.setState({ showFormModal: false })));
                                        FormToastComponent.successTrans('Translations', 'Default.saved');
                                    } else {
                                        FormToastComponent.errorTrans("CMS.Form.ref.not.found");
                                    }
                                })
                                .catch(reason => {
                                    if (
                                        reason.response != null &&
                                        reason.response.data != null &&
                                        reason.response.data.error != null &&
                                        reason.response.data.error.translations != null) {
                                        let message = reason.response.data.error.translations[0];
                                        if (message === "WEBSITE_LANGUAGE_SET_NAME" || message === "WEBSITE_LANGUAGE_EXISTING_NAME") {
                                            delete reason.response.data.error.translations;
                                            reason.response.data.error.name = [<FormattedMessageString id={"CMS.Websites.Translations.Form.name." + message} />];
                                        }
                                    }
                                    FormHelper.handleSubmitError(this, reason.response);
                                })
                        } else {
                            WebsiteRepository.updateTranslations(Website.get(), values)
                                .then(response => {
                                    let translation = this.state.translation;
                                    if (this.props.table_ref && response.status === 200) {
                                        let table_ref = this.props.table_ref;
                                        table_ref.setState({ isTableReloading: true }, () => {
                                            table_ref.setState({
                                                rowsArray: table_ref.state.rowsArray.map(function (rowArray) {
                                                    if (rowArray.language_code === translation.language) {
                                                        ['website', 'default'].forEach(type => {
                                                            let rows = rowArray.rows[type];
                                                            rowArray.rows[type] = [];
                                                            rows = rows.map(function (row) {
                                                                if (row.id === translation.id) {
                                                                    row.translation = values.translation;
                                                                }
                                                                return row;
                                                            });
                                                            rowArray.rows[type].push(...rows);
                                                        })
                                                    }
                                                    return rowArray;
                                                }),
                                                isTableReloading: false
                                            }, () => {
                                                this.props.parent_ref.setState({
                                                    showFormModal: false,
                                                    showEditModal: false,
                                                    editTranslation: null
                                                });
                                            })
                                        });
                                    }
                                    FormToastComponent.successTrans('Translations', 'Default.saved');
                                })
                                .catch(error => console.error(error));
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <FormattedMessage id={"CMS.Websites.Translations.Form.name"}>
                                        {(value) => <Form.Label>{value}</Form.Label>}
                                    </FormattedMessage>
                                    <Form.Control type="text" name="name"
                                        value={values.name || ''}
                                        onChange={(event) => {
                                            event.target.value = InputHelper.transformToLowerCaseAndUnderscore(event.target.value);
                                            handleChange(event);
                                        }}
                                        isInvalid={!!errors.name || !!this.state.form_errors.name}
                                        onBlur={handleBlur} disabled={this.state.translation.id} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && touched.name && (
                                            <WebsiteMaxRequiredInvalidError error={errors.name} />
                                        )}
                                        {this.state.form_errors.name && (
                                            <p>{this.state.form_errors.name}</p>
                                        )}
                                    </Form.Control.Feedback>
                                    <FormattedMessage id={"CMS.Websites.Translations.Form.name.info." + (this.state.translation.id ? 'edit' : 'add')}>
                                        {(value) => <p className="input-info">{value}</p>}
                                    </FormattedMessage>
                                </Form.Group>
                                {this.state.translation.id ?
                                    <Form.Group>
                                        <FormattedMessage id={"CMS.Websites.Translations.Form.translation"}>
                                            {(value) =>
                                                <Form.Label className="position-relative">
                                                    {value}
                                                    <ToolTipInfo />
                                                </Form.Label>
                                            }
                                        </FormattedMessage>
                                        <CustomTextArea name={"translation"}
                                            onChange={handleChange} onBlur={handleBlur}
                                            isInvalid={!!errors.translation}>
                                            {this.state.translation.translation}
                                        </CustomTextArea>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.translation && touched.translation && (
                                                <FormRequiredError field_name={"CMS.Websites.Translations.Form.translation"} />
                                            )}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    :
                                    this.state.website.languages !== undefined ?
                                        this.state.website.languages.map(language => (
                                            <Form.Group>
                                                <FormattedMessage id={"CMS.Websites.Translations.Form.translation"}>
                                                    {
                                                        (value) => <Form.Label className="position-relative">
                                                            {value}
                                                            <ToolTipInfo />
                                                            <span className={"extra"}>
                                                                ({this.languages.find(l => l.value === language.language).label})
                                                            </span>
                                                        </Form.Label>
                                                    }
                                                </FormattedMessage>
                                                <CustomTextArea
                                                    name={"translations_" + language.language}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(!!this.state.form_errors.translation || errors['translations_' + language.language]) && (values.translations === {} || values.translations[language.language] == null /*&& values.translations[language] != null*/)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.form_errors.translation && <p>{this.state.form_errors.translation}</p>}
                                                    {errors['translations_' + language.language] && <FormRequiredError field_name={errors['translations_' + language.language]} />}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))
                                        : null
                                }
                                <Row className={"buttons"}>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <FormattedMessage id={this.state.translation.id ? "CMS.Websites.Translation.Form.save" : "CMS.Websites.Translation.Form.submit"}>
                                                {value => (
                                                    <Button type="submit" onClick={() => FormHelper.submitFormErrorToast(this)}>
                                                        {!this.state.translation.id ? <FontAwesomeIcon icon={["far", "plus"]} /> : null}
                                                        {value}
                                                    </Button>
                                                )}
                                            </FormattedMessage>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <FormattedMessage id="CMS.Websites.Translation.Form.cancel">
                                                {value => (
                                                    <Button variant="secondary"
                                                        onClick={() => this.props.parent_ref.setState({ showFormModal: false, showEditModal: false, editTranslation: null })}>
                                                        {value}
                                                    </Button>
                                                )}
                                            </FormattedMessage>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        );
    }
}

WebsiteTranslationFormComponent.propTypes = {
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired,
    translation: PropTypes
};

export default withRouter(WebsiteTranslationFormComponent);