import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class LeftNavButtonComponent extends React.Component {

    toggle(e) {
        e.preventDefault();

        this.props.parent.setState({
            showNavOnMobile: !this.props.parent.state.showNavOnMobile
        })
    }

    render() {
        return (
            <div className={"responsive-button-wrapper"}>
                <Link to="#" className={"responsive-button"} title={"responsive-button"} onClick={(e) => this.toggle(e)}>
                    <span />
                </Link>
            </div>
        );
    }

}

LeftNavButtonComponent.propTypes = {
    parent: PropTypes.object.isRequired,
};
export default LeftNavButtonComponent;