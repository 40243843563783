import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import UserDetailed from "../../models/user/UserDetailed";
import { Col, Form, Image, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import UserTypeEnum from "../../enums/UserTypeEnum";
import { CheckPermissions, CountryHelper, FormHelper, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import FormToastComponent from "../basics/FormToastComponent";
import Address from "../../models/user/Address";
import Contact from "../../models/user/Contact";
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { CustomerRepository, UserRepository } from "../../repository";
import { FormCityError, FormContactCompanyNameError, FormContactEmailError, FormContactPhoneError, FormFirstnameError, FormLastnameError, FormStreetError, FormStreetNumberError, FormZipError } from "./errors";
import { FormEmailError, FormRequiredError, FormWebsiteError } from "../errors";
import User from "../../models/user/User";
import { LoaderComponent } from "../basics/layout";
import UserFormError from "./errors/UserFormError";

class FormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: new UserDetailed(),
            error: false,
            type: this.props.slug === "managers/resellers/overview" || this.props.slug === "customers/overview" ? UserRolesEnum.RESELLER : UserRolesEnum.WIZARD,
            loading: true,
            resellers: [],
            websites: [],
            form_errors: []
        };

        let availableLanguages = this.props.slug !== "customers/overview" ? ['nl', 'fr', 'en'] : [];

        this._languages = CountryHelper.getLanguages(availableLanguages);
        this._countries = CountryHelper.getCountries();
        this.form = null;
    }

    componentDidMount() {
        if (this.props.slug === "customers/overview") {
            UserRepository.getResellersRows()
                .then(response => {
                    this.setState({
                        resellers: response.map(value => {
                            return { value: value.key, label: value.company };
                        })
                    })
                })
                .catch(error => { })
        }

        let selectedCustomer = User.getCustomer();
        if (selectedCustomer && this.props.slug === 'managers/overview' && CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER)) {
            CustomerRepository.getCustomer(selectedCustomer).then(result => {
                if (result.data.success) {
                    this.setState({
                        websites: result.data.customer.websites
                            .map(website => { return { value: website.id, label: website.name } })
                            .filter(website => GeneralSettingsHelper.filter(website))
                            .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
                    })
                }
            });
        }

        // edit
        if (this.props.id != null) {
            this.props.get_promise
                .then(response => {
                    if (response.data.success) {
                        let user = null;

                        if (this.props.slug === "customers/overview") {
                            let data = response.data.customer;
                            user = new UserDetailed();
                            user.email = data['email'];
                            user.language = data['language'] !== null ? data['language'] : 'nl';
                            user.reseller = data['reseller'] + '';
                            if (data['address_info'] != null) {
                                let address = data['address_info'];
                                user.address = new Address(
                                    address['street'] !== "" ? address['street'] : undefined,
                                    address['housenumber'] !== "" ? address['housenumber'] : undefined,
                                    address['postal'] !== "" ? address['postal'] : undefined,
                                    address['city'] !== "" ? address['city'] : undefined,
                                    address['country'] !== "" ? address['country'] : undefined,
                                );
                            }
                            if (data.contact_info != null) {
                                let contact = data.contact_info;
                                user.contact = new Contact(
                                    contact['firstname'] !== "" ? contact['firstname'] : undefined,
                                    contact['lastname'] !== "" ? contact['lastname'] : undefined,
                                    contact['phone'] !== "" ? contact['phone'] : undefined,
                                    contact['mobile'] !== "" ? contact['mobile'] : undefined,
                                    contact['alternative_email'] !== "" ? contact['alternative_email'] : undefined,
                                    contact['url'] !== "" ? contact['url'] : undefined,
                                    contact['company_name'] !== "" ? contact['company_name'] : undefined,
                                    contact['tax'] !== "" ? contact['tax'] : undefined,
                                );
                            }
                        } else {
                            let data = response.data.user;

                            user = new UserDetailed();
                            user.email = data.user['email'];
                            user.type = this.props.slug === "customers/overview" ? "CUSTOMER" : CheckPermissions.get(data['roles'], true);
                            user.language = data.language !== null ? data.language : 'nl';
                            if (data.address != null) {
                                user.address = new Address(
                                    data.address['street'] !== "" ? data.address['street'] : undefined,
                                    data.address['housenumber'] !== "" ? data.address['housenumber'] : undefined,
                                    data.address['postal'] !== "" ? data.address['postal'] : undefined,
                                    data.address['city'] !== "" ? data.address['city'] : undefined,
                                    data.address['country'] !== "" ? data.address['country'] : undefined
                                );
                            }
                            if (data.contact_info != null) {
                                user.contact = new Contact(
                                    data.contact_info['firstname'] !== "" ? data.contact_info['firstname'] : undefined,
                                    data.contact_info['lastname'] !== "" ? data.contact_info['lastname'] : undefined,
                                    data.contact_info['phone'] !== "" ? data.contact_info['phone'] : undefined,
                                    data.contact_info['mobile'] !== "" ? data.contact_info['mobile'] : undefined,
                                    data.contact_info['alternative_email'] !== "" ? data.contact_info['alternative_email'] : undefined,
                                    data.contact_info['url'] !== "" ? data.contact_info['url'] : undefined,
                                    data.contact_info['company_name'] !== "" ? data.contact_info['company_name'] : undefined,
                                    data.contact_info['tax'] !== "" ? data.contact_info['tax'] : undefined,
                                );
                            }

                            if (data.websites) {
                                user.websites = data.websites.map(website => website.id);
                            }
                        }

                        this.setState({
                            user: user,
                            loading: false
                        })
                    }
                })
        } else {
            let user = this.state.user;
            user.language = 'nl';
            user.type = this.props.slug === "managers/resellers/overview" ? 'reseller' : this.props.slug === "customers/overview" ? "CUSTOMER" : 'manager';
            this.setState({
                user: user,
                loading: false
            });
            if (this.props.match.params.reseller !== undefined) {
                user.reseller = this.props.match.params.reseller;
            }
        }
    }

    getProfilePicture() {
        if (this.state.user.profile_picture_url !== '') {
            return (
                <div className={"profile-picture-placeholder"}>
                    <Image src={this.state.user.profile_picture_url} />
                </div>
            )
        } else {
            return (
                <FormattedMessage
                    id={"CMS.User.Form.profile_picture.placeholder.text"}>
                    {
                        (value) =>
                            <div className={"profile-picture-placeholder"}>
                                <FontAwesomeIcon icon={['fas', 'image']} />
                                <p>{value}</p>
                            </div>
                    }
                </FormattedMessage>
            )
        }
    }

    goBackAfterCreate = () => this.props.history.push("/" + this.props.slug);

    getValidationSchema = () => {
        if (this.props.slug === "customers/overview") {
            return UserDetailed.schema_customer;
        } else if (this.state.type === UserRolesEnum.RESELLER) {
            return UserDetailed.schema;
        } else {
            return UserDetailed.schema_form_standard;
        }
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <div className={"custom-form user"}>
                <Formik
                    enableReinitialize
                    validationSchema={this.getValidationSchema()}
                    initialValues={this.state.user}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, actions) => {
                        if (this.props.slug === "managers/resellers/overview") {
                            values.type = "reseller";
                        }
                        if (this.props.slug === "customers/overview") {
                            CustomerRepository.storeCustomer(values, this.props.id)
                                .then(response => {
                                    FormToastComponent.successTrans("Customer", "Default.saved");
                                    this.goBackAfterCreate();
                                })
                                .catch(error => FormHelper.handleSubmitError(this, error))

                        } else {
                            UserRepository.storeUser(values, this.props.id)
                                .then(response => {
                                    FormToastComponent.successTrans("User", "Default.saved");
                                    this.goBackAfterCreate();
                                })
                                .catch(error => FormHelper.handleSubmitError(this, error))
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Row>
                                    {this.props.slug !== "customers/overview" ?
                                        <>
                                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) && (
                                                <>
                                                    <Form.Group as={Col} md={6} className={this.state.websites.length <= 1 ? 'visually-hidden' : ''}>
                                                        <FormattedMessage id={"CMS.User.Form.website"}>
                                                            {(value) => <Form.Label>{value}</Form.Label>}
                                                        </FormattedMessage>
                                                        <Select
                                                            name={"websites"}
                                                            value={this.state.websites.map(website => website && values.websites.indexOf(website.value) > -1 ? website : null).filter(x => x)}
                                                            onChange={(event) => {
                                                                handleChange(event);

                                                                values.websites = [];
                                                                if (event) {
                                                                    event.forEach(option => values.websites.push(option.value));
                                                                }

                                                                let user = this.state.user;
                                                                user.websites = values.websites;
                                                                this.setState({
                                                                    user: user
                                                                });
                                                            }}
                                                            options={this.state.websites}
                                                            isInvalid={!!errors.websites}
                                                            isMulti
                                                            onBlur={handleBlur}
                                                            className={errors.websites ? 'react-select-container is-invalid' : 'react-select-container'}
                                                            classNamePrefix="react-select"
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.websites && <FormRequiredError field_name={"CMS.User.Form.website"} />}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Col md={6} />
                                                </>)}
                                            <Form.Group as={Col} md={6}>
                                                <FormattedMessage id={"CMS.User.Form.type"}>
                                                    {
                                                        (value) => <Form.Label>{value}</Form.Label>
                                                    }
                                                </FormattedMessage>
                                                <Form.Control as="select" name="type"
                                                    value={typeof values.type === "object" ? "editor" : values.type}
                                                    onChange={event => {
                                                        handleChange(event);
                                                        let role_number = UserRolesEnum[event.currentTarget.value.toUpperCase()];
                                                        this.setState({
                                                            type: role_number
                                                        })
                                                    }}
                                                    isInvalid={!!errors.type}
                                                    onBlur={handleBlur}
                                                    disabled={this.props.slug === "managers/resellers/overview"}>
                                                    {Object.entries(UserTypeEnum).map(userType => {
                                                        let role_number = UserRolesEnum[userType[0]];
                                                        if (role_number === UserRolesEnum.WIZARD) {
                                                            return null;
                                                        }
                                                        if (role_number === UserRolesEnum.RESELLER) {
                                                            if (this.props.slug !== "managers/resellers/overview" || !CheckPermissions.role(this.props.user.roles, UserRolesEnum.WIZARD)) {
                                                                return null;
                                                            }
                                                        }
                                                        if (role_number === UserRolesEnum.MANAGER) {
                                                            if (!CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER)) {
                                                                return null;
                                                            }
                                                        }
                                                        return (
                                                            <FormattedMessage id={userType[1].value_id}
                                                                key={userType[1].key}>
                                                                {
                                                                    (value) => (
                                                                        <option value={userType[1].key}>
                                                                            {value}
                                                                        </option>
                                                                    )
                                                                }
                                                            </FormattedMessage>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                        :
                                        <>
                                            <Form.Group as={Col} md={6}>
                                                <FormattedMessage id={"CMS.Reseller.overview.single.title"}>
                                                    {
                                                        (value) => <Form.Label>{value}</Form.Label>
                                                    }
                                                </FormattedMessage>
                                                <Select
                                                    name={"reseller"}
                                                    value={this.state.resellers.find(({ value }) => value + "" === values.reseller)}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        let user = this.state.user;
                                                        user.reseller = event.value;
                                                        this.setState({
                                                            user: user
                                                        });
                                                        values.reseller = event.value;
                                                    }}
                                                    options={this.state.resellers}
                                                    isInvalid={!!errors.reseller}
                                                    onBlur={handleBlur}
                                                    className={errors.reseller ? 'react-select-container is-invalid' : 'react-select-container'}
                                                    classNamePrefix="react-select"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.reseller && (
                                                        <FormRequiredError
                                                            field_name={"CMS.Reseller.overview.single.title"} />)}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </>
                                    }
                                    <Col md={6} />
                                    <Form.Group as={Col} md={6}>
                                        <FormattedMessage id={"CMS.User.Form.language"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        <Select
                                            name={"language"}
                                            value={this._languages.find(({ value }) => value + "" === values.language)}
                                            onChange={(event) => {
                                                handleChange(event);
                                                let user = this.state.user;
                                                user.language = event.value;
                                                this.setState({
                                                    user: user
                                                });
                                                values.language = event.value;
                                            }}
                                            isInvalid={!!errors.language}
                                            onBlur={handleBlur}
                                            options={this._languages}
                                            className={'react-select-container'}
                                            classNamePrefix="react-select"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.language}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Col md={6} />
                                    <Form.Group as={Col} md={6}>
                                        <FormattedMessage id={"CMS.User.Form.firstname"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={"CMS.User.Form.firstname.placeholder"}>
                                            {
                                                (value) => <Form.Control type="text" name="contact.first_name"
                                                    value={values.contact.first_name || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.contact && !!errors.contact.first_name}
                                                    onBlur={handleBlur}
                                                    placeholder={value} />
                                            }
                                        </FormattedMessage>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contact && touched.contact && (
                                                <FormFirstnameError error={errors.contact.first_name} />)}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md={6}>
                                        <FormattedMessage id={"CMS.User.Form.lastname"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={"CMS.User.Form.lastname.placeholder"}>
                                            {
                                                (value) => <Form.Control type="text" name="contact.last_name"
                                                    value={values.contact.last_name || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.contact && !!errors.contact.last_name}
                                                    onBlur={handleBlur} placeholder={value} />
                                            }
                                        </FormattedMessage>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contact && touched.contact && (
                                                <FormLastnameError error={errors.contact.last_name} />)}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {
                                        this.state.type === UserRolesEnum.RESELLER ?
                                            <>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.User.Form.company"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.User.Form.company.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="contact.company_name"
                                                                value={values.contact.company_name || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact && !!errors.contact.company_name}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && errors.contact.company_name && (
                                                            <FormContactCompanyNameError
                                                                error={errors.contact.company_name} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.User.Form.vat_number"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.User.Form.vat_number.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="contact.vat_number"
                                                                value={values.contact.vat_number || ''}
                                                                onChange={handleChange}
                                                                isInvalid={(!!errors.contact && !!errors.contact.vat_number) || this.state.form_errors.contact_tax || this.state.form_errors.tax}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (
                                                            <FormRequiredError field_name={"CMS.User.Form.vat_number"} />)}
                                                        {this.state.form_errors.contact_tax && (
                                                            <p>{this.state.form_errors.contact_tax}</p>
                                                        )}
                                                        {this.state.form_errors.tax && (
                                                            <p>{this.state.form_errors.tax}</p>
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </> :
                                            null
                                    }

                                    {
                                        this.state.type === UserRolesEnum.RESELLER ?
                                            <>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.address.form.street"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.Profile.address.form.street.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="address.street"
                                                                value={values.address.street || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.address && !!errors.address.street}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address && touched.address && (
                                                            <FormStreetError error={errors.address.street} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Col md={5}>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md={6}>
                                                            <FormattedMessage id={"CMS.Profile.address.form.number"}>
                                                                {
                                                                    (value) => <Form.Label>{value}</Form.Label>
                                                                }
                                                            </FormattedMessage>
                                                            <Form.Control type="text" name="address.number"
                                                                value={values.address.number || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.address && !!errors.address.number}
                                                                onBlur={handleBlur} />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address && touched.address && (
                                                                    <FormStreetNumberError error={errors.address.number} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md={6}>
                                                            <FormattedMessage id={"CMS.Profile.address.form.zip"}>
                                                                {
                                                                    (value) => <Form.Label>{value}</Form.Label>
                                                                }
                                                            </FormattedMessage>
                                                            <Form.Control type="text" name="address.zip"
                                                                value={values.address.zip || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.address && !!errors.address.zip}
                                                                onBlur={handleBlur} />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address && touched.address && (
                                                                    <FormZipError error={errors.address.zip} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Col>

                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.address.form.city"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.Profile.address.form.city.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="address.city"
                                                                value={values.address.city || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.address && !!errors.address.city}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address && touched.address && (
                                                            <FormCityError error={errors.address.city} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.address.form.country"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <Select
                                                        value={this._countries.find(({ value }) => value + "" === values.address.country)}
                                                        name="address.country"
                                                        onChange={event => {
                                                            handleChange(event);
                                                            let user = this.state.user;
                                                            user.address.country = event.value;
                                                            this.setState({
                                                                user: user
                                                            });
                                                            values.address.country = event.value;
                                                        }}
                                                        isInvalid={!!errors.address && !!errors.address.country}
                                                        onBlur={handleBlur}
                                                        options={this._countries}
                                                        className={errors.address && errors.address.country ? 'react-select-container is-invalid' : 'react-select-container'}
                                                        classNamePrefix="react-select"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address && touched.address && (
                                                            <FormRequiredError
                                                                field_name={"CMS.Profile." + errors.address.country} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.phone"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.phone.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="contact.phone"
                                                                value={values.contact.phone || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact && !!errors.contact.phone}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (
                                                            <FormContactPhoneError error={errors.contact.phone} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.mobile"}>
                                                        {
                                                            (value) => <Form.Label>{value}</Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.mobile.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text" name="contact.mobile"
                                                                value={values.contact.mobile || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact && !!errors.contact.mobile}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (
                                                            <FormContactPhoneError error={errors.contact.mobile} field_name={"CMS.Profile." + errors.contact.mobile} />)}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                            :
                                            null
                                    }
                                    <Form.Group as={Col} md={6}>
                                        <FormattedMessage id={"CMS.Profile.contact.form.email"}>
                                            {
                                                (value) => <Form.Label>{value}</Form.Label>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={"CMS.Profile.contact.form.email.placeholder"}>
                                            {
                                                (value) => <Form.Control type="text" name="email" value={values.email || ''}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email || !!this.state.form_errors.email}
                                                    onBlur={handleBlur}
                                                    placeholder={value} />
                                            }
                                        </FormattedMessage>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email && touched.email && (
                                                <FormContactEmailError error={errors.email} />
                                            )}
                                            {this.state.form_errors !== [] && this.state.form_errors.email && (
                                                <p>{this.state.form_errors.email}</p>
                                            )}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {
                                        this.state.type === UserRolesEnum.RESELLER ?
                                            <>
                                                <Form.Group as={Col} md={6}>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.email2"}>
                                                        {
                                                            (value) =>
                                                                <Form.Label>
                                                                    {value}
                                                                    <FormattedMessage id={"CMS.Form.optional"}>
                                                                        {
                                                                            (optional) => <span
                                                                                className={"optional"}>*{optional}</span>
                                                                        }
                                                                    </FormattedMessage>
                                                                </Form.Label>
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id={"CMS.Profile.contact.form.email2.placeholder"}>
                                                        {
                                                            (value) => <Form.Control type="text"
                                                                name="contact.alternative_email"
                                                                value={values.contact.alternative_email || ''}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.contact && !!errors.contact.alternative_email}
                                                                onBlur={handleBlur}
                                                                placeholder={value} />
                                                        }
                                                    </FormattedMessage>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contact && touched.contact && (
                                                            <FormEmailError field_name={"CMS.Profile." + errors.contact.alternative_email} />)}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </> :
                                            <Col md={6} />
                                    }
                                    {
                                        this.state.type === UserRolesEnum.RESELLER && this.props.slug !== "customers/overview" ?
                                            <Form.Group as={Col} md={12}>
                                                <FormattedMessage id={"CMS.Profile.contact.form.url"}>
                                                    {value => <Form.Label>{value}</Form.Label>}
                                                </FormattedMessage>
                                                <FormattedMessage id={"CMS.Profile.contact.form.url.placeholder"}>
                                                    {value =>
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="url-addon">
                                                                    https://
                                                                </InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="url"
                                                                name="contact.url"
                                                                value={values.contact.url || ''}
                                                                onChange={handleChange}
                                                                isInvalid={(!!errors.contact && !!errors.contact.url) || errors.url}
                                                                onBlur={handleBlur}
                                                                placeholder={value}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.contact && touched.contact && (
                                                                    <UserFormError error={errors.contact.url} field_name={"CMS.Profile." + errors.contact.url} />)}

                                                                {errors.url && <FormWebsiteError field_name={"CMS.Profile." + errors.url.slice(0, -8)} />}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    }
                                                </FormattedMessage>
                                            </Form.Group>
                                            : null

                                    }
                                    {/* WILL BE ADDED IN SPRINT 3 (MEDIA) */}
                                    {/*<Form.Group as={Col} md={4}>*/}
                                    {/*    <FormattedMessage id={"CMS.User.Form.profile_picture.placeholder"}>*/}
                                    {/*        {*/}
                                    {/*            (value) => <Form.Label>{value}</Form.Label>*/}
                                    {/*        }*/}
                                    {/*    </FormattedMessage>*/}
                                    {/*    {this.getProfilePicture()}*/}
                                    {/*</Form.Group>*/}
                                    {/*<Form.Group as={Col} md={8}>*/}
                                    {/*    <Form.Label className={"hidden"}>placeholder</Form.Label>*/}
                                    {/*    <Dropzone accept={'image/*'} onDrop={acceptedFiles => {*/}
                                    {/*        this.setState(prevState => ({*/}
                                    {/*            user: {*/}
                                    {/*                ...prevState.user,*/}
                                    {/*                profile_picture_url: URL.createObjectURL(acceptedFiles[0])*/}
                                    {/*            }*/}
                                    {/*        }), () => {*/}
                                    {/*        })*/}
                                    {/*    }}>*/}
                                    {/*        {({getRootProps, getInputProps}) => (*/}
                                    {/*            <section className={"media-drag-drop profile-picture-drag-drop"}>*/}
                                    {/*                <div {...getRootProps()}*/}
                                    {/*                     className={"media-drag-drop-inner profile-picture-drag-drop-inner"}>*/}
                                    {/*                    /!*<input {...getInputProps()} />*!/*/}
                                    {/*                    <Form.Control {...getInputProps()} name="user.profile_picture_url"*/}
                                    {/*                                  value={values.profile_picture || ''}*/}
                                    {/*                                  onChange={handleChange}*/}
                                    {/*                                  isInvalid={!!errors.profile_picture}*/}
                                    {/*                                  onBlur={handleBlur}/>*/}
                                    {/*                    <Row>*/}
                                    {/*                        <Col sm={3}>*/}
                                    {/*                            <div className={"icon-wrapper"}>*/}
                                    {/*                                <FontAwesomeIcon icon={['fas', 'arrow-up']}/>*/}
                                    {/*                            </div>*/}
                                    {/*                        </Col>*/}
                                    {/*                        <Col sm={9}>*/}
                                    {/*                            <FormattedMessage*/}
                                    {/*                                id={"CMS.User.Form.profile_picture.title"}>*/}
                                    {/*                                {*/}
                                    {/*                                    (value) => <h1>{value}</h1>*/}
                                    {/*                                }*/}
                                    {/*                            </FormattedMessage>*/}
                                    {/*                            <FormattedMessage*/}
                                    {/*                                id={"CMS.User.Form.profile_picture.title.sub"}>*/}
                                    {/*                                {*/}
                                    {/*                                    (value) => <h2>{value}</h2>*/}
                                    {/*                                }*/}
                                    {/*                            </FormattedMessage>*/}
                                    {/*                        </Col>*/}
                                    {/*                        <Col>*/}
                                    {/*                            <FormattedMessage id={"CMS.User.Form.profile_picture.text"}*/}
                                    {/*                                              values={{*/}
                                    {/*                                                  pixels: "600x600 px",*/}
                                    {/*                                                  size: "3mb"*/}
                                    {/*                                              }}>*/}
                                    {/*                                {*/}
                                    {/*                                    (value) => <p>{value}</p>*/}
                                    {/*                                }*/}
                                    {/*                            </FormattedMessage>*/}
                                    {/*                        </Col>*/}
                                    {/*                    </Row>*/}

                                    {/*                </div>*/}
                                    {/*            </section>*/}
                                    {/*        )}*/}
                                    {/*    </Dropzone>*/}
                                    {/*    <Form.Control.Feedback type="invalid">*/}
                                    {/*        {errors.profile_picture}*/}
                                    {/*    </Form.Control.Feedback>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group as={Col} xs={12} controlId="formSubmit">
                                        <FormattedMessage id={this.props.single_title_id}>
                                            {
                                                (value) =>
                                                    <FormattedMessage
                                                        id={this.props.id != null ? "CMS.Form.overview.edit.text" : "CMS.User.overview.add.btn"}
                                                        values={{ "text": value }}>
                                                        {
                                                            (value) => <Button type="submit" onClick={() => FormHelper.submitFormErrorToast(this)}>{value}</Button>
                                                        }
                                                    </FormattedMessage>
                                            }
                                        </FormattedMessage>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        )
    }
}

FormComponent.propTypes = {
    single_title_id: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    id: PropTypes.any,
    get_promise: PropTypes.any
};
export default withRouter(FormComponent);
