import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const FormRequiredError = (props) => (
    <FormattedMessage id={props.field_name}>
        {field =>
            <FormattedMessage id="CMS.Form.field.required" values={{ field: field }}>
                {message => <p>{message}</p>}
            </FormattedMessage>
        }
    </FormattedMessage>
)

FormRequiredError.propTypes = {
    field_name: PropTypes.string.isRequired,
};
export default FormRequiredError