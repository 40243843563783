class UserTableRow {
    id;
    key;
    name;
    language;
    company;
    email;
    rows = [];
    role;
    websiteId;
    lastLogin;
    parentIndex = null;
    deletable = true;

    constructor(id, key, name, language, company, email, lastLogin, role, rows, websiteId = null, deletable = true) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.language = language;
        this.company = company;
        this.email = email;
        this.lastLogin = lastLogin;
        this.role = role;
        this.rows = rows;
        this.websiteId = websiteId;
        this.deletable = deletable;
    }
}

export default UserTableRow;