import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";
import FormattedMessageString from "../FormattedMessageString";
import { Application } from "../../../helpers";

class StandardFormEditorUploadComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            non_filtered_row: this.props.rows,
            rows: this.fixRows()
        }

        this.table_id = "standard-from-editor-upload-table-" + Application.generateUniqueId();
    }

    fixRows() {
        let parent = this;
        return this.props.rows.filter(function (media) {
            return media.name.toLowerCase().indexOf(parent.props.parent.state.search.toLowerCase()) >= 0;
        })
    }

    componentDidMount() {
        this.initDraggable();
    }

    componentDidUpdate() {
        this.initDraggable();
    }

    initDraggable() {
        let table = document.getElementById(this.table_id);
        if (!table) return;

        let rows = table.getElementsByTagName('tr');

        for (let i = 0; i < rows.length; i++) {
            let parent = this;
            let row = rows[i];
            row.setAttribute('draggable', true);
            row.addEventListener("dragstart", (event) => {
                event.dataTransfer.dropEffect = "move";
                event.dataTransfer.effectAllowed = "move";

                let id = row.getElementsByTagName('td')[0].getAttribute('data-id')
                parent.props.parent.setState({
                    media_drag: id
                })
            });
        }
    }

    reload = () => this.setState({ reload: true }, () => {
        this.props.parent.initImages().then(() => {
            this.setState({ rows: this.fixRows(), reload: false })
        });
    });

    filterRow = () => {
        let parent = this;

        this.setState({
            rows: this.state.non_filtered_row.filter(media => {
                return media.name.toLowerCase().indexOf(parent.props.parent.state.search.toLowerCase()) >= 0;
            })
        })
    }

    render() {
        if (this.state.reload) return 'reloading...';

        return (
            <div className="page-edit-upload">
                <div className="header">
                    <FormattedMessage id="CMS.Page.Content.Upload.edit.title">
                        {(value) => <p className="title">{value}</p>}
                    </FormattedMessage>
                    <div className="search-wrapper">
                        <FontAwesomeIcon icon={['far', 'search']} />
                        <FormattedMessage id="CMS.Page.Content.Upload.edit.search.placeholder">
                            {(value) => <Form.Control type={"text"} value={this.props.parent.state.search} placeholder={value} onChange={event => {
                                this.props.parent.setState({ search: event.target.value }, () => this.filterRow())
                            }} />}
                        </FormattedMessage>
                    </div>
                    <Link to="#" className="upload ml-auto" onClick={event => {
                        event.preventDefault();
                        this.props.parent.setState({ showFormModal: true })
                    }}>
                        <FontAwesomeIcon icon={['far', "upload"]} />
                    </Link>
                    <Link to="#" className="toggle" onClick={(event) => {
                        event.preventDefault()
                        this.setState({ open: !this.state.open })
                    }}>
                        <FontAwesomeIcon icon={['far', this.state.open ? 'angle-up' : 'angle-down']} />
                    </Link>
                </div>
                <div className={this.state.open ? "files-content" : "d-none"}>
                    <BootstrapTable id={this.table_id} bootstrap4 keyField="id" data={this.state.rows}
                        columns={[
                            {
                                dataField: 'id',
                                text: '',
                                formatter: (cellContent, row) => {
                                    return <FontAwesomeIcon icon={['far', 'arrows-alt']} />
                                },
                                attrs: (cell, row) => ({ 'data-id': row.id })
                            },
                            {
                                dataField: 'title',
                                text: <FormattedMessageString id="CMS.Media.overview.file.name" />,
                                sort: true
                            },
                            {
                                dataField: 'type',
                                text: <FormattedMessageString id="CMS.Media.overview.file.type" />,
                                sort: true,
                                formatter: (cellContent, row) => {
                                    let type = row.external ? row.type : row.name.split('.').pop();
                                    return <FormattedMessage id={"CMS.Media.overview.file.name." + type}>
                                        {(value) => <>{value}</>}
                                    </FormattedMessage>;
                                }
                            }
                        ]}
                        defaultSorted={[{
                            dataField: 'name',
                            order: 'desc'
                        }]}
                    />
                </div>
            </div>
        );
    }
}

export default StandardFormEditorUploadComponent;