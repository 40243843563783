import React from "react";
import { Route, withRouter } from "react-router-dom";
import CheckPermissions from "../../helpers/CheckPermissions";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import PermissionChecker from "./PermissionChecker";

class RoleRoute extends React.Component {
    render() {
        if (!CheckPermissions.role(this.props.roles, this.props.role)) {
            return null;
        }

        let defaultCheck = CheckPermissions.role(this.props.roles, this.props.role) || (this.props.extraCheck != null && this.props.extraCheck);

        if (
            (defaultCheck && !GeneralSettingsHelper.is()) ||
            (defaultCheck && this.props.canShowInGeneralSettings)
        ) {
            return (
                <PermissionChecker {...this.props}>
                    <Route {...this.props}>
                        {this.props.children}
                    </Route>
                </PermissionChecker>
            );
        }

        return null;
    }
}

export default withRouter(RoleRoute);