import React from "react";
import { Formik } from "formik";
import Media from "../../../models/media/Media";
import Form from "react-bootstrap/Form";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import MediaFormNameError from "../../media/errors/MediaFormNameError";
import { Button, Image as BsImage, InputGroup } from "react-bootstrap";
import FormHelper from "../../../helpers/FormHelper";
import FormToastComponent from "../../basics/FormToastComponent";
import MediaRepository from "../../../repository/MediaRepository";
import PageRepository from "../../../repository/PageRepository";
import { withRouter } from "react-router-dom";
import MediaHelper from "../../../helpers/MediaHelper";
import CustomTextArea from "../../basics/CustomTextArea";
import JsonHelper from "../../../helpers/JsonHelper";
import ToolTipInfo from "../../basics/ToolTipInfo";
import EyeCatcherUrlInternalFormComponent from "./EyeCatcherUrlInternalFormComponent";
import LoaderComponent from "../../basics/layout/LoaderComponent";

class EyeCatcherUploadComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            media: {}
        }
    }

    componentDidMount() {
        let media = this.state.media;

        if (this.props.text !== undefined) {
            if (this.props.text.id !== undefined && this.props.text.id != null) {
                media.id = this.props.text.id
            }
            if (this.props.text.title !== undefined && this.props.text.title != null) {
                media.title = this.props.text.title
            }
            if (this.props.text.text !== undefined && this.props.text.text != null) {
                media.text = this.props.text.text
            }
            if (this.props.text.url !== undefined && this.props.text.url != null) {
                media.url = this.props.text.url
            }
            if (this.props.text.url_text !== undefined && this.props.text.url_text != null) {
                media.url_text = this.props.text.url_text
            }
            if (this.props.text.language !== undefined && this.props.text.language != null) {
                media.language = this.props.text.language
            }
        }

        if (this.props.media_item !== undefined && this.props.media_item.media !== undefined && this.props.media_item.media.url !== undefined) {
            media.existing_media_url = this.props.media_item.media.url;
        }

        MediaRepository.getSpecificSettings("eye_catcher", this.props.parent.props.id)
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.settings !== undefined) {
                    this.setState({ settings: response.data.settings, isLoading: false, media: media })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(() => FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found"))
    }

    editText = (page_id, values) => {
        return new Promise((resolve, reject) => {
            if (this.props.media_item.text.length > 0) {
                PageRepository.editEyeCatcher(page_id, values.id, values)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(reason => reject(reason))
            } else {
                PageRepository.addEyeCatcherText(page_id, this.props.media_item.media.id, values)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(reason => reject(reason))
            }
        })
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <div className="custom-form media eye-catcher">
                <Formik
                    enableReinitialize
                    validationSchema={this.props.media_item !== undefined ? Media.eye_catcher_schema_edit : Media.eye_catcher_schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={(values, formikActions) => {
                        let page_id = this.props.match.params.id
                        if (this.props.media_item !== undefined) { // edit
                            if (values.media !== undefined) { // edit media
                                MediaRepository.upload(values.media, 'eye_catcher', 'page', page_id)
                                    .then(media_response => {
                                        if (media_response.data !== undefined && media_response.data.media !== undefined) {
                                            PageRepository.editMediaEyeCatcher(page_id, this.props.media_item.media.id, media_response.data.media.id)
                                                .then(response => {
                                                    if (response !== undefined) {
                                                        this.editText(page_id, values)
                                                            .then(text_response => {
                                                                let self = this;
                                                                this.props.parent.closeEditModal()
                                                                setTimeout(function () {
                                                                    self.props.parent.props.parent.props.parent.reload()
                                                                }, 200);
                                                            })
                                                            .catch(error => console.error(error))
                                                    }
                                                })
                                                .catch(error => console.error(error))
                                        }
                                    })
                            } else { // only change text
                                this.editText(page_id, values)
                                    .then(text_response => {
                                        let self = this;
                                        FormToastComponent.successTrans('Eyecatcher', 'CMS.Page.eyecatcher.upload.edit.success');
                                        this.props.parent.closeEditModal()
                                        setTimeout(function () {
                                            self.props.parent.props.parent.props.parent.reload()
                                        }, 200);
                                    })
                                    .catch(error => console.error(error))
                            }

                        } else { // add
                            MediaRepository.upload(values.media, "eye_catcher", 'page', page_id)
                                .then(media_response => {
                                    if (media_response.data !== undefined && media_response.data.media !== undefined) {
                                        PageRepository.createEyeCatcher(page_id, media_response.data.media.id, this.props.parent.state.currentModalLanguage, values)
                                            .then(page_response => {
                                                FormToastComponent.successTrans('Eyecatcher', 'CMS.Page.eyecatcher.upload.add.success')
                                                this.props.parent.closeModal(this.props.parent.reload())
                                            })
                                            .catch(error => console.error(error))
                                    }
                                })
                                .catch(error => {
                                    if (error.response !== undefined && error.response.data !== undefined) {
                                        let error_message = JsonHelper.tryParse(error.response.data.message);
                                        if (error_message.file && error_message.file.length > 0) {
                                            error_message.file.forEach(error => {
                                                FormToastComponent.error(error);
                                            })
                                        } else if (error.response.data.message) {
                                            FormToastComponent.error(error.response.data.message);
                                        } else {
                                            FormToastComponent.errorTrans("CMS.Media.Album.invalid.file")
                                        }
                                    } else if (error.message !== undefined && error.message) {
                                        FormToastComponent.error(error.message);
                                    } else {
                                        FormToastComponent.errorTrans("CMS.Media.Album.invalid.file")
                                    }
                                })
                        }

                    }}
                    initialValues={this.state.media}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => {
                        return (
                            <Form noValidate onSubmit={handleSubmit} className={"w-100"}>
                                <Form.Group>
                                    {this.state.media.existing_media_url !== undefined && (
                                        <div className="placeholder-image">
                                            <BsImage src={this.state.media.existing_media_url} fluid={true} />
                                        </div>
                                    )}
                                    <Dropzone
                                        multiple={false}
                                        accept={MediaHelper.acceptedMimeTypes(this.state.settings)}
                                        maxSize={this.state.settings?.max_size}
                                        noKeyboard
                                        onDrop={(acceptedFiles, rejectedFiles) => {
                                            if (acceptedFiles.length > 0) {
                                                values.media = acceptedFiles[0];
                                                let media = this.state.media;

                                                MediaHelper.handleDropAccepted(acceptedFiles[0], { minWidth: this.state.settings.min_width, minHeight: this.state.settings.min_height }, (result) => {
                                                    media.existing_media_url = result;

                                                    this.setState({ media: media })
                                                });
                                            }

                                            MediaHelper.handleDropRejected(rejectedFiles, this.state.settings);
                                        }}>
                                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                                            return (
                                                <>
                                                    <section className={errors.media ? "media-drag-drop invalid" : "media-drag-drop"} {...getRootProps()}>
                                                        <div className="media-drag-drop-inner">
                                                            <div className="media-drag-drop-inner-row">
                                                                <div className="media-drag-drop-icon">
                                                                    <div className="icon-wrapper">
                                                                        <FontAwesomeIcon icon={['fas', 'arrow-up']} />
                                                                    </div>
                                                                </div>
                                                                <div className="media-drag-drop-text">
                                                                    <FormattedMessage id="CMS.Media.Form.media_dragdrop.title">
                                                                        {(value) => <h1>{value}</h1>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="CMS.Media.Form.media_dragdrop.title.sub">
                                                                        {(value) => <h2>{value}</h2>}
                                                                    </FormattedMessage>
                                                                </div>
                                                            </div>
                                                            <div className="media-drag-drop-requirements">
                                                                <p className="mt-3">{MediaHelper.assembleRequirements(this.state.settings)}</p>
                                                            </div>
                                                            <Form.Control {...getInputProps()} />
                                                        </div>
                                                    </section>
                                                </>
                                            )
                                        }}
                                    </Dropzone>
                                </Form.Group>
                                <Form.Group>
                                    <FormattedMessage id="CMS.Form.optional">
                                        {(optional_text) =>
                                            <FormattedMessage id="CMS.Page.eyecatcher.upload.title">
                                                {(value) =>
                                                    <Form.Label>
                                                        {value}
                                                        <span className={"optional"}>*{optional_text}</span>
                                                    </Form.Label>}
                                            </FormattedMessage>
                                        }
                                    </FormattedMessage>
                                    <FormattedMessage id="CMS.Page.eyecatcher.upload.title.placeholder">
                                        {(value) =>
                                            <Form.Control type="text" name="title"
                                                value={values.title || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors.title}
                                                onBlur={handleBlur}
                                                placeholder={value} />
                                        }
                                    </FormattedMessage>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title && <MediaFormNameError error={errors.title} />}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <FormattedMessage id="CMS.Page.eyecatcher.upload.text">
                                        {(value) =>
                                            <Form.Label className="position-relative">
                                                {value}
                                                <ToolTipInfo />
                                            </Form.Label>
                                        }
                                    </FormattedMessage>
                                    <FormattedMessage id="CMS.Page.eyecatcher.upload.text.placeholder">
                                        {(value) =>
                                            <CustomTextArea name="text" minHeight={36}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!errors.text}
                                                placeholder={value}
                                                defaultValue={values.text}
                                            />
                                        }
                                    </FormattedMessage>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.text && <MediaFormNameError error={errors.text} />}
                                    </Form.Control.Feedback>
                                    <FormattedMessage id="CMS.Page.eyecatcher.upload.text.info">
                                        {(value) => <p className="input-info">{value}</p>}
                                    </FormattedMessage>
                                </Form.Group>
                                <Form.Group>
                                    <FormattedMessage id="CMS.Form.optional">
                                        {(optional_text) =>
                                            <FormattedMessage id="CMS.Page.eyecatcher.upload.url">
                                                {(value) =>
                                                    <Form.Label>
                                                        {value}
                                                        <span className={"optional"}>*{optional_text}</span>
                                                    </Form.Label>
                                                }
                                            </FormattedMessage>
                                        }
                                    </FormattedMessage>
                                    <InputGroup className={!!errors.url ? 'is-invalid' : ''}>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="url-addon">
                                                https://
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormattedMessage id="CMS.Page.eyecatcher.upload.url.placeholder">
                                            {(value) =>
                                                <Form.Control type="text" name="url"
                                                    value={values.url || ''} placeholder={value}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.url}
                                                    onBlur={handleBlur}
                                                />
                                            }
                                        </FormattedMessage>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.url && <MediaFormNameError error={errors.url} />}
                                    </Form.Control.Feedback>

                                    <EyeCatcherUrlInternalFormComponent parent={this} language={this.state.media.id ? this.state.media.language : this.props.parent.state.currentModalLanguage} />
                                </Form.Group>
                                <Form.Group>
                                    <FormattedMessage id="CMS.Form.optional">
                                        {(optional_text) => <FormattedMessage id="CMS.Page.eyecatcher.upload.url_text">
                                            {(value) =>
                                                <Form.Label>{value}
                                                    <span className={"optional"}>*{optional_text}</span>
                                                </Form.Label>
                                            }
                                        </FormattedMessage>}
                                    </FormattedMessage>

                                    <FormattedMessage id="CMS.Page.eyecatcher.upload.url_text.placeholder">
                                        {(value) =>
                                            <Form.Control type="text" name="url_text"
                                                value={values.url_text || ''}
                                                onChange={handleChange}
                                                isInvalid={!!errors.url_text}
                                                onBlur={handleBlur}
                                                placeholder={value}
                                            />
                                        }
                                    </FormattedMessage>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.url_text && <MediaFormNameError error={errors.url_text} />}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <div className="actions">
                                        <FormattedMessage id="Default.cancel">
                                            {(value) =>
                                                <Button variant="secondary"
                                                    onClick={() => this.props.parent.setState({ showModal: false, show_edit: false })}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                        <FormattedMessage id={this.props.media_item ? "CMS.Media.Form.edit" : "CMS.Media.Form.add"}>
                                            {(value) =>
                                                <Button type="button" onClick={() => {
                                                    this.form.handleSubmit();
                                                    FormHelper.submitFormErrorToast(this)
                                                }}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                    </div>
                                </Form.Group>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EyeCatcherUploadComponent);