import API from "../services/api";

export default class TeamRepository {
    static all = (all) => API.get('/team/all?all=' + (all ? 1 : 0)).then(response => response.data.team);
    static get = (id) => API.get('/team/details/' + id).then(respone => respone.data.team);

    static create = (data) => API.post('/team/create', data);
    static update = (data) => API.post('/team/update', data).then(response => response.data);
    static updateTree = (data) => API.post('/team/update/order', { tree: data });
    static toggle = (id) => API.post('/team/toggle', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.active;
            }
        });

    static delete = (id) => API.delete('/team/delete?id=' + id);
}