import React from "react";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import EyeCatcherUploadComponent from "./EyeCatcherUploadComponent";
import DeleteModalComponent from "../../basics/table/DeleteModalComponent";
import PageRepository from "../../../repository/PageRepository";
import FormToastComponent from "../../basics/FormToastComponent";
import { Link } from "react-router-dom";
import ContentHelper from "../../../helpers/ContentHelper";

class EyeCatcherItemComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show_tool_tip: false,
            show_edit: false
        }
    }

    componentDidMount() {
        let parent = this;
        window.addEventListener("click", function (event) {
            if (parent.state.show_tool_tip && event.target.closest('.info-tooltip') == null && event.target.closest('.info-icon') == null) {
                parent.setState({ show_tool_tip: false })
            }
        });
    }

    toggleToolTip = () => this.setState({ show_tool_tip: !this.state.show_tool_tip })
    closeToolTip = () => this.setState({ show_tool_tip: false })

    openEditModal = () => this.setState({ show_edit: true })
    closeEditModal = () => this.setState({ show_edit: false })

    openDeleteModal = () => this.setState({ showDeleteModal: true })

    render() {
        return (
            <div className="custom-images-table-item" style={{ cursor: "pointer" }}
                data-key={this.props.media_item.media !== undefined ? this.props.media_item.media.id : this.props.media_item.id}
                draggable
                onDragStart={(e) => this.props.onDragStart(e, this.props.index)}
                onDragOver={(e) => this.props.onDragOver(e)}
                onDrop={(e) => this.props.onDrop(e, this.props.index)}

            >
                <div className={"custom-images-table-item-inner"}>
                    <div className={"image"}>
                        <Image src={this.props.image_url != null ? this.props.image_url : "https://via.placeholder.com/155x100"} fluid={true} />
                    </div>
                    {this.props.isText ?
                        <Link className="edit icon" to="#" onClick={this.openEditModal}>
                            <FontAwesomeIcon icon={['far', 'edit']} />
                        </Link>
                        : null
                    }
                    <Link className="delete icon" to="#" onClick={this.openDeleteModal}>
                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                    </Link>
                    {this.props.text !== undefined && this.props.isText ?
                        <>
                            <Link className={"info-icon"} to="#" onClick={this.toggleToolTip}>
                                <FontAwesomeIcon icon={['fas', 'info-circle']} />
                            </Link>
                            <div
                                className={this.state.show_tool_tip === true ? "info-tooltip show" : "info-tooltip"}>
                                <p className="title">{this.props.text.title}</p>
                                <div className="text">
                                    <p dangerouslySetInnerHTML={{ __html: ContentHelper.replaceTags(this.props.text.text) }}></p>
                                </div>
                                <a target="_blank" className="link" href={ContentHelper.formatUrl(this.props.text.url)} rel="noopener noreferrer">
                                    {this.props.text.url_text ? this.props.text.url_text : this.props.text.url}
                                </a>
                                <Link to="#" onClick={this.closeToolTip} className="close">
                                    <FontAwesomeIcon icon={['fal', 'times']} />
                                </Link>
                            </div>

                        </>
                        : null
                    }
                    {this.props.isText ?
                        <ModalFormFragment title_id={"CMS.Page.eyecatcher.modal.title.edit"}
                            show={this.state.show_edit} onHide={this.closeEditModal} size="lg">
                            <EyeCatcherUploadComponent {...this.props} parent={this} media_item={this.props.media_item} text={this.props.text} />
                        </ModalFormFragment>
                        : null
                    }
                    <DeleteModalComponent ref={r => this.deleteRef = r} parent={this}
                        title={"CMS.Page.eyecatcher.modal.title.delete"}
                        text={"CMS.Page.eyecatcher.modal.text.delete"}
                        customDeleteRowFunction={event => {
                            event.preventDefault();

                            let media_item = this.props.media_item;
                            let is_text = media_item !== undefined && media_item.id === undefined && media_item.media !== undefined;
                            if (is_text) {
                                media_item = media_item.media;
                            }

                            PageRepository.deleteEyeCatcher(this.props.parent.props.id, this.props.parent.props.type, media_item, this.props.text)
                                .then(success => {
                                    if (success) {
                                        let media = this.props.parent.props.parent.state.media;

                                        if (is_text) {
                                            media = media.filter(item => item.media.id !== media_item.id)
                                        } else {
                                            media = media.filter(item => item.id !== media_item.id);
                                        }
                                        
                                        FormToastComponent.successTrans('Eyecatcher', 'CMS.Media.Album.Order.deleted.success');

                                        this.props.parent.props.parent.setState({
                                            media: media
                                        }, this.deleteRef.closeModal)
                                    } else {
                                        FormToastComponent.errorTrans("CMS.Page.eyecatcher.modal.error.delete")
                                    }
                                })
                                .catch(() => FormToastComponent.errorTrans("CMS.Page.eyecatcher.modal.error.delete"))
                        }} />
                </div>
            </div>
        );
    }
}

EyeCatcherItemComponent.propTypes = {
    index: PropTypes.number.isRequired,
    media_item: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired,
    isText: PropTypes.bool.isRequired,
    image_url: PropTypes.string.isRequired,
    text: PropTypes.object.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
};
export default EyeCatcherItemComponent;