import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormGreatherThanEqualError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {(field) =>
                    <FormattedMessage id="CMS.Form.field.gte" values={{ field: field, value: this.props.value }}>
                        {(message) => <p>{message}</p>}
                    </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

FormGreatherThanEqualError.propTypes = {
    field_name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};
export default FormGreatherThanEqualError