import * as Yup from "yup"
import ProductContent from "./ProductContent";
import { PriceHelper } from "../../helpers";

export default class Product {
    id;
    internal_id;
    website;
    category;
    active;
    spotlight;
    image;
    article_number;
    unit_price;
    stock;
    contents = [];
    media = [];

    constructor() {
        this.spotlight = false;
        this.content = new ProductContent();
        this.contents = [new ProductContent()];
    }

    static schema = (settings) => {
        let fields = {
            title: Yup.string().required('CMS.Product.Content.Form.title').max(100, 'CMS.Product.Content.Form.title|100|max'),
            unit_price: Yup.number().transform((_, value) => parseFloat(value.replace(/,/g, ''))).typeError('CMS.Product.Content.Form.unit_price|number').required('CMS.Product.Content.Form.unit_price').min(0, 'CMS.Product.Content.Form.unit_price|0|gte'),
            content: Yup.object({ text: Yup.string().required('CMS.Product.Content.Form.content') })
        };

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Product.Content.Form.category|select');
        }

        if (settings.show_article_number) {
            fields.article_number = Yup.string().required('CMS.Product.Content.Form.article_number').max(255, 'CMS.Product.Content.Form.article_number|255|max');
        }
        if (settings.show_stock) {
            fields.stock = Yup.number().integer().required('CMS.Product.Content.Form.stock').min(0, 'CMS.Product.Content.Form.stock|0|gte');
        }
        if (settings.show_summary) {
            fields.summary = Yup.string().required('CMS.Product.Content.Form.summary').max(255, 'CMS.Product.Content.Form.summary|255|max');
        }

        for (let i = 1; i <= 3; i++) {
            if (!settings['show_additional_field_' + i]) continue;
            let label = settings['additional_field_' + i + '_label'];

            let isRequired = settings['additional_field_' + i + '_required'];
            let type = settings['additional_field_' + i + '_type'];

            let validationObject = null;
            if (type === 'editor') {
                validationObject = Yup.object({ text: isRequired ? Yup.string().required(label) : Yup.string().nullable() });
            } else if (isRequired) {
                validationObject = Yup.string().required(label);
            } else {
                validationObject = Yup.string().nullable();
            }

            if (settings['additional_field_' + i + '_type'] === 'url') {
                validationObject = validationObject.test("is-url-without-https-http", label + '|invalid', val => {
                    if (val) {
                        return Yup.object().shape({
                            ['additional_field_' + i + '_value']: Yup.string().url(label + '|invalid')
                        }).validate({ ['additional_field_' + i + '_value']: 'https://' + val.replace('http://', '').replace('https://', '').replace('www.', '') })
                    }

                    return true;
                })
            }

            fields['additional_field_' + i + '_value'] = validationObject;
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Product();

        item.id = json.id;
        item.internal_id = json.internal_id;
        item.website = json.website;
        item.category = json.category;
        item.active = json.active;
        item.spotlight = json.spotlight;
        item.article_number = json.article_number;
        item.unit_price = PriceHelper.price(json.unit_price);
        item.stock = json.stock;
        item.image = json.image;
        item.contents = json.contents.map(content => ProductContent.fromJson(content));
        item.media = json.media;

        return item;
    }
}
