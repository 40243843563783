import React from "react";
import PropTypes from "prop-types";
import SortableTree from "react-sortable-tree";
import TestimonialRepository from "../../repository/TestimonialRepository";
import FormToastComponent from "../basics/FormToastComponent";
import LoaderComponent from "../basics/layout/LoaderComponent";

class TestimonialOverviewTreeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            isLoading: true,
            index: this.props.index
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
            treeData: this.format(this.props.data)
        })
    }

    update(data) {
        TestimonialRepository.updateTree(this.state.treeData)
            .then(response => {
                FormToastComponent.successTrans("Testimonials", "CMS.Testimonials.overview.tree.updated")
                this.props.parent.updateData()
            })
            .catch(error => {
                console.error(error.message);
                FormToastComponent.successTrans("Testimonials", "CMS.Testimonials.overview.tree.failed")
            })
    }

    format(array) {
        let formattedArray = [];

        array.forEach(function (testimonial) {
            testimonial = {
                title: testimonial['title'] + '    ( id {' + testimonial['id'] + '})',
                id: testimonial['id'],
                expanded: true
            }

            formattedArray.push(testimonial)
        })

        return formattedArray;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        
        return (
            <div style={{ height: 500 }}>
                <SortableTree
                    treeData={this.state.treeData}
                    onChange={treeData => this.setState({ treeData })}
                    onMoveNode={(data) => this.update(data)}
                    maxDepth={0}
                />
            </div>
        );
    }

}

TestimonialOverviewTreeComponent.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    parent: PropTypes.object.isRequired
};
export default TestimonialOverviewTreeComponent
