import React from "react";
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from "react-intl";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent } from "../basics/table";
import Website from "../../models/website/Website";
import { ListGroup, Tab } from "react-bootstrap";
import { Application, CountryHelper, CheckPermissions, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { DynamicFieldValueRepository, EventRegistrationRepository, EventRepository, ModuleRepository, WebsiteRepository } from "../../repository";
import FormToastComponent from "../basics/FormToastComponent";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ToolTipLink from "../basics/ToolTipLink";
import LoaderComponent from "../basics/layout/LoaderComponent";
import TableMoreActionComponent from "../basics/table/TableMoreActionComponent";
import EventHelper from "../../helpers/EventHelper";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";

class EventOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            website: null,
            isLoading: true,
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            /*this.state.settings.show_start_date ? {
                dataField: 'date_start',
                text: <FormattedMessageString id="CMS.Event.overview.table.date_start" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let dateA = rowA.date_start;
                    let dateB = rowB.date_start;

                    return order === 'desc' ? Date.parse(dateB) - Date.parse(dateA) : Date.parse(dateA) - Date.parse(dateB)
                },
                isDummyField: true,
                formatter: (cellContent, row) => row.date_start ? row.date_start.format(this.state.settings.show_hours ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY') : null
            } : {},*/
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Event.overview.table.title" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.contents[index]?.title
            },
            this.state.settings.use_categories ?
                {
                    dataField: 'category',
                    text: <FormattedMessageString id="CMS.Event.overview.table.category" />,
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                        let contentsA = rowA.contents[index];
                        let contentsB = rowB.contents[index];

                        if (order === 'asc')
                            return contentsA.category.title.toLowerCase() < contentsB.category.title.toLowerCase() ? -1 : 1;
                        else if (order === 'desc')
                            return contentsB.category.title.toLowerCase() > contentsA.category.title.toLowerCase() ? 1 : -1;
                    },
                    formatter: (cellContent, row) => row.contents[index]?.category?.title
                } :
                {},
            this.state.settings.show_periods ? {
                dataField: 'nr_periods',
                text: <FormattedMessageString id="CMS.Event.overview.table.periods" />,
                isDummyField: true,
                sort: false,
                formatter: (cellContent, row) => (
                    <Link to={'/module/events/' + Module.getId() + '/periods/' + row.id + '/overview'}>
                        {row.nr_periods + ' ' + this.props.intl.formatMessage({ id: 'CMS.Event.period.overview.title' + (row.nr_periods === 1 ? '_singular' : '') }).toLowerCase()}
                    </Link>
                )
            } : {
                dataField: 'date_start',
                text: <FormattedMessageString id="CMS.Event.period.overview.table.date_start" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let dateA = rowA.hasPeriods() ? rowA.firstPeriod().date_start : '2100-12-31';
                    let dateB = rowB.hasPeriods() ? rowB.firstPeriod().date_start : '2100-12-31';

                    return order === 'desc' ? Date.parse(dateB) - Date.parse(dateA) : Date.parse(dateA) - Date.parse(dateB)
                },
                isDummyField: true,
                formatter: (cellContent, row) => {
                    if (row.hasPeriods()) {
                        return (
                            <Link to={'/module/events/' + Module.getId() + '/periods/' + row.id + '/edit/' + row.firstPeriod().id + '/nl'}>
                                {row.firstPeriod().label(this.state.settings)}
                            </Link>
                        );
                    } else {
                        return (
                            <Link to={'/module/events/' + Module.getId() + '/periods/' + row.id + '/add'}>
                                Periode toevoegen
                            </Link>
                        );
                    }
                }
            },
            {
                dataField: 'updated',
                text: <FormattedMessageString id="CMS.Event.overview.table.last_updated" />,
                isDummyField: true,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return Date.parse(rowA.contents[index].updated_at) - Date.parse(rowB.contents[index].updated_at)
                    } else if (order === 'desc') {
                        return Date.parse(rowB.contents[index].updated_at) - Date.parse(rowA.contents[index].updated_at)
                    }
                },
                formatter: (cellContent, row) => {
                    let contents = row.contents[index];
                    if (contents === undefined) return null;

                    return (
                        <div className="updated_by">
                            {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                            {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                        </div>
                    )
                }
            },
            {
                dataField: 'views',
                text: '',
                sort: false,
                isDummyField: true,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    let contents = row.contents[index];
                    return (
                        <div className="actions">
                            {contents && !GeneralSettingsHelper.is() && (
                                <Link to="#" className="link px-1 link-auto">
                                    <FontAwesomeIcon icon={['fas', 'fa-chart-column']} className="mr-1" />
                                    {contents.views}
                                </Link>
                            )}
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.contents[index];

                    let moreActions = [];
                    if (this.state.settings.use_attendees) {
                        moreActions.push({
                            key: 'registrations', icon: 'id-card-clip', label: this.props.intl.formatMessage({ id: 'CMS.Event.overview.more_actions.registrations' }), callback: () => this.onRegistrationLinkClick(row.id)
                        });
                        moreActions.push({ key: 'export-registrations', icon: 'file-excel', label: this.props.intl.formatMessage({ id: 'CMS.Event.overview.more_actions.export_registrations' }), callback: () => this.exportRegistrations(row) });
                    }
                    moreActions.push({ key: 'duplicate', icon: 'copy', label: this.props.intl.formatMessage({ id: 'CMS.Event.overview.more_actions.duplicate' }), callback: () => this.duplicate(row, content.language) });

                    return <div className="actions">
                        {this.state.settings.use_attendees && (
                            <ToolTipLink to="#" className="link mr-1 px-1 link-auto" title="CMS.Event.overview.tooltip.nr_subscriptions" onClick={() => this.onRegistrationLinkClick(row.id)}>
                                <FontAwesomeIcon icon={['fa', 'id-card-clip']} className="mr-2" />
                                {row.nr_registrations}
                            </ToolTipLink>
                        )}
                        {this.state.settings.content_with_blocks && (
                            <ToolTipLink className="default" to={"content/" + row.id + (content ? '/' + content.language : '/')} title="CMS.Event.overview.tooltip.content_with_blocks">
                                <FontAwesomeIcon icon={['far', 'file-plus']} size="lg" />
                            </ToolTipLink>
                        )}
                        {this.state.settings.use_attendees && (
                            <ToolTipLink title={row.registrationOpen() ? 'CMS.Event.overview.tooltip.registrations.open' : 'CMS.Event.overview.tooltip.registrations.closed'} onClick={() => this.quickUpdate(row, { action: 'registration_allowed' })}>
                                <FontAwesomeIcon className={row.registrationOpen() ? 'succcess' : 'danger'} icon={['far', 'fa-calendar']} />
                            </ToolTipLink>
                        )}
                        {this.state.settings.show_photoalbum && (
                            <ToolTipLink className="default" to={"photoalbum/" + row.id} title={"CMS.Event.overview.tooltip.show_photoalbum"}>
                                <FontAwesomeIcon icon={['far', 'images']} size="lg" />
                            </ToolTipLink>
                        )}
                        {this.state.settings.show_videoalbum && (
                            <ToolTipLink className="default" to={"videoalbum/" + row.id + (content !== undefined ? '/' + content.language : '/')} title={"CMS.Event.overview.tooltip.show_videoalbum"}>
                                <FontAwesomeIcon icon={['far', 'video-plus']} size="lg" />
                            </ToolTipLink>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            row.active ?
                                <Link onClick={(event) => this.toggleActive(event, row)} className="active" to="#">
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </Link>
                                :
                                <Link onClick={(event) => this.toggleActive(event, row)} className="inactive" to="#">
                                    <FontAwesomeIcon icon={['fas', 'times']} />
                                </Link>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableEditActionComponent id={row.id} custom={content !== undefined ? '/' + content.language : '/'} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableMoreActionComponent parent={this} id={row.id} items={moreActions} />
                        )}
                    </div>
                }
            }
        ];
    }

    updateData = () => EventRepository.all().then(response => this.props.parent.setState({ rows: response }));

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id="list-group-language-events"
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                <div className="custom-data-table event">
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search
                                        title={this.props.title_id} type={this.props.type}
                                        subRows={false}
                                        sort={{ dataField: 'date_start', order: 'desc' }}
                                    />
                                    <DeleteModalComponent parent={this} />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

    quickUpdate = (_row, data) => EventRepository.quickUpdate({ id: _row.id, action: data.action, value: data.value })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => this.setState({ reload: false })))
        .catch(error => FormToastComponent.errorTrans('CMS.Client.overview.toggle.failed'));

    toggleActive = (event, _row) => EventRepository.toggle(_row.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.id === _row.id) {
                    let event = row;
                    event.active = response;
                    return {
                        ...row,
                        event: event
                    }
                }
                return row;
            })
            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => this.setState({ reload: false }));
        })
        .catch(() => FormToastComponent.errorTrans('CMS.Event.overview.toggle.failed'));

    duplicate = (row, language) => this.props.history.push('duplicate/' + row.id + '/' + language);
    exportRegistrations = (row) => Promise.all([ModuleRepository.all(), EventRegistrationRepository.all(row.id), DynamicFieldValueRepository.all(1), DynamicFieldValueRepository.all(2), DynamicFieldValueRepository.all(3)])
        .then(results => {
            let moduleSettings = { event: this.state.settings, dynamicFields: { 1: results[2], 2: results[3], 3: results[4] } };
            results[0]
                .filter(module => [ModuleTypeEnum.CLIENT, ModuleTypeEnum.ATTENDEE].indexOf(module.module.type) > -1)
                .map(module => Module.fromJson(module.module))
                .forEach(module => moduleSettings[module.type] = Module.getSettings(module));

            EventHelper.export(row, results[1], { settings: moduleSettings, intl: this.props.intl, language: Application.language() });
        });

    onRegistrationLinkClick = (eventId) => {
        Application.resetTablePageNumber();
        this.props.history.push('/module/events/' + Module.getId() + '/registrations/' + eventId + '/overview')
    }

}

export default withRouter(injectIntl(EventOverviewTableComponent));