let ModuleTypeEnum = {
    ATTENDEE: 'attendee',
    BLOG: 'blog',
    CATEGORY: 'category',
    CLIENT: 'client',
    EVENT: 'event',
    FAQ: 'faq',
    MAP: 'map',
    NEWS: 'news',
    PRODUCT: 'product',
    PROJECT: 'project',
    TEAM: 'team',
    TESTIMONIAL: 'testimonial',
    WEBLINK: 'weblink',
    WEBSHOP: 'webshop'
};

export default ModuleTypeEnum;