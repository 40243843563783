import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageRepository from "./../../repository/PageRepository";
import { Modal } from "react-bootstrap";

/**
 * In this component is the policy component created.
 */
class PolicyComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: this.resetModal()
        }
    }

    resetModal = () => {
        return {
            show: false,
            title: null,
            content: null
        };
    }

    render() {
        return (
            <div className="footer">
                <p>&copy; {`${new Date().getFullYear()}`} Design by <a href="https://www.seekat.app" target="_blank" rel="noopener noreferrer" title="Seekat CMS">Seekat</a> </p>
                <p><Link to={"#"} onClick={() => this.onClick('termsofuse')}><FormattedMessage id="Policy.termsofuse" /></Link> - <Link to={"#"} onClick={() => this.onClick('privacy')}><FormattedMessage id="Policy.privacy" /></Link> - <Link to={"#"} onClick={() => this.onClick('cookie')}><FormattedMessage id="Policy.cookie" /></Link></p>

                {this.renderModal()}
            </div>
        )
    }

    onClick = (slug) => {
        PageRepository.getBySlug(slug)
            .then(page => {
                if (!page) return;

                let value = JSON.parse(page.value);

                this.setState({
                    modal: {
                        show: true,
                        title: value ? value.title : page.title,
                        content: value ? value.text : ''
                    }
                });
            });
    }

    toggleModal = () => {
        this.setState({ modal: this.resetModal() });
    }

    renderModal = () => {
        return <Modal show={this.state.modal.show} onHide={this.toggleModal} animation={false} scrollable size="xl" className={"pages-show-modal"} centered>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"pages-modal-content"} dangerouslySetInnerHTML={{ __html: this.state.modal.content }}></div>
            </Modal.Body>
        </Modal >
    }
}

export default PolicyComponent;

