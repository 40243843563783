import React from "react";
import FormInvalidError from "../../errors/FormInvalidError";
import WebsiteMaxRequiredError from "./WebsiteMaxRequiredError";

class WebsiteMaxRequiredInvalidError extends React.Component {
    render() {
        if (this.props.error === "CMS.Websites.Translations.Form.name.invalid") {
            return <FormInvalidError field_name={this.props.error.slice(0, -8)}/>
        } else {
            return <WebsiteMaxRequiredError error={this.props.error}/>
        }
    }
}

export default WebsiteMaxRequiredInvalidError;
