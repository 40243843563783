import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Page from "../../models/page/Page";
import PageTextFormComponent from "./forms/PageTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import PageUrlFormComponent from "./forms/PageUrlFormComponent";
import * as Yup from "yup";
import PageRepository from "../../repository/PageRepository";
import FormHelper from "../../helpers/FormHelper";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";

class PageEditComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: null,
            pageContent: null,
            isLoading: true,
            validation: {},
            settings: {
                titleOptional: true,
                onBlockTypeChange: this.handleBlockTypeChange
            },
            goBackAfterSubmit: true
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        this.props.get_promise
            .then(page => {
                if (page !== undefined) {
                    page = Page.fromJson(page);
                    let pageContent = page.content.filter(content => content.language === this.props.match.params.language)[0];
                    pageContent.value = JSON.parse(pageContent.value);
                    
                    if (pageContent.value.content) {
                        pageContent.value.content.forEach((_, i) => {
                            pageContent.value.content[i].index = i;
                        })
                    }

                    this.setState({ page: page, pageContent: pageContent }, () =>
                        PageRepository.getValidation(this.state.page.type)
                            .then(val_response => {
                                if (val_response.data !== undefined && val_response.data.success) {
                                    this.setState({
                                        validation: JSON.parse(val_response.data.validation),
                                        isLoading: false
                                    }, () => {
                                        if (this.state.page.type === "url") {
                                            this.props.parent.updateTitle("CMS.Page.Content.Form.url");
                                        } else if (this.state.page.type === "text") {
                                            this.props.parent.updateTitle(<FormattedMessageString id="CMS.Form.overview.edit.page" values={{ name: pageContent.title }} />)
                                        }
                                    })
                                } else {
                                    this.setState({ validation: false, isLoading: false })
                                }
                            }))
                } else {
                    FormToastComponent.errorTrans("CMS.Pages.Form.page.not.found")
                }
            });
    }

    getFields() {
        switch (this.state.page.type) {
            case "url":
                return PageUrlFormComponent.fields();
            case "text/image":
            case "text/image/module":
                return PageTextFormComponent.fields_image(false, this.state.page, this.state.settings);
            case "text/module":
            case "text":
                return PageTextFormComponent.fields();
            default:
                return [];
        }
    }

    getBeforeSubmit(element) {
        switch (this.state.page.type) {
            case "text/image":
            case "text/image/module":
                return PageTextFormComponent.fields_image_before_submit(element);
            case "url":
                return PageUrlFormComponent.fields_before_submit(element);
            default:
                return new Promise(resolve => resolve(true));
        }
    }

    _goBackAfterSubmit = true;
    setGoBackAfterSubmit = (value) => this._goBackAfterSubmit = value;

    goBackAfterCreate() {
        if (this._goBackAfterSubmit) {
            this.props.history.push("/" + this.props.slug);
        }
    }

    handleBlockTypeChange = (e) => {
        this.setState({
            settings: {
                ...this.state.settings,
                titleOptional: e.value === 'text'
            }
        });

        return e.value;
    }

    validation() {
        if (this.state.page.type.substring(0, 10) === 'text/image') {
            return Yup.object({
                // title: Yup.string().max(255, 'CMS.Page.Content.Form.title|255|max').nullable(),
                //value: Yup.object({ text: Yup.string().required('CMS.Page.Content.Form.text') })
            });
        } else {
            let fields = {};

            for (const [key, value] of Object.entries(this.state.validation)) {
                let validationObject = Yup.string();
                let values = value.split('|');
                for (let i = 0; i < values.length; i++) {
                    let rule = values[i];

                    if (rule === 'required') {
                        validationObject = validationObject.required("CMS.Page.Content.Form." + key);
                    } else if (rule === 'url') {
                        validationObject = validationObject.test("is-url-without-https-http", "CMS.Website.Form.url.invalid", val => {
                            if (val !== undefined) {
                                let schemaUrl = Yup.object().shape({
                                    url: Yup.string().url("CMS.Page.Content.Form.url.invalid")
                                });
                                return schemaUrl.validate({ url: 'https://' + val.replace(/www./) })
                            } else {
                                return true;
                            }
                        })
                    } else if (rule.indexOf('max:') > -1) {
                        let max = rule.replace('max:', '');
                        validationObject = validationObject.max(max, 'CMS.Page.Content.Form.' + key + '|' + max + '|max');
                    }
                }

                fields[key] = validationObject;
            }

            return Yup.object({ value: Yup.object(fields) });
        }
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <StandardFormComponent {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.pageContent}
                validationSchema={this.validation()}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {
                        let value = values.value;
                        if (value.content) value = { content: value.content };
                        if (Array.isArray(value)) value = { ...value };

                        PageRepository.updateContent({ id: this.state.page.id, language: values.language, value: value })
                            .then(response => {
                                if (response.success) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                    this.goBackAfterCreate();
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                    })

                }}
                formErrors={this.state.form_errors}
                fields={this.getFields()}
                rawValidationSchema={this.state.validation}
                btnSubmitClass={this.state.page.type.substring(0, 10) === 'text/image' ? 'visually-hidden' : null}
            />
        )
    }
}

export default withRouter(PageEditComponent);