import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import CategoryFormError from "./errors/CategoryFormError";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import { Application } from "../../helpers";

class CategoryCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let settings = {
            manager_can_change: { type: 'bool' },
            show_email: { type: 'bool' },
            show_label: { type: 'bool' },
            show_colors: { type: 'bool' },
            show_summary: { type: 'bool', depending: 'show_highlights' },
            show_highlights: { type: 'bool', depending: 'show_summary' },
            show_content: { type: 'bool', depending: 'content_with_blocks' },
            content_with_blocks: {
                type: 'bool',
                children: {
                    content_with_blocks_image_width: { type: 'number', width: 6, message: false },
                    content_with_blocks_image_height: { type: 'number', width: 6, message: false },
                    has_special_blocks: { type: 'bool' },
                },
                depending: 'show_content'
            },
            show_cover_image: {
                type: 'bool',
                children: {
                    cover_image_width: { type: 'number', width: 6, message: false },
                    cover_image_height: { type: 'number', width: 6, message: false },
                    need_home_img: {
                        type: 'bool',
                        children: {
                            home_img_width: { type: 'number', width: 6, message: false },
                            home_img_height: { type: 'number', width: 6, message: false }
                        }
                    }
                },
            },
            show_svg: { type: 'bool' },
            show_photoalbum: { type: 'bool' },
            show_videoalbum: { type: 'bool' },
            show_as_footer_navigation_on_all_pages: { type: 'bool' },
            has_subcategories: {
                type: 'bool',
                children: {
                    subcategories_number_of_levels: { type: 'choice', options: [1, 2, 3, 4] },
                    subcategories_manager_can_change: { type: 'bool', visible: { manager_can_change: false } },
                    subcategories_show_email: { type: 'bool' },
                    subcategories_show_label: { type: 'bool' },
                    subcategories_show_colors: { type: 'bool' },
                    subcategories_show_summary: { type: 'bool', depending: 'subcategories_show_highlights' },
                    subcategories_show_highlights: { type: 'bool', depending: 'subcategories_show_summary' },
                    subcategories_show_content: { type: 'bool', depending: 'subcategories_content_with_blocks' },
                    subcategories_content_with_blocks: {
                        type: 'bool',
                        children: {
                            subcategories_image_width: { type: 'number', width: 6, message: false },
                            subcategories_image_height: { type: 'number', width: 6, message: false },
                            subcategories_has_special_blocks: { type: 'bool' },
                        },
                        depending: 'subcategories_show_content'
                    },
                    subcategories_show_cover_image: {
                        type: 'bool',
                        children: {
                            subcategories_cover_image_width: { type: 'number', width: 6, message: false },
                            subcategories_cover_image_height: { type: 'number', width: 6, message: false },
                            subcategories_need_home_img: {
                                type: 'bool',
                                children: {
                                    subcategories_home_img_width: { type: 'number', width: 6, message: false },
                                    subcategories_home_img_height: { type: 'number', width: 6, message: false }
                                }
                            }
                        },
                    },
                    subcategories_show_photoalbum: { type: 'bool' },
                    subcategories_show_videoalbum: { type: 'bool' },
                    subcategories_show_as_footer_navigation_on_all_pages: { type: 'bool', visible: { show_as_footer_navigation_on_all_pages: true } }
                }
            }
        }

        this.state = {
            type: this.props.type,
            settings: settings,
            manager_can_change: false,
            show_email: false,
            show_label: false,
            show_summary: false,
            show_highlight: false,
            show_content: false,
            content_with_blocks: false,
            show_cover_image: false,
            show_svg: false,
            show_photoalbum: false,
            show_videoalbum: false,
            has_subcategories: false,
            subcategories_number_of_levels: parseInt(Application.setting('module_subcategories_default_number_of_levels')),
            subcategories_manager_can_change: false,
            subcategories_show_email: false,
            subcategories_show_label: false,
            subcategories_show_summary: false,
            subcategories_show_highlights: false,
            subcategories_show_content: false,
            subcategories_content_with_blocks: false,
            subcategories_show_cover_image: false,
            subcategories_show_photoalbum: false,
            subcategories_show_videoalbum: false
        }

        this._checkboxRefs = [];

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        let stateSettings = {};
        Object.keys(this.props.values.settings).forEach(key => {
            stateSettings[key] = this.props.values.settings[key];
        });

        this.setState({
            ...this.state,
            ...stateSettings
        });
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');

        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }
            this.props.handleChange(event);

            this.handleDependingChange(name);
            this.setState({ [name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
        }
    };

    handleDependingChange = (name) => {
        let setting = this.state.settings[name];
        if (name.indexOf('subcategories_') > -1) {
            setting = this.state.settings.has_subcategories.children[name];
        }

        if (!setting || !setting.depending) return;

        let currentValue = !this.state[name];
        if (!currentValue) return;

        let dependingSetting = this.state.settings[setting.depending];
        if (name.indexOf('subcategories_') > -1) {
            dependingSetting = this.state.settings.has_subcategories.children[setting.depending];
        }

        this.handleBooleanChange(setting.depending, dependingSetting, false);

        let model = this.props.parent.state.model;
        model.settings[name] = !model.settings[name];

        if (model.settings[name]) {
            model.settings[setting.depending] = false;
        }

        this.props.parent.setState({ model: model });

        this.props.handleChange();
    }

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Categories.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {this.renderSettingGroups()}
                </Row>

                <DeleteModalComponent parent={this}
                    title={"CMS.Categories.Form.setting.delete.title"} text={"CMS.Categories.Form.setting.delete.text"}
                    buttons={{
                        confirm: { text: 'Default.confirm', icon: 'check' }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }

    renderSettingGroups = () => {
        let { settings } = this.state;

        let groups = [];

        Object.keys(settings).forEach(key => {
            groups.push(
                <Form.Group key={'group-' + key} as={Col} xs={12}>
                    <Row>
                        {this.renderSettings(key, settings[key])}
                    </Row>
                </Form.Group>
            );
        });

        return groups;
    }

    renderSettings = (key, setting) => {
        let items = [];
        

        if (!this.showSetting(setting)) return null;

        if (setting.type === 'bool') {
            this._checkboxRefs[key] = React.createRef();

            items.push(
                <Col key={'col-bool-' + key} md={setting.width ? setting.width : 12}>
                    <Form.Check
                        type="switch"
                        id={'custom-switch-' + key}
                        label={key}
                        name={"settings." + key}
                        onChange={(event) => this.onChange(event, () => this.handleBooleanChange(key, setting))}
                        ref={(input) => this._checkboxRefs[key] = input}
                        className="mb-1"
                        checked={this.state[key] ? "checked" : ""}
                    />
                </Col>
            );
        }

        if (setting.type === 'number') {
            items.push(
                <Col key={'col-number-' + key} md={setting.width ? setting.width : 12}>
                    <InputGroup className="mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">
                                {key}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            aria-describedby={key}
                            type={"text"}
                            name={"settings." + key}
                            onChange={this.onChange}
                            isInvalid={this.props.errors.settings != null && this.props.errors.settings[key]}
                            value={this.state[key]}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.props.errors.settings != null && this.props.errors.settings[key] && (
                                <CategoryFormError error={this.props.errors.settings[key]} />
                            )}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            );
        }

        if (setting.type === 'choice') {
            items.push(
                <Col key={'col-choice-' + key} md={setting.width ? setting.width : 12}>
                    <InputGroup className="no-suffix mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                {key}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select"
                            aria-describedby={key}
                            // onChange={this.onChange}
                            onChange={e => this.onChange(e, () => this.handleChoiceChange(key, setting, e.target.value))}
                            name={"settings." + key}
                            isInvalid={this.props.errors.settings != null && this.props.errors.settings[key]}
                            value={this.state[key]}
                        >
                            {setting.options.map(value => <option key={value} value={value}>{value}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {this.props.errors.settings != null && this.props.errors.settings[key] && (
                                <CategoryFormError error={this.props.errors.settings[key]} />
                            )}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            );
        }
        
        
         let subitems = [];
         if (this.hasChildren(setting) && this.state[key] === true) {
              
            Object.keys(setting.children).forEach(childKey => {
                let childSetting = setting.children[childKey];  
                subitems.push(this.renderSettings(childKey, childSetting, false));
            });
            
            items.push(
                    <Form.Group className="subsettings"> 
                        <Row>
                        {subitems}
                        </Row>
                    </Form.Group>
            );
       
        }
       

        if (setting.message === undefined || setting.message) {
            items.push(
                <Col key={'col-message-' + key} xs={setting.width ? setting.width : 12}>
                    <FormattedMessage id={"CMS.Modules.Form.settings.category." + key}>
                        {
                            (values) => <p className="input-info">{values}</p>
                        }
                    </FormattedMessage>
                </Col>
            );
        }

       

        return items;
    }

    showSetting = (setting) => {
        if (setting.visible === undefined || !setting.visible) return true;

        let show = true;
        Object.keys(setting.visible).forEach(field => {
            if (setting.visible[field] !== this.state[field]) {
                show = false;
            }
        });

        return show;
    }

    hasChildren = (setting) => setting?.children && Object.keys(setting.children).length > 0;

    handleBooleanChange = (key, setting, forceValue = null) => {
        let newValue = !this.state[key];
        if (forceValue !== null) {
            newValue = forceValue;
        }

        this.setState({
            [key]: newValue
        }, () => this.resetSettings(setting));
    }

    handleChoiceChange = (key, setting, value) => this.setState({ [key]: value }, () => this.resetSettings(setting));

    resetSettings = (setting) => {
        if (!this.hasChildren(setting)) return;

        Object.keys(setting.children).forEach(childKey => {
            let childSetting = setting.children[childKey];
            if (childSetting.type === 'bool' && this.props.values.settings[childKey] && this.originalValues.settings[childKey] && this.state.deleteRow !== childKey) {
                this.setState({ [childKey]: false })
            }

            delete this.props.values.settings[childKey];

            if (this.hasChildren(childSetting)) {
                this.resetSettings(childSetting);
            }
        });
    }
}

export default CategoryCustomSettingsFormComponent;