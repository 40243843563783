import { toast } from "react-toastify";
import { FormattedMessage } from 'react-intl';
import React from "react";
import FormattedMessageInfo from "./FormattedMessageInfo";

class FormToastComponent {

    static successTrans = (message, translationId) => toast.success(<FormattedMessageInfo id={translationId} message={message} />, {
        position: "top-right",
        autoClose: 5000,
        draggable: true,
        closeOnClick: true
    })

    static success = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        draggable: true,
        closeOnClick: true
    })

    static errorTrans = (translationId, values) => toast.error(<FormattedMessage id={translationId} values={values} />, {
        position: "top-right",
        autoClose: 7000,
        draggable: true,
        closeOnClick: true
    });

    static error = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 7000,
        draggable: true,
        closeOnClick: true
    });
}

export default FormToastComponent;