import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";

class MediaRepository {

    static getEmbed(url) {
        // return new Promise((resolve, reject) => {
        //     axios.get(
        //         'https://www.youtube.com/oembed?url=' + url + '&format=json'
        //     )
        //         .then(response => {
        //             resolve(response)
        //         })
        //         .catch(error => {
        //             reject(error)
        //         })
        // })
        return new Promise((resolve, reject) => {
            axios.get(
                // 'https://noembed.com/embed?format=json&url=' + encodeURI(url) + '&maxwidth=960&maxheight=540',
                'https://noembed.com/embed?format=json&url=' + encodeURI(url)
            )
                .then(response => {
                    if (response.data.html) {
                        response.data.html = response.data.html.replace(/width="[^"]*"/, '').replace(/height="[^"]*"/, '');
                    }

                    if (response !== undefined && response.data !== undefined && response.data.thumbnail_url !== undefined && response.data.provider_name === "YouTube") {
                        response.data.thumbnail_url = response.data.thumbnail_url.replace('hqdefault', 'mqdefault')
                    }
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }

    static upload = (file, collection, module, moduleId, options = null, name = null, settings = null) => {
        const data = new FormData();
        data.append('file', file);
        data.append('type', collection != null ? collection : 'photo_album');
        data.append('module', module);

        if (moduleId) data.append('module_id', moduleId);
        if (name != null) data.append('name', name)
        if (settings != null) data.append('settings', settings)

        return new Promise((resolve, reject) => {
            axios.post(
                config.get("api_url") + '/media/upload', data,
                {
                    ...options,
                    headers: CustomHeaders.get(null, module === 'website' ? moduleId : null),
                },
            )
                .then(response => {
                    if (response.status !== 200) {
                        reject(response);
                    }
                    resolve(response);
                })
                .catch(error => reject(error))
        })
    }

    static uploadExternal(url, type, moduleId, name, module = null) {
        if (url.substr(0, 8) !== 'https://' || url.substr(0, 7) === 'http://') {
            url = 'https://' + url;
        }

        let data = { url: url, type: type, name: name };
        if (module) {
            data = { ...data, ...{ module: module, moduleId: moduleId } };
        } else {
            data = { ...data, ...{ page: moduleId } };
        }

        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/media/external/upload', data, {
                headers: CustomHeaders.get()
            })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static updateOrder(order, type, module, moduleId) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/media/update/order',
                {
                    order: JSON.stringify(order),
                    type: type,
                    module: module,
                    module_id: moduleId
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    static delete(id, type, moduleId, module = null) {
        return new Promise((resolve, reject) => {
            let data = { id: id, type: type };
            if (module) {
                data = { ...data, ...{ moduleId: moduleId, module: module } };
            } else {
                data = { ...data, ...{ page: moduleId } };
            }

            axios.post(
                config.get('api_url') + '/media/delete', data,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    static getSpecificSettings(type, moduleId = null, module = null) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/media/settings?type=' + type + (moduleId ? (module ? '&module=' : '&page=') + moduleId : ''),
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    static getAlbum(id, album, module) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/media/all?type=' + album + '&module=' + module + '&moduleId=' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}

export default MediaRepository;
