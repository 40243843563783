export default class ProjectContent {
    id;
    title;
    language;
    client;
    url;
    summary;
    content;

    static fromJson = (json) => {
        let content = new ProjectContent();

        content.id = json.id;
        content.language = json.language;
        content.title = json.title;
        content.client = json.client;
        content.url = json.url;
        content.summary = json.summary;
        content.content = json.content != null ? json.content : '{}';

        return content;
    }
}