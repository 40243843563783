import ModuleTypeEnum from "../enums/ModuleTypeEnum";
import Category from "../models/category/Category";
import Module from "../models/module/Module";
import { ModuleRepository, PageRepository } from "../repository";
import JsonHelper from "./JsonHelper";

class PageHelper {
    _props = {};

    static generateInternalLinkList = (props) => Promise.all([
        PageRepository.tree(),
        ModuleRepository.all(),
        ModuleRepository.getData()
    ])
        .then(result => {
            this._props = props;

            let modules = result[1];

            let allowedCategories = [];
            let moduleData = {};

            // Modules
            if (modules) {
                modules.forEach(module => {
                    if (!(module.id in moduleData)) moduleData[module.id] = { module: module, items: [] };

                    if (module.module.type === ModuleTypeEnum.CATEGORY) {
                        let item = Module.fromJson(module.module);

                        let moduleCategories = result[2].categories.filter(c => c.category.module === module.id);

                        let showContent = Module.getSetting(item, 'content_with_blocks') || Module.getSetting(item, 'show_content');
                        let showContentSubcategories = Module.getSetting(item, 'subcategories_content_with_blocks') || Module.getSetting(item, 'subcategories_show_content');

                        moduleCategories.forEach(category => {
                            if (
                                (!category.category.parent && showContent) ||
                                (category.children.length > 0 && showContentSubcategories)
                            ) {
                                allowedCategories.push(category);
                            } else if (category.category.parent && showContentSubcategories) {
                                allowedCategories.push(category);
                            }
                        });
                    } else if (module.module.type === ModuleTypeEnum.TEAM) {
                        let m = Module.fromJson(module.module);
                        if (Module.getSetting(m, 'show_content')) {
                            moduleData[module.id].items = result[2].team.filter(item => item.team.module === m.id);
                        }
                    } else if ([ModuleTypeEnum.BLOG, ModuleTypeEnum.EVENT, ModuleTypeEnum.PROJECT, ModuleTypeEnum.NEWS].indexOf(module.module.type) > -1) {
                        if (module.module.type === ModuleTypeEnum.BLOG) {
                            moduleData[module.id].items = result[2].blogs.filter(item => item.blog.module === module.id);
                        } else if (module.module.type === ModuleTypeEnum.EVENT) {
                            moduleData[module.id].items = result[2].events.filter(item => item.event.module === module.id);
                        } else if (module.module.type === ModuleTypeEnum.PROJECT) {
                            moduleData[module.id].items = result[2].projects.filter(item => item.project.module === module.id);
                        } else if (module.module.type === ModuleTypeEnum.NEWS) {
                            moduleData[module.id].items = result[2].news.filter(item => item.news.module === module.id);
                        }
                    }
                });
            }

            let linkList = [];
            this.assembleList(linkList, {
                pages: result[0],
                moduleData: moduleData,
                categoryTree: allowedCategories.length > 0 ? Category.renderTree(allowedCategories) : {}
            });

            // Home page -> no children
            linkList[0].children = [];

            return linkList;
        });

    static assembleList = (linkList, data) => {
        data.pages.forEach((page) => {
            let content = page.page.contents.find(content => content.language === this._props.language);

            let record = {
                title: content?.title,
                url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/page/' + page.id,
                disabled: page.page.type === 'url',
                children: []
            };

            if (page.children.length > 0) {
                record.children = this.assembleList(record.children, { ...data, ...{ pages: page.children } });
            }

            if (page.page.modules) {
                let pageModules = JsonHelper.tryParse(page.page.modules);
                pageModules.forEach(pageModule => {
                    if (pageModule in data.categoryTree) {
                        record.children = [...record.children, ...this.assembleLinkListCategories(record.children, page, data.categoryTree[pageModule])];
                    } else if (pageModule in data.moduleData) {
                        record.children = [...record.children, ...this.assembleLinkList(record.children, page, data.moduleData[pageModule])];
                    }
                });
            }

            linkList.push(record);
        });

        return linkList;
    }

    static assembleLinkListCategories = (list, page, categories, depth = 1) => {
        Object.keys(categories).forEach(id => {
            let category = categories[id];
            let content = category.category.contents.find(content => content.language === this._props.language);

            let record = {
                title: content?.title,
                url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/category/' + category.category.id + '/' + page.id,
                children: []
            };

            if (Object.keys(category.children).length > 0) {
                record.children = this.assembleLinkListCategories(record.children, page, category.children, depth + 1);
            }

            list.push(record);
        });

        return list;
    }

    static assembleLinkList = (list, page, data, depth = 1) => {
        let type = data.module.module.type;
        data.items.forEach(item => {
            let content = item[type].contents.find(content => content.language === this._props.language);

            let record = {
                title: content?.title,
                url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/' + type + '/' + item.id + '/' + page.id + '/' + data.module.id,
                children: []
            };

            if (item.children && item.children.length > 0) {
                record.children = this.assembleLinkList(record.children, page, record.children, depth + 1);
            }

            list.push(record);
        });

        return list;
    }
}

export default PageHelper;