import React from "react";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";

class FormPhoneError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {
                    (field) =>
                        <FormattedMessage id={"CMS.Form.field.phone"} values={{ field: field }}>
                            {(message) => <p>{message} {this.props.countryCodeRequired && (<FormattedMessageString id="CMS.Form.Phone.country_code_warning" />)}</p>}
                        </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

export default FormPhoneError;