import { JsonHelper } from "../../helpers";
import ContentBlockContent from "./ContentBlockContent";
import * as Yup from 'yup';

export default class ContentBlock {
    id;
    website;
    name;
    show_title;
    show_pages;
    pages = [];
    show_content;
    show_image;
    image_width;
    image_height;
    show_url;
    state_active;
    active;
    content = [];

    constructor() {
        this.show_title = true;
        this.show_content = true;
        this.show_image = true;
        this.show_url = false;
        this.state_active = false;
        this.content = { text: '' };
    }

    static fromJson = (json) => {
        let contentBlock = new ContentBlock();
        contentBlock.id = json['id'];
        contentBlock.website = json['website'];
        contentBlock.name = json['name'];
        contentBlock.show_title = json['show_title'];
        contentBlock.show_pages = json.show_pages;
        contentBlock.pages = JsonHelper.tryParse(json.pages);
        contentBlock.show_content = json['show_content'];
        contentBlock.show_image = json['show_image'];
        contentBlock.image_width = json['image_width'];
        contentBlock.image_height = json['image_height'];
        contentBlock.state_active = json['state_active'];
        contentBlock.show_url = json['show_url'];
        contentBlock.active = json['active'];
        contentBlock.contents = json['contents'].map(function (content) {
            return ContentBlockContent.fromJson(content);
        });
        return contentBlock;
    }

    static validation(model) {
        let fields = {
            name: Yup.string().required('CMS.ContentBlock.Form.name').max(40, 'CMS.ContentBlock.Form.name|40|max'),
            summary: Yup.string().required('CMS.ContentBlock.Form.summary').max(255, 'CMS.ContentBlock.Form.summary|255|max'),
        };

        if (model.show_title) {
            fields.title = Yup.string().when('show_title', {
                is: setting => setting === true,
                then: Yup.string().required('CMS.ContentBlock.Form.title').max(255, 'CMS.ContentBlock.Form.title|255|max')
            });
        }

        if (model.show_pages) {
            fields.pages = Yup.array().required('CMS.ContentBlock.Form.pages')
        }

        if (model.show_content) {
            fields.content = Yup.object({ text: Yup.string().required('CMS.Testimonials.Form.content') })
        }

        if (model.show_image) {
            fields.image_width = Yup.string().when('show_image', {
                is: setting => setting === true,
                then: Yup.string().required('image_width').matches(/^[0-9]+$/, "image_width.number")
            });
            fields.image_height = Yup.string().when('show_image', {
                is: setting => setting === true,
                then: Yup.string().required('image_height').matches(/^[0-9]+$/, "image_height.number")
            });
        }

        if (model.show_url) {
            fields.url = Yup.string().required('CMS.ContentBlock.Form.url').max(255, 'CMS.ContentBlock.Form.url|255|max');
        }

        if (!model.show_title && !model.show_content && !model.show_image) {
            fields.show_title = Yup.boolean().oneOf([true], 'show_title');
            fields.show_content = Yup.boolean().oneOf([true], 'show_content');
            fields.show_image = Yup.boolean().oneOf([true], 'show_image');
        }

        return Yup.object(fields);
    }
}
