import React from "react";
import { FormAlphaNumericError, FormMaxError, FormRequiredError, FormNumberError, FormSelectError, FormWebsiteError } from "../../errors";

class ProjectFormError extends React.Component {

    render() {
        let error = this.props.error.split('|');
        if (this.props.error.slice(-6) === "number") {
            return <FormNumberError field_name={error[0]} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-5) === "match") {
            return <FormAlphaNumericError field_name={error[0]} />
        } else if (this.props.error.slice(-6) === 'select') {
            return <FormSelectError field_name={error[0]} />
        } else if (this.props.error.slice(this.props.error.length - 7, this.props.error.length) === "invalid") {
            return <FormWebsiteError field_name={error[0]} />;
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

export default ProjectFormError;