import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormMaxError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {
                    (field) =>
                        <FormattedMessage id={"CMS.Form.field.max"}
                            values={{ field: field, max: this.props.max }}>
                            {
                                (message) => <p>{message}</p>
                            }
                        </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

FormMaxError.propTypes = {
    field_name: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired
};
export default FormMaxError