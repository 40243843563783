import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import AttendeeFormError from './errors/AttendeeFormError';
import CustomTextArea from "../basics/CustomTextArea";
import { PriceHelper } from "../../helpers";

class AttendeeCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let { settings } = props.values;

        this._labels = {
            email: 'show_email',
            phone: 'show_telephone',
            gender: 'show_gender',
            birth_date: 'show_date_of_birth',
            national_insurance_number: 'show_national_registration_number',
            medical_info: 'show_medical_info'
        };
        
        this.state = {
            fields: {
                email: settings?.fields?.email ? settings.fields.email : false,
                phone: settings?.fields?.phone ? settings.fields.phone : false,
                gender: settings?.fields?.gender ? settings.fields.gender : false,
                birth_date: settings?.fields?.birth_date ? settings.fields.birth_date : false,
                national_insurance_number: settings?.fields?.national_insurance_number ? settings.fields.national_insurance_number : false,
                medical_info: settings?.fields?.medical_info ? settings.fields.medical_info : false,
            },
            use_membership: settings.use_membership,
            use_membership_duration: settings.use_membership_duration,
            membership_duration: settings.membership_duration,
            use_membership_start_date: settings.use_membership_start_date,
            membership_start_date: settings.membership_start_date,
            membership_price_currency: settings.membership_price_currency,
            membership_price: settings.membership_price ? PriceHelper.price(settings.membership_price) : null,
            membership_webshop_discount_code: settings.membership_webshop_discount_code,
            show_additional_dynamic_field_1: settings.show_additional_dynamic_field_1,
            additional_dynamic_field_1_type: settings.additional_dynamic_field_1_type,
            additional_dynamic_field_1_title: settings.additional_dynamic_field_1_title,
            additional_dynamic_field_1_text: settings.additional_dynamic_field_1_text,
            additional_dynamic_field_1_required: settings.additional_dynamic_field_1_required,
            show_additional_dynamic_field_2: settings.show_additional_dynamic_field_2,
            additional_dynamic_field_2_type: settings.additional_dynamic_field_2_type,
            additional_dynamic_field_2_title: settings.additional_dynamic_field_2_title,
            additional_dynamic_field_2_text: settings.additional_dynamic_field_2_text,
            additional_dynamic_field_2_required: settings.additional_dynamic_field_2_required,
            show_additional_dynamic_field_3: settings.show_additional_dynamic_field_3,
            additional_dynamic_field_3_type: settings.additional_dynamic_field_3_type,
            additional_dynamic_field_3_title: settings.additional_dynamic_field_3_title,
            additional_dynamic_field_3_text: settings.additional_dynamic_field_3_text,
            additional_dynamic_field_3_required: settings.additional_dynamic_field_3_required,
            can_disable_birth_date_field: settings.can_disable_birth_date_field,
            can_disable_national_insurance_number_field: settings.can_disable_national_insurance_number_field
        }

        this.originalValues = this.props.values;
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({ currentEvent: { ...event } })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();
        this.setState({ showDeleteModal: false, deleteRow: null, currentEvent: null })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Attendee.Form.fields">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {Object.keys(this.state.fields).map(field => (
                                <Col xs={12}>
                                    <Form.Check type="switch" id={'custom-switch-' + field} name={"settings.fields." + field}
                                        label={this._labels[field]} className="mb-1" checked={this.state.fields[field]}
                                        disabled={(field === 'birth_date' && !this.state.can_disable_birth_date_field) || (field === 'national_insurance_number' && !this.state.can_disable_national_insurance_number_field)}
                                        onChange={event => this.onChange(event, () => {
                                            let fields = this.state.fields;
                                            fields[field] = !fields[field];
                                            this.setState({ fields: fields })
                                        })}
                                    />
                                    {field === 'birth_date' && !this.props.values.settings.can_disable_birth_date_field && (
                                        <FormattedMessage id="CMS.Attendee.Form.settings.info.birth_date_field.disabled">
                                            {values => <p className="input-info text-warning">{values}</p>}
                                        </FormattedMessage>
                                    )}
                                    {field === 'national_insurance_number' && !this.props.values.settings.can_disable_national_insurance_number_field && (
                                        <FormattedMessage id="CMS.Attendee.Form.settings.info.national_insurance_number_field.disabled">
                                            {values => <p className="input-info text-warning">{values}</p>}
                                        </FormattedMessage>
                                    )}

                                    <FormattedMessage id={"CMS.Attendee.Form.settings.field." + field + '_info'}>
                                        {(values) => <p className="input-info">{values}</p>}
                                    </FormattedMessage>
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>
                </Row>

                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Attendee.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use membership */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-membership"
                                    label="use_attendee_membership"
                                    name="settings.use_membership"
                                    onChange={event => this.onChange(event, () => this.setState({ use_membership: !this.state.use_membership, use_membership_duration: false, membership_duration: null, use_membership_start_date: false, membership_start_date: null }, () => {
                                        this.props.values.settings.use_membership_duration = false;
                                        delete this.props.values.settings.membership_duration;
                                        this.props.values.settings.use_membership_start_date = false;
                                        delete this.props.values.settings.membership_start_date;
                                    }))}
                                    className="mb-1"
                                    checked={this.state.use_membership}
                                />
                            </Col>

                            {this.state.use_membership ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check type="switch" label="attendee_membership_duration" className="mb-1 is-invalid"
                                                    id="custom-switch-use_membership_duration"
                                                    name="settings.use_membership_duration"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_membership_duration: !this.state.use_membership_duration }, () => {
                                                        delete this.props.values.settings.membership_duration;

                                                        if (this.state.use_membership_duration) {
                                                            this.setState({ use_membership_start_date: false, membership_start_date: null }, () => {
                                                                this.props.values.settings.use_membership_start_date = false;
                                                                delete this.props.values.settings.membership_start_date;
                                                            })
                                                        }
                                                    }))}
                                                    checked={this.state.use_membership_duration}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings?.use_membership_duration && (
                                                        <AttendeeFormError error={this.props.errors.settings.use_membership_duration} />
                                                    )}
                                                </Form.Control.Feedback>

                                            </Col>
                                            {this.state.use_membership_duration ?
                                                <Form.Group className="subsettings sub">
                                                    <Row>
                                                        <Col md={12}>
                                                            <InputGroup className="no-suffix">
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">months</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" name="settings.membership_duration"
                                                                    aria-describedby="membership_duration"
                                                                    onChange={this.onChange}
                                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_duration}
                                                                    value={this.props.values.settings.membership_duration}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {this.props.errors.settings != null && this.props.errors.settings.membership_duration && (
                                                                        <AttendeeFormError error={this.props.errors.settings.membership_duration} />
                                                                    )}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                            <FormattedMessage id={"CMS.Attendee.Form.settings.info.months"}>
                                                                {(values) => <p className="input-info">{values}</p>}
                                                            </FormattedMessage>
                                                        </Col>
                                                    </Row>
                                                </Form.Group> :
                                                null}
                                            <Col md={12}>
                                                <FormattedMessage id={"CMS.Attendee.Form.settings.info.membership_duration"}>
                                                    {(values) => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Check type="switch" label="attendee_membership_start_date" className="mb-1 is-invalid"
                                                    name="settings.use_membership_start_date" id="custom-switch-use_membership_start_date"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_membership_start_date: !this.state.use_membership_start_date }, () => {
                                                        this.props.values.settings.membership_start_date = null;

                                                        if (this.state.use_membership_start_date) {
                                                            this.setState({ use_membership_duration: false, membership_duration: null, self_registration: false }, () => {
                                                                this.props.values.settings.self_registration = false;
                                                                this.props.values.settings.use_membership_duration = false;
                                                                delete this.props.values.settings.membership_duration;
                                                            })
                                                        }
                                                    }))}
                                                    checked={this.state.use_membership_start_date}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings?.use_membership_start_date && (
                                                        <AttendeeFormError error={this.props.errors.settings.use_membership_start_date} />
                                                    )}
                                                </Form.Control.Feedback>

                                            </Col>
                                            {this.state.use_membership_start_date ?
                                                <Form.Group className="subsettings sub">
                                                    <Row>
                                                        <Col md={12}>
                                                            <InputGroup className="no-suffix">
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">date</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="date" name="settings.membership_start_date"
                                                                    aria-describedby="membership_start_date"
                                                                    onChange={this.onChange}
                                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_start_date}
                                                                    value={this.props.values.settings.membership_start_date}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {this.props.errors.settings != null && this.props.errors.settings.membership_start_date && (
                                                                        <AttendeeFormError error={this.props.errors.settings.membership_start_date} />
                                                                    )}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                            <FormattedMessage id={"CMS.Attendee.Form.settings.info.date"}>
                                                                {(values) => <p className="input-info">{values}</p>}
                                                            </FormattedMessage>
                                                        </Col>
                                                    </Row>
                                                </Form.Group> :
                                                null}
                                            <Col md={12}>
                                                <FormattedMessage id={"CMS.Attendee.Form.settings.info.use_membership_start_date"}>
                                                    {(values) => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <InputGroup className="large">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">attendee_membership_price</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <div className="form-control-currency-disabled">
                                                        <Form.Control as="select" name="settings.membership_price_currency" className="form-control-xs form-control-currency"
                                                            disabled aria-describedby="membership_price_currency"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_price_currency}
                                                            value={this.props.values.settings.membership_price_currency}
                                                        >
                                                            {Object.keys(PriceHelper.getCurrencyList()).map(currency => (
                                                                <option value={currency} key={currency} dangerouslySetInnerHTML={{ __html: PriceHelper.getCurrencySign(currency) }} />
                                                            ))}
                                                        </Form.Control>
                                                    </div>
                                                    <Form.Control type="text" name="settings.membership_price"
                                                        aria-describedby="membership_price"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_price}
                                                        value={this.props.values.settings.membership_price}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.membership_price && (
                                                            <AttendeeFormError error={this.props.errors.settings.membership_price} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <FormattedMessage id="CMS.Attendee.Form.settings.info.membership_price">
                                                    {(values) => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_attendee_membership_webshop_discount_code" className="mb-1"
                                                    id="custom-switch-membership_webshop_discount_code"
                                                    name="settings.membership_webshop_discount_code"
                                                    onChange={event => this.onChange(event, () => this.setState({ membership_webshop_discount_code: !this.state.membership_webshop_discount_code }))}
                                                    checked={this.state.membership_webshop_discount_code}
                                                />
                                                <FormattedMessage id={"CMS.Attendee.Form.settings.info.membership_webshop_discount_code"}>
                                                    {(values) => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col>
                                <FormattedMessage id={"CMS.Attendee.Form.settings.info.use_membership"}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_additional_dynamic_field_1 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_dynamic_field_1" className="mb-1" checked={this.state.show_additional_dynamic_field_1}
                                    id="custom-switch-show-additional-dynamic-field-1" name="settings.show_additional_dynamic_field_1"
                                    onChange={event => this.onChange(event, () => this.setState({ show_additional_dynamic_field_1: !this.state.show_additional_dynamic_field_1, additional_dynamic_field_1_required: false }, () => {
                                        delete this.props.values.settings.additional_dynamic_field_1_type;
                                        delete this.props.values.settings.additional_dynamic_field_1_title;
                                        delete this.props.values.settings.additional_dynamic_field_1_text;
                                        delete this.props.values.settings.additional_dynamic_field_1_required;
                                    }))}
                                />
                            </Col>

                            {this.state.show_additional_dynamic_field_1 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="no-suffix mb-1 is-invalid">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select" name="settings.additional_dynamic_field_1_type"
                                                        aria-describedby="additional_dynamic_field_1_type"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_type}
                                                        value={this.props.values.settings.additional_dynamic_field_1_type}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="dropdown">dropdown</option>
                                                        <option value="text">text</option>
                                                    </Form.Control>
                                                </InputGroup>

                                                {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_type && (
                                                    <Form.Control.Feedback type="invalid" className="mb-2">
                                                        <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_1_type} />
                                                    </Form.Control.Feedback>
                                                )}

                                            </Col>
                                            {!this.state.additional_dynamic_field_1_title ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 is-invalid">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="text"
                                                            aria-describedby="additional_dynamic_field_1_title"
                                                            name="settings.additional_dynamic_field_1_title"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_title}
                                                            value={this.props.values.settings.additional_dynamic_field_1_title}
                                                        />
                                                    </InputGroup>

                                                    {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_title && (
                                                        <Form.Control.Feedback type="invalid" className="mb-2">
                                                            <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_1_title} />
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Col>
                                                : null}
                                            {!this.state.additional_dynamic_field_1_text ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 ig-textarea">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <CustomTextArea
                                                            aria-describedby="additional_dynamic_field_1_text"
                                                            name="settings.additional_dynamic_field_1_text"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_text}
                                                        >
                                                            {this.props.values.settings.additional_dynamic_field_1_text}
                                                        </CustomTextArea>
                                                    </InputGroup>
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_1_text && (
                                                            <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_1_text} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                : null}
                                            <Col lg={12}>
                                                <Form.Check type="switch" className="mb-1" label="additional_dynamic_field_1_required" checked={this.state.additional_dynamic_field_1_required}
                                                    id="custom-switch-additional_dynamic_field_1_required" name="settings.additional_dynamic_field_1_required"
                                                    onChange={event => this.onChange(event, () => this.setState({ additional_dynamic_field_1_required: !this.state.additional_dynamic_field_1_required }))}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col lg={12}>
                                <FormattedMessage id="CMS.Attendee.Form.settings.info.show_additional_dynamic_field_1">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_additional_dynamic_field_2 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_dynamic_field_2" className="mb-1" checked={this.state.show_additional_dynamic_field_2}
                                    id="custom-switch-show-additional-dynamic-field-2" name="settings.show_additional_dynamic_field_2"
                                    onChange={event => this.onChange(event, () => this.setState({ show_additional_dynamic_field_2: !this.state.show_additional_dynamic_field_2, additional_dynamic_field_2_required: false }, () => {
                                        delete this.props.values.settings.additional_dynamic_field_2_type;
                                        delete this.props.values.settings.additional_dynamic_field_2_title;
                                        delete this.props.values.settings.additional_dynamic_field_2_text;
                                        delete this.props.values.settings.additional_dynamic_field_2_required;
                                    }))}
                                />
                            </Col>

                            {this.state.show_additional_dynamic_field_2 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="no-suffix mb-1 is-invalid">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select" name="settings.additional_dynamic_field_2_type"
                                                        aria-describedby="additional_dynamic_field_2_type"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_type}
                                                        value={this.props.values.settings.additional_dynamic_field_2_type}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="dropdown">dropdown</option>
                                                        <option value="text">text</option>
                                                    </Form.Control>
                                                </InputGroup>

                                                {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_type && (
                                                    <Form.Control.Feedback type="invalid" className="mb-2">
                                                        <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_2_type} />
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            {!this.state.additional_dynamic_field_2_title ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 is-invalid">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="text"
                                                            aria-describedby="additional_dynamic_field_2_title"
                                                            name="settings.additional_dynamic_field_2_title"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_title}
                                                            value={this.props.values.settings.additional_dynamic_field_2_title}
                                                        />
                                                    </InputGroup>

                                                    {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_title && (
                                                        <Form.Control.Feedback type="invalid" className="mb-2">
                                                            <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_2_title} />
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Col>
                                                : null}
                                            {!this.state.additional_dynamic_field_2_text ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 ig-textarea">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <CustomTextArea
                                                            aria-describedby="additional_dynamic_field_2_text"
                                                            name="settings.additional_dynamic_field_2_text"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_text}
                                                        >
                                                            {this.props.values.settings.additional_dynamic_field_2_text}
                                                        </CustomTextArea>
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_2_text && (
                                                                <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_2_text} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>

                                                : null}
                                            <Col lg={12}>
                                                <Form.Check type="switch" className="mb-1" label="additional_dynamic_field_2_required" checked={this.state.additional_dynamic_field_2_required}
                                                    id="custom-switch-additional_dynamic_field_2_required" name="settings.additional_dynamic_field_2_required"
                                                    onChange={event => this.onChange(event, () => this.setState({ additional_dynamic_field_2_required: !this.state.additional_dynamic_field_2_required }))}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col lg={12}>
                                <FormattedMessage id="CMS.Attendee.Form.settings.info.show_additional_dynamic_field_2">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_additional_dynamic_field_3 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_dynamic_field_3" className="mb-1" checked={this.state.show_additional_dynamic_field_3}
                                    id="custom-switch-show-additional-dynamic-field-3" name="settings.show_additional_dynamic_field_3"
                                    onChange={event => this.onChange(event, () => this.setState({ show_additional_dynamic_field_3: !this.state.show_additional_dynamic_field_3, additional_dynamic_field_3_required: false }, () => {
                                        delete this.props.values.settings.additional_dynamic_field_3_type;
                                        delete this.props.values.settings.additional_dynamic_field_3_title;
                                        delete this.props.values.settings.additional_dynamic_field_3_text;
                                        delete this.props.values.settings.additional_dynamic_field_3_required;
                                    }))}
                                />
                            </Col>

                            {this.state.show_additional_dynamic_field_3 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="no-suffix mb-1 is-invalid">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">type</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Form.Control as="select" name="settings.additional_dynamic_field_3_type"
                                                        aria-describedby="additional_dynamic_field_3_type"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_type}
                                                        value={this.props.values.settings.additional_dynamic_field_3_type}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="dropdown">dropdown</option>
                                                        <option value="text">text</option>
                                                    </Form.Control>
                                                </InputGroup>

                                                {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_type && (
                                                    <Form.Control.Feedback type="invalid" className="mb-2">
                                                        <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_3_type} />
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            {!this.state.additional_dynamic_field_3_title ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 is-invalid">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">label</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control type="text"
                                                            aria-describedby="additional_dynamic_field_3_title"
                                                            name="settings.additional_dynamic_field_3_title"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_title}
                                                            value={this.props.values.settings.additional_dynamic_field_3_title}
                                                        />
                                                    </InputGroup>

                                                    {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_title && (
                                                        <Form.Control.Feedback type="invalid" className="mb-2">
                                                            <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_3_title} />
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Col>
                                                : null}
                                            {!this.state.additional_dynamic_field_3_text ?
                                                <Col md={12}>
                                                    <InputGroup className="no-suffix mb-1 ig-textarea">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <CustomTextArea
                                                            aria-describedby="additional_dynamic_field_3_text"
                                                            name="settings.additional_dynamic_field_3_text"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_text}
                                                        >
                                                            {this.props.values.settings.additional_dynamic_field_3_text}
                                                        </CustomTextArea>
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.additional_dynamic_field_3_text && (
                                                                <AttendeeFormError error={this.props.errors.settings.additional_dynamic_field_3_text} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                : null}
                                            <Col lg={12}>
                                                <Form.Check type="switch" className="mb-1" label="additional_dynamic_field_3_required" checked={this.state.additional_dynamic_field_3_required}
                                                    id="custom-switch-additional_dynamic_field_3_required" name="settings.additional_dynamic_field_3_required"
                                                    onChange={event => this.onChange(event, () => this.setState({ additional_dynamic_field_3_required: !this.state.additional_dynamic_field_3_required }))}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col lg={12}>
                                <FormattedMessage id="CMS.Attendee.Form.settings.info.show_additional_dynamic_field_3">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title="CMS.Attendee.Form.setting.delete.title" text="CMS.Attendee.Form.setting.delete.text"
                    buttons={{ confirm: { text: 'Default.confirm', icon: 'check' } }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default AttendeeCustomSettingsFormComponent;