import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaRepository from "../../../repository/MediaRepository";
import FormToastComponent from "../../basics/FormToastComponent";
import DeleteModalComponent from "../../basics/table/DeleteModalComponent"
import BackgroundVideo from "../../../models/media/BackgroundVideo";
import FsLightbox from "fslightbox-react";
import parse from "html-react-parser";
import Module from "../../../models/module/Module";
import MediaHelper from "../../../helpers/MediaHelper";

class MediaAlbumOverviewComponent extends React.Component {
    constructor(props) {
        super(props);

        let module = Module.get();

        this._deleteRef = null;

        this.state = {
            draggingElement: null,
            lightboxToggler: true,
            slide: 1,
            module: module ? module.module.type : null
        }
    }

    async componentDidMount() {
        if (this.props.media === undefined) {
            let rows = [];
            for (let row of this.props.rows) {
                row = await BackgroundVideo.create(row)
                rows.push(row);
            }

            this.props.parent.setState({
                rows: rows
            })
        }
    }

    dragItem = (e) => {
        let draggedItem = e.target.closest(".table-album-item");
        draggedItem.style.cursor = "grabbing";
        this.setState({ draggingElement: draggedItem })
    }

    allowDrop = (e) => e.preventDefault();

    drop = (e) => {
        e.preventDefault();
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let rowToDrop = e.target.closest('.table-album-item');

        if (rowToDrop.dataset.key !== this.state.draggingElement.dataset.key) {
            let parent = document.getElementById('table-album');
            let children = parent.children;
            let currentOrder = [];
            let currentOrderChildren = {}

            for (let i = 0; i < children.length; i++) {
                currentOrder.push(children[i].dataset.key);
                currentOrderChildren[children[i].dataset.key] = children[i];
            }

            let fromKey = this.state.draggingElement.dataset.key;
            let fromIndex = currentOrder.indexOf(fromKey);

            let toKey = rowToDrop.dataset.key;
            let toIndex = currentOrder.indexOf(toKey);

            let newOrder = currentOrder;
            newOrder.splice(fromIndex, 1); // Remove dragged el
            newOrder.splice(toIndex, 0, fromKey); // Add dragged el on new position

            parent.innerHTML = '';
            for (let i = 0; i < newOrder.length; i++) {
                let newKey = newOrder[i];
                parent.appendChild(currentOrderChildren[newKey]);
            }

            this.setState({ draggingElement: null })

            this.updateOrder();
        }
    }

    updateOrder = () => {
        let parent = document.getElementById('table-album');
        let children = parent.children;
        let newOrder = [];

        for (let i = 0; i < children.length; i++) {
            newOrder.push(children[i].dataset.key);
        }

        MediaRepository.updateOrder(newOrder, this.props.type, this.state.module ? this.state.module : 'page', this.props.match.params.id !== undefined ? this.props.match.params.id : this.props.id)
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.success) {
                    FormToastComponent.successTrans('Media', 'CMS.Media.Album.Order.updated.success');

                    this.props.parent.setState({ media: response.data.media });

                    this.setState({ reload: true, newOrder: newOrder }, () => {
                        this.setState({ reload: false })
                    })
                } else {
                    FormToastComponent.errorTrans('Media', 'CMS.Media.Album.Order.updated.failed')
                }
            })
            .catch(error => {
                console.error(error);
                FormToastComponent.errorTrans('Media', 'CMS.Media.Album.Order.updated.failed')
            })
    }

    render() {
        if (this.props.type === undefined) return 'type required';
        if (this.state.reload) return null;

        let rows = [...this.props.media !== undefined ? this.props.media : this.props.rows];

        let updatedRows = [];

        if (this.state.newOrder !== undefined) {
            this.state.newOrder.forEach((row) => {
                updatedRows.push(rows.find(x => x.id === parseInt(row)));
            })

            rows = updatedRows;
        }

        let images = [];
        let loadLightbox = true;

        images = rows.map(function (row) {
            if (row instanceof BackgroundVideo) {
                if (row.embed != null && row.embed.html) {
                    let parseHtml = parse(row.embed.html);
                    if (Array.isArray(parseHtml)) {
                        if (row.type === "youtube") {
                            parseHtml = parseHtml[1];
                        } else if (row.type === "slideshare") {
                            parseHtml = parseHtml[0];
                        }
                    }
                    return parseHtml;
                } else {
                    return <video width="1920" height="1080" controls src={row.url} autoPlay={true} />
                }
            }

            loadLightbox = false;
            return null;
        });

        if (rows.length <= 0) {
            return (
                <div className="custom-data-table">
                    <FormattedMessage id={"CMS.Media.Album." + this.props.type + ".overview.empty"}>
                        {value => <p className="not-found">{value}</p>}
                    </FormattedMessage>
                </div>
            )
        }

        return (
            <div className="custom-data-table">
                <div className="table-header">
                    <FormattedMessage id={"CMS.Media.Album." + (this.props.overview_title ? this.props.overview_title : this.props.type) + ".overview.title"}>
                        {title => <p>{title}</p>}
                    </FormattedMessage>
                </div>
                <div className="table-album" id="table-album">
                    {rows.map((media_item, index) => {
                        if (media_item === undefined) return null;
                        return <div key={index} className="image table-album-item" style={{ cursor: "pointer" }} draggable data-key={media_item.id}
                            onDragStart={(e) => this.dragItem(e, index)}
                            onDragOver={(e) => this.allowDrop(e)}
                            onDrop={(e) => this.drop(e, index)}
                        >
                            {media_item.video === true && media_item.mime_type !== "external" ?
                                <div onClick={event => this.setState({ lightboxToggler: !this.state.lightboxToggler, slide: index + 1 })}>
                                    <video width="150" height="110" src={media_item.url + "#t=0.1"} />
                                    <div className={"overlay"}>
                                        <FontAwesomeIcon icon={['far', 'play']} />
                                    </div>
                                </div>
                                :
                                (media_item.video === true && media_item.mime_type === "external" ?
                                    <div onClick={(event => this.setState({ lightboxToggler: !this.state.lightboxToggler, slide: index + 1 }))}>
                                        <Image src={media_item['url']} fluid={true} />
                                        <div className={"overlay"}>
                                            <FontAwesomeIcon icon={['far', 'play']} />
                                        </div>
                                    </div>
                                    :
                                    <Image src={MediaHelper.buildImageSrc(media_item['sized_images'] && media_item['sized_images']['thumbnail'] ? media_item['sized_images']['thumbnail'] : media_item['url'], this.props.parent.state.website)} fluid={true} />
                                )}
                            <Link to="#" className="delete" test={index + 'test'} onClick={(event) => DeleteModalComponent.openDeleteModal(event, media_item, this)}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                            </Link>
                        </div>
                    })}
                </div>

                <DeleteModalComponent ref={r => this._deleteRef = r} parent={this} customDeleteRowFunction={(event) => {
                    event.preventDefault();
                    let media = this.state.deleteRow;

                    let type = null;
                    let componentName = this.props.component_name ? this.props.component_name : (this.props.parent ? this.props.parent.props.component_name : null);
                    if (componentName && componentName.indexOf('pages') <= -1) {
                        let module = Module.get();
                        type = module ? module.module.type : null;
                    }
                    let id = this.props.media !== undefined ? this.props.id : this.props.match.params.id;

                    MediaRepository.delete(media.id, this.props.type, id, type)
                        .then(response => {
                            if (response !== undefined && response.data !== undefined && response.data.success) {
                                FormToastComponent.successTrans('Media', 'CMS.Media.Album.Order.deleted.success');

                                rows = rows.filter((item) => {
                                    if (item === undefined) {
                                        return false;
                                    }
                                    return item.id !== media.id;
                                })

                                if (this.props.media !== undefined) {
                                    this.props.parent.setState({ media: rows }, this._deleteRef.closeModal)
                                } else {
                                    this.props.parent.setState({ rows: rows }, this._deleteRef.closeModal)
                                }
                            } else {
                                FormToastComponent.errorTrans('Media', 'CMS.Media.Album.Order.deleted.failed')
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            FormToastComponent.errorTrans('Media', 'CMS.Media.Album.Order.deleted.failed')
                        })
                }} />

                {loadLightbox && (
                    <FsLightbox
                        toggler={this.state.lightboxToggler}
                        customSources={images}
                        slide={this.state.slide}
                        disableLocalStorage={true}
                        key={this}
                    />
                )}
            </div >
        )
    }
}

export default withRouter(MediaAlbumOverviewComponent);