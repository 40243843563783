import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class FormEmailError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field_name}>
                {(field) =>
                    <FormattedMessage id="CMS.Form.field.email" values={{ field: field }}>
                        {message => <p>{message}</p>}
                    </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

FormEmailError.propTypes = {
    field_name: PropTypes.string.isRequired,
};
export default FormEmailError