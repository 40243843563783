class Application {

    // GENERAL FUNCTIONS
    static get = (key) => localStorage.getItem('application.' + key);
    static set = (key, value) => localStorage.setItem('application.' + key, value);
    static remove = (key) => localStorage.removeItem('application.' + key);

    static clear = () => {
        this.remove('table_language');
        this.remove('table_dropdown');
        this.remove('settings');
    }

    static getLanguages = () => ['nl', 'fr', 'en'];
    static language = () => localStorage.getItem('language');

    // TABLE LANGUAGE
    static setTableLanguage = (language) => this.set('table_language', language);

    // Table number
    static updateTablePageNumber = (page, id) => this.set('table_page_number' + (id ? '_' + id : ''), page);
    static resetTablePageNumber = () => {
        this.remove('table_page_number');
        this.remove('table_page_number_default');
        this.remove('table_page_number_website');
    }
    static tablePageNumber = (id) => {
        let page = localStorage.getItem('application.table_page_number' + (id ? '_' + id : ''));
        return page ? parseInt(page) : 1;
    }

    static tableLanguage() {
        let language = localStorage.getItem('application.table_language');
        if (language === undefined) return 0;
        return language;
    }
    static tableDropdown = () => this.get('table_dropdown');
    static updateTableDropdown = (id) => id ? this.set('table_dropdown', id) : this.remove('table_dropdown');

    // SETTINGS
    static setSettings = (value) => this.set('settings', JSON.stringify(value));

    static setting = (key, def = null) => {
        let settings = localStorage.getItem('application.settings');
        if (!settings) return null;

        settings = JSON.parse(settings);

        let setting = settings.filter(setting => setting.name === key);

        if (!settings || !setting[0]) return def;
        return setting[0]['value'];
    }

    static generateUniqueId = (length = 5) => {
        let id = '';
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        for (let i = 1; i <= length; i++) {
            id += characters.charAt(Math.floor(Math.random() * length));
        }
        return id;
    }
}

export default Application;