import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class ToolTipLink extends React.Component {

    render() {
        return <FormattedMessage id={this.props.title}>
            {title =>
                <Link {...this.props} data-tip data-for={title}>
                    {this.props.children}
                    <ReactTooltip id={title} type="success" effect="solid" className="custom-tooltip">
                        <span>{title}</span>
                    </ReactTooltip>
                </Link>
            }
        </FormattedMessage>;
    }
}


ToolTipLink.propTypes = {
    title: PropTypes.string.isRequired
}
export default ToolTipLink;