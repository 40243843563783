import React from "react";
import StandardTableComponent from "../../basics/table/StandardTableComponent";
import DeleteModalComponent from "../../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../../basics/table/TableDeleteActionComponent";
import CheckPermissions from "../../../helpers/CheckPermissions";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import GeneralSettingsHelper from "../../../helpers/GeneralSettingsHelper";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";
import EmptyTablePlaceholderComponent from "../../basics/EmptyTablePlaceholderComponent";
import moment from "moment";

class BlogSubscriptionOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            rows: this.props.rows
        };
    }

    columns = () => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'email',
                text: <FormattedMessageString id="CMS.Blog.Subscription.overview.table.email" />,
                isDummyField: true,
                sort: true,
                formatter: (cellContent, row) => row.email
            },
            {
                dataField: 'language',
                text: <FormattedMessageString id="CMS.Blog.Subscription.overview.table.language" />,
                isDummyField: true,
                formatter: (cellContent, row) => row.language.toUpperCase(),
                headerStyle: (colum, colIndex) => { return { width: '5%' } },
            },
            {
                dataField: 'consent',
                text: <FormattedMessageString id="CMS.Blog.Subscription.overview.table.consent" />,
                isDummyField: true,
                sort: false,
                formatter: (cellContent, row) => <FormattedMessageString id="CMS.Blog.Subscription.overview.table.consent_yes" />,
                headerStyle: (colum, colIndex) => { return { width: '100px' } },
            },
            {
                dataField: 'confirmed_at',
                text: <FormattedMessageString id="CMS.Blog.Subscription.overview.table.confirmed_at" />,
                isDummyField: true,
                sort: true,
                formatter: (cellContent, row) => row.confirmed_at ? moment(row.confirmed_at).format('DD/MM/YYYY HH:mm') : '-',
                headerStyle: (colum, colIndex) => { return { width: '250px' } },
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => { return { width: '10%' } },
                formatter: (cellContent, row) => (
                    <div className="actions">
                        {(CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR)) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                    </div>
                )
            }
        ];
    }

    render() {
        if (this.state.rows <= 0) return <EmptyTablePlaceholderComponent typeId={this.props.title_id} />
        return (
            <div className="tab-tables">
                <div className="custom-data-table forms">
                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns()} search={true}
                        title={this.props.title_id} type={this.props.type} subRows={false}
                        sort={{ dataField: 'email', order: 'asc' }}
                    />
                </div>
                <DeleteModalComponent ref={r => this._deleteRef = r} parent={this} />
            </div>
        );
    }
}

export default BlogSubscriptionOverviewTableComponent;