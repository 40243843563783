import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion, AccordionCollapse, AccordionToggle, Alert, Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import FormFormError from "../errors/FormFormError";
import FieldComponent from "./FieldComponent";
import { injectIntl } from "react-intl";

class GroupComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true
        };
    }

    render() {
        let { group, selected, selectGroup, removeGroup, handleSortFields, handleEndSortFields, index, errors } = this.props;
        let { toggleOpen } = this.state;
        const key = 'group_' + group.order;

        let fieldsError = '';

        if (errors?.fields && typeof errors.fields === 'string') {
            fieldsError = (
                <Alert variant="danger" size="sm">
                    <FormFormError error={errors.fields} />
                </Alert>
            );
        }

        return (
            <div className={`fbgroup ${selected ? 'is-selected' : ''}`} onClick={(event) => selectGroup(event, group)}>
                <Accordion defaultActiveKey={toggleOpen ? key : null}>
                    <div className="fbgroup-header w-30">
                        <div className="fbgroup-handle">
                            <FontAwesomeIcon icon={['fas', 'fa-up-down-left-right']} />
                        </div>
                        <div className="fbgroup-head form-group">
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder={this.props.intl.formatMessage({ id: 'CMS.Form.Form.groups.label.placeholder' })}
                                onChange={this.onChange}
                                value={group.title}
                                isInvalid={errors?.title ? true : false}
                            />
                            {(errors?.title) ? <Form.Control.Feedback type="invalid"><FormFormError error={errors.title} /></Form.Control.Feedback> : ''}
                        </div>
                        <div className="fbgroup-actions">
                            <div className="fbgroup-action">
                                <ButtonGroup toggle>
                                    <ToggleButton
                                        type="checkbox"
                                        variant="link"
                                        value="1"
                                        checked={group.active}
                                        onChange={(event) => this.handleToggleGroupActive(event, group)}
                                    >
                                        <FontAwesomeIcon fixedWidth icon={['fas', (group.active) ? 'eye' : 'eye-slash']} />
                                    </ToggleButton>
                                </ButtonGroup>
                            </div>
                            <div className="fbgroup-action">
                                <Button variant="danger" className="iconbtn" onClick={() => removeGroup(this.props.index)}>
                                    <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                </Button>
                            </div>
                            <div className="fbgroup-action">
                                <AccordionToggle className="fbgroup-accordiontoggle" as={Button} variant="link" eventKey={key} onClick={this.handleAccordionToggle}>
                                    <FontAwesomeIcon icon={['fas', (toggleOpen) ? 'angle-up' : 'angle-down']} />
                                </AccordionToggle>
                            </div>
                        </div>
                    </div>
                    {fieldsError}
                    <AccordionCollapse eventKey={key} className="fbgroup-body">
                        <React.Fragment>
                            <ReactSortable group="FieldComponents" handle=".fbgroup-fieldhandle" list={group.fields} setList={(fields) => handleSortFields(group, fields)} onEnd={() => handleEndSortFields(index)}>
                                {group.fields.map((field, index) =>
                                    <FieldComponent
                                        key={index}
                                        index={index}
                                        errors={(errors?.fields && typeof errors.fields === 'object' && index in errors.fields) ? errors.fields[index] : {}}
                                        field={field}
                                        updateField={this.updateField}
                                        removeField={this.removeField}
                                        settings={this.props.types[field.type]}
                                    />
                                )}
                            </ReactSortable>
                        </React.Fragment>
                    </AccordionCollapse>
                </Accordion>
            </div >
        );
    }

    handleToggleGroupActive = (event, group) => {
        group.active = event.currentTarget.checked;
        this.props.updateGroup(group);
    }

    handleAccordionToggle = (event) => {
        return this.setState({ toggleOpen: !this.state.toggleOpen });
    }

    onChange = (e) => {
        let { group } = this.props;
        let { name, value } = e.target;

        group[name] = value;
        this.props.updateGroup(group);
    }

    updateField = (index, field) => {
        let { group } = this.props;

        group.fields[index] = field;

        this.props.updateGroup(group);
    }

    removeField = (index) => {
        let { group, errors } = this.props;

        group.fields.splice(index, 1);

        if (errors?.fields && index in errors.fields) {
            errors.fields.splice(index, 1);
        }

        this.props.updateGroup(group);
    }
}

export default injectIntl(GroupComponent);