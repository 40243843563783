import React from "react";
import spinner from '../../images/spinner.svg';
import {Image} from "react-bootstrap";

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className={"spinner"}>
                <Image src={spinner} fluid={true}/>
            </div>
        );
    }
}

export default SpinnerComponent;
