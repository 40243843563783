import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { injectIntl } from "react-intl";
import FormFormError from "../errors/FormFormError";

class FieldOptionComponent extends React.Component {
    render() {
        let { option, intl, errors } = this.props;
        
        return (
            <div className="fbgroup">
                <div className="fbgroup-header">
                    <div className="fbgroup-optionhandle">
                        <FontAwesomeIcon icon={['fas', 'fa-up-down-left-right']} />
                    </div>
                    <div className="fbgroup-head">
                        <InputGroup className={errors?.label || errors?.value ? 'is-invalid' : ''}>
                            <InputGroup.Text>
                                {option.order}
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="label"
                                onChange={this.onChange}
                                value={option.label}
                                placeholder={intl.formatMessage({ id: "CMS.Form.Form.fields.options.label.placeholder" }, { 'number': option.order })}
                                isInvalid={errors?.label ? true : false}
                            />

                            <Form.Control
                                type="text"
                                name="value"
                                onChange={this.onChange}
                                value={option.value}
                                placeholder={intl.formatMessage({ id: "CMS.Form.Form.fields.options.value.placeholder" }, { 'number': option.order })}
                                isInvalid={errors?.value ? true : false}
                            />
                        </InputGroup>
                        {(errors?.label) ? <Form.Control.Feedback type="invalid"><FormFormError error={errors.label} /></Form.Control.Feedback> : ''}
                        {(errors?.value) ? <Form.Control.Feedback type="invalid"><FormFormError error={errors.value} /></Form.Control.Feedback> : ''}
                    </div>
                    <div className="fbgroup-actions">
                        <Button variant="invalid" className="iconbtn" onClick={this.remove}>
                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    onChange = (e) => {
        let { option } = this.props;
        let { name, value } = e.target;

        option[name] = value;
        this.update(option);
    }

    update = (option) => this.props.updateOption(this.props.index, option);

    remove = () => this.props.removeOption(this.props.index);
}

export default injectIntl(FieldOptionComponent);