import axios from "axios";
import config from 'react-global-configuration';
import WebsiteTableRow from "../models/website/WebsiteTableRow";
import WebsiteTranslationTableRow from "../models/website/WebsiteTranslationTableRow";
import CustomHeaders from "./CustomHeaders";
import MediaTableRow from "../models/media/MediaTableRow";
import CountryHelper from "../helpers/CountryHelper";
import Website from "../models/website/Website";
import WebsiteSettingRow from "../models/website/WebsiteSettingRow";
import MediaHelper from "../helpers/MediaHelper";
import UserRepository from "./UserRepository";
import API from "../services/api";

class WebsiteRepository {

    static create(values) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/store',
                values.toArray(),
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(true);
                    }
                })
                .catch(error => reject(error.response));
        })
    }

    static update(id, values) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/edit',
                values.toArray(id),
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (response.data.success != null) {
                        resolve(true);
                    }
                })
                .catch(error => reject(error.response));
        })
    }

    static get(id) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/website/details?id=' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (response.data.success) {
                        resolve(response.data.website);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => reject(error.message))
        })
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/toggle',
                {
                    'id': id
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(response.data.active);
                    }
                })
                .catch(error => reject(error.message));
        });
    }

    static delete(id) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/website/delete?id=' + id,
                {
                    headers: CustomHeaders.get(null, id)
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(response.data.success);
                    } else {
                        reject(false)
                    }
                })
                .catch(error => reject(false));
        })
    }

    static getWebsiteRows() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/website/overview',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    let data = response['data'];
                    let rows = [];
                    if (data !== undefined && data['websites'] !== undefined) {
                        data = data['websites'];
                        data.forEach((website, index, arr) => {
                            let languages = website['languages'].map(language => language.language);
                            rows.push(new WebsiteTableRow(website.id, website.name, website.domain, languages, website.customer, website.active, website.api_key))
                        });
                    }

                    resolve(rows);
                })
                .catch(error => reject(error.message))
        })
    }

    static getUserWebsiteRows() {
        return new Promise((resolve, reject) => {
            UserRepository.getUser()
                .then(response => {
                    let data = response.data.user;
                    let rows = [];
                    if (data !== undefined && data.websites !== undefined) {
                        data.websites.forEach(website => {
                            if (website != null) {
                                rows.push(new WebsiteTableRow(website.id, website.name, website.domain, ["nl", "fr", "en"], website.customer))
                            }
                        });
                    }

                    resolve(rows);
                })
                .catch(error => reject(error.message))
        });
    }

    static getAll = () => {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/website/overview',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data.websites))
                .catch(reason => reject(reason.response))
        })
    }

    // MEDIA
    static uploadMedia(name, file, options = null) {
        const data = new FormData();
        data.append('name', name);
        data.append('file', file);

        return new Promise((resolve, reject) => {
            if (!MediaHelper.isFileSizeAllowed(file)) {
                reject({ message: 'Network Error' });
                return;
            }

            axios.post(
                config.get('api_url') + '/website/media/upload', data,
                {
                    ...options,
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (response.status !== 200) {
                        reject(response);
                    }
                    resolve(response)
                })
                .catch(error => reject(error.response))
        })
    }

    static uploadExternalMedia(name, url) {
        if (url.substr(0, 8) !== 'https://' || url.substr(0, 7) === 'http://') {
            url = 'https://' + url;
        }

        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/media/external/upload',
                {
                    name: name,
                    url: url
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
        })
    }

    static getMediaRows() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/website/media/all',
                { headers: CustomHeaders.get() }
            )
                .then(response => {
                    let rows = [];
                    let success = response['data']['success'];
                    if (success) {
                        let data = response['data'][0];
                        let counter = 0;
                        if (!Array.isArray(data['media_items'])) {
                            data['media_items'] = Object.values(data['media_items']);
                        }
                        data['media_items'].forEach((media, index, arr) => {
                            rows.push(new MediaTableRow(counter, media.id, media.name, media.file_name, media.size, (media.full_path ? media.full_path : media.path), media.url, media.mime_type, media.created_at));
                            counter++;
                        });
                        data['external_media_items'].forEach((media, index, arr) => {
                            rows.push(new MediaTableRow(counter, media.id, media.name, media.url, null, media.path, media.url, media.type, media.created_at, true));
                            counter++;
                        })
                    }
                    resolve(rows);
                })
                .catch(error => reject(error.message))
        });
    }

    static deleteMedia(media) {
        return new Promise((resolve, reject) => {
            if (media.external) {
                axios.delete(
                    config.get('api_url') + '/website/media/external/delete?media-id=' + media.key,
                    {
                        headers: CustomHeaders.get()
                    }
                )
                    .then(response => resolve(response))
                    .catch(error => reject(error.response));
            } else {
                axios.delete(
                    config.get('api_url') + '/website/media/delete?media-id=' + media.key,
                    {
                        headers: CustomHeaders.get()
                    }
                )
                    .then(response => resolve(response))
                    .catch(error => reject(error.response));
            }
        });
    }

    // LANGUAGES
    static getTranslations(id) {
        return new Promise((resolve, reject) => {
            axios.post(config.get('api_url') + '/website/languages/overview',
                {
                    website_id: id,
                },
                {
                    headers: CustomHeaders.get()
                })
                .then(response => {
                    let rowsArray = [];
                    if (response['data']) {
                        let website = response['data']['website'];
                        let languages = website['languages'];
                        if (languages.length > 0) {
                            languages.forEach((language, index, arr) => {
                                let rows = [];
                                let translations = language['translations'];
                                if (translations.length > 0) {
                                    translations.forEach((translation, index, arr) => {
                                        rows.push(new WebsiteTranslationTableRow(translation.id, translation.type, translation.name, translation.translation, language.language));
                                    });
                                }

                                let rowInfo = {
                                    rows: rows,
                                    language: CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label,
                                    language_code: language.language
                                };

                                rowsArray.push(rowInfo);
                            })
                        }
                    }
                    resolve(rowsArray);
                })
                .catch(error => reject(error.message))
        });
    }

    static deleteLanguage(id, language) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/website/languages/delete?website=' + id + '&language=' + language,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(reason => reject(reason.response));
        })
    }

    static addLanguage(language, website) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/languages/store',
                {
                    'language': language,
                    'website': website
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(reason => reject(reason.response));
        })
    }

    // TRANSLATIONS
    static addTranslations(data) {
        data = {
            'website_id': Website.get(),
            'translations': {
                '0': {
                    'name': data.name,
                    'translation': data.translations
                }
            }
        };
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/translations/create',
                data,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(reason => reject(reason));
        })
    }

    static updateTranslations(website_id, data) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/translations/update',
                {
                    "website_id": website_id,
                    "translation_id": data.id,
                    "name": data.name,
                    "translation": data.translation
                },
                {
                    headers: CustomHeaders.get(),
                }
            )
                .then(response => resolve(response))
                .catch(reason => reject(reason))
        })
    }

    // SETTINGS
    static getSettings(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get("api_url") + "/website/settings/overview",
                { website_id: id },
                { headers: CustomHeaders.get() })
                .then(response => {
                    let defaultRows = [];
                    let websiteRows = [];

                    if (response.status === 200 && response.data != null && response.data.settings != null) {
                        let settings = response.data.settings;
                        let counter = 0;
                        if (settings.default != null) {
                            defaultRows = settings.default.map(function (setting) {
                                counter++;
                                return new WebsiteSettingRow(setting.id, counter, setting.name, setting.value, true);
                            });
                        }
                        if (settings.website != null) {
                            websiteRows = settings.website.map(function (setting) {
                                counter++;
                                return new WebsiteSettingRow(setting.id, counter, setting.name, setting.value);
                            });
                        }
                        resolve({
                            'default': defaultRows,
                            'website': websiteRows
                        })
                    } else {
                        reject("no records");
                    }


                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        })
    }

    static storeSettings(website_id, name, value, type, id = null) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/website/settings/create',
                {
                    'website_id': website_id,
                    'name': name,
                    'value': value,
                    'type': type,
                    'id': id
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(reason => {
                    reject(reason);
                })
        });
    }

    static deleteSetting(id, name) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/website/settings/delete?website_id=' + id + '&name=' + name,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(reason => {
                    reject(reason);
                })
        });

    }

    static deleteTranslation(id, name) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/website/translations/delete?website_id=' + id + '&name=' + name,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(reason => {
                    reject(reason);
                })
        })
    }
}


export default WebsiteRepository;
