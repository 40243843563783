import React from "react";
import PropTypes from "prop-types";
import FaqContent from "../../models/faq/FaqContent";
import FaqRepository from "../../repository/FaqRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import FaqTextFormComponent from "./forms/FaqTextFormComponent";
import Module from "../../models/module/Module";
import ModuleRepository from "../../repository/ModuleRepository";
import * as Yup from "yup"
import { LoaderComponent } from "../basics/layout";

class FaqAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new FaqContent(),
            loading: true,
            settings: {},
            isEdit: false,
            roles: this.props.user.roles
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        Promise.all(promises)
            .then(result => {
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }

                this.setState({
                    loading: false,
                    settings: settings
                });
            });
    }

    validation() {
        let fields = {
            question: Yup.string().required('CMS.Faq.Content.Form.question').max(255, 'CMS.Faq.Form.name|255|max'),
            answer: Yup.object({ text: Yup.string().required('CMS.Faq.Content.Form.answer') })
        };

        if (this.state.settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Faq.Form.category|select');
        }

        return Yup.object(fields);
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className={"faq"}
                validationSchema={this.validation()}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    FaqRepository.create(values)
                        .then(response => {
                            FormToastComponent.successTrans("Faqs", "Default.saved");
                            this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                        })
                        .catch(error => {
                            FormHelper.handleSubmitError(this, error);
                        });
                }}
                formErrors={this.state.form_errors}
                fields={FaqTextFormComponent.fields(this.state.settings, 0)}
            />
        );
    }
}

FaqAddFormComponent.propTypes = {
    model: PropTypes.object.isRequired,
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired
};
export default withRouter(FaqAddFormComponent);
