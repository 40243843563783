import React from "react";
import PropTypes from "prop-types";
import FormMaxError from "../../errors/FormMaxError";
import FormRequiredError from "../../errors/FormRequiredError";

class WebsiteFormNameError extends React.Component {
    render() {
        if (this.props.error === "CMS.Website.Form.name.max") {
            return <FormMaxError field_name={this.props.error.slice(0, -4)} max={100} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

WebsiteFormNameError.propTypes = {
    error: PropTypes.string.isRequired
};
export default WebsiteFormNameError;