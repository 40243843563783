import { APPLICATION_UPDATE } from './application.actions';

let initial = {
    'table.dropdown': null
}

const applicationReducer = (state = initial, action) => {
    switch (action.type) {
        case APPLICATION_UPDATE:
            return {
                ...state,
                [action.name]: action.payload
            };
        default:
            return state;
    }
}

export default applicationReducer;