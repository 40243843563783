import * as Yup from 'yup';

class Media {
    static default_field_name = "CMS.Media.Form.name";

    name;
    media;
    media_url;

    constructor(name, media, media_url) {
        this.name = name;
        this.media = media;
        this.media_url = media_url;
    }

    static schema = Yup.object().shape({
        name: Yup.string().required(Media.default_field_name)
            .max(255, Media.default_field_name + ".max")
            .matches(/^[^`~!@#$%^&*()_+={}[\]|\\:;“’<,>.?๐฿]*$/, Media.default_field_name + '.invalid', { excludeEmptyString: true }),
        media: Yup.mixed().when('media_url', (media_url, passSchema) => media_url == null || media_url === '' ?
            passSchema.required("CMS.Media.Form.media")
            : passSchema
        ).nullable(),
        media_url: Yup.string().ensure().when('media', (media, passSchema) => media == null || media === '' ?
            passSchema.required("CMS.Media.Form.media")
            : passSchema
        ).nullable(),
    }, [['media', 'media_url']]);

    static eye_catcher_schema = Yup.object({
        title: Yup.string().max(255, 'CMS.Media.Form.name.max'),
        text: Yup.string().trim().required('CMS.Page.eyecatcher.upload.text'),
        url: Yup.string().test("is-url-without-https-http", "CMS.Website.Form.url.invalid", val => {
            if (val !== undefined) {
                let schemaUrl = Yup.object().shape({
                    url: Yup.string().url('CMS.Website.Form.url')
                });
                return schemaUrl.validate({ url: 'https://' + val.replace(/www./) })
            } else {
                return true;
            }
        }),
        media: Yup.mixed().required()
    })

    static eye_catcher_schema_edit = Yup.object({
        title: Yup.string().max(255, 'CMS.Media.Form.name.max'),
        text: Yup.string().trim().required('CMS.Page.eyecatcher.upload.text'),
        url: Yup.string().test("is-url-without-https-http", "CMS.Website.Form.url.invalid", val => {
            if (val !== undefined) {
                let schemaUrl = Yup.object().shape({
                    url: Yup.string().url('CMS.Website.Form.url')
                });
                return schemaUrl.validate({ url: 'https://' + val.replace(/www./) })
            } else {
                return true;
            }
        })
    })
}

export default Media;