import React from "react";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import MapRepository from "../../repository/MapRepository";
import FormToastComponent from "../basics/FormToastComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { FormattedMessage } from "react-intl";
import Application from "../../helpers/Application";
import FormattedMessageString from "../basics/FormattedMessageString";
import StandardTableFormInputComponent from "../basics/table/StandardTableFormInputComponent";
import cellEditFactory from "react-bootstrap-table2-editor";
import TableHelper from "../../helpers/TableHelper";
import Module from "../../models/module/Module";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLeafletMap from "./leaflet/MapLeafletMap";
import LoaderComponent from "../basics/layout/LoaderComponent";
import { TableEditActionComponent } from "../basics/table";

class MapOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        let module = Module.get();

        this.state = {
            rows: this.props.rows,
            settings: module ? Module.getSettings(module.module) : {},
            website: null,
            isLoading: true,
            language: Application.tableLanguage()
        };
    }

    componentDidMount = () => WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));

    columns(index) {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Maps.overview.table.name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.map.contents[index];
                    return content ? content.title : null;
                },
                editorRenderer: (editorProps, value, row) => {
                    let content = row.map.contents[index];
                    if (content !== undefined)
                        value = content.title;

                    if (editorProps.defaultValue) {
                        editorProps.defaultValue = undefined;
                    }

                    return <StandardTableFormInputComponent {...editorProps} value={value} />
                },
                validator: (newValue) => {
                    if (newValue && newValue.length > 255) {
                        return { valid: false, message: <FormattedMessageString id="CMS.Table.validation.max" values={{ max: 255 }} /> };
                    }
                }
            },
            {
                dataField: 'address',
                text: <FormattedMessageString id="CMS.Maps.overview.table.address" />,
                sort: true,
                isDummyField: true,
                editable: false,
                formatter: (cellContent, row) => {
                    let content = row.map.contents[index];
                    return content.address;
                }
            },
            {
                dataField: 'latitude',
                text: <FormattedMessageString id="CMS.Maps.overview.table.latitude" />,
                sort: false,
                editable: false,
                formatter: (cellContent, row) => row.map.latitude
            },
            {
                dataField: 'longitude',
                text: <FormattedMessageString id="CMS.Maps.overview.table.longitude" />,
                sort: false,
                editable: false,
                formatter: (cellContent, row) => row.map.longitude
            },
            {
                dataField: 'views',
                text: '',
                sort: false,
                isDummyField: true,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.map.contents[index];
                    return (
                        <div className="actions">
                            {
                                content && !GeneralSettingsHelper.is() ?
                                    <Link to="#" className="link px-1">
                                        <FontAwesomeIcon icon={['fa', 'fa-route']} className="mr-1" />
                                        {content.clicks}
                                    </Link>
                                    : null
                            }
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.map.contents[index];

                    return <div className="actions">


                        {this.state.settings.use_favorite && (
                            <Link onClick={() => this.toggleFavorite(row)} to="#" className={content && content.is_favorite ? 'favorite' : 'default'}>
                                <FontAwesomeIcon icon={['far', 'star']} size="xl" />
                            </Link>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableEditActionComponent id={row.map.id} custom={content !== undefined ? '/' + content.language : '/'} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                    </div>
                }
            }
        ];
    }

    toggleFavorite = (_row) => {
        let _content = _row.map.contents[this.state.language];
        if (!_content) return;

        MapRepository.toggle(_row.map.id, _content.language)
            .then(response => {
                let newRows = [...this.state.rows];
                newRows = newRows.map(row => {
                    let map = row.map;
                    map.contents[this.state.language].is_favorite = false;
                    if (row.map.id === _row.map.id) {
                        map.contents[this.state.language].is_favorite = response;
                    }

                    return { ...row, map: map }
                })

                this.setState(curr => ({ ...curr, rows: newRows, isLoading: true }), () => this.setState({ isLoading: false }));
            })
            .catch(error => FormToastComponent.errorTrans('CMS.Maps.overview.toggle.failed'))
    }

    postDeleteRowFunction = () => this.updateData();
    // updateData = () => MapRepository.getAll().then(response => this.setState({ isLoading: false, rows: response }));
    updateData = () => {
        let path = this.props.parent.props.location.pathname;
        this.props.parent.props.history.push('/');
        this.props.parent.props.history.replace(path);
    };

    table = (index) => {
        return (
            <div className={"custom-data-table maps"}>
                <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={false}
                    title={this.props.title_id} type={this.props.type}
                    subRows={false}
                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        onStartEdit: this.handleInlineStartEdit.bind(this),
                        afterSaveCell: this.handleInlineEditAfterSave.bind(this)
                    })}
                    sort={{
                        dataField: 'title',
                        order: 'asc'
                    }} />
                <DeleteModalComponent parent={this} />
            </div>
        )
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-maps"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {
                            this.state.website.languages.map((language, index) => {
                                return (
                                    <ListGroup.Item action href={"#" + language.language} key={index}
                                        onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                        {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                                    </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                    <Tab.Content>
                        {
                            this.state.website.languages.map((language, index) => {
                                if (index.toString() !== this.state.language.toString()) return null; // Only render tab if language is active

                                return <Tab.Pane eventKey={"#" + language.language} key={index}>
                                    {this.table(index)}
                                    <MapLeafletMap locations={this.state.rows} settings={this.state.settings} language={index} onMapUpdate={this.updateData} />
                                </Tab.Pane>

                            })
                        }
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

    _editableIndexes = null;
    handleInlineEditAfterSave = (oldValue, newValue, row, column) => {
        this.handleInlineStartEdit();

        let content = row.map.contents[this.state.language];
        if (content) {
            oldValue = content.title;
        }

        if (oldValue === newValue) return;

        content.title = newValue;

        MapRepository.update(row.map.id, content.language, { ...row.map, ...content })
            .then(() => {
                FormToastComponent.successTrans("Maps", "Default.saved");

                if (this.props.parent.props.parent) {
                    this.props.parent.props.parent.reload();
                }
            })
            .catch(() => FormToastComponent.errorTrans("Default.error"));
    }

    handleInlineStartEdit = () => {
        TableHelper.focusEditCell();

        setTimeout(() => {
            let mainRows = document.querySelectorAll('.main-row');
            mainRows.forEach(tr => {
                if (tr.childNodes[1]) tr.childNodes[1].setAttribute('colspan', 3);
            })

            let subRows = document.querySelectorAll('.sub-row');
            subRows.forEach(tr => {
                if (tr.childNodes[1]) tr.childNodes[1].setAttribute('colspan', 2);
            })
        });
    }

    initEscListener = (e) => {
        if (e.keyCode !== 27) return;
        this.handleInlineStartEdit();
    }
}

export default MapOverviewTableComponent;