class TableHelper {
    static focusEditCell = () => {
        let td = document.getElementsByClassName('react-bootstrap-table-editing-cell');

        if (!td || !td[0]) return;

        let inputEl = td[0].childNodes;
        if (!inputEl || !inputEl[0]) return;

        inputEl[0].focus();
    }
}

export default TableHelper;