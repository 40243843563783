export default class TeamContent {

    static fromJson = (json) => {
        let content = new TeamContent();

        content.id = json.id;
        content.language = json.language;
        content.first_name = json.first_name;
        content.last_name = json.last_name;
        content.function = json.function;
        content.phone = json.phone;
        content.mobile = json.mobile;
        content.email = json.email;
        content.additional_field_1_value = json.additional_field_1_value;
        content.additional_field_2_value = json.additional_field_2_value;
        content.additional_field_3_value = json.additional_field_3_value;
        content.content = json.content != null ? json.content : '{}';

        return content;
    }
}