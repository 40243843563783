class WebsiteTranslationTableRow {

    id;
    type;
    name;
    translation;
    language;

    constructor(id, type, name, translation, language) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.translation = translation;
        this.language = language;
    }
}

export default WebsiteTranslationTableRow;