import { MediaRepository } from "../../repository";

export default class BackgroundVideo {
    id;
    name;
    file_name;
    size;
    url;
    mime_type;
    video = true;
    embed;

    constructor(id, name, file_name, size, url, mime_type) {
        this.id = id;
        this.name = name;
        this.file_name = file_name;
        this.size = size;
        this.url = url;
        this.mime_type = mime_type;
    }

    static async create(object) {
        let backgroundVideo = new BackgroundVideo(object['id'], object['name'], object['file_name'], object['size'], object['url'], object['mime_type']);
        if (backgroundVideo.mime_type === "external") {
            let response = await MediaRepository.getEmbed(backgroundVideo.url);
            backgroundVideo.url = response.data.thumbnail_url;
            backgroundVideo.embed = response.data;
        }
        return backgroundVideo;
    }
}