import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

const ButtonIcon = (props) => (    
    <Button variant={props.variant} onClick={props.onClick} disabled={props.disabled} size={props.size} className={props.className}>
        <FontAwesomeIcon icon={['fa', props.icon]} className="mr-1" />
        {props.children}
    </Button>
)

ButtonIcon.defaultProps = {
    variant: 'primary',
    size: 'lg',
    disabled: false
}
export default ButtonIcon;
