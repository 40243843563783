import React from 'react';
import Image from "react-bootstrap/Image";
import logoDark from '../../images/logos/logo-dark.png'
import logoLight from '../../images/logos/logo-light.svg'
import PropTypes from "prop-types";

/**
 * In this component are the different logo's created.
 */
class LogoComponent extends React.Component {


    logo() {
        if (this.props.color === "light") return logoLight;
        return logoDark;
    }

    render() {
        return (
            <div className="logo">
                <Image src={this.logo()} fluid={true}/>
            </div>
        )
    }
}

LogoComponent.propTypes = {
    color: PropTypes.string
};
export default LogoComponent;