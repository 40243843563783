import React from "react";
import { FormattedMessage } from "react-intl";

class FormMaximumConditionalError extends React.Component {
    render() {
        return (
            <FormattedMessage id={this.props.field1}>
                {field1 =>
                    <FormattedMessage id={this.props.field2}>
                        {field2 =>
                            <FormattedMessage id="CMS.Form.field.max.conditional" values={{ field1: field1, field2: field2 }}>
                                {value => <p>{value}</p>}
                            </FormattedMessage>
                        }
                    </FormattedMessage>
                }
            </FormattedMessage>
        );
    }
}

export default FormMaximumConditionalError