import * as Yup from "yup"
import TeamContent from "./TeamContent";
import { JsonHelper } from "../../helpers";

export default class Team {
    id;
    website;
    category;
    active;
    module_references;
    image;
    contents = [];

    constructor() {
        this.module_references = [];
        this.content = new TeamContent();
    }

    static schema = (settings) => {
        let fields = {
            first_name: Yup.string().required('CMS.Team.Content.Form.first_name').max(100, 'CMS.Team.Content.Form.first_name|100|max'),
            last_name: Yup.string().required('CMS.Team.Content.Form.last_name').max(100, 'CMS.Team.Content.Form.last_name|100|max')
        };

        if (settings.use_categories) {
            fields.category = Yup.mixed().required('CMS.Team.Content.Form.category|select');
        }
        if (settings.use_reference) {
            fields.module_references = Yup.mixed().required('CMS.Team.Form.module|select');
        }
        if (settings.show_function) {
            fields.function = Yup.string().required('CMS.Team.Content.Form.function').max(255, 'CMS.Team.Content.Form.function|255|max');
        }
        if (settings.show_phone) {
            fields.phone = Yup.string().required('CMS.Team.Content.Form.phone').phone('BE', 'CMS.Team.Content.Form.phone|phone');
        }
        if (settings.show_mobile) {
            fields.mobile = Yup.string().required('CMS.Team.Content.Form.mobile').phone('BE', 'CMS.Team.Content.Form.mobile|phone');
        }
        if (settings.show_email) {
            fields.email = Yup.string().required('CMS.Team.Content.Form.email').email('CMS.Team.Content.Form.email|email');
        }

        for (let i = 1; i <= 3; i++) {
            if (settings['show_additional_field_' + i] && settings['additional_field_' + i + '_required']) {
                if (settings['additional_field_' + i + '_type'] === 'editor') {
                    fields['additional_field_' + i + '_value'] = Yup.object({ text: Yup.string().required('CMS.Team.Content.Form.additional_field_' + i + '_value') })
                } else {
                    fields['additional_field_' + i + '_value'] = Yup.string().required('CMS.Team.Content.Form.additional_field_' + i + '_value');
                }
            }
        }

        if (settings.show_content) {
            fields.content = Yup.object({ text: Yup.string().required('CMS.Team.Content.Form.content') })
        }

        return Yup.object(fields);
    }

    static fromJson = (json) => {
        let item = new Team();

        item.website = json.website;
        item.category = json.category;
        item.id = json.id;
        item.active = json.active;
        item.module_references = json.module_references ? JsonHelper.tryParse(json.module_references) : [];
        item.image = json.image;
        item.contents = json.contents.map(content => TeamContent.fromJson(content));

        return item;
    }
}
