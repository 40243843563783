import { MediaRepository } from "../../repository";

class MediaTableRow {
    id;
    name;
    file_name;
    size;
    path;
    media_url;
    type;
    upload_date;
    external;
    embed = null;

    constructor(id, key, name, file_name, size, path, media_url, type, upload_date, external = false) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.file_name = file_name;
        this.size = size;
        this.path = path;
        this.media_url = media_url;
        this.type = type;
        this.upload_date = upload_date;
        this.external = external;

        if (external) {
            if (type === "youtube" || type === "vimeo" || type === "soundcloud" || type === "slideshare") {
                MediaRepository.getEmbed(this.media_url)
                    .then(response => {
                        if (response.status === 200) {
                            this.embed = response.data
                        }
                    })
            }
        }
    }
}


export default MediaTableRow;
