import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class EmptyTablePlaceholderComponent extends React.Component {
    render() {
        if (this.props.typeId === 'CMS.Pages.videoalbum.title') {
            return (
                <div className={'custom-data-table ' + this.props.className}>
                    <FormattedMessage id="CMS.Media.Album.video_album.overview.empty">
                        {value => <p className="not-found">{value}</p>}
                    </FormattedMessage>
                </div>
            );
        }

        return (
            <div className={'custom-data-table ' + this.props.className}>
                <FormattedMessage id={this.props.typeId}>
                    {(type) =>
                        <FormattedMessage id="CMS.Table.no_record_found" values={{ type: type.toLowerCase() }}>
                            {(value) => <p className={"not-found"}>{value}</p>}
                        </FormattedMessage>}
                </FormattedMessage>
            </div>
        );
    }
}
EmptyTablePlaceholderComponent.propTypes = {
    typeId: PropTypes.string.isRequired
};
export default EmptyTablePlaceholderComponent;