import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, Row, Col, Alert, AccordionToggle, Accordion, AccordionCollapse } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { ReactSortable } from "react-sortablejs";
import { Option } from "../../../models/form";
import FormattedMessageString from "../../basics/FormattedMessageString";
import FormFormError from "../errors/FormFormError";
import FieldOptionComponent from "./FieldOptionComponent";

class FieldComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            key: 'field_' + (props.field.id ? props.field.id : 'n_' + Math.floor(Math.random() * 100)),
            advancedOptionsOpen: false
        };
    }

    render() {
        let { field, intl, errors } = this.props;

        return (
            <div className="fbgroup">
                <div className="fbgroup-header">
                    <div className="fbgroup-fieldhandle">
                        <FontAwesomeIcon icon={['fas', 'fa-up-down-left-right']} />
                    </div>
                    <div className="fbgroup-head">
                        <span className="fbgroup-title">
                            <FormattedMessageString id={'CMS.Form.Form.fields.type.' + field.type} />
                        </span>
                    </div>
                    <div className="fbgroup-actions">
                        <Button variant="invalid" className="iconbtn" onClick={this.remove}>
                            <FontAwesomeIcon icon={['far', 'trash-alt']} />
                        </Button>
                    </div>
                </div>
                <div className="fbgroup-body">
                    <Row>
                        {this.hasLabel(field) && (
                            <Col md={this.hasRequired(field) ? 6 : 12}>
                                {/** LABEL start */}
                                <Form.Group>
                                    <FormattedMessage id="CMS.Form.Form.fields.label">
                                        {(value) => <Form.Label>{value}</Form.Label>}
                                    </FormattedMessage>

                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        name="label"
                                        onChange={this.onChange}
                                        value={field.label}
                                        placeholder={intl.formatMessage({ id: 'CMS.Form.Form.fields.label.placeholder' })}
                                        isInvalid={errors?.label ? true : false}
                                    />
                                    {(errors?.label) ? <Form.Control.Feedback type="invalid"><FormFormError error={errors.label} /></Form.Control.Feedback> : ''}
                                </Form.Group>
                                {/** LABEL end */}
                            </Col>
                        )}
                        {this.hasRequired(field) && (
                            <Col md={6}>
                                {/** REQUIRED start */}
                                <Form.Group>
                                    <Form.Label className={'mb-3'}>&nbsp;</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id={'custom-switch-required-' + field.order + '-' + (field.id ? field.id : Math.floor(Math.random() * 100))}
                                        label={intl.formatMessage({ id: 'CMS.Form.Form.fields.required' })}
                                        name="required"
                                        onChange={this.onChange}
                                        checked={!!field.required}
                                    />
                                </Form.Group>
                                {/** REQUIRED end */}

                            </Col>
                        )}
                    </Row>

                    {/** OPTIONS start */}
                    {this.props.settings?.hasOptions && this.renderOptions()}
                    {/** OPTIONS end */}

                    <Row>
                        <Col lg={12}>
                            <Accordion defaultActiveKey={this.state.advancedOptionsOpen ? this.state.key : null}>
                                <AccordionToggle className="fbgroup-accordiontoggle" as={Button} variant="link" eventKey={this.state.key} onClick={this.handleAccordionToggle}>
                                    <FormattedMessage id="CMS.Form.Form.fields.advances.button_text">
                                        {value =>
                                            <strong>
                                                {value} <FontAwesomeIcon icon={['fas', (this.state.advancedOptionsOpen) ? 'angle-up' : 'angle-down']} />
                                            </strong>
                                        }
                                    </FormattedMessage>
                                </AccordionToggle>

                                <AccordionCollapse eventKey={this.state.key} className="fbgroup-body">
                                    <Row>
                                        {/** PLACEHOLDER start */}
                                        {this.hasPlaceholder(field) && (
                                            <Form.Group as={Col} lg={6}>
                                                <FormattedMessage id="CMS.Form.Form.fields.placeholder">
                                                    {(value) => <Form.Label>{value}</Form.Label>}
                                                </FormattedMessage>
                                                <FormattedMessage id="CMS.Form.Form.fields.placeholder.placeholder">
                                                    {(placeholder) => (
                                                        <Form.Control
                                                            type="text"
                                                            name="placeholder"
                                                            onChange={this.onChange}
                                                            value={field.placeholder}
                                                            placeholder={placeholder}
                                                        />
                                                    )}
                                                </FormattedMessage>
                                            </Form.Group>
                                        )}
                                        {/** PLACEHOLDER end */}

                                        {/** CSS CLASS start */}
                                        <Form.Group as={Col} lg={6}>
                                            <FormattedMessage id="CMS.Form.Form.fields.cssClass">
                                                {(value) => <Form.Label>{value}</Form.Label>}
                                            </FormattedMessage>
                                            <FormattedMessage id="CMS.Form.Form.fields.cssClass.placeholder">
                                                {(placeholder) => (
                                                    <Form.Control type="text" name="css_class"
                                                        onChange={this.onChange}
                                                        value={field.css_class}
                                                        placeholder={placeholder}
                                                    />
                                                )}
                                            </FormattedMessage>
                                        </Form.Group>
                                        {/** CSS CLASS end */}
                                    </Row>
                                </AccordionCollapse>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }

    renderOptions = () => {
        let { field, index, errors } = this.props;

        let optionsError = '';

        if (errors?.options && typeof errors.options === 'string') {
            optionsError = (
                <Alert variant="danger" size="sm">
                    <FormFormError error={errors.options} />
                </Alert>
            );
        }

        return (
            <React.Fragment>
                {optionsError}
                <ReactSortable group={'FieldOptionComponents_' + index} handle=".fbgroup-optionhandle" list={field.options} setList={(options) => this.handleSortOptions(field, options)} onEnd={this.handleEndSortOptions}>
                    {field.options.map((option, index) => <FieldOptionComponent
                        key={index}
                        index={index}
                        option={option}
                        updateOption={this.updateOption}
                        removeOption={this.removeOption}
                        errors={(errors?.options && typeof errors.options === 'object' && index in errors.options) ? errors.options[index] : {}}
                    />)}
                </ReactSortable>

                <Button size="sm" variant="link" className="px-0 btn-link-bold" onClick={this.addOption}>
                    <FontAwesomeIcon icon={['fal', 'fa-plus-circle']} className="mr-1" />
                    <FormattedMessageString id="CMS.Form.Form.fields.options.add" />
                </Button>
            </React.Fragment>
        );
    }

    handleEndSortOptions = () => {
        let { field, errors } = this.props;

        if (errors?.options) {
            errors.options = {};
        }

        this.update(field);
    }

    handleSortOptions = (field, options) => {

        options.forEach((option, index) => {
            option.order = index + 1;
        });

        field.options = options;
    }

    addOption = () => {
        let { field } = this.props;

        let option = new Option();
        option.order = field.options.length + 1;
        option.value = '';
        option.label = '';

        field.options.push(option);

        this.update(field);
    }

    updateOption = (index, option) => {
        let { field } = this.props;

        field.options[index] = option;
        this.update(field);
    }

    removeOption = (index) => {
        let { field, errors } = this.props;

        field.options.splice(index, 1);

        if (errors?.options && index in errors.options) {
            errors.options.splice(index, 1);
        }

        field.options.forEach((option, index) => {
            option.order = index + 1;
        });

        this.update(field);
    }

    handleSortFieldOptions = (field, options) => {

    }

    handleEndSortFieldOptions = () => {

    }

    onChange = (e) => {
        let { field } = this.props;
        let { name, value, type, checked } = e.target;

        switch (type) {
            case 'checkbox':
                field[name] = checked;
                break;
            default:
                field[name] = value;
                break;
        }

        this.update(field);
    }

    remove = () => this.props.removeField(this.props.index);

    update = (field) => this.props.updateField(this.props.index, field);

    handleAccordionToggle = () => this.setState({ advancedOptionsOpen: !this.state.advancedOptionsOpen });

    hasLabel = (field) => !['html_linebreak'].includes(field.type);
    hasRequired = (field) => !['html_linebreak', 'html_paragraph'].includes(field.type);
    hasPlaceholder = (field) => !['html_linebreak', 'html_paragraph'].includes(field.type);
}

export default injectIntl(FieldComponent);