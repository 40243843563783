import * as Yup from 'yup';
import UserRolesEnum from '../../enums/UserRolesEnum';
import CheckPermissions from '../../helpers/CheckPermissions';
import CategoryContent from "./CategoryContent";
import { Application } from '../../helpers';

export default class Category {
    id;
    type;
    parent;
    settings = {};
    website;
    color = '#000000';
    content = [];

    static validation(settings, parent = null) {
        let fieldPrefix = parent ? 'subcategories_' : '';

        let fields = {
            title: Yup.string().required('CMS.Category.Content.Form.title').max(50, 'CMS.Category.Content.Form.title|50|max')
        };
        if (settings[fieldPrefix + 'show_label']) {
            fields.label = Yup.string().required('CMS.Category.Content.Form.label').max(255, 'CMS.Category.Content.Form.label|255|max');
        }
        if (settings[fieldPrefix + 'show_colors']) {
            fields.color = Yup.string().required('CMS.Category.Content.Form.color');
        }
        if (settings[fieldPrefix + 'show_email']) {
            fields.email = Yup.string().required('CMS.Category.Content.Form.email').email('CMS.Category.Content.Form.email.invalid');
        }
        if (settings[fieldPrefix + 'show_summary']) {
            fields.summary = Yup.string().required('CMS.Category.Content.Form.summary').max(255, 'CMS.Category.Content.Form.summary|255|max');
        }
        if (settings[fieldPrefix + 'show_highlights']) {
            fields.highlights = Yup.array().min(1, 'CMS.Category.Content.Form.highlights');
        }
        if (settings[fieldPrefix + 'show_content']) {
            fields.value = Yup.object({ text: Yup.string().required('CMS.Category.Content.Form.content') })
        }

        return Yup.object(fields);
    }

    static fromJson(json) {
        let category = new Category();
        category.id = json.id;
        category.type = json.type;
        category.parent = json.parent;
        category.website = json.website;
        category.settings = json.settings === "[]" ? "{}" : json.settings;
        category.color = json.color ? json.color : '#000000';
        category.content = json.contents.map(content => {
            let c = CategoryContent.fromJson(content);
            c.color = category.color;
            return c;
        });
        return category;
    }

    static canChange(roles, settings, isSubcategory = false) {
        if (isSubcategory && this.canChange(roles, settings)) {
            return true;
        }

        if (CheckPermissions.role(roles, UserRolesEnum.RESELLER)) {
            return true;
        }

        if (isSubcategory) {
            return settings.subcategories_manager_can_change !== undefined && settings.subcategories_manager_can_change;
        } else {
            return settings.manager_can_change !== undefined && settings.manager_can_change;
        }
    }

    static maximumNumberOfLevels = (settings) => {
        if (settings.subcategories_number_of_levels) {
            return parseInt(settings.subcategories_number_of_levels);
        }

        return parseInt(Application.setting('module_subcategories_default_number_of_levels'));
    }

    static renderTree = (data) => {
        let categories = {};
        data.forEach(item => categories[item.id] = item);

        let tree = {};
        Object.keys(categories).forEach(categoryId => {
            let category = categories[categoryId];

            if (category.category.parent) return;

            if (!(category.category.module in tree)) {
                tree[category.category.module] = {};
            }

            tree[category.category.module][categoryId] = {
                category: category.category,
                children: {}
            }

            this.renderChildren(tree[category.category.module][categoryId], category, categories)
        });

        return tree;
    }

    static renderChildren = (tree, category, categories) => {
        if (!category.children || category.children.length <= 0) return;

        category.children.forEach(childId => {
            if (!(childId in categories)) return;

            let child = categories[childId];
            if (child.category.parent !== tree.category.id) return;

            tree.children[childId] = {
                category: child.category,
                children: {}
            };

            this.renderChildren(tree.children[child.id], child, categories);
        });
    }
}