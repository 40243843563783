import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModuleRepository from "../../repository/ModuleRepository";
import BlogFormError from './errors/BlogFormError';
import { CheckPermissions } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { connect } from 'react-redux';

class BlogCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            subscribe_on: props.values.settings.subscribe_on,
            show_summary: props.values.settings.show_summary,
            content_with_blocks: props.values.settings.content_with_blocks,
            has_special_blocks: props.values.settings.has_special_blocks,
            show_cover_image: props.values.settings.show_cover_image,
            cover_image_width: props.values.settings.cover_image_width,
            cover_image_height: props.values.settings.cover_image_height,
            need_home_img: props.values.settings.need_home_img,
            home_img_width: props.values.settings.home_img_width,
            home_img_height: props.values.settings.home_img_height,
            show_photoalbum: props.values.settings.show_photoalbum,
            show_videoalbum: props.values.settings.show_videoalbum,
            hide_media: props.values.settings.hide_media,
            media_password: props.values.settings.media_password,
            modules: [],
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => {
                this.setState({
                    modules: result.filter(module => module.module.type === 'category'),
                    use_categories: this.props.values.settings.use_categories
                })
            });
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })

        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Blog.Form.settings"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) && (
                        <>
                            {/* Show subscribe_on */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-on-subscribe"
                                            label="subscribe_on"
                                            name={"settings.subscribe_on"}
                                            onChange={event => this.onChange(event, () => this.setState({ subscribe_on: !this.state.subscribe_on }))}
                                            className="mb-1"
                                            checked={this.state.subscribe_on}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.subscribe_on"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* Show summary */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-show-summary"
                                            label="show_summary"
                                            name={"settings.show_summary"}
                                            onChange={event => this.onChange(event, () => this.setState({ show_summary: !this.state.show_summary }))}
                                            className="mb-1"
                                            checked={this.state.show_summary}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.show_summary"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* Show content_with_blocks */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-content-with-blocks"
                                            label="content_with_blocks"
                                            name={"settings.content_with_blocks"}
                                            onChange={event => this.onChange(event, () =>
                                                this.setState({
                                                    content_with_blocks: !this.state.content_with_blocks,
                                                }, () => {
                                                    delete this.props.values.settings.content_with_blocks_image_width;
                                                    delete this.props.values.settings.content_with_blocks_image_height;
                                                })
                                            )}
                                            className="mb-1"
                                            checked={this.state.content_with_blocks}
                                        />
                                    </Col>

                                    {
                                        this.state.content_with_blocks ?
                                            <>
                                             <Form.Group className="subsettings">
                                             <Row>
                                                <Col md={6}>
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                id="inputGroupPrependEyecatcherWidth">content_with_blocks_image_width</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            aria-describedby="content_with_blocks_image_width"
                                                            type={"text"}
                                                            name={"settings.content_with_blocks_image_width"}
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_width}
                                                            value={this.props.values.settings.content_with_blocks_image_width}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_width && (
                                                                <BlogFormError error={this.props.errors.settings.content_with_blocks_image_width} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                id="inputGroupPrependEyecatcherHeight">content_with_blocks_image_height</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            aria-describedby="content_with_blocks_image_height"
                                                            type={"text"}
                                                            name={"settings.content_with_blocks_image_height"}
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_height}
                                                            value={this.props.values.settings.content_with_blocks_image_height}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_height && (
                                                                <BlogFormError error={this.props.errors.settings.content_with_blocks_image_height} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>

                                                <Col md={12}>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch-has-special-blocks"
                                                        label="has_special_blocks"
                                                        name={"settings.has_special_blocks"}
                                                        onChange={event => this.onChange(event, () => this.setState({ has_special_blocks: !this.state.has_special_blocks }))}
                                                        className="mb-1"
                                                        checked={this.state.has_special_blocks}
                                                    />
                                                </Col>
                                                </Row>
                                                </Form.Group>
                                            </> : null
                                    }

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.content_with_blocks"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* Show image */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-show-cover-image"
                                            label="show_cover_image"
                                            name={"settings.show_cover_image"}
                                            onChange={event => this.onChange(event, () => {
                                                this.setState({
                                                    show_cover_image: !this.state.show_cover_image,
                                                    need_home_img: false
                                                }, () => {
                                                    delete this.props.values.settings.cover_image_width;
                                                    delete this.props.values.settings.cover_image_height;
                                                    this.props.values.settings.need_home_img = false;
                                                    delete this.props.values.settings.home_img_width;
                                                    delete this.props.values.settings.home_img_height;
                                                })
                                            })}
                                            className="mb-1"
                                            checked={this.state.show_cover_image ? "checked" : ""}
                                        />
                                    </Col>

                                    {
                                        this.state.show_cover_image ?
                                            <>
                                            <Form.Group className="subsettings">
                                            <Row>
                                                <Col md={6}>
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                id="inputGroupPrependEyecatcherWidth">cover_image_width</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            aria-describedby="cover_image_width"
                                                            type={"text"}
                                                            name={"settings.cover_image_width"}
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_width}
                                                            value={this.props.values.settings.cover_image_width}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.cover_image_width && (
                                                                <BlogFormError error={this.props.errors.settings.cover_image_width} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <InputGroup className="mb-1">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text
                                                                id="inputGroupPrependEyecatcherHeight">cover_image_height</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control
                                                            aria-describedby="cover_image_height"
                                                            type={"text"}
                                                            name={"settings.cover_image_height"}
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_height}
                                                            value={this.props.values.settings.cover_image_height}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.props.errors.settings != null && this.props.errors.settings.cover_image_height && (
                                                                <BlogFormError error={this.props.errors.settings.cover_image_height} />
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>

                                                        <Col xs={12}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch-needs-home-image"
                                                                label="need_home_img"
                                                                name={"settings.need_home_img"}
                                                                onChange={event => this.onChange(event, () => this.setState({ need_home_img: !this.state.need_home_img }, () => {
                                                                    delete this.props.values.settings.home_img_width;
                                                                    delete this.props.values.settings.home_img_height;
                                                                }))}
                                                                className="mb-1"
                                                                checked={this.state.need_home_img ? "checked" : ""}
                                                            />
                                                            </Col>
                                                            {this.state.need_home_img && (
                                                               <Form.Group className="subsettings sub">
                                          
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <InputGroup className="mb-1">
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text
                                                                                    id="inputGroupPrependEyecatcherWidth">home_img_width</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control
                                                                                aria-describedby="home_img_width"
                                                                                type={"text"}
                                                                                name={"settings.home_img_width"}
                                                                                onChange={this.onChange}
                                                                                isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_width}
                                                                                value={this.props.values.settings.home_img_width}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {this.props.errors.settings != null && this.props.errors.settings.home_img_width && (
                                                                                    <BlogFormError error={this.props.errors.settings.home_img_width} />
                                                                                )}
                                                                            </Form.Control.Feedback>
                                                                        </InputGroup>
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <InputGroup className="mb-1">
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text
                                                                                    id="inputGroupPrependEyecatcherHeight">home_img_height</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control
                                                                                aria-describedby="home_img_height"
                                                                                type={"text"}
                                                                                name={"settings.home_img_height"}
                                                                                onChange={this.onChange}
                                                                                isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_height}
                                                                                value={this.props.values.settings.home_img_height}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {this.props.errors.settings != null && this.props.errors.settings.home_img_height && (
                                                                                    <BlogFormError error={this.props.errors.settings.home_img_height} />
                                                                                )}
                                                                            </Form.Control.Feedback>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                                </Form.Group>
                                                            )}
                                                        
                                                        </Row>
                                                        </Form.Group>
                                               
                                            </>
                                            : null
                                    }

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.show_cover_image"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* Show photo_album */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-show-photoalbum"
                                            label="show_photoalbum"
                                            name={"settings.show_photoalbum"}
                                            onChange={event => this.onChange(event, () =>
                                                this.setState({ show_photoalbum: !this.state.show_photoalbum })
                                            )}
                                            className="mb-1"
                                            checked={this.state.show_photoalbum}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.show_photoalbum"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>

                            {/* Show video_album */}
                            <Form.Group as={Col} xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch-show-videoalbum"
                                            label="show_videoalbum"
                                            name={"settings.show_videoalbum"}
                                            onChange={event => this.onChange(event, () => this.setState({ show_videoalbum: !this.state.show_videoalbum }))}
                                            className="mb-1"
                                            checked={this.state.show_videoalbum}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <FormattedMessage id={"CMS.Modules.Form.settings.blog.show_videoalbum"}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </>
                    )}

                    {/* Show hide_media */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-hide-media"
                                    label="hide_media"
                                    disabled={!CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER)}
                                    name={"settings.hide_media"}
                                    onChange={event => this.onChange(event, () => this.setState({ hide_media: !this.state.hide_media }, () => {
                                        delete this.props.values.settings.media_password;
                                    }))}
                                    className="mb-1"
                                    checked={this.state.hide_media}
                                />
                            </Col>

                           
                       
                        {this.state.hide_media && (
                                 <Form.Group className="subsettings">
                            <Row>
                                <Col md={12}>
                                    <InputGroup className="xxl mb-1 nowrap">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                id="inputGroupPrependEyecatcherWidth">media_password</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <div class="password-toggle-wrap">
                                        <Form.Control
                                            aria-describedby="media_password"
                                            type={this.state.showPasswordPlain ? 'text' : 'password'}
                                            name={"settings.media_password"}
                                            onChange={this.onChange}
                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.media_password}
                                            value={this.props.values.settings.media_password}
                                        />
                                        <div className={"password-toggle"} onClick={() => this.setState({ showPasswordPlain: !this.state.showPasswordPlain })}>
                                        <FontAwesomeIcon 
                                            icon={['far', this.state.showPasswordPlain ? 'eye-slash' : 'eye']}
                                            
                                        />
                                        </div>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            {this.props.errors.settings != null && this.props.errors.settings.media_password && (
                                                <BlogFormError error={this.props.errors.settings.media_password} />
                                            )}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            </Form.Group>
                        )}
                
                       
                            <Col xs={12}>
                                <FormattedMessage id={"CMS.Modules.Form.settings.blog.hide_media"}>
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title={"CMS.Blog.Form.setting.delete.title"} text={"CMS.Blog.Form.setting.delete.text"}
                    buttons={{
                        confirm: {
                            text: 'Default.confirm',
                            icon: 'check'
                        }
                    }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={(event) => this.setState({
                        showDeleteModal: false,
                        deleteRow: null
                    })}
                />
            </Form.Group >
        );
    }
}
const mapStateToProps = state => ({ user: state.user });
export default connect(mapStateToProps)(BlogCustomSettingsFormComponent);