// https://github.com/umpirsky/country-list
const CountryList = {
    nl_NL: {
        "AF": "Afghanistan",
        "AX": "\u00c5land",
        "AL": "Albani\u00eb",
        "DZ": "Algerije",
        "AS": "Amerikaans-Samoa",
        "VI": "Amerikaanse Maagdeneilanden",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua en Barbuda",
        "AR": "Argentini\u00eb",
        "AM": "Armeni\u00eb",
        "AW": "Aruba",
        "AC": "Ascension",
        "AU": "Australi\u00eb",
        "AZ": "Azerbeidzjan",
        "BS": "Bahama\u2019s",
        "BH": "Bahrein",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgi\u00eb",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia",
        "BA": "Bosni\u00eb en Herzegovina",
        "BW": "Botswana",
        "BR": "Brazili\u00eb",
        "IO": "Brits Indische Oceaanterritorium",
        "VG": "Britse Maagdeneilanden",
        "BN": "Brunei",
        "BG": "Bulgarije",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Cambodja",
        "CA": "Canada",
        "IC": "Canarische Eilanden",
        "BQ": "Caribisch Nederland",
        "CF": "Centraal-Afrikaanse Republiek",
        "EA": "Ceuta en Melilla",
        "CL": "Chili",
        "CN": "China",
        "CX": "Christmaseiland",
        "CC": "Cocoseilanden",
        "CO": "Colombia",
        "KM": "Comoren",
        "CG": "Congo-Brazzaville",
        "CD": "Congo-Kinshasa",
        "CK": "Cookeilanden",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CW": "Cura\u00e7ao",
        "CY": "Cyprus",
        "DK": "Denemarken",
        "DG": "Diego Garcia",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominicaanse Republiek",
        "DE": "Duitsland",
        "EC": "Ecuador",
        "EG": "Egypte",
        "SV": "El Salvador",
        "GQ": "Equatoriaal-Guinea",
        "ER": "Eritrea",
        "EE": "Estland",
        "SZ":"ESwatini",
        "ET": "Ethiopi\u00eb",
        "FO": "Faer\u00f6er",
        "FK": "Falklandeilanden",
        "FJ": "Fiji",
        "PH": "Filipijnen",
        "FI": "Finland",
        "FR": "Frankrijk",
        "GF": "Frans-Guyana",
        "PF": "Frans-Polynesi\u00eb",
        "TF": "Franse Gebieden in de zuidelijke Indische Oceaan",
        "GA": "Gabon",
        "GM": "Gambia",
        "GE": "Georgi\u00eb",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GD": "Grenada",
        "GR": "Griekenland",
        "GL": "Groenland",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinee",
        "GW": "Guinee-Bissau",
        "GY": "Guyana",
        "HT": "Ha\u00efti",
        "HN": "Honduras",
        "HU": "Hongarije",
        "HK": "Hongkong SAR van China",
        "IE": "Ierland",
        "IS": "IJsland",
        "IN": "India",
        "ID": "Indonesi\u00eb",
        "IQ": "Irak",
        "IR": "Iran",
        "IM": "Isle of Man",
        "IL": "Isra\u00ebl",
        "IT": "Itali\u00eb",
        "CI": "Ivoorkust",
        "JM": "Jamaica",
        "JP": "Japan",
        "YE": "Jemen",
        "JE": "Jersey",
        "JO": "Jordani\u00eb",
        "KY": "Kaaimaneilanden",
        "CV": "Kaapverdi\u00eb",
        "CM": "Kameroen",
        "KZ": "Kazachstan",
        "KE": "Kenia",
        "KG": "Kirgizi\u00eb",
        "KI": "Kiribati",
        "UM": "Kleine afgelegen eilanden van de Verenigde Staten",
        "KW": "Koeweit",
        "XK": "Kosovo",
        "HR": "Kroati\u00eb",
        "LA": "Laos",
        "LS": "Lesotho",
        "LV": "Letland",
        "LB": "Libanon",
        "LR": "Liberia",
        "LY": "Libi\u00eb",
        "LI": "Liechtenstein",
        "LT": "Litouwen",
        "LU": "Luxemburg",
        "MO": "Macau SAR van China",
        "MG": "Madagaskar",
        "MW": "Malawi",
        "MV": "Maldiven",
        "MY": "Maleisi\u00eb",
        "ML": "Mali",
        "MT": "Malta",
        "MA": "Marokko",
        "MH": "Marshalleilanden",
        "MQ": "Martinique",
        "MR": "Mauritani\u00eb",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia",
        "MD": "Moldavi\u00eb",
        "MC": "Monaco",
        "MN": "Mongoli\u00eb",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MZ": "Mozambique",
        "MM": "Myanmar (Birma)",
        "NA": "Namibi\u00eb",
        "NR": "Nauru",
        "NL": "Nederland",
        "NP": "Nepal",
        "NI": "Nicaragua",
        "NC": "Nieuw-Caledoni\u00eb",
        "NZ": "Nieuw-Zeeland",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "KP": "Noord-Korea",
        "MK": "Noord-Macedoni\u00eb",
        "MP": "Noordelijke Marianen",
        "NO": "Noorwegen",
        "NF": "Norfolk",
        "UG": "Oeganda",
        "UA": "Oekra\u00efne",
        "UZ": "Oezbekistan",
        "OM": "Oman",
        "TL": "Oost-Timor",
        "AT": "Oostenrijk",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestijnse gebieden",
        "PA": "Panama",
        "PG": "Papoea-Nieuw-Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PN": "Pitcairneilanden",
        "PL": "Polen",
        "PT": "Portugal",
        "XA": "Pseudo-Accenten",
        "XB": "Pseudo-Bidi",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "RE": "R\u00e9union",
        "RO": "Roemeni\u00eb",
        "RU": "Rusland",
        "RW": "Rwanda",
        "KN": "Saint Kitts en Nevis",
        "LC": "Saint Lucia",
        "VC": "Saint Vincent en de Grenadines",
        "BL": "Saint-Barth\u00e9lemy",
        "MF": "Saint-Martin",
        "PM": "Saint-Pierre en Miquelon",
        "SB": "Salomonseilanden",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "Sao Tom\u00e9 en Principe",
        "SA": "Saoedi-Arabi\u00eb",
        "SN": "Senegal",
        "RS": "Servi\u00eb",
        "SC": "Seychellen",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SH": "Sint-Helena",
        "SX": "Sint-Maarten",
        "SI": "Sloveni\u00eb",
        "SK": "Slowakije",
        "SD": "Soedan",
        "SO": "Somali\u00eb",
        "ES": "Spanje",
        "SJ": "Spitsbergen en Jan Mayen",
        "LK": "Sri Lanka",
        "SR": "Suriname",
        "SY": "Syri\u00eb",
        "TJ": "Tadzjikistan",
        "TW": "Taiwan",
        "TZ": "Tanzania",
        "TH": "Thailand",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad en Tobago",
        "TA": "Tristan da Cunha",
        "TD": "Tsjaad",
        "CZ": "Tsjechi\u00eb",
        "TN": "Tunesi\u00eb",
        "TR": "Turkije",
        "TM": "Turkmenistan",
        "TC": "Turks- en Caicoseilanden",
        "TV": "Tuvalu",
        "UY": "Uruguay",
        "VU": "Vanuatu",
        "VA": "Vaticaanstad",
        "VE": "Venezuela",
        "GB": "Verenigd Koninkrijk",
        "AE": "Verenigde Arabische Emiraten",
        "US": "Verenigde Staten",
        "VN": "Vietnam",
        "WF": "Wallis en Futuna",
        "EH": "Westelijke Sahara",
        "ZM": "Zambia",
        "ZW": "Zimbabwe",
        "ZA": "Zuid-Afrika",
        "GS": "Zuid-Georgia en Zuidelijke Sandwicheilanden",
        "KR": "Zuid-Korea",
        "SS": "Zuid-Soedan",
        "SE": "Zweden",
        "CH": "Zwitserland"
    },
    en_EN: {
        "AF": "Afghanistan",
        "AX": "\u00c5land Islands",
        "AL": "Albania",
        "DZ": "Algeria",
        "AS": "American Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua & Barbuda",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AC": "Ascension Island",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia",
        "BA": "Bosnia & Herzegovina",
        "BW": "Botswana",
        "BR": "Brazil",
        "IO": "British Indian Ocean Territory",
        "VG": "British Virgin Islands",
        "BN": "Brunei",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "IC": "Canary Islands",
        "CV": "Cape Verde",
        "BQ": "Caribbean Netherlands",
        "KY": "Cayman Islands",
        "CF": "Central African Republic",
        "EA": "Ceuta & Melilla",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Christmas Island",
        "CC": "Cocos (Keeling) Islands",
        "CO": "Colombia",
        "KM": "Comoros",
        "CG": "Congo - Brazzaville",
        "CD": "Congo - Kinshasa",
        "CK": "Cook Islands",
        "CR": "Costa Rica",
        "CI": "C\u00f4te d\u2019Ivoire",
        "HR": "Croatia",
        "CU": "Cuba",
        "CW": "Cura\u00e7ao",
        "CY": "Cyprus",
        "CZ": "Czechia",
        "DK": "Denmark",
        "DG": "Diego Garcia",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "GQ": "Equatorial Guinea",
        "ER": "Eritrea",
        "EE": "Estonia",
        "SZ": "Eswatini",
        "ET": "Ethiopia",
        "FK": "Falkland Islands",
        "FO": "Faroe Islands",
        "FJ": "Fiji",
        "FI": "Finland",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories",
        "GA": "Gabon",
        "GM": "Gambia",
        "GE": "Georgia",
        "DE": "Germany",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Greece",
        "GL": "Greenland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HN": "Honduras",
        "HK": "Hong Kong SAR China",
        "HU": "Hungary",
        "IS": "Iceland",
        "IN": "India",
        "ID": "Indonesia",
        "IR": "Iran",
        "IQ": "Iraq",
        "IE": "Ireland",
        "IM": "Isle of Man",
        "IL": "Israel",
        "IT": "Italy",
        "JM": "Jamaica",
        "JP": "Japan",
        "JE": "Jersey",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Kuwait",
        "KG": "Kyrgyzstan",
        "LA": "Laos",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MO": "Macao SAR China",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshall Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia",
        "MD": "Moldova",
        "MC": "Monaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MA": "Morocco",
        "MZ": "Mozambique",
        "MM": "Myanmar (Burma)",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NL": "Netherlands",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "KP": "North Korea",
        "MK": "North Macedonia",
        "MP": "Northern Mariana Islands",
        "NO": "Norway",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestinian Territories",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippines",
        "PN": "Pitcairn Islands",
        "PL": "Poland",
        "PT": "Portugal",
        "XA": "Pseudo-Accents",
        "XB": "Pseudo-Bidi",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "RE": "R\u00e9union",
        "RO": "Romania",
        "RU": "Russia",
        "RW": "Rwanda",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "S\u00e3o Tom\u00e9 & Pr\u00edncipe",
        "SA": "Saudi Arabia",
        "SN": "Senegal",
        "RS": "Serbia",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SX": "Sint Maarten",
        "SK": "Slovakia",
        "SI": "Slovenia",
        "SB": "Solomon Islands",
        "SO": "Somalia",
        "ZA": "South Africa",
        "GS": "South Georgia & South Sandwich Islands",
        "KR": "South Korea",
        "SS": "South Sudan",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "BL": "St. Barth\u00e9lemy",
        "SH": "St. Helena",
        "KN": "St. Kitts & Nevis",
        "LC": "St. Lucia",
        "MF": "St. Martin",
        "PM": "St. Pierre & Miquelon",
        "VC": "St. Vincent & Grenadines",
        "SD": "Sudan",
        "SR": "Suriname",
        "SJ": "Svalbard & Jan Mayen",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SY": "Syria",
        "TW": "Taiwan",
        "TJ": "Tajikistan",
        "TZ": "Tanzania",
        "TH": "Thailand",
        "TL": "Timor-Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad & Tobago",
        "TA": "Tristan da Cunha",
        "TN": "Tunisia",
        "TR": "Turkey",
        "TM": "Turkmenistan",
        "TC": "Turks & Caicos Islands",
        "TV": "Tuvalu",
        "UM": "U.S. Outlying Islands",
        "VI": "U.S. Virgin Islands",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "United Arab Emirates",
        "GB": "United Kingdom",
        "US": "United States",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VA": "Vatican City",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "WF": "Wallis & Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
    },
    fr_FR: {
        "AF": "Afghanistan",
        "ZA": "Afrique du Sud",
        "AL": "Albanie",
        "DZ": "Alg\u00e9rie",
        "DE": "Allemagne",
        "AD": "Andorre",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctique",
        "AG": "Antigua-et-Barbuda",
        "SA": "Arabie saoudite",
        "AR": "Argentine",
        "AM": "Arm\u00e9nie",
        "AW": "Aruba",
        "AU": "Australie",
        "AT": "Autriche",
        "AZ": "Azerba\u00efdjan",
        "BS": "Bahamas",
        "BH": "Bahre\u00efn",
        "BD": "Bangladesh",
        "BB": "Barbade",
        "BE": "Belgique",
        "BZ": "Belize",
        "BJ": "B\u00e9nin",
        "BM": "Bermudes",
        "BT": "Bhoutan",
        "BY": "Bi\u00e9lorussie",
        "BO": "Bolivie",
        "BA": "Bosnie-Herz\u00e9govine",
        "BW": "Botswana",
        "BR": "Br\u00e9sil",
        "BN": "Brun\u00e9i Darussalam",
        "BG": "Bulgarie",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "KH": "Cambodge",
        "CM": "Cameroun",
        "CA": "Canada",
        "CV": "Cap-Vert",
        "EA": "Ceuta et Melilla",
        "CL": "Chili",
        "CN": "Chine",
        "CY": "Chypre",
        "CO": "Colombie",
        "KM": "Comores",
        "CG": "Congo-Brazzaville",
        "CD": "Congo-Kinshasa",
        "KP": "Cor\u00e9e du Nord",
        "KR": "Cor\u00e9e du Sud",
        "CR": "Costa Rica",
        "CI": "C\u00f4te d\u2019Ivoire",
        "HR": "Croatie",
        "CU": "Cuba",
        "CW": "Cura\u00e7ao",
        "DK": "Danemark",
        "DG": "Diego Garcia",
        "DJ": "Djibouti",
        "DM": "Dominique",
        "EG": "\u00c9gypte",
        "AE": "\u00c9mirats arabes unis",
        "EC": "\u00c9quateur",
        "ER": "\u00c9rythr\u00e9e",
        "ES": "Espagne",
        "EE": "Estonie",
        "SZ": "Eswatini",
        "VA": "\u00c9tat de la Cit\u00e9 du Vatican",
        "FM": "\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie",
        "US": "\u00c9tats-Unis",
        "ET": "\u00c9thiopie",
        "FJ": "Fidji",
        "FI": "Finlande",
        "FR": "France",
        "GA": "Gabon",
        "GM": "Gambie",
        "GE": "G\u00e9orgie",
        "GS": "G\u00e9orgie du Sud et \u00eeles Sandwich du Sud",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Gr\u00e8ce",
        "GD": "Grenade",
        "GL": "Groenland",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernesey",
        "GN": "Guin\u00e9e",
        "GQ": "Guin\u00e9e \u00e9quatoriale",
        "GW": "Guin\u00e9e-Bissau",
        "GY": "Guyana",
        "GF": "Guyane fran\u00e7aise",
        "HT": "Ha\u00efti",
        "HN": "Honduras",
        "HU": "Hongrie",
        "CX": "\u00cele Christmas",
        "AC": "\u00cele de l\u2019Ascension",
        "IM": "\u00cele de Man",
        "NF": "\u00cele Norfolk",
        "AX": "\u00celes \u00c5land",
        "KY": "\u00celes Ca\u00efmans",
        "IC": "\u00celes Canaries",
        "CC": "\u00celes Cocos",
        "CK": "\u00celes Cook",
        "FO": "\u00celes F\u00e9ro\u00e9",
        "FK": "\u00celes Malouines",
        "MP": "\u00celes Mariannes du Nord",
        "MH": "\u00celes Marshall",
        "UM": "\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis",
        "PN": "\u00celes Pitcairn",
        "SB": "\u00celes Salomon",
        "TC": "\u00celes Turques-et-Ca\u00efques",
        "VG": "\u00celes Vierges britanniques",
        "VI": "\u00celes Vierges des \u00c9tats-Unis",
        "IN": "Inde",
        "ID": "Indon\u00e9sie",
        "IQ": "Irak",
        "IR": "Iran",
        "IE": "Irlande",
        "IS": "Islande",
        "IL": "Isra\u00ebl",
        "IT": "Italie",
        "JM": "Jama\u00efque",
        "JP": "Japon",
        "JE": "Jersey",
        "JO": "Jordanie",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KG": "Kirghizistan",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Kowe\u00eft",
        "RE": "La R\u00e9union",
        "LA": "Laos",
        "LS": "Lesotho",
        "LV": "Lettonie",
        "LB": "Liban",
        "LR": "Lib\u00e9ria",
        "LY": "Libye",
        "LI": "Liechtenstein",
        "LT": "Lituanie",
        "LU": "Luxembourg",
        "MK": "Mac\u00e9doine",
        "MG": "Madagascar",
        "MY": "Malaisie",
        "MW": "Malawi",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malte",
        "MA": "Maroc",
        "MQ": "Martinique",
        "MU": "Maurice",
        "MR": "Mauritanie",
        "YT": "Mayotte",
        "MX": "Mexique",
        "MD": "Moldavie",
        "MC": "Monaco",
        "MN": "Mongolie",
        "ME": "Mont\u00e9n\u00e9gro",
        "MS": "Montserrat",
        "MZ": "Mozambique",
        "MM": "Myanmar (Birmanie)",
        "NA": "Namibie",
        "NR": "Nauru",
        "NP": "N\u00e9pal",
        "NI": "Nicaragua",
        "NE": "Niger",
        "NG": "Nig\u00e9ria",
        "NU": "Niue",
        "NO": "Norv\u00e8ge",
        "NC": "Nouvelle-Cal\u00e9donie",
        "NZ": "Nouvelle-Z\u00e9lande",
        "OM": "Oman",
        "UG": "Ouganda",
        "UZ": "Ouzb\u00e9kistan",
        "PK": "Pakistan",
        "PW": "Palaos",
        "PA": "Panama",
        "PG": "Papouasie-Nouvelle-Guin\u00e9e",
        "PY": "Paraguay",
        "NL": "Pays-Bas",
        "BQ": "Pays-Bas carib\u00e9ens",
        "PE": "P\u00e9rou",
        "PH": "Philippines",
        "PL": "Pologne",
        "PF": "Polyn\u00e9sie fran\u00e7aise",
        "PR": "Porto Rico",
        "PT": "Portugal",
        "XA":"Pseudo-accents",
        "XB":"Pseudo-bidi",
        "QA": "Qatar",
        "HK": "R.A.S. chinoise de Hong Kong",
        "MO": "R.A.S. chinoise de Macao",
        "CF": "R\u00e9publique centrafricaine",
        "DO": "R\u00e9publique dominicaine",
        "RO": "Roumanie",
        "GB": "Royaume-Uni",
        "RU": "Russie",
        "RW": "Rwanda",
        "EH": "Sahara occidental",
        "BL": "Saint-Barth\u00e9lemy",
        "KN": "Saint-Christophe-et-Ni\u00e9v\u00e8s",
        "SM": "Saint-Marin",
        "MF": "Saint-Martin",
        "SX": "Saint-Martin (partie n\u00e9erlandaise)",
        "PM": "Saint-Pierre-et-Miquelon",
        "VC": "Saint-Vincent-et-les-Grenadines",
        "SH": "Sainte-H\u00e9l\u00e8ne",
        "LC": "Sainte-Lucie",
        "SV": "Salvador",
        "WS": "Samoa",
        "AS": "Samoa am\u00e9ricaines",
        "ST": "Sao Tom\u00e9-et-Principe",
        "SN": "S\u00e9n\u00e9gal",
        "RS": "Serbie",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapour",
        "SK": "Slovaquie",
        "SI": "Slov\u00e9nie",
        "SO": "Somalie",
        "SD": "Soudan",
        "SS": "Soudan du Sud",
        "LK": "Sri Lanka",
        "SE": "Su\u00e8de",
        "CH": "Suisse",
        "SR": "Suriname",
        "SJ": "Svalbard et Jan Mayen",
        "SY": "Syrie",
        "TJ": "Tadjikistan",
        "TW": "Ta\u00efwan",
        "TZ": "Tanzanie",
        "TD": "Tchad",
        "CZ": "Tch\u00e9quie",
        "TF": "Terres australes fran\u00e7aises",
        "IO": "Territoire britannique de l\u2019oc\u00e9an Indien",
        "PS": "Territoires palestiniens",
        "TH": "Tha\u00eflande",
        "TL": "Timor oriental",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinit\u00e9-et-Tobago",
        "TA": "Tristan da Cunha",
        "TN": "Tunisie",
        "TM": "Turkm\u00e9nistan",
        "TR": "Turquie",
        "TV": "Tuvalu",
        "UA": "Ukraine",
        "UY": "Uruguay",
        "VU": "Vanuatu",
        "VE": "Venezuela",
        "VN": "Vietnam",
        "WF": "Wallis-et-Futuna",
        "YE": "Y\u00e9men",
        "ZM": "Zambie",
        "ZW": "Zimbabwe"
    }
};

// https://github.com/umpirsky/language-list
const LanguageList = {
    nl_NL: {
        "ab": "Abchazisch",
        "ada": "Adangme",
        "ady": "Adygees",
        "om": "Afaan Oromo",
        "aa": "Afar",
        "afh": "Afrihili",
        "af": "Afrikaans",
        "agq": "Aghem",
        "ain": "Ainu",
        "ak": "Akan",
        "akk": "Akkadisch",
        "ach": "Akoli",
        "bss": "Akoose",
        "akz": "Alabama",
        "sq": "Albanees",
        "ale": "Aleoetisch",
        "arq": "Algerijns Arabisch",
        "en_US": "Amerikaans Engels",
        "ase": "Amerikaanse Gebarentaal",
        "am": "Amhaars",
        "anp": "Angika",
        "njo": "Ao Naga",
        "ar": "Arabisch",
        "an": "Aragonees",
        "arc": "Aramees",
        "aro": "Araona",
        "arp": "Arapaho",
        "arw": "Arawak",
        "hy": "Armeens",
        "rup": "Aroemeens",
        "frp": "Arpitaans",
        "as": "Assamees",
        "ast": "Asturisch",
        "asa": "Asu",
        "ace": "Atjehs",
        "cch": "Atsam",
        "en_AU": "Australisch Engels",
        "av": "Avarisch",
        "ae": "Avestisch",
        "awa": "Awadhi",
        "ay": "Aymara",
        "az": "Azerbeidzjaans",
        "bfq": "Badaga",
        "ksf": "Bafia",
        "bfd": "Bafut",
        "bqi": "Bakhtiari",
        "ban": "Balinees",
        "bm": "Bambara",
        "bax": "Bamoun",
        "bjn": "Banjar",
        "bas": "Basa",
        "ba": "Basjkiers",
        "eu": "Baskisch",
        "bbc": "Batak Toba",
        "bar": "Beiers",
        "bej": "Beja",
        "bal": "Beloetsji",
        "bem": "Bemba",
        "bez": "Bena",
        "bn": "Bengaals",
        "bew": "Betawi",
        "bho": "Bhojpuri",
        "bik": "Bikol",
        "bin": "Bini",
        "my": "Birmaans",
        "bpy": "Bishnupriya",
        "bi": "Bislama",
        "byn": "Blin",
        "zbl": "Blissymbolen",
        "brx": "Bodo",
        "bua": "Boerjatisch",
        "bs": "Bosnisch",
        "brh": "Brahui",
        "bra": "Braj",
        "pt_BR": "Braziliaans Portugees",
        "br": "Bretons",
        "en_GB": "Brits Engels",
        "bug": "Buginees",
        "bg": "Bulgaars",
        "bum": "Bulu",
        "cad": "Caddo",
        "frc": "Cajun-Frans",
        "en_CA": "Canadees Engels",
        "fr_CA": "Canadees Frans",
        "cps": "Capiznon",
        "car": "Caribisch",
        "ca": "Catalaans",
        "cay": "Cayuga",
        "ceb": "Cebuano",
        "chg": "Chagatai",
        "ch": "Chamorro",
        "chr": "Cherokee",
        "chy": "Cheyenne",
        "chb": "Chibcha",
        "cgg": "Chiga",
        "zh": "Chinees",
        "chn": "Chinook Jargon",
        "chp": "Chipewyan",
        "cho": "Choctaw",
        "chk": "Chuukees",
        "swc": "Congo Swahili",
        "kw": "Cornish",
        "co": "Corsicaans",
        "cr": "Cree",
        "mus": "Creek",
        "dak": "Dakota",
        "dar": "Dargwa",
        "dzg": "Dazaga",
        "da": "Deens",
        "del": "Delaware",
        "din": "Dinka",
        "dv": "Divehi",
        "doi": "Dogri",
        "dgr": "Dogrib",
        "dua": "Duala",
        "de": "Duits",
        "dtp": "Dusun",
        "dyu": "Dyula",
        "dz": "Dzongkha",
        "efi": "Efik",
        "arz": "Egyptisch Arabisch",
        "eka": "Ekajuk",
        "elx": "Elamitisch",
        "ebu": "Embu",
        "egl": "Emiliano",
        "en": "Engels",
        "myv": "Erzja",
        "eo": "Esperanto",
        "et": "Estisch",
        "pt_PT": "Europees Portugees",
        "es_ES": "Europees Spaans",
        "ee": "Ewe",
        "ewo": "Ewondo",
        "ext": "Extremeens",
        "fo": "Faer\u00f6ers",
        "fan": "Fang",
        "fat": "Fanti",
        "fj": "Fijisch",
        "hif": "Fijisch Hindi",
        "fil": "Filipijns",
        "fi": "Fins",
        "phn": "Foenicisch",
        "fon": "Fon",
        "fr": "Frans",
        "fy": "Fries",
        "fur": "Friulisch",
        "ff": "Fulah",
        "gaa": "Ga",
        "gag": "Gagaoezisch",
        "gl": "Galicisch",
        "gan": "Gan Chinese",
        "gay": "Gayo",
        "gba": "Gbaya",
        "gez": "Ge\u2019ez",
        "zxx":"Geen lingu\u00efstische inhoud",
        "aln": "Gegisch",
        "ka": "Georgisch",
        "bbj": "Ghomala\u2019",
        "ki": "Gikuyu",
        "glk": "Gilaki",
        "gil": "Gilbertees",
        "gom": "Goa Konkani",
        "gon": "Gondi",
        "gor": "Gorontalo",
        "got": "Gothisch",
        "grb": "Grebo",
        "el": "Grieks",
        "kl": "Groenlands",
        "gn": "Guaran\u00ed",
        "gu": "Gujarati",
        "gur": "Gurune",
        "guz": "Gusii",
        "gwi": "Gwich\u02bcin",
        "hai": "Haida",
        "ht": "Ha\u00eftiaans Creools",
        "hak": "Hakka",
        "ha": "Hausa",
        "haw": "Hawa\u00efaans",
        "he": "Hebreeuws",
        "hz": "Herero",
        "hit": "Hettitisch",
        "hil": "Hiligaynon",
        "hi": "Hindi",
        "ho": "Hiri Motu",
        "hmn": "Hmong",
        "hu": "Hongaars",
        "hup": "Hupa",
        "iba": "Iban",
        "ibb": "Ibibio",
        "io": "Ido",
        "ga": "Iers",
        "ig": "Igbo",
        "is": "IJslands",
        "ilo": "Iloko",
        "smn": "Inari-Samisch",
        "id": "Indonesisch",
        "inh": "Ingoesjetisch",
        "izh": "Ingrisch",
        "ia": "Interlingua",
        "ie": "Interlingue",
        "iu": "Inuktitut",
        "ik": "Inupiaq",
        "it": "Italiaans",
        "sah": "Jakoets",
        "jam": "Jamaicaans Creools",
        "ja": "Japans",
        "jv": "Javaans",
        "yi": "Jiddisch",
        "kaj": "Jju",
        "dyo": "Jola-Fonyi",
        "jrb": "Judeo-Arabisch",
        "jpr": "Judeo-Perzisch",
        "jut": "Jutlands",
        "quc": "K\u2019iche\u2019",
        "kea": "Kaapverdisch Creools",
        "kbd": "Kabardisch",
        "kab": "Kabylisch",
        "kac": "Kachin",
        "kgp": "Kaingang",
        "kkj": "Kako",
        "kln": "Kalenjin",
        "xal": "Kalmuks",
        "kam": "Kamba",
        "kbl": "Kanembu",
        "kn": "Kannada",
        "yue": "Kantonees",
        "kr": "Kanuri",
        "kaa": "Karakalpaks",
        "krc": "Karatsjaj-Balkarisch",
        "krl": "Karelisch",
        "ks": "Kasjmiri",
        "csb": "Kasjoebisch",
        "kaw": "Kawi",
        "kk": "Kazachs",
        "ken": "Kenyang",
        "cu": "Kerkslavisch",
        "kha": "Khasi",
        "km": "Khmer",
        "kho": "Khotanees",
        "khw": "Khowar",
        "qug": "Kichwa",
        "kmb": "Kimbundu",
        "krj": "Kinaray-a",
        "rw": "Kinyarwanda",
        "ky": "Kirgizisch",
        "kiu": "Kirmanck\u00ee",
        "rn": "Kirundi",
        "lzh": "Klassiek Chinees",
        "nwc": "Klassiek Nepalbhasa",
        "syc": "Klassiek Syrisch",
        "tlh": "Klingon",
        "kum": "Koemuks",
        "ku": "Koerdisch",
        "ksh": "K\u00f6lsch",
        "bkm": "Kom",
        "kv": "Komi",
        "koi": "Komi-Permjaaks",
        "kg": "Kongo",
        "kok": "Konkani",
        "cop": "Koptisch",
        "ko": "Koreaans",
        "kfo": "Koro",
        "kos": "Kosraeaans",
        "avk": "Kotava",
        "khq": "Koyra Chiini",
        "ses": "Koyraboro Senni",
        "kpe": "Kpelle",
        "crh": "Krim-Tataars",
        "kri": "Krio",
        "hr": "Kroatisch",
        "kj": "Kuanyama",
        "kru": "Kurukh",
        "kut": "Kutenai",
        "lad": "Ladino",
        "lah": "Lahnda",
        "lkt": "Lakota",
        "lam": "Lamba",
        "lag": "Langi",
        "lo": "Laotiaans",
        "la": "Latijn",
        "es_419": "Latijns-Amerikaans Spaans",
        "lzz": "Lazisch",
        "ltg": "Letgaals",
        "lv": "Lets",
        "lez": "Lezgisch",
        "lij": "Ligurisch",
        "liv": "Lijfs",
        "li": "Limburgs",
        "ln": "Lingala",
        "lfn": "Lingua Franca Nova",
        "lt": "Litouws",
        "jbo": "Lojban",
        "lmo": "Lombardisch",
        "loz": "Lozi",
        "lu": "Luba-Katanga",
        "lua": "Luba-Lulua",
        "lg": "Luganda",
        "lui": "Luiseno",
        "smj": "Lule-Samisch",
        "lun": "Lunda",
        "luo": "Luo",
        "lb": "Luxemburgs",
        "luy": "Luyia",
        "mas": "Maa",
        "mde": "Maba",
        "mk": "Macedonisch",
        "jmc": "Machame",
        "mad": "Madoerees",
        "maf": "Mafa",
        "mag": "Magahi",
        "vmf": "Main-Franconian",
        "mai": "Maithili",
        "mak": "Makassaars",
        "mgh": "Makhuwa-Meetto",
        "kde": "Makonde",
        "mg": "Malagassisch",
        "ml": "Malayalam",
        "ms": "Maleis",
        "mt": "Maltees",
        "mdr": "Mandar",
        "man": "Mandingo",
        "mnc": "Mantsjoe",
        "gv": "Manx",
        "mi": "Maori",
        "arn": "Mapudungun",
        "mr": "Marathi",
        "chm": "Mari",
        "ary": "Marokkaans Arabisch",
        "mh": "Marshallees",
        "mwr": "Marwari",
        "mzn": "Mazanderani",
        "byv": "Medumba",
        "mul": "Meerdere talen",
        "mni": "Meitei",
        "men": "Mende",
        "mwv": "Mentawai",
        "mer": "Meru",
        "mgo": "Meta\u2019",
        "es_MX": "Mexicaans Spaans",
        "mic": "Mi\u2019kmaq",
        "enm": "Middelengels",
        "frm": "Middelfrans",
        "gmh": "Middelhoogduits",
        "mga": "Middeliers",
        "dum": "Middelnederlands",
        "min": "Minangkabau",
        "xmf": "Mingreels",
        "nan": "Minnanyu",
        "mwl": "Mirandees",
        "lus": "Mizo",
        "ar_001":"Modern standaard Arabisch",
        "moh": "Mohawk",
        "mdf": "Moksja",
        "ro_MD": "Moldavian",
        "lol": "Mongo",
        "mn": "Mongools",
        "mfe": "Morisyen",
        "ttt": "Moslim Tat",
        "mos": "Mossi",
        "mua": "Mundang",
        "mye": "Myene",
        "nqo": "N\u2019Ko",
        "naq": "Nama",
        "nap": "Napolitaans",
        "na": "Nauruaans",
        "nv": "Navajo",
        "ng": "Ndonga",
        "nl": "Nederlands",
        "nds": "Nedersaksisch",
        "dsb": "Nedersorbisch",
        "ne": "Nepalees",
        "new": "Newari",
        "sba": "Ngambay",
        "nnh": "Ngiemboon",
        "jgo": "Ngomba",
        "nmg": "Ngumba",
        "yrl": "Nheengatu",
        "nia": "Nias",
        "niu": "Niueaans",
        "nog": "Nogai",
        "frr": "Noord-Fries",
        "nd": "Noord-Ndebele",
        "se": "Noord-Samisch",
        "nso": "Noord-Sotho",
        "no": "Noors",
        "nb": "Noors - Bokm\u00e5l",
        "nn": "Noors - Nynorsk",
        "nov": "Novial",
        "nus": "Nuer",
        "nym": "Nyamwezi",
        "ny": "Nyanja",
        "nyn": "Nyankole",
        "tog": "Nyasa Tonga",
        "nyo": "Nyoro",
        "nzi": "Nzima",
        "oc": "Occitaans",
        "or": "Odia",
        "udm": "Oedmoerts",
        "uga": "Oegaritisch",
        "ug": "Oeigoers",
        "uk": "Oekra\u00efens",
        "uz": "Oezbeeks",
        "oj": "Ojibwa",
        "und":"Onbekende taal",
        "frs": "Oost-Fries",
        "de_AT": "Oostenrijks Duits",
        "hsb": "Oppersorbisch",
        "osa": "Osage",
        "os": "Ossetisch",
        "ota": "Ottomaans-Turks",
        "egy": "Oudegyptisch",
        "ang": "Oudengels",
        "fro": "Oudfrans",
        "grc": "Oudgrieks",
        "goh": "Oudhoogduits",
        "sga": "Oudiers",
        "non": "Oudnoors",
        "peo": "Oudperzisch",
        "pro": "Oudproven\u00e7aals",
        "prg": "Oudpruisisch",
        "pal": "Pahlavi",
        "pau": "Palaus",
        "pi": "Pali",
        "pfl": "Paltsisch",
        "pam": "Pampanga",
        "pag": "Pangasinan",
        "pap": "Papiaments",
        "ps": "Pasjtoe",
        "pdc": "Pennsylvania-Duits",
        "fa": "Perzisch",
        "pcd": "Picardisch",
        "pms": "Pi\u00ebmontees",
        "pdt": "Plautdietsch",
        "pon": "Pohnpeiaans",
        "pnt": "Pontisch",
        "pl": "Pools",
        "pt": "Portugees",
        "pa": "Punjabi",
        "qu": "Quechua",
        "raj": "Rajasthani",
        "rap": "Rapanui",
        "rar": "Rarotongan",
        "rm": "Reto-Romaans",
        "rif": "Riffijns",
        "ro": "Roemeens",
        "rue": "Roetheens",
        "rgn": "Romagnol",
        "rom": "Romani",
        "rof": "Rombo",
        "root": "Root",
        "rtm": "Rotumaans",
        "rug": "Roviana",
        "ru": "Russisch",
        "rwk": "Rwa",
        "ssy": "Saho",
        "sam": "Samaritaans-Aramees",
        "saq": "Samburu",
        "sm": "Samoaans",
        "sgs": "Samogitisch",
        "sad": "Sandawe",
        "sg": "Sango",
        "sbp": "Sangu",
        "sa": "Sanskriet",
        "sat": "Santali",
        "sc": "Sardijns",
        "sas": "Sasak",
        "sdc": "Sassarees",
        "stq": "Saterfries",
        "saz": "Saurashtra",
        "sco": "Schots",
        "gd": "Schots-Gaelisch",
        "sly": "Selayar",
        "sel": "Selkoeps",
        "seh": "Sena",
        "see": "Seneca",
        "srr": "Serer",
        "sei": "Seri",
        "sr": "Servisch",
        "sh": "Servo-Kroatisch",
        "ksb": "Shambala",
        "shn": "Shan",
        "swb": "Shimaore",
        "sn": "Shona",
        "scn": "Siciliaans",
        "sid": "Sidamo",
        "bla": "Siksika",
        "szl": "Silezisch",
        "sli": "Silezisch Duits",
        "sd": "Sindhi",
        "si": "Singalees",
        "sms": "Skolt-Samisch",
        "den": "Slavey",
        "sl": "Sloveens",
        "sk": "Slowaaks",
        "sux": "Soemerisch",
        "su": "Soendanees",
        "sus": "Soesoe",
        "xog": "Soga",
        "sog": "Sogdisch",
        "so": "Somalisch",
        "snk": "Soninke",
        "ckb": "Soran\u00ee",
        "es": "Spaans",
        "srn": "Sranantongo",
        "zgh": "Standaard Marokkaanse Tamazight",
        "suk": "Sukuma",
        "sw": "Swahili",
        "ss": "Swazi",
        "syr": "Syrisch",
        "tg": "Tadzjieks",
        "tl": "Tagalog",
        "ty": "Tahitiaans",
        "dav": "Taita",
        "tly": "Talysh",
        "tmh": "Tamashek",
        "tzm": "Tamazight (Centraal-Marokko)",
        "ta": "Tamil",
        "trv": "Taroko",
        "twq": "Tasawaq",
        "shi": "Tashelhiyt",
        "tt": "Tataars",
        "te": "Telugu",
        "ter": "Tereno",
        "teo": "Teso",
        "tet": "Tetun",
        "th": "Thais",
        "bo": "Tibetaans",
        "tig": "Tigre",
        "ti": "Tigrinya",
        "tem": "Timne",
        "tiv": "Tiv",
        "tli": "Tlingit",
        "tum": "Toemboeka",
        "tyv": "Toevaans",
        "tpi": "Tok Pisin",
        "tkl": "Tokelaus",
        "to": "Tongaans",
        "fit": "Tornedal-Fins",
        "zh_Hant":"Traditioneel Chinees",
        "tkr": "Tsakhur",
        "tsd": "Tsakonisch",
        "tsi": "Tsimshian",
        "shu": "Tsjadisch Arabisch",
        "cs": "Tsjechisch",
        "ce": "Tsjetsjeens",
        "cv": "Tsjoevasjisch",
        "ts": "Tsonga",
        "tn": "Tswana",
        "tcy": "Tulu",
        "aeb": "Tunesisch Arabisch",
        "tk": "Turkmeens",
        "tr": "Turks",
        "tru": "Turoyo",
        "tvl": "Tuvaluaans",
        "tw": "Twi",
        "kcg": "Tyap",
        "umb": "Umbundu",
        "ur": "Urdu",
        "vai": "Vai",
        "ve": "Venda",
        "vec": "Venetiaans",
        "zh_Hans":"Vereenvoudigd Chinees",
        "vi": "Vietnamees",
        "nl_BE": "Vlaams",
        "vo": "Volap\u00fck",
        "vro": "V\u00f5ro",
        "vot": "Votisch",
        "vun": "Vunjo",
        "wa": "Waals",
        "wae": "Walser",
        "war": "Waray",
        "wbp": "Warlpiri",
        "was": "Washo",
        "guc": "Wayuu",
        "cy": "Welsh",
        "vep": "Wepsisch",
        "mrj": "West-Mari",
        "vls": "West-Vlaams",
        "be": "Wit-Russisch",
        "wal": "Wolaytta",
        "wo": "Wolof",
        "wuu": "Wuyu",
        "xh": "Xhosa",
        "hsn": "Xiangyu",
        "yav": "Yangben",
        "yao": "Yao",
        "yap": "Yapees",
        "ybb": "Yemba",
        "ii": "Yi",
        "yo": "Yoruba",
        "esu": "Yupik",
        "zap": "Zapotec",
        "dje": "Zarma",
        "zza": "Zaza",
        "zea": "Zeeuws",
        "zen": "Zenaga",
        "za": "Zhuang",
        "zu": "Zoeloe",
        "gbz": "Zoroastrisch Dari",
        "alt": "Zuid-Alta\u00efsch",
        "azb": "Zuid-Azerbeidzjaans Arabisch",
        "nr": "Zuid-Ndbele",
        "sma": "Zuid-Samisch",
        "st": "Zuid-Sotho",
        "zun": "Zuni",
        "sv": "Zweeds",
        "gsw": "Zwitserduits",
        "fr_CH": "Zwitsers Frans",
        "de_CH": "Zwitsers Hoogduits"
    },
    en_EN: {
        "ab": "Abkhazian",
        "ace": "Achinese",
        "ach": "Acoli",
        "ada": "Adangme",
        "ady": "Adyghe",
        "aa": "Afar",
        "afh": "Afrihili",
        "af": "Afrikaans",
        "agq": "Aghem",
        "ain": "Ainu",
        "ak": "Akan",
        "akk": "Akkadian",
        "bss": "Akoose",
        "akz": "Alabama",
        "sq": "Albanian",
        "ale": "Aleut",
        "arq": "Algerian Arabic",
        "en_US": "American English",
        "ase": "American Sign Language",
        "am": "Amharic",
        "egy": "Ancient Egyptian",
        "grc": "Ancient Greek",
        "anp": "Angika",
        "njo": "Ao Naga",
        "ar": "Arabic",
        "an": "Aragonese",
        "arc": "Aramaic",
        "aro": "Araona",
        "arp": "Arapaho",
        "arw": "Arawak",
        "hy": "Armenian",
        "rup": "Aromanian",
        "frp": "Arpitan",
        "as": "Assamese",
        "ast": "Asturian",
        "asa": "Asu",
        "cch": "Atsam",
        "en_AU": "Australian English",
        "de_AT": "Austrian German",
        "av": "Avaric",
        "ae": "Avestan",
        "awa": "Awadhi",
        "ay": "Aymara",
        "az": "Azerbaijani",
        "bfq": "Badaga",
        "ksf": "Bafia",
        "bfd": "Bafut",
        "bqi": "Bakhtiari",
        "ban": "Balinese",
        "bal": "Baluchi",
        "bm": "Bambara",
        "bax": "Bamun",
        "bjn": "Banjar",
        "bas": "Basaa",
        "ba": "Bashkir",
        "eu": "Basque",
        "bbc": "Batak Toba",
        "bar": "Bavarian",
        "bej": "Beja",
        "be": "Belarusian",
        "bem": "Bemba",
        "bez": "Bena",
        "bn": "Bengali",
        "bew": "Betawi",
        "bho": "Bhojpuri",
        "bik": "Bikol",
        "bin": "Bini",
        "bpy": "Bishnupriya",
        "bi": "Bislama",
        "byn": "Blin",
        "zbl": "Blissymbols",
        "brx": "Bodo",
        "bs": "Bosnian",
        "brh": "Brahui",
        "bra": "Braj",
        "pt_BR": "Brazilian Portuguese",
        "br": "Breton",
        "en_GB": "British English",
        "bug": "Buginese",
        "bg": "Bulgarian",
        "bum": "Bulu",
        "bua": "Buriat",
        "my": "Burmese",
        "cad": "Caddo",
        "frc": "Cajun French",
        "en_CA": "Canadian English",
        "fr_CA": "Canadian French",
        "yue": "Cantonese",
        "cps": "Capiznon",
        "car": "Carib",
        "ca": "Catalan",
        "cay": "Cayuga",
        "ceb": "Cebuano",
        "tzm": "Central Atlas Tamazight",
        "dtp": "Central Dusun",
        "ckb": "Central Kurdish",
        "esu": "Central Yupik",
        "shu": "Chadian Arabic",
        "chg": "Chagatai",
        "ch": "Chamorro",
        "ce": "Chechen",
        "chr": "Cherokee",
        "chy": "Cheyenne",
        "chb": "Chibcha",
        "cgg": "Chiga",
        "qug": "Chimborazo Highland Quichua",
        "zh": "Chinese",
        "chn": "Chinook Jargon",
        "chp": "Chipewyan",
        "cho": "Choctaw",
        "cu": "Church Slavic",
        "chk": "Chuukese",
        "cv": "Chuvash",
        "nwc": "Classical Newari",
        "syc": "Classical Syriac",
        "ksh": "Colognian",
        "swb": "Comorian",
        "swc": "Congo Swahili",
        "cop": "Coptic",
        "kw": "Cornish",
        "co": "Corsican",
        "cr": "Cree",
        "mus": "Creek",
        "crh": "Crimean Turkish",
        "hr": "Croatian",
        "cs": "Czech",
        "dak": "Dakota",
        "da": "Danish",
        "dar": "Dargwa",
        "dzg": "Dazaga",
        "del": "Delaware",
        "din": "Dinka",
        "dv": "Divehi",
        "doi": "Dogri",
        "dgr": "Dogrib",
        "dua": "Duala",
        "nl": "Dutch",
        "dyu": "Dyula",
        "dz": "Dzongkha",
        "frs": "Eastern Frisian",
        "efi": "Efik",
        "arz": "Egyptian Arabic",
        "eka": "Ekajuk",
        "elx": "Elamite",
        "ebu": "Embu",
        "egl": "Emilian",
        "en": "English",
        "myv": "Erzya",
        "eo": "Esperanto",
        "et": "Estonian",
        "pt_PT": "European Portuguese",
        "es_ES": "European Spanish",
        "ee": "Ewe",
        "ewo": "Ewondo",
        "ext": "Extremaduran",
        "fan": "Fang",
        "fat": "Fanti",
        "fo": "Faroese",
        "hif": "Fiji Hindi",
        "fj": "Fijian",
        "fil": "Filipino",
        "fi": "Finnish",
        "nl_BE": "Flemish",
        "fon": "Fon",
        "gur": "Frafra",
        "fr": "French",
        "fur": "Friulian",
        "ff": "Fulah",
        "gaa": "Ga",
        "gag": "Gagauz",
        "gl": "Galician",
        "gan": "Gan Chinese",
        "lg": "Ganda",
        "gay": "Gayo",
        "gba": "Gbaya",
        "gez": "Geez",
        "ka": "Georgian",
        "de": "German",
        "aln": "Gheg Albanian",
        "bbj": "Ghomala",
        "glk": "Gilaki",
        "gil": "Gilbertese",
        "gom": "Goan Konkani",
        "gon": "Gondi",
        "gor": "Gorontalo",
        "got": "Gothic",
        "grb": "Grebo",
        "el": "Greek",
        "gn": "Guarani",
        "gu": "Gujarati",
        "guz": "Gusii",
        "gwi": "Gwich\u02bcin",
        "hai": "Haida",
        "ht": "Haitian",
        "hak": "Hakka Chinese",
        "ha": "Hausa",
        "haw": "Hawaiian",
        "he": "Hebrew",
        "hz": "Herero",
        "hil": "Hiligaynon",
        "hi": "Hindi",
        "ho": "Hiri Motu",
        "hit": "Hittite",
        "hmn": "Hmong",
        "hu": "Hungarian",
        "hup": "Hupa",
        "iba": "Iban",
        "ibb": "Ibibio",
        "is": "Icelandic",
        "io": "Ido",
        "ig": "Igbo",
        "ilo": "Iloko",
        "smn": "Inari Sami",
        "id": "Indonesian",
        "izh": "Ingrian",
        "inh": "Ingush",
        "ia": "Interlingua",
        "ie": "Interlingue",
        "iu": "Inuktitut",
        "ik": "Inupiaq",
        "ga": "Irish",
        "it": "Italian",
        "jam": "Jamaican Creole English",
        "ja": "Japanese",
        "jv": "Javanese",
        "kaj": "Jju",
        "dyo": "Jola-Fonyi",
        "jrb": "Judeo-Arabic",
        "jpr": "Judeo-Persian",
        "jut": "Jutish",
        "kbd": "Kabardian",
        "kea": "Kabuverdianu",
        "kab": "Kabyle",
        "kac": "Kachin",
        "kgp": "Kaingang",
        "kkj": "Kako",
        "kl": "Kalaallisut",
        "kln": "Kalenjin",
        "xal": "Kalmyk",
        "kam": "Kamba",
        "kbl": "Kanembu",
        "kn": "Kannada",
        "kr": "Kanuri",
        "kaa": "Kara-Kalpak",
        "krc": "Karachay-Balkar",
        "krl": "Karelian",
        "ks": "Kashmiri",
        "csb": "Kashubian",
        "kaw": "Kawi",
        "kk": "Kazakh",
        "ken": "Kenyang",
        "kha": "Khasi",
        "km": "Khmer",
        "kho": "Khotanese",
        "khw": "Khowar",
        "ki": "Kikuyu",
        "kmb": "Kimbundu",
        "krj": "Kinaray-a",
        "rw": "Kinyarwanda",
        "kiu": "Kirmanjki",
        "tlh": "Klingon",
        "bkm": "Kom",
        "kv": "Komi",
        "koi": "Komi-Permyak",
        "kg": "Kongo",
        "kok": "Konkani",
        "ko": "Korean",
        "kfo": "Koro",
        "kos": "Kosraean",
        "avk": "Kotava",
        "khq": "Koyra Chiini",
        "ses": "Koyraboro Senni",
        "kpe": "Kpelle",
        "kri": "Krio",
        "kj": "Kuanyama",
        "kum": "Kumyk",
        "ku": "Kurdish",
        "kru": "Kurukh",
        "kut": "Kutenai",
        "nmg": "Kwasio",
        "ky": "Kyrgyz",
        "quc": "K\u02bciche\u02bc",
        "lad": "Ladino",
        "lah": "Lahnda",
        "lkt": "Lakota",
        "lam": "Lamba",
        "lag": "Langi",
        "lo": "Lao",
        "ltg": "Latgalian",
        "la": "Latin",
        "es_419": "Latin American Spanish",
        "lv": "Latvian",
        "lzz": "Laz",
        "lez": "Lezghian",
        "lij": "Ligurian",
        "li": "Limburgish",
        "ln": "Lingala",
        "lfn": "Lingua Franca Nova",
        "lzh": "Literary Chinese",
        "lt": "Lithuanian",
        "liv": "Livonian",
        "jbo": "Lojban",
        "lmo": "Lombard",
        "nds": "Low German",
        "sli": "Lower Silesian",
        "dsb": "Lower Sorbian",
        "loz": "Lozi",
        "lu": "Luba-Katanga",
        "lua": "Luba-Lulua",
        "lui": "Luiseno",
        "smj": "Lule Sami",
        "lun": "Lunda",
        "luo": "Luo",
        "lb": "Luxembourgish",
        "luy": "Luyia",
        "mde": "Maba",
        "mk": "Macedonian",
        "jmc": "Machame",
        "mad": "Madurese",
        "maf": "Mafa",
        "mag": "Magahi",
        "vmf": "Main-Franconian",
        "mai": "Maithili",
        "mak": "Makasar",
        "mgh": "Makhuwa-Meetto",
        "kde": "Makonde",
        "mg": "Malagasy",
        "ms": "Malay",
        "ml": "Malayalam",
        "mt": "Maltese",
        "mnc": "Manchu",
        "mdr": "Mandar",
        "man": "Mandingo",
        "mni": "Manipuri",
        "gv": "Manx",
        "mi": "Maori",
        "arn": "Mapuche",
        "mr": "Marathi",
        "chm": "Mari",
        "mh": "Marshallese",
        "mwr": "Marwari",
        "mas": "Masai",
        "mzn": "Mazanderani",
        "byv": "Medumba",
        "men": "Mende",
        "mwv": "Mentawai",
        "mer": "Meru",
        "mgo": "Meta\u02bc",
        "es_MX": "Mexican Spanish",
        "mic": "Micmac",
        "dum": "Middle Dutch",
        "enm": "Middle English",
        "frm": "Middle French",
        "gmh": "Middle High German",
        "mga": "Middle Irish",
        "nan": "Min Nan Chinese",
        "min": "Minangkabau",
        "xmf": "Mingrelian",
        "mwl": "Mirandese",
        "lus": "Mizo",
        "ar_001": "Modern Standard Arabic",
        "moh": "Mohawk",
        "mdf": "Moksha",
        "ro_MD": "Moldavian",
        "lol": "Mongo",
        "mn": "Mongolian",
        "mfe": "Morisyen",
        "ary": "Moroccan Arabic",
        "mos": "Mossi",
        "mul": "Multiple Languages",
        "mua": "Mundang",
        "ttt": "Muslim Tat",
        "mye": "Myene",
        "naq": "Nama",
        "na": "Nauru",
        "nv": "Navajo",
        "ng": "Ndonga",
        "nap": "Neapolitan",
        "ne": "Nepali",
        "new": "Newari",
        "sba": "Ngambay",
        "nnh": "Ngiemboon",
        "jgo": "Ngomba",
        "yrl": "Nheengatu",
        "nia": "Nias",
        "niu": "Niuean",
        "zxx": "No linguistic content",
        "nog": "Nogai",
        "nd": "North Ndebele",
        "frr": "Northern Frisian",
        "se": "Northern Sami",
        "nso": "Northern Sotho",
        "no": "Norwegian",
        "nb": "Norwegian Bokm\u00e5l",
        "nn": "Norwegian Nynorsk",
        "nov": "Novial",
        "nus": "Nuer",
        "nym": "Nyamwezi",
        "ny": "Nyanja",
        "nyn": "Nyankole",
        "tog": "Nyasa Tonga",
        "nyo": "Nyoro",
        "nzi": "Nzima",
        "nqo": "N\u02bcKo",
        "oc": "Occitan",
        "oj": "Ojibwa",
        "ang": "Old English",
        "fro": "Old French",
        "goh": "Old High German",
        "sga": "Old Irish",
        "non": "Old Norse",
        "peo": "Old Persian",
        "pro": "Old Proven\u00e7al",
        "or": "Oriya",
        "om": "Oromo",
        "osa": "Osage",
        "os": "Ossetic",
        "ota": "Ottoman Turkish",
        "pal": "Pahlavi",
        "pfl": "Palatine German",
        "pau": "Palauan",
        "pi": "Pali",
        "pam": "Pampanga",
        "pag": "Pangasinan",
        "pap": "Papiamento",
        "ps": "Pashto",
        "pdc": "Pennsylvania German",
        "fa": "Persian",
        "phn": "Phoenician",
        "pcd": "Picard",
        "pms": "Piedmontese",
        "pdt": "Plautdietsch",
        "pon": "Pohnpeian",
        "pl": "Polish",
        "pnt": "Pontic",
        "pt": "Portuguese",
        "prg": "Prussian",
        "pa": "Punjabi",
        "qu": "Quechua",
        "raj": "Rajasthani",
        "rap": "Rapanui",
        "rar": "Rarotongan",
        "rif": "Riffian",
        "rgn": "Romagnol",
        "ro": "Romanian",
        "rm": "Romansh",
        "rom": "Romany",
        "rof": "Rombo",
        "root": "Root",
        "rtm": "Rotuman",
        "rug": "Roviana",
        "rn": "Rundi",
        "ru": "Russian",
        "rue": "Rusyn",
        "rwk": "Rwa",
        "ssy": "Saho",
        "sah": "Sakha",
        "sam": "Samaritan Aramaic",
        "saq": "Samburu",
        "sm": "Samoan",
        "sgs": "Samogitian",
        "sad": "Sandawe",
        "sg": "Sango",
        "sbp": "Sangu",
        "sa": "Sanskrit",
        "sat": "Santali",
        "sc": "Sardinian",
        "sas": "Sasak",
        "sdc": "Sassarese Sardinian",
        "stq": "Saterland Frisian",
        "saz": "Saurashtra",
        "sco": "Scots",
        "gd": "Scottish Gaelic",
        "sly": "Selayar",
        "sel": "Selkup",
        "seh": "Sena",
        "see": "Seneca",
        "sr": "Serbian",
        "sh": "Serbo-Croatian",
        "srr": "Serer",
        "sei": "Seri",
        "ksb": "Shambala",
        "shn": "Shan",
        "sn": "Shona",
        "ii": "Sichuan Yi",
        "scn": "Sicilian",
        "sid": "Sidamo",
        "bla": "Siksika",
        "szl": "Silesian",
        "zh_Hans": "Simplified Chinese",
        "sd": "Sindhi",
        "si": "Sinhala",
        "sms": "Skolt Sami",
        "den": "Slave",
        "sk": "Slovak",
        "sl": "Slovenian",
        "xog": "Soga",
        "sog": "Sogdien",
        "so": "Somali",
        "snk": "Soninke",
        "azb": "South Azerbaijani",
        "nr": "South Ndebele",
        "alt": "Southern Altai",
        "sma": "Southern Sami",
        "st": "Southern Sotho",
        "es": "Spanish",
        "srn": "Sranan Tongo",
        "zgh": "Standard Moroccan Tamazight",
        "suk": "Sukuma",
        "sux": "Sumerian",
        "su": "Sundanese",
        "sus": "Susu",
        "sw": "Swahili",
        "ss": "Swati",
        "sv": "Swedish",
        "fr_CH": "Swiss French",
        "gsw": "Swiss German",
        "de_CH": "Swiss High German",
        "syr": "Syriac",
        "shi": "Tachelhit",
        "tl": "Tagalog",
        "ty": "Tahitian",
        "dav": "Taita",
        "tg": "Tajik",
        "tly": "Talysh",
        "tmh": "Tamashek",
        "ta": "Tamil",
        "trv": "Taroko",
        "twq": "Tasawaq",
        "tt": "Tatar",
        "te": "Telugu",
        "ter": "Tereno",
        "teo": "Teso",
        "tet": "Tetum",
        "th": "Thai",
        "bo": "Tibetan",
        "tig": "Tigre",
        "ti": "Tigrinya",
        "tem": "Timne",
        "tiv": "Tiv",
        "tli": "Tlingit",
        "tpi": "Tok Pisin",
        "tkl": "Tokelau",
        "to": "Tongan",
        "fit": "Tornedalen Finnish",
        "zh_Hant": "Traditional Chinese",
        "tkr": "Tsakhur",
        "tsd": "Tsakonian",
        "tsi": "Tsimshian",
        "ts": "Tsonga",
        "tn": "Tswana",
        "tcy": "Tulu",
        "tum": "Tumbuka",
        "aeb": "Tunisian Arabic",
        "tr": "Turkish",
        "tk": "Turkmen",
        "tru": "Turoyo",
        "tvl": "Tuvalu",
        "tyv": "Tuvinian",
        "tw": "Twi",
        "kcg": "Tyap",
        "udm": "Udmurt",
        "uga": "Ugaritic",
        "uk": "Ukrainian",
        "umb": "Umbundu",
        "und": "Unknown Language",
        "hsb": "Upper Sorbian",
        "ur": "Urdu",
        "ug": "Uyghur",
        "uz": "Uzbek",
        "vai": "Vai",
        "ve": "Venda",
        "vec": "Venetian",
        "vep": "Veps",
        "vi": "Vietnamese",
        "vo": "Volap\u00fck",
        "vro": "V\u00f5ro",
        "vot": "Votic",
        "vun": "Vunjo",
        "wa": "Walloon",
        "wae": "Walser",
        "war": "Waray",
        "wbp": "Warlpiri",
        "was": "Washo",
        "guc": "Wayuu",
        "cy": "Welsh",
        "vls": "West Flemish",
        "fy": "Western Frisian",
        "mrj": "Western Mari",
        "wal": "Wolaytta",
        "wo": "Wolof",
        "wuu": "Wu Chinese",
        "xh": "Xhosa",
        "hsn": "Xiang Chinese",
        "yav": "Yangben",
        "yao": "Yao",
        "yap": "Yapese",
        "ybb": "Yemba",
        "yi": "Yiddish",
        "yo": "Yoruba",
        "zap": "Zapotec",
        "dje": "Zarma",
        "zza": "Zaza",
        "zea": "Zeelandic",
        "zen": "Zenaga",
        "za": "Zhuang",
        "gbz": "Zoroastrian Dari",
        "zu": "Zulu",
        "zun": "Zuni"
    },
    fr_FR: {
        "ab":"Abkhaze",
        "ace":"Aceh",
        "ach":"Acoli",
        "ada":"Adangme",
        "ady":"Adygh\u00e9en",
        "aa":"Afar",
        "afh":"Afrihili",
        "af":"Afrikaans",
        "agq":"Aghem",
        "ain":"A\u00efnou",
        "ak":"Akan",
        "akk":"Akkadien",
        "bss":"Akoose",
        "akz": "Alabama",
        "sq":"Albanais",
        "ale":"Al\u00e9oute",
        "arq": "Algerian Arabic",
        "de":"Allemand",
        "de_AT":"Allemand autrichien",
        "de_CH":"Allemand suisse",
        "alt":"Alta\u00ef du Sud",
        "zgh":"Amazighe standard marocain",
        "ase": "American Sign Language",
        "am":"Amharique",
        "ang":"Ancien anglais",
        "fro":"Ancien fran\u00e7ais",
        "goh":"Ancien haut allemand",
        "sga":"Ancien irlandais",
        "anp":"Angika",
        "en": "Anglais",
        "en_US":"Anglais am\u00e9ricain",
        "en_AU":"Anglais australien",
        "en_GB":"Anglais britannique",
        "en_CA":"Anglais canadien",
        "njo": "Ao Naga",
        "ar":"Arabe",
        "ar_001":"Arabe standard moderne",
        "shu":"Arabe tchadien",
        "an":"Aragonais",
        "arc":"Aram\u00e9en",
        "sam":"Aram\u00e9en samaritain",
        "aro": "Araona",
        "arp":"Arapaho",
        "arn":"Araukan",
        "arw":"Arawak",
        "hy":"Arm\u00e9nien",
        "as":"Assamais",
        "asa":"Assou",
        "ast":"Asturien",
        "cch":"Atsam",
        "av":"Avar",
        "ae":"Avestique",
        "awa":"Awadhi",
        "ay":"Aymara",
        "az":"Az\u00e9ri",
        "ba":"Bachkir",
        "bfq": "Badaga",
        "ksf":"Bafia",
        "bfd":"Bafut",
        "bqi": "Bakhtiari",
        "ban":"Balinais",
        "bal":"Baloutchi",
        "bm":"Bambara",
        "bax":"Bamoun",
        "bjn": "Banjar",
        "nds":"Bas-allemand",
        "dsb":"Bas-sorabe",
        "eu":"Basque",
        "bas":"Bassa",
        "bbc": "Batak Toba",
        "bar": "Bavarian",
        "bej":"Bedja",
        "bem":"Bemba",
        "bez":"B\u00e9na",
        "bn":"Bengali",
        "bew": "Betawi",
        "bho":"Bhojpuri",
        "bi":"Bichelamar",
        "be":"Bi\u00e9lorusse",
        "bik":"Bikol",
        "bin":"Bini",
        "my":"Birman",
        "bpy": "Bishnupriya",
        "byn":"Blin",
        "brx":"Bodo",
        "bs":"Bosniaque",
        "bum":"Boulou",
        "bua":"Bouriate",
        "brh": "Brahui",
        "bra":"Braj",
        "br":"Breton",
        "bug":"Bugi",
        "bg":"Bulgare",
        "cad":"Caddo",
        "frc": "Cajun French",
        "yue":"Cantonais",
        "cps": "Capiznon",
        "kea":"Capverdien",
        "krl":"Car\u00e9lien",
        "car":"Caribe",
        "ca":"Catalan",
        "cay":"Cayuga",
        "ceb":"Cebuano",
        "dtp": "Central Dusun",
        "esu": "Central Yupik",
        "ksb":"Chambala",
        "ch":"Chamorro",
        "chr":"Cherokee",
        "chy":"Cheyenne",
        "chb":"Chibcha",
        "qug": "Chimborazo Highland Quichua",
        "zh":"Chinois",
        "zh_Hans":"Chinois simplifi\u00e9",
        "zh_Hant":"Chinois traditionnel",
        "chp":"Chipewyan",
        "shi":"Chleuh",
        "cho":"Choctaw",
        "chk":"Chuuk",
        "si":"Cinghalais",
        "swb":"Comorien",
        "cop":"Copte",
        "ko":"Cor\u00e9en",
        "kw":"Cornique",
        "co":"Corse",
        "cr":"Cree",
        "mus":"Creek",
        "mfe":"Cr\u00e9ole mauricien",
        "hr":"Croate",
        "dak":"Dakota",
        "da":"Danois",
        "dar":"Dargwa",
        "dzg":"Dazaga",
        "del":"Delaware",
        "din":"Dinka",
        "dyo":"Diola-fogny",
        "dyu":"Dioula",
        "doi":"Dogri",
        "dgr":"Dogrib",
        "dua":"Douala",
        "dz":"Dzongkha",
        "sco": "\u00e9cossais",
        "efi":"Efik",
        "arz": "Egyptian Arabic",
        "egy": "\u00e9gyptien ancien",
        "eka":"Ekajuk",
        "elx": "\u00e9lamite",
        "ebu":"Embou",
        "egl": "Emilian",
        "myv":"Erzya",
        "es":"Espagnol",
        "es_ES":"Espagnol europ\u00e9en",
        "es_419":"Espagnol latino-am\u00e9ricain",
        "es_MX":"Espagnol mexicain",
        "eo":"Esp\u00e9ranto",
        "et":"Estonien",
        "ee": "\u00e9w\u00e9",
        "ewo": "\u00e9wondo",
        "ext": "Extremaduran",
        "fan":"Fang",
        "fat":"Fanti",
        "fo":"F\u00e9ro\u00efen",
        "fj":"Fidjien",
        "hif": "Fiji Hindi",
        "fil":"Filipino",
        "fi":"Finnois",
        "nl_BE":"Flamand",
        "fon":"Fon",
        "gur": "Frafra",
        "fr": "Fran\u00e7ais",
        "fr_CA":"Fran\u00e7ais canadien",
        "fr_CH":"Fran\u00e7ais suisse",
        "ksh":"Francique ripuaire",
        "frp":"Franco-proven\u00e7al",
        "fur":"Frioulan",
        "frr":"Frison du Nord",
        "fy":"Frison occidental",
        "frs":"Frison oriental",
        "gaa":"Ga",
        "gd":"Ga\u00e9lique \u00e9cossais",
        "gag":"Gagaouze",
        "gl":"Galicien",
        "cy":"Gallois",
        "gan": "Gan Chinese",
        "lg":"Ganda",
        "gay":"Gayo",
        "gba":"Gbaya",
        "ka":"G\u00e9orgien",
        "aln": "Gheg Albanian",
        "bbj":"Ghomala",
        "glk": "Gilaki",
        "gil":"Gilbertais",
        "gom": "Goan Konkani",
        "gon":"Gondi",
        "gor":"Gorontalo",
        "got":"Gotique",
        "grb":"Grebo",
        "el":"Grec",
        "grc":"Grec ancien",
        "kl":"Groenlandais",
        "gn":"Guarani",
        "gez":"Gu\u00e8ze",
        "gu":"Gujarati",
        "guz":"Gusii",
        "gwi":"Gwich\u02bcin",
        "hai":"Haida",
        "ht":"Ha\u00eftien",
        "hak": "Hakka Chinese",
        "ha":"Haoussa",
        "hsb":"Haut-sorabe",
        "haw":"Hawa\u00efen",
        "he":"H\u00e9breu",
        "hz":"H\u00e9r\u00e9ro",
        "hil":"Hiligaynon",
        "hi":"Hindi",
        "ho":"Hiri motu",
        "hit":"Hittite",
        "hmn":"Hmong",
        "hu":"Hongrois",
        "hup":"Hupa",
        "sah":"Iakoute",
        "iba":"Iban",
        "ibb":"Ibibio",
        "io":"Ido",
        "ig":"Igbo",
        "ilo":"Ilokano",
        "id":"Indon\u00e9sien",
        "inh":"Ingouche",
        "izh": "Ingrian",
        "ia":"Interlingua",
        "ie":"Interlingue",
        "iu":"Inuktitut",
        "ik":"Inupiaq",
        "ga":"Irlandais",
        "is":"Islandais",
        "it":"Italien",
        "jam": "Jamaican Creole English",
        "ja":"Japonais",
        "chn":"Jargon chinook",
        "jv":"Javanais",
        "kaj":"Jju",
        "jrb":"Jud\u00e9o-arabe",
        "jpr":"Jud\u00e9o-persan",
        "jut": "Jutish",
        "quc":"K\u2019iche\u2019",
        "kbd":"Kabardin",
        "kab":"Kabyle",
        "kac":"Kachin",
        "csb":"Kachoube",
        "kgp": "Kaingang",
        "kkj":"Kako",
        "kln":"Kalenjin",
        "xal":"Kalmouk",
        "kam":"Kamba",
        "kbl":"Kanembou",
        "kn":"Kannada",
        "kr":"Kanouri",
        "kaa":"Karakalpak",
        "krc":"Karatcha\u00ef balkar",
        "ks":"Kashmiri",
        "kaw":"Kawi",
        "kk":"Kazakh",
        "ken": "Kenyang",
        "kha":"Khasi",
        "km":"Khmer",
        "kho":"Khotanais",
        "khw": "Khowar",
        "cgg":"Kiga",
        "ki":"Kikuyu",
        "kmb":"KiMboundou",
        "krj": "Kinaray-a",
        "ky":"Kirghize",
        "kiu": "Kirmanjki",
        "tlh":"Klingon",
        "bkm":"Kom",
        "kv":"Komi",
        "koi":"Komi-permiak",
        "kg":"Kongo",
        "kok":"Konkani",
        "kfo":"Koro",
        "avk": "Kotava",
        "kum":"Koumyk",
        "khq":"Koyra chiini",
        "ses":"Koyraboro senni",
        "kpe":"Kpell\u00e9",
        "kri": "Krio",
        "kj":"Kuanyama",
        "ku":"Kurde",
        "kru":"Kurukh",
        "kos":"Kusaien",
        "kut":"Kutenai",
        "nmg":"Kwasio",
        "lad":"Ladino",
        "lah":"Lahnda",
        "lkt":"Lakota",
        "lam":"Lamba",
        "lag":"Langi",
        "und":"Langue ind\u00e9termin\u00e9e",
        "lo":"Lao",
        "ltg": "Latgalian",
        "la":"Latin",
        "lzz": "Laz",
        "lv":"Letton",
        "lez":"Lezghien",
        "lij": "Ligurian",
        "li":"Limbourgeois",
        "ln":"Lingala",
        "lfn": "Lingua Franca Nova",
        "lzh": "Literary Chinese",
        "lt":"Lituanien",
        "liv": "Livonian",
        "jbo":"Lojban",
        "lmo": "Lombard",
        "sli": "Lower Silesian",
        "loz":"Lozi",
        "lu":"Luba-katanga",
        "lua":"Luba-lulua",
        "lui":"Luiseno",
        "lun":"Lunda",
        "luo":"Luo",
        "lus":"Lushai",
        "lb":"Luxembourgeois",
        "mde":"Maba",
        "mk":"Mac\u00e9donien",
        "jmc":"Machame",
        "mad":"Madurais",
        "maf":"Mafa",
        "mag":"Magahi",
        "vmf": "Main-Franconian",
        "mai":"Maithili",
        "mak":"Makassar",
        "mgh":"Makhuwa-meetto",
        "kde":"Makonde",
        "ms":"Malais",
        "ml":"Malayalam",
        "dv":"Maldivien",
        "mg":"Malgache",
        "mt":"Maltais",
        "mdr":"Mandar",
        "mnc":"Mandchou",
        "man":"Mandingue",
        "mni":"Manipuri",
        "gv":"Manx",
        "mi":"Maori",
        "mr":"Marathe",
        "chm":"Mari",
        "mh":"Marshall",
        "mwr":"Marwar\u00ee",
        "mas":"Masai",
        "mzn": "Mazanderani",
        "byv":"Medumba",
        "men":"Mend\u00e9",
        "mwv": "Mentawai",
        "mer":"Merou",
        "mgo":"M\u00e9ta\u2019",
        "mic":"Micmac",
        "nan": "Min Nan Chinese",
        "min":"Minangkabau",
        "xmf": "Mingrelian",
        "mwl":"Mirandais",
        "moh":"Mohawk",
        "mdf":"Moksa",
        "ro_MD":"Moldave",
        "lol":"Mongo",
        "mn":"Mongol",
        "mos":"Mor\u00e9",
        "ary": "Moroccan Arabic",
        "enm":"Moyen anglais",
        "frm":"Moyen fran\u00e7ais",
        "gmh":"Moyen haut-allemand",
        "mga":"Moyen irlandais",
        "dum":"Moyen n\u00e9erlandais",
        "mul":"Multilingue",
        "mua":"Mundang",
        "ttt": "Muslim Tat",
        "mye":"My\u00e8n\u00e8",
        "nqo":"N\u2019ko",
        "naq":"Nama",
        "nap":"Napolitain",
        "na":"Nauruan",
        "nv":"Navaho",
        "nd":"Nd\u00e9b\u00e9l\u00e9 du Nord",
        "nr":"Nd\u00e9b\u00e9l\u00e9 du Sud",
        "ng":"Ndonga",
        "nl":"N\u00e9erlandais",
        "ne":"N\u00e9palais",
        "new":"Newari",
        "nwc":"Newar\u00ee classique",
        "sba":"Ngambay",
        "nnh":"Ngiemboon",
        "jgo":"Ngomba",
        "yrl": "Nheengatu",
        "nia":"Nias",
        "niu":"Niu\u00e9",
        "nog":"Noga\u00ef",
        "no":"Norv\u00e9gien",
        "nb":"Norv\u00e9gien bokm\u00e5l",
        "nn":"Norv\u00e9gien nynorsk",
        "nov": "Novial",
        "nus":"Nuer",
        "nym":"Nyamwezi",
        "ny":"Nyanja",
        "nyn":"Nyankol\u00e9",
        "nyo":"Nyoro",
        "nzi":"Nzema",
        "oc":"Occitan",
        "oj":"Ojibwa",
        "luy":"Oluluyia",
        "or":"Oriya",
        "om":"Oromo",
        "osa":"Osage",
        "os":"Oss\u00e8te",
        "udm":"Oudmourte",
        "uga":"Ougaritique",
        "ug":"Ou\u00efghour",
        "ur":"Ourdou",
        "uz":"Ouzbek",
        "ps":"Pachto",
        "pal":"Pahlavi",
        "pfl": "Palatine German",
        "pau":"Palau",
        "pi":"Pali",
        "pam":"Pampangan",
        "pag":"Pangasinan",
        "pap":"Papiamento",
        "pa":"Pendjabi",
        "pdc": "Pennsylvania German",
        "fa":"Persan",
        "peo":"Persan ancien",
        "ff":"Peul",
        "phn":"Ph\u00e9nicien",
        "pcd": "Picard",
        "pms": "Piedmontese",
        "pdt": "Plautdietsch",
        "pon":"Pohnpei",
        "pl":"Polonais",
        "pnt": "Pontic",
        "pt":"Portugais",
        "pt_BR":"Portugais br\u00e9silien",
        "pt_PT":"Portugais europ\u00e9en",
        "pro":"Proven\u00e7al ancien",
        "prg": "Prussian",
        "qu":"Quechua",
        "root":"Racine",
        "raj":"Rajasthani",
        "rap":"Rapanui",
        "rar":"Rarotongien",
        "rif": "Riffian",
        "rgn": "Romagnol",
        "rm":"Romanche",
        "rof":"Rombo",
        "rtm": "Rotuman",
        "ro":"Roumain",
        "rn":"Roundi",
        "rug": "Roviana",
        "ru":"Russe",
        "rue": "Rusyn",
        "rwk":"Rwa",
        "rw":"Rwanda",
        "ssy":"Saho",
        "saq":"Samburu",
        "smn":"Sami d\u2019Inari",
        "smj":"Sami de Lule",
        "se":"Sami du Nord",
        "sma":"Sami du Sud",
        "sms":"Sami skolt",
        "sm":"Samoan",
        "sgs": "Samogitian",
        "sad":"Sandawe",
        "sg":"Sangho",
        "sbp":"Sangu",
        "zxx":"Sans contenu linguistique",
        "sa":"Sanskrit",
        "sat":"Santal",
        "sc":"Sarde",
        "sas":"Sasak",
        "sdc": "Sassarese Sardinian",
        "stq": "Saterland Frisian",
        "saz": "Saurashtra",
        "sly": "Selayar",
        "sel":"Selkoupe",
        "seh":"Sena",
        "see":"Seneca",
        "sr":"Serbe",
        "sh":"Serbo-croate",
        "srr":"S\u00e9r\u00e8re",
        "sei": "Seri",
        "st":"Sesotho",
        "shn":"Shan",
        "sn":"Shona",
        "scn":"Sicilien",
        "sid":"Sidamo",
        "bla":"Siksika",
        "szl": "Silesian",
        "sd":"Sindhi",
        "den":"Slavey",
        "cu":"Slavon d\u2019\u00e9glise",
        "sk":"Slovaque",
        "sl":"Slov\u00e8ne",
        "xog":"Soga",
        "sog":"Sogdien",
        "so":"Somali",
        "snk":"Sonink\u00e9",
        "ckb":"Sorani",
        "nso":"Sotho du Nord",
        "su":"Soundanais",
        "sus":"Soussou",
        "azb": "South Azerbaijani",
        "srn":"Sranan tongo",
        "sv":"Su\u00e9dois",
        "gsw":"Suisse allemand",
        "suk":"Sukuma",
        "sux":"Sum\u00e9rien",
        "sw":"Swahili",
        "swc":"Swahili du Congo",
        "ss":"Swati",
        "zbl":"Symboles Bliss",
        "syr":"Syriaque",
        "syc":"Syriaque classique",
        "tg":"Tadjik",
        "tl":"Tagalog",
        "ty":"Tahitien",
        "dav":"Taita",
        "tly": "Talysh",
        "tmh":"Tamacheq",
        "tzm":"Tamazight",
        "ta":"Tamoul",
        "trv":"Taroko",
        "twq":"Tasawaq",
        "tt":"Tatar",
        "chg":"Tchaghata\u00ef",
        "cs":"Tch\u00e8que",
        "ce":"Tch\u00e9tch\u00e8ne",
        "cv":"Tchouvache",
        "te":"T\u00e9lougou",
        "tem":"Temne",
        "ter":"Tereno",
        "teo":"Teso",
        "tet":"Tetum",
        "th":"Tha\u00ef",
        "bo":"Tib\u00e9tain",
        "tig":"Tigr\u00e9",
        "ti":"Tigrigna",
        "tiv":"Tiv",
        "tli":"Tlingit",
        "tpi":"Tok pisin",
        "tkl":"Tokelau",
        "tog":"Tonga nyasa",
        "to":"Tonguien",
        "fit": "Tornedalen Finnish",
        "tyv":"Touva",
        "tkr": "Tsakhur",
        "tsd": "Tsakonian",
        "tsi":"Tsimshian",
        "ts":"Tsonga",
        "tn":"Tswana",
        "tcy": "Tulu",
        "tum":"Tumbuka",
        "aeb": "Tunisian Arabic",
        "tr":"Turc",
        "crh":"Turc de Crim\u00e9e",
        "ota":"Turc ottoman",
        "tk":"Turkm\u00e8ne",
        "tru": "Turoyo",
        "tvl":"Tuvalu",
        "tw":"Twi",
        "kcg":"Tyap",
        "rom":"Tzigane",
        "uk":"Ukrainien",
        "umb":"Umbundu",
        "vai":"Va\u00ef",
        "rup":"Valaque",
        "ve":"Venda",
        "vec": "Venetian",
        "vep": "Veps",
        "vi":"Vietnamien",
        "non":"Vieux norrois",
        "vo":"Volapuk",
        "vro": "V\u00f5ro",
        "vot":"Vote",
        "vun":"Vunjo",
        "wal":"Walamo",
        "wa":"Wallon",
        "wae":"Walser",
        "war":"Waray",
        "wbp": "Warlpiri",
        "was":"Washo",
        "guc": "Wayuu",
        "vls": "West Flemish",
        "mrj": "Western Mari",
        "wo":"Wolof",
        "wuu": "Wu Chinese",
        "xh":"Xhosa",
        "hsn": "Xiang Chinese",
        "yav":"Yangben",
        "yao":"Yao",
        "yap":"Yapois",
        "ybb":"Yemba",
        "ii":"Yi du Sichuan",
        "yi":"Yiddish",
        "yo":"Yoruba",
        "zap":"Zapot\u00e8que",
        "dje":"Zarma",
        "zza":"Zazaki",
        "zea": "Zeelandic",
        "zen":"Zenaga",
        "za":"Zhuang",
        "gbz": "Zoroastrian Dari",
        "zu":"Zoulou",
        "zun":"Zuni"
    }
};

class CountryHelper {

    static getCountries() {
        switch (localStorage.getItem('language')) {
            case 'nl':
                return this.toDropdown(CountryList.nl_NL);
            case 'fr':
                return this.toDropdown(CountryList.fr_FR);
            default:
                return this.toDropdown(CountryList.en_EN);
        }
    }

    static getLanguages(toInclude = []) {
        switch (localStorage.getItem('language')) {
            case 'nl':
                return this.toDropdown(LanguageList.nl_NL, toInclude);
            case 'fr':
                return this.toDropdown(LanguageList.fr_FR, toInclude);
            default:
                return this.toDropdown(LanguageList.en_EN, toInclude);
        }
    }

    static getLanguagesSelect(toInclude = []) {
        switch (localStorage.getItem('language')) {
            case 'nl':
                return this.toDropdownSelect(LanguageList.nl_NL, toInclude);
            case 'fr':
                return this.toDropdownSelect(LanguageList.fr_FR, toInclude);
            default:
                return this.toDropdownSelect(LanguageList.en_EN, toInclude);
        }
    }
    
    static getLanguagesShort(toInclude = []) {
        switch (localStorage.getItem('language')) {
            case 'nl':
                return this.toDropdownShort(LanguageList.nl_NL, toInclude);
            case 'fr':
                return this.toDropdownShort(LanguageList.fr_FR, toInclude);
            default:
                return this.toDropdownShort(LanguageList.en_EN, toInclude);
        }
    }

    static toDropdown(object, toInclude = []) {
        let list = Object.keys(object).map(value => ({ value: value, label: object[value] }));

        if (toInclude.length > 0) {
            list = Object.values(list).filter(item => toInclude.indexOf(item.value) > -1 ? item : null);
        }

        return list;
    }

    static toDropdownSelect(object, toInclude = []) {
        let list = Object.keys(object).map(value => ({ value: value, label: object[value] }));

        if (toInclude.length > 0) {
            list = Object.values(list).filter(item => toInclude.indexOf(item.value) > -1 ? item : null);
        }

        return list;
    }
    
    static toDropdownShort(object, toInclude = []) {
        let list = Object.keys(object).map(value => ({ value: value, label: value.toUpperCase() }));

        if (toInclude.length > 0) {
            list = Object.values(list).filter(item => toInclude.indexOf(item.value) > -1 ? item : null);
        }

        return list;
    }

}

export default CountryHelper;