import * as Yup from 'yup';

class WebsiteTranslation {

    id;
    name;
    translation;
    translations;
    language;

    constructor(id, name, translation, language) {
        this.id = id;
        this.name = name;
        this.translation = translation;
        this.language = language;
        this.translations = {};
    }

    static add_schema = (languages) => {
        let fields = {
            name: Yup.string().required("CMS.Websites.Translations.Form.name").max(100, "CMS.Websites.Translations.Form.name.max").matches(/^[a-zA-Z0-9_]*$/, 'CMS.Websites.Translations.Form.name.invalid', { excludeEmptyString: true }),
        };

        languages.forEach(language => {
            fields['translations_' + language.language] = Yup.string().required('CMS.Websites.Translations.Form.translation');
        });

        return Yup.object(fields);
    }

    static edit_schema = Yup.object({
        name: Yup.string().required(),
        translation: Yup.string().required()
    });
}

export default WebsiteTranslation;