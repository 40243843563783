import * as Yup from "yup"

export default class PageContent {
    id;
    title;
    language;
    slug;
    value;

    static fromJson(json) {
        let pageContent = new PageContent();
        pageContent.id = json['id'];
        pageContent.title = json['title'];
        pageContent.language = json['language'];
        pageContent.slug = json['slug'];
        pageContent.value = json['value'] != null ? json['value'] : '{}';
        return pageContent;
    }


    static seo_schema = Yup.object({
        title: Yup.string().nullable().max(65, "CMS.Page.Content.Seo.field.title,65"),
        tags: Yup.string().nullable().max(500, "CMS.Page.Content.Seo.field.tags,500"),
        description: Yup.string().nullable().max(160, "CMS.Page.Content.Seo.field.description,160"),
    })
}
