import React from "react";
import FormattedMessageString from "../../basics/FormattedMessageString";
import TwofaBackupCodesComponent from "./TwofaBackupCodesComponent";
import PropTypes from "prop-types";

class Activate2faStep3Component extends React.Component {

    render() {
        return (
            <>
                <p className={"step-title"}><FormattedMessageString id={"Auth.2fa.step3.title"} /></p>
                <p><FormattedMessageString id={"Auth.2fa.step3.text1"} /></p>
                <TwofaBackupCodesComponent next_step_action={this.props.next_step_action} />
            </>
        )
    }

}

Activate2faStep3Component.propTypes = {
    next_step_action: PropTypes.func.isRequired
};
export default Activate2faStep3Component;