import React from "react";
import { FormAlphaNumericError, FormEmailError, FormMaxError, FormPhoneError, FormRequiredError, FormSelectError } from "../../errors";

class MapFormError extends React.Component {
    render() {
        let error = this.props.error.split('|');

        if (this.props.error.slice(-5) === "email") {
            return <FormEmailError field_name={error[0]} />
        } else if (this.props.error.slice(-5) === "phone") {
            return <FormPhoneError field_name={error[0]} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-5) === "match") {
            return <FormAlphaNumericError field_name={error[0]} />
        } else if (this.props.error.slice(-6) === 'select') {
            return <FormSelectError field_name={error[0]} />
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

export default MapFormError;