import * as Yup from "yup"
import FormContent from "./FormContent";
import Group from "./Group";
import { JsonHelper } from "../../helpers";

export default class Form {
    id;
    website;
    active;
    page;
    pages;
    mail_admin_send;
    mail_admin_sender_name;
    mail_admin_sender_email;
    mail_admin_recipient_name;
    mail_admin_recipient_email;
    mail_visitor_send;
    mail_visitor_sender_name;
    mail_visitor_sender_email;
    mail_visitor_recipient_field;
    button_text;
    success_message;
    groups;

    constructor() {
        this.groups = [];
    }

    static OPTION_SCHEME = Yup.object().shape({
        label: Yup.string().required('CMS.Form.Form.fields.options.label').max(255, 'CMS.Form.Form.fields.options.label|255|max'),
        value: Yup.string().max(255, 'CMS.Form.Form.title|255|max')
    });

    static FIELD_SCHEME = Yup.object().shape({
        // label: Yup.string().required('CMS.Form.Form.fields.label').max(255, 'CMS.Form.Form.fields.label|255|max'),
        label: Yup.string().when('type', {
            is: (type) => (!['html_linebreak'].includes(type)),
            then: Yup.string().required('CMS.Form.Form.fields.label').max(255, 'CMS.Form.Form.fields.label|255|max')
        }),
        options: Yup.array().of(this.OPTION_SCHEME).when('type', {
            is: (type) => (['multiple-radio', 'multiple-checkbox'].includes(type)),
            then: Yup.array().min(1, 'CMS.Form.Form.fields|1|min.array'),
            otherwise: false
        })
    });

    static GROUP_SCHEME = Yup.object().shape({
        title: Yup.string().required('CMS.Form.Form.groups.label').max(255, 'CMS.Form.Form.groups.label|255|max'),
        fields: Yup.array().of(this.FIELD_SCHEME).min(1, 'CMS.Form.Form.fields|1|min.array')
    });

    static schema_add() {

        let fields = {
            title: Yup.string().required('CMS.Form.Form.title').max(255, 'CMS.Form.Form.title|255|max'),
            pages: Yup.array().required('CMS.Form.Form.pages'),
            button_text: Yup.string().required('CMS.Form.Form.button_text').max(100, 'CMS.Form.Form.button_text|100|max'),
            success_message: Yup.object({ text: Yup.string().required('CMS.Form.Form.success_message') }),
            groups: Yup.array().of(this.GROUP_SCHEME).min(1, 'CMS.Form.Form.groups|1|min.array')
        };

        return Yup.object(fields);
    }

    static schema_mail_settings(model) {
        let fields = {};

        if (model.mail_admin_send) {
            fields = {
                ...fields,
                ...{
                    mail_admin_sender_name: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.sender-name').max(100, 'CMS.Form.Form.Email.sender-name|100|max'),
                    mail_admin_sender_email: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.sender-email').email('CMS.Form.Form.Email.sender-email|email').max(255, 'CMS.Form.Form.Email.sender-email|255|max'),
                    mail_admin_recipient_name: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.recipient-name').max(100, 'CMS.Form.Form.Email.recipient-name|100|max'),
                    mail_admin_recipient_email: Yup.string().required('CMS.Form.Form.Email.recipient-email'),
                    mail_admin_subject: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.subject').max(255, 'CMS.Form.Form.Email.subject|255|max'),
                    mail_admin_content: Yup.object({ text: Yup.string().required('CMS.Form.Form.Email.content') })
                }
            };
        }

        if (model.mail_visitor_send) {
            fields = {
                ...fields,
                ...{
                    mail_visitor_sender_name: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.sender-name').max(100, 'CMS.Form.Form.Email.sender-name|100|max'),
                    mail_visitor_sender_email: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.sender-email').email('CMS.Form.Form.Email.sender-email|email').max(255, 'CMS.Form.Form.Email.sender-email|255|max'),
                    mail_visitor_subject: Yup.string().transform((_, value) => value !== null ? value : '').required('CMS.Form.Form.Email.subject').max(255, 'CMS.Form.Form.Email.subject|255|max'),
                    mail_visitor_content: Yup.object({ text: Yup.string().required('CMS.Form.Form.Email.content') })
                }
            };
        }

        return Yup.object(fields);
    }

    static fromJson(json, language) {
        let form = new Form();
        form.website = json.website;

        form.page = json.page;
        form.pages = JsonHelper.tryParse(json.pages);
        form.id = json.id;
        form.active = json.active;
        form.mail_admin_send = json.mail_admin_send;
        form.mail_admin_sender_name = json.mail_admin_sender_name;
        form.mail_admin_sender_email = json.mail_admin_sender_email;
        form.mail_admin_recipient_name = json.mail_admin_recipient_name;
        form.mail_admin_recipient_email = json.mail_admin_recipient_email ? JsonHelper.tryParse(json.mail_admin_recipient_email) : [];
        form.mail_visitor_send = json.mail_visitor_send;
        form.mail_visitor_sender_name = json.mail_visitor_sender_name;
        form.mail_visitor_sender_email = json.mail_visitor_sender_email;
        form.mail_visitor_recipient_field = json.mail_visitor_recipient_field;

        form.groups = json.groups.map(group => Group.fromJson(group, language));

        let contents = json.contents.filter(content => content.language === language)[0];

        return { ...form, ...FormContent.fromJson(contents) };
    }
}
