import React from "react";
import { Link } from 'react-router-dom';
import { DeleteModalComponent, StandardTableComponent, TableDeleteActionComponent } from "../../basics/table";
import { ListGroup, Tab } from "react-bootstrap";
import { Application, CountryHelper, CheckPermissions, GeneralSettingsHelper } from "../../../helpers";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import { DynamicFieldValueRepository, WebsiteRepository } from "../../../repository";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../../basics/FormattedMessageString";
import Module from "../../../models/module/Module";
import LoaderComponent from "../../basics/layout/LoaderComponent";
import Website from "../../../models/website/Website";
import ModalFormFragment from "../../../fragments/basics/ModalFormFragment";
import DynamicFieldValueFormComponent from "./DynamicFieldValueFormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DynamicFieldValueOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: props.rows,
            editField: null,
            settings: Module.getSettings(),
            website: null,
            isLoading: true,
            toggler: [],
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'value',
                text: <FormattedMessageString id="CMS.Attendee.DynamicField.overview.table.value" />,
                sort: true,
                isDummyField: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let valueA = rowA.contents[this.state.language]?.value.toLowerCase();
                    let valueB = rowB.contents[this.state.language]?.value.toLowerCase();

                    if (order === 'asc') {
                        return valueA < valueB ? -1 : 1;
                    } else {
                        return valueB > valueA ? 1 : -1;
                    }
                },
                formatter: (cellContent, row) => row.contents[index]?.value
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.contents[index];
                    content.id = row.id

                    return <div className="actions">
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <Link to="#" className="edit" onClick={() => this.setState({ showEditModal: true, editDynamicFieldValue: content })}>
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Link>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} disabled={!row.deletable} />
                        )}
                    </div>
                }
            }
        ];
    }

    reload = () => this.props.history.replace(this.props.parent.props.location.pathname);
    updateData = () => DynamicFieldValueRepository.all().then(response => this.props.parent.hardReload());

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({ reload: true }, () => {
            this.props.parent.setState({ reload: false }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({ toggler: toggler, language: this.state.language })
            })
        })
    }

    closeModal = (reload) => this.setState({ showEditModal: false, editField: null }, () => {
        if (reload) {
            this.props.parent.hardReload();
        }
    })

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id="list-group-language-events"
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}
                            >
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                <div className="custom-data-table event">
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={true}
                                        title={this.props.title_id} type={this.props.type} subRows={false}
                                        sort={{ dataField: 'value', order: 'asc' }}
                                    />
                                    <DeleteModalComponent parent={this} />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>

                <ModalFormFragment title_id={<FormattedMessageString id="CMS.Form.overview.edit.text" values={{ text: this.props.title_id }} />} show={this.state.showEditModal}
                    onHide={() => this.setState({ showEditModal: false, editDynamicFieldValue: null })}
                >
                    <DynamicFieldValueFormComponent parent_ref={this} table_ref={this}
                        dynamicFieldValue={this.state.editDynamicFieldValue}
                    />
                </ModalFormFragment>
            </div>
        );
    }
}

export default DynamicFieldValueOverviewTableComponent;