import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FormHelper from "../../../helpers/FormHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import LoaderComponent from "../../basics/layout/LoaderComponent";
import WebsiteMaxRequiredError from "../../websites/errors/WebsiteMaxRequiredError";
import DynamicFieldValue from "../../../models/attendee/DynamicFieldValue";
import DynamicFieldValueContent from "../../../models/attendee/DynamicFieldValueContent";
import DynamicFieldValueRepository from "../../../repository/DynamicFieldValueRepository";
import FormToastComponent from "../../basics/FormToastComponent";

class DynamicFieldValueFormComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            dynamicFieldValue: this.props.dynamicFieldValue == null ? new DynamicFieldValueContent() : this.props.dynamicFieldValue,
            isLoading: false,
            website_id: this.props.match.params.id,
            form_errors: []
        };
        this._form = null;
    }

    handleSubmitSuccess = () => this.props.parent_ref.setState({ showFormModal: false, showEditModal: false }, () => {
        FormToastComponent.successTrans('Item', 'Default.saved');
        this.props.table_ref.closeModal(true)
    });
    handleSubmitError = (reason) => FormHelper.handleSubmitError(this, reason.response);

    render() {
        if (this.state.isLoading) return <LoaderComponent />;

        return (
            <div className="custom-form website-setting">
                <Formik
                    enableReinitialize
                    validationSchema={DynamicFieldValue.schema}
                    initialValues={this.state.dynamicFieldValue}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this._form = ref}
                    onSubmit={(values, formikActions) => {
                        if (!values.id) {
                            DynamicFieldValueRepository.create({ field_number: this.props.match.params.field_number, value: values.value })
                                .then(() => this.handleSubmitSuccess())
                                .catch(reason => this.handleSubmitError(reason))
                        } else {
                            DynamicFieldValueRepository.update({ ...{ field_number: this.props.match.params.field_number }, ...values })
                                .then(() => this.handleSubmitSuccess())
                                .catch(reason => this.handleSubmitError(reason))
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group>
                                <FormattedMessage id="CMS.Websites.Settings.Form.name">
                                    {(value) => <Form.Label>{value}</Form.Label>}
                                </FormattedMessage>
                                <Form.Control type="text" name="value" value={values.value || ''}
                                    onChange={handleChange}
                                    isInvalid={!!errors.value || !!this.state.form_errors.value}
                                    onBlur={handleBlur}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.value && <WebsiteMaxRequiredError error={errors.value} />}
                                    {this.state.form_errors.value && <p>{this.state.form_errors.value}</p>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row className="buttons">
                                <Col xs={6}>
                                    <Form.Group>
                                        <FormattedMessage id={this.state.dynamicFieldValue.value ? "CMS.Websites.Settings.Form.save" : "CMS.Websites.Settings.Form.submit"}>
                                            {value =>
                                                <Button type="submit" onClick={() => { FormHelper.submitFormErrorToast(this); this.setState({ form_errors: [] }) }}>
                                                    {!this.state.dynamicFieldValue.value && <FontAwesomeIcon icon={["far", "plus"]} />}
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <FormattedMessage id="CMS.Websites.Translation.Form.cancel">
                                            {value =>
                                                <Button variant="secondary" onClick={() => this.props.parent_ref.setState({ showFormModal: false, showEditModal: false }, () => this.props.table_ref.closeModal(false))}>
                                                    {value}
                                                </Button>
                                            }
                                        </FormattedMessage>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div >
        );
    }
}

DynamicFieldValueFormComponent.propTypes = {
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired,
    dynamicFieldValue: PropTypes
};
export default withRouter(DynamicFieldValueFormComponent);