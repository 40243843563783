import React from "react";
import EventRepository from "../../repository/EventRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import EventTextFormComponent from "./forms/EventTextFormComponent";
import Module from "../../models/module/Module";
import Event from "../../models/event/Event";
import ModuleRepository from "../../repository/ModuleRepository";
import EventContent from "../../models/event/EventContent";
import JsonHelper from "../../helpers/JsonHelper";
import { LoaderComponent } from "../basics/layout";

class EventFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new EventContent(),
            loading: true,
            settings: {},
            action: 'create',
            roles: this.props.user.roles,
        };

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
        this.isManager = CheckPermissions.role(this.state.roles, UserRolesEnum.MANAGER) && !CheckPermissions.role(this.state.roles, UserRolesEnum.RESELLER);
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];

        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let settings = result[0]?.data ? result[0]?.data.settings : {};

                let model = this.state.model;
                if (result[1] !== undefined) {
                    model = result[1];
                    let content = model.contents.filter(content => content.language === this.props.match.params.language)[0];

                    model.title = content.title;
                    model.summary = content.summary;
                    model.value = content.value === '[]' ? {} : JsonHelper.tryParse(content.value);
                    model.pdf_document = content.pdf_document;
                }
                
                let action = this.state.action;
                if (this.props.location.pathname.indexOf('/duplicate/') > -1) {
                    action = 'duplicate';

                    model.clone();
                } else if (model.id) {
                    action = 'edit';
                }

                this.setState({
                    loading: false,
                    settings: settings,
                    model: model,
                    action: action
                });
            });
    }

    getBeforeSubmit = (element) => EventTextFormComponent.fields_image_before_submit(element, this.state.settings)

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="event"
                validationSchema={Event.schema(this.state.settings, this.state.model)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.state.action === 'edit') {
                                EventRepository.update({ id: values.id, language: this.props.match.params.language, ...values })
                                    .then(() => {
                                        FormToastComponent.successTrans("Event", "Default.saved");
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error))
                            } else if (this.state.action === 'duplicate') {
                                values.id = this.props.match.params.id;
                                EventRepository.duplicate({ id: this.props.match.params.id, language: this.props.match.params.language, ...values })
                                    .then(() => {
                                        FormToastComponent.successTrans("Event", "Default.saved");
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error))
                            } else {
                                EventRepository.create(values)
                                    .then(() => {
                                        FormToastComponent.successTrans("Event", "Default.saved");
                                        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview');
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error))
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={EventTextFormComponent.fields(this.state.settings, this)}
            />
        );
    }
}

export default withRouter(EventFormComponent);