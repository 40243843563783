import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TableToggleActiveActionComponent extends React.Component {
    render() {
        if (this.props.active) {
            return (
                <Link onClick={this.props.onClick} className={'active ' + (this.props.disabled ? 'disabled' : '')} to="#">
                    <FontAwesomeIcon icon={['fas', 'check']} />
                </Link>
            );
        }

        return (
            <Link onClick={this.props.onClick} className={'inactive ' + (this.props.disabled ? 'disabled' : '')} to="#">
                <FontAwesomeIcon icon={['fas', 'times']} />
            </Link>
        );
    }
}

export default TableToggleActiveActionComponent;