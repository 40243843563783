import React from "react";
import ContentBlock from "../../models/content-blocks/ContentBlock";
import ContentBlockRepository from "../../repository/ContentBlockRepository";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import ContentBlockCustomSettingsFormComponent from "./ContentBlockCustomSettingsFormComponent";
import ContentBlockFormError from "./errors/ContentBlockFormError";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import ContentBlockTextFormComponent from "./forms/ContentBlockTextFormComponent";
import InputHelper from "../../helpers/InputHelper";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { LoaderComponent } from "../basics/layout";
import { PageRepository } from "../../repository";

class ContentBlockFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: this.props.model ? this.props.model : new ContentBlock(),
            loading: true,
            isEdit: false
        };

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            PageRepository.all(null, true)
        ];
        if (this.props.get_promise !== undefined) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                let model = this.state.model;

                let pages = result[0];

                let pagesList = [];
                if (pages !== undefined) {
                    pagesList = pages.map(page => {
                        return {
                            value: page.page.id,
                            label: '-'.repeat(page.index) + " " + page.title
                        }
                    });
                }

                if (result[1] !== undefined) {
                    model = ContentBlock.fromJson(result[1].contentBlock);
                    let contentBlockContent = model.contents.filter(content => content.language === this.props.match.params.language)[0];

                    model.title = contentBlockContent.title;
                    model.summary = contentBlockContent.summary;
                    model.content = contentBlockContent.content === '[]' ? {} : JSON.parse(contentBlockContent.content);
                    model.image = contentBlockContent.image;
                    model.url = contentBlockContent.url;
                }

                this.setState({
                    model: model,
                    loading: false,
                    pages: pagesList,
                    isEdit: model.id !== undefined
                });
            });
    }

    goBackAfterCreate = () => this.props.history.push("/" + this.props.slug);
    getBeforeSubmit = (element) => ContentBlockTextFormComponent.fields_image_before_submit(element);

    handleChange = (name, value) => {
        let model = this.state.model;

        if (['show_title', 'show_image', 'show_url', 'show_pages', 'show_content', 'state_active'].indexOf(name) > -1) {
            model[name] = !model[name];
        } else {
            model[name] = value;
        }

        this.setState({ model: model });
    }

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.props}
                ref={this.table_ref}
                parent={this}
                className="content-block"
                model={this.state.model}
                validationSchema={ContentBlock.validation(this.state.model)}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.state.isEdit) {
                                ContentBlockRepository.update({ id: this.state.model.id, language: this.props.match.params.language, ...values })
                                    .then(response => {
                                        if (response.success) {
                                            FormToastComponent.successTrans("ContentBlocks", "Default.saved")
                                            this.goBackAfterCreate();
                                        } else {
                                            FormHelper.handleSubmitError(this, null)
                                        }
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error));
                            } else {
                                ContentBlockRepository.create(values)
                                    .then(response => {
                                        FormToastComponent.successTrans("ContentBlocks", "Default.saved");
                                        this.goBackAfterCreate();
                                    })
                                    .catch(error => FormHelper.handleSubmitError(this, error));
                            }
                        })
                }}
                formErrors={this.state.form_errors}
                fields={[
                    {
                        type: "row",
                        children: [
                            {
                                type: "col",
                                xl: 9,
                                fields: [
                                    {
                                        title: "CMS.ContentBlock.Form.name",
                                        type: "text",
                                        name: "name",
                                        disabled: this.state.isEdit,
                                        message: 'CMS.ContentBlock.Form.name.info.' + (this.state.isEdit ? 'edit' : 'add'),
                                        beforeOnChange: (e) => {
                                            return InputHelper.transformToLowerCaseAndUnderscore(e.target.value)
                                        },
                                        customFeedback: ContentBlockFormError,
                                        formErrorName: "name",
                                        security: UserRolesEnum.RESELLER
                                    }
                                ]
                            },
                            {
                                type: "col",
                                xl: 9,
                                fields: [
                                    {
                                        title: "CMS.ContentBlock.Form.summary",
                                        type: "textarea",
                                        name: "summary",
                                        customFeedback: ContentBlockFormError,
                                        formErrorName: "summary",
                                        security: UserRolesEnum.RESELLER
                                    }
                                ]
                            },
                            {
                                type: "col",
                                md: 8,
                                fields: []
                            },
                            {
                                type: "col",
                                xl: 9,
                                fields: [],
                                custom: ContentBlockCustomSettingsFormComponent,
                                customProps: { user: this.props.user, model: this.state.model, pages: this.state.pages, language: this.props.match.params.language }
                            }
                        ]
                    }
                ]}
            />
        );
    }
}

export default withRouter(ContentBlockFormComponent);