import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import JsonHelper from './JsonHelper';

class ProductHelper {
    static export = (records, params) => {
        let workbook = {
            Sheets: {
                'products': XLSX.utils.json_to_sheet(records.map(record => this.convertProductToExportRecord(record, params)))
            },
            SheetNames: ['products']
        };

        this.categories(workbook, params);
        this.additionaFields(workbook, params);

        let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, 'products.xlsx');
    }

    static convertProductToExportRecord = (product, params) => {
        // Set general data
        let record = {
            id: product.internal_id,
            article_number: product.article_number,
            unit_price: product.unit_price,
            category: product.category,
            stock: product.stock,
            active: product.active ? 1 : 0,
            spotlight: product.spotlight ? 1 : 0,
        };

        if (!params.settings.use_spotlight) delete record.spotlight;
        if (!params.settings.use_categories) delete record.category;
        if (!params.settings.show_article_number) delete record.article_number;
        if (!params.settings.show_stock) delete record.stock;

        params.website.languages.forEach(language => {
            let lng = language.language;

            record = {
                ...record, ...{
                    ['title_' + lng]: '',
                    ['content_' + lng]: ''
                }
            }

            if (params.settings.show_summary) {
                record['summary_' + lng] = '';
            }
            if (params.settings.use_product_tags) {
                record['tags_' + lng] = '';
            }
            for (let i = 1; i <= 3; i++) {
                if (!params.settings['show_additional_field_' + i]) continue;

                let label = params.settings['additional_field_' + i + '_label'];
                record['additional_field_' + i + '_value_' + lng + ' | ' + label] = '';
            }

            let content = product.contents.filter(content => content.language === lng)[0];
            if (!content) return record;

            record['title_' + lng] = content?.title;
            record['content_' + lng] = JsonHelper.tryParse(content?.content)?.text;

            if ('summary_' + lng in record) {
                record['summary_' + lng] = content?.summary;
            }

            if ('tags_' + lng in record) {
                record['tags_' + lng] = content?.tags.length ? content.tags.join(',') : null;
            }

            for (let i = 1; i <= 3; i++) {
                if (!params.settings['show_additional_field_' + i]) continue;

                let label = params.settings['additional_field_' + i + '_label'];
                if ('additional_field_' + i + '_value_' + lng + ' | ' + label in record) {
                    if (params.settings['additional_field_' + i + '_type'] === 'editor') {
                        record['additional_field_' + i + '_value_' + lng + ' | ' + label] = JsonHelper.tryParse(content[['additional_field_' + i + '_value']])?.text;
                    } else {
                        record['additional_field_' + i + '_value_' + lng + ' | ' + label] = content['additional_field_' + i + '_value'];
                    }
                }
            }
        });

        return record;
    }

    static categories = (workbook, params) => {
        if (!params.settings.use_categories) return workbook;

        let data = params.categories;
        data.push({ id: null, label: null });

        workbook.Sheets['categories'] = XLSX.utils.json_to_sheet(data);
        workbook.SheetNames.push('categories');

        return workbook;
    }

    static additionaFields = (workbook, params) => {
        let data = [];
        for (let i = 1; i <= 3; i++) {
            if (!params.settings['show_additional_field_' + i]) continue;
            data.push({ id: 'aditional_field_' + i, label: params.settings['additional_field_' + i + '_label'] });
        }

        if (data.length <= 0) return workbook;

        workbook.Sheets['additional_fields'] = XLSX.utils.json_to_sheet(data);
        workbook.SheetNames.push('additional_fields');

        return workbook;
    }

    static data = (params) => {
        let data = [];

        if (params.settings.use_categories) {
            data = params.categories;
            data.push({ id: null, label: null });
        }

        for (let i = 1; i <= 3; i++) {
            if (!params.settings['show_additional_field_' + i]) continue;
            data.push({ id: 'aditional_field_' + i, label: params.settings['additional_field_' + i + '_label'] });
        }

        return data;
    }
}

export default ProductHelper;