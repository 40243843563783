export default class Ipv4 {
    static ipv4(message = 'Invalid IP address') {
        return this.matches(/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/, {
            message,
            excludeEmptyString: true
        }).test('ip', message, value => {
            return value === undefined || value.trim() === ''
                ? true
                : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
        });
    }
}
