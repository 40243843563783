import React from "react";
import { withRouter } from 'react-router-dom';
import FormToastComponent from "../basics/FormToastComponent";
import Blog from "../../models/blog/Blog";
import BlogTextFormComponent from "./forms/BlogTextFormComponent";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import BlogRepository from "../../repository/BlogRepository";
import FormHelper from "../../helpers/FormHelper";
import ModuleRepository from "../../repository/ModuleRepository";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";
import API from "../../services/api";
import { JsonHelper } from "../../helpers";

class BlogContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blog: null,
            blogContent: null,
            isLoading: true,
            settings: {},
            validation: {}
        }

        this.table_ref = React.createRef();
    }

    componentDidMount() {
        let promises = [
            ModuleRepository.getSettings()
        ];
        if (this.props.get_promise) {
            promises.push(this.props.get_promise);
        }

        Promise.all(promises)
            .then(result => {
                // Settings
                let settingsResult = result[0];
                let settings = {};
                if (settingsResult !== undefined && settingsResult.data !== undefined) {
                    settings = settingsResult.data.settings;
                }
                settings.titleOptional = true;

                let blogResponse = result[1];
                let blog = Blog.fromJson(blogResponse);
                let blogContent = blog.contents.filter(content => content.language === this.props.match.params.language)[0];
                blogContent.value = JsonHelper.tryParse(blogContent.value);

                if (blogContent.value.content) {
                    blogContent.value.content.forEach((_, i) => {
                        blogContent.value.content[i].index = i;
                    })
                }

                this.setState({
                    settings: settings,
                    blog: blog,
                    blogContent: blogContent
                }, () => this.setState({ isLoading: false }, () => {
                    this.props.parent.updateTitle(<FormattedMessageString id="CMS.Media.Content.block.add" />);
                }))
            })
            .catch(error => FormToastComponent.error(error));
    }

    async getBeforeSubmit(element) {
        let promises = [];
        promises.push(BlogTextFormComponent.fields_block_before_submit(element, 'blog_block_image'));

        await Promise.all(promises).then(result => true);
    }

    handleBlockTypeChange = (e) => {
        this.setState({
            settings: {
                ...this.state.settings,
                titleOptional: e.value === 'text'
            }
        });

        return e.value;
    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />

        return (
            <StandardFormComponent {...this.props}
                ref={this.table_ref}
                parent={this}
                model={this.state.blogContent}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind()
                    this.getBeforeSubmit(this.table_ref.current).then(result => {

                        let data = {
                            id: this.state.blog.id,
                            language: values.language,
                            type: values.type,
                            title: values.title,
                            summary: values.summary,
                            value: JSON.stringify(values.value)
                        };

                        BlogRepository.updateContent(data)
                            .then(response => {
                                if (API.isSuccess(response)) {
                                    FormToastComponent.successTrans(values.title, "Default.saved")
                                } else {
                                    FormHelper.handleSubmitError(this, null)
                                }
                            })
                            .catch(error => {
                                FormHelper.handleSubmitError(this, error)
                            })
                    })
                }
                }
                formErrors={this.state.form_errors}
                fields={BlogTextFormComponent.fields_block({ ...this.state.settings, ...{ onBlockTypeChange: this.handleBlockTypeChange } })}
                btnSubmitClass="visually-hidden"
            />
        )
    }
}

export default withRouter(BlogContentComponent);